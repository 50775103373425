import React, { ReactElement } from "react";
import styled from "styled-components";

import * as apiTypes from "../../../api/consumer/types";
import { PreferredTemperatureUnit } from "../../../api/consumer/types/user";

import { Point, TemperatureRange } from "../../types";
import { isWithinRange } from "../../utils";

import { Presets } from "./MobileThermostat";

type OwnProps = {
  presets: Presets;
  temperatureRange: TemperatureRange;
};

export type StateProps = {
  isDeviceLocked: boolean;
  onChange?: (temperature: number, unit: PreferredTemperatureUnit) => void;
  temperatureUnit: apiTypes.user.PreferredTemperatureUnit;
};

type PresetTemperaturesProps = StateProps & OwnProps;

export const PresetTemperatures: React.FC<PresetTemperaturesProps> = ({
  presets,
  onChange,
  temperatureRange,
  temperatureUnit,
}) => {
  const handlePresetClick = (temp: number | null): void => {
    if (!onChange || !temp) return;
    onChange(temp, temperatureUnit);
  };

  return (
    <>
      {Object.entries(presets).map(([key, preset]): ReactElement | null => {
        return (
          <PresetMarker
            onClick={handlePresetClick}
            key={key}
            position={preset?.position}
            temperature={preset?.temperature}
            temperatureRange={temperatureRange}
          />
        );
      })}
    </>
  );
};

type PresetMarkerProps = {
  onClick: (temp: number | null) => void;
  position: Point | null | undefined;
  temperature: number | null | undefined;
  temperatureRange: TemperatureRange;
};

const PresetMarker: React.FC<PresetMarkerProps> = ({
  onClick,
  position,
  temperature,
  temperatureRange,
}) => {
  if (
    !position ||
    !temperature ||
    !isWithinRange(temperature, temperatureRange)
  ) {
    return null;
  }

  return (
    <sc.Preset position={position} onClick={(): void => onClick(temperature)}>
      <svg width="10px" height="10px" viewBox="0 0 10 10">
        <circle fill="var(--white)" stroke="none" cx={5} cy={5} r={5} />
      </svg>
    </sc.Preset>
  );
};

type PresetProps = {
  position: Point;
};

const sc = {
  Preset: styled.div.attrs<PresetProps>(({ position }) => ({
    style: {
      transform: `translate(${position.x}px, ${position.y}px)`,
    },
  }))<PresetProps>`
    cursor: grab;
    left: 5px;
    position: absolute;
    top: 5px;
  `,
};
