import { getLoginCookie } from "../cookies";
import { Action } from "../shared/types";
import * as a from "./actions";
import { SsoPage, SsoState } from "./types";

const loginCookie = getLoginCookie();

const initialState: SsoState = {
  createAccount: {},
  forgotPassword: {},
  login: {
    success: !!loginCookie,
  },
  resetPassword: {},
  sendEmailVerificationRequest: {},
  updateUser: {},
  verifyEmail: {},
};

export const getInitialState = (overrides?: Partial<SsoState>): SsoState => ({
  ...initialState,
  ...overrides,
});

export const reducer = (
  state: SsoState = initialState,
  action: Action
): SsoState => {
  const { payload } = action;

  switch (action.type) {
    case a.SSO_LOGIN_SUCCESS:
      return {
        ...state,
        login: {
          success: true,
        },
      };

    case a.SSO_LOGIN_FAILURE:
      return {
        ...state,
        login: {
          failureCode: payload.code,
          success: false,
        },
      };

    case a.SSO_CREATE_ACCOUNT_SUCCESS:
      return {
        ...state,
        createAccount: {
          success: true,
        },
      };

    case a.SSO_CREATE_ACCOUNT_FAILURE:
      return {
        ...state,
        createAccount: {
          failureCode: payload.code,
          success: false,
        },
      };

    case a.SSO_FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        forgotPassword: {
          success: true,
        },
      };

    case a.SSO_FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        forgotPassword: {
          failureCode: payload.code,
          success: false,
        },
      };

    case a.SSO_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetPassword: {
          success: true,
        },
      };

    case a.SSO_RESET_PASSWORD_FAILURE:
      return {
        ...state,
        resetPassword: {
          failureCode: payload.code,
          success: false,
        },
      };

    case a.SSO_SEND_EMAIL_VERIFICATION_FAILURE:
      return {
        ...state,
        sendEmailVerificationRequest: {
          failureCode: payload.code,
          success: false,
        },
      };

    case a.SSO_SEND_EMAIL_VERIFICATION_SUCCESS:
      return {
        ...state,
        sendEmailVerificationRequest: {
          success: true,
        },
      };

    case a.SSO_SIGN_OUT:
      return getInitialState({
        login: {},
      });

    case a.SSO_UPDATE_PAGE_STATE: {
      const page: SsoPage = payload.page;
      return {
        ...state,
        [page]: {
          ...state[page],
          ...payload.pageUpdate,
        },
      };
    }

    case a.SSO_UPDATE_USER_FAILURE:
      return {
        ...state,
        updateUser: {
          failureCode: payload.code,
          success: false,
        },
      };

    case a.SSO_UPDATE_USER_SUCCESS:
      return {
        ...state,
        updateUser: {
          success: true,
        },
      };

    case a.SSO_VERIFY_EMAIL_FAILURE:
      return {
        ...state,
        sendEmailVerificationRequest: {
          failureCode: payload.code,
          success: false,
        },
        verifyEmail: {
          failureCode: payload.code,
          success: false,
        },
      };

    case a.SSO_VERIFY_EMAIL_SUCCESS:
      return {
        ...state,
        verifyEmail: {
          success: true,
        },
      };

    case a.SSO_RESET_PAGE:
      const page: SsoPage = payload.page;

      return {
        ...state,
        [page]: initialState[page],
      };

    default:
      return state;
  }
};
