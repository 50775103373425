import React, { useRef } from "react";
import styled, { keyframes } from "styled-components";

import { useGetComponentWidth } from "../../../shared/hooks";
import { StrainColor } from "../../../shared/types";

import { PodOuterBar } from "./PodOuterBar";
import { PodPlaceholderBar } from "./PodPlaceholderBar";

const FILL_BAR_WIDTH = 318;
const TICK_POSITIONS = [0, 1, 2, 3, 4, 5, 6];
const BAR_HEIGHT = 20;
const BAR_OFFSET = 10;

const getTickColor = (
  effectsScore: number,
  position: number,
  strainColor: StrainColor
): string => {
  return effectsScore === position
    ? strainColor.primary
    : strainColor.secondary;
};

// Calculate where the fill bar should draw to, compensates for SVG strokeWidth
const getTickOffset = (position: number, width: number) => {
  return ((width - 10) / 6) * position + 10;
};

type EffectsSpectrumBarProps = {
  effectsScore: number;
  strainColor: StrainColor;
};

export const EffectsSpectrumBar: React.FC<EffectsSpectrumBarProps> = ({
  effectsScore,
  strainColor,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const barWidth = useGetComponentWidth(ref, undefined, FILL_BAR_WIDTH);
  const scorePosition = getTickOffset(effectsScore, barWidth);

  return (
    <sc.Container ref={ref}>
      {barWidth === 0 ? (
        <PodPlaceholderBar width={FILL_BAR_WIDTH} />
      ) : (
        <sc.Svg viewBox={`0 0 ${barWidth + BAR_OFFSET} ${BAR_HEIGHT}`}>
          <PodOuterBar
            color={strainColor.primary}
            width={barWidth + BAR_OFFSET}
          />
          <sc.ScoreIndicator
            width={BAR_HEIGHT}
            height={BAR_HEIGHT}
            fill={strainColor.secondary}
            rx="4"
            x={scorePosition - BAR_HEIGHT / 2}
          />
          {TICK_POSITIONS.map((position) => {
            return (
              <BarTick
                color={strainColor.secondary}
                key={position}
                tickOffset={getTickOffset(position, barWidth)}
                transitionColor={getTickColor(
                  effectsScore,
                  position,
                  strainColor
                )}
              />
            );
          })}
        </sc.Svg>
      )}
    </sc.Container>
  );
};

type barTickProps = {
  color: string;
  tickOffset: number;
  transitionColor: string;
};

const BarTick: React.FC<barTickProps> = ({
  color,
  tickOffset,
  transitionColor,
}) => {
  return (
    <sc.BarTick
      d={`M${tickOffset} 5 L ${tickOffset} 15`}
      initColor={color}
      stroke={transitionColor}
      strokeLinecap="round"
      strokeWidth={2}
      transitionColor={transitionColor}
    />
  );
};

const scoreIndicatorAnimation = () => keyframes`
  0% {opacity: 0;}
  100% { opacity: 1; }
`;

const barTickAnimation = (
  initColor: string,
  transitionColor: string
) => keyframes`
  0% {stroke: ${initColor};}
  100% { stroke: ${transitionColor}; }
`;

type styledBarTickProps = {
  initColor: string;
  transitionColor: string;
};

const sc = {
  BarTick: styled.path<styledBarTickProps>`
    animation-duration: 800ms;
    animation-name: ${({ initColor, transitionColor }) =>
      barTickAnimation(initColor, transitionColor)};
    animation-timing-function: ease-in-out;
  `,

  Container: styled.div`
    height: 20px;
    position: relative;
    width: 100%;
  `,

  ScoreIndicator: styled.rect`
    animation-duration: 800ms;
    animation-name: ${scoreIndicatorAnimation()};
    animation-timing-function: ease-in-out;
  `,

  Svg: styled.svg`
    left: 0;
    position: absolute;
  `,
};
