import { isMobile } from "react-device-detect";

import { Action } from "../shared/types";
import * as a from "./actions";
import { ANALYTICS_SPEC_VERSION } from "./constants";
import { AnalyticsState } from "./types";

const initialState: AnalyticsState = {
  platform: isMobile ? "mobile-web" : "web",
  specVersion: ANALYTICS_SPEC_VERSION,
};

export const getInitialState = (
  overrides?: Partial<AnalyticsState>
): AnalyticsState => {
  return { ...initialState, ...overrides };
};

export const reducer = (
  state: AnalyticsState = initialState,
  action: Action
): AnalyticsState => {
  switch (action.type) {
    case a.TRACK_EVENT:
    default:
      return state;
  }
};
