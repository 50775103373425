import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

import * as routes from "../../main/routes";
import { MainAppState } from "../../main/types";
import { Modal, ModalProps } from "../../modal/components/Modal";
import { Pencil } from "../../shared/components/Icons";
import * as Text from "../../shared/components/Text";
import * as text from "../../shared/text";
import { desktopBreakpoint } from "../../shared/utils";
import { goToSso } from "../../sso/utils";

type AccountInfoModalProps = ModalProps;

export const AccountInfoModal: React.FC<AccountInfoModalProps> = ({
  isOpen,
  onRequestClose,
}) => {
  const modalProps = {
    isOpen,
    onRequestClose,
  };
  return (
    <Modal {...modalProps}>
      <AccountInfoModalContent {...modalProps} />
    </Modal>
  );
};

const AccountInfoModalContent: React.FC<AccountInfoModalProps> = ({
  onRequestClose,
}) => {
  const userEmail = useSelector(
    (state: MainAppState) => state.consumerApi.user.email
  );

  const handleChangeMyPasswordClick = (route: string): void => {
    goToSso(route);

    // Added a 0.5s timeout here to prevent the Account page from briefly
    // showing before opening a new tab/redirecting to the SSO page.
    if (onRequestClose) setTimeout(onRequestClose, 500);
  };

  return (
    <sc.Container>
      <sc.EditIcon>
        <Pencil />
      </sc.EditIcon>
      <sc.Title>{text.ACCOUNT_INFORMATION}</sc.Title>
      <sc.InfoContainer>
        <sc.InfoLabel>{text.EMAIL}:</sc.InfoLabel>
        <sc.InfoValue>{userEmail}</sc.InfoValue>
      </sc.InfoContainer>
      <sc.InfoContainer>
        <sc.InfoLabel>{text.PASSWORD}:</sc.InfoLabel>
        <sc.InfoValue>{text.PASSWORD_PLACEHOLDER}</sc.InfoValue>
      </sc.InfoContainer>
      <sc.Link
        to="#"
        onClick={(): void =>
          handleChangeMyPasswordClick(
            routes.ssoEditAccount({ returnTo: routes.account() })
          )
        }
      >
        {text.CHANGE_MY_PASSWORD}
        {" →"}
      </sc.Link>
    </sc.Container>
  );
};

const sc = {
  Container: styled.div`
    align-items: center;
    background-color: var(--white);
    border-radius: 8px;
    box-shadow: 0 1px 12px 0 var(--bluey-grey-80);
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-top: 45px;
    width: 100%;

    ${desktopBreakpoint(
      css`
        height: 344px;
        justify-content: center;
        padding-top: 0;
        width: var(--default-modal-width);
      `
    )}
  `,

  EditIcon: styled.div`
    border: solid 1px var(--black);
    border-radius: 32px;
    height: 48px;
    width: 48px;

    & svg {
      height: 20px;
      margin: 12px;
      width: 20px;
    }
  `,

  InfoContainer: styled.div`
    display: flex;
    justify-content: space-between;
    max-width: 340px;
    width: 100%;

    ${desktopBreakpoint(
      css`
        max-width: none;
        width: 340px;
      `
    )}
  `,

  InfoLabel: styled.div`
    font-size: 16px;
    line-height: 1.13;
    margin: 16px 0 0 16px;
  `,

  InfoValue: styled.div`
    ${Text.PlaaxLight}
    font-size: 16px;
    line-height: 1.13;
    margin: 16px 16px 0 0;
    text-align: right;
  `,

  Link: styled(Link)`
    ${Text.PlaaxLight}
    color: var(--black);
    font-size: 16px;
    margin-top: 40px;

    &:hover {
      ${Text.Plaax}
    }
  `,

  Title: styled.div`
    font-size: 18px;
    margin-top: 40px;
    max-width: 340px;
    padding-left: 16px;
    text-transform: uppercase;
    width: 100%;

    ${desktopBreakpoint(
      css`
        max-width: none;
        width: 340px;
      `
    )}
  `,
};
