import styled, { css } from "styled-components";

import { ReactComponent as BoostIcon } from "../../assets/images/boost.svg";
import { ReactComponent as EfficiencyIcon } from "../../assets/images/efficiency.svg";
import { ReactComponent as FlavorIcon } from "../../assets/images/flavor.svg";
import { ReactComponent as StandardIcon } from "../../assets/images/standard.svg";
import { ReactComponent as StealthIcon } from "../../assets/images/stealth.svg";

const dynamicModeIconStyles = css`
  height: 40px;
  width: 40px;
`;

export const StandardMode = styled(StandardIcon)`
  ${dynamicModeIconStyles}
`;

export const BoostMode = styled(BoostIcon)`
  ${dynamicModeIconStyles}
`;

export const EfficiencyMode = styled(EfficiencyIcon)`
  ${dynamicModeIconStyles}
`;

export const StealthMode = styled(StealthIcon)`
  ${dynamicModeIconStyles}
`;

export const FlavorMode = styled(FlavorIcon)`
  ${dynamicModeIconStyles}
`;
