import React from "react";

import InfiniteLoadingList from "../../../shared/components/InfiniteLoadingList";
import { getRowReducer } from "../../../shared/utils";

import { ROW_SPACE } from "../../constants";
import * as hooks from "../../hooks";
import * as SC from "../../styledComponents";
import { MyPodsType } from "../../types";

import { MyPodCard } from "./MyPodCard";
import { MyPodsLoadingContent } from "./MyPodsLoadingContent";
import { NoPods } from "./NoPods";

export type MyFavoritesProps = {
  cardWidth: number;
  columnCount: number;
  rowHeight: number;
  useMyFavorites?: hooks.UseMyFavorites;
  width: number;
};

export const MyFavorites: React.FC<MyFavoritesProps> = ({
  cardWidth,
  columnCount,
  rowHeight,
  useMyFavorites = hooks.useMyFavorites,
  width,
}) => {
  const {
    count,
    pods,
    fetchNextPage,
    hasFetchedFirstPage,
    isFetching,
    nextFetchUrl,
    onHeartClick,
  } = useMyFavorites();

  if (isFetching && !hasFetchedFirstPage) {
    return (
      <MyPodsLoadingContent
        cardWidth={cardWidth}
        columnCount={columnCount}
        rowHeight={rowHeight}
        width={width}
      />
    );
  }

  if (hasFetchedFirstPage && count === 0) {
    return <NoPods />;
  }

  const rows = pods.reduce(getRowReducer(columnCount), []).map((podRow) => (
    <SC.PodsRow height={rowHeight} key={`row-${podRow[0].strain.id}`}>
      {podRow.map((pod) => (
        <MyPodCard
          height={rowHeight - ROW_SPACE}
          key={pod.strain.id}
          onHeartClick={onHeartClick}
          pod={pod}
          podType={MyPodsType.FAVORITES}
          width={cardWidth}
        />
      ))}
    </SC.PodsRow>
  ));

  return (
    <InfiniteLoadingList
      hasMoreItems={!!nextFetchUrl}
      loadMoreRows={fetchNextPage}
      rowHeight={rowHeight}
      rows={rows}
      width={width}
    />
  );
};
