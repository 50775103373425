import * as dateFns from "date-fns";
import React from "react";
import styled from "styled-components";

import { getConnectedPodJson } from "../../../api/consumer/models/ConnectedPod";
import { getStrainJson } from "../../../api/consumer/models/Strain";
import { getStrainReviewJson } from "../../../api/consumer/models/StrainReview";
import * as t from "../../../api/consumer/types/connectedPods";
import * as c from "../../../pods/constants";
import InfiniteLoadingList from "../../../shared/components/InfiniteLoadingList";
import { getRowReducer } from "../../../shared/utils";

import { ROW_SPACE } from "../../constants";
import * as SC from "../../styledComponents";
import { MyPodsType } from "../../types";

import { MyPodCard } from "./MyPodCard";

const placeholderPods: t.ConnectedPodJson[] = [
  getConnectedPodJson({
    latestClientTimestamp: dateFns.add(new Date(), { hours: -2 }).toISOString(),
    latestReview: getStrainReviewJson({ rating: 5 }),
    strain: getStrainJson({
      classification: c.CBD,
      id: "placeholder1",
      name: "Chill CBD",
    }),
  }),
  getConnectedPodJson({
    latestClientTimestamp: dateFns.add(new Date(), { days: -1 }).toISOString(),
    latestReview: getStrainReviewJson({ rating: 5 }),
    strain: getStrainJson({
      classification: c.HYBRID,
      id: "placeholder2",
      name: "Birthday Cake",
    }),
  }),
  getConnectedPodJson({
    latestClientTimestamp: dateFns.add(new Date(), { days: -2 }).toISOString(),
    latestReview: getStrainReviewJson({ rating: 5 }),
    strain: getStrainJson({
      classification: c.SATIVA,
      id: "placeholder3",
      name: "Sour Diesel",
    }),
  }),
  getConnectedPodJson({
    latestClientTimestamp: dateFns.add(new Date(), { days: -7 }).toISOString(),
    latestReview: getStrainReviewJson({ rating: 5 }),
    strain: getStrainJson({
      classification: c.INDICA,
      id: "placeholder4",
      name: "Lavender OG",
    }),
  }),
];

export type MyPodsPlaceholderContentProps = {
  cardWidth: number;
  columnCount: number;
  rowHeight: number;
  width: number;
};

export const MyPodsPlaceholderContent: React.FC<MyPodsPlaceholderContentProps> =
  ({ cardWidth, columnCount, rowHeight, width }) => {
    const rows = placeholderPods
      .reduce(getRowReducer(columnCount), [])
      .map((podRow) => (
        <SC.PodsRow height={rowHeight} key={`row-${podRow[0].strain.id}`}>
          {podRow.map((pod) => (
            <MyPodCard
              height={rowHeight - ROW_SPACE}
              key={pod.strain.id}
              pod={pod}
              podType={MyPodsType.HISTORY}
              width={cardWidth}
            />
          ))}
        </SC.PodsRow>
      ));

    return (
      <sc.ContentContainer data-testid="my-pods-placeholder-pods">
        <InfiniteLoadingList
          hasMoreItems={false}
          loadMoreRows={async () => {}}
          rowHeight={rowHeight}
          rows={rows}
          width={width}
        />
      </sc.ContentContainer>
    );
  };

const sc = {
  ContentContainer: styled.div`
    pointer-events: none;
  `,
};
