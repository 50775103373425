import { Action } from "../../../shared/types";

import { BannerType } from "./FlashBanner";

export const SHOW_FLASH_BANNER = "SHOW_FLASH_BANNER";
export const CLEAR_FLASH_BANNER = "CLEAR_FLASH_BANNER";

export type ShowFlashBanner = (
  message: string,
  bannerType: BannerType,
  noTimeOut?: boolean,
  timeout?: number
) => Action;

export const showFlashBanner = (
  message: string,
  bannerType: BannerType = BannerType.INFO,
  noTimeOut?: boolean,
  timeout?: number
): Action => ({
  payload: { bannerType, message, noTimeOut, timeout },
  type: SHOW_FLASH_BANNER,
});

export const clearFlashBanner = (): Action => ({ type: CLEAR_FLASH_BANNER });
