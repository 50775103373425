import React from "react";
import styled from "styled-components";

import { EraReplayJson } from "../../../pax-ble/types/Era";

import { DOSE_OPTIONS } from "../constants";

export type StateProps = {
  isDeviceLocked?: boolean;
  replay?: EraReplayJson;
};

export const EraDeviceInfoOverlay: React.FC<StateProps> = ({
  isDeviceLocked,
  replay,
  ...props
}) => {
  if (!replay?.isActive || isDeviceLocked) return null;

  return <Overlay label={DOSE_OPTIONS[replay.doseId].label} {...props} />;
};

type OverlayProps = {
  label: string;
};

const Overlay: React.FC<OverlayProps> = ({ label, ...props }) => {
  return (
    <sc.Container data-testid="device-info-overlay" {...props}>
      <sc.TextPill>{label}</sc.TextPill>
    </sc.Container>
  );
};

const sc = {
  Container: styled.div`
    align-items: center;
    backdrop-filter: blur(10px);
    border-radius: 69px;
    display: flex;
    height: 138px;
    justify-content: center;
    width: 138px;
  `,

  TextPill: styled.div`
    background-color: var(--black);
    border-radius: 19px;
    color: var(--white);
    padding: 10px;
    text-transform: uppercase;
  `,
};
