import React, { useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import styled, { css } from "styled-components";

import * as consumerApi from "../../api/consumer";
import * as t from "../../api/consumer/types";
import * as r from "../../main/routes";
import { BannerSibling } from "../../shared/components/BannerContainer/BannerSibling";
import { Column } from "../../shared/components/Flex";
import GoBack from "../../shared/components/GoBack";
import * as sharedHooks from "../../shared/hooks";
import * as text from "../../shared/text";
import { desktopBreakpoint } from "../../shared/utils";

import * as hooks from "../hooks";
import { LegalText } from "../styledComponents";
import { getCoaLegalDisclaimerForGeoState } from "../utils";
import { PodsCannabinoidsCard } from "./PodsCannabinoidsCard";
import PaxCommitmentCard from "./PodsPaxCommitmentCard";
import PodsSafetyTestsCard from "./PodsSafetyTestsCard";
import { PodsStrainInformationCard } from "./PodsStrainInformationCard";
import { PodsTerpenesCard } from "./PodsTerpenesCard";
import { PodsTestingDetailsCard } from "./PodsTestingDetailsCard";
import { BigCard, SmallCard } from "./PodsTestResultsCard";

const MOBILE_HEADER_HEIGHT = 115;
const SCROLL_THRESHOLD = 25;

type TestResultsPathParams = {
  batchId?: string;
  podId?: string;
  strainId?: string;
};
const getTestResultsPath = ({
  podId,
  batchId,
  strainId,
}: TestResultsPathParams): string => {
  if (podId) {
    return consumerApi.paths.pods.podBatchResults({ podId });
  } else if (batchId && strainId) {
    return consumerApi.paths.strains.strainBatchResult({ batchId, strainId });
  }

  throw new Error(
    `Unexpected blank params: podId=${podId}, batchId=${batchId}, strainId=${strainId}`
  );
};

export type PodsTestResultsPageProps = RouteComponentProps<{
  batchId?: string;
  podId?: string;
  strainId?: string;
}> & {
  useReactQuery: consumerApi.hooks.UseReactQuery<t.TestResultJson>;
  useStrainById: hooks.UseStrainById;
};

export const PodsTestResultsPage: React.FC<PodsTestResultsPageProps> = ({
  match,
  useReactQuery = consumerApi.hooks.useReactQuery,
  useStrainById = hooks.useStrainById,
}) => {
  const isDesktop = sharedHooks.useDesktopMediaQuery();
  const isPastScrollThreshold =
    sharedHooks.useScrollThresholdListener(SCROLL_THRESHOLD);
  const testResultsPath = getTestResultsPath(match.params);
  const { data: testResults } = useReactQuery({ path: testResultsPath });
  const { strain } = useStrainById(testResults?.strainId);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let detailsCard = <SmallCard data-testid="loading" borderStyle="dotted" />;
  let cannabinoidsCard = <BigCard data-testid="loading" borderStyle="dotted" />;
  let terpenesCard = <BigCard data-testid="loading" borderStyle="dotted" />;
  let safetyTestsCard = <BigCard data-testid="loading" borderStyle="dotted" />;
  let strainInfoCard = <SmallCard data-testid="loading" borderStyle="dotted" />;
  let goBackArrow = <GoBack to={"/explore/pods"} />;
  if (testResults && testResults.parsedBatchResult) {
    detailsCard = (
      <PodsTestingDetailsCard
        labName={testResults.parsedBatchResult.testLab}
        batchId={testResults.batchId}
        testDate={testResults.parsedBatchResult.testDate}
        strainId={testResults.strainId}
      />
    );
    cannabinoidsCard = (
      <PodsCannabinoidsCard
        cannabinoids={testResults.parsedBatchResult.parsedCannabinoids}
        cbd={testResults.parsedBatchResult.totalCbdPercent}
        thc={testResults.parsedBatchResult.totalThcPercent}
      />
    );
    terpenesCard = (
      <PodsTerpenesCard
        terpenes={testResults.parsedBatchResult.parsedTerpenes}
      />
    );
    safetyTestsCard = (
      <PodsSafetyTestsCard
        tests={testResults.parsedBatchResult.parsedTestResults}
      />
    );
    strainInfoCard = (
      <PodsStrainInformationCard strainId={testResults.strainId} />
    );
    goBackArrow = <sc.GoBack to={r.podDetails(testResults.strainId)} />;
  }

  return (
    <>
      <sc.PageTitleContainer
        $isPastThreshold={isPastScrollThreshold}
        mobileOnly
      >
        {goBackArrow}
        <Column>
          <sc.PageTitle $isPastThreshold={isPastScrollThreshold}>
            {text.POD_TEST_RESULTS}
          </sc.PageTitle>
          {!isDesktop &&
            !isPastScrollThreshold &&
            testResults?.parsedBatchResult?.testLab && (
              <sc.TestedByText>
                {text.TESTED_BY} {testResults.parsedBatchResult.testLab}
              </sc.TestedByText>
            )}
        </Column>
      </sc.PageTitleContainer>
      <sc.CardsContainer>
        {detailsCard}
        {strainInfoCard}
        {cannabinoidsCard}
        {terpenesCard}
        {safetyTestsCard}
        <PaxCommitmentCard />
      </sc.CardsContainer>
      <sc.LegalFooter>
        <sc.LegalText>
          {getCoaLegalDisclaimerForGeoState(strain?.geoState)}
        </sc.LegalText>
      </sc.LegalFooter>
    </>
  );
};

type ScrollProps = {
  $isPastThreshold: boolean;
};

const sc = {
  CardsContainer: styled(Column)`
    margin: 10px auto 0;
    max-width: 500px;
    padding: 0 20px 20px;

    ${desktopBreakpoint(css`
      flex-direction: row;
      flex-wrap: wrap;
      margin: 0 auto;
      max-width: none;
      padding: 0;
      width: 820px;
    `)}
  `,

  GoBack: styled(GoBack)`
    left: 10px;
    position: absolute;
    top: 16px;

    ${desktopBreakpoint(css`
      left: 0;
      position: relative;
      top: 0;
    `)}
  `,

  LegalFooter: styled.div`
    padding: 20px;

    ${desktopBreakpoint(css`
      margin: 30px auto 20px;
      padding: 0;
      width: 820px;
    `)}
  `,

  LegalText: styled(LegalText)`
    border-top: 2px var(--very-light-pink) dotted;
    font-size: 12px;
    padding-top: 20px;

    ${desktopBreakpoint(css`
      border-top: none;
      padding-top: 0;
    `)}
  `,

  PageTitle: styled.div<ScrollProps>`
    font-size: ${({ $isPastThreshold }) =>
      $isPastThreshold ? "20px" : "24px"};
    padding-bottom: 4px;
    text-transform: uppercase;
    transition: 85ms ease-out;

    ${desktopBreakpoint(css`
      font-size: 28px;
      margin-left: 252px;
    `)}
  `,

  PageTitleContainer: styled(BannerSibling)<ScrollProps>`
    align-items: center;
    background-color: var(--white);
    border-bottom: 1px solid var(--very-light-pink);
    display: flex;
    flex: 1;
    height: ${({ $isPastThreshold }) =>
      $isPastThreshold ? "55" : MOBILE_HEADER_HEIGHT}px;
    justify-content: center;
    position: sticky;
    top: 0;
    transition: 85ms ease-out;
    width: 100%;
    z-index: var(--z-index-sticky-headers);

    ${desktopBreakpoint(css`
      border-bottom: none;
      height: 48px;
      justify-content: flex-start;
      margin: 61px auto 32px;
      max-width: 805px;
      position: relative;
    `)}
  `,

  TestedByText: styled.div`
    color: var(--gunmetal);
    font-size: 16px;
    font-style: italic;
    margin-top: 13px;
    text-align: center;
  `,
};
