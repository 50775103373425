// TODO: remove use of any
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as CSS from "csstype";
import React, { ReactNode } from "react";
import { Index, InfiniteLoader, List, WindowScroller } from "react-virtualized";

import "react-virtualized/styles.css";

type InfiniteLoadingListProps = {
  hasMoreItems: boolean;
  listStyle?: CSS.Properties;
  loadMoreRows: () => Promise<any>;
  rows: ReactNode[];
  rowHeight: number;
  width: number;
  overscanRowCount?: number;
};

type RowRendererProps = {
  index: number;
  key: string;
  style: any;
};

const InfiniteLoadingList: React.FC<InfiniteLoadingListProps> = ({
  hasMoreItems,
  listStyle,
  loadMoreRows,
  overscanRowCount = 1,
  rowHeight,
  rows,
  width,
}) => {
  listStyle = {
    outline: "none",
    ...listStyle,
  };

  // Add an empty row to induce a fetch when the empty row is reached.
  const infiniteRowCount = hasMoreItems ? rows.length + 1 : rows.length;

  const isRowLoaded = ({ index }: Index): boolean => {
    return !!rows[index];
  };

  const RowRenderer: React.FC<RowRendererProps> = ({ index, key, style }) => {
    return (
      <div key={key} style={style}>
        {rows[index]}
      </div>
    );
  };

  return (
    <InfiniteLoader
      isRowLoaded={isRowLoaded}
      loadMoreRows={loadMoreRows}
      rowCount={infiniteRowCount}
    >
      {({ onRowsRendered, registerChild }) => (
        <WindowScroller>
          {({ height, isScrolling, onChildScroll, scrollTop }) => {
            return (
              <List
                autoHeight
                className="pod-list"
                height={height}
                isScrolling={isScrolling}
                noContentRenderer={() => "No pods match your search"}
                onRowsRendered={onRowsRendered}
                onScroll={onChildScroll}
                overscanRowCount={overscanRowCount}
                ref={registerChild}
                rowCount={rows.length}
                rowHeight={rowHeight}
                rowRenderer={RowRenderer}
                scrollTop={scrollTop}
                style={listStyle}
                width={width}
              />
            );
          }}
        </WindowScroller>
      )}
    </InfiniteLoader>
  );
};

export default InfiniteLoadingList;
