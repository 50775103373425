export enum Action {
  READ_CURRENT_STATE = 0,
  READ_PETAL_CONFIG = 1,
  WRITE_CONFIG = 2,
  SET_ACTIVE_PETAL = 3,
  RESET = 4,
}

export enum UiFunction {
  PETAL_1 = 1,
  PETAL_2 = 2,
  PETAL_3 = 3,
  PETAL_4 = 4,
  CUSTOM = 255,
}

export enum PodType {
  D3 = 0,
  D3N = 2,
  MAPLE = 3,
  BIRCH = 4,
}

export enum DoseSize {
  NONE = 0,
  MICRO = 1,
  SMALL = 2,
  MEDIUM = 3,
  LARGE = 4,
}

type HeatingCurvePoint = {
  milliseconds: number;
  tempC10: number;
};

export type Configuration = {
  action?: Action;
  doseLockoutTime: number;
  dosePulse: 0 | 1;
  doseSize: DoseSize;
  heatingCurve: HeatingCurvePoint[];
  maxPressureDiff: number;
  maxTempC10: number;
  podRev: PodType;
  pressureSlope: number;
  uiFunction: UiFunction;
};

export type WriteConfigurationOptions = {
  setPetalConfiguration?: boolean;
  useActiveDosePulse?: boolean;
  useActiveDoseSize?: boolean;
};
