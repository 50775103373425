import React from "react";
import styled from "styled-components";

import MobileThermostat from "../../device/containers/MobileThermostat/MobileThermostat";

import FlowerDynamicModes from "../containers/Thermostat/FlowerDynamicModes";
import FlowerOvenState from "../containers/Thermostat/FlowerOvenState";

type FlowerDeviceControlsMobileProps = {
  isDeviceLocked: boolean;
};

export const FlowerDeviceControlsMobile: React.FC<FlowerDeviceControlsMobileProps> =
  ({ isDeviceLocked, ...props }) => {
    return (
      <>
        {isDeviceLocked && <sc.LockedText>Device Locked</sc.LockedText>}
        <sc.MobileContentContainer {...props}>
          <MobileThermostat />
          <FlowerOvenState isDeviceLocked={isDeviceLocked} />
          <FlowerDynamicModes isDeviceLocked={isDeviceLocked} />
        </sc.MobileContentContainer>
      </>
    );
  };

const sc = {
  LockedText: styled.div`
    color: var(--pale-red);
    font-size: 20px;
    margin-top: 15px;
    text-align: center;
    text-transform: uppercase;
  `,

  MobileContentContainer: styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
  `,
};
