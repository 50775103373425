import React from "react";
import styled, { css, SimpleInterpolation } from "styled-components";

import { desktopBreakpoint } from "../../shared/utils";

type MyPaxCardProps = {
  imageSrc: string;
  title: string;
};

export const MyPaxCard: React.FC<MyPaxCardProps> = ({
  imageSrc,
  title,
  ...props
}) => {
  return (
    <sc.Card imageSrc={imageSrc} {...props}>
      <sc.Title>{title}</sc.Title>
    </sc.Card>
  );
};

type CardProps = {
  imageSrc: string;
};

const sc = {
  Card: styled.div<CardProps>`
    background-color: var(--very-light-pink-4);
    background-image: url("${({ imageSrc }): SimpleInterpolation => imageSrc}");
    background-position: center;
    background-size: cover;
    border: solid 1px var(--very-light-pink);
    border-radius: 8px;
    height: 197px;
    margin: 4px 0;
    max-width: ${(props) => props.theme.myPax.pageContentWidth};
    position: relative;
    width: calc(100vw - 32px);

    ${desktopBreakpoint(
      css`
        height: 240px;
        margin: 4px;
        width: 415px;
      `
    )}
  `,

  Title: styled.div`
    color: var(--black);
    font-size: 18px;
    left: 20px;
    position: absolute;
    text-transform: uppercase;
    top: 20px;
    width: 0;

    @media (min-width: 400px) {
      width: 348px;
    }

    ${desktopBreakpoint(
      css`
        font-size: 18px;
      `
    )}
  `,
};
