import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Redirect, RouteComponentProps } from "react-router-dom";
import styled, { css } from "styled-components";

import * as userSelectors from "../../../api/consumer/selectors/user";
import * as r from "../../../main/routes";
import { BannerSibling } from "../../../shared/components/BannerContainer/BannerSibling";
import { Row } from "../../../shared/components/Flex";
import GoBack from "../../../shared/components/GoBack";
import * as Text from "../../../shared/components/Text";
import { useDesktopMediaQuery } from "../../../shared/hooks";
import * as text from "../../../shared/text";
import { desktopBreakpoint } from "../../../shared/utils";

import * as c from "../../constants";
import { useMyPodsMobileColumn } from "../../hooks";
import { Page } from "../../styledComponents";
import { MyPodsType } from "../../types";

import { SignInHeader } from "../SignInHeader";
import { MyFavorites } from "./MyFavorites";
import { MyHistory } from "./MyHistory";
import { MyPodsPlaceholderContent } from "./MyPodsPlaceholderContent";
import { MyPodsTabBar } from "./MyPodsTabBar";

export type MyPodsProps = RouteComponentProps<{
  selectedMyPodsType: string;
}>;

export const MyPods: React.FC<MyPodsProps> = ({ match }) => {
  const initialPodsType = match.params.selectedMyPodsType as MyPodsType;
  const isDesktop = useDesktopMediaQuery();
  const mobileWidth = useMyPodsMobileColumn();
  const [podsType, setPodsType] = useState<MyPodsType>(initialPodsType);
  const hasFetchedUser = useSelector(userSelectors.getHasFetchedUser);
  const isSignedIn = useSelector(userSelectors.getIsUserSignedIn);

  if (podsType !== initialPodsType) {
    return <Redirect to={r.accountPods(podsType)} />;
  }

  const cardWidth = isDesktop ? c.DESKTOP_CARD_WIDTH : mobileWidth - 8;
  const columnCount = isDesktop ? c.DESKTOP_COLUMNS_COUNT : 1;
  const rowHeight = isDesktop ? c.DESKTOP_GRID_ROW_HEIGHT : c.MOBILE_ROW_HEIGHT;
  const width = isDesktop ? c.DESKTOP_GRID_WIDTH : mobileWidth;
  const shouldSignIn = hasFetchedUser && !isSignedIn;

  let content;
  if (shouldSignIn) {
    content = (
      <MyPodsPlaceholderContent
        cardWidth={cardWidth}
        columnCount={columnCount}
        rowHeight={rowHeight}
        width={width}
      />
    );
  } else if (podsType === MyPodsType.HISTORY) {
    content = (
      <MyHistory
        cardWidth={cardWidth}
        columnCount={columnCount}
        rowHeight={rowHeight}
        width={width}
      />
    );
  } else {
    content = (
      <MyFavorites
        cardWidth={cardWidth}
        columnCount={columnCount}
        rowHeight={rowHeight}
        width={width}
      />
    );
  }

  return (
    <sc.Page>
      <sc.TitleContainer>
        <sc.TitleRow alignItems="center">
          {!isDesktop && <GoBack to={r.account()} />}
          <sc.Title>{text.MY_PODS}</sc.Title>
        </sc.TitleRow>
        <MyPodsTabBar
          selectedMyPodsType={podsType}
          setSelectedPodsType={setPodsType}
        />
      </sc.TitleContainer>
      {shouldSignIn && (
        <SignInHeader returnTo={r.accountPods(initialPodsType)} />
      )}
      <sc.ContentContainer disabled={shouldSignIn}>
        {content}
      </sc.ContentContainer>
    </sc.Page>
  );
};

type ContainerProps = {
  disabled: boolean;
};

const sc = {
  ContentContainer: styled.div<ContainerProps>`
    margin: 10px auto 0;
    opacity: ${({ disabled }) => (disabled ? "0.36" : "1")};
    pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};

    ${desktopBreakpoint(
      css`
        margin: 20px 0 0;
        min-height: 408px;
      `
    )}
  `,

  Page: styled(Page)`
    align-items: center;
    padding: 0 8px 16px;

    ${desktopBreakpoint(
      css`
        padding: 0;
      `
    )}
  `,

  Title: styled.div`
    ${Text.PlaaxBold}
    font-size: 14px;
    line-height: 20px;
    margin: 0 auto;
    position: relative;
    right: 26px;
    text-transform: capitalize;

    ${desktopBreakpoint(
      css`
        font-size: 24px;
        line-height: 30px;
        margin: 0;
        right: 0;
      `
    )}
  `,

  TitleContainer: styled(BannerSibling)`
    background-color: var(--white);
    box-shadow: 0 1px 6px var(--bluey-grey-30);
    position: sticky;
    width: 100vw;
    z-index: var(--z-index-fixed-header);

    ${desktopBreakpoint(
      css`
        box-shadow: none;
        width: 100%;
      `
    )}
  `,

  TitleRow: styled(Row)`
    box-shadow: 0 1px 6px var(--bluey-grey-30);
    height: 56px;
    margin: 0 auto;
    padding-left: 16px;
    width: 100%;

    ${desktopBreakpoint(
      css`
        box-shadow: none;
        padding-left: 0;
      `
    )}
  `,
};
