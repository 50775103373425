import { createSelector } from "reselect";

import * as cookies from "../../../cookies";
import { TEMPERATURE_UNITS } from "../../../data/temperatureUnits";
import { MainAppState } from "../../../main/types";
import { MAX_RECOMMENDED_SEARCHES } from "../../../pods/constants";

import * as t from "../types";
import { UserType } from "../types/user";

export type GetAuthenticationToken = () => string;

export const getAuthenticationToken: GetAuthenticationToken = () =>
  cookies.getLoginCookie() || cookies.getAnonymousUserCookie();

export const getUserPeripheralId = (): string =>
  cookies.getRegisteredUserPeripheralId() ||
  cookies.getAnonymousUserPeripheralId();

export const getUserUuid = (): string =>
  cookies.getRegisteredUserUuid() || cookies.getAnonymousUserUuid() || "";

export const getThemes = (state: MainAppState): t.user.Theme[] =>
  state.consumerApi.themes || [];

export const getIsFetchingUser = (state: MainAppState): boolean =>
  state.consumerApi.isFetchingUser;

export const getHasFetchedUser = (state: MainAppState): boolean =>
  state.consumerApi.hasFetchedUser;

export const getIsUserSignedIn = (state: MainAppState): boolean =>
  !!state.sso.login.success &&
  state.consumerApi.user.type !== UserType.ANONYMOUS;

export const getUser = (state: MainAppState): t.user.User =>
  state.consumerApi.user;

export const getProfileTheme = (
  state: MainAppState
): t.user.Theme | undefined => state.consumerApi.user.profile?.theme;

export const getUserNeedsVerification = createSelector(getUser, (user) => {
  return user.type === UserType.REGISTERED && !user.hasVerifiedEmail;
});

export const getUserEmail = (state: MainAppState): string =>
  state.consumerApi.user.email || "";

export const getUserLocationCode = (state: MainAppState): string => {
  return (
    state.consumerApi.user.location?.isoCode ||
    state.consumerApi.user.location?.region ||
    ""
  );
};

export const getUserLocation = (
  state: MainAppState
): t.user.UserLocation | undefined => {
  return state.consumerApi.user.location;
};

export const getIsUserInUS = createSelector([getUserLocation], (location) => {
  return location?.region === "US";
});

export const getPreferredTemperatureLabel = (
  state: MainAppState
): string | undefined => {
  if (!state.consumerApi.user.profile?.preferredTemperatureUnit)
    return undefined;

  const { label } =
    TEMPERATURE_UNITS[state.consumerApi.user.profile.preferredTemperatureUnit];

  return label;
};

const getRecentSearches = (state: MainAppState): string[] =>
  state.consumerApi.user.recentSearches;

export const getUniqueRecentSearches = createSelector(
  [getRecentSearches],
  (recentSearches) => {
    // Unique array with 5 most recent searches. Preserves order with most recent first.
    return Array.from(new Set(recentSearches)).slice(
      0,
      MAX_RECOMMENDED_SEARCHES
    );
  }
);

const getRecentlyViewedPods = (state: MainAppState): t.StrainJson[] =>
  state.consumerApi.user.recentlyViewedPods;

export const getUniqueRecentlyViewedPods = createSelector(
  [getRecentlyViewedPods],
  (recentlyViewedPods) => {
    // Unique array by strain id. Preserves order with most recent first.
    return Array.from(
      new Map(recentlyViewedPods.map((strain) => [strain.id, strain])).values()
    );
  }
);

export const getShareDataFlagForUser = createSelector([getUser], (user) => {
  return !!user?.dataSharingPreferences?.shareUsageData;
});

export const getIsShareDataFlagForUserUnset = createSelector(
  [getUser],
  (user) => {
    return (
      user?.dataSharingPreferences?.shareUsageData === undefined ||
      user?.dataSharingPreferences?.shareUsageData === null
    );
  }
);
