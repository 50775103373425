import { combineReducers } from "redux";

import { reducer as analytics } from "../analytics/reducer";
import { reducer as announcements } from "../announcements/reducer";
import { reducer as consumerApi } from "../api/consumer/reducer";
import { reducer as device } from "../device/reducer";
import { reducer as findMyPax } from "../find-my-pax/reducer";
import { reducer as firmware } from "../firmware/reducer";
import { reducer as mailingList } from "../mailing-list/reducer";
import { reducer as modal } from "../modal/reducer";
import { reducer as pods } from "../pods/reducer";
import { reducer as flashBanner } from "../shared/components/FlashBanner/reducer";
import { reducer as sso } from "../sso/reducer";

export const reducer = combineReducers({
  analytics,
  announcements,
  consumerApi,
  device,
  findMyPax,
  firmware,
  flashBanner,
  mailingList,
  modal,
  pods,
  sso,
});
