import React from "react";
import styled from "styled-components";

import * as Text from "../../../shared/components/Text";

type ModuleCardProps = {
  isActive?: boolean;
  isLarge?: boolean;
  onClick?: (e: React.MouseEvent) => void;
  title?: string;
  width?: number;
};

export const ModuleCard: React.FC<ModuleCardProps> = ({
  children,
  isActive = false,
  isLarge = false,
  title,
  width,
  ...props
}) => {
  return (
    <sc.Container
      isActive={isActive}
      isLarge={isLarge}
      width={width}
      {...props}
    >
      {title && <sc.Title isActive={isActive}>{title}</sc.Title>}
      {children}
    </sc.Container>
  );
};

type ContainerProps = {
  isActive: boolean;
  isLarge: boolean;
  width?: number;
};

type TitleProps = {
  isActive: boolean;
};

const sc = {
  Container: styled.div<ContainerProps>`
    background-color: ${({ isActive }) =>
      isActive ? "var(--black-80)" : "var(--white-80)"};
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    height: ${({ isLarge }) =>
      isLarge
        ? "var(--experience-modes-large-module-height)"
        : "var(--experience-modes-small-module-size)"};
    margin: 8px;
    min-width: ${({ isLarge }) =>
      isLarge
        ? "var(--experience-modes-large-module-width)"
        : "var(--experience-modes-small-module-size)"};
    width: ${({ width }) =>
      width ? width + "px" : "var(--experience-modes-small-module-size)"};
  `,

  Title: styled.div<TitleProps>`
    ${Text.PlaaxBold}
    color: ${({ isActive }) => (isActive ? "var(--white)" : "var(--black)")};
    font-size: 12px;
    margin: 12px 0 0 12px;
  `,
};
