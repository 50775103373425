import React, { FormEvent } from "react";
import { Link as ReactLink } from "react-router-dom";
import styled from "styled-components";

import PaxLogo from "../../assets/images/pax-logo.svg";
import { Button as SharedButton } from "../../shared/components/Button";
import * as Text from "../../shared/components/Text";

type FormProps = {
  children: React.ReactNode;
  onSubmit?: (e: FormEvent<HTMLFormElement>) => void;
};

export const Form: React.FC<FormProps> = ({ children, ...props }) => {
  return (
    <FormContainer>
      <FormHeader>
        <Logo src={PaxLogo} alt="PAX" data-testid="pax-logo" />
      </FormHeader>
      <form {...props}>{children}</form>
    </FormContainer>
  );
};

const FormContainer = styled.div`
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 1px 8px 0 var(--silver);
  padding: 40px 52px;
  width: 420px;

  @media (max-width: 438px) {
    border-radius: 0px;
    height: 100%;
    padding-top: 40px;
    width: 100%;
  }
`;

const FormHeader = styled.div`
  margin-bottom: 40px;
  text-align: center;
`;

const Logo = styled.img`
  width: 112px;
`;

export const Button = styled(SharedButton)`
  display: block;
  margin: 0 auto;
  min-width: 148px;
  padding: 5px 20px;
`;

export const ButtonsContainer = styled.div`
  margin: 42px auto 60px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const Field = styled.div`
  margin-bottom: 20px;
`;

export const Footer = styled.div`
  ${Text.PlaaxLight}
  color: var(--black);
  font-size: 14px;
  margin-top: 20px;
  text-align: center;

  a {
    color: var(--black);
  }
`;

type InputProps = {
  hasError?: boolean;
};

export const Input = styled.input<InputProps>`
  ${Text.PlaaxLight}

  border: ${(props): string =>
    props.hasError ? "2px solid var(--pale-red)" : "1px solid var(--black)"};
  border-radius: 4px;
  height: 40px;
  margin-bottom: 12px;
  outline: none;
  padding: 10px;
  width: 316px;

  @media (max-width: 438px) {
    width: 100%;
  }

  &:hover,
  &:active {
    border: ${(props): string =>
      props.hasError
        ? "2px solid var(--pale-red)"
        : "2px solid var(--dodger-blue)"};
  }

  &:disabled {
    background-color: var(--white);
    border: 1px solid var(--very-light-pink-2);
    color: var(--black);
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const PasswordInput = styled(Input)`
  margin-bottom: 0;
`;

export const Label = styled.label`
  display: block;
  font-weight: 500;
  margin-bottom: 12px;
`;

const LinkSpan = styled.span`
  ${Text.Underline}
  color: var(--black);
  cursor: pointer;
  display: block;
  font-size: 14px;
  margin: 10px 0;

  &:hover {
    font-weight: bold;
  }
`;

type LinkProps = {
  to: string;
  children: React.ReactChild;
  "data-testid"?: string;
  onClick?: () => void;
};

export const Link: React.FC<LinkProps> = ({ children, ...props }) => (
  <ReactLink {...props}>
    <LinkSpan>{children}</LinkSpan>
  </ReactLink>
);

export const Message = styled.div`
  ${Text.PlaaxItalicLight}
  font-size: 14px;
  margin-top: 20px;
  text-align: center;
`;

export const P = styled.p`
  font-size: 16px;
`;

export const Subtitle = styled.p`
  ${Text.PlaaxLight}
  font-size: 16px;
  margin: 12px 0 40px 0;
`;

export const Title = styled.h4`
  font-size: 24px;
  font-weight: normal;
  margin: 2px 0;
`;
