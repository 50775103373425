import styled, { css } from "styled-components";

import { IS_TEST } from "../appConstants";
import * as icons from "../shared/components/Icons";
import * as Text from "../shared/components/Text";
import { desktopBreakpoint } from "../shared/utils";
import * as c from "./constants";

type SubtitleProps = {
  bold?: boolean;
  isClickable?: boolean;
  marginTop?: number;
};

export const Subtitle = styled.div<SubtitleProps>`
  ${Text.Plaax}
  cursor: ${({ isClickable }) => (isClickable ? "pointer" : "unset")};
  font-size: 14px;
  font-weight: ${({ bold }) => (bold ? "700" : "400")};
  line-height: 20px;
  text-decoration: ${({ isClickable }) => (isClickable ? "underline" : "none")};

  ${({ marginTop }) => (marginTop ? `margin-top: ${marginTop}px;` : "")}
`;

export const Title = styled.div`
  ${Text.PlaaxBold}
  font-size: 20px;
  line-height: 28px;
`;

type ContainerProps = {
  $desktopWidth?: number;
};

type ContentProps = {
  $opacity: number;
};

export const ModalContentStyles = {
  Body: styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    padding-top: 1px;
  `,

  Close: styled(icons.Clear)`
    cursor: pointer;
    height: 20px;
    position: absolute;
    right: 18px;
    top: 18px;
    width: 20px;
    z-index: var(--z-index-modal-closer);
  `,

  Container: styled.div<ContainerProps>`
    background-color: var(--white);
    border-radius: 0;
    padding-bottom: 32px;
    width: 100vw;

    ${desktopBreakpoint(css<ContainerProps>`
      transition: width ${c.MODAL_TRANSITION_DURATION}ms ease;
      width: ${({ $desktopWidth }) => ($desktopWidth ? $desktopWidth : 0)}px;
    `)}
  `,

  Content: styled.div<ContentProps>`
    opacity: ${({ $opacity }) => $opacity};
    pointer-events: ${({ $opacity }) =>
      IS_TEST || $opacity > 0 ? "auto" : "none"};
    transition: opacity 100ms ease-in-out;
  `,

  Header: styled.div`
    align-items: center;
    background: var(--white);
    border-bottom: solid 1px var(--very-light-pink-5);
    display: flex;
    height: 52px;
    justify-content: center;
    position: sticky;
    top: 0;
    z-index: var(--z-index-modal-header);
  `,

  HeaderTitle: styled(Title)`
    font-size: 14px;
    line-height: 20px;
  `,
};
