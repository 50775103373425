import React from "react";
import { isMobile } from "react-device-detect";
import styled, { css } from "styled-components";

import Button, { SecondaryButton } from "../../shared/components/Button";
import { Column } from "../../shared/components/Flex";
import * as Text from "../../shared/components/Text";
import { desktopBreakpoint } from "../../shared/utils";

import { FirmwareModalContentProps, FirmwareStep } from "../types";

export const FirmwareUpdateConfirmationModalContent: React.FC<FirmwareModalContentProps> =
  ({ onStepCompleted }) => {
    const handleButtonClick = (shouldSkip: boolean): void => {
      onStepCompleted(FirmwareStep.UPDATE_CONFIRMATION, shouldSkip);
    };

    const updateMessage = `Place your device next to your ${
      isMobile ? "phone" : "computer"
    } during the entire update to make
  sure everything goes smoothly.`;

    return (
      <sc.Container>
        <sc.Title data-testid="firmware-update-confirmation-title">
          Firmware Update
        </sc.Title>
        <sc.TextContainer>
          <sc.Message>
            We are about to update your firmware to make sure you get the latest
            and greatest.
          </sc.Message>
          <sc.Message>{updateMessage}</sc.Message>
        </sc.TextContainer>
        <sc.ButtonContainer>
          <SecondaryButton
            data-testid="skip-button"
            onClick={(): void => handleButtonClick(true)}
          >
            Skip
          </SecondaryButton>
          <Button
            data-testid="continue-button"
            onClick={(): void => handleButtonClick(false)}
          >
            Continue
          </Button>
        </sc.ButtonContainer>
      </sc.Container>
    );
  };

const sc = {
  ButtonContainer: styled.div`
    font-size: 16px;
    margin-bottom: 60px;
    margin-top: 130px;

    > button:first-child {
      margin-right: 60px;
    }

    ${desktopBreakpoint(
      css`
        margin-top: 166px;
      `
    )}
  `,

  Container: styled(Column)`
    align-items: center;
    justify-content: center;
    margin: auto;
    max-width: 424px;
    padding: 0 40px;

    ${desktopBreakpoint(
      css`
        height: 588px;
        max-width: none;
        width: 424px;
      `
    )}
  `,

  Message: styled.div`
    ${Text.PlaaxLight}
    color: var(--black);
    line-height: 1;
    margin-top: 20px;
    text-align: left;
  `,

  TextContainer: styled.div`
    font-size: 18px;
    height: 200px;
    margin-top: 32px;

    ${desktopBreakpoint(
      css`
        width: 340px;
      `
    )}
  `,

  Title: styled.div`
    ${Text.Plaax}
    font-size: 24px;
    margin-top: 60px;
    text-transform: uppercase;
  `,
};
