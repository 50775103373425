import React from "react";
import styled from "styled-components";

type FormValidationTooltipProps = {
  error?: string;
};

export const FormValidationTooltip: React.FC<FormValidationTooltipProps> = ({
  error,
}) => {
  if (!error) return null;
  return (
    <sc.Container data-testid="form-validation-tooltip">
      <sc.Inner>{error}</sc.Inner>
    </sc.Container>
  );
};

const sc = {
  Container: styled.div`
    left: 50%;
    position: absolute;
    top: 33px;
  `,
  Inner: styled.div`
    background-color: var(--black);
    border-radius: 8px;
    box-shadow: 0px 5px 8px 0px var(--black-50);
    color: var(--white);
    left: -50%;
    padding: 5px 8px;
    position: relative;
    white-space: nowrap;

    &::after {
      border-bottom-color: var(--black);
      border-style: solid;
      border-width: 5px;
      bottom: 100%;
      content: " ";
      left: 50%;
      margin-left: -5px;
      position: absolute;
    }
  `,
};
