import Bugsnag from "@bugsnag/js";
import { History } from "history";

import * as consumerApi from "../api/consumer";
import * as apiTypes from "../api/consumer/types";
import { FetchStrainOptions } from "../api/consumer/types/strain";
import * as routes from "../main/routes";
import { Action, AppThunk } from "../shared/types";
import * as strainsFilter from "./models/StrainsFilter";
import { StrainsFilter } from "./types";

export const FETCH_STRAINS_ERROR = "FETCH_STRAINS_ERROR";
export const FETCH_STRAINS_START = "FETCH_STRAINS_START";
export const FETCH_STRAINS_SUCCESS = "FETCH_STRAINS_SUCCESS";
export const INCREMENT_CLICKED_POD_COUNT = "INCREMENT_CLICKED_POD_COUNT";
export const SET_USER_LOCATION_UNDETERMINED = "SET_USER_LOCATION_UNDETERMINED";
export const SET_USER_LOCATION_DETERMINED = "SET_USER_LOCATION_DETERMINED";
export const SET_STRAINS_FILTER = "SET_STRAINS_FILTER";

export type FetchStrains = (fetchOptions: FetchStrainOptions) => AppThunk;

export const fetchStrains: FetchStrains =
  ({ filter, page, pageSize = 30 }): AppThunk =>
  async (dispatch): Promise<void> => {
    const path = consumerApi.paths.strains.publicStrains({
      filter,
      page,
      pageSize,
    });

    dispatch({
      payload: { path },
      type: FETCH_STRAINS_START,
    });

    try {
      const response = await consumerApi.fetch<
        apiTypes.CollectionResponse<apiTypes.StrainJson>
      >(path);

      dispatch(receivedStrains(response));
    } catch (e) {
      dispatch(fetchStrainsError());
      Bugsnag.notify(e as Error);
    }
  };

const fetchStrainsError = (): Action => ({
  type: FETCH_STRAINS_ERROR,
});

export type FetchStrainsFromUrl = (url: string) => void;

export const fetchStrainsFromUrl: FetchStrainsFromUrl =
  (url) =>
  async (dispatch: React.Dispatch<Action>): Promise<void> => {
    const response = await consumerApi.fetch<
      apiTypes.CollectionResponse<apiTypes.StrainJson>
    >(url, { fullUrl: true });

    dispatch(receivedStrains(response));
  };

type IncrementClickedPodCount = () => Action;

export const incrementClickedPodCount: IncrementClickedPodCount = () => ({
  type: INCREMENT_CLICKED_POD_COUNT,
});

export const receivedStrains = (
  response: apiTypes.CollectionResponse<apiTypes.StrainJson>
): Action => ({
  payload: { response },
  type: FETCH_STRAINS_SUCCESS,
});

type ResetStrainsFilter = () => Action;

export const resetStrainsFilter: ResetStrainsFilter = () => {
  const filter = strainsFilter.buildStrainsFilter({});

  return { payload: { filter }, type: SET_STRAINS_FILTER };
};

export type SetStrainsFilterAndRedirect = (
  history: History,
  filterPartial: Partial<StrainsFilter>
) => Action;

export const setStrainsFilterAndRedirect: SetStrainsFilterAndRedirect = (
  history,
  filterPartial
) => {
  const filter = strainsFilter.buildStrainsFilter(filterPartial);

  const route = strainsFilter.isStrainsFilterEmpty(filter)
    ? routes.podsHome()
    : routes.podsSearch(filter);

  history.replace(route);

  return { payload: { filter }, type: SET_STRAINS_FILTER };
};
