import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import styled, { css, SimpleInterpolation } from "styled-components";

import { PreferredTemperatureUnit } from "../../../api/consumer/types/user";
import * as Arrow from "../../../shared/components/Arrow";
import { Row } from "../../../shared/components/Flex";
import * as Text from "../../../shared/components/Text";

import { setHeaterSetPoint } from "../../actions";
import { PRESET_TEMP_BUFFER } from "../../constants";

type PresetTemperatureDisplayType = {
  label: string;
  description: string;
};

type PresetTemperatureDisplayMapType = {
  [key: string]: {
    label: string;
    description: string;
  };
};

const presetTemperatureDisplayMap: PresetTemperatureDisplayMapType = {
  expertTemp: {
    description:
      "is the pod preset for optimal temperature for this strain as chosen by the extractor.",
    label: "ExpertTemp™",
  },
  idealFlavor: {
    description:
      "is the best temperature to taste and smell the full terpene profile of this strain as chosen by the extractor.",
    label: "Ideal Flavor",
  },
  idealVapor: {
    description:
      "is the best temperature for increased vapor production as chosen by the extractor.",
    label: "Ideal Vapor",
  },
};

const ARROW_SIZE = 6;

type TemperatureInfoProps = {
  deviceTemperature?: number;
  idealFlavorTemperature?: number | null;
  expertTemperature?: number | null;
  idealVaporTemperature?: number | null;
  temperatureUnit: PreferredTemperatureUnit;
};

const TemperatureInfo: React.FC<TemperatureInfoProps> = ({
  deviceTemperature = 0,
  idealFlavorTemperature,
  expertTemperature,
  idealVaporTemperature,
  temperatureUnit,
}) => {
  const dispatch = useDispatch();
  const [activePresetTemperature, setActivePresetTemperature] =
    useState<PresetTemperatureDisplayType | null>();
  const [hoverPresetTemperature, setHoverPresetTemperature] =
    useState<PresetTemperatureDisplayType | null>();

  useEffect(() => {
    if (
      expertTemperature &&
      Math.abs(expertTemperature - deviceTemperature) <= PRESET_TEMP_BUFFER
    ) {
      setActivePresetTemperature(presetTemperatureDisplayMap.expertTemp);
      return;
    }

    if (
      idealFlavorTemperature &&
      Math.abs(idealFlavorTemperature - deviceTemperature) <= PRESET_TEMP_BUFFER
    ) {
      setActivePresetTemperature(presetTemperatureDisplayMap.idealFlavor);
      return;
    }

    if (
      idealVaporTemperature &&
      Math.abs(idealVaporTemperature - deviceTemperature) <= PRESET_TEMP_BUFFER
    ) {
      setActivePresetTemperature(presetTemperatureDisplayMap.idealVapor);
      return;
    }

    setActivePresetTemperature(null);
  }, [
    deviceTemperature,
    idealFlavorTemperature,
    idealVaporTemperature,
    expertTemperature,
  ]);

  if (!idealFlavorTemperature && !expertTemperature && !idealVaporTemperature) {
    return (
      <>
        <sc.ArrowRow>
          <sc.FlavorText>More Flavor</sc.FlavorText>
          <sc.ArrowLeft size={ARROW_SIZE} />
          <sc.ArrowLine size={ARROW_SIZE} />
          <sc.ArrowRight size={ARROW_SIZE} />
          <sc.FlavorText>More Vapor</sc.FlavorText>
        </sc.ArrowRow>
        <sc.P>
          Generally, you will get more flavor from your pods at lower
          temperatures, and more vapor at higher temperatures. Not sure what’s
          right for you? Start low and go slow.
        </sc.P>
      </>
    );
  }

  const handleTemperatureClick = (temperature: number): void => {
    dispatch(setHeaterSetPoint(temperature, temperatureUnit));
  };

  const renderTemperatureText = (): React.ReactElement => {
    if (hoverPresetTemperature) {
      return (
        <>
          <Text.Bold>{hoverPresetTemperature.label} </Text.Bold>
          {hoverPresetTemperature.description}
        </>
      );
    }

    if (activePresetTemperature) {
      return (
        <>
          <Text.Bold>{activePresetTemperature.label} </Text.Bold>
          {activePresetTemperature.description}
        </>
      );
    }

    return (
      <>
        <Text.Bold>{presetTemperatureDisplayMap.expertTemp.label} </Text.Bold>
        {presetTemperatureDisplayMap.expertTemp.description}
      </>
    );
  };

  return (
    <>
      <sc.PresetTemperatureRow>
        {idealFlavorTemperature && (
          <div>
            <sc.PresetTemperature
              onClick={(): void =>
                handleTemperatureClick(idealFlavorTemperature)
              }
              onMouseEnter={(): void =>
                setHoverPresetTemperature(
                  presetTemperatureDisplayMap.idealFlavor
                )
              }
              onMouseLeave={(): void => setHoverPresetTemperature(null)}
              isActive={
                activePresetTemperature ===
                presetTemperatureDisplayMap.idealFlavor
              }
            >
              {idealFlavorTemperature}°
            </sc.PresetTemperature>
            <sc.PresetTemperatureLabel>Ideal Flavor</sc.PresetTemperatureLabel>
          </div>
        )}
        {expertTemperature && (
          <div>
            <sc.PresetTemperature
              onClick={(): void => handleTemperatureClick(expertTemperature)}
              onMouseEnter={(): void =>
                setHoverPresetTemperature(
                  presetTemperatureDisplayMap.expertTemp
                )
              }
              onMouseLeave={(): void => setHoverPresetTemperature(null)}
              isActive={
                activePresetTemperature ===
                presetTemperatureDisplayMap.expertTemp
              }
            >
              {expertTemperature}°
            </sc.PresetTemperature>
            <sc.PresetTemperatureLabel>ExpertTemp™</sc.PresetTemperatureLabel>
          </div>
        )}
        {idealVaporTemperature && (
          <div>
            <sc.PresetTemperature
              onClick={(): void =>
                handleTemperatureClick(idealVaporTemperature)
              }
              onMouseEnter={(): void =>
                setHoverPresetTemperature(
                  presetTemperatureDisplayMap.idealVapor
                )
              }
              onMouseLeave={(): void => setHoverPresetTemperature(null)}
              isActive={
                activePresetTemperature ===
                presetTemperatureDisplayMap.idealVapor
              }
            >
              {idealVaporTemperature}°
            </sc.PresetTemperature>
            <sc.PresetTemperatureLabel>Ideal Vapor</sc.PresetTemperatureLabel>
          </div>
        )}
      </sc.PresetTemperatureRow>
      <sc.P>{renderTemperatureText()}</sc.P>
    </>
  );
};

export default TemperatureInfo;

type PresetTemperatureProps = {
  isActive?: boolean;
};

const activeTemperatureStyles = css`
  background: var(--black);
  color: var(--white);
`;

const sc = {
  ArrowLeft: styled(Arrow.Left)`
    margin-left: 40px;
  `,

  ArrowLine: styled.div<Arrow.ArrowProps>`
    border-bottom: 1px solid var(--black);
    height: ${(props): SimpleInterpolation => props.size}px;
    width: 90px;
  `,

  ArrowRight: styled(Arrow.Right)`
    margin-right: 40px;
  `,

  ArrowRow: styled(Row)`
    margin-top: 56px;
  `,

  FlavorText: styled(Text.Bold)`
    color: var(--black);
    text-transform: uppercase;
  `,

  P: styled(Text.P)`
    ${Text.PlaaxLight}
    margin: 16px auto 0;
    max-width: 528px;
    min-height: 40px;
    text-align: center;
  `,

  PresetTemperature: styled.div<PresetTemperatureProps>`
    border: solid 1px var(--black);
    border-radius: 48px;
    font-size: 16px;
    height: 48px;
    margin: 8px auto;
    padding: 14px 0;
    text-align: center;
    width: 48px;

    ${({ isActive }): SimpleInterpolation =>
      isActive ? activeTemperatureStyles : ""}

    &:hover {
      ${activeTemperatureStyles}
      cursor: pointer;
    }
  `,

  PresetTemperatureLabel: styled.div`
    ${Text.Plaax}
    font-size: 14px;
  `,

  PresetTemperatureRow: styled(Row)`
    align-content: center;
    justify-content: space-evenly;
    margin-top: 24px;
    width: 500px;
  `,
};
