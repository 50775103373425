import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";

import packageJson from "../package.json";
import { navigator } from "./navigator";

const BASE_URL_REGEX = /.pax\.com\/$/gm;

Bugsnag.start({
  apiKey: process.env.REACT_APP_BUGSNAG_API_KEY || "",
  appVersion: packageJson.version,
  enabledReleaseStages: ["staging", "production", "beta"],
  onError: (event) => {
    const hasGattError = !!event.errors.find((error) =>
      error.errorMessage.startsWith("GATT")
    );

    if (hasGattError) return false;

    // Assign event context for grouping based on the page where the error occurred
    // https://docs.bugsnag.com/product/error-grouping/#error-context
    const eventPage = event.request.url;
    // Explore Pages
    if (eventPage?.includes("/explore/pods/search")) {
      event.context = "Pods Search";
    } else if (eventPage?.includes("/explore/pods/")) {
      event.context = "Pod Details";
    } else if (eventPage?.includes("/explore/pods")) {
      event.context = "Pods Home";
    } else if (eventPage?.includes("/explore/partners")) {
      event.context = "Partner Details";
    } else if (eventPage?.includes("/explore")) {
      event.context = "Explore";
    }
    // Device Pages
    else if (eventPage?.includes("/device/era-pro")) {
      event.context = "Era Pro Device";
    } else if (eventPage?.includes("/device/era")) {
      event.context = "Era Device";
    } else if (eventPage?.includes("/device/pax-3")) {
      event.context = "Pax3 Device";
    } else if (eventPage?.includes("/device/experience-modes")) {
      event.context = "Experience Modes";
    } else if (eventPage?.includes("/device")) {
      event.context = "Device";
    }
    // My Pax Pages
    else if (eventPage?.includes("/account/settings")) {
      event.context = "My Account";
    } else if (eventPage?.includes("/account/devices")) {
      event.context = "My Devices";
    } else if (eventPage?.includes("/account/pods")) {
      event.context = "My Pods";
    } else if (eventPage?.includes("/account")) {
      event.context = "My Pax";
    }
    // SSO Pages
    else if (eventPage?.includes("/sso")) {
      event.context = "SSO";
    }
    // App Home Page ("/")
    else if (eventPage?.match(BASE_URL_REGEX)) {
      event.context = "App Home";
    }
    // Does not match a known web app route
    else {
      event.context = "Unknown App Route";
    }

    // Assign error class to group errors based on HTTP response status codes
    // https://docs.bugsnag.com/product/error-grouping/#error-class
    event.errors.forEach((error) => {
      if (error.errorMessage.includes("Request failed with status code")) {
        const statusCode = error.errorMessage.split(" ").pop()?.trim();
        error.errorClass = "http status: " + statusCode;
      }
    });

    // Add additional diagnostic information.
    const device = window.paxConnectedDevice;

    event.addMetadata("browser", {
      bluetooth: !!navigator.bluetooth,
    });

    event.addMetadata("peripheral", {
      firmware: device?.firmwareRevision,
      hasReceivedNotification: device?.hasReceivedNotification,
      hasReceivedUnknownAttribute: device?.hasReceivedUnknownAttribute,
      isConnected: device?.connected,
      type: device?.type,
    });
  },
  plugins: [new BugsnagPluginReact()],
  releaseStage: process.env.REACT_APP_ENV,
});
Bugsnag.leaveBreadcrumb("Starting app...");
