import React from "react";
import styled from "styled-components";

import * as PaxBle from "../../../pax-ble";
import { Session } from "../../../pax-ble/types/EraPro";

export type StateProps = {
  isDeviceLocked?: boolean;
  session?: Session;
};

export const EraProDeviceInfoOverlay: React.FC<StateProps> = ({
  isDeviceLocked,
  session,
  ...props
}) => {
  if (
    !session ||
    session.action === PaxBle.Types.EraPro.EraProSessionAction.INACTIVE ||
    isDeviceLocked
  )
    return null;

  const label = `${session.doseId} dose${session.doseId > 1 ? "s" : ""}`;
  return <Overlay label={label} {...props} />;
};

type OverlayProps = {
  label: string;
};

const Overlay: React.FC<OverlayProps> = ({ label, ...props }) => {
  return (
    <sc.Container data-testid="device-info-overlay" {...props}>
      <sc.TextPill>{label}</sc.TextPill>
    </sc.Container>
  );
};

const sc = {
  Container: styled.div`
    align-items: center;
    backdrop-filter: blur(10px);
    border-radius: 69px;
    display: flex;
    height: 138px;
    justify-content: center;
    width: 138px;
  `,

  TextPill: styled.div`
    background-color: var(--black);
    border-radius: 19px;
    color: var(--white);
    padding: 10px;
    text-transform: uppercase;
  `,
};
