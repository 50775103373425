import { connect } from "react-redux";

import * as a from "../../../api/consumer/actions/users";
import * as s from "../../../api/consumer/selectors/user";
import { MainAppState } from "../../../main/types";

import { MyPaxHeader } from "../../components/MyPaxHeader";

const mapStateToProps = (state: MainAppState) => {
  return {
    hasFetchedUser: s.getHasFetchedUser(state),
    isSignedIn: s.getIsUserSignedIn(state),
    themes: s.getThemes(state),
    userTheme: s.getProfileTheme(state),
  };
};

const mapDispatchToProps = {
  fetchThemes: a.fetchThemes,
  setDefaultProfileTheme: a.setDefaultProfileTheme,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyPaxHeader);
