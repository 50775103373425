import React from "react";

import * as PaxBle from "../../../pax-ble";

import { SelectDeviceType } from "../../device/actions";
import { NullableDeviceType } from "../../device/types";
import { useDesktopMediaQuery } from "../../shared/hooks";
import * as text from "../../shared/text";

import * as t from "../types";
import { DeviceTypeList } from "./DeviceTypeList";

export type StateProps = {
  deviceError?: string;
  deviceTypes: PaxBle.Types.DeviceType[];
  selectedDeviceType: NullableDeviceType;
};

export type DispatchProps = { selectDeviceType: SelectDeviceType };

type DeviceTypeSelectorProps = t.DeviceConnectionModalContentProps &
  StateProps &
  DispatchProps;

export const DeviceTypeSelector: React.FC<DeviceTypeSelectorProps> = ({
  deviceError,
  deviceTypes,
  onStepCompleted,
  selectedDeviceType,
  selectDeviceType,
}) => {
  const isDesktop = useDesktopMediaQuery();

  const handleOptionClick = (newDeviceType: NullableDeviceType): void => {
    selectDeviceType(newDeviceType);
    onStepCompleted(t.DeviceConnectionStep.DEVICE_TYPE_SELECTOR);
  };

  return (
    <DeviceTypeList
      deviceError={deviceError}
      deviceTypes={deviceTypes}
      horizontal={!isDesktop}
      onOptionClick={handleOptionClick}
      selectedDeviceType={selectedDeviceType}
      title={text.SELECT_YOUR_DEVICE}
    />
  );
};
