export enum ExperimentCode {
  LOW_POD_ALERTS_UPSELL = "FGUpsellExperiment",
  PAX_FINDER_UPSELL = "FMPUpsellEngagementExperiment",
  AA_TEST_ALL_WEB = "aaAllExperimentWeb",
  AA_TEST_REGISTERED_WEB = "aaRegisteredExperimentWeb",
}

export type ExperimentsJson = {
  assignedVariations: Variation[];
};

export type Variation = {
  experimentCode: ExperimentCode;
  variationCode: string;
};
