import { doesBrowserSupportTabs, isConnectBrowser } from "../../navigator";

import { ErrorCode } from "../api/consumer/types";
import { getLoginCookie } from "../cookies";
import { isExternalSsoEnabled } from "../featureFlags";
import { MailingList } from "../mailing-list/types";
import * as r from "../main/routes";
import * as c from "./constants";
import * as t from "./types";

export const isPasswordInError = (responseCode?: ErrorCode): boolean =>
  !!responseCode &&
  [
    ErrorCode.INVALID_LOGIN,
    ErrorCode.INVALID_PASSWORD,
    ErrorCode.REUSED_PASSWORD,
  ].indexOf(responseCode) !== -1;

export const isUsernameInError = (responseCode?: ErrorCode): boolean =>
  !!responseCode &&
  [
    ErrorCode.INVALID_LOGIN,
    ErrorCode.INVALID_TOKEN,
    ErrorCode.INVALID_USERNAME,
    ErrorCode.DUPLICATE_EMAIL,
  ].indexOf(responseCode) !== -1;

export const ssoRedirect: t.SsoRedirect = (redirectTo: string) => {
  // Set window location directly because SsoApp does not handle non-SSO routes.
  window.location.href = redirectTo;
};

export const ssoApiReturnTo: t.SsoApiReturnTo = (returnTo: string) => {
  // Returns the URL if we came from an email link containing the full returnTo URL
  if (returnTo.includes("http")) return returnTo;
  // Builds the full URL if its only a path for the API returnTo value.
  return window.location.protocol + "//" + window.location.host + returnTo;
};

export const getSsoCountry = (country: string | null): c.SsoCountry => {
  return c.SsoCountry[country as keyof typeof c.SsoCountry];
};

const MAILING_LIST_MAP = {
  [c.SsoCountry.CANADA]: MailingList.CANADA,
  [c.SsoCountry.INTERNATIONAL]: MailingList.EU,
  [c.SsoCountry.US]: MailingList.US,
};

export const getMailingListForCountry = (country: c.SsoCountry): string => {
  return MAILING_LIST_MAP[country];
};

export const goToSso: t.GoToSso = (ssoRoute, openWindow = window.open) => {
  // Avoid opening an SSO window if the user is already logged in,
  // unless they are trying to edit their account or verify email.
  if (
    !isExternalSsoEnabled &&
    !ssoRoute.includes(r.ssoEditAccount().split("?")[0]) &&
    !ssoRoute.includes(r.ssoEmailVerificationRequestSent().split("?")[0]) &&
    getLoginCookie().length > 0
  )
    return;

  if (doesBrowserSupportTabs()) {
    const ssoWindow = openWindow(ssoRoute, "_blank");
    ssoWindow?.focus();
  } else {
    ssoRedirect(ssoRoute);
  }
};

export const endSsoFlow = (redirectTo: string, shouldClose?: string): void => {
  let windowOpenerPresent = false;

  // Bypass check for Connect Browser because it doesn't support window.opener.
  if (!isConnectBrowser) {
    windowOpenerPresent = window.opener !== null;
  }

  if (
    shouldClose === "true" &&
    (windowOpenerPresent || doesBrowserSupportTabs())
  ) {
    window.close();
    return;
  }

  ssoRedirect(redirectTo);
};
