import React from "react";
import { Redirect } from "react-router-dom";

export const MyPodsRedirect: React.FC = () => {
  return (
    <Redirect
      from="/my-pax/pods/:selectedMyPodsType"
      to="/account/pods/history"
    />
  );
};
