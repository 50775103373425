import { useFormik } from "formik";
import React, { useState } from "react";
import styled, { css, SimpleInterpolation } from "styled-components";

import * as consumerApiActions from "../../api/consumer/actions/devices";
import * as deviceActions from "../../device/actions";
import { DEVICE_NAME_MAX_LENGTH } from "../../device/constants";
import { Modal, ModalProps } from "../../modal/components/Modal";
import Button from "../../shared/components/Button";
import * as flashBanner from "../../shared/components/FlashBanner";
import { Column, Row } from "../../shared/components/Flex";
import * as Icons from "../../shared/components/Icons";
import { Slider } from "../../shared/components/Slider";
import * as Text from "../../shared/components/Text";
import * as text from "../../shared/text";
import { desktopBreakpoint, removeEmojis } from "../../shared/utils";

type DeviceSettingsModalProps = ModalProps;

export type StateProps = {
  brightness: number;
  deviceName: string;
  firmwareRevision: string;
  haptics: number;
  isDeviceLocked: boolean;
  serialNumber?: string;
};

export type DispatchProps = {
  disconnectDevice: deviceActions.DisconnectDevice;
  setBrightness: deviceActions.SetBrightness;
  setDeviceName: deviceActions.SetDeviceName;
  setHaptics: deviceActions.SetHaptics;
  showFlashBanner: flashBanner.ShowFlashBanner;
  updateDevice: consumerApiActions.UpdateDevice;
};

export type EraProDeviceSettingsModalProps = DeviceSettingsModalProps &
  StateProps &
  DispatchProps & {
    onModalClose: () => void;
  };

export const EraProDeviceSettingsModal: React.FC<EraProDeviceSettingsModalProps> =
  ({
    brightness,
    deviceName,
    disconnectDevice,
    firmwareRevision,
    haptics,
    hideCloser,
    isDeviceLocked,
    isOpen,
    onModalClose,
    onRequestClose,
    serialNumber,
    setBrightness,
    setDeviceName,
    setHaptics,
    showFlashBanner,
    updateDevice,
  }) => {
    const handleBrightnessChange = (value: number): void => {
      const percentage = value / 100;
      setBrightness(percentage);
    };

    const handleDisconnectClick = (): void => {
      disconnectDevice();
    };

    const modalProps = {
      hideCloser,
      isOpen,
      onRequestClose,
    };

    return (
      <Modal {...modalProps}>
        <sc.Container data-testid="era-pro-settings-modal">
          <sc.Header>
            <sc.BackButton onClick={onModalClose}>
              <Icons.ChevronLeft />
            </sc.BackButton>
            <sc.SettingsIconContainer>
              <Icons.Settings />
            </sc.SettingsIconContainer>
            <DeviceNameRow
              deviceName={deviceName}
              isDeviceLocked={isDeviceLocked}
              serialNumber={serialNumber}
              setDeviceName={setDeviceName}
              showFlashBanner={showFlashBanner}
              updateDevice={updateDevice}
            />
          </sc.Header>
          <sc.Group>
            <sc.Label>Brightness</sc.Label>
            <Slider
              isDisabled={isDeviceLocked}
              value={brightness * 100}
              onAfterChange={handleBrightnessChange}
            />
          </sc.Group>
          <sc.Group>
            <sc.Label>Haptics</sc.Label>
            <Slider
              isDisabled={isDeviceLocked}
              onAfterChange={setHaptics}
              value={haptics}
            />
          </sc.Group>
          <sc.BottomSection>
            <sc.ButtonSection>
              <sc.Button
                data-testid="switch-device"
                onClick={handleDisconnectClick}
              >
                Switch Devices
              </sc.Button>
              <sc.Button
                data-testid="disconnect-device"
                onClick={handleDisconnectClick}
              >
                Disconnect
              </sc.Button>
            </sc.ButtonSection>
            {firmwareRevision && (
              <sc.Firmware>Firmware v{firmwareRevision}</sc.Firmware>
            )}
          </sc.BottomSection>
        </sc.Container>
      </Modal>
    );
  };

type DeviceNameRowProps = {
  deviceName: string;
  isDeviceLocked?: boolean;
  serialNumber?: string;
  setDeviceName: deviceActions.SetDeviceName;
  showFlashBanner: flashBanner.ShowFlashBanner;
  updateDevice: consumerApiActions.UpdateDevice;
};

const DeviceNameRow: React.FC<DeviceNameRowProps> = ({
  deviceName = "",
  isDeviceLocked,
  serialNumber,
  setDeviceName,
  showFlashBanner,
  updateDevice,
}) => {
  const [editMode, setEditMode] = useState(false);

  const handleDeviceNameClick = (): void => {
    if (isDeviceLocked) return;
    setEditMode(true);
  };

  const formik = useFormik({
    initialValues: { deviceName },
    onSubmit: (values) => {
      const updatedDeviceName = removeEmojis(values.deviceName);

      setDeviceName(updatedDeviceName);
      setEditMode(false);
      if (!serialNumber) return;

      try {
        updateDevice(serialNumber, { deviceName: updatedDeviceName });
      } catch (err) {
        showFlashBanner(
          text.OOPS_AN_ERROR_OCCURRED_TRY_AGAIN,
          flashBanner.BannerType.ERROR
        );
      }
    },
  });

  if (editMode) {
    return (
      <sc.DeviceNameRow>
        <form data-testid="device-name-form" onSubmit={formik.handleSubmit}>
          <input
            data-testid="device-name-input"
            id="deviceName"
            maxLength={DEVICE_NAME_MAX_LENGTH}
            name="deviceName"
            onBlur={(): void => formik.handleSubmit()}
            onChange={formik.handleChange}
            required
            type="text"
            value={removeEmojis(formik.values.deviceName)}
          />
        </form>
      </sc.DeviceNameRow>
    );
  }

  return (
    <sc.DeviceNameRow>
      <sc.DeviceName
        data-testid="device-name"
        onClick={isDeviceLocked ? undefined : handleDeviceNameClick}
      >
        {deviceName}
      </sc.DeviceName>
      {!isDeviceLocked && (
        <sc.Pencil
          data-testid="device-name-edit"
          onClick={handleDeviceNameClick}
        />
      )}
    </sc.DeviceNameRow>
  );
};

const sc = {
  BackButton: styled.button`
    background: none;
    border: none;
    left: 6px;
    position: absolute;
    top: 20px;

    ${desktopBreakpoint(css`
      display: none;
    `)}
  `,

  BottomSection: styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: flex-end;
  `,

  Button: styled(Button)`
    font-size: 14px;
    min-width: 157px;
  `,

  ButtonSection: styled(Column)`
    align-items: center;
    justify-content: center;
    margin: 54px 0 20px;

    & > button {
      margin-bottom: 15px;
    }

    & > button:last-child {
      margin-bottom: 0;
    }

    ${desktopBreakpoint(css`
      align-items: center;
      flex-direction: row;

      & > button {
        flex: 1;
        margin-bottom: 0;
        margin-right: 20px;
      }

      & > button:last-child {
        margin-right: 0;
      }
    `)}
  `,

  Container: styled(Column)`
    margin: auto;
    min-height: 100%;
    padding: 40px;

    ${desktopBreakpoint(css`
      width: var(--default-modal-width);
    `)}
  `,

  DeviceName: styled.div`
    border-bottom: 1px solid transparent;
    cursor: ${(props): SimpleInterpolation =>
      props.onClick ? "pointer" : "auto"};
    font-size: 24px;
    text-transform: uppercase;

    &:hover {
      border-bottom: ${(props): SimpleInterpolation =>
        props.onClick ? "1px solid var(--black)" : ""};
    }
  `,

  DeviceNameRow: styled(Row)`
    align-items: center;
    margin-bottom: 44px;
  `,

  Firmware: styled.div`
    ${Text.PlaaxLight}
    color: var(--brown-grey);
    font-size: 14px;
    margin: 0 auto 20px;
  `,

  Group: styled(Column)`
    margin-bottom: 28px;
  `,

  Header: styled(Column)`
    align-items: center;
  `,

  Label: styled.div`
    font-size: 18px;
    margin-bottom: 8px;
    text-transform: uppercase;

    &:last-child {
      margin-top: 28px;
    }
  `,

  Pencil: styled(Icons.Pencil)`
    cursor: pointer;
    height: 17px;
    margin-left: 12px;
    width: 16px;
  `,

  SettingsIconContainer: styled.div`
    align-items: center;
    border: 1px solid var(--black);
    border-radius: 100%;
    display: flex;
    height: 48px;
    justify-content: center;
    margin-bottom: 16px;
    width: 48px;
  `,
};
