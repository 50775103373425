export const DOSE_OPTIONS: {
  [doseId: number]: {
    active: boolean;
    label: string;
    value: number;
  };
} = {
  1: { active: false, label: "Micro", value: 1 },
  2: { active: false, label: "Small", value: 2 },
  3: { active: false, label: "Medium", value: 3 },
  4: { active: false, label: "Large", value: 4 },
};

export const ERA_SESSION_LOCKOUT_TIME = 30;
