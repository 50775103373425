import React from "react";
import { isIOS, isMobile } from "react-device-detect";

import { StrainMetaDataRecommendationSource } from "../api/consumer/types/strain";
import * as c from "./constants";

// Dependencies and trademarks.
export const PAXFINDER = "PAXFinder™";
export const PAX_2 = "PAX 2™";
export const PAX_3 = "PAX 3™";
export const PAX_ERA = "PAX® ERA™";
export const PAX_ERA_LIFE = "PAX® ERA LIFE™";
export const PAX_ERA_PRO = "PAX® ERA PRO™";

export const ABOUT = "About";
export const aboutBrandPartner = (partnerName?: string): string =>
  partnerName ? `About ${partnerName}` : "";
export const ACCESSORIES = "Accessories";
export const ACCOUNT = "Account"; // Formerly My PAX...
export const ACCOUNT_INFO = "Account Info";
export const ACCOUNT_INFORMATION = "Account Information";
export const ACCOUNT_PREFERENCES = "Account Preferences";
export const ACCOUNT_SETTINGS = "Account Settings";
export const ADD_A_DEVICE_TO_TRY_OUT_PAXFINDER = `Add a device to try out ${PAXFINDER}`;
export const ADD_A_REVIEW =
  "Add a review to help others and find more strains.";
export const ADDED_TO_MY_PODS = "Added to My Pods";
export const ADDRESS_REQUIRED = "Address required";
export const ADD_NOTE = "Add note";
export const ADD_YOUR_REVIEW = "Add your review.";
export const AGO = "ago";
export const ALABAMA_ST_SAN_FRANSICO_CA = "Alabama Street, San Francisco, CA";
export const ALL_PODS_FOR_PAX_ERA = "All Pods for PAX Era";
export const ALL_POSTS = "All Posts";
export const ALPHABETICAL_BY_BRAND = "Alphabetical by Brand";
export const ALPHABETICAL_BY_POD = "Alphabetical by Pod";
export const APP = "App";
export const APPAREL = "Apparel";
export const APPLY = "Apply";
export const AVAILABLE_ERA_PODS = "Available ERA Pods";
export const AVAILABLE_FOR = "available for";
export const BALANCED = "Balanced";
export const BASED_ON_YOUR_INTERNET_CONNECTION =
  "Based on your internet connection";
const BATCH = "BATCH";
export const batchId = (batchId: string): string => `${BATCH} #${batchId}`;
export const BATTERY_TOO_LOW = "Battery Too Low";
export const becauseYouLikedDisliked = (
  liked: boolean,
  strainName: string
): string => `Because you ${liked ? "liked" : "disliked"} ${strainName}`;
const BETA_APP = "Beta App";
export const BOOST = "Boost";
export const BRAND = "Brand";
export const BRAND_IS_REQUIRED = "Brand is required";
export const BRIGHTNESS = "Brightness";
export const CALMING = "Calming";
export const CANCEL = "Cancel";
export const CANCEL_DOSE = "Cancel Dose";
export const CANNABIS = "Cannabis";
export const CBD = "CBD";
export const cbdPodsInLocation = (location?: string): string =>
  location ? `CBD Pods in ${location}` : "CBD Pods";
export const CHANGE_MY_PASSWORD = "Change my password";
export const CHOOSE_YOUR_PROFILE_THEME = "Choose your profile theme";
export const CLEAR = "Clear";
export const CLOSE = "Close";
export const COA_PASSED_REGULATIONS =
  "This certificate of analysis has passed state regulations at the time of sale.";
export const coaPassedRegulationsInLocation = (location: string): string =>
  `This certificate of analysis has passed state regulations at the time of sale. ${location} allows a manufacturer to provide bulk batch oil test results prior to the addition of any additional ingredients, such as terpenes, in accordance with state law. Check your pod’s label and ingredients for any additional terpenes, which may affect total THC, CBD, and other cannabinoid content, as applicable.`;
export const COMMUNITY = "Community";
export const CONFIRM = "Confirm";
export const CONNECT = "Connect";
export const CONNECT_A_NEW_DEVICE = "Connect a new device";
export const CONNECTED = "Connected";
export const CONNECTING = "Connecting...";
export const CONSERVES_OIL =
  "Conserves oil while providing a clean mouth and throat feel";
export const CONSISTENT_EXPERIENCE =
  "Consistent experience blending flavor and vapor";
export const COOLING = "Cooling";
export const CREATE_ACCOUNT_OR_SIGN_IN_TO_MANAGE =
  "Create an account or sign in to manage your devices, find your PAX, save pods for later, view your pod history, and rate your pods! Never miss out on the latest and greatest.";
export const CURRENT_POD = "Current Pod";
export const DEVELOPER = "Developer";
export const DEVICE = "Device";
export const DEVICE_ALREADY_CONNECTED =
  "Your device may already be connected. Please disconnect it from any other tabs and try again.";
export const DEVICE_CONNECTION_ONLY_POSSIBLE_IN = `Device connection is only possible in ${
  isMobile
    ? isIOS
      ? "Connect Browser"
      : "Google Chrome"
    : "Google Chrome and Microsoft Edge"
}`;
export const DEVICE_CONNECTION_TAKING_TOO_LONG =
  "Device connection is taking too long. Please charge your device and try again.";
export const DEVICE_DISCONNECTED_UNEXPECTEDLY =
  "Device disconnected unexpectedly. Please reconnect.";
export const DEVICE_MODE = "Device Mode";
export const DEVICE_NAME = "Device Name";
export const DEVICE_SETTINGS = "Device Settings";
export const DEVICE_SYNCING = "Device Syncing";
export const DEVICE_SYNCING_DISCONNECTED =
  "Device disconnected while syncing. Please reconnect.";
export const DEVICE_USAGE_DATA = "Device Usage Data";
export const DISABLE_LOCATION = "Disable Location";
export const DISABLING_PAXFINDER_WILL_REMOVE = `Disabling ${PAXFINDER} will remove your last saved location for this device and we will stop storing any future locations`;
export const DISCONNECT = "Disconnect";
export const DISCONNECTED = "Disconnected";
export const DONE = "Done";
export const DOSE_CONTROL = "Dose Control";
export const DOSE_PULSE = "Dose Pulse";
export const EDIT = "Edit";
export const EDUCATION = "Education";
export const EFFECT = "Effect";
export const EFFECTS = "Effects";
export const EFFICIENCY = "Efficiency";
export const eg = (text: string): string => `e.g. ${text}`;
export const EMAIL = "Email";
export const ENABLED = "Enabled";
export const ENABLE_PAXFINDER = `Enable ${PAXFINDER}`;
export const ENABLE_PAXFINDER_UNDER_EACH_DEVICE = `Enable ${PAXFINDER} under each device`;
export const ENERGIZED = "Energized";
export const ENERGIZING = "Energizing";
export const ENTER = "Enter";
export const ENTER_SERIAL_NUMBER = "Enter Serial Number";
export const ENTER_YOUR_ADDRESS = "Enter your address";
export const ERA_ACCECCORIES = "Era Accessories";
export const ERA_LABEL = "ERA";
export const ERA_PODS_REVIEWS = "ERA Pod Reviews";
export const ERA_PRO_LABEL = "ERA Pro";
export const errorComparingFirmwareVersions = (
  firmwareRevision: string,
  latestFirmwareVersion: string
): string =>
  `Error comparing FW versions ${firmwareRevision} and ${latestFirmwareVersion}`;
export const ERROR_CONNECTING_CHECK_SERIAL =
  "Error connecting to your device. Please check the serial number and try again.";
export const ERROR_CONNECTING_DEVICE_TRY_AGAIN =
  "Error connecting to your device. Please try again.";
export const ERROR_FINDING_STRAIN =
  "There was a problem finding your strain. Please try again.";
export const EXCELLENT = "Excellent";
export const EXPERIENCES = "Experiences";
export const EXPERIENCE_MODE_EXPLANATION = `The petals of your Era Pro device represent four unique experiences, rather than the temperature settings. Use the pop & click gesture to cycle through the modes, and consume in a way that best suits your needs!`;
export const EXPLORE = "Explore";
export const EXPLORE_PODS = "Explore Pods";
export const FAILED_TO_CONNECT_TAP_TO_REFRESH = (
  <span>
    Failed to connect to device. <u>Tap here</u> to refresh the page and try
    again.
  </span>
);
export const FAIR = "Fair";
export const FAQ = "FAQ";
export const FAVORITES = "Favorites";
export const FILTER = "Filter";
export const FIND = "Find";
export const FIND_RETAILERS = "Find Retailers";
export const FIRMWARE_NOT_AVAILABLE = "Firmware Not Available";
export const FIRMWARE_UPDATE = "Firmware update";
export const FIRMWARE_UPDATE_AVAILABLE = "Firmware Update Available";
export const FIRST_USE = "First use";
export const FLAVOR = "Flavor";
export const FLAVORS = "Flavors";
export const FOCUS = "Focus";
export const FORGET_DEVICE = "Forget Device";
const FOR_YOU = "For You";
export const FROM = "From";
export const GET_REAL_CLOSE = "Get Real Close";
export const GOOD = "Good";
export const GO_BACK = "Go Back";
export const GREAT = "Great";
export const HAPTICS = "Haptics";
export const HAVE_YOU_TRIED_THIS_PRODUCT = "Have you tried this product?";
export const HAVING_TROUBLE = "Having trouble?";
export const HEATING = "Heating";
export const HIGHEST_RATED = "Highest Rated";
export const HISTORY = "History";
export const HOW_DID_YOU_LIKE_THIS_POD = "How did you like this pod?";
export const howDidYouLikePodName = (podName: string): string =>
  `How did you like ${podName}?`;
export const hybridPodsInLocation = (location?: string): string =>
  location ? `Hybrid Pods in ${location}` : "Hybrid Pods";
export const HYPER = "Hyper";
const IN = "in";
export const indicaPodsInLocation = (location?: string): string =>
  location ? `Indica Pods in ${location}` : "Indica Pods";
export const informationAboutHazePod =
  "Information about pod material provided by Peace & Haight, Inc.’s manufacturing partner, the manufacturer of the material.";
export const informationAboutPod = (partnerName = "brand partner"): string =>
  `Information about pod material provided by ${partnerName}, the manufacturer of the material. PAX makes no representation as to its accuracy. PAX does not manufacture, distribute or sell cannabis.`;
export const IS_SYNCING = "is syncing";
export const ISSUES_COMMUNICATING_WITH_DEVICE =
  "There are issues communicating with your device...";
export const JOURNAL = "Journal";
export const KEEP_SHAKING_DEVICE = (
  <span>
    Keep shaking your device until blue lights appear. When they do, hit{" "}
    <i>{CONNECT}</i>.
  </span>
);
export const KNOW_WHEN_YOU_ARE_LOW = "Know When You Are Low";
export const LAB_REPORT = "Lab report";
export const LAB_TESTED = "Lab tested";
export const LAST_SAVED = "Last saved";
export const LAST_USE = "Last use";
export const LAST_USED = "Last used";
export const LEARN = "Learn";
export const LOADING_DESCRIPTION = "loading Description...";
export const LOCAL_STORES = "Local Stores";
export const LOCATION = "location";
export const LOCATION_ACCESS_REQUIRED = `In order to use ${PAXFINDER}, location access is required`;
export const LOCATION_COULD_NOT_BE_DETERMINED =
  "Your location could not be determined";
export const locationErrorMessage = (USLocation: boolean): string =>
  `Please enter a valid US or Canadian address or ${
    USLocation ? ZIP_CODE : POSTAL_CODE
  }.`;
export const LOCATION_IS_SAVED_ONLY_WHEN =
  "Location is saved only when PAX is connected";
export const LOCK_DEVICE = "Lock Device";
export const LOOKING_FOR_LOCATION = "Looking for the latest location…";
export const MANAGE_DEVICES = "Manage Devices";
export const MAXIMIZES_VAPOR_PRODUCTION =
  "Maximizes vapor production without compromising flavor";
export const MILD = "Mild";
export const MISSING_STRAIN_REPORTED = "Got it! We'll track this strain down.";
export const MODERATE = "Moderate";
export const MY_DEVICES = "My Devices";
export const MY_PODS = "My Pods";
export const MY_REVIEW = "My Review";
export const MY_USAGE = "My Usage";
export const NAME = "Name";
export const nearAddress = (address: string): string => `Near ${address}`;
const NEARBY = "Nearby";
export const nDose = (dose: number): string =>
  `${dose} dose${dose > 1 ? "s" : ""}`;
export const NEED_TO_TRY_AGAIN = "Need to try again?";
export const NEWS = "News";
export const NEWEST = "Newest";
export const NEXT = "Next";
export const NOTIFICATIONS = "Notifications";
export const NOT_ENABLED = "Not Enabled";
export const NOW = "now";
export const NOW_YOU_CAN_FIND_OUT_WHEN_POD_IS_LOW =
  "Now you can find out when your pod is low to replenish or explore new pods";
export const NOW_YOU_CAN_KEEP_TRACK = `Now you can keep track of your PAX device's last connected location when ${PAXFINDER} is enabled`;
export const NO_PODS_HERE_YET = "No pods here yet";
export const noPodsInYourArea = (location?: string): string =>
  `Unfortunately, we couldn't find any pods in ${
    location ? location : "your area"
  }.`;
export const noPodsInYourAreaWithSearchCriteria = (location?: string): string =>
  `Unfortunately, we couldn't find any pods in ${
    location ? location : "your area"
  } that fit your search criteria. Try adjusting your search.`;
export const NOT_AVAILABLE = "Not Available";
export const OFF = "Off";
export const ON = "On";
export const OOPS_AN_ERROR_OCCURRED_TRY_AGAIN =
  "Oops! An error occurred. Please try again.";
export const OOPS_AN_ERROR_OCCURRED_WHILE_FINDING_LOCATION_UPDATE_SEARCH =
  "Oops! An error occurred while finding location. Try again later or update your search";
export const OOPS_AN_ERROR_OCCURRED_WHILE_CHANGING_LOCATION =
  "Oops! An error occurred while changing location, try again later";
export const OOPS_LOOKS_LIKE_OUR_SERVERS_ARE_BUSY =
  "Oops! Looks like our servers are a bit busy. Please try again a little later";
export const OR_SIGN_IN = "Or Sign In";
export const ORDER = "Order";
export const OUR_PURPOSE = "Our Purpose";
export const OVEN_OFF = "Oven Off";
export const PAIR_YOUR_DEVICE = "Pair Your Device";
export const PARTNER = "Partner";
export const PASSED_ALL_TESTS_FOR_SAFETY_AND_PURITY =
  "Passed all tests for safety and purity";
export const PASSWORD = "Password";
export const PASSWORD_PLACEHOLDER = "**********";
export const PAXFINDER_SAVES_LOCATION_EVERY = `${PAXFINDER} saves location data every 5 minutes when your device is connected`;
export const PAX_3_LOW_BATTERY_DISCONNECT = `Your ${PAX_3} has disconnected because its battery is low. Please charge your device and reconnect.`;
export const PAX_DEVICE = "PAX device";
export const PAX_DEVICE_PAIRING = "PAX device pairing";
export const PAX_DIAMONDS = "PAX Diamonds";
export const PAX_MINI_LABEL = "PAX Mini";
export const PAX_PLUS_LABEL = "PAX Plus";
export const PAX_SMART = "PAX Smart™";
export const percerntOfPeopleFeltThis = (percent: number): string =>
  `${percent}% felt this`;
export const percentUsersFelt = (
  percent: number,
  strainName: string
): string => `${percent}% of PAX users reported ${strainName} helped them feel this
effect.`;
export const PHOTOS_AND_VIDEOS = "Photos & Videos";
export const PLEASE_CHARGE_DEVICE =
  "Please charge your device to at least 25% before updating.";
export const PAX_APP = "PAX App";
export const PAX_ACCESSORIES = "PAX Accessories";
export const PAX_DIAMONDS_PODS = "PAX Diamonds Pods";
export const PAX_JOURNAL = "Pax Journal";
export const PAX_LIVE_ROSIN = "PAX Live Rosin";
export const PAX_LIVE_ROSIN_PODS = "PAX Live Rosin Pods";
export const PAX_PODS_UNAVAILABLE_IN_LOCATION_PLEASE_TRY_DIFFERENT_LOCATION =
  "PAX Pods are currently only available in the US and Canada, please try a different location";
export const PLEASE_ENTER_SERIAL_ERA_PRO = `Please enter the serial number located on the inner rim of your ${PAX_ERA_PRO} device`;
export const PLEASE_USE_ONLY_ALPHANUMERIC_CHARS =
  "Please use only alphanumeric characters.";
export const POD_ADDED_TO_PAX_ERA = `Pod added to ${PAX_ERA}`;
export const POD_ADDED_TO_PAX_ERA_PRO = `Pod added to ${PAX_ERA_PRO}`;
export const POD_BATCH_LIST = "Pod Batch List";
export const POD_COUNT = "Pod count";
export const POD_LOW = "Pod Low";
export const podSafety = (isAmerican: boolean): string =>
  `We work to ensure Era pods are filled with only the highest quality oil. All pods sold on the PAX platform comply with ${
    isAmerican ? "state" : "federal"
  } testing standards.`;
export const podsByBrandPartner = (partnerName?: string): string =>
  partnerName ? `Pods by ${partnerName}` : "";
export const POD_TEST_RESULTS = "Pod Test Results";
export const POPULAR_PODS_THIS_MONTH = "Popular Pods This Month";
const POSTAL_CODE = "postal code";
export const postalCodeExplanation = (USLocation: boolean): string =>
  `For the most relevant products and content, please enter your ${
    USLocation ? ZIP_CODE : POSTAL_CODE
  } or street address`;
export const POOR = "Poor";
export const POTENCY = "Potency";
export const POWERED_BY_GOOGLE = "Powered by Google";
export const PREFERENCES = "Preferences";
export const PROBLEM_UPDATING_FIRMWARE =
  "There was a problem updating your firmware, please try again later.";
export const PRODUCER = "Producer";
export const PRODUCT_INFORMATION = "Product Information";
export const PROFILE_THEME = "Profile Theme";
export const RATING = "Rating";
export const READY = "Ready";
export const RECENT_DEVICES = "Recent Devices";
export const RECENTLY_VIEWED_PODS = "Recently Viewed Pods";
export const RECENT_SEARCHES = "Recent Searches";
export const RECOMMENDED_FOR_YOU = "Recommended for you";
export const RECOMMENDED_FOR_YOU_TITLE = (
  recommendationSource: StrainMetaDataRecommendationSource | undefined,
  location: string
): string =>
  `Recommended ${
    recommendationSource === StrainMetaDataRecommendationSource.YOUR_LOCATION
      ? NEARBY
      : recommendationSource === StrainMetaDataRecommendationSource.POPULAR &&
        location
      ? `${IN} ${location}`
      : FOR_YOU
  }`;
export const RECONNECT = "Reconnect";
export const REDUCE_VISIBLE_VAPOR =
  "Reduces visible vapor, device LEDs, and haptics for discreet use";
export const RELEVANCE = "Relevance";
export const RELIEF = "Relief";
export const REPORT_MISSING_STRAIN = "Report A Missing Strain";
export const REPORT_MISSING_STRAIN_TAGLINE =
  "Let us know what is missing and our team will work to add it";
export const RESEND = "Resend";
export const RESET = "Reset";
export const REST = "Rest";
export const REVIEW = "Review";
export const REVIEWS = "Reviews";
export const SAFETY = "Safety";
export const sativaPodsInLocation = (location?: string): string =>
  location ? `Sativa Pods in ${location}` : "Sativa Pods";
export const SAVED_30_MINUTES_AGO = "Saved 30 minutes ago";
export const SAVED_FROM = "Saved From";
export const SAVING_FROM = "Saving From";
export const SEARCH_IN = "Search in";
export const SEARCH_PODS = "Search Pods";
export const SEARCH_RESULTS = "Search Results";
export const SEE_DEVICE_CONTROLS = "See device controls";
export const SEE_LESS = "See less";
export const SEE_MORE = "See more";
export const SEE_POD_HISTORY = "See pod history";
export const SELECT_A_BRAND = "Select a brand";
export const SELECT_A_POD = "Select a pod for the full experience";
export const SELECT_DEVICE_FROM_MENU = "Select your device from the menu";
export const SELECT_DOSE = "Select Dose";
export const SELECT_OR_INSERT_POD = (
  <span>
    Select a pod or insert a <b>{PAX_SMART}</b> pod into your device to ID it
  </span>
);
export const SELECT_YOUR_DEVICE = "Select Your Device";
export const SELECT_UP_TO_2 = "Select up to 2";
export const SELECTED_THIS_EFFECT = "selected this effect";
export const SERIAL_IS_REQUIRED = "Serial is required";
export const SERIAL_MUST_BE_ALPHANUMERIC = "Serial must be alphanumeric";
export const SERIAL_MUST_CONTAIN_8_CHARS = "Serial must contain 8 characters";
export const SESSION_HAS_EXPIRED =
  "Your session has expired. Please log in again.";
export const SETTINGS = "Settings";
export const SETTING_TEMPERATURE = "Setting Temperature";
export const SET_AS_CURRENT = "Set as current";
export const SET_YOUR_LOCATION = "Set your location";
export const SHAKE_TO_PAIR = "Shake To Pair";
export const SHARE_MY_DATA_WITH_PAX = (
  dataShareLink: React.ReactNode
): React.ReactNode => (
  <>
    Share my {dataShareLink} with PAX to help improve the quality of our
    products
  </>
);
export const SIGN_IN = "Sign In";
export const SIGN_IN_TO_RATE = "Sign in to rate";
export const signInToRateThisPod = (strainName: string): string =>
  `Sign in to leave a rating for ${strainName}`;
export const SIGN_IN_TO_SAVE_AND_SEE_COOL_THINGS =
  "Sign in to save it and see cool things like your pod history";
export const SIGN_OUT = "Sign Out";
export const SIGN_UP = "Sign Up";
export const SIGN_UP_TO_ENABLE = "Sign Up To Enable";
export const SIGN_UP_TO_SAVE = "Sign Up To Save";
export const SKIP_EFFECTS = "Skip effects";
export const SOCIAL_IMPACT = "Social Impact";
export const SORT = "Sort";
export const STEALTH = "Stealth";
export const SPECTRUM = "Spectrum";
export const starFilterPillText = (rating: number): string =>
  `${rating}+ Stars`;
export const STRAIN = "Strain";
export const STRAINS = "Strains";
export const STRAIN_DETAILS = "Strain Details";
export const STRAIN_IS_REQUIRED = "Strain is required";
export const STRAIN_NAME_AS_CURRENT_POD = (
  partnerName: string,
  strainName: string
): React.ReactNode => (
  <>
    <b>{strainName}</b> by <b>{partnerName}</b> as your current pod?
  </>
);
export const STRENGTH = "Strength";
export const STRONG = "Strong";
export const SUPPORT = "Support";
export const SUBMIT = "Submit";
export const SUBMIT_REVIEW = "Submit review";
export const SUGGESTED_SEARCHES = "Suggested Searches";
export const TEMPERATURE = "Temperature";
export const TEMPERATURE_MODE_EXPLANATION = "The original temperature controls";
export const TEMPERATURE_UNIT = "Temperature unit";
export const TESTED_BY = "tested by";
export const TESTING = "testing";
export const TEST_RESULTS = "Test Results";
export const TEST_RESULTS_FROM = "test results from";
export const THANK_YOU = "Thank you";
export const THC = "THC";
export const THERE_WERE_PROBLEMS_GETTING_YOUR_ADDRESS =
  "There were some problems finding your address. Try again later.";
export const THE_UNITED_STATES = "the United States";
export const THIS_POD = "this pod";
export const THIS_STRAIN = "this strain";
export const THIS_STRAIN_HELPED_MOST_PEOPLE = "This strain helped most people";
export const TIMEOUT = "Timeout";
export const topPodsIn = (location?: string): string =>
  location ? `Top Pods in ${location}` : "Top Pods";
export const TURN_ON_PAX3 = `Turn your ${PAX_3} on by pressing the mouthpiece.`;
export const TYPE = "Type";
export const UH_OH_LOOKS_LIKE_YOU_ARE_OFFLINE =
  "Uh oh! Looks like you're offline";
export const UNABLE_TO_FIND_LOCATION = "Unable to find location.";
export const UNABLE_TO_FIND_STRAIN =
  "Unable to find your strain, please select a pod to add it";
export const UNITED_STATES = "United States";
export const UNLOCK_DEVICE = "Unlock Device";
export const UNWIND = "Unwind";
export const UPDATE = "Update";
export const UPLIFTED = "Uplifted";
export const USE_CURRENT_LOCATION = "Use current location";
export const USER_DATA_HIDDEN_FOR_PRIVACY_REASONS =
  "User data hidden for privacy reasons";
export const usingLocation = (location: string): string => `Using ${location}`;
export const Vapes = "Vapes";
export const VAPORIZERS = "Vaporizers";
export const VAPE_PENS = "Vape Pens";
export const VERIFICATION_EMAIL_SENT =
  "A verification email has been sent. Please check your inbox to finish verifying your email.";
export const VERIFY_YOUR_EMAIL = "Verify Your Email";
export const VERY_STRONG = "Very Strong";
export const VIEW_ALL = "View all";
export const VIEW_ALL_PODS = "View All Pods";
export const VIEW_ALL_PODS_IN_UNITED_STATES =
  "View all pods in the United States";
export const VIEW_ALL_PODS_IN_CANADA = "View all pods in Canada";
export const viewAllPodsInLocation = (locationDisplayName: string): string =>
  `View all pods in ${locationDisplayName.split(",")[1]?.trim()}`;
export const WEB = "Web";
export const WELCOME_TO_PAX = "Welcome To PAX";
export const WE_KNOW_YOU_LOVE = "We know you love";
export const weKnowYouLoveThisPod = (strainName: string): string =>
  `We know you love ${strainName}! Sign in to save it and see cool things like your pod history.`;
export const WHATS_DOSE_CONTROL = "What's Dose Control?";
export const WHICH_EFFECTS_DID_YOU_FEEL = "Which effects did you feel?";
export const WHERE_ARE_YOU = "Where are you?";
export const YOUR_REVIEW_WILL_HELP =
  "Your review will help other PAX users make better decisions about which pods are right for them.";
export const YOURE_USING_A_BETA_FIRMWARE = (
  <span>
    You&apos;re using a beta firmware version, go to the{" "}
    <a href={c.BETA_URL}>{BETA_APP}</a> to access all beta features.
  </span>
);
const ZIP_CODE = "ZIP Code";
