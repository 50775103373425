import React from "react";
import styled, { SimpleInterpolation } from "styled-components";

type ToggleProps = {
  active: boolean;
  onClick?: () => unknown;
};

export const Toggle: React.FC<ToggleProps> = ({ active, ...props }) => {
  return (
    <sc.Background active={active} {...props}>
      <sc.Circle active={active} />
    </sc.Background>
  );
};

type ActiveProp = {
  active: boolean;
};

const sc = {
  Background: styled.div<ActiveProp>`
    background-color: ${({ active }): SimpleInterpolation =>
      active ? "var(--black)" : "var(--very-light-pink)"};
    border-radius: 12px;
    cursor: pointer;
    height: 24px;
    position: relative;
    width: 40px;
  `,

  Circle: styled.div<ActiveProp>`
    background-color: var(--white);
    border-radius: 32px;
    box-shadow: 0 0 2px 0 var(--light-grey-blue-50);
    height: 20px;
    left: ${({ active }): SimpleInterpolation => (active ? "18" : "2")}px;
    position: absolute;
    top: 2px;
    transition: left 0.1s linear;
    width: 20px;
  `,
};
