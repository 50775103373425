import { connect } from "react-redux";

import * as PaxBleTypes from "../../../pax-ble/types";

import * as consumerApiActions from "../../api/consumer/actions/devices";
import * as deviceActions from "../../device/actions";
import * as c from "../../device/constants";
import * as deviceSelectors from "../../device/selectors";
import * as t from "../../main/types";
import { showFlashBanner } from "../../shared/components/FlashBanner";

import { setColorMode } from "../actions";
import {
  EraDeviceSettingsModal,
  StateProps,
} from "../components/EraDeviceSettingsModal";

const mapStateToProps = (state: t.MainAppState): StateProps => {
  const colorTheme = state.device.attributes[c.ATTRIBUTE_COLOR_THEME];
  const colorMode = colorTheme && colorTheme[0];

  return {
    brightness: deviceSelectors.getBrightness(state),
    colorMode,
    deviceName: deviceSelectors.getDeviceName(state),
    firmwareRevision: deviceSelectors.getFirmwareRevision(state),
    isDeviceLocked: deviceSelectors.getIsDeviceLocked(state),
    serialNumber: deviceSelectors.getDeviceSerialNumber(
      state,
      PaxBleTypes.DeviceType.ERA
    ),
    supportsRenameDevice: !!window.paxConnectedDevice?.supportsRenameDevice,
  };
};

const mapDispatchToProps = {
  disconnectDevice: deviceActions.disconnectDevice,
  setBrightness: deviceActions.setBrightness,
  setColorMode,
  setDeviceName: deviceActions.setDeviceName,
  showFlashBanner,
  updateDevice: consumerApiActions.updateDevice,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EraDeviceSettingsModal);
