import { connect } from "react-redux";

import { MainAppState } from "../../main/types";

import { forgotPassword } from "../actions";
import { SsoForgotPasswordPage } from "../components/SsoForgotPasswordPage";

const mapStateToProps = (state: MainAppState) => {
  return state.sso.forgotPassword;
};

const mapDispatchToProps = {
  forgotPassword,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SsoForgotPasswordPage);
