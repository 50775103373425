import React, { useState } from "react";
import styled, { css } from "styled-components";

import * as consumerApi from "../../api/consumer";
import * as t from "../../api/consumer/types";
import { ChevronCard } from "../../shared/components/ChevronCard";
import { Column } from "../../shared/components/Flex";
import * as Text from "../../shared/components/Text";
import { useDesktopMediaQuery } from "../../shared/hooks";
import * as text from "../../shared/text";
import { desktopBreakpoint } from "../../shared/utils";

import { EffectsRow } from "./EffectsRow";
import { PodFeedbackModal, PodFeedbackModalType } from "./PodFeedbackModal";
import { StarRating } from "./StarRating";

export type PodFeedbackMyReviewProps = {
  onEditComplete: () => void;
  review?: consumerApi.types.reviews.StrainReviewJson | null;
};

export const PodFeedbackMyReview: React.FC<PodFeedbackMyReviewProps> = ({
  onEditComplete,
  review,
  ...props
}) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [modalType, setModalType] = useState<PodFeedbackModalType>();

  if (!review) return null;

  const handleModalClose = (): void => {
    setIsModalOpen(false);
    setModalType(undefined);
    onEditComplete();
  };

  const handleEditClick = (): void => {
    setModalType(PodFeedbackModalType.EDIT);
    setIsModalOpen(true);
  };

  const handleReviewClick = (): void => {
    setModalType(PodFeedbackModalType.PREVIEW);
    setIsModalOpen(true);
  };

  const reviewDateString = new Date(review.clientTimestamp).toLocaleDateString(
    "en-US",
    {
      day: "numeric",
      month: "short",
      year: "numeric",
    }
  );

  return (
    <sc.Container {...props}>
      <sc.BoxContainer data-testid="your-feedback-box">
        <sc.Title>{text.MY_REVIEW}</sc.Title>
        <sc.CustomChevronCard hideChevron>
          <sc.SubTitle>{reviewDateString}</sc.SubTitle>
          <ReviewInformation
            data-testid="review-information"
            onReviewClick={handleReviewClick}
            review={review}
          />
          <sc.EditButton
            data-testid="edit-feedback-button"
            onClick={handleEditClick}
          >
            {text.EDIT}
          </sc.EditButton>
        </sc.CustomChevronCard>
      </sc.BoxContainer>
      <PodFeedbackModal
        initialModalType={modalType}
        initialValues={review}
        isOpen={isModalOpen}
        reviewId={review.id}
        onRequestClose={handleModalClose}
        strainId={review.strainId}
        submitLabel="Update"
      />
    </sc.Container>
  );
};

type ReviewInformationProps = {
  onReviewClick: () => void;
  review: t.reviews.StrainReviewJson;
};

const ReviewInformation: React.FC<ReviewInformationProps> = ({
  onReviewClick,
  review,
}) => {
  const isDesktop = useDesktopMediaQuery();
  const textStringMax = isDesktop ? 85 : 75;
  const willReviewFit = review.text.length < textStringMax;

  const textString = willReviewFit
    ? review.text
    : `${review.text.slice(0, textStringMax)}...`;

  return (
    <sc.Column onClick={onReviewClick}>
      <sc.StarRating data-testid="rating" disabled value={review.rating} />
      {textString.length > 0 && (
        <sc.NoteContainer>
          <sc.TextValue data-testid="notes">
            &ldquo;{textString}&rdquo;
          </sc.TextValue>
          {!willReviewFit && <sc.SeeMoreLink>{text.SEE_MORE}</sc.SeeMoreLink>}
        </sc.NoteContainer>
      )}
      <EffectsRow effects={review.selectedEffects} />
    </sc.Column>
  );
};

const sc = {
  BoxContainer: styled.div`
    margin-top: 16px;

    ${desktopBreakpoint(
      css`
        margin-top: 0;
        width: 50%;
      `
    )}
  `,

  Column: styled(Column)`
    cursor: pointer;
    flex: 1;
    margin-right: 16px;
    overflow: hidden;
  `,

  Container: styled.div`
    width: 100%;

    ${desktopBreakpoint(
      css`
        border-top: none;
        flex-direction: row;
        justify-content: space-between;
        padding: 0;
      `
    )}
  `,

  CustomChevronCard: styled(ChevronCard)`
    align-items: normal;
    border: solid 1px var(--khaki-20);
    box-shadow: none;
    flex-direction: column;
    position: relative;
  `,

  EditButton: styled.div`
    ${Text.PlaaxLight}
    align-items: center;
    cursor: pointer;
    display: flex;
    height: 56px;
    justify-content: center;
    position: absolute;
    right: 0;
    text-decoration: underline;
    top: 0;
    width: 70px;
  `,

  NoteContainer: styled(Column)`
    margin: 8px 0 16px;
  `,

  SeeMoreLink: styled.div`
    ${Text.PlaaxLight}
    font-size: 12px;
    margin: 4px 0 16px;
    text-decoration: underline;
  `,

  StarRating: styled(StarRating)`
    margin-bottom: 16px;
  `,

  SubTitle: styled.div`
    ${Text.PlaaxBold}
    font-size: 14px;
    letter-spacing: -0.12px;
    line-height: 19.84px;
    margin-bottom: 8px;
  `,

  TextValue: styled.div`
    ${Text.PlaaxLight}
    font-size: 14px;
    line-height: 24px;
    max-height: 48px;
    overflow: hidden;
  `,

  Title: styled.div`
    font-size: 20px;
    letter-spacing: -0.21px;
    line-height: 28px;
    margin-bottom: 8px;
  `,
};
