// TODO remove app import
import * as Icons from "../../app/pax-3/components/Icons";

import * as t from "../types";

export const COLOR_MODE_DEFAULT: t.Pax3.ColorMode = {
  heating: {
    animation: 0,
    color1: {
      blue: 0,
      green: 0,
      red: 0,
    },
    color2: {
      blue: 0,
      green: 0,
      red: 0,
    },
    frequency: 0,
  },
  regulating: {
    animation: 0,
    color1: {
      blue: 0,
      green: 0,
      red: 0,
    },
    color2: {
      blue: 0,
      green: 0,
      red: 0,
    },
    frequency: 0,
  },
  standby: {
    animation: 0,
    color1: {
      blue: 0,
      green: 0,
      red: 0,
    },
    color2: {
      blue: 0,
      green: 0,
      red: 0,
    },
    frequency: 0,
  },
  startup: {
    animation: 0,
    color1: {
      blue: 0,
      green: 0,
      red: 0,
    },
    color2: {
      blue: 0,
      green: 0,
      red: 0,
    },
    frequency: 0,
  },
};

export const COLOR_MODE_OCEAN: t.Pax3.ColorMode = {
  heating: {
    animation: t.HEATING_STATE_COLOR_MODE_ANIMATIONS.DISPLAY_HEATING_UP_FADE,
    color1: {
      blue: 40,
      green: 7,
      red: 19,
    },
    color2: {
      blue: 255,
      green: 0,
      red: 40,
    },
    frequency: t.COLOR_MODE_FREQUENCIES.DISPLAY_FREQ_1P37HZ,
  },
  regulating: {
    animation: t.HEATING_STATE_COLOR_MODE_ANIMATIONS.DISPLAY_HEATING_PLASMA,
    color1: {
      blue: 67,
      green: 92,
      red: 36,
    },
    color2: {
      blue: 255,
      green: 0,
      red: 0,
    },
    frequency: t.COLOR_MODE_FREQUENCIES.DISPLAY_FREQ_0P5HZ,
  },
  standby: {
    animation: t.HEATING_STATE_COLOR_MODE_ANIMATIONS.DISPLAY_HEATING_DOWN_FADE,
    color1: {
      blue: 20,
      green: 20,
      red: 0,
    },
    color2: {
      blue: 255,
      green: 0,
      red: 0,
    },
    frequency: t.COLOR_MODE_FREQUENCIES.DISPLAY_FREQ_0P25HZ,
  },
  startup: {
    animation: t.HEATING_STATE_COLOR_MODE_ANIMATIONS.DISPLAY_HEATING_UP_FADE,
    color1: {
      blue: 255,
      green: 0,
      red: 0,
    },
    color2: {
      blue: 117,
      green: 108,
      red: 0,
    },
    frequency: t.COLOR_MODE_FREQUENCIES.DISPLAY_FREQ_1P5HZ,
  },
};

export const COLOR_MODE_SUNSET: t.Pax3.ColorMode = {
  heating: {
    animation: t.HEATING_STATE_COLOR_MODE_ANIMATIONS.DISPLAY_HEATING_UP_FADE,
    color1: {
      blue: 40,
      green: 115,
      red: 255,
    },
    color2: {
      blue: 104,
      green: 0,
      red: 153,
    },
    frequency: t.COLOR_MODE_FREQUENCIES.DISPLAY_FREQ_1P25HZ,
  },
  regulating: {
    animation: t.HEATING_STATE_COLOR_MODE_ANIMATIONS.DISPLAY_HEATING_PLASMA,
    color1: {
      blue: 40,
      green: 149,
      red: 253,
    },
    color2: {
      blue: 136,
      green: 0,
      red: 153,
    },
    frequency: t.COLOR_MODE_FREQUENCIES.DISPLAY_FREQ_0P75HZ,
  },
  standby: {
    animation: t.HEATING_STATE_COLOR_MODE_ANIMATIONS.DISPLAY_HEATING_DOWN_FADE,
    color1: {
      blue: 125,
      green: 114,
      red: 255,
    },
    color2: {
      blue: 154,
      green: 64,
      red: 0,
    },
    frequency: t.COLOR_MODE_FREQUENCIES.DISPLAY_FREQ_0P25HZ,
  },
  startup: {
    animation:
      t.STARTUP_STATE_COLOR_MODE_ANIMATIONS.DISPLAY_STARTUP_FLICKER_SWIRL,
    color1: {
      blue: 40,
      green: 115,
      red: 255,
    },
    color2: {
      blue: 104,
      green: 0,
      red: 153,
    },
    frequency: t.COLOR_MODE_FREQUENCIES.DISPLAY_FREQ_0P2HZ,
  },
};

export const COLOR_MODE_MARS: t.Pax3.ColorMode = {
  heating: {
    animation: t.HEATING_STATE_COLOR_MODE_ANIMATIONS.DISPLAY_HEATING_UP_FADE,
    color1: {
      blue: 0,
      green: 0,
      red: 255,
    },
    color2: {
      blue: 1,
      green: 2,
      red: 3,
    },
    frequency: t.COLOR_MODE_FREQUENCIES.DISPLAY_FREQ_1P5HZ,
  },
  regulating: {
    animation: t.HEATING_STATE_COLOR_MODE_ANIMATIONS.DISPLAY_HEATING_PLASMA,
    color1: {
      blue: 3,
      green: 22,
      red: 255,
    },
    color2: {
      blue: 1,
      green: 2,
      red: 3,
    },
    frequency: t.COLOR_MODE_FREQUENCIES.DISPLAY_FREQ_0P5HZ,
  },
  standby: {
    animation: t.HEATING_STATE_COLOR_MODE_ANIMATIONS.DISPLAY_HEATING_DOWN_FADE,
    color1: {
      blue: 10,
      green: 30,
      red: 250,
    },
    color2: {
      blue: 12,
      green: 15,
      red: 4,
    },
    frequency: t.COLOR_MODE_FREQUENCIES.DISPLAY_FREQ_0P33HZ,
  },
  startup: {
    animation: t.STARTUP_STATE_COLOR_MODE_ANIMATIONS.DISPLAY_STARTUP_STORM,
    color1: {
      blue: 0,
      green: 0,
      red: 250,
    },
    color2: {
      blue: 0,
      green: 20,
      red: 60,
    },
    frequency: t.COLOR_MODE_FREQUENCIES.DISPLAY_FREQ_4HZ,
  },
};

type HeatingParametersOptionsProps = {
  boostEnable: boolean;
  customTemperatureEnable: boolean;
  heaterEnable: boolean;
  noLipCoolingEnable: boolean;
  noLipShutdownEnable: boolean;
  rampContinueEnable: boolean;
  rampEnable: boolean;
  standbyEnable: boolean;
};

const heatingParametersOptionsToInt = (
  heatingOptions: HeatingParametersOptionsProps
): number => {
  let optionsString = "";

  Object.values(heatingOptions).forEach((flag) => {
    optionsString = (flag ? "1" : "0") + optionsString;
  });

  return parseInt(optionsString, 2);
};

export const HEATING_PARAMETERS_STANDARD: t.Pax3.HeatingParameters = {
  boostTemperatureChange: 39,
  noLipCooldownRate: 30,
  noLipCooldownStart: 20,
  noLipCooldownTemperatureChange: 150,
  noLipPowerOffTime: 180,
  noMotionToStandbyTime: 30,
  options: heatingParametersOptionsToInt({
    boostEnable: true,
    customTemperatureEnable: true,
    heaterEnable: true,
    noLipCoolingEnable: true,
    noLipShutdownEnable: true,
    rampContinueEnable: false,
    rampEnable: false,
    standbyEnable: true,
  }),
  rampRate: 20,
  rampStartingTemperature: 1990,
  rampTargetTemperature: 2300,
  standbyTemperature: 1600,
};

export const HEATING_PARAMETERS_BOOST: t.Pax3.HeatingParameters = {
  boostTemperatureChange: 112,
  noLipCooldownRate: 20,
  noLipCooldownStart: 30,
  noLipCooldownTemperatureChange: 70,
  noLipPowerOffTime: 180,
  noMotionToStandbyTime: 60,
  options: heatingParametersOptionsToInt({
    boostEnable: true,
    customTemperatureEnable: true,
    heaterEnable: true,
    noLipCoolingEnable: true,
    noLipShutdownEnable: true,
    rampContinueEnable: false,
    rampEnable: false,
    standbyEnable: true,
  }),
  rampRate: 20,
  rampStartingTemperature: 1990,
  rampTargetTemperature: 2300,
  standbyTemperature: 1750,
};

export const HEATING_PARAMETERS_EFFICIENCY: t.Pax3.HeatingParameters = {
  boostTemperatureChange: 39,
  noLipCooldownRate: 30,
  noLipCooldownStart: 20,
  noLipCooldownTemperatureChange: 150,
  noLipPowerOffTime: 180,
  noMotionToStandbyTime: 30,
  options: heatingParametersOptionsToInt({
    boostEnable: true,
    customTemperatureEnable: false,
    heaterEnable: true,
    noLipCoolingEnable: true,
    noLipShutdownEnable: true,
    rampContinueEnable: false,
    rampEnable: true,
    standbyEnable: true,
  }),
  rampRate: 20,
  rampStartingTemperature: 2050,
  rampTargetTemperature: 2350,
  standbyTemperature: 1600,
};

export const HEATING_PARAMETERS_STEALTH: t.Pax3.HeatingParameters = {
  boostTemperatureChange: 0,
  noLipCooldownRate: 100,
  noLipCooldownStart: 9,
  noLipCooldownTemperatureChange: 300,
  noLipPowerOffTime: 180,
  noMotionToStandbyTime: 15,
  options: heatingParametersOptionsToInt({
    boostEnable: true,
    customTemperatureEnable: true,
    heaterEnable: true,
    noLipCoolingEnable: true,
    noLipShutdownEnable: true,
    rampContinueEnable: false,
    rampEnable: false,
    standbyEnable: true,
  }),
  rampRate: 20,
  rampStartingTemperature: 2040,
  rampTargetTemperature: 2300,
  standbyTemperature: 1200,
};

export const HEATING_PARAMETERS_FLAVOR: t.Pax3.HeatingParameters = {
  boostTemperatureChange: 84,
  noLipCooldownRate: 100,
  noLipCooldownStart: 10,
  noLipCooldownTemperatureChange: 250,
  noLipPowerOffTime: 180,
  noMotionToStandbyTime: 15,
  options: heatingParametersOptionsToInt({
    boostEnable: true,
    customTemperatureEnable: true,
    heaterEnable: true,
    noLipCoolingEnable: true,
    noLipShutdownEnable: true,
    rampContinueEnable: false,
    rampEnable: false,
    standbyEnable: true,
  }),
  rampRate: 20,
  rampStartingTemperature: 2040,
  rampTargetTemperature: 2300,
  standbyTemperature: 1600,
};

export const DYNAMIC_MODE_STANDARD: t.Pax3.DynamicMode = {
  Icon: Icons.StandardMode,
  description:
    "is the default setting for the PAX 3™. Temperature boosts when you draw, auto-cools when you don't. The perfect Standard.",
  heatingParameters: HEATING_PARAMETERS_STANDARD,
  label: "Standard",
  type: t.Pax3.DynamicModeType.STANDARD,
};

export const DYNAMIC_MODE_BOOST: t.Pax3.DynamicMode = {
  Icon: Icons.BoostMode,
  description:
    "keeps your device in high gear. Temperature boosts aggressively and auto-cools slower. Get what you need with speed.",
  heatingParameters: HEATING_PARAMETERS_BOOST,
  label: "Boost",
  type: t.Pax3.DynamicModeType.BOOST,
};

export const DYNAMIC_MODE_EFFICIENCY: t.Pax3.DynamicMode = {
  Icon: Icons.EfficiencyMode,
  description:
    "doesn't waste a drop. Temperature setting ramps up gradually throughout your session in addition to Standard temperature boost and auto-cooling. Economic and long-lasting.",
  heatingParameters: HEATING_PARAMETERS_EFFICIENCY,
  label: "Efficiency",
  type: t.Pax3.DynamicModeType.EFFICIENCY,
};

export const DYNAMIC_MODE_STEALTH: t.Pax3.DynamicMode = {
  Icon: Icons.StealthMode,
  brightness: 0.3,
  description:
    "is for ultimate discretion. LEDs dim, auto-cools fast. Reduced material odor means increased privacy.",
  heatingParameters: HEATING_PARAMETERS_STEALTH,
  label: "Stealth",
  type: t.Pax3.DynamicModeType.STEALTH,
};

export const DYNAMIC_MODE_FLAVOR: t.Pax3.DynamicMode = {
  Icon: Icons.FlavorMode,
  description:
    "is the most delicious possible. Temperature boosts more during draw and decreases quickly after draw. The most on-demand heating ever.",
  heatingParameters: HEATING_PARAMETERS_FLAVOR,
  label: "Flavor",
  type: t.Pax3.DynamicModeType.FLAVOR,
};

export const DYNAMIC_MODES = [
  DYNAMIC_MODE_STANDARD,
  DYNAMIC_MODE_BOOST,
  DYNAMIC_MODE_EFFICIENCY,
  DYNAMIC_MODE_STEALTH,
  DYNAMIC_MODE_FLAVOR,
];
