import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import * as consumerApi from "../../api/consumer";
import * as partnerActions from "../../api/consumer/actions/partners";
import { fetch } from "../../api/consumer/fetch";
import * as partnerSelectors from "../../api/consumer/selectors/partners";
import { PlaaxButton } from "../../shared/components/Button";
import { Dropdown as SharedDropdown } from "../../shared/components/Dropdown";
import * as flashBanner from "../../shared/components/FlashBanner";
import * as Text from "../../shared/components/Text";
import * as text from "../../shared/text";

type NewStrain = {
  partnerName: string;
  description: string;
};

type CreateMissingStrainReport = (newStrain: NewStrain) => Promise<NewStrain>;

const createMissingStrainReportRequest: CreateMissingStrainReport = (
  newStrain
) => {
  return fetch<NewStrain>(consumerApi.paths.strains.missingStrainReports(), {
    data: newStrain,
    method: "POST",
  });
};

type FormErrors = {
  brand?: string;
  strain?: string;
};

const validateMissingStrainForm = (values: ReportMissingStrainFormValues) => {
  const errors: FormErrors = {};

  if (!values.brand.label || !values.brand.value) {
    errors.brand = text.BRAND_IS_REQUIRED;
  }

  if (!values.strain) {
    errors.strain = text.STRAIN_IS_REQUIRED;
  }

  return errors;
};

type ReportMissingStrainFormValues = {
  brand: {
    label: string;
    value: string;
  };
  strain: string;
};

const initialFormValues: ReportMissingStrainFormValues = {
  brand: {
    label: text.SELECT_A_BRAND,
    value: "",
  },
  strain: "",
};

export type ReportMissingStrainFormProps = {
  createMissingStrainReport?: CreateMissingStrainReport;
  fetchPartners?: partnerActions.FetchPartners;
  getPartners?: consumerApi.selectors.partners.GetPartners;
  onSubmit: () => void;
};

export const ReportMissingStrainForm: React.FC<ReportMissingStrainFormProps> =
  ({
    createMissingStrainReport = createMissingStrainReportRequest,
    fetchPartners = partnerActions.fetchPartners,
    getPartners = partnerSelectors.getPartners,
    onSubmit,
  }) => {
    const [hasSubmitButtonBeenPressed, setHasSubmitButtonBeenPressed] =
      useState<boolean>(false);

    const dispatch = useDispatch();

    useEffect(() => {
      dispatch(fetchPartners());
    }, [dispatch, fetchPartners]);

    const allPartners = useSelector(getPartners);

    const partnerOptions = allPartners.map((partner) => ({
      label: partner.partnerName,
      value: partner.id,
    }));

    const handleSubmit = async (
      formValues: ReportMissingStrainFormValues
    ): Promise<void> => {
      try {
        await createMissingStrainReport({
          description: formValues.strain,
          partnerName: formValues.brand.label,
        });

        dispatch(
          flashBanner.showFlashBanner(
            text.MISSING_STRAIN_REPORTED,
            flashBanner.BannerType.SUCCESS
          )
        );
      } catch (error) {
        dispatch(
          flashBanner.showFlashBanner(
            text.OOPS_AN_ERROR_OCCURRED_TRY_AGAIN,
            flashBanner.BannerType.ERROR
          )
        );
      }

      onSubmit();
    };

    const formik = useFormik({
      initialValues: initialFormValues,
      onSubmit: handleSubmit,
      validate: validateMissingStrainForm,
    });

    const brandFieldError = hasSubmitButtonBeenPressed &&
      formik.errors.brand && (
        <sc.ValidationError>
          <Text.ErrorSpan>{formik.errors.brand}</Text.ErrorSpan>
        </sc.ValidationError>
      );

    const strainFieldError = hasSubmitButtonBeenPressed &&
      formik.errors.strain && (
        <sc.ValidationError>
          <Text.ErrorSpan>{formik.errors.strain}</Text.ErrorSpan>
        </sc.ValidationError>
      );

    return (
      <form onSubmit={formik.handleSubmit}>
        <sc.FormSection>
          <sc.Label>
            {text.BRAND}
            <sc.Dropdown
              classNamePrefix="missing-strain"
              name="brand"
              onChange={(option: consumerApi.types.partner.PartnerJson[]) =>
                formik.setFieldValue("brand", option)
              }
              options={partnerOptions}
              value={formik.values.brand}
            />
            {brandFieldError}
          </sc.Label>
        </sc.FormSection>
        <sc.Label>
          {text.STRAIN}
          <sc.Input
            maxLength={32}
            name="strain"
            onChange={formik.handleChange}
            placeholder={text.eg(text.HYPER)}
            value={formik.values.strain}
          />
          {strainFieldError}
        </sc.Label>
        <sc.ButtonContainer>
          <sc.Button
            onClick={() => setHasSubmitButtonBeenPressed(true)}
            type="submit"
          >
            {text.SUBMIT}
          </sc.Button>
        </sc.ButtonContainer>
      </form>
    );
  };

const sc = {
  Button: styled(PlaaxButton)`
    margin-top: 8px;
    min-width: 170px;
  `,

  ButtonContainer: styled.div`
    display: grid;
    justify-content: center;
    margin-top: 30px;
  `,

  Dropdown: styled(SharedDropdown)`
    margin-bottom: 8px;

    .missing-strain__single-value {
      text-transform: none;
    }

    .missing-strain__menu {
      height: 250px;
    }
  `,

  FormSection: styled.div`
    margin-bottom: 30px;
  `,

  Input: styled.input`
    border: 1px solid black;
    border-radius: 100px;
    font-size: 14px;
    font-weight: 300;
    height: 40px;
    left: 585px;
    margin-top: 20px;
    padding: 0 20px 0 20px;
    width: 100%;

    &:hover,
    &:focus {
      border: 1px solid rgba(151, 166, 105, 0.2);
      outline: none;
    }
  `,

  Label: styled.label`
    ${Text.PlaaxLight}
    font-size: 20px;
    margin-bottom: 14px;
    margin-top: 50px;

    > div {
      margin-top: 14px;
    }
  `,

  ValidationError: styled.div`
    font-size: 16px;
  `,
};
