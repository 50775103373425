import React from "react";
import { Link } from "react-router-dom";

import * as routes from "../../main/routes";
import * as utils from "../../sso/utils";

import { GoToSso } from "../types";

export type SsoLinkProps = {
  button: React.ReactElement;
  goToSso?: GoToSso;
  returnTo: string;
  ssoRouteTo: routes.SsoRouteTo;
};

export const SsoLink: React.FC<SsoLinkProps> = ({
  button,
  goToSso = utils.goToSso,
  returnTo,
  ssoRouteTo,
}) => {
  const handleClick = (): void => {
    const route = ssoRouteTo({
      returnTo,
    });

    goToSso(route);
  };

  return (
    <Link to="#" onClick={handleClick}>
      {button}
    </Link>
  );
};
