import React from "react";
import styled from "styled-components";

import { ReactComponent as Star } from "../../assets/images/star.svg";
import { Row } from "../../shared/components/Flex";
import * as Text from "../../shared/components/Text";
import { useDesktopMediaQuery } from "../../shared/hooks";
import { REVIEWS } from "../../shared/text";

import { StarFillGradientId, StarFillLevel } from "../types";

const MAX_STARS = 5;
const MIN_RATINGS_COUNT = 5;

const getStarFillGradient = (
  starPosition: number,
  ratingsAverage: number
): string => {
  const decimal = ratingsAverage % 1;

  if (starPosition <= ratingsAverage) return StarFillGradientId.FILLED;

  if (
    starPosition > Math.ceil(ratingsAverage) ||
    decimal < StarFillLevel.GRADIENT_1
  ) {
    return StarFillGradientId.EMPTY;
  } else if (
    decimal >= StarFillLevel.GRADIENT_1 &&
    decimal < StarFillLevel.GRADIENT_2
  ) {
    return StarFillGradientId.GRADIENT_1;
  } else if (
    decimal >= StarFillLevel.GRADIENT_2 &&
    decimal < StarFillLevel.GRADIENT_3
  ) {
    return StarFillGradientId.GRADIENT_2;
  } else {
    return StarFillGradientId.GRADIENT_3;
  }
};

export type PodAverageRatingProps = {
  starIconSize?: string;
  fontSize?: string;
  ratingsAverage: number;
  ratingsCount: number;
  shouldShowSuffix?: boolean;
};

export const PodAverageRating: React.FC<PodAverageRatingProps> = ({
  fontSize,
  ratingsAverage,
  ratingsCount,
  shouldShowSuffix = false,
  starIconSize: iconSize = "12px",
  ...props
}) => {
  const isDesktop = useDesktopMediaQuery();

  if (ratingsCount < MIN_RATINGS_COUNT) {
    return null;
  }

  return (
    <Row alignItems="center" data-testid="star-row" {...props}>
      <Row>
        {Array(MAX_STARS)
          .fill("")
          .map((_, index) => (
            <sc.Star
              $fillGradient={getStarFillGradient(index + 1, ratingsAverage)}
              $size={iconSize}
              data-testid={getStarFillGradient(index + 1, ratingsAverage)}
              key={`star-${index + 1}`}
            />
          ))}
      </Row>
      <sc.RatingsCount $fontSize={fontSize}>
        {ratingsCount} {isDesktop && shouldShowSuffix && REVIEWS}
      </sc.RatingsCount>
    </Row>
  );
};

type RatingsCountProps = {
  $fontSize?: string;
};

type StarProps = {
  $fillGradient?: string;
  $size?: string;
};

const sc = {
  RatingsCount: styled.div<RatingsCountProps>`
    ${Text.MediumLLBold}
    color: var(--black-40);
    font-size: ${({ $fontSize }) => $fontSize || "12px"};
    margin-left: 10px;
    text-transform: uppercase;
  `,

  Star: styled(Star)<StarProps>`
    height: ${({ $size }) => $size || "12px"};
    margin: 0 2px;
    width: ${({ $size }) => $size || "12px"};

    > path {
      fill: ${({ $fillGradient }) => `url(#${$fillGradient})`};
    }
  `,
};
