import React from "react";
import styled from "styled-components";

import { Row } from "../../shared/components/Flex";
import { StickyHeader } from "../../shared/components/StickyHeader";
import * as h from "../../shared/hooks";

import { DESKTOP_SEARCH_HEADER_HEIGHT } from "../constants";
import * as u from "../utils";
import { PodsExploreHeader, QueryFieldContainer } from "./PodsExploreHeader";
import { PodsHomeQueryField } from "./PodsHomeQueryField";
import { PodsLocationHeader } from "./PodsLocationHeader";

const MOBILE_HEADER_HEIGHT = 64;

export type PodsHomePageHeaderProps = {
  useDesktopMediaQuery?: h.UseDesktopMediaQuery;
  goToPodsSearchPage?: u.GoToPodsSearchPage;
};

export const PodsHomePageHeader: React.FC<PodsHomePageHeaderProps> = ({
  useDesktopMediaQuery = h.useDesktopMediaQuery,
}) => {
  const isDesktop = useDesktopMediaQuery();

  const renderDesktopHeaderContent = (): React.ReactElement => (
    <Row alignItems="center">
      <QueryFieldContainer>
        <PodsHomeQueryField />
      </QueryFieldContainer>
    </Row>
  );

  const renderMobileHeaderContent = (): React.ReactElement => (
    <StickyHeader containerHeight={MOBILE_HEADER_HEIGHT}>
      <sc.MobileContainer>
        <PodsLocationHeader />
      </sc.MobileContainer>
    </StickyHeader>
  );

  return isDesktop ? (
    <StickyHeader containerHeight={DESKTOP_SEARCH_HEADER_HEIGHT}>
      <PodsExploreHeader
        renderHeaderContent={renderDesktopHeaderContent}
        shouldShowTitle
      />
    </StickyHeader>
  ) : (
    <PodsExploreHeader
      renderHeaderContent={renderMobileHeaderContent}
      shouldShowTitle
    />
  );
};

const sc = {
  MobileContainer: styled.div`
    box-shadow: var(--mobile-header-box-shadow);
    position: relative;
  `,
};
