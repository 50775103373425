import styled, { css, keyframes } from "styled-components";

import { Row } from "../shared/components/Flex";
import * as Text from "../shared/components/Text";
import { desktopBreakpoint } from "../shared/utils";

export const Button = styled.div`
  ${Text.MediumLLLight}
  border-top: solid 1px var(--black-10);
  cursor: pointer;
  font-size: 14px;
  height: 35px;
  padding-top: 8px;
  text-align: center;
  text-transform: uppercase;
`;

export const Message = styled.div`
  ${Text.MediumLLLight}
  color: var(--black-50);
  font-size: 14px;
  line-height: 1.43;
  margin: 0 17px 16px;
`;

export const Notification = styled.div`
  background-color: var(--white);
  border: solid 1px var(--bluey-grey-30);
  border-radius: 8px;
  margin: 0 auto 12px;
  padding-top: 12px;
  width: 100%;

  &:last-child {
    margin: 0 auto;
  }
`;

export const SimpleMessage = styled.div`
  ${Text.MediumLLLight}
  color: var(--black-50);
  font-size: 14px;
  line-height: 1;
`;

type SpinnerProps = {
  color?: string;
};

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled.div<SpinnerProps>`
  animation: ${spin} 1s linear infinite;
  border: 1.5px solid ${({ color }) => (color ? color : "var(--black-50)")};
  border-radius: 50%;
  border-right-color: transparent;
  height: 11px;
  margin-left: 5px;
  margin-top: 2px;
  width: 11px;
`;

export const SpinnerRow = styled(Row)`
  margin-left: 17px;
  padding-bottom: 20px;
  padding-top: 12px;

  ${desktopBreakpoint(
    css`
      width: 215px;
    `
  )}
`;

export const Title = styled.div`
  color: var(--black);
  font-size: 14px;
  height: 20px;
  line-height: 1.43;
  margin-bottom: 4px;
  margin-left: 17px;
  text-transform: uppercase;
`;
