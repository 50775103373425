import styled, { css, SimpleInterpolation } from "styled-components";

import { Modal } from "../../../modal/components/Modal";
import { Dropdown as SharedDropdown } from "../../../shared/components/Dropdown";
import { Row } from "../../../shared/components/Flex";
import { ChevronLeft } from "../../../shared/components/Icons";
import { PillSelectOption } from "../../../shared/components/PillSelect";
import * as Text from "../../../shared/components/Text";
import { desktopBreakpoint } from "../../../shared/utils";

export const BackArrow = styled(ChevronLeft)`
  height: 25px;
  left: 12px;
  position: absolute;
  width: 25px;

  &:hover {
    background: var(--black);
    border-radius: 50px;
    cursor: pointer;
  }

  &:hover path {
    fill: var(--white);
  }

  ${desktopBreakpoint(
    css`
      display: none;
    `
  )}
`;

export type BasePillProps = PillSelectOption & {
  activeBackgroundColor: string;
};

export const BasePill = styled(Row)<BasePillProps>`
  ${(props) => !props.active && Text.PlaaxLight}
  align-items: center;
  background-color: ${(props): SimpleInterpolation =>
    props.active ? props.activeBackgroundColor : "var(--pale-grey)"};
  border-radius: 16px;
  box-shadow: 0 0 0 1px
    ${(props): SimpleInterpolation =>
      props.active ? "var(--black)" : "var(--pale-grey)"};
  cursor: pointer;
  font-size: 14px;
  height: 32px;
  justify-content: center;
  padding: 11px 12px 9px;
  text-transform: capitalize;
  transition: 0.2s;

  &:last-child {
    margin-right: 0;
  }
`;

export const ButtonsRow = styled(Row)`
  justify-content: center;

  > button:first-child {
    margin-right: 12px;
  }
`;

export const Dropdown = styled(SharedDropdown)`
  text-transform: capitalize;
`;

export const EffectsSelectContainer = styled(Row)`
  flex-wrap: wrap;
  height: auto;
`;

export const Filter = styled.div`
  margin-bottom: 12px;
  padding-bottom: 8px;

  &:last-child {
    margin-bottom: 24px;
  }

  ${desktopBreakpoint(
    css`
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 40px;
      }
    `
  )}
`;

export const PodsModal = styled(Modal)`
  border-radius: 0;
  box-shadow: none;

  ${desktopBreakpoint(
    css`
      border-radius: 8px;
      box-shadow: 0 1px 12px 0 var(--bluey-grey-80);
      max-height: 100%;
      overflow: auto;
    `
  )}
`;

export const Label = styled.label`
  ${Text.MediumLLBold}
  display: block;
  font-size: 14px;
  margin-bottom: 12px;
  text-transform: uppercase;

  > div {
    margin-top: 12px;
  }
`;

export const LabelForDropDown = styled.label`
  ${Text.MediumLLBold}
  display: block;
  font-size: 14px;
  text-transform: uppercase;

  > div {
    margin-top: 12px;
  }
`;

export const Header = styled(Row)`
  ${Text.MediumLLBold}
  align-items: center;
  background-color: var(--white);
  font-size: 28px;
  height: 48px;
  justify-content: flex-start;
  margin: 5px auto 10px;
  max-width: 500px;
  padding: 0 20px;
  position: sticky;
  text-transform: uppercase;
  top: 0;
  z-index: var(--z-index-modal-header);

  ${desktopBreakpoint(
    css`
      margin-bottom: 0;
      margin-top: 20px;
    `
  )}
`;

export const IconPillSelectContainer = styled.div`
  column-gap: 19px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 12px;

  ${desktopBreakpoint(
    css`
      grid-template-columns: 1fr 1fr;
    `
  )}
`;

const iconPillStyles = css<BasePillProps>`
  ${Text.MediumLLBold}
  background: ${({ active }) => (active ? "var(--black)" : "var(--white)")};
  border: 3px solid var(--black);
  border-radius: 6px;
  color: ${({ active }) => (active ? "var(--white)" : "var(--black)")};
  font-size: 12px;
  min-width: 100px;
  padding: 11px;

  & > div {
    width: 22px;
  }
`;

export const IconPillOption = styled(BasePill)<BasePillProps>`
  ${iconPillStyles}
  flex-direction: column;
  height: 81px;
  text-align: center;

  & > div {
    margin: 0 0 12px;
  }
`;

export const RowIconPillOption = styled(BasePill)<BasePillProps>`
  ${iconPillStyles}
  height: 52px;
  justify-content: flex-start;

  & > div {
    margin: 0 10px 4px 0;
  }
`;
