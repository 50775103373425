import React from "react";
import styled, { css } from "styled-components";

import { Row } from "../../shared/components/Flex";
import * as Text from "../../shared/components/Text";
import { useDesktopMediaQuery } from "../../shared/hooks";
import { EXPLORE } from "../../shared/text";
import { desktopBreakpoint } from "../../shared/utils";

type PodsExploreHeaderProps = {
  renderHeaderContent: () => React.ReactElement;
  podsActiveFilters?: React.ReactNode;
  shouldShowTitle?: boolean;
};

export const PodsExploreHeader: React.FC<PodsExploreHeaderProps> = ({
  renderHeaderContent,
  podsActiveFilters = null,
  shouldShowTitle = false,
}) => {
  const isDesktop = useDesktopMediaQuery();

  return (
    <>
      {!isDesktop && renderHeaderContent()}
      <sc.SearchRow>
        {shouldShowTitle && <sc.Title>{EXPLORE}</sc.Title>}
        {isDesktop && renderHeaderContent()}
      </sc.SearchRow>
      <sc.ActiveFilters>{podsActiveFilters}</sc.ActiveFilters>
    </>
  );
};

const sc = {
  ActiveFilters: styled.div`
    padding-left: 20px;

    ${desktopBreakpoint(
      css`
        padding-left: 0;
      `
    )}
  `,

  SearchRow: styled(Row)`
    align-items: center;
    justify-content: space-between;
    position: relative;
  `,

  Title: styled.div`
    ${Text.MediumLLBold}
    font-size: 44px;
    padding: 12px 16px 22px;
    text-transform: uppercase;

    ${desktopBreakpoint(
      css`
        font-size: 73px;
        padding: 12px 0 27px;
      `
    )}
  `,
};

export const QueryFieldContainer = styled.div`
  flex: 1;

  ${desktopBreakpoint(
    css`
      width: ${(props) => props.theme.queryField.width};
    `
  )}
`;
