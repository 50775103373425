// TODO: remove use of any
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ThunkDispatch } from "@reduxjs/toolkit";
import { ThunkAction } from "redux-thunk";

import { MainAppState } from "../main/types";

export type Action = {
  payload?: any;
  type: string;
};

export type AppThunk<ReturnType = any> = ThunkAction<
  ReturnType,
  MainAppState,
  unknown,
  Action
>;

export type DropdownOption = {
  label: string;
  value: string;
};

export type GetState = () => MainAppState;

export type MediaQueryChangeListener = (event: MediaQueryListEvent) => void;

export type UseDispatch = () => ThunkDispatch<MainAppState, null, Action>;

export type SearchParams = {
  [key: string]: string;
};

export enum SearchParam {
  CLOSE = "close",
  COUNTRY = "country",
  EMAIL = "email",
  REDIRECT = "return_to",
  POD_TYPE = "pod_type",
  TOKEN = "token",
}

export type StrainColor = {
  primary: string;
  secondary: string;
};
