import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled, { css } from "styled-components";

import * as PaxBleTypes from "../../../pax-ble/types";

import * as actions from "../../device/actions";
import { getDeviceError, getDeviceSerialNumber } from "../../device/selectors";
import { MainAppState } from "../../main/types";
import { Modal } from "../../modal/components/Modal";
import { EraProSerialLocation } from "../../shared/components/DeviceSerialLocationImages";
import { Column, Row } from "../../shared/components/Flex";
import * as serialInputForm from "../../shared/components/SerialInputForm";
import * as Text from "../../shared/components/Text";
import * as t from "../../shared/text";
import { desktopBreakpoint } from "../../shared/utils";

type EraProSerialModalProps = {
  isOpen: boolean;
  onRequestClose: (serial?: string) => void;
};

export const EraProSerialModal: React.FC<EraProSerialModalProps> = ({
  isOpen,
  ...props
}) => {
  const modalProps = {
    hideCloser: true,
    isOpen,
  };
  return (
    <Modal {...modalProps}>
      <EraProSerialModalContent {...modalProps} {...props} />
    </Modal>
  );
};

const EraProSerialModalContent: React.FC<EraProSerialModalProps> = ({
  onRequestClose,
}) => {
  const dispatch = useDispatch();
  const initialSerial = useSelector((state: MainAppState) =>
    getDeviceSerialNumber(state, PaxBleTypes.DeviceType.ERA_PRO)
  );
  const deviceError = useSelector(getDeviceError);

  const handleSubmit = (formValues: serialInputForm.FormValues): void => {
    const serial = formValues.serial?.toUpperCase() || "";
    dispatch(actions.setSerial(serial, PaxBleTypes.DeviceType.ERA_PRO));
    onRequestClose(serial);
  };

  return (
    <sc.Container>
      <sc.Title>{t.ENTER_SERIAL_NUMBER}</sc.Title>
      <sc.EraProSerialLocation
        alt="PAX® ERA PRO™ Serial Number Location"
        src={EraProSerialLocation}
      />
      <sc.Text>{t.PLEASE_ENTER_SERIAL_ERA_PRO}</sc.Text>
      <serialInputForm.SerialInputForm
        deviceError={deviceError}
        initialSerial={initialSerial}
        onSubmit={handleSubmit}
        selectedDeviceType={PaxBleTypes.DeviceType.ERA_PRO}
      />
    </sc.Container>
  );
};

const sc = {
  ButtonRow: styled(Row)`
    justify-content: center;
  `,

  Container: styled(Column)`
    align-items: center;
    border-radius: 8px;
    padding: ${(props) => props.theme.connectionModal.padding};
    width: ${(props) => props.theme.connectionModal.width};

    ${desktopBreakpoint(
      css`
        box-shadow: 0 1px 12px 0 var(--bluey-grey-80);
        height: 628px;
      `
    )}
  `,

  EraProSerialLocation: styled.img`
    height: 262px;
    margin: 50px 26px 12px;
    width: 204px;
  `,

  SerialInput: styled.input`
    border: solid 1px var(--very-light-pink);
    border-radius: 4px;
    height: 36px;
    margin: 28px 48px 28px;
    text-align: center;
    width: 160px;
  `,

  Text: styled.div`
    ${Text.PlaaxLight}
    font-size: 14px;
    line-height: 1.43;
    margin-bottom: 12px;
    text-align: center;
  `,

  Title: styled.div`
    font-size: 24px;
    text-align: center;
    text-transform: uppercase;
  `,
};
