import React from "react";
import styled, { css } from "styled-components";

import * as Strain from "../../../api/consumer/types/strain";
import { Box } from "../../../shared/components/Box";
import * as text from "../../../shared/text";
import * as utils from "../../../shared/utils";

import { STRAIN_FLAVOR_ICON_MAP } from "../../constants";
import { PodSectionTitleStyles } from "../../styledComponents";

type FlavorsProps = {
  flavors: Strain.Flavors[];
};

export const Flavors: React.FC<FlavorsProps> = ({ flavors }) => {
  return (
    <>
      <sc.Title>{text.FLAVORS}</sc.Title>
      {flavors.map((flavor) => {
        const Icon = STRAIN_FLAVOR_ICON_MAP[flavor];

        return (
          <sc.FlavorBox key={flavor}>
            <sc.Icon>
              <Icon />
            </sc.Icon>
            {utils.capitalizeRemoveUnderscores(flavor)}
          </sc.FlavorBox>
        );
      })}
    </>
  );
};

const sc = {
  FlavorBox: styled(Box)`
    height: 82px;
    margin-bottom: 16px;
    padding: 22px;
  `,

  Icon: styled.div`
    margin-right: 20px;
  `,

  Title: styled.div`
    ${PodSectionTitleStyles}
    margin-bottom: 16px;

    ${utils.desktopBreakpoint(
      css`
        margin-bottom: 42px;
      `
    )}
  `,
};
