import { getAssignedVariations } from "../api/consumer/selectors/experiments";
import { AppThunk } from "../shared/types";
import * as t from "./types";

import { trackEvent } from ".";

export const TRACK_EVENT = "TRACK_EVENT";

type TrackEvent = <T extends Object>(
  event: string,
  properties?: T,
  commonFields?: t.SegmentCommonFields
) => AppThunk;

export const trackEventAction: TrackEvent =
  (event, properties, commonFields = {}) =>
  (dispatch, getState): void => {
    const state = getState();

    const stateProperties: t.ReduxStateEventProperties = {
      experiments: getAssignedVariations(state),
    };

    const _properties: Partial<t.AttachedEventProperties> = {
      ...properties,
      ...stateProperties,
    };

    trackEvent(event, _properties, commonFields);

    dispatch({ payload: { event }, type: TRACK_EVENT });
  };
