import React from "react";

import { DEFAULT_BAR_HEIGHT } from "../../constants";

type PodOuterBarProps = {
  color: string;
  height?: number;
  width: number;
};

export const PodOuterBar: React.FC<PodOuterBarProps> = ({
  color,
  height = DEFAULT_BAR_HEIGHT,
  width,
  ...props
}) => {
  return <rect fill={color} height={height} width={width} rx="4" {...props} />;
};
