import { animated, config, useSpring } from "@react-spring/web";
import { useDrag } from "@use-gesture/react";
import { debounce } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import styled, { SimpleInterpolation } from "styled-components";

import * as Icons from "../../shared/components/Icons";
import { useBodyScrollOverflow } from "../../shared/hooks";

const BOTTOM_SHEET_PADDING_BOTTOM = 100;
const DEBOUNCE_TIMEOUT = 200;
const DEFAULT_BOTTOM_SHEET_HEIGHT = 400;
const DEFAULT_BOTTOM_SHEET_WIDTH = 500;

type BottomSheetProps = {
  bottomSheetBottom?: number;
  height?: number;
  isOpen: boolean;
  onRequestClose: () => void;
  shouldHideCloser?: boolean;
  shouldScroll?: boolean;
  title?: string;
  width?: number;
};

export const BottomSheet: React.FC<BottomSheetProps> = ({
  bottomSheetBottom,
  children,
  height = DEFAULT_BOTTOM_SHEET_HEIGHT,
  isOpen,
  onRequestClose,
  shouldHideCloser = false,
  shouldScroll = false,
  title,
  width = DEFAULT_BOTTOM_SHEET_WIDTH,
  ...props
}) => {
  useBodyScrollOverflow("hidden");

  const [currentY, setCurrentY] = useState<number>(0);
  const [isMounted, setIsMounted] = useState<boolean>(true);

  useEffect(() => {
    return () => {
      setIsMounted(false);
    };
  }, []);

  const [{ y }, api] = useSpring(() => ({
    onChange: ({ value: y }) => {
      if (!isMounted) return;
      debounce(() => {
        setCurrentY(y.y);
      }, DEBOUNCE_TIMEOUT)();
    },
    y: height - 1,
  }));

  const open = useCallback(
    ({ canceled }: { canceled: boolean }) => {
      api.start({
        config: canceled ? config.wobbly : config.stiff,
        immediate: false,
        y: 0,
      });
    },
    [api]
  );

  useEffect(() => {
    open({ canceled: false });
  }, [open]);

  useEffect(() => {
    if (currentY === height) {
      onRequestClose();
    }
  }, [onRequestClose, currentY, height]);

  const close = (velocity = 0) => {
    api.start({
      config: { ...config.stiff, velocity },
      immediate: false,
      y: height,
    });
  };

  const bind = useDrag(
    ({
      last,
      velocity: [, vy],
      direction: [, dy],
      movement: [, my],
      cancel,
      canceled,
    }) => {
      if (my < -70) cancel();

      if (last) {
        my > height * 0.5 || (vy > 0.5 && dy > 0)
          ? close(vy)
          : open({ canceled });
      } else api.start({ immediate: true, y: my });
    },
    {
      bounds: { top: 0 },
      filterTaps: true,
      from: () => [0, y.get()],
      rubberband: true,
    }
  );

  const display = y.to((py) => (py < height ? "block" : "none"));

  if (!isOpen) return null;

  const bgStyle = {
    backgroundColor: "var(--black-50)",
    display: "flex",
    height: "100%",
    justifyContent: "center",
    opacity: y.to([0, height], [1, 0], "clamp"),
    width: "100%",
  };

  const bottom =
    typeof bottomSheetBottom === "number"
      ? bottomSheetBottom
      : -BOTTOM_SHEET_PADDING_BOTTOM;

  return (
    <sc.Container shouldScroll={shouldScroll} {...props}>
      <animated.div onClick={() => close()} style={bgStyle}>
        <animated.div
          onClick={(e) => e.stopPropagation()}
          style={{
            backgroundColor: "var(--white)",
            borderRadius: "20px 20px 0 0",
            bottom: bottom,
            display,
            height: height + BOTTOM_SHEET_PADDING_BOTTOM,
            maxWidth: width,
            position: "absolute",
            touchAction: "none",
            width: "100%",
            y,
          }}
        >
          {!shouldHideCloser && <sc.Closer onClick={() => close()} />}
          <sc.Header {...bind()}>
            <sc.Handle />
            {title && <sc.Title>{title}</sc.Title>}
          </sc.Header>
          {children}
        </animated.div>
      </animated.div>
    </sc.Container>
  );
};

type ContainerProps = {
  shouldScroll: boolean;
};

const sc = {
  Closer: styled(Icons.Clear)`
    cursor: pointer;
    left: 28px;
    position: absolute;
    top: 24px;
    width: ${(props): SimpleInterpolation => props.size?.toString() || 18}px;
    z-index: var(--z-index-modal-closer);
  `,

  Container: styled.div<ContainerProps>`
    display: flex;
    height: 100vh;
    justify-content: center;
    left: 0;
    overflow: ${({ shouldScroll }) => (shouldScroll ? "auto" : "hidden")};
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: var(--z-index-modal-overlay);
  `,

  Handle: styled.div`
    background-color: var(--black-15);
    border-radius: 2px;
    height: 4px;
    width: 48px;
  `,

  Header: styled.div`
    align-items: center;
    border-bottom: 1px solid var(--khaki-20);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
    touch-action: none;
  `,

  Title: styled.div`
    font-size: 14px;
    font-weight: bold;
    line-height: 20px;
    margin: 16px 0 8px;
  `,
};
