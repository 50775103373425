import * as t from "../types";

export const connectedPod = (strainId: string): string =>
  `/strains/${strainId}/connected-pod`;

export const connectedPods = (
  filter = t.connectedPods.ConnectedPodsFilter.NONE,
  pageSize = 32
): string => `/connected-pods?filter=${filter}&page_size=${pageSize}`;

export const recordConnectedPod = (strainId: string): string =>
  `/strains/${strainId}/connected-pods`;
