import { capitalize } from "lodash";
import React from "react";
import styled from "styled-components";

import { EFFECTS_ICON_MAP } from "../../pods/constants";
import { Row } from "../../shared/components/Flex";
import * as Text from "../../shared/components/Text";

type EffectsRowProps = {
  effects: string[];
};

export const EffectsRow: React.FC<EffectsRowProps> = ({ effects }) => {
  return (
    <Row data-testid="effects">
      {effects.map((effect) => (
        <sc.Badge data-testid="effect-badge" key={effect}>
          <sc.IconContainer>{EFFECTS_ICON_MAP[effect]}</sc.IconContainer>
          {capitalize(effect)}
        </sc.Badge>
      ))}
    </Row>
  );
};

const sc = {
  Badge: styled(Row)`
    ${Text.PlaaxLight}
    background: var(--white);
    border: 1px solid var(--khaki-20);
    border-radius: 8px;
    margin-right: 8px;
    padding: 10px 16px;
  `,

  IconContainer: styled.div`
    margin-right: 10px;

    & svg {
      height: 20px;
      width: 20px;
    }
  `,
};
