import { DeviceModel, DeviceShellColor } from "../api/consumer/types/device";

export const DEFAULT_MAP_LOCATION = "San Francisco, CA";

export const REQUEST_LOCATION_INTERVAL = 300_000; // 5 minutes
export const REQUEST_LOCATION_START = "REQUEST_LOCATION_START";
export const REQUEST_LOCATION_DONE = "REQUEST_LOCATION_DONE";

// Markers for 'Google Maps Static API' need to be publicly available
// device assets to use as markers are in /public/images/find-my-pax
export const PUBLIC_ASSETS_BASE_URL =
  "https://web-app.pax.com/images/find-my-pax/";

type FindMyPaxAsset = {
  marker: string;
  mutedMarker: string;
};

type FindMyPaxAssetMap = {
  [key in DeviceShellColor]?: FindMyPaxAsset;
};

const EraProFindMyPaxIcons: FindMyPaxAssetMap = {
  [DeviceShellColor.MATTE_JADE]: {
    marker: "era-pro/teal.png",
    mutedMarker: "era-pro/teal-off.png",
  },
  [DeviceShellColor.MATTE_RED]: {
    marker: "era-pro/maroon.png",
    mutedMarker: "era-pro/maroon-off.png",
  },
  [DeviceShellColor.MATTE_BLACK]: {
    marker: "era-pro/black.png",
    mutedMarker: "era-pro/black-off.png",
  },
  [DeviceShellColor.MATTE_SILVER]: {
    marker: "era-pro/silver.png",
    mutedMarker: "era-pro/silver-off.png",
  },
};

const EraFindMyPaxIcons: FindMyPaxAssetMap = {
  [DeviceShellColor.MATTE_BLACK]: {
    marker: "era/black.png",
    mutedMarker: "era/black-off.png",
  },
};

const Pax3FindMyPaxIcons: FindMyPaxAssetMap = {
  [DeviceShellColor.GLOSSY_BLACK]: {
    marker: "pax-3/glossy-black.png",
    mutedMarker: "pax-3/glossy-black-off.png",
  },
  [DeviceShellColor.GLOSSY_SILVER]: {
    marker: "pax-3/glossy-silver.png",
    mutedMarker: "pax-3/glossy-silver-off.png",
  },
  [DeviceShellColor.GLOSSY_GOLD]: {
    marker: "pax-3/glossy-gold.png",
    mutedMarker: "pax-3/glossy-gold-off.png",
  },
  [DeviceShellColor.GLOSSY_ROSE_GOLD]: {
    marker: "pax-3/rose-gold.png",
    mutedMarker: "pax-3/rose-gold-off.png",
  },
  [DeviceShellColor.GLOSSY_RED]: {
    marker: "pax-3/glossy-red.png",
    mutedMarker: "pax-3/glossy-red-off.png",
  },
  [DeviceShellColor.BLUE]: {
    marker: "pax-3/blue.png",
    mutedMarker: "pax-3/blue-off.png",
  },
  [DeviceShellColor.MATTE_BLACK]: {
    marker: "pax-3/matte-black.png",
    mutedMarker: "pax-3/matte-black-off.png",
  },
  [DeviceShellColor.MATTE_SILVER]: {
    marker: "pax-3/silver.png",
    mutedMarker: "pax-3/silver-off.png",
  },
  [DeviceShellColor.MATTE_ROSE_GOLD]: {
    marker: "pax-3/matte-rose-gold.png",
    mutedMarker: "pax-3/matte-rose-gold-off.png",
  },
  [DeviceShellColor.MATTE_TEAL]: {
    marker: "pax-3/teal.png",
    mutedMarker: "pax-3/teal-off.png",
  },
  [DeviceShellColor.MATTE_FUCHSIA]: {
    marker: "pax-3/fuchsia.png",
    mutedMarker: "pax-3/fuchsia-off.png",
  },
  [DeviceShellColor.BURGUNDY]: {
    marker: "pax-3/glossy-black.png",
    mutedMarker: "pax-3/glossy-black-off.png",
  },
  [DeviceShellColor.ONYX]: {
    marker: "pax-3/glossy-black.png",
    mutedMarker: "pax-3/glossy-black-off.png",
  },
  [DeviceShellColor.SAGE]: {
    marker: "pax-3/glossy-black.png",
    mutedMarker: "pax-3/glossy-black-off.png",
  },
  [DeviceShellColor.SAND]: {
    marker: "pax-3/glossy-black.png",
    mutedMarker: "pax-3/glossy-black-off.png",
  },
  [DeviceShellColor.SHADOW_RAIN]: {
    marker: "pax-3/glossy-black.png",
    mutedMarker: "pax-3/glossy-black-off.png",
  },
  [DeviceShellColor.MIDNIGHT_RAIN]: {
    marker: "pax-3/glossy-black.png",
    mutedMarker: "pax-3/glossy-black-off.png",
  },
  [DeviceShellColor.OCEAN]: {
    marker: "pax-3/glossy-black.png",
    mutedMarker: "pax-3/glossy-black-off.png",
  },
};

export const FindMyPaxIconsMap: { [key in DeviceModel]: FindMyPaxAssetMap } = {
  [DeviceModel.ERA]: EraFindMyPaxIcons,
  [DeviceModel.ERA_PRO]: EraProFindMyPaxIcons,
  [DeviceModel.PAX_3]: Pax3FindMyPaxIcons,
  [DeviceModel.PAX_3_5]: Pax3FindMyPaxIcons,
};
