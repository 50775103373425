import { StrainReviewJson } from "./reviews";
import { StrainJson } from "./strain";

export type NeedsReview = {
  data: ConnectedPodJson[];
  isFetched: boolean;
};

export type ConnectedPods = {
  needsReview: NeedsReview;
};

export type ConnectedPodJson = {
  earliestClientTimestamp: string;
  isLow: boolean;
  latestClientTimestamp: string;
  latestReview: StrainReviewJson | null;
  podSerialNumbers: string[];
  strain: StrainJson;
  wasScanned: boolean;
};

export enum ConnectedPodsFilter {
  AUTO_CONNECTED = "AUTO_CONNECTED",
  MANUALLY_CONNECTED = "MANUALLY_CONNECTED",
  NEEDS_REVIEW = "NEEDS_REVIEW",
  NONE = "NONE",
}

export type ConnectedPodPayload = {
  connectedPod: ConnectedPodJson;
};
