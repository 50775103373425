import { connect } from "react-redux";

import * as a from "../../device/selectors";
import * as t from "../../main/types";

import {
  EraDeviceInfoOverlay,
  StateProps,
} from "../components/EraDeviceInfoOverlay";

const mapStateToProps = (state: t.MainAppState): StateProps => {
  return {
    isDeviceLocked: a.getIsDeviceLocked(state),
    replay: a.getEraReplay(state),
  };
};

export default connect(mapStateToProps)(EraDeviceInfoOverlay);
