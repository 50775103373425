import { Endian } from "../../types";
import * as u from "../../utils";

// Represents a type-length-value trailer entry for firmware images using McuBoot or the legacy Apache Mynewt bootloader.
export default class McuManagerImageTlvTrailerEntry {
  type: number;
  length: number;
  value: Uint8Array;

  static MIN_SIZE = 4;

  static fromBytes(b: Uint8Array, offset = 0): McuManagerImageTlvTrailerEntry {
    if (offset + this.MIN_SIZE > b.length) {
      throw new Error(
        "Byte array too short to be a McuManagerImageTlvTrailerEntry"
      );
    }

    // Get type.
    const type = b[offset++];
    offset++; // Account for byte padding

    // Get length.
    const length = u.byteArrayToUnsignedInt(b, offset, Endian.LITTLE, 2);
    offset += 2; // Move past length

    // Get value.
    const value = b.slice(offset, offset + length);
    return new McuManagerImageTlvTrailerEntry(type, length, value);
  }

  constructor(type: number, length: number, value: Uint8Array) {
    this.type = type;
    this.length = length;
    this.value = value;
  }

  getEntryLength(): number {
    return McuManagerImageTlvTrailerEntry.MIN_SIZE + this.length;
  }
}
