import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch } from "react-redux";
import styled, {
  createGlobalStyle,
  css,
  SimpleInterpolation,
} from "styled-components";
import StyledReactModal from "styled-react-modal";

import * as Icons from "../../shared/components/Icons";
import { desktopBreakpoint } from "../../shared/utils";

import { modalClosed, modalOpened } from "../actions";

type TransparentBackgroundProps = {
  allowOuterElementInteraction: boolean;
};

type ModalCloserProps = {
  color?: string;
  size?: number;
};

type TimeoutProp = {
  close?: number;
  open?: number;
};

export type ModalProps = {
  allowOuterElementInteraction?: boolean;
  allowScroll?: boolean;
  children?: React.ReactNode;
  closeOnBackgroundClick?: boolean;
  closerProps?: ModalCloserProps;
  hideCloser?: boolean;
  isOpen: boolean;
  isTransparent?: boolean;
  onRequestClose?: () => unknown;
  timeout?: TimeoutProp;
};

export const Modal: React.FC<ModalProps> = ({
  allowOuterElementInteraction = isMobile, // allow interaction with nav bar in mobile view
  allowScroll = isMobile, // allow scroll when modals are open to allow for pull down for refresh in connect browser
  children,
  closeOnBackgroundClick = true,
  closerProps,
  hideCloser = false,
  isTransparent = isMobile,
  onRequestClose,
  timeout,
  ...props
}) => {
  const dispatch = useDispatch();
  const [shouldCloseModal, setShouldCloseModal] = useState<boolean>(true);
  const [isOpen, setIsOpen] = useState<boolean>();

  useEffect(() => {
    if (!timeout) return;

    let openTimeout: NodeJS.Timeout;

    if (props.isOpen) {
      openTimeout = setTimeout(
        () => setIsOpen(props.isOpen),
        timeout.open || 0
      );
      return;
    }

    const closeTimeout = setTimeout(
      () => setIsOpen(props.isOpen),
      timeout.close || 0
    );

    return () => {
      clearTimeout(openTimeout);
      clearTimeout(closeTimeout);
    };
  }, [props.isOpen, timeout]);

  useEffect(() => {
    dispatch(props.isOpen ? modalOpened() : modalClosed());

    return () => {
      dispatch(modalClosed());
    };
  }, [dispatch, props.isOpen]);

  const handleModalMouseDown = (): void => {
    setShouldCloseModal(false);
  };

  const handleModalMouseUp = (): void => {
    setShouldCloseModal(true);
  };

  const handleRequestClose = (): void => {
    if (shouldCloseModal && onRequestClose) {
      onRequestClose();
    }

    setShouldCloseModal(true);
  };

  const backgroundProps = {
    "data-testid": "modal-background",
  };

  const transparentBackgroundProps = {
    "data-testid": "modal-background",
    id: "transparent-background",
  };

  const modalProps = {
    allowScroll: allowScroll,
    backgroundProps: isTransparent
      ? transparentBackgroundProps
      : backgroundProps,
    isOpen: timeout ? !!isOpen : props.isOpen,
    onBackgroundClick: closeOnBackgroundClick ? handleRequestClose : undefined,
    onEscapeKeydown: closeOnBackgroundClick ? handleRequestClose : undefined,
  };

  return (
    <>
      {isTransparent && (
        <TransparentBackground
          allowOuterElementInteraction={allowOuterElementInteraction}
        />
      )}
      <sc.Modal {...props} {...modalProps}>
        <sc.ContentContainer
          onMouseDown={handleModalMouseDown}
          onMouseUp={handleModalMouseUp}
          data-testid="modal-content-container"
        >
          {!hideCloser && (
            <sc.Closer
              className="close-modal"
              data-testid="close-modal"
              onClick={handleRequestClose}
              {...closerProps}
            />
          )}
          {children}
        </sc.ContentContainer>
      </sc.Modal>
    </>
  );
};

const TransparentBackground = createGlobalStyle<TransparentBackgroundProps>`
  #transparent-background {
    background-color: var(--transparent);
    padding-top: var(--pax-nav-height);
    pointer-events: ${(props): SimpleInterpolation =>
      props.allowOuterElementInteraction ? "none" : "auto"};
    
    ${desktopBreakpoint(
      css`
        align-items: flex-start;
        padding-top: calc(
          var(--pax-nav-height) + var(--top-nav-height) + var(--banner-height)
        );
      `
    )}
  }
`;

const sc = {
  Closer: styled(Icons.Clear)`
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 12px;
    width: ${(props): SimpleInterpolation => props.size?.toString() || 18}px;
    z-index: var(--z-index-modal-closer);
  `,

  ContentContainer: styled.div`
    height: 100%;
    overflow: auto;

    ${desktopBreakpoint(css`
      overflow: initial;
    `)}
  `,

  // StyledReactModal does not support extending its props
  Modal: styled(StyledReactModal.styled``)`
    align-items: center;
    background-color: var(--white);
    border-radius: 0;
    box-shadow: none;
    height: 100%;
    pointer-events: auto;
    position: relative;
    width: 100%;

    ${desktopBreakpoint(
      css`
        border-radius: 8px;
        box-shadow: 0 1px 12px 0 var(--bluey-grey-80);
        height: auto;
        margin-bottom: 0;
        width: auto;
      `
    )}
  `,
};
