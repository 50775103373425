import { Action } from "../../../shared/types";

import * as p from "../paths/experiments";
import { ExperimentCode, ExperimentsJson } from "../types/experiments";

export const RECEIVED_EXPERIMENTS = "RECEIVED_EXPERIMENTS";
export const SUCCEEDED_EXPERIMENT = "SUCCEEDED_EXPERIMENT";
export const VIEWED_EXPERIMENT = "VIEWED_EXPERIMENT";

export const receivedExperiments = (experiments: ExperimentsJson): Action => {
  return {
    payload: { experiments },
    type: RECEIVED_EXPERIMENTS,
  };
};

type SucceededExperiment = (
  experimentCode: ExperimentCode
) => (dispatch: React.Dispatch<Action>) => unknown;

export const succeededExperiment: SucceededExperiment =
  (experimentCode) =>
  async (dispatch): Promise<void> => {
    try {
      await fetch(p.successes(experimentCode), { method: "POST" });
    } catch (err) {
      // Do nothing, fetch will handle the error.
    }

    dispatch({
      type: SUCCEEDED_EXPERIMENT,
    });
  };

type ViewedExperiment = (
  experimentCode: ExperimentCode
) => (dispatch: React.Dispatch<Action>) => unknown;

export const viewedExperiment: ViewedExperiment =
  (experimentCode) =>
  async (dispatch): Promise<void> => {
    try {
      await fetch(p.views(experimentCode), { method: "POST" });
    } catch (err) {
      // Do nothing, fetch will handle the error.
    }

    dispatch({
      type: VIEWED_EXPERIMENT,
    });
  };
