import React from "react";
import styled, { css } from "styled-components";

import { PAX_STRAIN, StrainJson } from "../../api/consumer/types/strain";
import * as r from "../../main/routes";
import { Column } from "../../shared/components/Flex";
import { PartnerLogo } from "../../shared/components/PartnerLogo";
import SearchParamLink from "../../shared/components/SearchParamLink";
import * as Text from "../../shared/components/Text";
import { desktopBreakpoint } from "../../shared/utils";

import { PAX_STRAIN_PACKAGING_MAP } from "../constants";
import { isPaxStrain } from "./PodDetails/utils";
import { PodAverageRating } from "./PodAverageRating";
import { StrainClassificationPill } from "./StrainClassificationPill";

export type PodCardProps = {
  Icon?: React.ReactElement | null;
  onClick?: () => void;
  strain: StrainJson;
};

export const PodCard: React.FC<PodCardProps> = ({ Icon, onClick, strain }) => {
  return (
    <sc.Container data-testid="pod-card">
      {Icon}
      <SearchParamLink
        data-testid="link-to-pod-details"
        onClick={onClick}
        to={r.podDetails(strain.id)}
      >
        <sc.LogoContainer>
          {!isPaxStrain(strain.partner.id) ? (
            <sc.PartnerLogo partner={strain.partner} />
          ) : (
            <sc.PackagingImage
              alt={`${strain.name} Package`}
              data-testid="product-packaging"
              src={PAX_STRAIN_PACKAGING_MAP[strain.id as PAX_STRAIN]}
            />
          )}
        </sc.LogoContainer>
        <sc.StrainInfoContainer>
          <StrainClassificationPill strain={strain} />
          <sc.StrainName>{strain.name}</sc.StrainName>
          <sc.PartnerName>{strain.partner.partnerName}</sc.PartnerName>
        </sc.StrainInfoContainer>
        <sc.PodAverageRating
          fontSize="12px"
          starIconSize="14px"
          ratingsAverage={strain.averageRating}
          ratingsCount={strain.numRatings}
          shouldShowSuffix
        />
      </SearchParamLink>
    </sc.Container>
  );
};

const sc = {
  Container: styled(Column)`
    background-color: var(--white);
    border: 3px solid var(--black-10);
    border-radius: 4px;
    height: ${(props) => props.theme.podCard.height};
    padding: 0 16px 12px;
    position: relative;
    transition: transform 0.2s;
    width: ${(props) => props.theme.podCard.width};

    & a {
      color: var(--black);
      cursor: pointer;
      height: calc(${(props) => props.theme.podCard.height} - 12px);
      text-decoration: none;
    }

    &:hover {
      transform: scale(1.015);
    }

    ${desktopBreakpoint(
      css`
        padding: 12px 16px;

        & a {
          height: calc(${(props) => props.theme.podCard.height} - 24px);
        }
      `
    )}
  `,

  LogoContainer: styled.div`
    align-items: center;
    display: flex;
    height: 140px;
    justify-content: center;
    position: relative;
    right: 16px;
    width: 154px;

    ${desktopBreakpoint(
      css`
        height: 166px;
        width: 276px;
      `
    )}
  `,

  PackagingImage: styled.img`
    height: 140px;
    object-fit: cover;

    ${desktopBreakpoint(
      css`
        height: 166px;
      `
    )}
  `,

  PartnerLogo: styled(PartnerLogo)`
    height: 76px;
    width: 76px;

    ${desktopBreakpoint(
      css`
        height: 98px;
        width: 98px;
      `
    )}
  `,

  PartnerName: styled.div`
    ${Text.MediumLLLight}
    font-size: 14px;
    max-width: calc(${(props) => props.theme.podCard.width} - 32px);
    text-transform: capitalize;
  `,

  PodAverageRating: styled(PodAverageRating)`
    bottom: 16px;
    height: 13px;
    justify-content: space-between;
    position: absolute;
    width: 124px;

    ${desktopBreakpoint(
      css`
        bottom: 24px;
        width: 246px;
      `
    )}
  `,

  StrainInfoContainer: styled(Column)`
    height: 88px;
    justify-content: space-between;

    ${desktopBreakpoint(
      css`
        height: 94px;
      `
    )}
  `,

  StrainName: styled.div`
    ${Text.MediumLLBold}
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    font-size: 16px;
    max-height: 40px;
    max-width: calc(${(props) => props.theme.podCard.width} - 32px);
    overflow: hidden;
    text-transform: uppercase;

    ${desktopBreakpoint(
      css`
        font-size: 18px;
      `
    )}
  `,
};

export const PodLoadingCard: React.FC = () => (
  <sc.Container data-testid="loading-card" />
);
