import React from "react";
import * as Router from "react-router-dom";
import styled from "styled-components";

import * as r from "../../main/routes";
import { getPrimaryStrainColor } from "../../shared/colors/Strain";
import { Row } from "../../shared/components/Flex";
import { Hexagon } from "../../shared/components/Icons";
import * as Text from "../../shared/components/Text";
import * as t from "../../shared/text";
import { getStrainClassificationFormattedText } from "../../shared/utils";

import * as hooks from "../hooks";
import { SmallCard } from "./PodsTestResultsCard";

export type PodsStrainInformationCardProps = {
  strainId: string;
  useStrainById?: hooks.UseStrainById;
};

export const PodsStrainInformationCard: React.FC<PodsStrainInformationCardProps> =
  ({ strainId, useStrainById = hooks.useStrainById }) => {
    const { strain } = useStrainById(strainId);

    if (!strain) {
      return (
        <sc.SmallEmptyCard
          data-testid="pods-empty-strain-information-card"
          borderStyle="dotted"
        />
      );
    }

    return (
      <SmallCard
        data-testid="pods-strain-information-card"
        borderStyle="dotted"
      >
        <sc.Title>{t.PRODUCT_INFORMATION}</sc.Title>
        <Row justifyContent="space-between">
          <sc.Label>{t.PRODUCER}:</sc.Label>
          <sc.Link
            data-testid="link-to-partner-details"
            to={r.partnerDetails(strain.partner.id)}
          >
            {strain.partner.partnerName.toLowerCase()}
          </sc.Link>
        </Row>
        <Row justifyContent="space-between">
          <sc.Label>{t.NAME}:</sc.Label>
          <sc.Link
            data-testid="link-to-pod-details"
            to={r.podDetails(strainId)}
          >
            {strain.name.toLowerCase()}
          </sc.Link>
        </Row>
        <Row justifyContent="space-between">
          <sc.Label>{t.TYPE}:</sc.Label>
          <sc.Value>
            <Hexagon color={getPrimaryStrainColor(strain)} size={14} />{" "}
            {getStrainClassificationFormattedText(strain.classification)}
          </sc.Value>
        </Row>
      </SmallCard>
    );
  };

type TestResultsCardProps = {
  borderStyle: string;
};

const sc = {
  Label: styled.div`
    color: var(--black);
    font-size: 16px;
    line-height: 1;
    margin-bottom: 12px;
    text-transform: uppercase;
  `,

  Link: styled(Router.Link)`
    ${Text.PlaaxLight}
    color: var(--black);
    font-size: 16px;
    line-height: 1;
    margin-bottom: 12px;
    text-transform: capitalize;
  `,

  SmallEmptyCard: styled(SmallCard)<TestResultsCardProps>`
    align-items: center;
    border-style: ${(props) => props.borderStyle};
  `,

  Title: styled.div`
    color: var(--black);
    font-size: 20px;
    line-height: 0.8;
    margin-bottom: 16px;
    text-transform: uppercase;
  `,

  Value: styled.div`
    ${Text.PlaaxLight}
    color: var(--black);
    font-size: 16px;
    line-height: 1;
    margin-bottom: 12px;
    text-transform: capitalize;
  `,
};
