import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { ModalAction } from "../../modal/actions";
import { Banner } from "../../shared/components/Banner";
import * as Text from "../../shared/components/Text";

export type StateProps = {
  latestFirmwareAvailable: string;
};

export type DispatchProps = {
  openFirmwareModal: ModalAction;
};

export type FirmwareUpdateBannerProps = StateProps & DispatchProps;

export const FirmwareUpdateBanner: React.FC<FirmwareUpdateBannerProps> = ({
  latestFirmwareAvailable,
  openFirmwareModal,
}) => {
  return (
    <Banner data-testid="update-device-banner">
      <span>
        Firmware update v{latestFirmwareAvailable} available!
        <sc.UpdateLink
          data-testid="update-link"
          to="#"
          onClick={openFirmwareModal}
        >
          <Text.Bold>Update</Text.Bold>
        </sc.UpdateLink>
      </span>
    </Banner>
  );
};

const sc = {
  UpdateLink: styled(Link)`
    color: var(--white);
    margin-left: 4px;
    text-transform: uppercase;
  `,
};

export default FirmwareUpdateBanner;
