import Bugsnag from "@bugsnag/js";
import compareVersions from "compare-versions";

import * as c from "../constants";
import { BaseDevice } from "../shared/BaseDevice";
import { SessionControl } from "../shared/SessionControl";
import * as t from "../types";
import * as u from "../utils";
import { VaporEngine } from "./vapor-engine/VaporEngine";
import { EraProDeviceEncryption } from "./EraProDeviceEncryption";
import { EraProEncryptionHandshake } from "./EraProEncryptionHandshake";
import { EraProLogStore } from "./EraProLogStore";

const ENCRYPTION_HANDSHAKE_TIMEOUT = 2000;
const ERA_PRO_BRIGHTNESS_MIN = 0;
const ERA_PRO_BRIGHTNESS_MAX = 127;
const UNENCRYPTED_BUFFER_SIZE = 16;
const ENCRYPTED_BUFFER_SIZE = 48;

export class EraProDevice extends BaseDevice {
  type = t.DeviceType.ERA_PRO;

  encryption?: EraProDeviceEncryption;
  systemId?: string;
  vaporEngine?: VaporEngine;

  async initialize(
    attributeIdsToRead: number[],
    options: t.DeviceOptions
  ): Promise<void> {
    this.supportsLogs = true;

    const service = await this.getService(c.ERA_PRO_PAX_SERVICE_UUID);

    this.write = await service.getCharacteristic(
      c.ERA_PRO_CHARACTERISTICS.Write
    );
    this.notify = await service.getCharacteristic(
      c.ERA_PRO_CHARACTERISTICS.Notify
    );

    try {
      this.encryption = await this.initializeEncryption();
      this.encryptedWrites = !!this.encryption;
      this.serial = this.encryption.peripheralId;
    } catch (err) {
      this.encryption = undefined;
      this.serial = options?.serial;
    }

    this.firmwareRevision = await this.readFirmwareRevision();
    this.systemId = await this.readSystemId();

    this.logStore = new EraProLogStore(this, this.encryption);
    await this.logStore.initialize();

    this.notify.addEventListener(
      "characteristicvaluechanged",
      this.onCharacteristicValueChanged
    );
    await this.notify.startNotifications();

    // Devices with FW below 4.1.18 do not support all current attributes
    if (
      compareVersions(this.firmwareRevision, "4.1.18") < 0 &&
      options?.eraProLegacyFirmwareSupportedAttributes
    ) {
      this.readAttributes(options.eraProLegacyFirmwareSupportedAttributes);
    } else {
      this.readAttributes(attributeIdsToRead);
    }

    // Devices with FW below 5.16.0 do not support the vapor engine
    if (compareVersions(this.firmwareRevision, "5.16.0") >= 0) {
      this.vaporEngine = new VaporEngine(this);
      this.vaporEngine.initialize();
    }
  }

  async initializeEncryption(): Promise<EraProDeviceEncryption> {
    return new Promise<EraProDeviceEncryption>((resolve, reject) => {
      let encryptionHandshake: EraProEncryptionHandshake;
      const tryPairingHandshake = async (): Promise<void> => {
        const encryptionExchangeService = await this.getService(
          c.ERA_PRO_PAX_SERVICE_UUID
        );

        encryptionHandshake = new EraProEncryptionHandshake(
          this,
          encryptionExchangeService
        );

        await encryptionHandshake.startEncryptionHandshake();
      };
      tryPairingHandshake();

      setTimeout(() => {
        if (!encryptionHandshake?.hasCompletedHandshake) {
          return reject(new Error("Encryption handshake not completed"));
        }

        return resolve(encryptionHandshake.encryption);
      }, ENCRYPTION_HANDSHAKE_TIMEOUT);
    });
  }

  onCharacteristicValueChanged = (event: Event): Promise<void> | undefined => {
    this.hasReceivedNotification = true;

    if (!event || !event.target) return;
    const characteristic = event.target as BluetoothRemoteGATTCharacteristic;
    if (!characteristic.value) return;

    const buffer = new Uint8Array(characteristic.value.buffer);
    const [attributeId, value] = this.parseAttributeValue(buffer);

    if (attributeId === c.ERR_BAD_ATTRIBUTE_PARSE_CODE) {
      console.error(buffer.toString());
      Bugsnag.notify(new Error(c.ERR_BAD_ATTRIBUTE_PARSE));
      return;
    }

    if (attributeId === c.ERR_BAD_ENCRYPTED_PACKET_CODE) {
      // Ignore the packet. Errors handled in EraProDeviceEncryption.
      return;
    }

    this.events.emit("receivedAttribute", attributeId, value);
  };

  disconnect(): void {
    this.notify?.removeEventListener(
      "characteristicvaluechanged",
      this.onCharacteristicValueChanged
    );

    if (this.logStore) this.logStore.disconnect();
    if (this.gatt.connected) this.gatt.disconnect();
  }

  readBrightness(): void {
    this.readAttribute(c.ATTRIBUTE_BRIGHTNESS);
  }

  readColorTheme(): void {
    throw new Error("not supported");
  }

  readDeviceName(): void {
    this.readAttribute(c.ATTRIBUTE_DEVICE_NAME);
  }

  readHaptics(): void {
    this.readAttribute(c.ATTRIBUTE_HAPTICS);
  }

  readHeaterSetPoint(): void {
    this.readAttribute(c.ATTRIBUTE_HEATER_SET_POINT);
  }

  readLockState(): void {
    this.readAttribute(c.ATTRIBUTE_LOCKED);
  }

  readSessionControl(): void {
    this.readAttribute(c.ATTRIBUTE_SESSION_CONTROL);
  }

  startSession(doseId: number): void {
    if (this.vaporEngine) {
      this.vaporEngine.setDoseControl(doseId as t.VaporEngine.DoseSize);
      return;
    }

    const initialTotalUsage = SessionControl.getUsageForDose(doseId);
    const initialLockoutSeconds = 5;

    const buffer = new ArrayBuffer(6);
    const view = new DataView(buffer);

    view.setUint8(0, SessionControl.ACTION_START);
    view.setUint8(1, doseId);
    view.setUint16(2, initialTotalUsage, true);
    view.setUint16(4, initialLockoutSeconds, true);

    const bytes = new Uint8Array(buffer);

    this.writeAttribute(c.ATTRIBUTE_SESSION_CONTROL, bytes);
  }

  stopFindMyPax = (): void => {
    this.writeAttribute(c.ATTRIBUTE_FIND_MY_PAX, {
      mode: 0,
      timeout: 0,
    });
  };

  writeBrightness(percentage: number): void {
    const brightness = this.percentageToBrightness(percentage);

    this.writeAttribute(c.ATTRIBUTE_BRIGHTNESS, brightness);
  }

  writeColorMode(): void {
    throw new Error("not supported");
  }

  writeDeviceName(deviceName: string): void {
    this.writeAttribute(c.ATTRIBUTE_DEVICE_NAME, deviceName);
  }

  writeHaptics(value: number): void {
    this.writeAttribute(c.ATTRIBUTE_HAPTICS, value);
  }

  writeHeaterSetPoint(heaterSetPoint: number): void {
    if (this.vaporEngine) {
      this.vaporEngine.writeCustomTemp(heaterSetPoint);
      return;
    }

    this.writeAttribute(c.ATTRIBUTE_HEATER_SET_POINT, heaterSetPoint);
  }

  writeLockState(value: number): void {
    this.writeAttribute(c.ATTRIBUTE_LOCKED, value);
  }

  stopSession(): void {
    if (this.vaporEngine) {
      this.vaporEngine.setDoseControl(t.VaporEngine.DoseSize.NONE);
      return;
    }

    const buffer = new ArrayBuffer(6);
    const view = new DataView(buffer);

    view.setUint8(0, SessionControl.ACTION_STOP);

    const bytes = new Uint8Array(buffer);

    this.writeAttribute(c.ATTRIBUTE_SESSION_CONTROL, bytes);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  getWriteOperationBuffer(attributeId: number, value: any): Uint8Array {
    let buffer = new ArrayBuffer(
      this.encryption ? ENCRYPTED_BUFFER_SIZE : UNENCRYPTED_BUFFER_SIZE
    );
    const view = new DataView(buffer);
    view.setUint8(0, attributeId);

    switch (attributeId) {
      case c.ATTRIBUTE_BRIGHTNESS:
        view.setUint8(1, value);
        // Set brightness and default brightness.
        view.setUint8(2, 0);
        break;

      case c.ATTRIBUTE_DEVICE_NAME:
        const deviceName = value as string;
        view.setUint8(1, deviceName.length);

        const encodedName = u.encodeTextValue(deviceName);
        for (let i = 0; i < encodedName.length; i++) {
          view.setUint8(2 + i, encodedName[i]);
        }
        break;

      case c.ATTRIBUTE_HAPTICS:
        view.setUint8(1, value);
        // Trigger haptics.
        view.setUint8(2, 1);
        break;

      case c.ATTRIBUTE_HEATER_SET_POINT:
        view.setUint16(1, value, true);
        break;

      case c.ATTRIBUTE_LOCKED:
        view.setUint8(1, value);
        break;

      case c.ATTRIBUTE_SESSION_CONTROL:
        const valueBuffer = value as Uint8Array;
        for (let i = 0; i < valueBuffer.length; i++) {
          view.setUint8(1 + i, valueBuffer[i]);
        }
        break;

      case c.ATTRIBUTE_STATUS_UPDATE:
        view.setBigUint64(1, BigInt(value), true);
        break;

      case c.ATTRIBUTE_LOG_REQUEST:
        view.setUint32(1, value, true);
        break;

      case c.ATTRIBUTE_TIME:
        view.setUint32(1, value, true);
        break;

      case c.ATTRIBUTE_FIND_MY_PAX:
        const { mode, timeout } = value as t.EraPro.FindMyPaxStatus;
        view.setUint8(1, mode);
        view.setUint16(2, timeout, true);
        break;

      case c.ATTRIBUTE_VAPOR_ENGINE:
        if (!this.vaporEngine) {
          throw new Error(
            c.VaporEngine.vaporEngineNotSupportedErrorMessage(
              this.firmwareRevision || ""
            )
          );
        }
        const config = value as t.VaporEngine.Configuration;
        const { action, uiFunction } = config;

        switch (action) {
          case t.VaporEngine.Action.READ_CURRENT_STATE:
          case t.VaporEngine.Action.RESET:
            view.setUint8(1, action);

            break;
          case t.VaporEngine.Action.READ_PETAL_CONFIG:
            if (!config.podRev) {
              throw new Error(c.VaporEngine.ERROR_INVALID_CONFIG);
            }

            view.setUint8(1, action);
            view.setUint8(2, uiFunction);
            view.setUint8(3, config.podRev);

            break;
          case t.VaporEngine.Action.WRITE_CONFIG:
            try {
              view.setUint8(1, action);
              view.setUint8(2, uiFunction);
              view.setUint8(3, config.podRev);
              view.setUint8(4, config.doseSize);
              view.setUint8(5, config.dosePulse);
              view.setUint16(6, config.doseLockoutTime, true);
              view.setUint16(8, config.maxTempC10, true);
              view.setUint16(10, config.maxPressureDiff, true);
              view.setUint32(12, config.pressureSlope, true);
              view.setUint16(16, config.heatingCurve[0].tempC10, true);
              view.setUint16(18, config.heatingCurve[0].milliseconds, true);
              view.setUint16(20, config.heatingCurve[1].tempC10, true);
              view.setUint16(22, config.heatingCurve[1].milliseconds, true);
              view.setUint16(24, config.heatingCurve[2].tempC10, true);
              view.setUint16(26, config.heatingCurve[2].milliseconds, true);
              view.setUint16(28, config.heatingCurve[3].tempC10, true);
              view.setUint16(30, config.heatingCurve[3].milliseconds, true);
              view.setUint16(32, config.heatingCurve[4].tempC10, true);
              view.setUint16(34, config.heatingCurve[4].milliseconds, true);
              view.setUint16(36, config.heatingCurve[5].tempC10, true);
              view.setUint16(38, config.heatingCurve[5].milliseconds, true);
              view.setUint16(40, config.heatingCurve[6].tempC10, true);
              view.setUint16(42, config.heatingCurve[6].milliseconds, true);
              view.setUint16(44, config.heatingCurve[7].tempC10, true);
              view.setUint16(46, config.heatingCurve[7].milliseconds, true);
            } catch {
              throw new Error(c.VaporEngine.invalidConfigErrorMessage(config));
            }

            break;
          case t.VaporEngine.Action.SET_ACTIVE_PETAL:
            view.setUint8(1, action);
            view.setUint8(2, uiFunction);

            break;
          default:
            throw new Error(`Unrecognized vapor engine action: ${action}`);
        }

        // Firmware bug. This request will not work if there are extra bytes at the end of the request.
        buffer = buffer.slice(0, c.VaporEngine.WRITE_REQUEST_SIZE[action]);

        break;

      default:
        throw new Error(`Unrecognized attribute: ${attributeId}`);
    }

    if (this.encryption) {
      return this.encryption.buildEncryptedPacket(new Uint8Array(buffer));
    }

    return new Uint8Array(buffer);
  }

  protected async readFirmwareRevision(): Promise<string> {
    let revision: string;
    if (this.encryption && this.encryption.firmwareRevision) {
      revision = u.decodeTextValue(this.encryption.firmwareRevision);
    } else {
      try {
        const data: DataView = await this.readCharacteristicValue(
          c.DEVICE_INFO_SERVICE_UUID,
          c.ERA_PRO_CHARACTERISTICS.FirmwareRevision
        );
        revision = u.decodeTextValue(data);
      } catch (err) {
        throw new Error(c.ERR_ENCRYPTION_HANDSHAKE_FAILED);
      }
    }

    return revision;
  }

  protected async readSystemId(): Promise<string> {
    let data: DataView;
    if (this.encryption && this.encryption.systemId) {
      data = new DataView(this.encryption.systemId.buffer);
    } else {
      data = await this.readCharacteristicValue(
        c.DEVICE_INFO_SERVICE_UUID,
        c.ERA_PRO_CHARACTERISTICS.SystemId
      );
    }

    return data.getBigUint64(0, true).toString();
  }

  private brightnessToPercentage(brightness: number): number {
    return (
      (brightness - ERA_PRO_BRIGHTNESS_MIN) /
      (ERA_PRO_BRIGHTNESS_MAX - ERA_PRO_BRIGHTNESS_MIN)
    );
  }

  private parseAttributeValue(buffer: Uint8Array): [number, unknown] {
    const attributeId = buffer[0];
    const view = new DataView(buffer.buffer);

    let value;
    switch (attributeId) {
      // Attribute with number values.
      case c.ATTRIBUTE_BATTERY:
      case c.ATTRIBUTE_LOCKED:
      case c.ATTRIBUTE_POD_INSERTED:
      case c.ATTRIBUTE_SHELL_COLOR:
        value = value = buffer[1];
        break;

      case c.ATTRIBUTE_BRIGHTNESS:
        value = this.brightnessToPercentage(buffer[1]);
        break;

      case c.ATTRIBUTE_CHARGE_STATUS:
        const chargeStatus = buffer[1].toString(2);

        value = {
          isCharging: chargeStatus[0] === "1",
          isChargingComplete: chargeStatus[1] === "1",
        };
        break;

      case c.ATTRIBUTE_DEVICE_NAME:
        const data = buffer.slice(2);
        const name = u.decodeTextValue(data);

        value = name;
        break;

      case c.ATTRIBUTE_ENCRYPTION_PACKET:
        if (!this.encryption)
          return [c.ERR_BAD_ATTRIBUTE_PARSE_CODE, undefined];

        try {
          const initialErrorCount =
            this.encryption.consecutiveDecryptionErrorCount;

          const { decryptedPacket, updatedErrorCount } =
            this.encryption.getDecryptedPacket(buffer);

          // Parse the packet only when there were no errors decrypting it.
          if (updatedErrorCount <= initialErrorCount) {
            return this.parseAttributeValue(decryptedPacket);
          }
        } catch (err) {
          Bugsnag.notify(err as Error);
          this.events.emit("onConsecutiveDecryptionErrors");
        }

        return [c.ERR_BAD_ENCRYPTED_PACKET_CODE, undefined];

      case c.ATTRIBUTE_HAPTICS:
        // Haptics trigger flag in buffer[2] is currently ignored.
        value = buffer[1];
        break;

      case c.ATTRIBUTE_HEATER_SET_POINT:
        const heaterSetPoint = view.getUint16(1, true);
        value = heaterSetPoint;
        break;

      case c.ATTRIBUTE_POD_DATA:
        const podStatus = view.getUint8(1);

        if (podStatus !== 0) {
          value = { podStatus: podStatus };
          break;
        }

        let autoSessionId;
        let podType;
        let podId;
        const productIdNumberValue: number = view.getUint32(5, true);
        const userPinNumberValue: number = view.getUint32(9, true);
        const vendorIdNumberValue: number = view.getUint16(13, true);

        const cmFieldVersion = view.getUint8(2);
        const fillerFieldVersion = view.getUint8(3);
        const deviceFieldVersion = view.getUint8(4);
        const productId =
          productIdNumberValue === 0
            ? null
            : u.decodeTextValue(buffer.slice(5, 9));
        const userPin =
          userPinNumberValue === 0
            ? null
            : u.decodeTextValue(buffer.slice(9, 13));
        const vendorId =
          vendorIdNumberValue === 0
            ? null
            : u.decodeTextValue(buffer.slice(13, 15));
        const fillerDefaultTemp = view.getUint16(15, true);
        const fillerRecommendedTempFlavor = view.getUint16(17, true);
        const fillerRecommendedTempVapor = view.getUint16(19, true);
        const userPreferredTemp = view.getUint16(21, true);
        const userPreferredSessionSize = view.getUint16(23, true);
        const userPreferredLockoutTime = view.getUint16(25, true);
        const autoSessionSize = view.getUint16(27, true);
        const autoSessionLockoutTime = view.getUint16(29, true);
        const currentSessionProgress = view.getUint16(31, true);
        const userPreferredSessionId = view.getUint8(33);
        try {
          autoSessionId = view.getUint8(34);
          podType = view.getUint8(35);
          podId =
            "0x" +
            Buffer.from(buffer.slice(36, 45)).toString("hex").toUpperCase();
        } catch (err) {
          // Ignore these fields as lower firmware versions don't support these.
        }

        const podData: t.EraPro.PodData = {
          autoSessionId,
          autoSessionLockoutTime,
          autoSessionSize,
          cmFieldVersion,
          currentSessionProgress,
          deviceFieldVersion,
          fillerDefaultTemp,
          fillerFieldVersion,
          fillerRecommendedTempFlavor,
          fillerRecommendedTempVapor,
          podId,
          podStatus,
          podType,
          productId,
          userPin,
          userPreferredLockoutTime,
          userPreferredSessionId,
          userPreferredSessionSize,
          userPreferredTemp,
          vendorId,
        };

        value = podData;
        break;

      case c.ATTRIBUTE_SESSION_CONTROL:
        const action = view.getUint8(1);
        const doseId = view.getUint8(2);
        const initialTotalUsage = view.getUint16(3, true);
        const initialLockoutSeconds = view.getUint16(5, true);
        const usageTowardCompletion = view.getUint16(7, true);
        const totalUsage = view.getUint16(9, true);
        const lockoutSecondsRemaining = view.getUint16(11, true);
        let session = {};

        // New values for firmware supporting the vapor engine
        if (this.vaporEngine && action >= 3) {
          const dosePulseCount = view.getUint16(13, true);
          const puffTimeProgress = view.getUint16(15, true);
          const tempC10 = view.getUint16(17, true);
          const pressureDiff = view.getUint16(19, true);

          session = {
            dosePulseCount,
            pressureDiff,
            puffTimeProgress,
            tempC10,
          };
        }

        const isActive =
          [SessionControl.ACTION_START, SessionControl.ACTION_ACTIVE].indexOf(
            action
          ) >= 0;
        const isLockedOut = lockoutSecondsRemaining > 0;

        session = {
          action,
          doseId,
          initialLockoutSeconds,
          initialTotalUsage,
          isActive,
          isLockedOut,
          lockoutSecondsRemaining,
          totalUsage,
          usageTowardCompletion,
          ...session,
        };

        value = session;
        break;

      case c.ATTRIBUTE_SUPPORTED_ATTRIBUTES:
        const bitfield = view.getBigUint64(1);
        const binary = bitfield.toString(2);
        value = binary;
        break;

      case c.ATTRIBUTE_TIME:
        const deviceTime = view.getUint32(1, true);
        value = deviceTime;
        break;

      case c.ATTRIBUTE_FIND_MY_PAX:
        const mode = view.getUint8(1);
        const timeout = view.getUint16(2, true);

        value = { mode, timeout } as t.EraPro.FindMyPaxStatus;
        break;

      case c.ATTRIBUTE_VAPOR_ENGINE:
        if (!this.vaporEngine) {
          console.error(
            c.VaporEngine.vaporEngineNotSupportedErrorMessage(
              this.firmwareRevision || ""
            )
          );

          break;
        }

        const vaporEngineAction: t.VaporEngine.Action = view.getUint8(1);
        const uiFunction: t.VaporEngine.UiFunction = view.getUint8(2);
        const config = {
          uiFunction,
        } as Partial<t.VaporEngine.Configuration>;

        if (vaporEngineAction === t.VaporEngine.Action.READ_CURRENT_STATE) {
          this.vaporEngine.activeUiFunction = uiFunction;
          value = {
            activeUiFunction: uiFunction,
            dosePulse: !!this.vaporEngine.activeConfiguration?.dosePulse,
          };
          break;
        }

        config.podRev = view.getUint8(3);
        config.doseSize = view.getUint8(4);
        config.dosePulse = view.getUint8(5) as 0 | 1;
        config.doseLockoutTime = view.getUint16(6, true);
        config.maxTempC10 = view.getUint16(8, true);
        config.maxPressureDiff = view.getUint16(10, true);
        config.pressureSlope = view.getUint32(12, true);

        config.heatingCurve = [];
        config.heatingCurve.push({
          milliseconds: view.getUint16(16, true),
          tempC10: view.getUint16(18, true),
        });
        config.heatingCurve.push({
          milliseconds: view.getUint16(20, true),
          tempC10: view.getUint16(22, true),
        });
        config.heatingCurve.push({
          milliseconds: view.getUint16(24, true),
          tempC10: view.getUint16(26, true),
        });
        config.heatingCurve.push({
          milliseconds: view.getUint16(28, true),
          tempC10: view.getUint16(30, true),
        });
        config.heatingCurve.push({
          milliseconds: view.getUint16(32, true),
          tempC10: view.getUint16(34, true),
        });
        config.heatingCurve.push({
          milliseconds: view.getUint16(36, true),
          tempC10: view.getUint16(38, true),
        });
        config.heatingCurve.push({
          milliseconds: view.getUint16(40, true),
          tempC10: view.getUint16(42, true),
        });
        config.heatingCurve.push({
          milliseconds: view.getUint16(44, true),
          tempC10: view.getUint16(46, true),
        });

        this.vaporEngine.configurations[uiFunction] =
          config as t.VaporEngine.Configuration;

        value = {
          activeUiFunction: this.vaporEngine.activeUiFunction,
          dosePulse: !!this.vaporEngine.activeConfiguration?.dosePulse,
        };

        break;

      default:
        this.hasReceivedUnknownAttribute = true;

        console.error(`Unrecognized Era Pro attribute: ${attributeId}`);
        value = buffer[1];
        break;
    }

    return [attributeId, value];
  }

  private percentageToBrightness(percentage: number): number {
    return Math.round(percentage * ERA_PRO_BRIGHTNESS_MAX);
  }
}
