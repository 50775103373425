import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";

import * as deviceSelectors from "../../device/selectors";
import * as routes from "../../main/routes";
import { MainAppState } from "../../main/types";

import {
  PodDetailsPage,
  PodDetailsPageProps,
} from "../components/PodDetails/PodDetailsPage";
import * as podSelectors from "../selectors";

type LocationState = {
  goBackTo: string;
  wasQRCodeScanned: boolean;
};

const mapStateToProps = (
  state: MainAppState,
  props: PodDetailsPageProps & RouteComponentProps
) => {
  const locationState: LocationState | undefined = props.location
    .state as LocationState;

  const goBackTo = locationState?.goBackTo || routes.podsHome();

  return {
    devicePodId: deviceSelectors.getCurrentPodStrainId(state),
    goBackTo,
    strainId: props.match.params.strainId,
    strainsFilter: podSelectors.getStrainsFilter(state),
    wasQRCodeScanned: !!locationState?.wasQRCodeScanned,
  };
};

export default connect(mapStateToProps)(PodDetailsPage);
