import React, { useEffect } from "react";
import * as redux from "react-redux";
import styled, { css, SimpleInterpolation } from "styled-components";

import { Action } from "../../../shared/types";

import { mobileBreakpoint } from "../../utils";

import { Banner } from "../Banner";
import { Clear } from "../Icons";
import * as a from "./actions";
import { getFlashBanner } from "./selectors";

export enum BannerType {
  ERROR = "ERROR",
  INFO = "INFO",
  SUCCESS = "SUCCESS",
  WARNING = "WARNING",
}

const BANNER_COLOR_MAP = {
  [BannerType.ERROR]: "var(--pale-red)",
  [BannerType.INFO]: "var(--salmon)",
  [BannerType.SUCCESS]: "var(--black)",
  [BannerType.WARNING]: "var(--warning-yellow)",
};

export type FlashBannerProps = {
  useDispatch?: () => React.Dispatch<Action>;
  centerMessage?: boolean;
};

export const FlashBanner: React.FC<FlashBannerProps> = ({
  useDispatch = redux.useDispatch,
  centerMessage = false,
}) => {
  const dispatch = useDispatch();

  const { message, noTimeOut, type, timeout } =
    redux.useSelector(getFlashBanner);

  useEffect(() => {
    if (!message || noTimeOut) return;

    setTimeout(() => {
      if (!message) return;
      dispatch(a.clearFlashBanner());
    }, timeout);
  }, [dispatch, message, noTimeOut, timeout]);

  if (!message) return null;

  const handleClearBannerClick = () => {
    dispatch(a.clearFlashBanner());
  };

  return (
    <sc.FlashBanner color={BANNER_COLOR_MAP[type]} data-testid="flash-banner">
      <sc.Message centerMessage={centerMessage}>{message}</sc.Message>
      <sc.Closer
        color="var(--white)"
        width={20}
        onClick={handleClearBannerClick}
        data-testid="clear-banner"
      />
    </sc.FlashBanner>
  );
};

type MessageProps = {
  centerMessage?: boolean;
};

const sc = {
  Closer: styled(Clear)`
    cursor: pointer;
    height: 20px;
    position: absolute;
    right: 12px;
    top: 10px;
    width: ${(props) => props.theme.flashBanner.closerWidth}px;
  `,
  FlashBanner: styled(Banner)`
    > span {
      ${mobileBreakpoint(
        css`
          margin: 0 ${(props) => props.theme.flashBanner.closerWidth + 10}px;
        `
      )}
    }
  `,
  Message: styled.span<MessageProps>`
    margin: ${(props): SimpleInterpolation =>
      props.centerMessage ? "auto" : "0"};
  `,
};
