import * as datefns from "date-fns";
import cookie from "js-cookie";

import { BannerType } from "./shared/components/FlashBanner";
import { SESSION_HAS_EXPIRED } from "./shared/text";

const DOMAIN = process.env.REACT_APP_CONSUMER_API_URL?.includes("localhost")
  ? "localhost"
  : ".pax.com";

type JwtUserData = {
  "token-fingerprint": string;
  exp: number;
  iat: number;
  peripheralId: string;
  uuid: string;
};

export const clearCurrentTokens = (): void => {
  if (getLoginCookie()) {
    deleteLoginCookie();
    setBannerCookie(SESSION_HAS_EXPIRED, BannerType.ERROR);
    window.location.reload();
  }
  deleteAnonymousCookie();
};

// Anonymous cookie.

export const ANONYMOUS_COOKIE_NAME =
  process.env.REACT_APP_ANONYMOUS_COOKIE || "paxAnonymousCookie";

export const getAnonymousUserCookie = (): string => {
  return cookie.get(ANONYMOUS_COOKIE_NAME) || "";
};

type DeleteAnonymousCookie = () => void;

export const deleteAnonymousCookie: DeleteAnonymousCookie = () => {
  cookie.remove(ANONYMOUS_COOKIE_NAME, { domain: DOMAIN });
};

type SetAnonymousCookie = (token: string) => void;

export const setAnonymousCookie: SetAnonymousCookie = (token) => {
  const inTenDays = datefns.addDays(new Date(), 10);

  cookie.set(ANONYMOUS_COOKIE_NAME, token, {
    domain: DOMAIN,
    expires: inTenDays,
  });
};

export const getAnonymousUserUuid = (): string => {
  return parseJwt<JwtUserData>(getAnonymousUserCookie()).uuid;
};

export const getAnonymousUserPeripheralId = (): string => {
  return parseJwt<JwtUserData>(getAnonymousUserCookie()).peripheralId;
};

// Banner cookie.

const BANNER_COOKIE_NAME = "paxBannerCookie";

export const getBannerCookie = (): string => {
  return cookie.get(BANNER_COOKIE_NAME) || "";
};

export type SetBannerCookie = (message: string, bannerType: BannerType) => void;

export const setBannerCookie: SetBannerCookie = (message, bannerType) => {
  const inFiveSeconds = new Date(new Date().getTime() + 5 * 1000);

  cookie.set(BANNER_COOKIE_NAME, `${message}:${bannerType}`, {
    expires: inFiveSeconds,
  });
};

// Login cookie.

export const LOGIN_COOKIE_NAME =
  process.env.REACT_APP_LOGIN_COOKIE || "paxLoginCookie";

export const getLoginCookie = (): string => {
  return cookie.get(LOGIN_COOKIE_NAME) || "";
};

export const getRegisteredUserPeripheralId = (): string => {
  return parseJwt<JwtUserData>(getLoginCookie()).peripheralId;
};

export const getRegisteredUserUuid = (): string => {
  return parseJwt<JwtUserData>(getLoginCookie()).uuid;
};

export const deleteLoginCookie = (): void => {
  cookie.remove(LOGIN_COOKIE_NAME, { domain: DOMAIN });
};

// From https://stackoverflow.com/a/38552302
const parseJwt = <T extends {} = {}>(token: string): T => {
  if (!token) return {} as T;

  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
  return JSON.parse(jsonPayload);
};
