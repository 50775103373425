import React from "react";

import * as t from "../../../../api/consumer/types";
import * as strainColors from "../../../../shared/colors/Strain";
import * as utils from "../../../../shared/utils";

import * as hooks from "../../../hooks";

import { BasicStrength } from "./BasicStrength";
import { CannabinoidStrength } from "./CannabinoidStrength";

export type PodStrengthProps = {
  isModule?: boolean;
  strain: t.StrainJson;
  useBatchListFetch?: hooks.UseBatchListFetch;
};

export const PodStrength: React.FC<PodStrengthProps> = ({
  isModule = false,
  strain,
  useBatchListFetch = hooks.useBatchListFetch,
}) => {
  const batchList = useBatchListFetch(strain.id);
  const potency = utils.capitalizeRemoveUnderscores(strain.potency);
  const strainColor = strainColors.getStrainColors(strain);

  const latestTestReport = batchList.find(
    (batch) => batch.parsedBatchResult
  )?.parsedBatchResult;

  if (!isModule && !!latestTestReport) {
    return (
      <CannabinoidStrength
        potency={potency}
        strainColor={strainColor}
        testReport={latestTestReport}
      />
    );
  }

  return (
    <BasicStrength
      isModule={isModule}
      potency={potency}
      strain={strain}
      strainColor={strainColor}
    />
  );
};
