import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import * as s from "../../../api/consumer/selectors/user";
import { Column } from "../../../shared/components/Flex";
import * as Icons from "../../../shared/components/Icons";
import * as Text from "../../../shared/components/Text";
import * as t from "../../../shared/text";

import * as c from "../../constants";

export const MobileMenu: React.FC = () => {
  const isUserInUS = useSelector(s.getIsUserInUS);

  return (
    <sc.Container>
      {/* Leaving submenus commented out for now in case we want to add in again later. */}
      {/* <SubMenuSlideIn label={t.Vapes}>
        <MobileSingleColumnMenuContent
          menuItems={
            isUserInUS
              ? menuItems.SIMPLIFIED_DEVICE_MENU_ITEMS_US
              : menuItems.SIMPLIFIED_DEVICE_MENU_ITEMS_CA
          }
        />
      </SubMenuSlideIn>
      <SubMenuSlideIn label={t.CANNABIS}>
        <MobileSingleColumnMenuContent
          menuItems={
            isUserInUS
              ? menuItems.CANNABIS_MENU_ITEMS_US
              : menuItems.CANNABIS_MENU_ITEMS_CA
          }
        />
      </SubMenuSlideIn> */}
      <sc.MenuLink
        href={isUserInUS ? c.US_FAQ : c.CANADA_FAQ}
        target="_blank"
        rel="noopener noreferrer"
      >
        {t.FAQ}
      </sc.MenuLink>
    </sc.Container>
  );
};

const sc = {
  Chevron: styled(Icons.ChevronRight)`
    height: 18px;
    width: 18px;
  `,

  Container: styled(Column)`
    width: 100%;

    &::-webkit-scrollbar {
      display: none;
    }
  `,

  MenuContentContainer: styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    max-width: 260px;
  `,

  MenuItem: styled.button`
    ${Text.PlaaxBold}
    align-items: center;
    background-color: var(--transparent);
    border: none;
    display: flex;
    font-size: 16px;
    height: 45px;
    justify-content: space-between;
    letter-spacing: 2px;
    padding: 0;
    text-transform: uppercase;
    width: 100%;
  `,

  MenuLink: styled.a`
    ${Text.PlaaxBold}
    align-items: center;
    color: var(--black);
    display: flex;
    font-size: 16px;
    height: 45px;
    letter-spacing: 2px;
    text-decoration: none;
    text-transform: uppercase;
    width: 100%;
  `,

  TitleText: styled.div`
    margin-bottom: 15px;
  `,
};
