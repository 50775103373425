import React from "react";
import styled from "styled-components";

import InfiniteLoadingList from "../../../shared/components/InfiniteLoadingList";
import { getRowReducer } from "../../../shared/utils";

import { ROW_SPACE } from "../../constants";
import * as SC from "../../styledComponents";

export type MyPodsLoadingContentProps = {
  cardWidth: number;
  columnCount: number;
  rowHeight: number;
  width: number;
};

export const MyPodsLoadingContent: React.FC<MyPodsLoadingContentProps> = ({
  cardWidth,
  columnCount,
  rowHeight,
  width,
}) => {
  const rows = [1, 2, 3, 4]
    .reduce(getRowReducer(columnCount), [])
    .map((podRow) => (
      <SC.PodsRow height={rowHeight} key={`row-${podRow[0]}`}>
        {podRow.map((pod) => (
          <SC.PodCardContainer
            data-testid={`my-loading-pod-card-${pod}`}
            height={rowHeight - ROW_SPACE}
            key={`loading-card-${pod}`}
            width={cardWidth}
          >
            <SC.PodCardPartnerLogo borderColor="var(--very-light-pink)" />
          </SC.PodCardContainer>
        ))}
      </SC.PodsRow>
    ));

  return (
    <sc.ContentContainer data-testid="my-pods-loading-pods">
      <InfiniteLoadingList
        hasMoreItems={false}
        loadMoreRows={async () => {}}
        rowHeight={rowHeight}
        rows={rows}
        width={width}
      />
    </sc.ContentContainer>
  );
};

const sc = {
  ContentContainer: styled.div`
    pointer-events: none;
  `,
};
