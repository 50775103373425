import React, { useState } from "react";
import styled, { css } from "styled-components";

import { StrainJson } from "../../api/consumer/types/strain";
import * as r from "../../main/routes";
import { Star } from "../../shared/components/Icons";
import * as Text from "../../shared/components/Text";
import { useSignInUpsellModal } from "../../shared/hooks";
import * as text from "../../shared/text";
import { desktopBreakpoint } from "../../shared/utils";

import { PodFeedbackModal } from "./PodFeedbackModal";

export type PodFeedbackAddNewPodProps = {
  onReviewComplete: () => void;
  onReviewStarted: () => void;
  strain?: StrainJson | null;
};

export const PodFeedbackAddNewPod: React.FC<PodFeedbackAddNewPodProps> = ({
  onReviewComplete,
  onReviewStarted,
  strain,
}) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const { handleShowSignInUpsell, signInUpsellModal } = useSignInUpsellModal(
    text.signInToRateThisPod(strain ? strain.name : text.THIS_POD),
    <Star $isFilled />,
    strain ? r.podDetails(strain.id) : r.podsHome(),
    text.SIGN_IN_TO_RATE
  );

  if (!strain) return null;

  const handleAddReviewClick = () => {
    onReviewStarted();
    handleShowSignInUpsell();
    setIsModalOpen(true);
  };

  const handleModalClose = (): void => {
    setIsModalOpen(false);
    onReviewComplete();
  };

  return (
    <>
      <sc.Container>
        <sc.Star />
        <sc.AddPodLink
          data-testid="add-pod-link"
          onClick={handleAddReviewClick}
        >
          {text.HAVE_YOU_TRIED_THIS_PRODUCT} {text.ADD_YOUR_REVIEW}
        </sc.AddPodLink>
      </sc.Container>
      <PodFeedbackModal
        isOpen={isModalOpen}
        onRequestClose={handleModalClose}
        strainId={strain.id}
      />
      {signInUpsellModal}
    </>
  );
};

const sc = {
  AddPodLink: styled.div`
    ${Text.Plaax}
    cursor: pointer;
    font-size: 14px;
    letter-spacing: -0.105px;
    line-height: 20px;
    text-decoration: underline;
  `,

  Container: styled.div`
    border-bottom: 1px solid var(--khaki-20);
    display: flex;
    padding-bottom: 20px;

    ${desktopBreakpoint(css`
      border-bottom: 0;
    `)}
  `,

  Star: styled(Star)`
    align-self: center;
    height: 12px;
    margin: 2px 8px 0 0;
    width: 12px;

    & > path {
      fill: none;
      stroke: var(--black);
      stroke-width: 2px;
    }
  `,
};
