import React, { useState } from "react";
import { useSelector } from "react-redux";

import { navigator } from "../../navigator";

import { MainAppState } from "../main/types";
import { NotificationsIcon } from "./components/NotificationsIcon";
import { NotificationsModal } from "./components/NotificationsModal";
import * as s from "./selectors";

type UseNotifications = (
  getNotifications?: (state: MainAppState) => s.NotificationObject[]
) => React.ReactNode;

export const useNotifications: UseNotifications = () => {
  const notifications = useSelector(
    navigator.bluetooth ? s.getNotifications : s.getExploreAppNotifications
  );

  return (
    <div data-testid="notifications">
      {notifications.map(({ Notification, type }) => {
        return <Notification key={type} />;
      })}
    </div>
  );
};

type UseNotificationsModal = () => {
  notificationsIcon: React.ReactNode;
  notificationsModal: React.ReactNode;
};

export const useNotificationsModal: UseNotificationsModal = () => {
  const notifications = useNotifications();

  const [isNotificationsModalOpen, setIsNotificationsModalOpen] =
    useState<boolean>(false);

  const notificationsIcon = (
    <NotificationsIcon
      setIsNotificationsModalOpen={setIsNotificationsModalOpen}
    />
  );

  const notificationsModal = (
    <NotificationsModal
      isOpen={isNotificationsModalOpen}
      onRequestClose={() => setIsNotificationsModalOpen(false)}
      notifications={notifications}
      setIsOpen={setIsNotificationsModalOpen}
    />
  );

  return { notificationsIcon, notificationsModal };
};
