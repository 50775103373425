// TODO: remove use of any
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import compareVersions from "compare-versions";

import * as c from "../constants";
import { BaseDevice } from "../shared/BaseDevice";
import {
  ERA_AES_KEY_SIZE,
  LegacyDeviceEncryption,
} from "../shared/LegacyDeviceEncryption";
import * as t from "../types";
import * as u from "../utils";

const PAX_3_BRIGHTNESS_MIN = 0;
const PAX_3_BRIGHTNESS_MAX = 128;
const PAX_3_HAPTICS_AMPLITUDE_MIN = 0;
const PAX_3_HAPTICS_AMPLITUDE_MAX = 128;

const CONNECTION_INTERVAL = 200;
const CONNECTION_MAX_INTERVALS = 20;

type Pax3DeviceOptions = {
  serial: string;
};

export class Pax3Device extends BaseDevice {
  isInitializing = false;
  type = t.DeviceType.PAX_3;

  encryption?: LegacyDeviceEncryption;

  async initialize(
    attributeIdsToRead: number[],
    options: Pax3DeviceOptions
  ): Promise<void> {
    this.isInitializing = true;
    this.firmwareRevision = await this.readFirmwareRevision();
    this.supportsLogs = false;
    this.supportsRenameDevice =
      compareVersions(this.firmwareRevision, "0.7.0") !== -1;
    this.supportsSessionControl = false;
    this.systemId = (await this.readSystemId()).toString();

    this.serial = options.serial;
    this.encryption = new LegacyDeviceEncryption(this.serial);

    const service = await this.getService(c.ERA_PAX_SERVICE_UUID);

    this.notify = await service.getCharacteristic(c.ERA_CHARACTERISTICS.Notify);
    this.read = await service.getCharacteristic(c.ERA_CHARACTERISTICS.Read);
    this.write = await service.getCharacteristic(c.ERA_CHARACTERISTICS.Write);

    this.notify.addEventListener(
      "characteristicvaluechanged",
      this.onCharacteristicValueChanged
    );

    await this.notify.startNotifications();

    // For Pax3 devices with firmware <0.6.90, this is a no-op.
    // These devices provide attribute value notifications upon connecting.
    this.readAttributes(attributeIdsToRead);

    // Detect a bad identifier by waiting for notifications on this.notify.
    // Don't use await to avoid delaying initialize from returning.
    return new Promise((resolve, reject) => {
      let intervalCount = 0;

      const interval = setInterval(() => {
        intervalCount++;

        console.log({
          intervalCount,
          receivedNotification: this.hasReceivedNotification,
          receivedUnknownAttribute: this.hasReceivedUnknownAttribute,
        });

        switch (true) {
          case this.hasReceivedUnknownAttribute:
            clearInterval(interval);
            this.isInitializing = false;
            return reject(new Error(c.ERR_BAD_IDENTIFIER));

          case intervalCount > CONNECTION_MAX_INTERVALS:
            clearInterval(interval);
            this.isInitializing = false;
            return reject(new Error(c.ERR_CONNECTION_TIMEOUT));

          case this.hasReceivedNotification:
            // Successful connection.
            clearInterval(interval);
            this.isInitializing = false;
            resolve();
        }
      }, CONNECTION_INTERVAL);
    });
  }

  disconnect(): void {
    this.notify?.removeEventListener(
      "characteristicvaluechanged",
      this.onCharacteristicValueChanged
    );

    if (this.gatt.connected) this.gatt.disconnect();
  }

  onCharacteristicValueChanged = async (event: Event): Promise<void> => {
    if (!event || !event.target || !this.read) return;

    let decryptedData = new Uint8Array();
    try {
      // Received a notification from the "data ready" characteristic.
      // Read this data from the "read" characteristic.
      const value = await this.read.readValue();
      const bytes = new Uint8Array(value.buffer);

      // Decrypt the data.
      const data = bytes.slice(0, -1 * ERA_AES_KEY_SIZE);
      const encryptionInitializationVector = bytes.slice(-1 * ERA_AES_KEY_SIZE);

      const encryption = new LegacyDeviceEncryption(
        this.serial || "",
        encryptionInitializationVector
      );
      decryptedData = encryption.decrypt(data);
    } catch (err) {
      // The PAX 3's ACTUAL_TEMP attribute is constantly changing
      // and will sometimes cause an error if a new value comes in
      // as the device disconnects. This prevent notifiying Bugsnag
      // of those errors.
      return;
    }

    // Update device state, parse the attribute value, and notify listeners.
    this.hasReceivedNotification = true;
    const attributeId = decryptedData[0];
    const attributeValue = this.parseAttributeValue(decryptedData);
    this.events.emit("receivedAttribute", attributeId, attributeValue);
  };

  readBrightness(): void {
    this.readAttribute(c.ATTRIBUTE_BRIGHTNESS);
  }

  readColorTheme(): void {
    this.readAttribute(c.ATTRIBUTE_COLOR_THEME);
  }

  readDeviceName(): void {
    this.readAttribute(c.ATTRIBUTE_DEVICE_NAME);
  }

  readHaptics(): void {
    this.readAttribute(c.ATTRIBUTE_HAPTIC_MODE);
  }

  readHeaterSetPoint(): void {
    this.readAttribute(c.ATTRIBUTE_HEATER_SET_POINT);
  }

  readLockState(): void {
    this.readAttribute(c.ATTRIBUTE_LOCKED);
  }

  writeBrightness(
    percentage: number,
    command = t.BrightnessCommand.DEFAULT
  ): void {
    const brightness = this.percentageToBrightness(percentage);

    this.writeAttribute(c.ATTRIBUTE_BRIGHTNESS, {
      brightness,
      command,
    });
  }

  writeColorMode(colorMode: t.Pax3.ColorMode): void {
    this.writeAttribute(c.ATTRIBUTE_COLOR_THEME, colorMode);
  }

  writeDefaultColorMode(): void {
    this.writeAttribute(c.ATTRIBUTE_UI_MODE, 0);
  }

  writeDeviceName(deviceName: string): void {
    this.writeAttribute(c.ATTRIBUTE_DEVICE_NAME, deviceName);
  }

  writeDynamicMode(dynamicMode: t.Pax3.DynamicMode): void {
    this.writeAttribute(c.ATTRIBUTE_DYNAMIC_MODE, dynamicMode.type);
    this.writeAttribute(
      c.ATTRIBUTE_HEATING_PARAMETERS,
      dynamicMode.heatingParameters
    );

    if (dynamicMode.brightness) {
      this.writeBrightness(
        dynamicMode.brightness,
        t.BrightnessCommand.TEMPORARY
      );
    } else {
      this.writeBrightness(0, t.BrightnessCommand.RESET);
    }
  }

  writeHaptics(percentage: number): void {
    const haptics = this.percentageToHaptics(percentage);
    this.writeAttribute(c.ATTRIBUTE_HAPTIC_MODE, haptics);
  }

  writeHeaterSetPoint(heaterSetPoint: number): void {
    this.writeAttribute(c.ATTRIBUTE_HEATER_SET_POINT, heaterSetPoint);
  }

  writeLockState(value: number): void {
    this.writeAttribute(c.ATTRIBUTE_LOCKED, value);
  }

  getWriteOperationBuffer(attributeId: number, value: any): Uint8Array {
    if (!this.encryption) throw new Error("not initialized");

    let buffer = new ArrayBuffer(16);
    let view = new DataView(buffer);
    view.setUint8(0, attributeId);

    switch (attributeId) {
      case c.ATTRIBUTE_BRIGHTNESS:
        view.setUint8(1, value.brightness);
        // Set brightness and default brightness.
        view.setUint8(2, value.command);
        break;

      case c.ATTRIBUTE_COLOR_THEME:
        // Color theme requires a larger buffer
        buffer = new ArrayBuffer(34);
        view = new DataView(buffer);
        view.setUint8(0, attributeId);

        view.setUint8(1, 4);
        const colorTheme = value as t.Pax3.ColorMode;
        // startup
        view.setUint8(2, colorTheme.startup.color1.red);
        view.setUint8(3, colorTheme.startup.color1.green);
        view.setUint8(4, colorTheme.startup.color1.blue);
        view.setUint8(5, colorTheme.startup.color2.red);
        view.setUint8(6, colorTheme.startup.color2.green);
        view.setUint8(7, colorTheme.startup.color2.blue);
        view.setUint8(8, colorTheme.startup.animation);
        view.setUint8(9, colorTheme.startup.frequency);

        // heating
        view.setUint8(10, colorTheme.heating.color1.red);
        view.setUint8(11, colorTheme.heating.color1.green);
        view.setUint8(12, colorTheme.heating.color1.blue);
        view.setUint8(13, colorTheme.heating.color2.red);
        view.setUint8(14, colorTheme.heating.color2.green);
        view.setUint8(15, colorTheme.heating.color2.blue);
        view.setUint8(16, colorTheme.heating.animation);
        view.setUint8(17, colorTheme.heating.frequency);

        // regulating
        view.setUint8(18, colorTheme.regulating.color1.red);
        view.setUint8(19, colorTheme.regulating.color1.green);
        view.setUint8(20, colorTheme.regulating.color1.blue);
        view.setUint8(21, colorTheme.regulating.color2.red);
        view.setUint8(22, colorTheme.regulating.color2.green);
        view.setUint8(23, colorTheme.regulating.color2.blue);
        view.setUint8(24, colorTheme.regulating.animation);
        view.setUint8(25, colorTheme.regulating.frequency);

        // standby
        view.setUint8(26, colorTheme.standby.color1.red);
        view.setUint8(27, colorTheme.standby.color1.green);
        view.setUint8(28, colorTheme.standby.color1.blue);
        view.setUint8(29, colorTheme.standby.color2.red);
        view.setUint8(30, colorTheme.standby.color2.green);
        view.setUint8(31, colorTheme.standby.color2.blue);
        view.setUint8(32, colorTheme.standby.animation);
        view.setUint8(33, colorTheme.standby.frequency);

        break;

      case c.ATTRIBUTE_DEVICE_NAME:
        const deviceName = value as string;
        view.setUint8(1, deviceName.length);

        const encodedName = u.encodeTextValue(deviceName);
        for (let i = 0; i < encodedName.length; i++) {
          view.setUint8(2 + i, encodedName[i]);
        }
        break;

      case c.ATTRIBUTE_DYNAMIC_MODE:
        view.setUint8(1, value);
        break;

      case c.ATTRIBUTE_HAPTIC_MODE:
        // We only send amplitude, but the PAX3 will accept haptic profiles if we specify them
        view.setUint8(1, value);
        view.setUint8(2, 0);
        break;

      case c.ATTRIBUTE_HEATER_SET_POINT:
        view.setUint16(1, value, true);
        break;

      case c.ATTRIBUTE_HEATING_PARAMETERS:
        // Heating parameters requires a larger buffer
        buffer = new ArrayBuffer(23);
        view = new DataView(buffer);
        view.setUint8(0, attributeId);
        const parameters = value as t.Pax3.HeatingParameters;

        view.setUint16(1, parameters.standbyTemperature, true);
        view.setUint16(3, parameters.noMotionToStandbyTime, true);
        view.setUint16(5, parameters.noLipCooldownTemperatureChange, true);
        view.setUint16(7, parameters.noLipCooldownStart, true);
        view.setUint16(9, parameters.noLipCooldownRate, true);
        view.setUint16(11, parameters.noLipPowerOffTime, true);
        view.setUint16(13, parameters.boostTemperatureChange, true);
        view.setUint16(15, parameters.rampTargetTemperature, true);
        view.setUint16(17, parameters.rampStartingTemperature, true);
        view.setUint16(19, parameters.rampRate, true);
        view.setUint16(21, parameters.options, true);
        break;

      case c.ATTRIBUTE_LOCKED:
        view.setUint8(1, value);
        break;

      case c.ATTRIBUTE_LOG_SYNC_REQUEST:
        view.setUint32(1, value.timestamp, true);
        view.setUint8(5, value.timestampOffset);
        break;

      case c.ATTRIBUTE_SESSION_CONTROL:
        const valueBuffer = value as Uint8Array;
        for (let i = 0; i < valueBuffer.length; i++) {
          view.setUint8(1 + i, valueBuffer[i]);
        }
        break;

      case c.ATTRIBUTE_STATUS_UPDATE:
        view.setBigUint64(1, BigInt(value), true);
        break;

      case c.ATTRIBUTE_TIME:
        view.setUint32(1, value, true);
        break;

      case c.ATTRIBUTE_UI_MODE:
        view.setUint8(1, value);
        break;

      default:
        throw new Error(`Unrecognized attribute: ${attributeId}`);
    }

    return this.encryption.encrypt(new Uint8Array(buffer));
  }

  protected async readFirmwareRevision(): Promise<string> {
    const data: DataView = await this.readCharacteristicValue(
      c.DEVICE_INFO_SERVICE_UUID,
      c.ERA_CHARACTERISTICS.FirmwareRevision
    );
    const revision: string = u.decodeTextValue(data);

    return revision;
  }

  protected async readSystemId(): Promise<bigint> {
    const data: DataView = await this.readCharacteristicValue(
      c.DEVICE_INFO_SERVICE_UUID,
      c.ERA_CHARACTERISTICS.SystemId
    );

    return data.getBigUint64(0, true);
  }

  private brightnessToPercentage(brightness: number): number {
    return (
      (brightness - PAX_3_BRIGHTNESS_MIN) /
      (PAX_3_BRIGHTNESS_MAX - PAX_3_BRIGHTNESS_MIN)
    );
  }

  private hapticsToPercentage(haptics: number): number {
    return (
      (haptics - PAX_3_HAPTICS_AMPLITUDE_MIN) /
      (PAX_3_HAPTICS_AMPLITUDE_MAX - PAX_3_HAPTICS_AMPLITUDE_MIN)
    );
  }

  private bytesToColorMode(bytes: Uint8Array): t.ColorMode {
    bytes = bytes.map((byte) => byte & 0xff);

    const colorMode: t.ColorMode = {
      animation: bytes[6],
      color1: {
        blue: bytes[2],
        green: bytes[1],
        red: bytes[0],
      },
      color2: {
        blue: bytes[5],
        green: bytes[4],
        red: bytes[3],
      },
      frequency: bytes[7],
    };

    return colorMode;
  }

  private parseAttributeValue(bytes: Uint8Array): any {
    const attributeId = bytes[0];
    const view = new DataView(bytes.buffer);

    switch (attributeId) {
      // Attribute with 1-byte uint8/boolean values.
      case c.ATTRIBUTE_BATTERY:
      case c.ATTRIBUTE_GAME_MODE:
      case c.ATTRIBUTE_LOCKED:
      case c.ATTRIBUTE_POD_INSERTED:
      case c.ATTRIBUTE_SHELL_COLOR:
      case c.ATTRIBUTE_UI_MODE:
      case c.ATTRIBUTE_DYNAMIC_MODE:
      case c.ATTRIBUTE_LOW_SOC_MODE:
      case c.ATTRIBUTE_HEATING_STATE:
        return bytes[1];

      case c.ATTRIBUTE_BRIGHTNESS:
        return this.brightnessToPercentage(bytes[1]);

      case c.ATTRIBUTE_CHARGE_STATUS:
        const chargeStatus = bytes[1].toString(2);

        return {
          isCharging: chargeStatus[0] === "1",
          isChargingComplete: chargeStatus[1] === "1",
        };

      case c.ATTRIBUTE_COLOR_THEME:
        // Format is 1 byte for number of color modes, followed by 8 bytes per mode.
        const colorModesCount = bytes[1];
        const colorModes: t.ColorMode[] = [];

        for (let i = 0; i < colorModesCount; i++) {
          const colorBytes = bytes.slice(2 + i * 8, 10 + i * 8);

          const colorMode = this.bytesToColorMode(colorBytes);
          colorModes.push(colorMode);
        }

        const colorTheme: t.Pax3.ColorMode = {
          heating: colorModes[1],
          regulating: colorModes[2],
          standby: colorModes[3],
          startup: colorModes[0],
        };

        return colorTheme;

      case c.ATTRIBUTE_DEVICE_NAME:
        const nameLength = bytes[1];
        const data = bytes.slice(2, 2 + nameLength);
        const name = u.decodeTextValue(data);

        return name;

      case c.ATTRIBUTE_HEATER_SET_POINT:
        const heaterSetPoint = view.getUint16(1, true);
        return heaterSetPoint;

      case c.ATTRIBUTE_SUPPORTED_ATTRIBUTES:
        const bitfield = view.getBigUint64(1);
        const binary = bitfield.toString(2);
        return binary;

      case c.ATTRIBUTE_TIME:
        const epochInSeconds = view.getUint32(1, true);
        return epochInSeconds;

      case c.ATTRIBUTE_USAGE_LIMIT:
        return view.getUint32(1, true);

      // TODO These attributes are not used in the UI. They are not parsed properly.
      case c.ATTRIBUTE_HEATER_RANGES:
        return Array.from(bytes.slice(1));

      case c.ATTRIBUTE_ACTUAL_TEMP:
      case c.ATTRIBUTE_CURRENT_TARGET_TEMP:
        return view.getUint16(1, true);

      case c.ATTRIBUTE_HAPTIC_MODE:
        // We only read the amplitude
        const masterAmplitude = view.getUint8(1);

        return this.hapticsToPercentage(masterAmplitude);

      default:
        this.hasReceivedUnknownAttribute = true;

        // Silence unrecognized attribute ids during initialization.
        // The serial number is probably incorrect.
        if (!this.isInitializing) {
          console.error(`Unrecognized Pax3 attribute: ${attributeId}`);
        }

        return bytes[1];
    }
  }

  private percentageToBrightness(percentage: number): number {
    return Math.round(percentage * PAX_3_BRIGHTNESS_MAX);
  }

  private percentageToHaptics(percentage: number): number {
    return Math.round(percentage * PAX_3_HAPTICS_AMPLITUDE_MAX);
  }
}
