import React from "react";

import { ModalAction } from "../../modal/actions";
import * as Text from "../../shared/components/Text";
import * as text from "../../shared/text";

import * as SC from "../styledComponents";

export type StateProps = {
  deviceName: string;
  latestFirmwareVersion: string;
};

export type DispatchProps = {
  openFirmwareModal?: ModalAction;
};

export type FirmwareNotificationProps = StateProps & DispatchProps;

export const FirmwareNotification: React.FC<FirmwareNotificationProps> = ({
  deviceName,
  latestFirmwareVersion,
  openFirmwareModal,
}) => {
  return (
    <SC.Notification>
      <SC.Title>{text.FIRMWARE_UPDATE_AVAILABLE}</SC.Title>
      <SC.Message>
        {firmwareMessage(latestFirmwareVersion)}
        <Text.Bold>{deviceName}</Text.Bold>
      </SC.Message>
      <SC.Button onClick={openFirmwareModal}>{text.UPDATE}</SC.Button>
    </SC.Notification>
  );
};

export const firmwareMessage = (version: string): string =>
  `${text.FIRMWARE_UPDATE} v.${version} ${text.AVAILABLE_FOR} `;
