// Spec:
// https://github.com/PaxLabs/pax-logging/blob/develop/schemas/events/app/search/location_0.json

import { AppThunk } from "../../shared/types";

import { trackEventAction } from "../actions";

const EVENT_NAME = "location";

type LocationEventProperties = {
  action: "manually_set" | "cleared";
  inputType?: "postal_code" | "street_address";
};

type TrackLocation = (
  action: "manually_set" | "cleared",
  inputType?: "postal_code" | "street_address"
) => AppThunk;

export const trackLocation: TrackLocation =
  (action, inputType) =>
  (dispatch, getState): void => {
    trackEventAction<LocationEventProperties>(EVENT_NAME, {
      action,
      inputType,
    })(dispatch, getState, null);
  };
