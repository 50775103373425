import React from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import styled, { css } from "styled-components";

import { getIsUserInUS } from "../../api/consumer/selectors/user";
import { isDevPageEnabled } from "../../featureFlags";
import * as c from "../../pax-nav/constants";
import { PodsLocationHeader } from "../../pods/components/PodsLocationHeader";
import { Row } from "../../shared/components/Flex";
import { PaxLogo } from "../../shared/components/Icons";
import * as Text from "../../shared/components/Text";
import * as text from "../../shared/text";
import { desktopBreakpoint } from "../../shared/utils";

import * as r from "../routes";
import { Page } from "../types";
import * as u from "../utils";

export type TopbarProps = {
  supportsBluetooth?: boolean;
};

export const Topbar: React.FC<TopbarProps> = ({
  supportsBluetooth = !!navigator.bluetooth,
  ...props
}) => {
  const isUserInUS = useSelector(getIsUserInUS);

  const renderUnderlineIfPageIsActive = (isActive: boolean) =>
    isActive && <sc.ActiveUnderline />;

  const isExplorePageActive = u.isPageActive(
    location,
    Page.EXPLORE,
    supportsBluetooth
  );

  return (
    <sc.Container {...props}>
      <sc.NavContainer>
        <sc.Nav>
          <sc.DesktopPAXLink
            href={isUserInUS ? c.US_HOME : c.CANADA_HOME}
            target="_blank"
            rel="noopener noreferrer"
          >
            <sc.PaxLogo />
          </sc.DesktopPAXLink>
          <sc.NavLink to={r.device()} data-testid="device-nav-link">
            {text.DEVICE}
            {renderUnderlineIfPageIsActive(
              u.isPageActive(location, Page.DEVICE, supportsBluetooth)
            )}
          </sc.NavLink>
          <sc.NavLink to={r.podsHome()} data-testid="explore-nav-link">
            {text.EXPLORE}
            {renderUnderlineIfPageIsActive(isExplorePageActive)}
          </sc.NavLink>
          <sc.NavLink to={r.account()} data-testid="my-pax-nav-link">
            {text.ACCOUNT}
            {renderUnderlineIfPageIsActive(
              u.isPageActive(location, Page.ACCOUNT)
            )}
          </sc.NavLink>
          <sc.ExteriorLink
            href={isUserInUS ? c.US_FAQ : c.CANADA_FAQ}
            data-testid="support-nav-link"
            target="_blank"
            rel="noopener noreferrer"
          >
            {text.FAQ}
          </sc.ExteriorLink>
          {isDevPageEnabled && (
            <sc.NavLink to="/device/_dev" data-testid="dev-link">
              {text.DEVELOPER}
              {renderUnderlineIfPageIsActive(
                u.isPageActive(location, Page.DEV)
              )}
            </sc.NavLink>
          )}
        </sc.Nav>
        {isExplorePageActive && <PodsLocationHeader />}
      </sc.NavContainer>
    </sc.Container>
  );
};

const sc = {
  ActiveUnderline: styled.div`
    background-color: var(--black);
    border-radius: 4px;
    display: block;
    height: 2px;
    position: relative;
    top: 8px;
  `,

  Container: styled(Row)`
    border-bottom: solid 1px var(--very-light-pink-6);
    border-top: solid 1px var(--very-light-pink-6);
    display: none;
    height: var(--top-nav-height);
    white-space: nowrap;

    ${desktopBreakpoint(
      css`
        box-shadow: var(--mobile-header-box-shadow);
        display: flex;
        z-index: var(--z-index-top-bar);
      `
    )}
  `,

  DesktopPAXLink: styled.a`
    margin-right: 40px;
  `,

  ExteriorLink: styled.a`
    ${Text.MediumLLBold}
    color: var(--black-60);
    display: inline-block;
    font-size: 16px;
    line-height: 13px;
    margin-right: 40px;
    text-decoration: none;
    text-transform: uppercase;
  `,

  Nav: styled.nav`
    align-items: center;
    display: flex;
    height: var(--top-nav-height);
    padding-bottom: 2px;
  `,

  NavContainer: styled.div`
    display: flex;
    justify-content: space-between;
    margin: auto;
    max-width: var(--nav-bar-width);
    padding: 0 16px;
    width: 100%;

    ${desktopBreakpoint(
      css`
        padding: 0 3.125%;
      `
    )}
  `,

  NavLink: styled(NavLink)`
    ${Text.MediumLLBold}
    color: var(--black-60);
    display: inline-block;
    font-size: 16px;
    line-height: 13px;
    margin-right: 40px;
    text-decoration: none;
    text-transform: uppercase;

    &.active {
      color: var(--black);
    }
  `,

  PaxLogo: styled(PaxLogo)`
    width: 94px;
  `,
};
