import React from "react";
import styled, { css } from "styled-components";

import { useDesktopMediaQuery } from "../../shared/hooks";
import { desktopBreakpoint } from "../../shared/utils";

import * as SC from "../styledComponents";
import { PodsFilterSidebar } from "./PodsFilter/PodsFilterSidebar";
import { PodsCarousel } from "./PodsCarousel";
import { PodsHomePageHeader } from "./PodsHomePageHeader";

export const PodsHomeLoadingPage: React.FC = () => {
  const isDesktop = useDesktopMediaQuery();

  return (
    <sc.ExplorePage>
      <PodsHomePageHeader />
      <SC.PodsHomeContentContainer>
        {isDesktop && <PodsFilterSidebar disableWhenEmpty />}
        <SC.PodsCarouselContainer>
          <sc.LoadingCarousel isLoading strains={[]} title="" />
          <sc.LoadingCarousel isLoading strains={[]} title="" />
          <sc.LoadingCarousel isLoading strains={[]} title="" />
          <sc.LoadingCarousel isLoading strains={[]} title="" />
          <sc.LoadingCarousel isLoading strains={[]} title="" />
          <sc.LoadingCarousel isLoading strains={[]} title="" />
          <sc.LoadingCarousel isLoading strains={[]} title="" />
          <sc.LoadingCarousel isLoading strains={[]} title="" />
        </SC.PodsCarouselContainer>
      </SC.PodsHomeContentContainer>
    </sc.ExplorePage>
  );
};

const sc = {
  ExplorePage: styled(SC.Page)`
    height: calc(
      100vh - var(--location-header-height) - var(--mobile-nav-bar-height)
    );
    overflow: hidden;
    width: 100vw;

    ${desktopBreakpoint(
      css`
        overflow: visible;
        padding: 0 16px;
        position: relative;
      `
    )}
  `,

  LoadingCarousel: styled(PodsCarousel)`
    margin-bottom: 20px;

    & > div:first-child {
      padding-top: 20px;
    }

    & > div:nth-child(2) {
      overflow-x: inherit;
    }

    ${desktopBreakpoint(
      css`
        margin-bottom: 32px;
      `
    )}
  `,
};
