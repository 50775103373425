import { connect } from "react-redux";

import { MainAppState } from "../../main/types";

import { sendEmailVerificationRequest } from "../actions";
import { SsoEmailVerificationRequestSentPage } from "../components/SsoEmailVerificationRequestSentPage";

const mapStateToProps = (state: MainAppState) => {
  return {
    ...state.sso.sendEmailVerificationRequest,
  };
};

const mapDispatchToProps = {
  sendEmailVerificationRequest,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SsoEmailVerificationRequestSentPage);
