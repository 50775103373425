import React from "react";
import styled from "styled-components";

import * as routes from "../../main/routes";
import Button from "../../shared/components/Button";
import { Row } from "../../shared/components/Flex";
import * as Text from "../../shared/components/Text";
import * as text from "../../shared/text";
import { SsoLink } from "../../sso/components/SsoLink";

type SignInHeaderProps = {
  returnTo: string;
};

export const SignInHeader: React.FC<SignInHeaderProps> = ({ returnTo }) => {
  const signUpButton = <Button data-testid="sign-up">{text.SIGN_UP}</Button>;
  const signInButton = <Button data-testid="sign-in">{text.SIGN_IN}</Button>;

  return (
    <sc.SignInContainer>
      <sc.Text>{text.CREATE_ACCOUNT_OR_SIGN_IN_TO_MANAGE}</sc.Text>
      <sc.ButtonRow>
        <SsoLink
          button={signUpButton}
          returnTo={returnTo}
          ssoRouteTo={routes.ssoCreateAccount}
        />
        <SsoLink
          button={signInButton}
          returnTo={returnTo}
          ssoRouteTo={routes.ssoLogin}
        />
      </sc.ButtonRow>
    </sc.SignInContainer>
  );
};

const sc = {
  ButtonRow: styled(Row)`
    align-items: center;
    justify-content: center;

    & a:first-child {
      margin-right: 32px;
    }
  `,

  SignInContainer: styled.div`
    border: solid 1px var(--very-light-pink-4);
    border-radius: 8px;
    margin-top: 16px;
    padding: 16px;
  `,

  Text: styled.div`
    ${Text.PlaaxLight}
    font-size: 16px;
    line-height: 1.13;
    margin: 0 auto 16px;
    max-width: 660px;
    min-height: 41px;
    text-align: center;
  `,
};
