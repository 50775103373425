// Spec:
// https://github.com/PaxLabs/pax-logging/blob/develop/schemas/events/app/search/search_0.json

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ORDER_BY_APP_DEFAULT } from "../../api/consumer/constants";
import { getUserLocationCode } from "../../api/consumer/selectors/user";
import { StrainJson } from "../../api/consumer/types";
import * as strainTypes from "../../api/consumer/types/strain";
import * as pods from "../../pods/constants";
import { useRecommendedSearches } from "../../pods/hooks";
import { StrainsFilter } from "../../pods/types";
import { AppThunk } from "../../shared/types";

import { trackEventAction } from "../actions";

export enum SortedBy {
  ALPHABETIC_BRAND = "alphabetic_brand",
  ALPHABETIC_STRAIN = "alphabetic_strain",
  AVERAGE_RATING = "average_rating",
  LANDING = "landing",
  NEWEST = "newest",
  RANDOM = "random",
  RELEVANCE = "relevance",
}

const EVENT_NAME = "search";

type SearchEventProperties = {
  action: "load" | "click" | "load_no_results";
  isFeaturedStatePod?: boolean;
  isRecentQuery?: boolean;
  isRecentlyViewed?: boolean;
  isRecommendedForYou?: boolean;
  isSuggestedQuery?: boolean;
  isoLocation?: string;
  loadedStrainIds?: string[];
  query?: string | null;
  sortedBy: SortedBy;
};

type SearchEventFilterProperties = {
  filterBrandId?: string;
  filterCbd: boolean;
  filterEffectCelebrate: boolean;
  filterEffectChill: boolean;
  filterEffectCreate: boolean;
  filterEffectHustle: boolean;
  filterEffectRelief: boolean;
  filterEffectRest: boolean;
  filterHybrid: boolean;
  filterIndica: boolean;
  filterPotencyMild: boolean;
  filterPotencyModerate: boolean;
  filterPotencyStrong: boolean;
  filterPotencyVeryStrong: boolean;
  filterSativa: boolean;
};

type PodClickedProperties = {
  clickedBrandId: string;
  clickedPotency: string;
  clickedStrainId: string;
  clickedStrainType: string;
};

type FilteredSearchEventProperties = SearchEventProperties &
  SearchEventFilterProperties;

export type PodCardClickedEventProperties = PodClickedProperties &
  FilteredSearchEventProperties & {
    clickedOffset: number;
    clickedSelectionCount?: number;
    isRecentlyViewed?: boolean;
  };

type TopPodCardClickedEventProperties = SearchEventProperties &
  PodClickedProperties & {
    clickedOffset: -1;
    isFeaturedStatePod: true;
    isoLocation: string;
  };

type ViewAllCardClickedEventProperties = FilteredSearchEventProperties & {
  clickedIsViewAll: true;
};

const getSearchEventFilterProperties = (
  filterPartial: Partial<StrainsFilter>
): SearchEventFilterProperties => ({
  filterBrandId: filterPartial.partnerId,
  filterCbd: !!filterPartial.strainClassifications?.includes(pods.CBD),
  filterEffectCelebrate: !!filterPartial.desiredEffects?.includes(
    pods.CELEBRATE
  ),
  filterEffectChill: !!filterPartial.desiredEffects?.includes(pods.CHILL),
  filterEffectCreate: !!filterPartial.desiredEffects?.includes(pods.CREATE),
  filterEffectHustle: !!filterPartial.desiredEffects?.includes(pods.HUSTLE),
  filterEffectRelief: !!filterPartial.desiredEffects?.includes(pods.RELIEF),
  filterEffectRest: !!filterPartial.desiredEffects?.includes(pods.REST),
  filterHybrid: !!filterPartial.strainClassifications?.includes(pods.HYBRID),
  filterIndica: !!filterPartial.strainClassifications?.includes(pods.INDICA),
  filterPotencyMild: !!filterPartial.potency?.includes(
    strainTypes.Potency.MILD
  ),
  filterPotencyModerate: !!filterPartial.potency?.includes(
    strainTypes.Potency.MODERATE
  ),
  filterPotencyStrong: !!filterPartial.potency?.includes(
    strainTypes.Potency.STRONG
  ),
  filterPotencyVeryStrong: !!filterPartial.potency?.includes(
    strainTypes.Potency.VERY_STRONG
  ),
  filterSativa: !!filterPartial.strainClassifications?.includes(pods.SATIVA),
});

const getSearchEventStrainProperties = (
  strain: StrainJson
): PodClickedProperties => ({
  clickedBrandId: strain.partner.id,
  clickedPotency: strain.potency.toLocaleLowerCase(),
  clickedStrainId: strain.id,
  clickedStrainType: strain.classification.toLocaleLowerCase(),
});

export const usePodsSearchAnalytics = (
  isFetchingPods: boolean,
  strainRows: StrainJson[][],
  strainsFilter: StrainsFilter
): void => {
  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useState<string | undefined>(
    strainsFilter.query
  );
  const [shouldTrackSearch, setShouldTrackSearch] = useState<boolean>(false);
  const { isSuggestedQueries, recommendedSearches } = useRecommendedSearches();

  const isoLocation = useSelector(getUserLocationCode);

  // This useEffect and these state variables are used to prevent a tracking call from firing on the initial load of the page
  // before pods have started to be fetched. shouldTrackSearch only flips to true after isFetchingPods has flipped to true.
  // Once isFetchingPods has flipped back to false, the tracking call will happen and shouldTrackSearch will flip back to false.
  useEffect(() => {
    if (shouldTrackSearch || !isFetchingPods) return;

    setShouldTrackSearch(true);
  }, [shouldTrackSearch, isFetchingPods]);

  useEffect(() => {
    if (strainsFilter.query === searchQuery) return;

    setSearchQuery(strainsFilter.query);
    // eslint-disable-next-line
  }, [strainsFilter]);

  useEffect(() => {
    if (!shouldTrackSearch || isFetchingPods) return;

    const action = strainRows.length === 0 ? "load_no_results" : "load";

    dispatch(
      trackFilteredSearch(strainsFilter, action, {
        isRecentQuery:
          !isSuggestedQueries &&
          (searchQuery ? recommendedSearches.includes(searchQuery) : false),
        isSuggestedQuery:
          isSuggestedQueries &&
          (searchQuery ? recommendedSearches.includes(searchQuery) : false),
        isoLocation,
      })
    );
    setShouldTrackSearch(false);

    // Only track search once when pods have been fetched and shouldTrackSearch is true.
    // eslint-disable-next-line
  }, [isFetchingPods, shouldTrackSearch]);
};

const SORTED_BY_MAP: {
  [key in strainTypes.OrderBy]: SortedBy;
} = {
  [strainTypes.OrderBy.ALPHABETIC]: SortedBy.ALPHABETIC_STRAIN,
  [strainTypes.OrderBy.AVERAGE_RATING]: SortedBy.AVERAGE_RATING,
  [strainTypes.OrderBy.DEFAULT]: SortedBy.ALPHABETIC_BRAND,
  [strainTypes.OrderBy.NEWEST]: SortedBy.NEWEST,
  [strainTypes.OrderBy.RANDOM]: SortedBy.RANDOM,
  [strainTypes.OrderBy.RELEVANCE]: SortedBy.RELEVANCE,
};

const trackFilteredSearch =
  (
    filter: Partial<StrainsFilter>,
    action: "load" | "load_no_results",
    options?: Partial<SearchEventProperties>
  ): AppThunk =>
  (dispatch, getState): void => {
    trackEventAction<FilteredSearchEventProperties>(EVENT_NAME, {
      ...getSearchEventFilterProperties(filter),
      action,
      query: filter.query || null,
      sortedBy: filter.orderBy
        ? SORTED_BY_MAP[filter.orderBy]
        : SORTED_BY_MAP[ORDER_BY_APP_DEFAULT],
      ...options,
    })(dispatch, getState, null);
  };

type TrackFilteredSearchResultClickedOptions = {
  clickedOffset: number;
  clickedSelectionCount?: number;
  isRecentlyViewed?: boolean;
  sortedBy?: SortedBy;
};

export const trackFilteredSearchResultClicked =
  (
    filter: Partial<StrainsFilter>,
    strain: StrainJson,
    {
      clickedOffset,
      clickedSelectionCount,
      isRecentlyViewed,
      sortedBy,
    }: TrackFilteredSearchResultClickedOptions
  ): AppThunk =>
  (dispatch, getState): void => {
    trackEventAction<PodCardClickedEventProperties>(EVENT_NAME, {
      ...getSearchEventFilterProperties(filter),
      ...getSearchEventStrainProperties(strain),
      action: "click",
      clickedOffset,
      clickedSelectionCount,
      isRecentlyViewed,
      query: filter.query || null,
      sortedBy: sortedBy
        ? sortedBy
        : filter.orderBy
        ? SORTED_BY_MAP[filter.orderBy]
        : SORTED_BY_MAP[ORDER_BY_APP_DEFAULT],
    })(dispatch, getState, null);
  };

export const trackViewAllClicked =
  (filter: Partial<StrainsFilter>): AppThunk =>
  (dispatch, getState): void => {
    trackEventAction<ViewAllCardClickedEventProperties>(EVENT_NAME, {
      ...getSearchEventFilterProperties(filter),
      action: "click",
      clickedIsViewAll: true,
      sortedBy: SortedBy.LANDING,
    })(dispatch, getState, null);
  };

export const trackPodsLoaded =
  (
    loadedStrainIds: string[],
    options?: Partial<SearchEventProperties>
  ): AppThunk =>
  (dispatch, getState): void => {
    trackEventAction<SearchEventProperties>(EVENT_NAME, {
      action: loadedStrainIds.length === 0 ? "load_no_results" : "load",
      loadedStrainIds,
      sortedBy: SortedBy.LANDING,
      ...options,
    })(dispatch, getState, null);
  };

export const trackTopPodClicked =
  (strain: StrainJson): AppThunk =>
  (dispatch, getState): void => {
    trackEventAction<TopPodCardClickedEventProperties>(EVENT_NAME, {
      ...getSearchEventStrainProperties(strain),
      action: "click",
      clickedOffset: -1,
      isFeaturedStatePod: true,
      isoLocation: strain.geoState,
      sortedBy: SortedBy.LANDING,
    })(dispatch, getState, null);
  };
