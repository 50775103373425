import React from "react";
import styled, { css } from "styled-components";

import * as Strain from "../../../../api/consumer/models/Strain";
import * as t from "../../../../api/consumer/types";
import * as Text from "../../../../shared/components/Text";
import * as text from "../../../../shared/text";
import { StrainColor } from "../../../../shared/types";
import * as utils from "../../../../shared/utils";

import { PodSectionTitleStyles } from "../../../styledComponents";

import { PodStrengthBar } from "./PodStrengthBar";

type BasicStrengthProps = {
  isModule: boolean;
  potency: string;
  strain: t.StrainJson;
  strainColor: StrainColor;
};

export const BasicStrength: React.FC<BasicStrengthProps> = ({
  isModule,
  potency,
  strain,
  strainColor,
}) => {
  const thcRange = Strain.getThcPercentageRange(strain);
  const thcPercentage = Strain.getIngredientPercentageOrRangeString(
    strain,
    t.StrainIngredient.THC
  );
  const cbdPercentage = Strain.getIngredientPercentageOrRangeString(
    strain,
    t.StrainIngredient.CBD
  );

  return (
    <>
      <sc.Heading isModule={isModule}>{text.STRENGTH}</sc.Heading>
      <sc.PotencyText>
        {`${potency} • ${thcPercentage} ${text.THC} ${cbdPercentage} ${text.CBD}`}
      </sc.PotencyText>
      <PodStrengthBar
        isModule={isModule}
        strainColor={strainColor}
        strengthRange={thcRange}
      />
    </>
  );
};

type HeadingProps = {
  isModule: boolean;
};

const sc = {
  Heading: styled.div<HeadingProps>`
    ${PodSectionTitleStyles}
    font-size: ${({ isModule }) => (isModule ? "14px" : "16px")};
    line-height: ${({ isModule }) => (isModule ? "16px" : "22px")};
    margin: ${({ isModule }) => (isModule ? "20px 0 8px" : "0 0 8px")};
    text-transform: ${({ isModule }) => (isModule ? "none" : "uppercase")};

    ${utils.desktopBreakpoint(
      css`
        margin-top: 0;
      `
    )}
  `,

  PotencyText: styled.div`
    ${Text.MediumLLLight}
    color: var(--black);
    font-size: 14px;
    letter-spacing: -0.11px;
    line-height: 1.29;
    margin: 4px 0 12px;
  `,
};
