import * as t from "../types";

type ConnectedPodValues = {
  latestReview: t.reviews.StrainReviewJson;
  strain: t.StrainJson;
} & Partial<t.connectedPods.ConnectedPodJson>;

export const getConnectedPodJson = (
  values: ConnectedPodValues
): t.connectedPods.ConnectedPodJson => ({
  earliestClientTimestamp: new Date().toISOString(),
  isLow: false,
  latestClientTimestamp: new Date().toISOString(),
  podSerialNumbers: [],
  wasScanned: false,
  ...values,
});
