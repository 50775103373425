import React, { lazy, LazyExoticComponent } from "react";

import { ModalProps } from "../modal/components/Modal";

export const RESET_ANNOUNCEMENTS_STATE = "RESET_ANNOUNCEMENTS_STATE";
export const SET_VIEWED_STATUS = "SET_VIEWED_STATUS";

// Feature announcement codes are maintained here:
// https://paxlabs.atlassian.net/wiki/spaces/PMA/pages/1655373942/Feature+Announcement+Codes
export enum FEATURE_ANNOUNCEMENT_CODE {
  EMAIL_COLLECTION = "email-collection",
  FIND_MY_PAX = "find-my-pax",
  LOW_POD_ALERTS = "low-pod-alerts",
}

export const FeatureAnnouncementModalMap: {
  [key in FEATURE_ANNOUNCEMENT_CODE]: LazyExoticComponent<React.FC<ModalProps>>;
} = {
  [FEATURE_ANNOUNCEMENT_CODE.EMAIL_COLLECTION]: lazy(
    () => import("../mailing-list/containers/EmailCollectionModal")
  ),
  [FEATURE_ANNOUNCEMENT_CODE.FIND_MY_PAX]: lazy(
    () => import("./components/FindMyPaxAnnouncementModal")
  ),
  [FEATURE_ANNOUNCEMENT_CODE.LOW_POD_ALERTS]: lazy(
    () => import("./components/LowPodAlertsModal")
  ),
};
