import React from "react";
import styled, { css } from "styled-components";

import { PartnerJson } from "../../api/consumer/types/partner";

import { WhiteOil } from "./Icons";

type PartnerLogoProps = {
  children?: React.ReactNode;
  onClick?: () => void;
  partner?: PartnerJson;
};

export const PartnerLogo: React.FC<PartnerLogoProps> = ({
  children,
  onClick,
  partner,
  ...props
}) => {
  if (!partner?.logo?.downloadUrl) {
    return (
      <sc.Container {...props}>
        <sc.PartnerLogoPlaceholder
          data-testid="partner-logo-placeholder"
          onClick={onClick}
        >
          <sc.OilIcon />
        </sc.PartnerLogoPlaceholder>
        {children}
      </sc.Container>
    );
  }

  return (
    <sc.Container {...props}>
      <sc.Logo
        src={partner.logo.downloadUrl}
        alt={`${partner.partnerName} Logo`}
        data-testid="partner-logo"
        onClick={onClick}
      />
      {children}
    </sc.Container>
  );
};

const hoverStyles = css`
  &:hover {
    border: solid 1px var(--black);
    cursor: pointer;
  }
`;

type LogoProps = {
  onClick?: () => unknown;
};

const sc = {
  Container: styled.div`
    position: relative;
  `,

  Logo: styled.img<LogoProps>`
    background-color: var(--white);
    border: solid 1px var(--very-light-pink);
    border-radius: 100%;
    max-height: 100%;
    max-width: 100%;
    min-height: 100%;
    min-width: 100%;
    object-fit: cover;

    ${({ onClick }) => (onClick ? hoverStyles : "")}
  `,

  OilIcon: styled(WhiteOil)`
    height: 40%;
    width: 40%;

    & path:first-child {
      stroke: var(--very-light-pink);
      stroke-width: 2px;
    }
  `,

  PartnerLogoPlaceholder: styled.span<LogoProps>`
    align-items: center;
    background: var(--white);
    border: solid 1px var(--very-light-pink);
    border-radius: 100px;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;

    ${({ onClick }) => (onClick ? hoverStyles : "")}
  `,
};
