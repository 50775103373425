import { connect } from "react-redux";

import { MainAppState } from "../../main/types";
import { closeFirmwareModal } from "../../modal/actions";
import { getIsFirmwareModalOpen } from "../../modal/selectors";
import { showFlashBanner } from "../../shared/components/FlashBanner/actions";

import * as firmwareModal from "../components/FirmwareModal";

const mapStateToProps = (state: MainAppState): firmwareModal.StateProps => ({
  isFirmwareModalOpen: getIsFirmwareModalOpen(state),
});

const mapDispatchToProps: firmwareModal.DispatchProps = {
  closeFirmwareModal,
  showFlashBanner,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(firmwareModal.FirmwareModal);
