import { connect } from "react-redux";

import * as s from "../../../device/selectors";
import * as t from "../../../main/types";

import {
  FlowerThermostatCard,
  FlowerThermostatCardProps,
} from "../../components/Thermostat/FlowerThermostatCard";

const mapStateToProps = (state: t.MainAppState): FlowerThermostatCardProps => ({
  deviceTemperature: s.getDeviceTemperature(state),
  isDeviceLocked: s.getIsDeviceLocked(state),
  temperatureRange: s.getTemperatureRange(state),
  temperatureUnit: s.getPreferredTemperatureUnit(state),
});

export default connect(mapStateToProps)(FlowerThermostatCard);
