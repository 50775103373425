import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";

import { getUserLocation } from "../../api/consumer/selectors/user";
import { MainAppState } from "../../main/types";

import * as actions from "../actions";
import * as podsSearchPage from "../components/PodsSearchPage";
import * as selectors from "../selectors";

const mapStateToProps = (
  state: MainAppState,
  props: RouteComponentProps
): podsSearchPage.StateProps => {
  return {
    isFetchingPods: selectors.getIsFetchingPods(state),
    nextFetchUrl: selectors.getNextFetchUrl(state),
    strainsFilter: selectors.getStrainsFilterFromSearchParams(state, props),
    userLocation: getUserLocation(state),
  };
};

const mapDispatchToProps: podsSearchPage.DispatchProps = {
  fetchStrains: actions.fetchStrains,
  fetchStrainsFromUrl: actions.fetchStrainsFromUrl,
  setStrainsFilterAndRedirect: actions.setStrainsFilterAndRedirect,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(podsSearchPage.PodsSearchPage);
