import React, { lazy } from "react";
import { Redirect, Route, RouteComponentProps, Switch } from "react-router-dom";

import { ConnectDevicePage } from "../device/containers/ConnectDevicePage";
import EraDevicePage from "../era/containers/EraDevicePage";
import EraProDevicePage from "../era-pro/containers/EraProDevicePage";
import { ExperienceModesPage } from "../experience-modes/components/ExperienceModesPage";
import { isDevPageEnabled, isExperienceModesEnabled } from "../featureFlags";
import Pax3DevicePage from "../pax-3/containers/Pax3DevicePage";

const DevPage = lazy(() => import("../dev/containers/DevPage"));

export const DeviceRoutes: React.FC<RouteComponentProps> = () => (
  <Switch>
    <Route exact path="/device" component={ConnectDevicePage} />
    <Route exact path="/device/era" component={EraDevicePage} />
    <Route exact path="/device/pax-3" component={Pax3DevicePage} />
    <Route exact path="/device/era-pro" component={EraProDevicePage} />
    {isExperienceModesEnabled && (
      <Route
        exact
        path="/device/experience-modes"
        component={ExperienceModesPage}
      />
    )}
    {isDevPageEnabled && (
      <Route exact path="/device/_dev" component={DevPage} />
    )}
    <Redirect from="*" to="/device" />
  </Switch>
);
