import styled, { css } from "styled-components";

// Should match fallback fonts in app/styles/index.css.
const fallbackFonts = `-apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif`;

type LinkProps = {
  underline?: boolean;
};

export const Link = styled.a<LinkProps>`
  color: var(--black);
  text-decoration: ${({ underline }) => (underline ? "underline" : "none")};
`;

// TODO remove
export const H3 = styled.h3`
  font-size: 20px;
  font-weight: 500;
  line-height: 1;
  margin: 0 0 8px;
  text-transform: uppercase;
`;

// TODO remove
export const P = styled.p`
  font-size: 14px;
  line-height: 1.43;
  margin: 0 0 8px;
`;

// TODO remove
export const Bold = styled.span`
  font-weight: 500;
`;

/* MediumLL */

export const MediumLL = css`
  font-family: MediumLL, sans-serif;
  font-weight: normal;
`;

export const MediumLLLight = css`
  font-family: MediumLL, sans-serif;
  font-weight: 300;
`;
export const MediumLLMedium = css`
  font-family: MediumLL, sans-serif;
  font-weight: 500;
`;

export const MediumLLBold = css`
  font-family: MediumLL, sans-serif;
  font-weight: 700;
`;

/* Plaax */

export const Plaax = css`
  font-family: "Plaax", ${fallbackFonts};
  font-weight: normal;
`;

export const PlaaxLight = css`
  font-family: "Plaax", ${fallbackFonts};
  font-weight: 300;
`;

export const PlaaxMedium = css`
  font-family: "Plaax", ${fallbackFonts};
  font-weight: 500;
`;

export const PlaaxBold = css`
  font-family: "Plaax", ${fallbackFonts};
  font-weight: 700;
`;

export const PlaaxItalic = css`
  font-family: "Plaax", ${fallbackFonts};
  font-style: italic;
`;

export const PlaaxItalicLight = css`
  font-family: "Plaax", ${fallbackFonts};
  font-style: italic;
  font-weight: 300;
`;

export const Underline = css`
  text-decoration: underline;
`;

export const Error = css`
  color: var(--pale-red);
  font-style: italic;
`;

export const ErrorSpan = styled.span`
  ${Error}
`;
