import * as PaxBle from "../../pax-ble";

import { getConnectedDevice } from "../bluetooth/connectedDevice";

const getEraProDevice = (): PaxBle.EraProDevice | undefined =>
  getConnectedDevice() as PaxBle.EraProDevice;

export type StartEraProSession = (doseId: number) => void;

export const startEraProSession: StartEraProSession =
  (doseId, eraProDevice = getEraProDevice()) =>
  (): void => {
    if (!eraProDevice) return;
    eraProDevice.startSession(doseId);

    eraProDevice.readSessionControl();
  };

export type StopEraProSession = () => void;

export const stopEraProSession: StopEraProSession =
  (eraProDevice = getEraProDevice()) =>
  (): void => {
    if (!eraProDevice) return;
    eraProDevice.stopSession();
  };
