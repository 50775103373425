import axios from "axios";
import * as reactQuery from "react-query";

import { getAuthenticationToken } from "../../api/consumer/selectors/user";

import { fetch } from "./fetch";
import { CancellablePromise } from "./types";

export const defaultQueryFn: reactQuery.QueryFunction<
  unknown,
  reactQuery.QueryKey
> = async ({ queryKey }) => {
  const token = getAuthenticationToken();
  const cancelSource = axios.CancelToken.source();

  if (!token) {
    throw new Error("No authentication token.");
  }
  const path = queryKey[0] as string;
  const promise: CancellablePromise<unknown> = fetch<unknown>(path, {
    cancelToken: cancelSource.token,
  });

  promise.cancel = () => {
    cancelSource.cancel("Query was cancelled by React Query");
  };

  return await promise;
};

export const defaultQueryKeyHashFn: reactQuery.QueryKeyHashFunction<reactQuery.QueryKey> =
  (queryKey) => {
    const token = getAuthenticationToken();
    const newQueryKey: unknown[] =
      typeof queryKey === "string" ? [queryKey, token] : [...queryKey, token];

    return reactQuery.hashQueryKey(newQueryKey);
  };
