import { Action } from "../shared/types";
import * as c from "./constants";
import * as t from "./types";

export const getInitialState = (): t.FindMyPaxState => ({
  isRequestingLocation: false,
});

export const reducer = (
  state: t.FindMyPaxState = getInitialState(),
  action: Action
): t.FindMyPaxState => {
  const { type } = action;

  switch (type) {
    case c.REQUEST_LOCATION_START:
      return {
        ...state,
        isRequestingLocation: true,
      };

    case c.REQUEST_LOCATION_DONE:
      return {
        ...state,
        isRequestingLocation: false,
      };

    default:
      return state;
  }
};
