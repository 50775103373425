import React from "react";
import { Redirect, RouteComponentProps } from "react-router-dom";

import { podDetails } from "../../main/routes";

export const PodQRCodeRedirect: React.FC<
  RouteComponentProps<{
    strainId: string;
  }>
> = (props) => {
  const { strainId } = props.match.params;

  return (
    <Redirect
      to={{ pathname: podDetails(strainId), state: { wasQRCodeScanned: true } }}
    />
  );
};
