import * as apiTypes from "../api/consumer/types";
import { OrderBy, Potency } from "../api/consumer/types/strain";

export type GeoState = {
  geoCode: string;
  name: string;
};

export type OnPodSearchQuerySet = (query: string) => void;

export type OnStrainsFilterSet = (filter: Partial<StrainsFilter>) => void;

export type PodData = {
  autoBatchId: string | null;
  autoTestReport: apiTypes.ParsedTestResultJson | null;
  hasTestReport: boolean;
  isAutoId: boolean;
  strainId: string | null;
};

export type PodsState = {
  clickedPodCount: number;
  filter: StrainsFilter;
  isFetching: boolean;
  isUserLocationUndetermined: boolean;
  nextFetchUrl: string | null;
  strains: apiTypes.StrainJson[];
};

export type PodColumnCount = 0 | 1 | 2 | 3 | 4;

type PodRowWidthMobile = 164 | 340 | 516 | 692;
type PodRowWidthDesktop = 286 | 584 | 882;
export type PodRowWidth = PodRowWidthMobile | PodRowWidthDesktop;

export type StrainsFilter = {
  desiredEffects: string[];
  flavors: string[];
  minAvgRating?: string;
  orderBy?: OrderBy;
  partnerId?: string;
  potency: Potency[];
  query?: string;
  region?: string;
  selectedEffects: string[];
  state?: string;
  strainClassifications: string[];
};

export enum PodType {
  D3 = "D3",
  D3N = "D3N",
}

export enum ResponsiveScreenSize {
  SMALL = "SMALL",
  MEDIUM = "MEDIUM",
  LARGE = "LARGE",
  X_LARGE = "X_LARGE",
  XX_LARGE = "XX_LARGE",
  DESKTOP_SMALL = "DESKTOP_SMALL",
  DESKTOP_MEDIUM = "DESKTOP_MEDIUM",
  DESKTOP_LARGE = "DESKTOP_LARGE",
}

// Should match linearGradient IDs in star.svg
export enum StarFillGradientId {
  EMPTY = "star-empty",
  FILLED = "star-filled",
  GRADIENT_1 = "star-filled-gradient-1",
  GRADIENT_2 = "star-filled-gradient-2",
  GRADIENT_3 = "star-filled-gradient-3",
}

export enum StarFillLevel {
  GRADIENT_1 = 0.25,
  GRADIENT_2 = 0.5,
  GRADIENT_3 = 0.75,
}

export type PodSearchPageRouterLocationProps = {
  showRecentSearches?: boolean;
};
