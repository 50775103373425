import { get as lsGet } from "local-storage";
import { useEffect, useRef, useState } from "react";

import { EraReplayAction, EraReplayJson } from "../../pax-ble/types/Era";

import { LS_KEY_LOCKOUT_TIMEOUT_START } from "../device/constants";
import * as deviceUtils from "../device/utils";
import { StartEraSession } from "./actions";
import { ERA_SESSION_LOCKOUT_TIME } from "./constants";

const usePreviousSessionStatus = (lockoutLength: number): boolean => {
  const lockoutStartTime = useRef<number>();
  useEffect(() => {
    lockoutStartTime.current = lsGet<number>(LS_KEY_LOCKOUT_TIMEOUT_START);
  }, []);

  const timeDelta =
    lockoutStartTime.current &&
    lockoutStartTime.current + lockoutLength * 1000 - Date.now();

  return timeDelta != undefined && timeDelta > 0;
};

export const useSessionControlStatus = (
  heaterSetPoint: number,
  onStartEraSession: StartEraSession,
  replay?: EraReplayJson
): { isPreviousLockoutActive: boolean; isSessionComplete: boolean } => {
  const isPreviousLockoutActive = usePreviousSessionStatus(
    ERA_SESSION_LOCKOUT_TIME
  );
  const [isSessionComplete, setIsSessionComplete] = useState<boolean>(false);

  useEffect(() => {
    if (!replay) return;

    if (!replay.doseId) {
      setIsSessionComplete(false);
      return;
    }
    if (replay.action === EraReplayAction.FINISHED) {
      const startTime = Date.now();
      deviceUtils.setDeviceLockoutStarted(startTime, ERA_SESSION_LOCKOUT_TIME);
      setIsSessionComplete(true);
      return;
    }
    if (replay.action === EraReplayAction.LOCKOUT_EXPIRED) {
      deviceUtils.removeDeviceLockoutStarted();
      setIsSessionComplete(false);
      onStartEraSession(replay.doseId, heaterSetPoint);
      return;
    }
  }, [heaterSetPoint, onStartEraSession, replay]);

  return { isPreviousLockoutActive, isSessionComplete };
};
