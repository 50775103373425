import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { InputActionMeta } from "react-select";

import { receivedNewRecentSearch } from "../../api/consumer/actions/users";
import * as r from "../../main/routes";
import { QueryFieldDropdown } from "../../shared/components/QueryFieldDropdown";
import { DropdownOption } from "../../shared/types";

import { buildStrainsFilter } from "../models/StrainsFilter";

export const PodsHomeQueryField: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [newPendingQuery, setNewPendingQuery] = useState<string>("");
  const [selectedQuery, setSelectedQuery] = useState<
    DropdownOption | undefined
  >(undefined);

  const handleChange = (option: DropdownOption | null): void => {
    if (!option) return;

    setSelectedQuery(option);
    dispatch(receivedNewRecentSearch(option.value));

    history.push(r.podsSearch(buildStrainsFilter({ query: option.value })));
  };

  const handleInputChange = (
    value: string,
    { action }: InputActionMeta
  ): void => {
    if (action === "input-change" || value) setNewPendingQuery(value);
  };

  const handleKeyDown = (e: React.KeyboardEvent): void => {
    if (e.key === "Enter" && newPendingQuery) {
      e.preventDefault();
      dispatch(receivedNewRecentSearch(newPendingQuery));

      history.push(
        r.podsSearch(buildStrainsFilter({ query: newPendingQuery }))
      );
    }
  };

  return (
    <QueryFieldDropdown
      data-testid="home-page-query-field-dropdown"
      inputValue={newPendingQuery}
      onChange={handleChange}
      onInputChange={handleInputChange}
      onKeyDown={handleKeyDown}
      shouldShowDropdown
      value={selectedQuery}
    />
  );
};
