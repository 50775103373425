import { StrainsFilter } from "../../../pods/types";

import * as t from "./index";
import { PartnerJson } from "./partner";

export type CannabisCompoundJson = {
  fieldName: string;
  fieldValue: number;
  chemicalType: string;
};

export type FetchStrainOptions = {
  filter: StrainsFilter;
  orderBy?: OrderBy;
  page: number;
  pageSize?: number;
};

export type ParsedTestResultJson = {
  parsedCannabinoids: CannabisCompoundJson[];
  parsedTerpenes: CannabisCompoundJson[];
  parsedTestResults: SafetyTestResultJson[];
  parserType: string;
  testDate: string;
  testLab: string;
  totalCannabinoidPercent: number;
  totalCbdPercent: number;
  totalThcPercent: number;
};

export type Range = {
  min: number;
  max: number;
};

export type SafetyTestResultJson = {
  hasPassed: boolean;
  testName: string;
};

export type EffectJson = {
  effect: string;
  effectReviews: number;
  percentEffect: number;
  wasBpSelected: boolean;
};

export type StrainJson = {
  averageRating: number;
  bestFlavorTemperature: number;
  cbd: number;
  cbdRange: Range;
  classification: string;
  description: string;
  desiredEffects: string[];
  effectData: EffectJson[];
  extraction: string;
  geoState: string;
  highTerpene: boolean;
  id: string;
  isFavorited?: boolean;
  links: t.LinkJson[];
  logos: t.LogoJson[];
  maxTemperature: number;
  maxVaporTemperature: number;
  msrp: number;
  name: string;
  numEffectReviews: number;
  numRatings: number;
  partner: PartnerJson;
  photos: t.PhotoJson[];
  potency: Potency;
  potencyLevel: string;
  recommendedTemperature: number;
  spectrumScore: number;
  terpene: number;
  terpeneRange: Range;
  thc: number;
  thcRange: Range;
  videos: t.VideoJson[];
  viscosity: number;
};

export type TestResultJson = {
  batchId: string;
  downloadUrl: string;
  downloadUrls: string[];
  id: string;
  parsedBatchResult?: ParsedTestResultJson;
  strainId: string;
};

export type StrainMetaDataJson = {
  popularPodsNearbyAvailable?: boolean;
  recommendationId?: string;
  recommendationSource?: StrainMetaDataRecommendationSource;
};

export enum Flavors {
  BLUEBERRY = "BLUEBERRY",
  CITRUS = "CITRUS",
  CREAMY = "CREAMY",
  EARTHY = "EARTHY",
  FLORAL = "FLORAL",
  FRUITY = "FRUITY",
  LAVENDER = "LAVENDER",
  LEMON_PEEL = "LEMON_PEEL",
  ORANGE = "ORANGE",
  PINE = "PINE",
  PINEAPPLE = "PINEAPPLE",
  SPICY = "SPICY",
  SUGAR = "SUGAR",
  TANGY = "TANGY",
  TROPICAL = "TROPICAL",
}

export enum PAX_STRAIN {
  HAZE_BLUE_DREAM = "K3L6",
  HAZE_BLUEBERRY_OG = "D3L8",
  HAZE_CALIFORNIA_ORANGE = "h3LP",
  DIAMONDS_DURBAN_POISON = "zaJ4",
  DIAMONDS_GRANDDADDY_PURPLE = "DaJn",
  HAZE_GUAVA_GELATO = "z3Lp",
  HAZE_JACK_HERER = "D3LU",
  HAZE_LAVENDER_HAZE = "D3Ly",
  HAZE_LEMON_CAKE = "h3LY",
  HAZE_MOCHI_COOKIES = "h3Lo",
  DIAMONDS_OG_KUSH = "haJe",
  HAZE_PINEAPPLE_EXPRESS = "K3LA",
  DIAMONDS_STRAWBERRY_COUGH = "KaJg",
  DIAMONDS_SUNSET_SHERBET = "DaJL",
  HAZE_TAHOE_ROSE = "h3LR",
  DIAMONDS_WATERMELON_Z = "haJI",
  DIAMONDS_WEDDING_CAKE = "KaJQ",
  HAZE_WIFI_MINTS = "z3Lm",
}

export enum OrderBy {
  ALPHABETIC = "ALPHABETIC",
  AVERAGE_RATING = "AVERAGE_RATING",
  DEFAULT = "DEFAULT",
  NEWEST = "NEWEST",
  RANDOM = "RANDOM",
  RELEVANCE = "RELEVANCE",
}

export enum Potency {
  MILD = "MILD",
  MODERATE = "MODERATE",
  STRONG = "STRONG",
  VERY_STRONG = "VERY_STRONG",
}

export enum StrainMetaDataRecommendationSource {
  POPULAR = "POPULAR",
  YOUR_LOCATION = "YOUR_LOCATION",
  YOUR_PODS = "YOUR_PODS",
}

export enum StrainIngredient {
  CBD = "cbd",
  TERPENE = "terpene",
  THC = "thc",
}
