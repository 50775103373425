import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { DeviceConnectionFlows } from "../../connection-modal/constants";
import RecentDevices from "../../connection-modal/containers/RecentDevices";
import { useDeviceConnectionModal } from "../../connection-modal/hooks";
import { getIsFirmwareModalOpen } from "../../modal/selectors";

import { getIsDeviceConnected } from "../selectors";

export const ConnectDevice = (): React.ReactElement => {
  const isDeviceConnected = useSelector(getIsDeviceConnected);
  const isFirmwareModalOpen = useSelector(getIsFirmwareModalOpen);
  const initialIsOpen = !isDeviceConnected;

  const {
    deviceConnectionModal,
    isConnectionModalOpen,
    setIsConnectionModalOpen,
  } = useDeviceConnectionModal(
    DeviceConnectionFlows.connectDevice,
    initialIsOpen,
    RecentDevices,
    true,
    { allowOuterElementInteraction: true }
  );

  useEffect(() => {
    if (isDeviceConnected || isFirmwareModalOpen || isConnectionModalOpen)
      return;
    setIsConnectionModalOpen(true);
  }, [
    isConnectionModalOpen,
    isDeviceConnected,
    isFirmwareModalOpen,
    setIsConnectionModalOpen,
  ]);

  return <sc.Container>{deviceConnectionModal}</sc.Container>;
};

const sc = {
  Container: styled.div`
    text-align: center;
  `,
};
