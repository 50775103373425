import { StrainJson } from ".";

export type CreateStrainReviewJson = {
  clientTimestamp: string;
  effects: string[];
  rating: StrainRating;
  selectedEffects: string[];
  text: string;
};

export type StrainRating = 1 | 2 | 3 | 4 | 5;

export enum RecommendationType {
  DISSIMILAR_STRAINS = "DISSIMILAR_STRAINS",
  SIMILAR_STRAINS = "SIMILAR_STRAINS",
}

export type StrainReviewJson = CreateStrainReviewJson & {
  id: string;
  strainId: string;
  recommendationType: RecommendationType | "";
  recommendedPods: StrainJson[];
};
