import Bugsnag from "@bugsnag/js";
import { AxiosError } from "axios";
import { ThunkDispatch } from "redux-thunk";

import * as analytics from "../../../analytics";
import { trackEventAction } from "../../../analytics/actions";
import { trackLocation } from "../../../analytics/search/location";
import * as cookies from "../../../cookies";
import { MainAppState } from "../../../main/types";
import { isValidPostalCode } from "../../../pods/utils";
import * as flashBanner from "../../../shared/components/FlashBanner";
import * as text from "../../../shared/text";
import { Action, AppThunk, GetState } from "../../../shared/types";
import { SsoCountry } from "../../../sso/constants";

import { fetch } from "../index";
import * as p from "../paths";
import * as userSelectors from "../selectors/user";
import * as t from "../types";

export const RECEIVED_ANONYMOUS_USER = "RECEIVED_ANONYMOUS_USER";
export const RECEIVED_NEW_RECENT_SEARCH = "RECEIVED_NEW_RECENT_SEARCH";
export const RECEIVED_NEW_VIEWED_POD = "RECEIVED_NEW_VIEWED_POD";
export const RECEIVED_REGISTERED_USER = "RECEIVED_REGISTERED_USER";
export const RECEIVED_THEMES = "RECEIVED_THEMES";
export const RECEIVED_USER_LOCATION = "RECEIVED_USER_LOCATION";
export const SET_IS_FETCHING_USER = "SET_IS_FETCHING_USER";

export const receivedAnonymousUser = (
  anonymousUser: t.user.User,
  anonymousToken?: string,
  setAnonymousCookie = cookies.setAnonymousCookie
): Action => {
  if (anonymousToken) {
    setAnonymousCookie(anonymousToken);
  }

  return {
    payload: { anonymousToken, anonymousUser },
    type: RECEIVED_ANONYMOUS_USER,
  };
};

export const receivedRegisteredUser = (registeredUser: t.user.User): Action => {
  return {
    payload: { registeredUser },
    type: RECEIVED_REGISTERED_USER,
  };
};

export const receivedUserLocation = (location: t.user.UserLocation): Action => {
  return {
    payload: { location },
    type: RECEIVED_USER_LOCATION,
  };
};

type UpdateUserDataSharingPreferences = (
  dataSharingPreferences: t.user.DataSharingPreferences
) => AppThunk;

export const updateUserDataSharingPreferences: UpdateUserDataSharingPreferences =
  (dataSharingPreferences) => async (dispatch, getState) => {
    await updateUser({ dataSharingPreferences } as t.user.UpdateUser)(
      dispatch,
      getState,
      null
    );

    trackEventAction("track_usage_accepted", {
      wasAccepted: dataSharingPreferences.shareUsageData ? 1 : 0,
    })(dispatch, getState, null);
  };

type UpdateUserLocation = (query: string, region?: SsoCountry) => AppThunk;

export const updateUserLocation: UpdateUserLocation =
  (query, region) =>
  async (dispatch, getState): Promise<void> => {
    const isUserInUS = userSelectors.getIsUserInUS(getState());
    const data = region ? { region } : { query };

    try {
      const updatedLocation = await fetch<t.user.UserLocation>(
        p.users.userLocation(),
        { data, method: "PUT" }
      );

      const inputType = isValidPostalCode(query)
        ? "postal_code"
        : "street_address";

      dispatch(receivedUserLocation(updatedLocation));
      dispatch(trackLocation("manually_set", inputType));
    } catch (err) {
      const apiError = err as t.ApiError;
      if (apiError.errorCode === t.ErrorCode.NO_GEOCODING_RESULT) {
        dispatch(
          flashBanner.showFlashBanner(
            text.UNABLE_TO_FIND_LOCATION,
            flashBanner.BannerType.INFO
          )
        );
      } else if (apiError.errorCode === t.ErrorCode.GEOCODING_ERROR) {
        dispatch(
          flashBanner.showFlashBanner(
            text.locationErrorMessage(isUserInUS),
            flashBanner.BannerType.INFO
          )
        );
      } else if (apiError.errorCode === t.ErrorCode.BAD_PROVINCE) {
        dispatch(
          flashBanner.showFlashBanner(
            text.PAX_PODS_UNAVAILABLE_IN_LOCATION_PLEASE_TRY_DIFFERENT_LOCATION,
            flashBanner.BannerType.INFO
          )
        );
      } else {
        dispatch(
          flashBanner.showFlashBanner(
            text.OOPS_AN_ERROR_OCCURRED_WHILE_FINDING_LOCATION_UPDATE_SEARCH,
            flashBanner.BannerType.ERROR
          )
        );
        Bugsnag.notify(err as Error);
      }
    }
  };

export const deleteUserLocation = async (
  dispatch: ThunkDispatch<MainAppState, null, Action>
): Promise<void> => {
  try {
    const updatedLocation = await fetch<t.user.UserLocation>(
      p.users.userLocation(),
      { method: "DELETE" }
    );

    dispatch(receivedUserLocation(updatedLocation));
    dispatch(trackLocation("cleared"));
  } catch (err) {
    dispatch(
      flashBanner.showFlashBanner(
        text.OOPS_AN_ERROR_OCCURRED_WHILE_CHANGING_LOCATION,
        flashBanner.BannerType.INFO
      )
    );

    // Added to get more info on 404s we see in bugsnag
    const error = err as AxiosError;
    Bugsnag.notify(error, (event) => {
      if (!error.response) return;
      event.addMetadata("response", error.response);
    });
  }
};

export const fetchThemes =
  () =>
  async (dispatch: React.Dispatch<Action>): Promise<void> => {
    const token = userSelectors.getAuthenticationToken();

    if (!token) return;

    try {
      const response = await fetch<t.CollectionResponse<t.user.Theme>>(
        p.users.profileThemes()
      );

      if (!response?.results) return;

      dispatch(receivedThemes(response.results));
    } catch (e) {
      // Do nothing, fetch will handle the error.
    }
  };

const receivedThemes = (themes: t.user.Theme[]): Action => ({
  payload: { themes },
  type: RECEIVED_THEMES,
});

export const setDefaultProfileTheme =
  () =>
  (
    dispatch: ThunkDispatch<MainAppState, null, Action>,
    getState: GetState
  ): void => {
    const state = getState();
    const isUserSignedIn = userSelectors.getIsUserSignedIn(state);
    const profileTheme = userSelectors.getProfileTheme(state);
    const themes = userSelectors.getThemes(state);

    if (!isUserSignedIn || profileTheme || themes.length === 0) return;

    const updatedUser: t.user.UpdateUser = {
      profile: {
        theme: {
          id: themes[0].id,
        },
      },
    };

    updateUser(updatedUser)(dispatch, getState, null);
  };

type ReceivedNewViewedPod = (strain: t.StrainJson) => Action;

export const receivedNewViewedPod: ReceivedNewViewedPod = (strain) => ({
  payload: { strain },
  type: RECEIVED_NEW_VIEWED_POD,
});

export type UpdateUser = (
  updates: t.user.UpdateUser,
  trackProfileChange?: analytics.sso.TrackProfileChange
) => AppThunk;

export const updateUser: UpdateUser =
  (updates, trackProfileChange = analytics.sso.trackProfileChange) =>
  async (dispatch, getState): Promise<void> => {
    const state = getState();
    const user = userSelectors.getUser(state);

    try {
      const updatedUser = await fetch<t.user.User>(p.users.activeUser(), {
        data: updates,
        method: "PATCH",
      });

      if (updates.profile) {
        await trackProfileChange(user, updates.profile);
      }

      dispatch(receivedRegisteredUser(updatedUser));
    } catch (err) {
      // Do nothing, fetch will handle the error.
    }
  };

export const receivedNewRecentSearch = (newQuery: string): Action => ({
  payload: { newQuery },
  type: RECEIVED_NEW_RECENT_SEARCH,
});

export const setIsFetchingUser = (): Action => ({
  type: SET_IS_FETCHING_USER,
});
