import * as c from "./constants";
import * as t from "./types";

export const buildPath = (
  api: c.GOOGLE_API,
  parameters: t.ApiParameters
): string => {
  const parametersString = Object.entries(parameters)
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");

  return `${c.BASE_URL}/${api}?${
    parametersString ? parametersString + "&" : ""
  }key=${process.env.REACT_APP_GOOGLE_API_KEY}`;
};
