import { useEffect, useState } from "react";

import { useDesktopMediaQuery } from "../shared/hooks";
import { desktopTheme, mobileTheme, ResponsiveTheme } from "./themes";

export const useResponsiveTheme = (): ResponsiveTheme => {
  const [theme, setTheme] = useState<ResponsiveTheme>(mobileTheme);
  const isDesktop = useDesktopMediaQuery();

  useEffect(() => {
    setTheme(isDesktop ? desktopTheme : mobileTheme);
  }, [isDesktop]);

  return theme;
};
