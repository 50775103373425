import { connect } from "react-redux";

import * as s from "../../device/selectors";
import * as t from "../../main/types";

import { startEraProSession, stopEraProSession } from "../actions";
import EraProDoseControlCard from "../components/EraProDoseControlCard";

const mapStateToProps = (state: t.MainAppState) => ({
  isDeviceLocked: s.getIsDeviceLocked(state),
  session: s.getSession(state),
});

const mapDispatchToProps = {
  onStartEraProSession: startEraProSession,
  onStopEraProSession: stopEraProSession,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EraProDoseControlCard);
