import React from "react";
import { Redirect, Route, RouteComponentProps, Switch } from "react-router-dom";

import { AccountSettingsPageRedirect } from "./components/AccountSettingsPageRedirect";
import { MyDevicesPageRedirect } from "./components/MyDevicesPageRedirect";
import { MyPaxHomePageRedirect } from "./components/MyPaxHomePageRedirect";
import { MyPodsRedirect } from "./components/MyPodsRedirect";

export const MyPaxRoutes: React.FC<RouteComponentProps> = () => (
  <Switch>
    <Route exact path="/my-pax" component={MyPaxHomePageRedirect} />
    <Route
      exact
      path="/my-pax/account"
      component={AccountSettingsPageRedirect}
    />
    <Route exact path="/my-pax/devices" component={MyDevicesPageRedirect} />
    <Route
      exact
      path="/my-pax/pods/:selectedMyPodsType"
      component={MyPodsRedirect}
    />
    <Redirect from="*" to="/my-pax" />
  </Switch>
);
