import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";

import { DeviceJson } from "../../api/consumer/types/device";
import { getFormattedAddress } from "../../api/google-maps/geocoder";
import { isFindMyPaxEnabled } from "../../featureFlags";
import * as flashBanner from "../../shared/components/FlashBanner";
import { Column } from "../../shared/components/Flex";
import * as text from "../../shared/text";
import { desktopBreakpoint } from "../../shared/utils";

import { FindMyPaxInfo } from "./FindMyPaxInfo";
import { FindMyPaxMap } from "./FindMyPaxMap";

type FindMyPaxProps = {
  device: DeviceJson;
};

export const FindMyPax: React.FC<FindMyPaxProps> = ({ device }) => {
  const [address, setAddress] = useState<string>();

  useEffect(() => {
    const { location } = device;

    if (!location || !isFindMyPaxEnabled) {
      setAddress("");
      return;
    }

    const lookupAddress = async (): Promise<void> => {
      const { formatSuccessful, formattedAddress } = await getFormattedAddress(
        location
      );

      if (!formatSuccessful) {
        flashBanner.showFlashBanner(
          text.THERE_WERE_PROBLEMS_GETTING_YOUR_ADDRESS,
          flashBanner.BannerType.WARNING
        );
        return;
      }

      setAddress(formattedAddress);
    };

    lookupAddress();
  }, [device]);

  if (!isFindMyPaxEnabled) return null;

  return (
    <>
      <sc.Title>{text.PAXFINDER}</sc.Title>
      <sc.FindMyPaxContainer>
        <FindMyPaxInfo address={address} device={device} />
        <FindMyPaxMap address={address} device={device} />
      </sc.FindMyPaxContainer>
    </>
  );
};

const sc = {
  FindMyPaxContainer: styled(Column)`
    margin: auto;
    margin-top: 12px;
    max-width: 400px;
    width: 100%;

    & > div:first-child {
      margin-bottom: 10px;
    }

    ${desktopBreakpoint(
      css`
        flex-direction: row;
        max-width: none;

        & > div:first-child {
          margin-right: 8px;
        }
      `
    )}
  `,

  Title: styled.div`
    font-size: 20px;
    margin: auto;
    margin-top: 60px;
    max-width: 400px;
    width: 100%;

    ${desktopBreakpoint(
      css`
        max-width: none;
      `
    )}
  `,
};
