import md5 from "md5";
import { isMobile } from "react-device-detect";

import { encodeTextValue } from "../../pax-ble/utils";

export const getPlatform = (): string => {
  return isMobile ? "mobile-web" : "web";
};

// Return an md5 hash trimmed to 15 bytes (mirroring android).
export const encodeDeviceId = (deviceId: string): string => {
  const buffer = new ArrayBuffer(15);
  const view = new DataView(buffer);
  const deviceIdBytes = encodeTextValue(deviceId) as Buffer;
  const hashedBytes = md5(deviceIdBytes, { asBytes: true });

  for (let i = 0; i < 15; i++) {
    view.setUint8(i, Number(hashedBytes[i]));
  }
  const encodedDeviceId = Buffer.from(buffer).toString("hex");

  return encodedDeviceId;
};
