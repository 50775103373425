import React, { useCallback, useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

import * as deviceSelectors from "../../device/selectors";
import * as r from "../../main/routes";
import { ModalAction } from "../../modal/actions";
import { Modal } from "../../modal/components/Modal";
import * as flashBanner from "../../shared/components/FlashBanner";
import { DEVICE_DISCONNECTED_UNEXPECTEDLY } from "../../shared/text";

import FirmwareUpdateAvailableModalContent from "../containers/FirmwareUpdateAvailableModalContent";
import FirmwareUpdateProgressModalContent from "../containers/FirmwareUpdateProgressModalContent";
import * as selectors from "../selectors";
import * as t from "../types";
import { FirmwareUpdateConfirmationModalContent } from "./FirmwareUpdateConfirmationModalContent";

export type StateProps = {
  isFirmwareModalOpen: boolean;
};

export type DispatchProps = {
  showFlashBanner: flashBanner.ShowFlashBanner;
  closeFirmwareModal: ModalAction;
};

type OwnProps = {
  initialModalContent?: React.FC<t.FirmwareModalContentProps>;
  isBluetoothSupported?: boolean;
};

export type FirmwareModalProps = StateProps & DispatchProps & OwnProps;

const MODAL_FLOW: t.FirmwareFlow = {
  [t.FirmwareStep.UPDATE_AVAILABLE]: {
    next: connect()(FirmwareUpdateConfirmationModalContent),
    skip: null,
  },
  [t.FirmwareStep.UPDATE_CONFIRMATION]: {
    next: FirmwareUpdateProgressModalContent,
    skip: null,
  },
  [t.FirmwareStep.UPDATE_PROGRESS]: {
    next: null,
    skip: null,
  },
};

export const FirmwareModal: React.FC<FirmwareModalProps> = ({
  closeFirmwareModal,
  initialModalContent = FirmwareUpdateAvailableModalContent,
  isFirmwareModalOpen,
  showFlashBanner,
}) => {
  const firmwareUpgradeMessage = useSelector(
    selectors.getFirmwareUpgradeMessage
  );
  const isUpgradeInProgress = useSelector(
    selectors.getIsFirmwareUpgradeInProgress
  );
  const isConnected = useSelector(deviceSelectors.getIsDeviceConnected);

  const [FirmwareModalContent, setFirmwareModalContent] =
    useState<React.FC<t.FirmwareModalContentProps>>(initialModalContent);
  const [redirectUrl, setRedirectUrl] = useState<string | null>(null);

  useEffect(() => {
    if (isConnected || !isFirmwareModalOpen || isUpgradeInProgress) return;

    showFlashBanner(
      firmwareUpgradeMessage || DEVICE_DISCONNECTED_UNEXPECTEDLY,
      flashBanner.BannerType.ERROR
    );

    setFirmwareModalContent(initialModalContent);
    closeFirmwareModal();
    setRedirectUrl(r.home());
  }, [
    closeFirmwareModal,
    firmwareUpgradeMessage,
    initialModalContent,
    isConnected,
    isFirmwareModalOpen,
    isUpgradeInProgress,
    showFlashBanner,
  ]);

  const handleStepCompleted: t.OnFirmwareStepCompleted = useCallback(
    (currentStep, wasSkipped): void => {
      if (!isFirmwareModalOpen) return;

      const nextComponent = wasSkipped
        ? MODAL_FLOW[currentStep]?.skip
        : MODAL_FLOW[currentStep]?.next;

      if (!nextComponent) {
        setFirmwareModalContent(initialModalContent);
        closeFirmwareModal();
        return;
      }

      setFirmwareModalContent(nextComponent);
    },
    [closeFirmwareModal, initialModalContent, isFirmwareModalOpen]
  );

  const modalProps = {
    "data-testid": "firmware-modal",
    hideCloser: true,
    isOpen: isFirmwareModalOpen,
  };

  if (redirectUrl) {
    return <Redirect to={redirectUrl} />;
  }

  return (
    <Modal {...modalProps}>
      <FirmwareModalContent onStepCompleted={handleStepCompleted} />
    </Modal>
  );
};
