import React from "react";
import styled, { css, SimpleInterpolation } from "styled-components";

import { isConnectBrowser, navigator } from "../../../navigator";
import * as PaxBle from "../../../pax-ble";

import { PLATFORM_WINDOWS } from "../../appConstants";
import { NullableDeviceType } from "../../device/types";
import { ButtonWrapper } from "../../shared/components/ButtonWrapper";
import * as DeviceImages from "../../shared/components/DeviceImages";
import { DeviceLogo } from "../../shared/components/DeviceLogo";
import { Column, Row } from "../../shared/components/Flex";
import * as Text from "../../shared/components/Text";
import * as text from "../../shared/text";
import { desktopBreakpoint } from "../../shared/utils";

const { ERA, ERA_PRO, PAX_3, PAX_35 } = PaxBle.Types.DeviceType;

export type DeviceTypeListProps = {
  deviceError?: string;
  deviceTypes: PaxBle.Types.DeviceType[];
  horizontal?: boolean;
  onOptionClick: (newDeviceType: NullableDeviceType) => void;
  selectedDeviceType: NullableDeviceType;
  title: string;
};

export const DeviceTypeList: React.FC<DeviceTypeListProps> = ({
  deviceError,
  deviceTypes,
  horizontal = false,
  onOptionClick,
  selectedDeviceType,
  title,
}) => {
  const OptionContainer = horizontal
    ? sc.OptionsContainerVertical
    : sc.OptionsContainerHorizontal;

  const DeviceTypeOption = horizontal
    ? DeviceTypeOptionRow
    : DeviceTypeOptionColumn;

  const onReloadClick = (): void => {
    window.location.reload();
  };

  const connectBrowserGenericError = (
    <div onClick={onReloadClick}> {text.FAILED_TO_CONNECT_TAP_TO_REFRESH} </div>
  );

  const shouldShowconnectBrowserGenericError =
    isConnectBrowser &&
    deviceError === PaxBle.Constants.ERR_DEVICE_CONNECTION_MESSAGE;

  return (
    <sc.Container>
      <sc.Title>{title}</sc.Title>
      <OptionContainer>
        {deviceTypes.map((deviceType: PaxBle.Types.DeviceType) => {
          return (
            <DeviceTypeOption
              active={selectedDeviceType === deviceType}
              deviceType={deviceType}
              disabled={
                (deviceType === PAX_3 || deviceType === ERA) &&
                navigator.platform === PLATFORM_WINDOWS
              }
              key={deviceType}
              onClick={(): void => onOptionClick(deviceType)}
            />
          );
        })}
      </OptionContainer>
      {deviceError && (
        <sc.Message data-testid="device-connect-message">
          <Text.ErrorSpan>
            {shouldShowconnectBrowserGenericError
              ? connectBrowserGenericError
              : deviceError}
          </Text.ErrorSpan>
          <br />
          <br />
          <Text.Link
            href="https://www.pax.com/pages/pax-app-faq"
            rel="noopener noreferrer"
            target="_blank"
            underline
          >
            {text.HAVING_TROUBLE}
          </Text.Link>
        </sc.Message>
      )}
    </sc.Container>
  );
};

const deviceTypeImagesMap = {
  [ERA]: DeviceImages.Era,
  [ERA_PRO]: DeviceImages.EraProSilver,
  [PAX_3]: DeviceImages.Pax35Burgundy,
  [PAX_35]: DeviceImages.Pax35Burgundy,
};

type DeviceTypeOptionProps = {
  active: boolean;
  deviceType: PaxBle.Types.DeviceType;
  disabled: boolean;
  onClick: () => void;
};

const DeviceTypeOptionColumn: React.FC<DeviceTypeOptionProps> = ({
  active,
  deviceType,
  disabled,
  onClick,
}) => {
  return (
    <div>
      <sc.DeviceContainer
        active={active}
        aria-label={`Add a ${deviceType}`}
        data-testid={`device-type-${deviceType}`}
        disabled={disabled}
        onClick={disabled ? undefined : onClick}
      >
        <sc.Device
          src={deviceTypeImagesMap[deviceType]}
          alt={text.PAX_DEVICE}
        />
        {disabled && (
          <sc.DisabledIndicator>Available on MAC</sc.DisabledIndicator>
        )}
      </sc.DeviceContainer>
      <sc.DeviceLogo deviceType={deviceType} />
    </div>
  );
};

const DeviceTypeOptionRow: React.FC<DeviceTypeOptionProps> = ({
  active,
  deviceType,
  disabled,
  onClick,
}) => (
  <DeviceContainerRow
    active={active}
    data-testid={`device-type-${deviceType}`}
    disabled={disabled}
    onClick={disabled ? undefined : onClick}
  >
    <sc.DeviceLogo $horizontal deviceType={deviceType} />
    <sc.Device
      $horizontal
      alt={text.PAX_DEVICE}
      src={deviceTypeImagesMap[deviceType]}
    />
    {disabled && <sc.DisabledIndicator>Available on MAC</sc.DisabledIndicator>}
  </DeviceContainerRow>
);

type DeviceTypeContainerProps = {
  active: boolean;
  disabled: boolean;
};

type OrientationProps = {
  $horizontal?: boolean;
};

const sc = {
  ButtonContainer: styled.div`
    margin-top: 24px;
    text-align: center;
  `,

  Container: styled.div`
    padding: ${(props) => props.theme.connectionModal.padding};
  `,

  Device: styled.img<OrientationProps>`
    display: block;
    height: 215px;
    margin-top: 110px;

    ${desktopBreakpoint(
      css<OrientationProps>`
        height: ${({ $horizontal }) => ($horizontal ? "215px" : "210px")};
        margin-top: ${({ $horizontal }) => ($horizontal ? "110px" : "0")};
      `
    )}
  `,

  DeviceContainer: styled(ButtonWrapper)<DeviceTypeContainerProps>`
    align-items: center;
    background-color: ${(props): SimpleInterpolation =>
      props.active ? "var(--gunmetal-20);" : "var(--very-light-pink-4);"};
    border-radius: 8px;
    display: flex;
    height: 314px;
    justify-content: center;
    opacity: ${(props): SimpleInterpolation => (props.disabled ? 0.5 : 1)};
    position: relative;
    width: 234px;

    &:hover {
      background-color: ${(props): SimpleInterpolation =>
        props.disabled ? "var(--very-light-pink-4);" : "var(--gunmetal-20);"};
      cursor: ${(props): SimpleInterpolation =>
        props.disabled ? "default" : "pointer"};
    }
  `,

  DeviceLogo: styled(DeviceLogo)<OrientationProps>`
    display: block;
    height: 56px;
    margin: 0 40px 0 0;
    width: 131px;

    ${desktopBreakpoint(
      css<OrientationProps>`
        margin: ${({ $horizontal }) =>
          $horizontal ? "0 40px 0 0" : "16px auto 0"};
      `
    )}
  `,

  DisabledIndicator: styled.div`
    border: 1px solid var(--black);
    border-radius: 50px;
    bottom: 10px;
    font-size: 14px;
    padding: 8px;
    position: absolute;
  `,

  Message: styled.div`
    margin-top: 40px;
    min-height: 21px;
    text-align: center;
  `,

  OptionsContainerHorizontal: styled(Row)`
    justify-content: space-evenly;
    width: 806px;
  `,

  OptionsContainerVertical: styled(Column)`
    align-items: center;
    justify-content: space-evenly;
  `,

  Title: styled.div`
    font-size: ${(props) => props.theme.connectionModal.titleFontSize};
    margin-bottom: 32px;
    text-align: center;
    text-transform: uppercase;
  `,
};

const DeviceContainerRow = styled(sc.DeviceContainer)`
  height: 125px;
  margin-bottom: 12px;
  max-width: 344px;
  overflow: hidden;
  width: 100%;

  ${desktopBreakpoint(
    css`
      height: 150px;
      max-width: none;
      width: 344px;
    `
  )}
`;
