import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled, { css, SimpleInterpolation } from "styled-components";

import * as consumerApi from "../../api/consumer";
import * as routes from "../../main/routes";
import { Row } from "../../shared/components/Flex";
import * as text from "../../shared/text";
import { desktopBreakpoint } from "../../shared/utils";
import { ssoApiReturnTo } from "../../sso/utils";

import MyPaxHeader from "../containers/MyPaxHeader/MyPaxHeader";
import * as SC from "../styledComponents";
import { getDeviceCard } from "../utils";
import { MyPaxCard } from "./MyPaxCard";
import * as CardImages from "./MyPaxCardImages";
import { SignInHeader } from "./SignInHeader";

export type MyPaxHomePageProps = {
  hasFetchedUser: boolean;
  isSignedIn: boolean;
  lastConnectedDevice?: consumerApi.types.device.DeviceJson;
};

export const MyPaxHomePage: React.FC<MyPaxHomePageProps> = ({
  hasFetchedUser,
  isSignedIn,
  lastConnectedDevice,
}) => {
  const [myDevicesCardImage, setMyDevicesCardImage] = useState<string>(
    CardImages.UnknownDeviceCard
  );

  useEffect(() => {
    if (!lastConnectedDevice) {
      setMyDevicesCardImage(CardImages.UnknownDeviceCard);
      return;
    }

    setMyDevicesCardImage(
      getDeviceCard(lastConnectedDevice.model, lastConnectedDevice.shellColor)
    );
  }, [lastConnectedDevice, setMyDevicesCardImage]);

  return (
    <sc.Page>
      <MyPaxHeader short={false}>{text.ACCOUNT}</MyPaxHeader>
      {hasFetchedUser && !isSignedIn && (
        <sc.SignInHeader returnTo={ssoApiReturnTo(routes.account())} />
      )}
      <sc.RowContainer isSignedIn={isSignedIn}>
        <sc.CardsRow>
          <Link to={routes.accountDevices()}>
            <MyPaxCard
              data-testid="my-devices-card"
              imageSrc={hasFetchedUser ? myDevicesCardImage : ""}
              title={text.MY_DEVICES}
            />
          </Link>
          <Link to={routes.accountPods()}>
            <MyPaxCard
              data-testid="my-pods-card"
              imageSrc={CardImages.MyPods}
              title={text.MY_PODS}
            />
          </Link>
          <Link to={routes.accountSettings()}>
            <MyPaxCard
              data-testid="account-settings-card"
              imageSrc={CardImages.AccountSettings}
              title={text.ACCOUNT_SETTINGS}
            />
          </Link>
        </sc.CardsRow>
      </sc.RowContainer>
    </sc.Page>
  );
};

type RowContainerProps = {
  isSignedIn?: boolean;
};

const sc = {
  CardsRow: styled(Row)`
    align-content: center;
    flex-wrap: wrap;
    justify-content: flex-start;
  `,

  Page: styled(SC.Page)`
    max-width: calc(${(props) => props.theme.myPax.pageContentWidth} + 32px);
  `,

  RowContainer: styled.div<RowContainerProps>`
    margin: 0 auto;
    margin-top: ${({ isSignedIn }): SimpleInterpolation =>
      isSignedIn ? "20" : "8"}px;
    width: 100%;

    ${desktopBreakpoint(
      css<RowContainerProps>`
        margin-top: ${({ isSignedIn }): SimpleInterpolation =>
          isSignedIn ? "40" : "16"}px;
        min-width: calc(${(props) => props.theme.pageWidth} + 8px);
        position: relative;
        right: 4px;
      `
    )}
  `,

  SignInHeader: styled(SignInHeader)`
    width: ${(props) => props.theme.myPax.pageContentWidth};
  `,
};
