import * as t from "../types";
import { EraReplaySample } from "./EraReplaySample";

export class EraReplay {
  static fromBytes(bytes: Uint8Array): EraReplay {
    const action = bytes[0];
    const replay = new EraReplay(action);
    bytes = bytes.slice(1);

    let view = new DataView(bytes.buffer);
    let samplesAndRemainingBytes: {
      samples: EraReplaySample[];
      remainingBytes: Uint8Array;
    };

    switch (action) {
      case t.Era.EraReplayAction.USER_START:
        replay.doseId = Number(view.getBigUint64(0, true));
        bytes = bytes.slice(8);

        samplesAndRemainingBytes = EraReplaySample.multipleFromBytes(bytes);
        replay.samples = samplesAndRemainingBytes.samples;
        bytes = samplesAndRemainingBytes.remainingBytes;
        break;

      case t.Era.EraReplayAction.USER_STOP:
        replay.doseId = Number(view.getBigUint64(0, true));
        bytes = bytes.slice(8);
        break;

      case t.Era.EraReplayAction.CURRENT_STATE:
        replay.doseId = Number(view.getBigUint64(0, true));
        bytes = bytes.slice(8);

        if (replay.doseId === t.Era.EraDose.NONE) {
          replay.cutOffTime = view.getUint16(0, true);
          replay.idleTime = view.getUint16(2, true);
          replay.lockoutTime = view.getUint16(4, true);
          bytes = bytes.slice(6);

          replay.progress = 0;
          replay.isActive = false;
          replay.samples = [];
        } else {
          const { samples, remainingBytes } =
            EraReplaySample.multipleFromBytes(bytes);

          view = new DataView(remainingBytes.buffer);
          replay.progress = view.getUint32(0, true);
          replay.cutOffTime = view.getUint16(4, true);
          replay.idleTime = view.getUint16(6, true);

          replay.isActive = true;
          replay.lockoutTime = 0;
          replay.samples = samples;
        }
        break;

      case t.Era.EraReplayAction.FINISHED:
        replay.doseId = Number(view.getBigUint64(0, true));
        break;

      case t.Era.EraReplayAction.TIMED_OUT:
        samplesAndRemainingBytes = EraReplaySample.multipleFromBytes(bytes);
        replay.samples = samplesAndRemainingBytes.samples;
        view = new DataView(samplesAndRemainingBytes.remainingBytes.buffer);
        replay.progress = view.getUint32(0, true);
        break;

      case t.Era.EraReplayAction.ERROR:
        replay.doseId = Number(view.getBigUint64(0, true));
        replay.errorCode = bytes[8];
        break;

      case t.Era.EraReplayAction.LOCKOUT_EXPIRED:
        replay.doseId = Number(view.getBigUint64(0, true));
        break;

      default:
        //log bytes to bugsnag
        console.error("Unexpected action, bytes from device:");
        bytes.forEach((byte) => console.error(byte));
        throw new Error(`unexpected action: ${action}`);
    }

    return replay;
  }

  action: t.Era.EraReplayAction;
  cutOffTime = 0;
  doseId = 0;
  idleTime = 0;
  isActive = false;
  lockoutTime = 0;
  progress = 0;
  samples: EraReplaySample[] = [];

  errorCode?: t.Era.ErrorCode;

  constructor(
    action: number,
    doseId?: number,
    samplesData?: t.Era.EraReplaySampleData[]
  ) {
    this.action = action;

    if (doseId) this.doseId = doseId;

    if (samplesData) {
      this.samples = samplesData.map(
        ({ heaterSetPoint, usage }) =>
          new EraReplaySample(heaterSetPoint, usage)
      );
    }
  }

  writeToView(view: DataView, startIndex: number): void {
    let index = startIndex;

    switch (this.action) {
      case t.Era.EraReplayAction.USER_START:
        if (this.samples.length !== 1)
          throw new Error("expected a single sample");

        view.setUint8(index, t.Era.EraReplayAction.USER_START);
        index += 1;

        view.setBigUint64(index, BigInt(this.doseId), true);
        index += 8;

        view.setUint8(index, this.samples.length);
        index += 1;

        if (this.samples) {
          const sample = this.samples[0];
          view.setInt16(index, sample.heaterSetPoint, true);
          index += 2;

          view.setInt16(index, sample.usage, true);
          index += 2;
        }
        break;

      case t.Era.EraReplayAction.USER_STOP:
        view.setUint8(index, t.Era.EraReplayAction.USER_STOP);
        index += 1;

        view.setBigUint64(index, BigInt(this.doseId), true);
        index += 8;
        break;
    }
  }

  toJson(): t.Era.EraReplayJson {
    return {
      action: this.action,
      cutOffTime: this.cutOffTime,
      doseId: this.doseId,
      errorCode: this.errorCode,
      idleTime: this.idleTime,
      isActive: this.isActive,
      lockoutTime: this.lockoutTime,
      progressPercentage: this.progressPercentage,
      samples: this.samples.map((sample) => sample.toJson()),
    };
  }

  get progressPercentage(): number {
    if (this.progress === 0) return 0;

    return Math.round((100 * this.progress) / this.totalUsage);
  }

  get totalUsage(): number {
    return this.samples.reduce((memo, sample) => memo + sample.usage, 0);
  }
}
