import { PreferredTemperatureUnit } from "../api/consumer/types/user";

type TemperatureUnit = {
  label: string;
  shorthand: string;
};

export const TEMPERATURE_UNITS: {
  [key in PreferredTemperatureUnit]: TemperatureUnit;
} = {
  [PreferredTemperatureUnit.FAHRENHEIT]: {
    label: "Fahrenheit",
    shorthand: "F",
  },
  [PreferredTemperatureUnit.CELSIUS]: { label: "Celsius", shorthand: "C" },
};
