import { ReactElement } from "react";

import * as c from "../pods/constants";
import { useResponsiveScreenSize } from "../pods/hooks";

type ResponsiveModuleRowsOptions = {
  gridWidth: number;
  moduleRows: ReactElement[][];
};

type UseResponsiveModuleRows = (
  modules: ReactElement[]
) => ResponsiveModuleRowsOptions;

export const useResponsiveModuleRows: UseResponsiveModuleRows = (
  modules: ReactElement[]
) => {
  const screenSize = useResponsiveScreenSize();

  const columnCount = c.RESPONSIVE_POD_COLUMN_COUNT[screenSize];
  const gridWidth = c.RESPONSIVE_POD_ROW_WIDTH[screenSize];
  const moduleRows: ReactElement[][] = [];

  for (let i = 0; i < modules.length; i += columnCount) {
    moduleRows.push(modules.slice(i, i + columnCount));
  }

  return { gridWidth, moduleRows };
};
