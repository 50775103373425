import { encode } from "cborg";

import McuMgrBleTransport from "../ble/McuManagerBleTransport";
import * as u from "../utils";

export default class McuManager {
  static OP_READ = 0;
  static OP_WRITE = 2;

  groupId: number;
  transport: McuMgrBleTransport;

  constructor(groupId: number, transport: McuMgrBleTransport) {
    this.groupId = groupId;
    this.transport = transport;
  }

  queueOperation(
    op: number,
    flags: number,
    sequence: number,
    commandId: number,
    payload = {}
  ): Promise<Object> {
    const packet = this.buildPacket(
      op,
      flags,
      this.groupId,
      sequence,
      commandId,
      payload
    );

    return this.transport.queuePacket(packet);
  }

  buildPacket(
    op: number,
    flags: number,
    groupId: number,
    sequence: number,
    commandId: number,
    payload = {}
  ): Uint8Array {
    const encodedPayload = encode(payload);
    const header = [
      op,
      flags,
      encodedPayload.byteLength >>> 8,
      encodedPayload.byteLength,
      groupId >>> 8,
      groupId,
      sequence,
      commandId,
    ];
    const packet = u.mergeTypedArrays(
      new Uint8Array(header),
      new Uint8Array(encodedPayload)
    );

    return packet;
  }
}
