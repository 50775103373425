import React, { useEffect, useState } from "react";
import styled, { SimpleInterpolation } from "styled-components";

import { getEffectOptions } from "../../pod-feedback/utils";
import * as SC from "../../pods/components/PodsFilter/styledComponents";
import * as ps from "../../shared/components/PillSelect";
import * as Text from "../../shared/components/Text";

export type EffectsPillSelectProps = {
  max?: number;
  onChange: (selectedEffects: string[]) => unknown;
  value?: string[];
};

export const EffectsPillSelect: React.FC<EffectsPillSelectProps> = ({
  max = 2,
  onChange,
  value = [],
}) => {
  const [effectOptions, setEffectsOptions] = useState<ps.PillSelectOption[]>(
    getEffectOptions(value)
  );

  useEffect(() => {
    setEffectsOptions(getEffectOptions(value));
  }, [value]);

  const handleEffectChange = (changedOption: ps.PillSelectOption) => {
    const newEffect = changedOption.value;
    const currentSelectedEffects = [...value];
    let newSelectedEffects: string[];

    if (currentSelectedEffects.includes(newEffect)) {
      newSelectedEffects = currentSelectedEffects.filter(
        (e: string) => e !== newEffect
      );

      onChange(newSelectedEffects);

      return;
    }

    newSelectedEffects = [...currentSelectedEffects];

    if (currentSelectedEffects.length >= max) {
      newSelectedEffects = newSelectedEffects.slice(0, max - 1);
    }

    newSelectedEffects.push(newEffect);

    onChange(newSelectedEffects);
  };

  return (
    <ps.PillSelect
      Container={sc.EffectsSelectContainer}
      data-testid="effects-pill-select"
      onChange={handleEffectChange}
      options={effectOptions}
      PillOption={sc.PillOption}
    />
  );
};

const sc = {
  EffectsSelectContainer: styled(SC.EffectsSelectContainer)`
    justify-content: center;
  `,

  PillOption: styled(SC.BasePill)`
    ${Text.PlaaxBold}
    background-color: ${(props): SimpleInterpolation =>
      props.active ? "var(--black)" : "var(--white)"};
    border-radius: 8px;
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.08);
    color: ${(props): SimpleInterpolation =>
      props.active ? "var(--white)" : "var(--black)"};
    font-size: 14px;
    height: 48px;
    justify-content: flex-start;
    line-height: 20px;
    margin-bottom: 16px;
    padding-bottom: 14px;
    width: 156px;

    &:nth-child(odd) {
      margin-right: 16px;
    }
  `,
};
