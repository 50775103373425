import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import * as PaxBle from "../../../pax-ble";

import * as Text from "../../shared/components/Text";
import * as text from "../../shared/text";

import { getSelectedDeviceType } from "../selectors";

type DeviceWithoutPodTextProps = {
  fontSize?: number;
  isPodMissingStrainInfo: boolean;
  shouldUppercase?: boolean;
};

export const DeviceWithoutPodText: React.FC<DeviceWithoutPodTextProps> = ({
  fontSize = 16,
  isPodMissingStrainInfo,
  shouldUppercase,
}) => {
  const deviceType = useSelector(getSelectedDeviceType);

  let textToDisplay;
  if (deviceType !== PaxBle.Types.DeviceType.ERA_PRO) {
    textToDisplay = text.SELECT_A_POD;
  } else if (isPodMissingStrainInfo) {
    textToDisplay = text.UNABLE_TO_FIND_STRAIN;
  } else {
    textToDisplay = text.SELECT_OR_INSERT_POD;
  }

  return (
    <sc.Text fontSize={fontSize} isUppercase={shouldUppercase}>
      {textToDisplay}
    </sc.Text>
  );
};

type TextProps = {
  fontSize: number;
  isUppercase?: boolean;
};

const sc = {
  Text: styled.div<TextProps>`
    ${Text.PlaaxLight}
    font-size: ${({ fontSize }) => fontSize}px;
    line-height: 1.14;
    text-align: center;
    text-transform: ${({ isUppercase }) =>
      isUppercase ? "uppercase" : "none"};
    width: 236px;
  `,
};
