import React from "react";
import { useSelector } from "react-redux";
import styled, { css } from "styled-components";

import { getUserLocationCode } from "../../../api/consumer/selectors/user";
import { REGION_STATES } from "../../../data/regionStates";
import * as Text from "../../../shared/components/Text";
import { useDesktopMediaQuery } from "../../../shared/hooks";
import { desktopBreakpoint } from "../../../shared/utils";
import * as u from "../../../shared/utils";

import { GeoState } from "../../types";
import { getGeoStateLabel } from "../../utils";

const STATES_PER_ROW = 5;
const STATES_PER_ROW_MOBILE = 3;

export type PartnerStatesProps = {
  states?: string[];
};

export const PartnerStates: React.FC<PartnerStatesProps> = ({ states }) => {
  const isDesktop = useDesktopMediaQuery();

  const profileLocationCode = useSelector(getUserLocationCode);

  const statesPerRow = isDesktop ? STATES_PER_ROW : STATES_PER_ROW_MOBILE;

  if (!states || states.length === 0) return null;

  // Sort by name
  let geoStates: GeoState[] = states
    .filter(u.isGeoStateAmerican)
    .map((state) => ({
      geoCode: state,
      name: REGION_STATES.US[state].label,
    }))
    .sort((a, b) => (a.name > b.name ? 1 : -1));

  // Move preferred location first if mobile
  if (
    profileLocationCode &&
    u.isGeoStateAmerican(profileLocationCode) &&
    states.includes(profileLocationCode) &&
    !isDesktop
  ) {
    const profileLocation: GeoState = {
      geoCode: profileLocationCode,
      name: getGeoStateLabel(profileLocationCode),
    };

    geoStates = geoStates.filter(
      (state) => state.geoCode !== profileLocationCode
    );

    geoStates.unshift(profileLocation);
  }

  // If Canadian, render single location as 'Canada'.
  if (states.find(u.isGeoStateCanadian)) {
    geoStates.push({ geoCode: "CA-ON", name: "Canada" });
  }

  let stateRows: GeoState[][] = [];
  for (let i = 0; i < states.length; i += statesPerRow) {
    stateRows = stateRows.concat([geoStates.slice(i, i + statesPerRow)]);
  }

  return (
    <sc.Container>
      {stateRows.map((row, index) => (
        <div key={index}>
          {row.map(({ name, geoCode }) => (
            <sc.State
              isPreferred={geoCode === profileLocationCode}
              key={geoCode}
              data-testid="partner-location"
            >
              {name}
            </sc.State>
          ))}
        </div>
      ))}
    </sc.Container>
  );
};

type StateProps = {
  isPreferred?: boolean;
};

const sc = {
  Container: styled.div`
    max-width: ${(props) => props.theme.pageWidth};
    text-align: left;

    ${desktopBreakpoint(
      css`
        text-align: center;
      `
    )}
  `,

  State: styled.div<StateProps>`
    ${(props) => !props.isPreferred && Text.PlaaxLight};
    display: inline-block;
    font-size: 16px;
    line-height: 16px;

    &:not(:last-child):after {
      content: "  \\00B7   ";
      white-space: pre-wrap;
    }

    ${desktopBreakpoint(
      css<StateProps>`
        ${(props) => !props.isPreferred && Text.PlaaxLight};
        font-size: 18px;
        line-height: 1.3;
      `
    )}
  `,
};
