import React from "react";
import { useLocation } from "react-router-dom";

import * as cookies from "../cookies";
import * as analytics from "./index";

export const usePageViews = (
  getAnonymousUserPeripheralId = cookies.getAnonymousUserPeripheralId,
  trackPage = analytics.trackPage
): void => {
  const anonymousPeripheralId = getAnonymousUserPeripheralId();
  const location = useLocation();

  React.useEffect(() => {
    if (!anonymousPeripheralId) return;

    trackPage(location.pathname);
  }, [location.pathname, trackPage, anonymousPeripheralId]);
};
