import Bugsnag from "@bugsnag/js";

import * as c from "../constants";
import * as t from "../types";
import * as u from "../utils";
import { EraProDevice } from "./EraProDevice";
import { EraProDeviceEncryption } from "./EraProDeviceEncryption";
import {
  ADVERTISING_INIT_VECTOR,
  EraProEncryptionContext,
} from "./EraProEncryptionContext";

export class EraProEncryptionHandshake {
  device: EraProDevice;
  encryption: EraProDeviceEncryption;
  encryptionExchangeNotify?: BluetoothRemoteGATTCharacteristic;
  eraProPaxService: BluetoothRemoteGATTService;
  hasCompletedHandshake = false;

  constructor(device: EraProDevice, service: BluetoothRemoteGATTService) {
    this.device = device;
    this.eraProPaxService = service;

    this.encryption = new EraProDeviceEncryption();
    this.setEncryptedServiceUuid();
  }

  get encryptionContext(): EraProEncryptionContext {
    return this.encryption.encryptionContext;
  }

  handleEncryptionExchangeReceived = (event: Event): void => {
    if (!event || !event.target) return;

    const characteristic = event.target as BluetoothRemoteGATTCharacteristic;
    if (!characteristic.value) return;

    const buffer = new Uint8Array(characteristic.value.buffer);
    const attributeId = buffer[0];
    if (attributeId !== c.ATTRIBUTE_ENCRYPTION_PACKET) return;

    this.handleEncryptionPacket(buffer);
  };

  startEncryptionHandshake = async (): Promise<void> => {
    this.encryptionExchangeNotify =
      await this.eraProPaxService.getCharacteristic(
        c.ERA_PRO_CHARACTERISTICS.Notify
      );

    this.encryptionExchangeNotify.addEventListener(
      "characteristicvaluechanged",
      this.handleEncryptionExchangeReceived
    );

    await this.encryptionExchangeNotify.startNotifications();

    this.queueHandshakeRequest();
  };

  private queueHandshakeRequest(): void {
    const buffer = this.getEncryptionExchangeWriteOperationBuffer(
      t.EraPro.EncryptionExchangeSequence.SHARED_KEY,
      this.encryptionContext.getSharedKeyWithCrc16()
    );
    this.device.queueWriteRequest(buffer);
  }

  private handleEncryptionPacket(buffer: Uint8Array): void {
    try {
      const { decryptedPacket } = this.encryption.getDecryptedPacket(buffer);
      const decryptedAttributeId = decryptedPacket[0];

      if (decryptedAttributeId === c.ATTRIBUTE_ENCRYPTION_EXCHANGE) {
        const sequenceId = decryptedPacket[1];
        this.handleEncryptionExchangeValue(decryptedPacket, sequenceId);
      } else if (decryptedAttributeId === c.ATTRIBUTE_BLE_DIS_DATA) {
        const disId = decryptedPacket[1];
        this.handleBleDisDataValue(decryptedPacket, disId);
      }
    } catch (err) {
      // The handshake has been compromised, disconnect the device to try again.
      this.device.disconnect();
      Bugsnag.notify("Error completing the encryption handshake");
    }
  }

  private handleEncryptionExchangeValue(
    buffer: Uint8Array,
    sequenceId: number
  ): void {
    let encryptionPacket = null;
    let hasSentAck = false;
    const value = buffer.slice(2);

    if (sequenceId === t.EraPro.EncryptionExchangeSequence.PERIPHERAL_ID) {
      // Store peripheralId and write encrypted combinedId back to device.
      this.encryptionContext.peripheralId = value;

      const combinedId = this.encryptionContext.getCombinedId();
      encryptionPacket = this.getEncryptionExchangeWriteOperationBuffer(
        t.EraPro.EncryptionExchangeSequence.COMBINED_ID,
        combinedId
      );
    } else if (sequenceId === t.EraPro.EncryptionExchangeSequence.CENTRAL_ID) {
      // Check received centralId matches sent centralId and send ACK to device
      const deviceCentralId = u.decodeTextValue(value);
      const appCentralId = u.decodeTextValue(this.encryptionContext.centralId);
      if (deviceCentralId === appCentralId) {
        encryptionPacket = this.getEncryptionExchangeWriteOperationBuffer(
          t.EraPro.EncryptionExchangeSequence.ACKNOWLEDGE,
          new Uint8Array()
        );
        hasSentAck = true;
      }
    }

    if (encryptionPacket) this.device.queueWriteRequest(encryptionPacket);
    if (hasSentAck) this.requestBleDisData();
  }

  private handleBleDisDataValue(buffer: Uint8Array, disId: number): void {
    buffer = buffer.slice(2);

    if (disId === t.EraPro.BleDisData.FW_REVISION) {
      this.encryptionContext.firmwareRevision = buffer;
    } else if (disId === t.EraPro.BleDisData.SYSTEM_ID) {
      this.encryptionContext.systemId = buffer;
    }

    if (
      this.encryptionContext.firmwareRevision &&
      this.encryptionContext.systemId
    ) {
      this.hasCompletedHandshake = true;
      this.encryptionExchangeNotify?.removeEventListener(
        "characteristicvaluechanged",
        this.handleEncryptionExchangeReceived
      );
    }
  }

  private getEncryptionExchangeWriteOperationBuffer(
    sequenceId: number,
    value: Uint8Array
  ): Uint8Array {
    const baseData = Uint8Array.of(c.ATTRIBUTE_ENCRYPTION_EXCHANGE, sequenceId);
    const buffer = u.mergeTypedArrays(baseData, value);

    if (sequenceId === t.EraPro.EncryptionExchangeSequence.SHARED_KEY)
      return buffer;

    return this.encryption.buildEncryptedPacket(buffer);
  }

  // Firmware revision and systemId are only available in Ble Dis Data for encrypted
  // devices and are needed in EraProDevice before initialization can be completed.
  private requestBleDisData(): void {
    // Request firmware revision
    const firmwareRevisionBytes = Uint8Array.of(
      c.ATTRIBUTE_BLE_DIS_DATA,
      t.EraPro.BleDisData.FW_REVISION
    );
    const firmwareEncryptionPacket = this.encryption.buildEncryptedPacket(
      firmwareRevisionBytes
    );
    this.device.queueWriteRequest(firmwareEncryptionPacket);

    // Request systemId
    const systemIdBytes = Uint8Array.of(
      c.ATTRIBUTE_BLE_DIS_DATA,
      t.EraPro.BleDisData.SYSTEM_ID
    );
    const systemIdEncryptionPacket =
      this.encryption.buildEncryptedPacket(systemIdBytes);
    this.device.queueWriteRequest(systemIdEncryptionPacket);
  }

  private setEncryptedServiceUuid(): void {
    const reversedServiceUuidByteArray = u
      .encodeTextValue(c.ERA_PRO_PAX_SERVICE_UUID)
      .reverse();
    const encryptedServiceUuid = u.decodeTextValue(
      this.encryption.encrypt(
        reversedServiceUuidByteArray,
        ADVERTISING_INIT_VECTOR
      )
    );

    this.encryptionContext.serviceUuid = encryptedServiceUuid;
  }
}
