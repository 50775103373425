import styled, { css } from "styled-components";

import { desktopBreakpoint } from "../../shared/utils";

export const ResponsiveModalBackground = styled.div`
  background-color: var(--black-50);
  height: 100vh;
  left: 0;
  padding-bottom: var(--mobile-nav-bar-height);
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: var(--z-index-modal-overlay);

  ${desktopBreakpoint(
    css`
      align-items: center;
      display: flex;
      justify-content: center;
      overflow: auto;
      padding-bottom: 0;
    `
  )}
`;
