import { configureStore } from "@reduxjs/toolkit";

import { middleware } from "../middleware";
import { reducer } from "./reducer";

export const store = configureStore({
  devTools: process.env.NODE_ENV !== "production",
  middleware,
  reducer,
});
