export const emailVerifications = (): string => "/email-verifications";

export const emailVerificationRequests = (): string =>
  "/email-verification-requests";

export const login = (): string => "/registered-users/login";

export const logoutAnonymousUser = (): string => "/anonymous-users/logout";

export const logoutRegisteredUser = (): string => "/registered-users/logout";

export const newPassword = (): string => "/new-password";

export const pendingEmailVerificationRequests = (token: string): string =>
  `/pending-email-verification-requests/${token}`;

export const registeredUsers = (): string => "/registered-users";

export const resetPasswordRequest = (): string => "/reset-password-request";
