import { UiFunction } from "../../pax-ble/types/VaporEngine";

import * as t from "../shared/text";
import { DOSE_PROGRESS_THRESHOLD } from "./constants";

export const getModeLabel = (activeUiFunction: number): string | undefined => {
  switch (activeUiFunction) {
    case UiFunction.PETAL_1:
      return t.STEALTH;
    case UiFunction.PETAL_2:
      return t.EFFICIENCY;
    case UiFunction.PETAL_3:
      return t.BALANCED;
    case UiFunction.PETAL_4:
      return t.BOOST;
    default:
      return;
  }
};

export const getModeDescription = (
  activeUiFunction: number
): string | undefined => {
  switch (activeUiFunction) {
    case UiFunction.PETAL_1:
      return t.REDUCE_VISIBLE_VAPOR;
    case UiFunction.PETAL_2:
      return t.CONSERVES_OIL;
    case UiFunction.PETAL_3:
      return t.CONSISTENT_EXPERIENCE;
    case UiFunction.PETAL_4:
      return t.MAXIMIZES_VAPOR_PRODUCTION;
    default:
      return;
  }
};

export const getSessionProgressText = (
  percentageCompleted: number,
  shouldShowTimeout: boolean
): string =>
  shouldShowTimeout ||
  Math.abs(percentageCompleted - 100) <= DOSE_PROGRESS_THRESHOLD
    ? t.TIMEOUT
    : `${percentageCompleted}%`;
