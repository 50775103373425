import React, { Dispatch, SetStateAction, useEffect, useState } from "react";

import * as t from "../../../shared/text";
import { DropdownOption } from "../../../shared/types";

import { STRAIN_SORT_FILTERS, StrainSortFilter } from "../../constants";
import { StrainsFilter } from "../../types";

import * as SC from "./styledComponents";

type PodsSortByProps = {
  filterUpdate: StrainsFilter;
  setFilterUpdate: Dispatch<SetStateAction<StrainsFilter>>;
};

export const PodsSortBy: React.FC<PodsSortByProps> = ({
  filterUpdate,
  setFilterUpdate,
}) => {
  const [selectedSortOption, setSelectedSortedOption] =
    useState<DropdownOption>();

  useEffect(() => {
    const option =
      STRAIN_SORT_FILTERS.find((option) => {
        return option.value === filterUpdate.orderBy;
      }) || STRAIN_SORT_FILTERS[0];
    setSelectedSortedOption(option);
  }, [filterUpdate]);

  const handleSortByChange = (option: StrainSortFilter) => {
    setFilterUpdate({
      ...filterUpdate,
      orderBy: option.orderByValue,
    });
  };

  return (
    <SC.Filter>
      <SC.Label htmlFor="strain-sort-dropdown">{t.SORT}</SC.Label>
      <SC.Dropdown
        data-testid="strain-sort-dropdown"
        inputId="strain-sort-dropdown"
        name="strain-sort-dropdown"
        onChange={handleSortByChange}
        options={STRAIN_SORT_FILTERS}
        placeholder={STRAIN_SORT_FILTERS[0].label}
        value={selectedSortOption}
      />
    </SC.Filter>
  );
};
