import * as rt from "@reduxjs/toolkit";
import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ModalProvider } from "styled-react-modal";

import { reducer as analyticsReducer } from "../../analytics/reducer";
import { reducer as consumerApiReducer } from "../../api/consumer/reducer";
import { middleware } from "../../middleware";
import { reducer as flashBannerReducer } from "../../shared/components/FlashBanner/reducer";

import { SsoApp as App } from "../components/SsoApp";
import { reducer as ssoReducer } from "../reducer";

const reducer = rt.combineReducers({
  analytics: analyticsReducer,
  consumerApi: consumerApiReducer,
  flashBanner: flashBannerReducer,
  sso: ssoReducer,
});

const store = rt.configureStore({
  devTools: process.env.NODE_ENV !== "production",
  middleware,
  reducer,
});

export const SsoApp: React.FC = () => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <ModalProvider>
          <App />
        </ModalProvider>
      </BrowserRouter>
    </Provider>
  );
};
