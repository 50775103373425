import React from "react";
import styled from "styled-components";

import { Button } from "../../shared/components/Button";
import { Search } from "../../shared/components/Icons";

export type PodsSearchPageOpenerProps = {
  onSearchClick: () => void;
};
export const PodsSearchPageOpener: React.FC<PodsSearchPageOpenerProps> = ({
  onSearchClick,
}) => {
  return (
    <sc.SearchButton
      aria-label="pods-search"
      onClick={onSearchClick}
      transparent
    >
      <Search />
    </sc.SearchButton>
  );
};

const sc = {
  SearchButton: styled(Button)`
    height: auto;
    padding: 10px 20px 14px 14px;
    width: 52px;
  `,
};
