// Spec:
// https://paxlabs.atlassian.net/wiki/spaces/PMA/pages/1518207002/Consumer+Data+Analytics+Spec#SSO

import * as t from "../api/consumer/types";

import { trackEvent } from ".";

export type TrackForgotPassword = () => Promise<void>;

export const trackForgotPassword: TrackForgotPassword = () => {
  return trackSsoEvent("forgot_password");
};

type TrackLoginData = {
  error?: string | undefined;
  isStart?: boolean;
  isSuccess?: boolean;
  source?: string;
};

export type TrackLogin = (data: TrackLoginData) => Promise<void>;

export const trackLogin: TrackLogin = (data) => {
  return trackSsoEvent("login", data);
};

type TrackSignUpData = {
  error?: string | undefined;
  isStart?: boolean;
  isSuccess?: boolean;
  source?: string;
};

export type TrackSignup = (data: TrackSignUpData) => Promise<void>;

export const trackSignup: TrackSignup = (data) => {
  return trackSsoEvent("sign_up", {
    eventIndex: Date.now(),
    ...data,
  });
};

export type TrackProfileChange = (
  user: t.user.User,
  profileUpdates: t.user.Profile
) => Promise<void>;

export enum AnalyticsTemperatureUnit {
  IMPERIAL = "imperial",
  METRIC = "metric",
}

type UpdatePreferencesData = {
  newTempUnit?: AnalyticsTemperatureUnit;
  oldTempUnit?: AnalyticsTemperatureUnit;
};

export const trackProfileChange: TrackProfileChange = async (user, profile) => {
  const { theme, preferredTemperatureUnit } = profile;

  if (theme?.id !== user.profile?.theme?.id) {
    await trackSsoEvent("change_profile", {
      newProfileId: theme?.id,
      oldProfileId: user.profile?.theme?.id,
    });
  }

  const newTemperatureUnit = preferredTemperatureUnit;

  if (newTemperatureUnit) {
    const data: UpdatePreferencesData = {};

    if (newTemperatureUnit !== user.profile?.preferredTemperatureUnit) {
      data.oldTempUnit = getAnalyticsTemperatureUnit(
        user.profile?.preferredTemperatureUnit
      );
      data.newTempUnit = getAnalyticsTemperatureUnit(newTemperatureUnit);
    }

    if (data.newTempUnit) {
      await trackSsoEvent("update_preferences", data);
    }
  }
};

const getAnalyticsTemperatureUnit = (
  unit: t.user.PreferredTemperatureUnit | undefined
): AnalyticsTemperatureUnit | undefined => {
  if (!unit) return undefined;
  return unit === t.user.PreferredTemperatureUnit.CELSIUS
    ? AnalyticsTemperatureUnit.METRIC
    : AnalyticsTemperatureUnit.IMPERIAL;
};

const trackSsoEvent = async (event: string, properties = {}): Promise<void> => {
  // Add a buffer to avoid tracking request cancelations.
  // This is done specifically for SSO tracking calls since SSO pages tend to cause redirects.
  return new Promise((resolve) => {
    trackEvent(event, {
      eventIndex: Date.now(),
      ...properties,
    });

    setTimeout(resolve, 250);
  });
};
