// TODO remove import from app and navigator
import { PLATFORM_WINDOWS } from "../../app/appConstants";
import * as McuMgrConstants from "../../mcumgr-js/src/constants";
import { navigator } from "../../navigator";

// R/W Attributes
export const ATTRIBUTE_ACTUAL_TEMP = 1;
export const ATTRIBUTE_BATTERY = 3;
export const ATTRIBUTE_BLE_DIS_DATA = 52;
export const ATTRIBUTE_BRIGHTNESS = 21;
export const ATTRIBUTE_CHARGE_STATUS = 7;
export const ATTRIBUTE_CURRENT_TARGET_TEMP = 31;
export const ATTRIBUTE_COLOR_THEME = 20;
export const ATTRIBUTE_DEVICE_NAME = 10;
export const ATTRIBUTE_DYNAMIC_MODE = 19;
export const ATTRIBUTE_ENCRYPTION_EXCHANGE = 49;
export const ATTRIBUTE_ENCRYPTION_PACKET = 50;
export const ATTRIBUTE_FIND_MY_PAX = 54;
export const ATTRIBUTE_GAME_MODE = 15;
export const ATTRIBUTE_HAPTICS = 40;
export const ATTRIBUTE_HAPTIC_MODE = 23;
export const ATTRIBUTE_HEATER_RANGES = 17;
export const ATTRIBUTE_HEATER_SET_POINT = 2;
export const ATTRIBUTE_HEATING_PARAMETERS = 25;
export const ATTRIBUTE_HEATING_STATE = 32;
export const ATTRIBUTE_LOCKED = 6;
export const ATTRIBUTE_LOG_REQUEST = 41;
export const ATTRIBUTE_LOG_SYNC_REQUEST = 18;
export const ATTRIBUTE_LOW_SOC_MODE = 30;
export const ATTRIBUTE_POD_DATA = 42;
export const ATTRIBUTE_POD_INSERTED = 8;
export const ATTRIBUTE_REPLAY = 13;
export const ATTRIBUTE_SESSION_CONTROL = 36;
export const ATTRIBUTE_SHELL_COLOR = 28;
export const ATTRIBUTE_STATUS_UPDATE = 254;
export const ATTRIBUTE_SUPPORTED_ATTRIBUTES = 24;
export const ATTRIBUTE_TIME = 9;
export const ATTRIBUTE_UI_MODE = 27;
export const ATTRIBUTE_USAGE = 4;
export const ATTRIBUTE_USAGE_LIMIT = 5;
export const ATTRIBUTE_VAPOR_ENGINE = 56;

// Log Types
export const LOG_RESET = 64;
export const LOG_ERROR = 65;
export const LOG_RESET_V2 = 66;
export const LOG_PUFF = 71;
export const LOG_PUFF_V2 = 72;
export const LOG_PUFF_V3 = 73;
export const LOG_HEATER_SET_POINT = 74;
export const LOG_LOCK = 75;
export const LOG_UNLOCK = 76;
export const LOG_BRIGHTNESS = 77;
export const LOG_CHARGER_IN = 80;
export const LOG_CHARGER_OUT = 81;
export const LOG_CHARGE_COMPLETE = 82;
export const LOG_CHARGE_CHANGE = 83;
export const LOG_POD_INSERTED = 90;
export const LOG_POD_REMOVED = 91;
export const LOG_POD_READ = 93;
export const LOG_POD_READ_V2 = 94;
export const LOG_STATE_CHANGE = 95;
export const LOG_MOTION_SENSOR = 103;
export const LOG_DISCONNECT_REASON = 109;
export const LOG_CONNECT = 110;
export const LOG_DISCONNECT = 111;
export const LOG_APP_COMMAND = 112;
export const LOG_NAME_CHANGE = 113;
export const LOG_DFU_START = 120;
export const LOG_TIME_SYNC = 130;
export const LOG_BLUETOOTH_ERROR = 136;
export const LOG_ASSERT = 140;
export const LOG_LOW_BATTERY = 141;
export const LOG_INACTIVITY = 142;

export const LOG_FETCH_TIMEOUT = 500; // ms

// Use a longer wait time on Windows and unknown platforms.
export const CONNECT_WAIT_TIME =
  !navigator.platform || navigator.platform === PLATFORM_WINDOWS ? 3200 : 1200;

export const ERR_BAD_IDENTIFIER = "ERR_BAD_IDENTIFIER";
export const ERR_BAD_ATTRIBUTE_PARSE = "ERR_BAD_ATTRIBUTE_PARSE";
export const ERR_BAD_ATTRIBUTE_PARSE_CODE = -1;
export const ERR_BAD_ENCRYPTED_PACKET_CODE = -2;
export const ERR_CONNECTION_TIMEOUT = "ERR_CONNECTION_TIMEOUT";
export const ERR_DEVICE_CONNECTION_MESSAGE =
  "Failed to connect to device. Please try again.";
export const ERR_ENCRYPTION_HANDSHAKE_FAILED =
  "ERR_ENCRYPTION_HANDSHAKE_FAILED";

export const UNSYCNED_LOG_EVENTS_THRESHOLD = 100;

export const DEVICE_INFO_SERVICE_UUID = "0000180a-0000-1000-8000-00805f9b34fb";
export const SMP_SERVICE_UUID = McuMgrConstants.SMP_SERVICE_UUID;
export const ERA_PAX_SERVICE_UUID = "8e320200-64d2-11e6-bdf4-0800200c9a66";
export const ERA_LOG_SERVICE_UUID = "64f50300-efdc-11e6-bc64-92361f002671";

export const ERA_CHARACTERISTICS = {
  FirmwareRevision: "00002a26-0000-1000-8000-00805f9b34fb",
  LogNotify: "64f50302-efdc-11e6-bc64-92361f002671",
  LogRead: "64f50301-efdc-11e6-bc64-92361f002671",
  Notify: "8e320203-64d2-11e6-bdf4-0800200c9a66",
  Read: "8e320201-64d2-11e6-bdf4-0800200c9a66",
  SystemId: "00002a23-0000-1000-8000-00805f9b34fb",
  Write: "8e320202-64d2-11e6-bdf4-0800200c9a66",
};

export const ERA_PRO_CHARACTERISTICS = {
  FirmwareRevision: "00002a26-0000-1000-8000-00805f9b34fb",
  LogNotify: "64f50312-efdc-11e6-bc64-92361f002671",
  Notify: "8e320213-64d2-11e6-bdf4-0800200c9a66",
  Read: "8e320211-64d2-11e6-bdf4-0800200c9a66",
  SystemId: "00002a23-0000-1000-8000-00805f9b34fb",
  Write: "8e320212-64d2-11e6-bdf4-0800200c9a66",
};

export const ERA_PRO_PAX_SERVICE_UUID = "8e320210-64d2-11e6-bdf4-0800200c9a66";
export const ERA_PRO_LOG_SERVICE_UUID = "64f50310-efdc-11e6-bc64-92361f002671";

export * as Era from "./Era";
export * as Pax3 from "./Pax3";
export * as VaporEngine from "./VaporEngine";
