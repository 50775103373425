import { connect } from "react-redux";

import * as consumerApi from "../../api/consumer/";
import { MainAppState } from "../../main/types";

import { PreferencesModal } from "../components/PreferencesModal";

const mapStateToProps = (state: MainAppState) => ({
  userProfile: state.consumerApi.user.profile,
});

const mapDispatchToProps = {
  updateUser: consumerApi.actions.users.updateUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(PreferencesModal);
