import React from "react";
import styled, { css } from "styled-components";

import { Modal, ModalProps } from "../../modal/components/Modal";
import { Column } from "../../shared/components/Flex";
import * as Text from "../../shared/components/Text";
import * as text from "../../shared/text";
import { desktopBreakpoint } from "../../shared/utils";

import { ReportMissingStrainForm } from "./ReportMissingStrainForm";

export type OnMissingStrainModalPropTypes = ModalProps & {
  onSubmit: () => void;
};

export const ReportMissingStrainModal: React.FC<OnMissingStrainModalPropTypes> =
  ({ isOpen, onRequestClose, onSubmit }) => {
    const modalProps = {
      isOpen,
      onRequestClose,
      onSubmit,
    };

    return (
      <sc.Modal {...modalProps}>
        <sc.Container>
          <sc.Heading>{text.REPORT_MISSING_STRAIN}</sc.Heading>
          <sc.Text>{text.REPORT_MISSING_STRAIN_TAGLINE}</sc.Text>
          <ReportMissingStrainForm onSubmit={onSubmit} />
        </sc.Container>
      </sc.Modal>
    );
  };

const sc = {
  Container: styled(Column)`
    margin: 0 auto;
    max-width: 500px;
    overflow: auto;
    padding: 28px 60px;
    width: 100vw;

    ${desktopBreakpoint(
      css`
        width: 432px;
      `
    )}
  `,

  Heading: styled(Text.H3)`
    text-align: center;
  `,

  Modal: styled(Modal)`
    border-radius: 0;
    box-shadow: none;

    ${desktopBreakpoint(
      css`
        border-radius: 8px;
        box-shadow: 0 1px 12px 0 var(--bluey-grey-80);
        max-height: 100%;
        overflow: auto;
      `
    )}
  `,

  Text: styled.div`
    ${Text.PlaaxLight}
    font-size: 14px;
    line-height: 1.29;
    margin-bottom: 30px;
    text-align: center;
  `,
};
