import React from "react";
import * as reactQuery from "react-query";
import { useDispatch } from "react-redux";
import styled, { css } from "styled-components";

import * as consumerApi from "../../../api/consumer";
import { Box } from "../../../shared/components/Box";
import * as Text from "../../../shared/components/Text";
import * as text from "../../../shared/text";
import { desktopBreakpoint, getLastUsed } from "../../../shared/utils";

import { PodSectionTitleStyles } from "../../styledComponents";

export type PodUsageProps = {
  strainId: string;
  useQuery?: () => reactQuery.UseQueryResult<consumerApi.types.connectedPods.ConnectedPodJson>;
};

export const PodUsage: React.FC<PodUsageProps> = ({
  strainId,
  useQuery = reactQuery.useQuery,
  ...props
}) => {
  const dispatch = useDispatch();

  const { data: connectedPod } =
    useQuery<consumerApi.types.connectedPods.ConnectedPodJson>(
      consumerApi.paths.connectedPods.connectedPod(strainId),
      {
        onSuccess: (connectedPod) => {
          dispatch(
            consumerApi.actions.connectedPods.receivedConnectedPod(connectedPod)
          );
        },
      }
    );

  if (!connectedPod) return null;

  // If the podSerialNumbers array is empty, it must be a manually connected pod.
  // There's no way to keep track of the manual pod count so it gets set as 1.
  const podCount = Math.max(1, connectedPod.podSerialNumbers.length);

  return (
    <div data-testid="your-usage-box" {...props}>
      <sc.Title>{text.MY_USAGE}</sc.Title>
      <sc.Box>
        <sc.InfoContainer data-testid="last-use">
          <sc.InfoLabel>{`${text.LAST_USE}:`}</sc.InfoLabel>
          <sc.InfoValue>
            {getLastUsed(connectedPod.latestClientTimestamp)}
          </sc.InfoValue>
        </sc.InfoContainer>
        <sc.InfoContainer data-testid="pod-count">
          <sc.InfoLabel>{`${text.POD_COUNT}:`}</sc.InfoLabel>
          <sc.InfoValue>{podCount}</sc.InfoValue>
        </sc.InfoContainer>
        <sc.InfoContainer data-testid="first-use">
          <sc.InfoLabel>{`${text.FIRST_USE}:`}</sc.InfoLabel>
          <sc.InfoValue>
            {getLastUsed(connectedPod.earliestClientTimestamp)}
          </sc.InfoValue>
        </sc.InfoContainer>
      </sc.Box>
    </div>
  );
};

const sc = {
  Box: styled(Box)`
    align-items: flex-start;
    flex-direction: column;

    ${desktopBreakpoint(
      css`
        max-width: calc(50% - 41px);
      `
    )}
  `,

  InfoContainer: styled.div`
    display: flex;
    justify-content: left;
    margin-bottom: 5px;

    &:last-child {
      margin-bottom: 0;
    }
  `,

  InfoLabel: styled.div`
    font-size: 14px;
    margin-right: 6px;
  `,

  InfoValue: styled.div`
    ${Text.MediumLLMedium}
    font-size: 14px;
    line-height: 1.3;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `,

  Title: styled.div`
    ${PodSectionTitleStyles}
    margin: 24px 0 8px;
  `,
};
