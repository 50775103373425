import { FirmwareUpgradeState as McuFirmwareUpgradeState } from "../../mcumgr-js/src/dfu/FirmwareUpgradeManager";

import { Action } from "../shared/types";
import * as a from "./actions";
import * as t from "./types";

const initialState: t.FirmwareState = {
  firmwareInfo: {
    downloadUrl: "",
    version: "",
  },
  firmwareUpgradeState: {
    mcuState: McuFirmwareUpgradeState.NONE,
    title: "",
  },
};

export const getInitialState = (
  overrides?: Partial<t.FirmwareState>
): t.FirmwareState => {
  return { ...initialState, ...overrides };
};

export const reducer = (
  state: t.FirmwareState = initialState,
  action: Action
): t.FirmwareState => {
  const { payload } = action;

  switch (action.type) {
    case a.RECEIVED_FIRMWARE_INFO:
      const { version, downloadUrl } = payload;

      return {
        ...state,
        firmwareInfo: { downloadUrl, version },
      };

    case a.SET_FIRMWARE_UPGRADE_STATE:
      const firmwareUpgradeState: t.FirmwareUpgradeState =
        payload.firmwareUpgradeState;

      return { ...state, firmwareUpgradeState };

    case a.SET_FIRMWARE_UPGRADE_PROGRESS:
      const { progress } = payload;

      return {
        ...state,
        firmwareUpgradeState: {
          ...state.firmwareUpgradeState,
          progress,
        },
      };

    default:
      return state;
  }
};
