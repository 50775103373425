import React from "react";
import styled, { css } from "styled-components";

import * as consumerApi from "../../api/consumer";
import * as deviceActions from "../../device/actions";
import * as c from "../../device/constants";
import { ConnectionStatus } from "../../device/types";
import Button from "../../shared/components/Button";
import * as flashBanner from "../../shared/components/FlashBanner";
import * as text from "../../shared/text";
import { desktopBreakpoint, smallScreenBreakpoint } from "../../shared/utils";

import { getIsMyDeviceConnected } from "../utils";
import { DeviceLogo } from "./DeviceLogo";
import { MyDeviceConnectionStatus } from "./MyDeviceConnectionStatus";

export type StateProps = {
  connectionStatus: ConnectionStatus;
  supportsBluetooth: boolean;
};

export type DispatchProps = {
  connectToDevice: deviceActions.ConnectToDevice;
  deviceConnecting: deviceActions.DeviceConnecting;
  disconnectDevice: deviceActions.DisconnectDevice;
  removeDeviceInUserAccount: deviceActions.RemoveDeviceInUserAccount;
  selectDeviceType: deviceActions.SelectDeviceType;
};

type OwnProps = {
  device: consumerApi.types.device.DeviceJson;
  onForgetDevice: () => void;
};

export type MyDeviceProps = StateProps & DispatchProps & OwnProps;

export const MyDevice: React.FC<MyDeviceProps> = ({
  connectionStatus,
  connectToDevice,
  device,
  deviceConnecting,
  disconnectDevice,
  onForgetDevice,
  removeDeviceInUserAccount,
  selectDeviceType,
  supportsBluetooth,
  ...props
}) => {
  const isConnected = getIsMyDeviceConnected(device.serialNumber);

  const handleForgetDeviceClick = async (): Promise<void> => {
    // Wait for device to finish disconnecting before deleting it.
    await disconnectDevice();
    removeDeviceInUserAccount(device);
    onForgetDevice();
  };

  const handleConnectActionClick = async (): Promise<void> => {
    if (isConnected) {
      disconnectDevice();
      return;
    }

    deviceConnecting();

    const deviceType = consumerApi.constants.DEVICE_MODEL_MAP[device.model];
    const deviceOptions = c.getConnectToDeviceOptions(
      deviceType,
      device.serialNumber
    );

    try {
      selectDeviceType(deviceType);

      // await to catch error thrown when user cancels the bluetooth connection dialog.
      await connectToDevice(deviceType, deviceOptions);
    } catch (err) {
      flashBanner.showFlashBanner(
        (err as Error).message,
        flashBanner.BannerType.ERROR
      );
      disconnectDevice();
    }
  };

  return (
    <sc.Container {...props}>
      <sc.DeviceLogo
        className={device.model.toLowerCase().replace("_", "-")}
        data-testid="device-logo"
        device={device}
      />
      <sc.DeviceName>{device.deviceName}</sc.DeviceName>
      <MyDeviceConnectionStatus
        connectionStatus={connectionStatus}
        isDeviceActive={isConnected}
      />
      {supportsBluetooth && (
        <sc.ButtonRow>
          <sc.Button
            data-testid="connect-button"
            disabled={connectionStatus === ConnectionStatus.CONNECTING}
            onClick={handleConnectActionClick}
          >
            {isConnected ? text.DISCONNECT : text.CONNECT}
          </sc.Button>
          <sc.Button
            data-testid="forget-device-button"
            onClick={handleForgetDeviceClick}
          >
            {text.FORGET_DEVICE}
          </sc.Button>
        </sc.ButtonRow>
      )}
    </sc.Container>
  );
};

type ButtonProps = {
  disabled?: boolean;
};

const ERA_PRO_STYLES = css`
  background-position: 44px 8px;
  background-size: 48px;
`;

const ERA_STYLES = css`
  background-position: 33px 4px;
  background-size: 72px;
`;

const PAX_3_STYLES = css`
  background-position: 42px 12px;
  background-size: 52px;
`;

const sc = {
  Button: styled(Button)<ButtonProps>`
    color: ${({ disabled }) =>
      disabled ? "var(--very-light-pink-4)" : "var(--white)"};
    font-size: 15px;
    min-width: 140px;
    opacity: ${({ disabled }) => (disabled ? ".6" : "1")};
    padding: 10px 5px;

    ${desktopBreakpoint(
      css`
        padding: 10px;
        width: 152px;
      `
    )}
  `,

  ButtonRow: styled.div`
    margin: 20px auto;

    & button {
      margin-right: 14px;
    }

    & button:last-child {
      margin-right: 0;
    }

    ${smallScreenBreakpoint(
      css`
        & button {
          margin-right: 6px;
        }
      `
    )}

    ${desktopBreakpoint(
      css`
        & button {
          margin-right: 32px;
        }
      `
    )}
  `,

  Container: styled.div`
    display: flex;
    flex-direction: column;
  `,

  DeviceLogo: styled(DeviceLogo)`
    height: 140px;
    margin: auto;
    width: 140px;

    &.era-pro {
      ${ERA_PRO_STYLES}
    }

    &.era {
      ${ERA_STYLES}
    }

    &.pax-3 {
      ${PAX_3_STYLES}
    }

    &.pax-3_5 {
      ${PAX_3_STYLES}
    }
  `,

  DeviceName: styled.div`
    font-size: 24px;
    margin-top: 20px;
    text-align: center;
    text-transform: uppercase;
  `,
};
