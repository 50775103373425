import styled from "styled-components";

// Do not define height here - breaks infinite scroll
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
`;

export const App = styled.main`
  // background-color is set to white to avoid transparency in Connect Browser running in iOS dark mode.
  background-color: var(--white);
  flex: 1;
  margin-bottom: var(--mobile-nav-bar-height);
  min-height: calc(100vh - var(--mobile-nav-bar-height));
`;
