import McuManagerBleTransport from "../ble/McuManagerBleTransport";
import McuManagerResponse, {
  McuManagerResponseType,
} from "../ble/McuManagerResponse";
import McuManagerImage from "../image/McuManagerImage";
import DefaultManager from "../managers/DefaultManager";
import ImageManager, {
  ImageManagerListResponse,
  ImageSlot,
} from "../managers/ImageManager";
import { UploadCallback } from "../transfer/Upload";
import * as u from "../utils";
import FirmwareUpgradeCallback from "./FirmwareUpgradeCallback";

export enum FirmwareUpgradeMode {
  // This mode is the default and recommended mode for performing upgrades due to
  // its ability to recover from a bad firmware upgrade. The process for this mode
  // is UPLOAD, TEST, RESET, CONFIRM.
  TEST_AND_CONFIRM,

  // Useful if you want to run tests on the new image running before confirming it
  // manually as the primary boot image. The process for this mode is UPLOAD, TEST,
  // RESET.
  TEST_ONLY,

  // This mode is not recommended. If the device fails to boot into the new image,
  // it will not be able to recover and will need to be re-flashed. The process for
  // this mode is UPLOAD, CONFIRM, RESET.
  // TODO remove CONFIRM_ONLY?
  CONFIRM_ONLY,
}

export enum FirmwareUpgradeState {
  NONE,
  VALIDATE,
  UPLOAD,
  TEST,
  RESET,
  CONFIRM,
  SUCCESS,
}

const MAX_CONFIRM_ATTEMPTS = 2;

export default class FirmwareUpgradeManager {
  confirmAttempt = 0;
  defaultManager: DefaultManager;
  imageData: Uint8Array;
  imageHash: Uint8Array;
  imageManager: ImageManager;
  mode: FirmwareUpgradeMode;
  paused: boolean;
  state: FirmwareUpgradeState;
  uiCallback: FirmwareUpgradeCallback;

  constructor(
    transport: McuManagerBleTransport,
    imageData: Uint8Array,
    mode: FirmwareUpgradeMode,
    uiCallback: FirmwareUpgradeCallback
  ) {
    this.defaultManager = new DefaultManager(transport);
    this.imageData = imageData;
    this.imageHash = McuManagerImage.getHash(imageData);
    this.imageManager = new ImageManager(transport);
    this.mode = mode;
    this.state = FirmwareUpgradeState.NONE;
    this.paused = false;
    this.uiCallback = uiCallback;
  }

  start(): Promise<void> {
    if (this.state !== FirmwareUpgradeState.NONE) {
      throw new Error("Firmware upgrade is already in progress");
    }

    return this.validate();
  }

  fail = (error: Error): void => {
    const failedState = this.state;
    this.state = FirmwareUpgradeState.NONE;
    this.paused = false;
    this.internalCallback.onUpgradeFailed(failedState, error);
  };

  canceled(state: FirmwareUpgradeState): void {
    this.state = FirmwareUpgradeState.NONE;
    this.paused = false;
    this.internalCallback.onUpgradeCanceled(state);
  }

  setState(newState: FirmwareUpgradeState): void {
    if (newState !== this.state) {
      this.internalCallback.onStateChanged(this.state, newState);
    }
    this.state = newState;
  }

  async validate(): Promise<void> {
    this.setState(FirmwareUpgradeState.VALIDATE);
    const listResponse: ImageManagerListResponse =
      await this.imageManager.list();

    if (!McuManagerResponse.isSuccess(listResponse)) {
      throw new Error(`Error fetching list of images: rc=${listResponse.rc}`);
    }

    // Hacky extraction of the images.
    // Connect Browser has trouble with a properly typed version of this code.
    let images: ImageSlot[];
    try {
      images = Object.entries(listResponse)[0][1] as ImageSlot[];
      if (!images) throw new Error();
    } catch (e) {
      throw new Error("Missing images");
    }

    // Check if the new firmware is equal to the active one.
    if (
      images.length > 0 &&
      u.areByteArraysEqual(this.imageHash, images[0].hash)
    ) {
      if (images[0].confirmed) {
        // The new firmware is already active and confirmed.
        // No need to do anything.
        return this.success("Firmware is already installed!");
      }

      // The new firmware is in test mode.
      switch (this.mode) {
        case FirmwareUpgradeMode.CONFIRM_ONLY:
        case FirmwareUpgradeMode.TEST_AND_CONFIRM:
          // We have to confirm it.
          return this.confirm();
        case FirmwareUpgradeMode.TEST_ONLY:
          // Nothing to be done.
          return this.success("Firmware is already installed!");
      }
    }

    // If the image in slot 1 is confirmed, we can't erase or upload the image.
    // Must confirm the image in slot 0 and re-validate the image state.
    if (images.length > 1 && images[1].confirmed) {
      try {
        const response = await this.imageManager.confirm(images[0].hash);

        if (!McuManagerResponse.isSuccess(response)) {
          return this.fail(
            new Error(`Confirm request failed: rc=${response.rc}`)
          );
        }

        return this.validate();
      } catch (e) {
        return this.fail(e as Error);
      }
    }

    // If the image in slot 1 is pending, we won't be able to erase, upload or test the
    // image. Therefore, We must reset the device and revalidate the new image state.
    if (images.length > 1 && images[1].pending) {
      // Send reset command without changing state.
      return this.defaultManager
        .reset()
        .then(this.onResetResponse)
        .catch(this.fail);
    }

    // Check if the new firmware was already sent.
    if (
      images.length > 1 &&
      u.areByteArraysEqual(this.imageHash, images[1].hash)
    ) {
      // Image is identical to image in slot 1. No need to send anything.
      // If the test or confirm commands were not sent, proceed with next state.
      if (!images[1].pending) {
        switch (this.mode) {
          case FirmwareUpgradeMode.TEST_AND_CONFIRM:
          case FirmwareUpgradeMode.TEST_ONLY:
            return this.test();
          case FirmwareUpgradeMode.CONFIRM_ONLY:
            return this.confirm();
        }
      }

      // If image was already confirmed, reset (if confirm was planned), or fail.
      if (images[1].permanent) {
        switch (this.mode) {
          case FirmwareUpgradeMode.CONFIRM_ONLY:
          case FirmwareUpgradeMode.TEST_AND_CONFIRM:
            // If confirm command was sent, just reset.
            return this.reset();
          case FirmwareUpgradeMode.TEST_ONLY:
            return this.fail(
              new Error("Image already confirmed. Can't be tested.")
            );
        }
      }

      // If image was not confirmed, but test command was sent, confirm or reset.
      switch (this.mode) {
        case FirmwareUpgradeMode.CONFIRM_ONLY:
          return this.confirm();
        case FirmwareUpgradeMode.TEST_AND_CONFIRM:
        case FirmwareUpgradeMode.TEST_ONLY:
          return this.reset();
      }
    }

    // Validation successful, begin image upload.
    return this.upload();
  }

  async upload(): Promise<void> {
    this.setState(FirmwareUpgradeState.UPLOAD);

    if (this.paused) return;

    return this.imageManager.uploadImage(
      this.imageData,
      this.uploadImageCallback
    );
  }

  success(message: string): void {
    this.setState(FirmwareUpgradeState.NONE);
    this.paused = false;
    this.internalCallback.onUpgradeCompleted(message);
  }

  async test(): Promise<void> {
    this.setState(FirmwareUpgradeState.TEST);

    if (this.paused) return;

    return this.imageManager
      .test(this.imageHash)
      .then(this.onTestResponse)
      .catch(this.fail);
  }

  async confirm(): Promise<void> {
    this.setState(FirmwareUpgradeState.CONFIRM);

    if (this.paused) return;

    return this.imageManager
      .confirm(this.imageHash)
      .then(this.onConfirmResponse)
      .catch(this.onConfirmError);
  }

  async verify(): Promise<void> {
    this.setState(FirmwareUpgradeState.CONFIRM);

    if (this.paused) return;

    this.imageManager
      .confirm(undefined)
      .then(this.onConfirmResponse)
      .catch(this.onConfirmError);
  }

  async reset(): Promise<void> {
    this.setState(FirmwareUpgradeState.RESET);

    if (this.paused) return;

    return this.defaultManager
      .reset()
      .then(this.onResetResponse)
      .catch(this.fail);
  }

  // Callbacks.

  onTestResponse = async (
    response: ImageManagerListResponse
  ): Promise<void> => {
    // Check for an error return code
    if (!McuManagerResponse.isSuccess(response)) {
      this.fail(new Error("test request failed: rc=" + response.rc));
      return;
    }

    if (response.images.length !== 2) {
      this.fail(new Error("Test response does not contain enough info"));
      return;
    }

    if (!response.images[1].pending) {
      this.fail(new Error("Tested image is not in a pending state."));
      return;
    }

    // Test image success, begin device reset.
    return this.reset();
  };

  onConfirmResponse = (response: ImageManagerListResponse): void => {
    this.confirmAttempt = 0;

    if (!McuManagerResponse.isSuccess(response)) {
      this.fail(new Error(`Confirm request failed: rc=${response.rc}`));
      return;
    }

    if (response.images.length === 0) {
      this.fail(new Error("Confirm response does not contain images"));
      return;
    }

    // Handle the response based on mode.
    switch (this.mode) {
      case FirmwareUpgradeMode.CONFIRM_ONLY:
        // Check that an image exists in slot 1
        if (response.images.length !== 2) {
          this.fail(new Error("Confirm response does not contain enough info"));
          return;
        }

        // Check that the upgrade image has been confirmed
        if (!response.images[1].pending) {
          this.fail(new Error("Image is not in a confirmed state."));
          return;
        }

        // Reset the device, we don't want to do anything more.
        this.reset();
        break;

      case FirmwareUpgradeMode.TEST_AND_CONFIRM:
        // Check that the upgrade image has successfully booted
        if (!u.areByteArraysEqual(this.imageHash, response.images[0].hash)) {
          this.fail(new Error("Device failed to boot into new image"));
          return;
        }

        // Check that the upgrade image has been confirmed
        if (!response.images[0].confirmed) {
          this.fail(new Error("Image is not in a confirmed state."));
          return;
        }

        // The device has been tested and confirmed.
        this.success("Installation complete!");
        break;
    }
  };

  onConfirmError = (e: Error): void => {
    // The confirm request might have been sent after the device was rebooted
    // and the images were swapped. Swapping images, depending on the hardware,
    // might take a long time, during which the phone may throw 133 error as a
    // timeout. In such case we should try again.
    if (this.confirmAttempt++ < MAX_CONFIRM_ATTEMPTS) {
      console.log("Connection timeout. Retrying...");
      this.verify();
      return;
    }

    this.fail(e);
  };

  onResetResponse = (response: McuManagerResponseType): void => {
    if (!McuManagerResponse.isSuccess(response)) {
      return this.fail(new Error("Reset failed: rc=" + response.rc));
    }
  };

  // TODO refactor away from this namespacing
  internalCallback: FirmwareUpgradeCallback = {
    onStateChanged: (
      prevState: FirmwareUpgradeState,
      newState: FirmwareUpgradeState
    ): void => {
      if (!this.uiCallback) return;
      this.uiCallback.onStateChanged(prevState, newState);
    },

    onUpgradeCanceled: () => {},

    onUpgradeCompleted: (message: string) => {
      if (!this.uiCallback) return;
      this.uiCallback.onUpgradeCompleted(message);
    },

    onUpgradeFailed: (state: FirmwareUpgradeState, error: Error) => {
      if (!this.uiCallback) return;
      this.uiCallback.onUpgradeFailed(this.state, error);
    },

    onUpgradeStarted: (): void => {
      if (!this.uiCallback) return;
      this.uiCallback.onUpgradeStarted();
    },

    onUploadProgressChanged: (current: number, total: number): void => {
      if (!this.uiCallback) return;
      this.uiCallback.onUploadProgressChanged(current, total);
    },
  };

  uploadImageCallback: UploadCallback = {
    onUploadCanceled: (): void => {
      this.canceled(FirmwareUpgradeState.UPLOAD);
    },

    onUploadCompleted: (): Promise<void> => {
      switch (this.mode) {
        case FirmwareUpgradeMode.TEST_ONLY:
        case FirmwareUpgradeMode.TEST_AND_CONFIRM:
          return this.test();
        case FirmwareUpgradeMode.CONFIRM_ONLY:
          return this.confirm();
      }
    },

    onUploadFailed: (error: Error): void => {
      this.fail(error);
    },

    onUploadProgressChanged: (current: number, total: number): void => {
      this.internalCallback.onUploadProgressChanged(current, total);
    },
  };
}
