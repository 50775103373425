import { connect } from "react-redux";

import * as s from "../../device/selectors";
import * as t from "../../main/types";

import { startEraProSession, stopEraProSession } from "../actions";
import {
  EraProDoseControlMobile,
  StateProps,
} from "../components/EraProDoseControlMobile";

const mapStateToProps = (state: t.MainAppState): StateProps => ({
  isDeviceLocked: s.getIsDeviceLocked(state),
  isPodInserted: s.getIsPodInserted(state),
  session: s.getSession(state),
});

const mapDispatchToProps = {
  onStartEraProSession: startEraProSession,
  onStopEraProSession: stopEraProSession,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EraProDoseControlMobile);
