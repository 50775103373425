import React from "react";
import { isMobile } from "react-device-detect";
import { createSelector } from "reselect";

import * as PaxBle from "../../../../pax-ble";

import { getUserNeedsVerification } from "../../../api/consumer/selectors/user";
import { getConnectedDevice } from "../../../bluetooth/connectedDevice";
import { getHasEncryptionErrors } from "../../../device/selectors";
import { EncryptionErrorBanner } from "../../../era-pro/components/EncryptionErrorBanner";
import FirmwareUpdateBanner from "../../../firmware/containers/FirmwareUpdateBanner";
import * as firmwareSelectors from "../../../firmware/selectors";
import SsoEmailVerificationBanner from "../../../sso/containers/SsoEmailVerificationBanner";

import { BetaAppBanner } from "../BetaAppBanner";
import { FlashBanner } from "../FlashBanner";
import { getShouldShowFlashBanner } from "../FlashBanner/selectors";

export const getPrioritizedBanner = createSelector(
  [
    getConnectedDevice,
    getHasEncryptionErrors,
    firmwareSelectors.getIsNewerFirmwareAvailable,
    firmwareSelectors.getShouldShowBetaBanner,
    getShouldShowFlashBanner,
    getUserNeedsVerification,
  ],
  (
    connectedDevice: PaxBle.BaseDevice | undefined,
    hasEncryptionErrors: boolean,
    isNewerFirmwareAvailable: boolean,
    showBetaBanner: boolean,
    showFlashBanner: boolean,
    userNeedsVerification: boolean
  ): React.ReactElement | null => {
    const isEraPro = connectedDevice?.type === PaxBle.Types.DeviceType.ERA_PRO;

    if (showFlashBanner) return <FlashBanner centerMessage={isMobile} />;
    if (hasEncryptionErrors && connectedDevice && isEraPro)
      return <EncryptionErrorBanner />;
    if (isNewerFirmwareAvailable && connectedDevice)
      return <FirmwareUpdateBanner />;
    if (showBetaBanner && connectedDevice) return <BetaAppBanner />;
    if (userNeedsVerification) return <SsoEmailVerificationBanner />;
    return null;
  }
);
