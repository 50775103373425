import React from "react";
import styled from "styled-components";

import { StrainRating } from "../../api/consumer/types/reviews";
import { useStrainById } from "../../pods/hooks";
import { Column } from "../../shared/components/Flex";
import { PartnerLogo } from "../../shared/components/PartnerLogo";
import * as Text from "../../shared/components/Text";
import { Toast } from "../../shared/components/Toast";
import * as text from "../../shared/text";

import { StarRating } from "./StarRating";

type PodFeedbackToastProps = {
  strainId: string;
  onClick?: () => unknown;
  onStarClick?: (rating: StrainRating) => unknown;
};

export const PodFeedbackToast: React.FC<PodFeedbackToastProps> = ({
  strainId,
  onStarClick,
  ...props
}) => {
  const { strain } = useStrainById(strainId);

  if (!strain) return null;

  return (
    <Toast {...props}>
      <div>
        <sc.PartnerLogo partner={strain.partner} />
      </div>
      <sc.TextContainer>
        <sc.Title>{text.HOW_DID_YOU_LIKE_THIS_POD}</sc.Title>
        <sc.StarRating onChange={onStarClick} outlinedStars showZeroStars />
      </sc.TextContainer>
    </Toast>
  );
};

const sc = {
  PartnerLogo: styled(PartnerLogo)`
    height: 70px;
    margin-right: 16px;
    width: 70px;
  `,

  StarRating: styled(StarRating)`
    margin-top: 16px;

    & svg {
      height: 20px;
      margin-right: 20px;
      width: 20px;
    }

    & svg path {
      stroke: var(--white);
      stroke-width: 2px;
    }

    & svg:hover path,
    & svg:hover ~ svg path {
      fill: var(--white);
    }
  `,

  TextContainer: styled(Column)`
    justify-content: center;
  `,

  Title: styled.div`
    ${Text.PlaaxBold}
    font-size: 14px;
    letter-spacing: -0.105px;
    line-height: 20px;
  `,
};
