// TODO: remove use of any
/* eslint-disable @typescript-eslint/no-explicit-any */
import { History } from "history";
import React from "react";

import * as analytics from "../analytics";
import * as announcementActions from "../announcements/actions";
import * as consumerApi from "../api/consumer";
import { ApiError } from "../api/consumer/types";
import * as cookies from "../cookies";
import { Action } from "../shared/types";
import * as p from "./paths";
import * as t from "./types";

export const SSO_CREATE_ACCOUNT_FAILURE = "SSO_CREATE_ACCOUNT_FAILURE";
export const SSO_CREATE_ACCOUNT_SUCCESS = "SSO_CREATE_ACCOUNT_SUCCESS";
export const SSO_FORGOT_PASSWORD_FAILURE = "SSO_FORGOT_PASSWORD_FAILURE";
export const SSO_FORGOT_PASSWORD_SUCCESS = "SSO_FORGOT_PASSWORD_SUCCESS";
export const SSO_LOGIN_FAILURE = "SSO_LOGIN_FAILURE";
export const SSO_LOGIN_SUCCESS = "SSO_LOGIN_SUCCESS";
export const SSO_RESET_PAGE = "SSO_RESET_PAGE";
export const SSO_RESET_PASSWORD_FAILURE = "SSO_RESET_PASSWORD_FAILURE";
export const SSO_RESET_PASSWORD_SUCCESS = "SSO_RESET_PASSWORD_SUCCESS";
export const SSO_SEND_EMAIL_VERIFICATION_FAILURE =
  "SSO_SEND_EMAIL_VERIFICATION_FAILURE";
export const SSO_SEND_EMAIL_VERIFICATION_SUCCESS =
  "SSO_SEND_EMAIL_VERIFICATION_SUCCESS";
export const SSO_SIGN_OUT = "SSO_SIGN_OUT";
export const SSO_UPDATE_PAGE_STATE = "SSO_UPDATE_PAGE_STATE";
export const SSO_UPDATE_USER_FAILURE = "SSO_UPDATE_USER_FAILURE";
export const SSO_UPDATE_USER_SUCCESS = "SSO_UPDATE_USER_SUCCESS";
export const SSO_VERIFY_EMAIL_FAILURE = "SSO_VERIFY_EMAIL_FAILURE";
export const SSO_VERIFY_EMAIL_SUCCESS = "SSO_VERIFY_EMAIL_SUCCESS";

export type CreateAccount = (
  request: t.CreateAccountRequest,
  fetch?: consumerApi.Fetch,
  trackSignUp?: analytics.sso.TrackSignup
) => (dispatch: React.Dispatch<Action>) => any;

export const createAccount: CreateAccount =
  (
    request,
    fetch = consumerApi.fetch,
    trackSignup = analytics.sso.trackSignup
  ) =>
  async (dispatch): Promise<void> => {
    try {
      await fetch<consumerApi.types.user.UserResponse>(p.registeredUsers(), {
        data: request,
        method: "POST",
      });

      await trackSignup({ isSuccess: true });

      dispatch({
        payload: {},
        type: SSO_CREATE_ACCOUNT_SUCCESS,
      });

      // Sign up responses also set the login cookie.
      dispatch(receivedLoginSuccess());
    } catch (error) {
      const code =
        (error as ApiError).errorCode ??
        consumerApi.types.ErrorCode.UNKNOWN_ERROR;

      await trackSignup({ error: code });

      dispatch({
        payload: { code },
        type: SSO_CREATE_ACCOUNT_FAILURE,
      });
    }
  };

export type ForgotPassword = (
  request: t.ForgotPasswordRequest,
  fetch?: consumerApi.Fetch
) => (dispatch: React.Dispatch<Action>) => any;

export const forgotPassword: ForgotPassword =
  (request: t.ForgotPasswordRequest, fetch = consumerApi.fetch) =>
  async (dispatch): Promise<void> => {
    try {
      await fetch(p.resetPasswordRequest(), {
        data: {
          email: request.email,
          returnTo: request.returnTo,
        },
        method: "POST",
      });
      dispatch({
        payload: {},
        type: SSO_FORGOT_PASSWORD_SUCCESS,
      });
    } catch (error) {
      dispatch({
        payload: {
          code:
            (error as ApiError).errorCode ||
            consumerApi.types.ErrorCode.UNKNOWN_ERROR,
        },
        type: SSO_FORGOT_PASSWORD_FAILURE,
      });
    }
  };

export const receivedLoginSuccess = (): Action => ({
  payload: {},
  type: SSO_LOGIN_SUCCESS,
});

export type Login = (
  request: t.LoginRequest,
  fetch?: consumerApi.Fetch,
  trackLogin?: analytics.sso.TrackLogin
) => (dispatch: React.Dispatch<Action>) => any;

export const login: Login =
  (request, fetch = consumerApi.fetch, trackLogin = analytics.sso.trackLogin) =>
  async (dispatch): Promise<void> => {
    try {
      await fetch<consumerApi.types.user.UserResponse>(p.login(), {
        data: {
          anonymousUuid: request.anonymousUuid,
          email: request.email,
          password: request.password,
        },
        method: "POST",
      });

      await trackLogin({
        isSuccess: true,
      });

      dispatch(receivedLoginSuccess());
    } catch (error) {
      const code =
        (error as ApiError).errorCode ??
        consumerApi.types.ErrorCode.UNKNOWN_ERROR;

      await trackLogin({
        error: code,
      });

      dispatch({
        payload: { code },
        type: SSO_LOGIN_FAILURE,
      });
    }
  };

export const receivedResetPasswordSuccess = (): Action => ({
  payload: {},
  type: SSO_RESET_PASSWORD_SUCCESS,
});

export type ResetPassword = (
  request: t.ResetPasswordRequest,
  fetch?: consumerApi.Fetch
) => (dispatch: React.Dispatch<Action>) => any;

export const resetPassword: ResetPassword =
  (request: t.ResetPasswordRequest, fetch = consumerApi.fetch) =>
  async (dispatch): Promise<void> => {
    try {
      await fetch(p.newPassword(), {
        data: {
          password: request.password,
          token: request.token,
        },
        method: "POST",
      });
      dispatch(
        updateSsoPageState(t.SsoPage.forgotPassword, {
          initialEmail: request.email,
        })
      );
      dispatch(receivedResetPasswordSuccess());
    } catch (error) {
      dispatch({
        payload: {
          code:
            (error as ApiError).errorCode ||
            consumerApi.types.ErrorCode.UNKNOWN_ERROR,
        },
        type: SSO_RESET_PASSWORD_FAILURE,
      });
    }
  };

type ResetSsoPage = (page: t.SsoPage) => Action;

export const resetSsoPage: ResetSsoPage = (page) => ({
  payload: { page },
  type: SSO_RESET_PAGE,
});

export type SendEmailVerificationRequest = (
  request: t.EmailVerificationRequest,
  fetch?: consumerApi.Fetch,
  trackSignUp?: analytics.sso.TrackSignup
) => (dispatch: React.Dispatch<Action>) => any;

export const sendEmailVerificationRequest: SendEmailVerificationRequest =
  (request, fetch = consumerApi.fetch) =>
  async (dispatch): Promise<void> => {
    try {
      await fetch(p.emailVerificationRequests(), {
        data: {
          email: request.email,
          returnTo: request.returnTo,
        },
        method: "POST",
      });
      dispatch({
        payload: {},
        type: SSO_SEND_EMAIL_VERIFICATION_SUCCESS,
      });
    } catch (error) {
      dispatch({
        payload: {
          code:
            (error as ApiError).errorCode ||
            consumerApi.types.ErrorCode.UNKNOWN_ERROR,
        },
        type: SSO_SEND_EMAIL_VERIFICATION_FAILURE,
      });
    }
  };

type SsoSignOut = (
  history?: History,
  redirectTo?: string,
  fetch?: consumerApi.Fetch
) => (dispatch: React.Dispatch<Action>) => any;

export const ssoSignOut: SsoSignOut =
  (history, redirectTo, fetch = consumerApi.fetch) =>
  async (dispatch): Promise<void> => {
    // Delete anonymous token first to avoid race condition with useUser that results in using stale anonymous tokens
    if (cookies.getAnonymousUserCookie()) {
      await fetch(p.logoutAnonymousUser(), { method: "POST" });
      cookies.deleteAnonymousCookie();
    }

    if (cookies.getLoginCookie()) {
      await fetch(p.logoutRegisteredUser(), { method: "POST" });
      cookies.deleteLoginCookie();
    }

    dispatch(consumerApi.actions.resetState());
    dispatch(announcementActions.resetState());
    dispatch({ type: SSO_SIGN_OUT });

    if (history && redirectTo) {
      history.push(redirectTo);
    }
  };

export const updateUser: consumerApi.actions.users.UpdateUser =
  (updates) =>
  async (dispatch, getState): Promise<void> => {
    try {
      await consumerApi.actions.users.updateUser(updates)(
        dispatch,
        getState,
        null
      );

      dispatch({
        type: SSO_UPDATE_USER_SUCCESS,
      });
    } catch (error) {
      dispatch({
        payload: {
          code:
            (error as ApiError).errorCode ||
            consumerApi.types.ErrorCode.UNKNOWN_ERROR,
        },
        type: SSO_UPDATE_USER_FAILURE,
      });
    }
  };

export type VerifyEmail = (
  token: string,
  fetch?: consumerApi.Fetch
) => (dispatch: React.Dispatch<Action>) => any;

export const verifyEmail: VerifyEmail =
  (token, fetch = consumerApi.fetch) =>
  async (dispatch): Promise<void> => {
    try {
      await fetch(p.emailVerifications(), {
        data: {
          token,
        },
        method: "POST",
      });
      dispatch({
        payload: {},
        type: SSO_VERIFY_EMAIL_SUCCESS,
      });
    } catch (error) {
      dispatch({
        payload: {
          code:
            (error as ApiError).errorCode ||
            consumerApi.types.ErrorCode.UNKNOWN_ERROR,
        },
        type: SSO_VERIFY_EMAIL_FAILURE,
      });
    }
  };

export const updateSsoPageState = (
  page: t.SsoPage,
  pageUpdate: Partial<t.SsoPageState>
): Action => ({
  payload: {
    page,
    pageUpdate,
  },
  type: SSO_UPDATE_PAGE_STATE,
});
