import { createSelector } from "reselect";

import { navigator } from "../../navigator";
import { DeviceType } from "../../pax-ble/types";

import { getUser } from "../api/consumer/selectors/user";
import { UserType } from "../api/consumer/types/user";
import * as deviceSelectors from "../device/selectors";
import { getIsNewerFirmwareAvailable } from "../firmware/selectors";
import { MainAppState } from "../main/types";
import { SyncingNotification } from "./components/SyncingNotification";
import EmailVerificationNotification from "./containers/EmailVerificationNotification";
import FirmwareNotification from "./containers/FirmwareNotification";
import { NotificationType } from "./types";

const getDeviceSyncingNotification = createSelector(
  [
    deviceSelectors.getSelectedDeviceType,
    deviceSelectors.getIsDeviceConnected,
    deviceSelectors.getIsDeviceSyncing,
  ],
  (deviceType, isDeviceConnected, isDeviceSyncing) => {
    if (
      deviceType === DeviceType.PAX_3 ||
      !isDeviceConnected ||
      !isDeviceSyncing
    )
      return;

    return {
      Notification: SyncingNotification,
      type: NotificationType.DEVICE_SYNCING,
    };
  }
);

const getEmailVerificationNotification = createSelector(
  [getUser],
  (user): NotificationObject | undefined => {
    if (user.type !== UserType.REGISTERED || user.hasVerifiedEmail) return;

    return {
      Notification:
        EmailVerificationNotification as unknown as React.FC<unknown>,
      type: NotificationType.EMAIL_VERIFICATION,
    };
  }
);

const getFirmwareUpdateNotification = createSelector(
  [getIsNewerFirmwareAvailable, deviceSelectors.getIsDeviceConnected],
  (isNewerFirmwareAvailable, isDeviceConnected) => {
    if (!isDeviceConnected || !isNewerFirmwareAvailable) return;

    return {
      Notification: FirmwareNotification,
      type: NotificationType.FIRMWARE_UPDATE,
    };
  }
);

export type NotificationObject = {
  Notification: React.FC;
  type: NotificationType;
};

export const getExploreAppNotifications = createSelector(
  [getEmailVerificationNotification],
  (emailVerificationNotification) => {
    const notifications = [emailVerificationNotification];

    return notifications.filter(Boolean) as NotificationObject[];
  }
);

export const getNotifications = createSelector(
  [
    getFirmwareUpdateNotification,
    getDeviceSyncingNotification,
    getExploreAppNotifications,
  ],
  (
    firmwareUpdateNotification,
    deviceSyncingNotification,
    exploreAppNotifications
  ) => {
    const notifications = [
      firmwareUpdateNotification,
      deviceSyncingNotification,
      ...exploreAppNotifications,
    ];
    return notifications.filter(Boolean) as NotificationObject[];
  }
);

export const getHasNotifications = (state: MainAppState): boolean => {
  const isBluetoothSupported = !!navigator.bluetooth;

  const notifications = isBluetoothSupported
    ? getNotifications(state)
    : getExploreAppNotifications(state);

  return notifications.length > 0;
};
