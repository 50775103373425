import { Action } from "../../../shared/types";

import * as a from "./actions";
import { BannerType } from "./FlashBanner";

const DEFAULT_BANNER_TIMEOUT = 5000;

export type FlashBannerState = {
  message: string | null;
  noTimeOut?: boolean;
  timeout?: number;
  type: BannerType;
};

const initialState: FlashBannerState = {
  message: null,
  type: BannerType.INFO,
};

export const getInitialState = (
  overrides?: Partial<FlashBannerState>
): FlashBannerState => {
  return { ...initialState, ...overrides };
};

export const reducer = (
  state: FlashBannerState = initialState,
  action: Action
): FlashBannerState => {
  const { payload } = action;

  switch (action.type) {
    case a.SHOW_FLASH_BANNER:
      const {
        message,
        bannerType,
        noTimeOut,
        timeout = DEFAULT_BANNER_TIMEOUT,
      } = payload;
      return {
        ...state,
        message,
        noTimeOut,
        timeout,
        type: bannerType,
      };

    case a.CLEAR_FLASH_BANNER:
      return initialState;

    default:
      return state;
  }
};
