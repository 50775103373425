import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled, { css } from "styled-components";

import { getUserLocation } from "../../api/consumer/selectors/user";
import { Row } from "../../shared/components/Flex";
import { ChevronDown } from "../../shared/components/Icons";
import * as Text from "../../shared/components/Text";
import * as h from "../../shared/hooks";
import * as t from "../../shared/text";
import * as u from "../../shared/utils";

import { goToPodsSearchPage } from "../utils";
import { PodsFilterOpener } from "./PodsFilter/PodsFilterOpener";
import { PodsLocationModal } from "./PodsLocationModal";
import { PodsSearchPageOpener } from "./PodsSearchPageOpener";

export type PodsLocationHeaderProps = {
  useDesktopMediaQuery?: h.UseDesktopMediaQuery;
  handleSearchClick?: () => void;
};

export const PodsLocationHeader: React.FC<PodsLocationHeaderProps> = ({
  handleSearchClick,
  useDesktopMediaQuery = h.useDesktopMediaQuery,
}) => {
  const history = useHistory();
  const isDesktop = useDesktopMediaQuery();

  const handleSearchOpener = (): void => {
    handleSearchClick ? handleSearchClick() : goToPodsSearchPage(history, true);
  };

  const [isLocationModalOpen, setIsLocationModalOpen] =
    useState<boolean>(false);

  const location = useSelector(getUserLocation);
  const locationText = u.getLocationString({ location }) || t.SET_YOUR_LOCATION;

  const searchAndFilterOpeners = () =>
    !isDesktop && (
      <sc.Openers>
        <PodsFilterOpener />
        <PodsSearchPageOpener onSearchClick={handleSearchOpener} />
      </sc.Openers>
    );

  return (
    <>
      <sc.Container>
        <sc.InnerContainer>
          <sc.LocationRowHeading>{t.LOCATION}</sc.LocationRowHeading>
          <sc.LocationRow
            data-testid="pods-location-header"
            onClick={() => setIsLocationModalOpen(true)}
          >
            <sc.LocationText>{locationText}</sc.LocationText>
            <sc.ChevronDown />
          </sc.LocationRow>
        </sc.InnerContainer>
        {searchAndFilterOpeners()}
      </sc.Container>
      <PodsLocationModal
        isOpen={isLocationModalOpen}
        onRequestClose={() => setIsLocationModalOpen(false)}
      />
    </>
  );
};

const sc = {
  ChevronDown: styled(ChevronDown)`
    height: 13px;
    margin: 0 5px;
    min-width: 13px;
    width: 13px;
  `,

  Container: styled.div`
    ${Text.MediumLLBold}
    display: flex;
    justify-content: space-between;
    letter-spacing: 0.05em;
    padding: 9px 8px 10px 20px;
    text-transform: uppercase;
    width: 100%;

    ${u.desktopBreakpoint(
      css`
        border-left: solid 1px var(--very-light-pink);
        height: var(--top-nav-height);
        padding: 16px 20px 20px 32px;
        right: 64px;
        top: 62px;
        width: auto;
      `
    )};
  `,

  InnerContainer: styled.div`
    max-width: 70%;

    ${u.desktopBreakpoint(
      css`
        max-width: unset;
      `
    )};
  `,

  LocationRow: styled(Row)`
    justify-content: space-between;

    &:hover {
      cursor: pointer;
    }
  `,

  LocationRowHeading: styled.div`
    color: var(--grey-day);
    font-size: 13px;
    margin-bottom: 5px;
  `,

  LocationText: styled.div`
    font-size: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `,

  Openers: styled.div`
    display: flex;
    flex-direction: end;
    justify-self: end;
  `,
};
