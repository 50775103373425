import React from "react";
import styled from "styled-components";

import { ParsedTestResultJson } from "../../../api/consumer/types/strain";
import * as Card from "../../../shared/components/Card";
import * as Text from "../../../shared/components/Text";

import PodAutoTestResultsCard from "./PodAutoTestResultsCard";
import PodManualTestResultsCard from "./PodManualTestResultsCard";
import PodTestResultsCardNoPodSelected from "./PodTestResultsCardNoPodSelected";

const CARD_TITLE = "Pod Test Results";

export type PodTestResultsCardProps = {
  isDeviceLocked?: boolean;
  podId?: string;
  strainId?: string | null;
  testReport?: ParsedTestResultJson | null;
};

const PodTestResultsCard: React.FC<PodTestResultsCardProps> = ({
  isDeviceLocked,
  podId,
  strainId,
  testReport,
}) => {
  if (isDeviceLocked) {
    return (
      <Card.Small data-testid="locked-pod-test-results-card">
        <sc.TitleLocked>{CARD_TITLE}</sc.TitleLocked>
      </Card.Small>
    );
  }

  if (podId) {
    return <PodAutoTestResultsCard podId={podId} testReport={testReport} />;
  } else if (strainId) {
    return <PodManualTestResultsCard strainId={strainId} />;
  }

  return <PodTestResultsCardNoPodSelected />;
};

const sc = {
  TitleLocked: styled.h3`
    ${Text.Plaax}
    color: var(--very-light-pink);
    font-size: 20px;
    margin: 0 0 8px;
    text-transform: uppercase;
  `,
};

export default PodTestResultsCard;
