import React from "react";
import styled from "styled-components";

import * as Card from "../../../shared/components/Card";
import { Row } from "../../../shared/components/Flex";
import { PodSmallBlack } from "../../../shared/components/Icons";
import * as Text from "../../../shared/components/Text";

const PodTestResultsCardNoReport: React.FC = () => {
  return (
    <Card.Small data-testid="pod-test-results-card-no-report">
      <sc.TitleRow>
        <sc.Title>Pod Tested</sc.Title>
        <sc.PodSmallBlack
          checkmarkcolor="var(--teal-green)"
          podcolor="var(--brownish-grey)"
          size={22}
        />
      </sc.TitleRow>
      <sc.Text>Full test report not available yet</sc.Text>
    </Card.Small>
  );
};

const sc = {
  PodSmallBlack: styled(PodSmallBlack)`
    position: absolute;
    right: 46px;
  `,

  Text: styled.div`
    ${Text.PlaaxLight}
    color: var(--brownish-grey);
    font-size: 14px;
    line-height: 1;
  `,

  Title: styled.h3`
    ${Text.Plaax}
    color: var(--brownish-grey);
    font-size: 20px;
    margin: 0 0 8px;
    text-transform: uppercase;
  `,

  TitleRow: styled(Row)`
    justify-content: center;
    position: relative;
    width: 100%;
  `,
};

export default PodTestResultsCardNoReport;
