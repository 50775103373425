import React from "react";

import * as c from "../pods/constants";
import * as Icons from "../shared/components/Icons";
import * as ps from "../shared/components/PillSelect";
import * as utils from "../shared/utils";

export const getEffectOptions = (effects: string[]): ps.PillSelectOption[] => {
  const EFFECTS = [
    { Icon: Icons.Rest, value: c.REST },
    { Icon: Icons.Unwind, value: c.UNWIND },
    { Icon: Icons.Relief, value: c.RELIEF },
    { Icon: Icons.Focus, value: c.FOCUS },
    { Icon: Icons.Uplifted, value: c.UPLIFTED },
    { Icon: Icons.Energized, value: c.ENERGIZED },
  ];

  return EFFECTS.map(({ Icon, value }) => {
    const active = effects.includes(value);

    return {
      Icon: <Icon color={active ? "var(--white)" : "var(--black)"} />,
      active,
      label: utils.capitalize(value),
      value: value,
    };
  });
};
