import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { useQuery, UseQueryResult } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { InputActionMeta } from "react-select";

import { trackQRCodeScanned } from "../analytics/pods/qrCodeScanned";
import * as consumerApi from "../api/consumer";
import { getUserLocation } from "../api/consumer/selectors/user";
import * as apiTypes from "../api/consumer/types";
import { MainAppState } from "../main/types";
import * as flashBanner from "../shared/components/FlashBanner";
import { useDesktopMediaQuery } from "../shared/hooks";
import * as text from "../shared/text";
import { DropdownOption, MediaQueryChangeListener } from "../shared/types";
import { isWithinRange } from "../shared/utils";
import { buildStrainsFilter } from "./models/StrainsFilter";
import * as c from "./constants";
import { getActiveStrainFiltersCount, getStrainRows } from "./selectors";
import * as t from "./types";

export type UseBatchListFetch = (
  strainId?: string,
  usePendingReactQuery?: consumerApi.hooks.UsePendingReactQuery<
    consumerApi.types.CollectionResponse<consumerApi.types.TestResultJson>
  >
) => consumerApi.types.TestResultJson[];

export const useBatchListFetch: UseBatchListFetch = (
  strainId,
  usePendingReactQuery = consumerApi.hooks.usePendingReactQuery
) => {
  const path = consumerApi.paths.strains.strainBatchResults({
    page: 1,
    pageSize: 100,
    strainId: strainId || "",
  });

  const { data: batchList } = usePendingReactQuery({
    path,
    pendingCondition: strainId,
  });

  return batchList?.results || [];
};

export const usePodsPerCarousel = (): number => {
  const isDesktop = useDesktopMediaQuery();
  return isDesktop ? c.PODS_PER_CAROUSEL_DESKTOP : c.PODS_PER_CAROUSEL_MOBILE;
};

const MARGIN_BUFFER = 32;
const MARGIN_BUFFER_PLUS_STEP = MARGIN_BUFFER + 1;

/* eslint-disable sort-keys */
const BREAKPOINT_WIDTHS = {
  SMALL_MAX_WIDTH:
    c.RESPONSIVE_POD_ROW_WIDTH[t.ResponsiveScreenSize.MEDIUM] + MARGIN_BUFFER,
  MEDIUM_MIN_WIDTH:
    c.RESPONSIVE_POD_ROW_WIDTH[t.ResponsiveScreenSize.MEDIUM] +
    MARGIN_BUFFER_PLUS_STEP,
  MEDIUM_MAX_WIDTH:
    c.RESPONSIVE_POD_ROW_WIDTH[t.ResponsiveScreenSize.LARGE] + MARGIN_BUFFER,
  LARGE_MIN_WIDTH:
    c.RESPONSIVE_POD_ROW_WIDTH[t.ResponsiveScreenSize.LARGE] +
    MARGIN_BUFFER_PLUS_STEP,
  LARGE_MAX_WIDTH:
    c.RESPONSIVE_POD_ROW_WIDTH[t.ResponsiveScreenSize.X_LARGE] + MARGIN_BUFFER,
  X_LARGE_MIN_WIDTH:
    c.RESPONSIVE_POD_ROW_WIDTH[t.ResponsiveScreenSize.X_LARGE] +
    MARGIN_BUFFER_PLUS_STEP,
  X_LARGE_MAX_WIDTH:
    c.RESPONSIVE_POD_ROW_WIDTH[t.ResponsiveScreenSize.XX_LARGE] + MARGIN_BUFFER,
  DESKTOP_SMALL_MAX_WIDTH:
    c.RESPONSIVE_POD_ROW_WIDTH[t.ResponsiveScreenSize.DESKTOP_MEDIUM] +
    c.FILTER_SIDEBAR_WIDTH_WITH_MARGIN_BUFFER +
    MARGIN_BUFFER,
  DESKTOP_MEDIUM_MIN_WIDTH:
    c.RESPONSIVE_POD_ROW_WIDTH[t.ResponsiveScreenSize.DESKTOP_MEDIUM] +
    c.FILTER_SIDEBAR_WIDTH_WITH_MARGIN_BUFFER +
    MARGIN_BUFFER_PLUS_STEP,
  DESKTOP_MEDIUM_MAX_WIDTH:
    c.RESPONSIVE_POD_ROW_WIDTH[t.ResponsiveScreenSize.DESKTOP_LARGE] +
    c.FILTER_SIDEBAR_WIDTH_WITH_MARGIN_BUFFER +
    MARGIN_BUFFER,
  DESKTOP_LARGE_MIN_WIDTH:
    c.RESPONSIVE_POD_ROW_WIDTH[t.ResponsiveScreenSize.DESKTOP_LARGE] +
    c.FILTER_SIDEBAR_WIDTH_WITH_MARGIN_BUFFER +
    MARGIN_BUFFER_PLUS_STEP,
  DESKTOP_LARGE_MAX_WIDTH:
    c.RESPONSIVE_POD_ROW_WIDTH[t.ResponsiveScreenSize.DESKTOP_LARGE] +
    c.FILTER_SIDEBAR_WIDTH_WITH_MARGIN_BUFFER +
    MARGIN_BUFFER,
};
/* eslint-enable sort-keys */

/* eslint-disable sort-keys */
const BREAKPOINTS = {
  SMALL: `(max-width: ${BREAKPOINT_WIDTHS.SMALL_MAX_WIDTH}px)`,
  MEDIUM: `(min-width: ${BREAKPOINT_WIDTHS.MEDIUM_MIN_WIDTH}px) and (max-width: ${BREAKPOINT_WIDTHS.MEDIUM_MAX_WIDTH}px)`,
  LARGE: `(min-width: ${BREAKPOINT_WIDTHS.LARGE_MIN_WIDTH}px) and (max-width: ${BREAKPOINT_WIDTHS.LARGE_MAX_WIDTH}px)`,
  X_LARGE: `(min-width: ${BREAKPOINT_WIDTHS.X_LARGE_MIN_WIDTH}px) and (max-width: ${BREAKPOINT_WIDTHS.X_LARGE_MAX_WIDTH}px)`,
  XX_LARGE: `(min-width: ${BREAKPOINT_WIDTHS.X_LARGE_MAX_WIDTH}px)`,
  DESKTOP_SMALL: `(max-width: ${BREAKPOINT_WIDTHS.DESKTOP_SMALL_MAX_WIDTH}px)`,
  DESKTOP_MEDIUM: `(min-width: ${BREAKPOINT_WIDTHS.DESKTOP_MEDIUM_MIN_WIDTH}px) and (max-width: ${BREAKPOINT_WIDTHS.DESKTOP_MEDIUM_MAX_WIDTH}px)`,
  DESKTOP_LARGE: `(min-width: ${BREAKPOINT_WIDTHS.DESKTOP_LARGE_MIN_WIDTH}px)`,
};
/* eslint-enable sort-keys */

const getInitialScreenSize = (
  innerWidth: number,
  isDesktop: boolean
): t.ResponsiveScreenSize => {
  let initialScreenSize = isDesktop
    ? t.ResponsiveScreenSize.DESKTOP_SMALL
    : t.ResponsiveScreenSize.SMALL;

  //check mobile screen sizes
  if (!isDesktop) {
    if (
      isWithinRange(
        innerWidth,
        BREAKPOINT_WIDTHS.MEDIUM_MIN_WIDTH,
        BREAKPOINT_WIDTHS.MEDIUM_MAX_WIDTH
      )
    ) {
      initialScreenSize = t.ResponsiveScreenSize.MEDIUM;
    } else if (
      isWithinRange(
        innerWidth,
        BREAKPOINT_WIDTHS.LARGE_MIN_WIDTH,
        BREAKPOINT_WIDTHS.LARGE_MAX_WIDTH
      )
    ) {
      initialScreenSize = t.ResponsiveScreenSize.LARGE;
    } else if (
      isWithinRange(
        innerWidth,
        BREAKPOINT_WIDTHS.X_LARGE_MIN_WIDTH,
        BREAKPOINT_WIDTHS.X_LARGE_MAX_WIDTH
      )
    ) {
      initialScreenSize = t.ResponsiveScreenSize.X_LARGE;
    } else if (innerWidth > BREAKPOINT_WIDTHS.X_LARGE_MAX_WIDTH) {
      initialScreenSize = t.ResponsiveScreenSize.XX_LARGE;
    }
  } else {
    //check desktop screen sizes
    if (
      isWithinRange(
        innerWidth,
        BREAKPOINT_WIDTHS.DESKTOP_MEDIUM_MIN_WIDTH,
        BREAKPOINT_WIDTHS.DESKTOP_MEDIUM_MAX_WIDTH
      )
    ) {
      initialScreenSize = t.ResponsiveScreenSize.DESKTOP_MEDIUM;
    } else if (innerWidth > BREAKPOINT_WIDTHS.DESKTOP_LARGE_MIN_WIDTH) {
      initialScreenSize = t.ResponsiveScreenSize.DESKTOP_LARGE;
    }
  }

  return initialScreenSize;
};

export const useResponsiveScreenSize = (): t.ResponsiveScreenSize => {
  const isDesktop = useDesktopMediaQuery();
  const { innerWidth } = window;

  const initialScreenSize = getInitialScreenSize(innerWidth, isDesktop);

  const [screenSize, setScreenSize] =
    useState<t.ResponsiveScreenSize>(initialScreenSize);

  const getMediaQueryChangeHandler =
    (newScreenSize: t.ResponsiveScreenSize): MediaQueryChangeListener =>
    (event): void => {
      if (event.matches) setScreenSize(newScreenSize);
    };

  useEffect(() => {
    const breakpoints = isDesktop
      ? [
          [BREAKPOINTS.DESKTOP_SMALL, t.ResponsiveScreenSize.DESKTOP_SMALL],
          [BREAKPOINTS.DESKTOP_MEDIUM, t.ResponsiveScreenSize.DESKTOP_MEDIUM],
          [BREAKPOINTS.DESKTOP_LARGE, t.ResponsiveScreenSize.DESKTOP_LARGE],
        ]
      : [
          [BREAKPOINTS.SMALL, t.ResponsiveScreenSize.SMALL],
          [BREAKPOINTS.MEDIUM, t.ResponsiveScreenSize.MEDIUM],
          [BREAKPOINTS.LARGE, t.ResponsiveScreenSize.LARGE],
          [BREAKPOINTS.X_LARGE, t.ResponsiveScreenSize.X_LARGE],
          [BREAKPOINTS.XX_LARGE, t.ResponsiveScreenSize.XX_LARGE],
        ];

    const mediaQueriesAndListeners: [
      MediaQueryList,
      MediaQueryChangeListener
    ][] = breakpoints.map(([breakpoint, screenSize]) => [
      window.matchMedia(breakpoint),
      getMediaQueryChangeHandler(screenSize as t.ResponsiveScreenSize),
    ]);

    mediaQueriesAndListeners.forEach(([mediaQuery, changeHandler]) => {
      if (mediaQuery.addEventListener) {
        mediaQuery.addEventListener("change", changeHandler);
      } else {
        // addEventListener is not yet supported by Safari.
        mediaQuery.addListener(changeHandler);
      }
    });

    return (): void => {
      mediaQueriesAndListeners.forEach(([mediaQuery, changeHandler]) => {
        if (mediaQuery.removeEventListener) {
          mediaQuery.removeEventListener("change", changeHandler);
        } else {
          // removeEventListener is not yet supported by Safari.
          mediaQuery.removeListener(changeHandler);
        }
      });
    };
  }, [isDesktop]);

  return screenSize;
};

type ResponsiveStrainRowsOptions = {
  gridWidth: number;
  strainRows: consumerApi.types.StrainJson[][];
};

export type UseResponsiveStrainRows = () => ResponsiveStrainRowsOptions;

export const useResponsiveStrainRows: UseResponsiveStrainRows = () => {
  const screenSize = useResponsiveScreenSize();

  const gridWidth = c.RESPONSIVE_POD_ROW_WIDTH[screenSize];

  const strainRows = useSelector((state: MainAppState) =>
    getStrainRows(state, screenSize)
  );

  return { gridWidth, strainRows };
};

type RecommendedSearchesOptions = {
  isSuggestedQueries: boolean;
  recommendedSearches: string[];
  title: string;
};

export type UseRecommendedSearches = () => RecommendedSearchesOptions;

export const useRecommendedSearches: UseRecommendedSearches = () => {
  const recentSearches = useSelector(
    consumerApi.selectors.user.getUniqueRecentSearches
  );

  const { data: suggestedSearches } = consumerApi.hooks.useReactQuery<string[]>(
    {
      path: consumerApi.paths.pods.suggestedSearches(),
    }
  );

  const hasRecents = recentSearches.length > 0;
  const title = hasRecents ? text.RECENT_SEARCHES : text.SUGGESTED_SEARCHES;
  const searches = hasRecents
    ? recentSearches
    : suggestedSearches || c.SUGGESTED_SEARCHES_DEFAULTS;

  const recommendedSearches = searches.slice(0, c.MAX_RECOMMENDED_SEARCHES);

  return { isSuggestedQueries: !hasRecents, recommendedSearches, title };
};

export type UseStrainsForLocation = (
  filterPartial?: Partial<t.StrainsFilter>,
  pageSize?: number,
  usePendingReactQuery?: consumerApi.hooks.UsePendingReactQuery<
    consumerApi.types.CollectionResponse<consumerApi.types.StrainJson>
  >
) => UseQueryResult<
  | consumerApi.types.CollectionResponse<consumerApi.types.StrainJson>
  | undefined,
  Error
>;

export const useStrainsForLocation: UseStrainsForLocation = (
  filterPartial = {},
  pageSize = 10,
  usePendingReactQuery = consumerApi.hooks.usePendingReactQuery
) => {
  const location = useSelector(consumerApi.selectors.user.getUserLocation);

  const filter = buildStrainsFilter({
    ...filterPartial,
    orderBy: consumerApi.types.strain.OrderBy.RANDOM,
    region: location?.region,
    state: location?.isoCode,
  });

  const strainsPath = consumerApi.paths.strains.publicStrains({
    filter,
    page: 1,
    pageSize,
  });

  const queryState = usePendingReactQuery({
    path: strainsPath,
    pendingCondition: location,
  });

  return queryState;
};

export type UseUpsellPodsQuery = (
  path: string,
  usePendingReactQuery?: consumerApi.hooks.UsePendingReactQuery<
    consumerApi.types.CollectionResponse<consumerApi.types.StrainJson>
  >
) => {
  data: apiTypes.StrainJson[];
  isLoading: boolean;
  metaData?: apiTypes.strain.StrainMetaDataJson;
};

export const useUpsellPodsQuery: UseUpsellPodsQuery = (
  path,
  usePendingReactQuery = consumerApi.hooks.usePendingReactQuery
) => {
  const dispatch = useDispatch();
  const location = useSelector(getUserLocation);
  const podsPerCarousel = usePodsPerCarousel();

  const queryState = usePendingReactQuery({
    path,
    pendingCondition: location?.isoCode,
    shouldThrowError: true,
  });

  useEffect(() => {
    if (
      (queryState?.error as apiTypes.ApiError)?.errorCode !==
      apiTypes.ErrorCode.GEO_STATE_DOES_NOT_EXIST
    )
      return;

    dispatch(
      flashBanner.showFlashBanner(
        text.PAX_PODS_UNAVAILABLE_IN_LOCATION_PLEASE_TRY_DIFFERENT_LOCATION,
        flashBanner.BannerType.INFO
      )
    );
  }, [dispatch, queryState.error]);

  return {
    data: queryState.data?.results.slice(0, podsPerCarousel) || [],
    isLoading: queryState.isLoading,
    metaData: queryState.data?.meta,
  };
};

export type UseRecentlyViewedPods = () => {
  data: apiTypes.StrainJson[];
  isLoading: boolean;
};

export const useRecentlyViewedPods: UseRecentlyViewedPods = () => {
  const podsPerCarousel = usePodsPerCarousel();

  const recentlyViewedPods = useSelector(
    consumerApi.selectors.user.getUniqueRecentlyViewedPods
  ).slice(0, podsPerCarousel);

  const isFetchingUser = useSelector(
    consumerApi.selectors.user.getIsFetchingUser
  );

  return {
    data: recentlyViewedPods,
    isLoading: isFetchingUser,
  };
};

export type UseStrainByIdOptions = {
  isError: boolean;
  isLoading: boolean;
  strain: consumerApi.types.StrainJson | null;
};

export type UseStrainById = (
  strainId?: string | null,
  options?: { shouldShowErrorBanner: boolean },
  usePendingReactQuery?: consumerApi.hooks.UsePendingReactQuery<consumerApi.types.StrainJson>
) => UseStrainByIdOptions;

export const useStrainById: UseStrainById = (
  strainId,
  options,
  usePendingReactQuery = consumerApi.hooks.usePendingReactQuery
) => {
  const dispatch = useDispatch();

  const strain = useSelector((state: MainAppState) =>
    consumerApi.selectors.strains.getStrain(state, { strainId })
  );

  const { data, isError, isLoading } = usePendingReactQuery({
    path: consumerApi.paths.strains.strainById(strainId || ""),
    pendingCondition: strainId && !strain,
    shouldThrowError: options?.shouldShowErrorBanner,
    useQueryOptions: { retry: false },
  });

  useEffect(() => {
    if (!data) return;

    dispatch(consumerApi.actions.strains.receivedStrain(data));
  }, [data, dispatch]);

  useEffect(() => {
    if (!options?.shouldShowErrorBanner || !isError) return;

    dispatch(
      flashBanner.showFlashBanner(
        text.ERROR_FINDING_STRAIN,
        flashBanner.BannerType.ERROR
      )
    );
  }, [dispatch, isError, options?.shouldShowErrorBanner]);

  return { isError, isLoading, strain };
};

export const usePodQRCodeScanned = (
  strainId: string,
  wasQRCodeScanned: boolean
): void => {
  const dispatch = useDispatch();

  useQuery<consumerApi.types.connectedPods.ConnectedPodJson>(
    [consumerApi.paths.connectedPods.connectedPod(strainId), wasQRCodeScanned],
    {
      enabled: wasQRCodeScanned,
      onError: (error) => {
        const axiosError = error as AxiosError;

        if (axiosError.response?.status !== 404) {
          return;
        }
        // The user has not connected this pod previously, record the newly connected pod.

        dispatch(
          consumerApi.actions.connectedPods.recordConnectedPod(strainId, {
            wasScanned: wasQRCodeScanned,
          })
        );

        dispatch(
          trackQRCodeScanned(strainId, {
            hasReviewed: false,
            isFirstUsage: true,
          })
        );
      },
      onSuccess: (connectedPod) => {
        // The user has connected this pod previously, update our redux state with the new data.

        dispatch(
          consumerApi.actions.connectedPods.receivedConnectedPod(connectedPod)
        );

        dispatch(
          trackQRCodeScanned(strainId, {
            hasReviewed: !!connectedPod.latestReview,
            isFirstUsage: false,
          })
        );
      },
    }
  );
};

export type UsePodsMobileHeaderHandlersReturnTypes = {
  handleBackClick: () => void;
  handleClearClick: () => void;
  handleClearQueryFieldClick: () => void;
  handleEnterKeyDown: (e: React.KeyboardEvent) => void;
  handleInputChange: (value: string, { action }: InputActionMeta) => void;
  hideSearchField: () => void;
  menuIsOpen: boolean;
  newPendingQuery: string;
  onChange: (option: DropdownOption | null) => void;
  shouldShowQueryField: boolean;
  showSearchField: () => void;
};

export const useGetActiveStrainFilterCount = (): number => {
  const location = useLocation();

  return useSelector((state: MainAppState) =>
    getActiveStrainFiltersCount(state, { location })
  );
};

export type UseShouldShowPodsSearchHeaderProps = {
  getActiveStrainFiltersCount?: () => number;
  hasQuery: boolean;
  hideSearchField: () => void;
  isDesktop: boolean;
  resetAll: () => void;
};

export const useShouldShowPodsSearchHeader = ({
  getActiveStrainFiltersCount = useGetActiveStrainFilterCount,
  hasQuery,
  hideSearchField,
  isDesktop,
  resetAll,
}: UseShouldShowPodsSearchHeaderProps): boolean => {
  const [hasActiveFilters, setHasActiveFilters] = useState<boolean>(false);
  const [lastFilterCount, setLastFilterCount] = useState<number>(0);

  const activeStrainFiltersCount = getActiveStrainFiltersCount();

  useEffect(() => {
    if (activeStrainFiltersCount === lastFilterCount) return;

    hideSearchField();

    if (activeStrainFiltersCount === 0) {
      if (!hasQuery) {
        resetAll();
      }

      setHasActiveFilters(false);
    } else {
      setHasActiveFilters(true);
    }

    setLastFilterCount(activeStrainFiltersCount);
  }, [
    activeStrainFiltersCount,
    resetAll,
    hasActiveFilters,
    hasQuery,
    hideSearchField,
    lastFilterCount,
  ]);

  return (hasQuery || hasActiveFilters) && !isDesktop;
};
