export const ACCOUNT_WILL_RECEIVE_EMAIL =
  "If an account is associated with this email, you will receive an email.";

export const CHANGE_PASSWORD_LABEL = "Change your password";

export const CREATE_ACCOUNT = "Easy sign-in for all PAX platforms and apps";

export const CREATE_ACCOUNT_TITLE = "Create account";

export const EDIT_ACCOUNT_TITLE = "Update your account info";

export const EMAIL_LABEL = "Email";

export const EMAIL_LIST_SIGNUP = "I would like to receive emails from PAX";

export const EMAIL_NOT_RECEIVED =
  "Didn't receive an email? Double check the address below, check your spam folders, or resend the link!";

export const EMAIL_SENT_TITLE = "Email sent!";

export const EMAIL_SENT_CHECK_INBOX = "Email sent! Please check your inbox.";

export const FORGOT_PASSWORD = "Enter the email used for your account";

export const FORGOT_PASSWORD_LINK = "Forgot password?";

export const FORGOT_PASSWORD_TITLE = "Forgotten password";

export const LEGAL_NOTICE_1 =
  "By creating an account, you agree to the PAX Labs";

export const LEGAL_NOTICE_2 = "and";

export const NEW_PASSWORD_LABEL = "New Password";

export const PASSWORD_CONFIRMATION =
  "Please sign in with your new password and you'll be redirected to your experience…";

export const PASSWORD_CONFIRMATION_TITLE = "Password reset!";

export const PASSWORD_LABEL = "Password";

export const PASSWORD_PLACEHOLDER = "Enter a new password";

export const PASSWORD_RESET_REQUIRED_1 =
  "We've upgraded our site to enable new features and services. In order to proceed,";

export const PASSWORD_RESET_REQUIRED_2 =
  "This is a one time process. All of your order history and registrations will remain intact.";

export const PASSWORD_RESET_REQUIRED_3 =
  "Once complete, you can use your account to access";

export const PASSWORD_RESET_REQUIRED_4 = ", as well as our PAX App.";

export const PASSWORD_TOKEN_EXPIRED =
  "Uh oh! Looks like that reset link has expired. Resend the link to get a new one.";

export const PAX_LINK = "PAX.com";

export const PRIVACY_POLICY_LINK = "privacy policy";

export const REGION_LABEL = "Country";

export const RESET_PASSWORD = "Enter a new password for your account";

export const RESET_PASSWORD_BUTTON = "Reset Password";

export const RESET_PASSWORD_LINK = "please reset your password.";

export const RESET_PASSWORD_TITLE = "Reset your password";

export const SEND_AGAIN_BUTTON = "Send Again";

export const SIGN_IN_BUTTON = "Sign In";

export const SIGN_IN_LINK = "Already have an account? Sign In";

export const SIGN_UP_BUTTON = "Sign Up";

export const SIGN_UP_LINK = "Don’t have an account? Sign Up";

export const SKIP_LINK = "I’ll do this later";

export const TERMS_CONDITIONS_LINK = "terms and conditions";

export const TOKEN_EXPIRED_TITLE = "That link has expired";

export const UPDATE_BUTTON = "Update";

export const VERIFICATION_BANNER =
  "You have not verified your email, complete verification";

export const VERIFICATION_BANNER_CLICKED =
  "Please check your inbox to finish verifying your email";

export const VERIFICATION_BANNER_LINK = "here";

export const VERIFICATION_CONFIRMATION =
  "Redirecting you to your experience...";

export const VERIFICATION_CONFIRMATION_TITLE = "Email verified!";

export const VERIFICATION_TOKEN_EXPIRED =
  "Uh oh! Looks like that verification link has expired. Resend the link to get a new one.";

export const VERIFY_EMAIL_SENT_TITLE = "Verify your email";

export const VERIFY_EMAIL_SENT_TEXT_1 =
  "A verification email has been sent to the address below.";

export const VERIFY_EMAIL_SENT_TEXT_2 =
  "Didn't receive an email? Check your spam folder or resend the link.";
