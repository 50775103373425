import React, { FormEvent, useEffect, useState } from "react";
import styled from "styled-components";

import * as analytics from "../../analytics";
import * as apiTypes from "../../api/consumer/types";
import * as cookies from "../../cookies";
import * as r from "../../main/routes";
import { BannerType } from "../../shared/components/FlashBanner";
import { Row } from "../../shared/components/Flex";
import { PasswordInput } from "../../shared/components/PasswordInput";
import * as ps from "../../shared/components/PillSelect";
import * as Text from "../../shared/components/Text";
import { PRIVACY_POLICY_URL } from "../../shared/constants";
import * as sharedHooks from "../../shared/hooks";
import { SearchParam } from "../../shared/types";

import { CreateAccount } from "../actions";
import { SsoCountry } from "../constants";
import { useSsoPageReset } from "../hooks";
import * as text from "../text";
import { SsoPage, SsoProps } from "../types";
import * as utils from "../utils";
import { SsoChrome } from "./SsoChrome";
import { SsoCountrySelect } from "./SsoCountrySelect";
import { SsoErrorMessage } from "./SsoErrorMessage";
import * as SsoForm from "./SsoForm";

export type SsoCreateAccountPageProps = SsoProps & {
  anonymousUuid: string;
  createAccount: CreateAccount;
  failureCode?: apiTypes.ErrorCode;
  success?: boolean;
  trackSignup: analytics.sso.TrackSignup;
  useSearchParam: sharedHooks.UseSearchParam;
};

export const SsoCreateAccountPage: React.FC<SsoCreateAccountPageProps> = ({
  anonymousUuid,
  createAccount,
  failureCode,
  ssoApiReturnTo = utils.ssoApiReturnTo,
  ssoRedirect = utils.ssoRedirect,
  success,
  trackSignup = analytics.sso.trackSignup,
  useSearchParam = sharedHooks.useSearchParam,
}) => {
  const redirectTo = useSearchParam(SearchParam.REDIRECT) || r.home();
  const shouldCloseWindow = useSearchParam(SearchParam.CLOSE) || "false";
  const country =
    utils.getSsoCountry(useSearchParam(SearchParam.COUNTRY)) || SsoCountry.US;
  const returnTo = ssoApiReturnTo(redirectTo);
  const [hasInitialCountryChanged, setHasInitialCountryChanged] =
    useState<boolean>(true);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [selectedCountry, setSelectedCountry] = useState<SsoCountry>(country);
  const [isMailingListCheckboxChecked, setIsMailingListCheckboxChecked] =
    useState<boolean>(true);

  useEffect(() => {
    trackSignup({
      isStart: true,
      source: "web-sso-create-account-form",
    });
    // Only track isStart on initial mount.
    // eslint-disable-next-line
  }, [trackSignup]);

  useEffect(() => {
    if (hasInitialCountryChanged && selectedCountry !== SsoCountry.US) {
      setIsMailingListCheckboxChecked(false);
      setHasInitialCountryChanged(false);
    }
  }, [selectedCountry, hasInitialCountryChanged]);

  useSsoPageReset(SsoPage.createAccount);

  const handleCountryChange = (selectedOption: ps.PillSelectOption): void => {
    setSelectedCountry(selectedOption.value);
  };

  const handleMailingListCheckboxChange = (): void => {
    setIsMailingListCheckboxChecked(!isMailingListCheckboxChecked);
  };

  const handleFormSubmit = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault();

    const preferredTemperatureUnit =
      selectedCountry === SsoCountry.US
        ? apiTypes.user.PreferredTemperatureUnit.FAHRENHEIT
        : apiTypes.user.PreferredTemperatureUnit.CELSIUS;

    const profile: apiTypes.user.Profile = {
      isSubscribedToMailingList: isMailingListCheckboxChecked,
      preferredTemperatureUnit,
      region: selectedCountry,
    };

    const mailingList = isMailingListCheckboxChecked
      ? utils.getMailingListForCountry(selectedCountry)
      : undefined;

    createAccount({
      anonymousUuid,
      email,
      mailingList,
      password,
      profile,
      returnTo,
    });
  };

  if (success) {
    cookies.setBannerCookie("Sign up successful!", BannerType.SUCCESS);
    ssoRedirect(
      r.ssoEmailVerificationRequestSent({
        close: shouldCloseWindow,
        returnTo: redirectTo,
      })
    );
    return null;
  }

  return (
    <SsoChrome>
      <SsoForm.Form
        data-testid="create-account-form"
        onSubmit={handleFormSubmit}
      >
        <SsoForm.Field>
          <sc.Title>{text.CREATE_ACCOUNT_TITLE}</sc.Title>
          <SsoForm.Subtitle>{text.CREATE_ACCOUNT}</SsoForm.Subtitle>
        </SsoForm.Field>
        <sc.CountryField>
          <SsoForm.Label>{text.REGION_LABEL}</SsoForm.Label>
          <SsoCountrySelect
            onChange={handleCountryChange}
            value={selectedCountry}
          />
        </sc.CountryField>
        <SsoForm.Field>
          <SsoForm.Label htmlFor="email-input">
            {text.EMAIL_LABEL}
          </SsoForm.Label>
          <SsoForm.Input
            data-testid="email-input"
            hasError={utils.isUsernameInError(failureCode)}
            id="email-input"
            onChange={(e): void => setEmail(e.target.value)}
            required
            type="email"
          />
        </SsoForm.Field>
        <sc.PasswordField>
          <SsoForm.Label htmlFor="password-input">
            {text.PASSWORD_LABEL}
          </SsoForm.Label>
          <PasswordInput
            data-testid="password-input"
            hasError={utils.isPasswordInError(failureCode)}
            id="password-input"
            Input={SsoForm.PasswordInput}
            onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
              setPassword(e.target.value)
            }
            required
          />
        </sc.PasswordField>
        <sc.MailingListField>
          <Row alignItems="center" as="label">
            <input
              checked={isMailingListCheckboxChecked}
              data-testid="mailing-list-checkbox"
              onChange={handleMailingListCheckboxChange}
              type="checkbox"
            />
            <span>{text.EMAIL_LIST_SIGNUP}</span>
          </Row>
        </sc.MailingListField>
        {failureCode && (
          <SsoForm.Field>
            <SsoErrorMessage failureCode={failureCode} isNewPassword />
          </SsoForm.Field>
        )}
        <SsoForm.ButtonsContainer>
          <SsoForm.Button data-testid="submit-button" type="submit">
            {text.SIGN_UP_BUTTON}
          </SsoForm.Button>
        </SsoForm.ButtonsContainer>
        <SsoForm.Footer>
          <SsoForm.Link
            to={r.ssoInternalLogin({
              close: shouldCloseWindow,
              country: selectedCountry,
              returnTo: redirectTo,
            })}
            data-testid="sign-in-link"
          >
            {text.SIGN_IN_LINK}
          </SsoForm.Link>
          <sc.LegalText>
            {text.LEGAL_NOTICE_1}{" "}
            <a
              href={PRIVACY_POLICY_URL}
              rel="noopener noreferrer"
              target="_blank"
            >
              {text.PRIVACY_POLICY_LINK}
            </a>{" "}
            {text.LEGAL_NOTICE_2}{" "}
            <a
              href="https://www.pax.com/pages/terms-conditions"
              rel="noopener noreferrer"
              target="_blank"
            >
              {text.TERMS_CONDITIONS_LINK}
            </a>
            .
          </sc.LegalText>
        </SsoForm.Footer>
      </SsoForm.Form>
    </SsoChrome>
  );
};

const sc = {
  CountryField: styled(SsoForm.Field)`
    margin-top: 40px;
  `,

  LegalText: styled.div`
    margin-top: 20px;
  `,

  LinkContainer: styled.div`
    margin-top: 20px;
    text-align: center;
  `,

  MailingListField: styled(SsoForm.Field)`
    margin-bottom: 40px;

    label,
    input {
      cursor: pointer;
    }
    label {
      ${Text.PlaaxLight}
    }
    input {
      margin-right: 8px;
    }
  `,

  PasswordField: styled(SsoForm.Field)`
    margin-bottom: 13px;
  `,

  Title: styled(SsoForm.Title)`
    line-height: 0.67;
    margin: 40px 0 0 0;
  `,
};
