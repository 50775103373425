import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { DeviceType } from "../../../../pax-ble/types";

import * as selectors from "../../../device/selectors";
import * as r from "../../../main/routes";
import { Column } from "../../../shared/components/Flex";
import * as Icons from "../../../shared/components/Icons";
import * as Text from "../../../shared/components/Text";
import { Toast } from "../../../shared/components/Toast";
import { DEFAULT_TOAST_TIMEOUT } from "../../../shared/constants";
import * as t from "../../../shared/text";

export const PodAddedToast: React.FC = () => {
  const isDeviceConnected = useSelector(selectors.getIsDeviceConnected);
  const selectedDeviceType = useSelector(selectors.getSelectedDeviceType);

  let title = t.ADDED_TO_MY_PODS;
  let link = r.accountPods();
  let linkText = t.SEE_POD_HISTORY;

  if (isDeviceConnected) {
    if (selectedDeviceType === DeviceType.ERA_PRO) {
      title = t.POD_ADDED_TO_PAX_ERA_PRO;
      link = r.device();
      linkText = t.SEE_DEVICE_CONTROLS;
    } else if (selectedDeviceType === DeviceType.ERA) {
      title = t.POD_ADDED_TO_PAX_ERA;
      link = r.device();
      linkText = t.SEE_DEVICE_CONTROLS;
    }
  }
  return (
    <Toast timeout={DEFAULT_TOAST_TIMEOUT}>
      <sc.Container>
        <sc.Icon />
        <sc.TextContainer>
          <sc.Title>{title}</sc.Title>
          <sc.Link to={link}>{linkText}</sc.Link>
        </sc.TextContainer>
      </sc.Container>
    </Toast>
  );
};

const sc = {
  Container: styled.div`
    align-items: center;
    display: flex;
  `,

  Icon: styled(Icons.PodSwitcherCheckmark)`
    height: 21px;
    margin-right: 8px;
    width: 21px;

    > path {
      fill: var(--white);
    }
  `,

  Link: styled(Link)`
    color: var(--white);
  `,

  TextContainer: styled(Column)`
    justify-content: center;
  `,

  Title: styled.div`
    ${Text.MediumLLBold}
    font-size: 14px;
    letter-spacing: -0.105px;
    line-height: 20px;
  `,
};
