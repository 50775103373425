import React from "react";
import { useSelector } from "react-redux";
import styled, { css } from "styled-components";

import * as s from "../../device/selectors";
import * as Icons from "../../shared/components/Icons";
import { desktopBreakpoint } from "../../shared/utils";

import * as r from "../routes";
import { MobileNavLink, MobileNavLinkPAXLogo } from "./MobileNavLink";

type ResponsiveNavBarProps = {
  supportsBluetooth?: boolean;
};

export const ResponsiveNavBar: React.FC<ResponsiveNavBarProps> = () => {
  const isDeviceLocked = useSelector(s.getIsDeviceLocked);

  return (
    <>
      <sc.Nav>
        <MobileNavLink
          activeIcon={<Icons.SearchActive />}
          data-testid="explore-nav-link"
          defaultIcon={<Icons.Search />}
          to={r.podsHome()}
          startsWith={r.explore()}
        />
        <MobileNavLinkPAXLogo
          $isDeviceLocked={isDeviceLocked}
          activeIcon={<Icons.PaxDeviceActive />}
          data-testid="device-nav-link"
          defaultIcon={<Icons.PaxDevice />}
          to={r.device()}
          startsWith={r.device()}
        />
        <MobileNavLink
          activeIcon={<Icons.UserActive />}
          data-testid="my-pax-nav-link"
          defaultIcon={<Icons.User />}
          to={r.account()}
          startsWith={r.account()}
        />
      </sc.Nav>
    </>
  );
};

const sc = {
  Nav: styled.nav`
    align-items: center;
    background-color: var(--white);
    border-top: solid 0.5px var(--very-light-pink);
    bottom: 0;
    display: flex;
    flex-direction: row;
    height: var(--mobile-nav-bar-height);
    justify-content: space-between;
    padding: 0 25px;
    position: fixed;
    width: 100vw;
    z-index: var(--z-index-mobile-nav-bar);

    ${desktopBreakpoint(
      css`
        display: none;
      `
    )}
  `,
};
