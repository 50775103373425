import { createSelector } from "reselect";

import { MainAppState } from "../../../main/types";

import { ExperimentCode, Variation } from "../types/experiments";

export const getAssignedVariations = (state: MainAppState): Variation[] =>
  state.consumerApi.experiments?.assignedVariations;

type GetVariationProps = {
  experimentCode: ExperimentCode;
};

export const getVariation = createSelector(
  [
    getAssignedVariations,
    (_: MainAppState, props: GetVariationProps) => props.experimentCode,
  ],
  (variations, experimentCode) => {
    if (!variations) return null;

    return variations.find(
      (variation) => variation.experimentCode === experimentCode
    );
  }
);
