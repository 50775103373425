import styled, { css, SimpleInterpolation } from "styled-components";

import { desktopBreakpoint } from "../utils";
import * as Text from "./Text";

type BannerProps = {
  color?: string;
};

export const Banner = styled.div<BannerProps>`
  ${Text.PlaaxLight}
  align-items: center;
  background-color: ${({ color }): SimpleInterpolation => color};
  color: var(--white);
  display: flex;
  justify-content: flex-start;
  min-height: var(--banner-height);
  padding: 13px;
  top: 0;
  width: 100%;

  > span {
    text-align: center;
    width: 100%;
  }

  ${desktopBreakpoint(css`
    padding: 0 20px 0 0;

    > span {
      margin: auto;
      max-width: var(--banner-content-width);
      min-width: var(--banner-content-width);
    }
  `)}
`;

Banner.defaultProps = {
  color: "var(--salmon)",
};
