import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import { resetStrainsFilter } from "../../actions";
import * as c from "../../constants";
import { OnStrainsFilterSet } from "../../types";

import { PodsFilters } from ".";

type PodsFilterSidebarProps = {
  disableWhenEmpty?: boolean;
  onStrainsFilterSet?: OnStrainsFilterSet;
};

export const PodsFilterSidebar: React.FC<PodsFilterSidebarProps> = ({
  disableWhenEmpty = false,
  onStrainsFilterSet,
}) => {
  const dispatch = useDispatch();
  const [hasResetFilter, setHasResetFilter] = useState(false);

  // Reset strain filters on first load to ensure the filters are empty
  useEffect(() => {
    dispatch(resetStrainsFilter());
    setHasResetFilter(true);
  }, [dispatch]);

  return (
    <sc.Container>
      {hasResetFilter && (
        <sc.PodsFilters
          disableWhenEmpty={disableWhenEmpty}
          onStrainsFilterSet={onStrainsFilterSet}
          showReset
        />
      )}
    </sc.Container>
  );
};

const sc = {
  Container: styled.div`
    margin-right: ${c.FILTER_SIDEBAR_MARGIN}px;
    width: ${c.FILTER_SIDEBAR_WIDTH}px;
  `,

  PodsFilters: styled(PodsFilters)`
    padding: 0;
    width: ${c.FILTER_SIDEBAR_WIDTH}px;
  `,
};
