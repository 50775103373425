import McuManagerBleTransport from "../ble/McuManagerBleTransport";
import { McuManagerResponseType } from "../ble/McuManagerResponse";
import McuManager from "./McuManager";

const GROUP_DEFAULT = 0;
const ID_ECHO = 0;
const ID_RESET = 5;

export default class DefaultManager extends McuManager {
  constructor(transport: McuManagerBleTransport) {
    super(GROUP_DEFAULT, transport);
  }

  async echo(message: string): Promise<string> {
    return this.queueOperation(McuManager.OP_WRITE, 0, 0, ID_ECHO, {
      d: message,
    }) as Promise<string>;
  }

  async reset(): Promise<McuManagerResponseType> {
    return this.queueOperation(McuManager.OP_WRITE, 0, 0, ID_RESET);
  }
}
