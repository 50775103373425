import React from "react";
import styled, { css } from "styled-components";

import { Modal, ModalProps } from "../../modal/components/Modal";
import * as Text from "../../shared/components/Text";
import { desktopBreakpoint } from "../../shared/utils";

const DoseControlExplainerModal: React.FC<ModalProps> = (props) => {
  return (
    <Modal {...props}>
      <sc.Container data-testid="dose-explainer-modal">
        <sc.Title>Dose Control</sc.Title>
        <sc.Heading>Predictable</sc.Heading>
        <sc.Paragraph>
          A dose is a measurement of the amount of vapor. Doses are always
          consistent, even if you change the temperature. For example, if you
          increase the temperature you’ll get the same size dose but in a
          shorter time. Doses, or the amount of vapor, will vary between pods
          due to differing oil profiles.
        </sc.Paragraph>
        <sc.Heading>Select your preferred number of doses</sc.Heading>
        <sc.Paragraph>
          The dose sizes are multiples of a single dose. Experiment until you’ve
          found the number that’s right for you.
        </sc.Paragraph>
        <sc.Heading>Enjoy your pod</sc.Heading>
        <sc.Paragraph>
          Take one draw, two, three - take as many draws as you need to complete
          your dose. Vapor will cut off and your Era will vibrate when you’ve
          finished your dose.
        </sc.Paragraph>
      </sc.Container>
    </Modal>
  );
};

export default DoseControlExplainerModal;

const sc = {
  Container: styled.div`
    padding: 60px 40px;

    ${desktopBreakpoint(
      css`
        width: var(--default-modal-width);
      `
    )}
  `,

  Heading: styled.p`
    ${Text.PlaaxLight}
    margin: 0 0 16px;
  `,

  Paragraph: styled.p`
    ${Text.PlaaxLight}
    font-size: 18px;
    margin: 0 0 32px;
  `,

  Title: styled.div`
    align-items: center;
    font-size: 24px;
    margin-bottom: 32px;
    text-align: center;
    text-transform: uppercase;
  `,
};
