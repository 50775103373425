import React from "react";
import { useSelector } from "react-redux";
import styled, { css } from "styled-components";

import * as Icons from "../../shared/components/Icons";
import { desktopBreakpoint } from "../../shared/utils";

import { getHasNotifications } from "../selectors";

type NotificationsIconProps = {
  setIsNotificationsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const NotificationsIcon: React.FC<NotificationsIconProps> = ({
  setIsNotificationsModalOpen,
}) => {
  const hasNotifications = useSelector(getHasNotifications);

  const handleNotificationsAlertClick = (): void => {
    setIsNotificationsModalOpen(true);
  };

  return (
    <sc.NotificationsIconContainer
      disabled={!hasNotifications}
      onClick={handleNotificationsAlertClick}
    >
      {hasNotifications ? <Icons.NotificationOn /> : <Icons.Notification />}
    </sc.NotificationsIconContainer>
  );
};

type IconProps = {
  disabled: boolean;
};

const sc = {
  NotificationsIconContainer: styled.div<IconProps>`
    align-items: center;
    border-radius: 100%;
    cursor: pointer;
    display: flex;
    height: 48px;
    justify-content: center;
    opacity: ${({ disabled }) => (disabled ? "0.36" : "1")};
    pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
    width: 48px;

    &:hover {
      background-color: var(--black);

      & > svg g {
        fill: var(--white);
        stroke: var(--white);
      }
    }

    ${desktopBreakpoint(
      css`
        margin-left: 6px;
      `
    )}
  `,
};
