import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled, { css } from "styled-components";

import * as consumerApi from "../../api/consumer";
import { FindMyPax } from "../../find-my-pax/components/FindMyPax";
import * as selectors from "../../firmware/selectors";
import { openFirmwareModal } from "../../modal/actions";
import { ChevronLeft } from "../../shared/components/Icons";
import Page from "../../shared/components/Page";
import { desktopBreakpoint } from "../../shared/utils";

import MyDevice from "../containers/MyDevice";
import { getIsMyDeviceConnected } from "../utils";

export type MyDevicePageProps = {
  device: consumerApi.types.device.DeviceJson;
  onGoBackClick: () => void;
};

export const MyDevicePage: React.FC<MyDevicePageProps> = ({
  device,
  onGoBackClick,
}) => {
  const dispatch = useDispatch();
  const shouldShowFirmwareModal = useSelector(
    selectors.getShouldShowFirmwareModal
  );
  const isConnected = getIsMyDeviceConnected(device.serialNumber);

  useEffect(() => {
    if (isConnected && shouldShowFirmwareModal) {
      dispatch(openFirmwareModal());
    }
  }, [dispatch, isConnected, shouldShowFirmwareModal]);

  return (
    <sc.Page data-testid="device-page">
      <sc.ChevronLeft data-testid="go-back-button" onClick={onGoBackClick} />
      <MyDevice
        data-testid="my-device"
        device={device}
        onForgetDevice={onGoBackClick}
      />
      <FindMyPax device={device} />
    </sc.Page>
  );
};

const sc = {
  ChevronLeft: styled(ChevronLeft)`
    align-self: flex-start;
    height: 35px;
    padding: 4px;
    width: 35px;

    &:hover {
      background: var(--black);
      border-radius: 50px;
      cursor: pointer;
    }

    &:hover path {
      fill: var(--white);
    }

    ${desktopBreakpoint(
      css`
        height: 48px;
        width: 48px;
      `
    )}
  `,

  Page: styled(Page)`
    max-width: ${(props) => props.theme.pageWidth};
    padding-bottom: 10px;

    ${desktopBreakpoint(
      css`
        padding-bottom: 0;
      `
    )}
  `,

  Title: styled.div`
    font-size: 20px;
    margin: auto;
    margin-top: 60px;
    max-width: 400px;
    text-transform: uppercase;
    width: 100%;

    ${desktopBreakpoint(
      css`
        max-width: none;
      `
    )}
  `,
};
