import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled, { css } from "styled-components";

import { StrainJson } from "../../../api/consumer/types";
import { getDevicePod } from "../../../device/selectors";
import * as Text from "../../../shared/components/Text";
import { useDesktopMediaQuery } from "../../../shared/hooks";

import * as Icons from "../../components/Icons";
import * as t from "../../text";
import { desktopBreakpoint } from "../../utils";

type PodSwitcherWithTextProps = {
  Icon: React.ReactElement;
  isActive: boolean;
  HoverIcon?: React.ReactElement;
  onClick: () => void;
  strain?: StrainJson | null;
};

export const PodSwitcherWithText: React.FC<PodSwitcherWithTextProps> = ({
  Icon,
  isActive,
  HoverIcon,
  onClick,
  strain,
}) => {
  const [iconToRender, setIconToRender] = useState<React.ReactElement>(Icon);
  const devicePod = useSelector(getDevicePod);
  const isDesktop = useDesktopMediaQuery();

  useEffect(() => {
    setIconToRender(Icon);
  }, [Icon]);

  const handleMouseEnter = (): void => {
    if (!HoverIcon) return;

    setIconToRender(HoverIcon);
  };

  const handleMouseLeave = (): void => {
    setIconToRender(Icon);
  };

  // The autoId’d pod has strain info, so we don't allow override
  if (devicePod.isAutoId && devicePod.strainId) {
    // Show an unclickable indicator if the autoId'd pod is the the currently viewed pod
    if (devicePod.strainId === strain?.id) {
      return (
        <sc.UnclickableContainer
          className="pod-switcher"
          data-testid="unclickable-pod-switcher"
        >
          <Icons.PodSwitcherCheckmark data-testid="pod-switcher-checkmark-icon" />
          <sc.Text isActive>{t.CURRENT_POD}</sc.Text>
        </sc.UnclickableContainer>
      );
    } else {
      // Show nothing if the user is browsing pods and has an autoId pod inserted
      return null;
    }
  }

  return (
    <sc.Container
      className="pod-switcher"
      data-testid="pod-switcher"
      isActive={isActive}
      onClick={onClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {iconToRender}
      {!isDesktop && (
        <sc.Text isActive={isActive}>
          {isActive ? t.CURRENT_POD : t.SET_AS_CURRENT}
        </sc.Text>
      )}
    </sc.Container>
  );
};

const ContainerStyles = css`
  ${Text.MediumLLBold}
  align-items: center;
  border: 3px solid var(--black);
  border-radius: 4px;
  display: flex;
  height: 46px;
  justify-content: center;
  padding: 7px 16px 8px;
  z-index: var(--z-index-pod-switcher);

  & svg {
    height: 22px;
    margin-right: 5px;
    width: 22px;
  }

  ${desktopBreakpoint(css`
    margin-right: 16px;
    padding: 0;
    width: 46px;
  `)}
`;

type activeProps = {
  isActive: boolean;
};

const sc = {
  Container: styled.div<activeProps>`
    ${ContainerStyles}
    background: ${({ isActive }) =>
      isActive ? "var(--black)" : "var(--transparent)"};
    color: ${({ isActive }) => (isActive ? "var(--white)" : "var(--black)")};

    &:hover {
      background: var(--white-three);
      color: var(--black);
      cursor: pointer;
    }

    > svg > path {
      fill: ${({ isActive }) => (isActive ? "var(--white)" : "var(--black)")};
    }
  `,

  Text: styled.div<activeProps>`
    font-size: 14px;
    letter-spacing: 0.05em;
    text-decoration: ${({ isActive }) => (isActive ? "none" : "underline")};
    text-transform: uppercase;
  `,

  UnclickableContainer: styled.div`
    ${ContainerStyles}
    background: var(--black);
    color: var(--white);

    > svg > path {
      fill: var(--white);
    }
  `,
};
