import React, { useState } from "react";

import { navigator } from "../../navigator";

import { DeviceConnectionModal } from "./components/DeviceConnectionModal";
import * as t from "./types";

type UseDeviceConnectionModalOptions = {
  allowOuterElementInteraction?: boolean;
  hideCloser?: boolean;
  onRequestClose?: () => unknown;
};

type UseDeviceConnectionModalProps = (
  deviceConnectionFlow: t.DeviceConnectionFlow,
  initialIsOpen: boolean,
  initialStep: t.ModalStep,
  isTransparent: boolean,
  options?: UseDeviceConnectionModalOptions
) => {
  deviceConnectionModal: React.ReactNode;
  isConnectionModalOpen: boolean;
  setIsConnectionModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const useDeviceConnectionModal: UseDeviceConnectionModalProps = (
  deviceConnectionFlow,
  initialIsOpen,
  initialStep,
  isTransparent,
  options
) => {
  const [isConnectionModalOpen, setIsConnectionModalOpen] =
    useState(initialIsOpen);

  if (!navigator.bluetooth) {
    return {
      deviceConnectionModal: null,
      isConnectionModalOpen: false,
      setIsConnectionModalOpen: (): void => {},
    };
  }

  const deviceConnectionModal = (
    <DeviceConnectionModal
      allowOuterElementInteraction={options?.allowOuterElementInteraction}
      deviceConnectionFlow={deviceConnectionFlow}
      hideCloser={options?.hideCloser}
      isConnectionModalOpen={isConnectionModalOpen}
      initialStep={initialStep}
      isTransparent={isTransparent}
      onRequestClose={options?.onRequestClose}
      setIsConnectionModalOpen={setIsConnectionModalOpen}
    />
  );

  return {
    deviceConnectionModal,
    isConnectionModalOpen,
    setIsConnectionModalOpen,
  };
};
