import { getDeviceAsset } from "../../device/constants";

import { DEVICE_COLOR_STRING_MAP, DEVICE_MODEL_MAP } from "./constants";
import * as t from "./types";

// Only checks if both arrays contain the same strain ids.
// Ordering matters.
export const getAreStrainJsonArraysEqual = (
  a: t.StrainJson[],
  b: t.StrainJson[]
): boolean => {
  if (a.length !== b.length) return false;

  return a.every((strain, index) => strain.id === b[index].id);
};

export const getImageAssetForApiDevice = (
  device: t.device.DeviceJson
): string | null => {
  const paxBleDeviceType = DEVICE_MODEL_MAP[device.model];
  const deviceColorCode = DEVICE_COLOR_STRING_MAP[device.shellColor];

  return getDeviceAsset(paxBleDeviceType, deviceColorCode);
};

export const getThemeImageUrl = (
  theme: t.user.Theme,
  type: "DESKTOP_BANNER" | "MOBILE_BANNER" | "THUMBNAIL"
): string => {
  return theme.images?.find((image) => image.type === type)?.downloadUrl || "";
};
