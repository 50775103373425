import * as t from "../api/consumer/types";
import { Action } from "../shared/types";
import { isEqualJSONObject } from "../shared/utils";
import { getDefaultStrainsFilter } from "./models/StrainsFilter";
import * as a from "./actions";
import { PodsState } from "./types";

export const getInitialState = (): PodsState => ({
  clickedPodCount: 0,
  filter: getDefaultStrainsFilter(),
  isFetching: false,
  isUserLocationUndetermined: false,
  nextFetchUrl: null,
  strains: [],
});

export const reducer = (
  state: PodsState = getInitialState(),
  action: Action
): PodsState => {
  const { payload } = action;

  switch (action.type) {
    case a.FETCH_STRAINS_START:
      return {
        ...state,
        isFetching: true,
      };

    case a.FETCH_STRAINS_ERROR:
      return { ...state, isFetching: false };

    case a.FETCH_STRAINS_SUCCESS:
      const response = payload.response as t.CollectionResponse<t.StrainJson>;

      // If a response is for a subsequent page, concat the results.
      const strains = response.previous
        ? state.strains.concat(response.results)
        : response.results;

      return {
        ...state,
        isFetching: false,
        nextFetchUrl: response.next,
        strains,
      };

    case a.INCREMENT_CLICKED_POD_COUNT:
      return {
        ...state,
        clickedPodCount: state.clickedPodCount + 1,
      };

    case a.SET_STRAINS_FILTER:
      const { filter } = payload;
      const shouldResetClickedPodCount = !isEqualJSONObject(
        filter,
        state.filter
      );

      return {
        ...state,
        clickedPodCount: shouldResetClickedPodCount ? 0 : state.clickedPodCount,
        filter,
        isFetching: false,
        nextFetchUrl: null,
        strains: [],
      };

    case a.SET_USER_LOCATION_DETERMINED:
      return { ...state, isUserLocationUndetermined: false };

    case a.SET_USER_LOCATION_UNDETERMINED:
      return { ...state, isUserLocationUndetermined: true };

    default:
      return state;
  }
};

export default reducer;
