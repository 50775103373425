import React, { useEffect, useState } from "react";
import * as ReactQuery from "react-query";
import { useDispatch } from "react-redux";

import * as consumerApi from "../../api/consumer";
import { useStrainById } from "../../pods/hooks";

import { PodFeedbackAddMyReview } from "./PodFeedbackAddMyReview";
import { PodFeedbackAddNewPod } from "./PodFeedbackAddNewPod";
import { PodFeedbackMyReview } from "./PodFeedbackMyReview";

enum FeedbackType {
  ADD_POD = "ADD_POD",
  ADD_REVIEW = "ADD_REVIEW",
  MY_REVIEW = "MY_REVIEW",
}

export type PodFeedbackSectionProps = {
  strainId: string;
  useQuery?: () => ReactQuery.UseQueryResult<consumerApi.types.connectedPods.ConnectedPodJson>;
};

export const PodFeedbackSection: React.FC<PodFeedbackSectionProps> = ({
  strainId,
  useQuery = ReactQuery.useQuery,
}) => {
  const dispatch = useDispatch();
  const { strain } = useStrainById(strainId) || {};
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [feedbackType, setFeedbackType] = useState<FeedbackType>(
    FeedbackType.ADD_POD
  );

  const { data: connectedPod, refetch: refetchConnectedPod } =
    useQuery<consumerApi.types.connectedPods.ConnectedPodJson>(
      consumerApi.paths.connectedPods.connectedPod(strainId),
      {
        onSuccess: (connectedPod) => {
          dispatch(
            consumerApi.actions.connectedPods.receivedConnectedPod(connectedPod)
          );
        },
      }
    );

  // Only update the feedback type if the modal is not open
  useEffect(() => {
    if (isModalOpen) return;

    if (!connectedPod) {
      setFeedbackType(FeedbackType.ADD_POD);
    } else if (!connectedPod.latestReview) {
      setFeedbackType(FeedbackType.ADD_REVIEW);
    } else {
      setFeedbackType(FeedbackType.MY_REVIEW);
    }
  }, [connectedPod, isModalOpen]);

  const handleFeedBackComplete = () => {
    setIsModalOpen(false);
    refetchConnectedPod();
  };

  switch (feedbackType) {
    case FeedbackType.ADD_POD:
      return (
        <PodFeedbackAddNewPod
          onReviewComplete={handleFeedBackComplete}
          onReviewStarted={() => setIsModalOpen(true)}
          strain={strain}
        />
      );
    case FeedbackType.ADD_REVIEW:
      return (
        <PodFeedbackAddMyReview
          onReviewComplete={handleFeedBackComplete}
          onReviewStarted={() => setIsModalOpen(true)}
          strain={strain}
        />
      );
    case FeedbackType.MY_REVIEW:
      return (
        <PodFeedbackMyReview
          onEditComplete={handleFeedBackComplete}
          review={connectedPod?.latestReview}
        />
      );
    default:
      return null;
  }
};
