import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import styled, { SimpleInterpolation } from "styled-components";

import { trackPodLowEventFromDeviceControlsPage } from "../../analytics/fuel-gauge/podLow";
import { getPod } from "../../api/consumer/selectors/strains";
import * as t from "../../api/consumer/types";
import * as r from "../../main/routes";
import { MainAppState } from "../../main/types";
import { PodFeedbackPrompt } from "../../pod-feedback/components/PodFeedbackPrompt";
import * as podsHooks from "../../pods/hooks";
import { goToPodsSearchPage } from "../../pods/utils";
import * as Strain from "../../shared/colors/Strain";
import * as Card from "../../shared/components/Card";
import * as Icons from "../../shared/components/Icons";
import { LowPodBadge } from "../../shared/components/LowPodBadge";
import { PartnerLogo } from "../../shared/components/PartnerLogo";
import { StrainComposition } from "../../shared/components/StrainComposition";
import * as Text from "../../shared/components/Text";
import { useDeviceControlsPodSwitcher } from "../../shared/PodSwitcher";

import { getDevicePod } from "../selectors";
import { DeviceWithoutPodText } from "./DeviceWithoutPodText";

export type DevicePodDetailsCardProps = {
  isDeviceLocked?: boolean;
  useStrainById?: podsHooks.UseStrainById;
};

export const DevicePodDetailsCard: React.FC<DevicePodDetailsCardProps> = ({
  isDeviceLocked,
  useStrainById = podsHooks.useStrainById,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const pod = useSelector(getDevicePod);
  const connectedPodJson = useSelector((state: MainAppState) =>
    getPod(state, { strainId: pod.strainId })
  );

  const { strain } = useStrainById(pod.strainId);

  useEffect(() => {
    if (!connectedPodJson) return;

    dispatch(trackPodLowEventFromDeviceControlsPage(pod, connectedPodJson));
    // Track only when strain id changes.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pod.strainId]);

  const handlePodSwitcherClick = (): void => {
    goToPodsSearchPage(history);
  };

  const podSwitcher = useDeviceControlsPodSwitcher(
    handlePodSwitcherClick,
    strain
  );

  if (isDeviceLocked) {
    return (
      <sc.SmallCard color="var(--gunmetal-10)">
        <sc.PartnerLogo large />
      </sc.SmallCard>
    );
  }

  const isPodMissingStrainInfo = pod.isAutoId && (!pod.strainId || !strain);

  if (!strain || !pod.strainId) {
    return (
      <sc.SmallCard color="var(--gunmetal-10)">
        <sc.PartnerLogo large>{podSwitcher}</sc.PartnerLogo>
        <DeviceWithoutPodText
          fontSize={14}
          isPodMissingStrainInfo={isPodMissingStrainInfo}
        />
      </sc.SmallCard>
    );
  }

  const { partner } = strain;

  return (
    <>
      <sc.SmallCard color={Strain.getStrainColorGradient(strain)}>
        {pod.isAutoId && connectedPodJson?.isLow && (
          <LowPodBadge data-testid="low-pod-badge" toRight />
        )}
        <sc.Link
          to={{
            pathname: r.podDetails(strain.id),
            state: { goBackTo: r.device() },
          }}
        />
        <sc.PartnerLogo large={pod.hasTestReport} partner={partner}>
          {podSwitcher}
        </sc.PartnerLogo>
        <sc.Title title={strain.name}>{strain.name}</sc.Title>
        <sc.Subtitle>
          <sc.PartnerName title={partner.partnerName}>
            {partner.partnerName}
          </sc.PartnerName>
          <sc.Separator>•</sc.Separator>
          <Icons.Hexagon
            color={Strain.getPrimaryStrainColor(strain)}
            size={14}
          />
          <sc.Classification>{strain.classification}</sc.Classification>
        </sc.Subtitle>
        {!pod.hasTestReport && (
          <sc.CompositionContainer>
            <StrainComposition
              strain={strain}
              ingredient={t.StrainIngredient.THC}
            />
            <StrainComposition
              strain={strain}
              ingredient={t.StrainIngredient.CBD}
            />
          </sc.CompositionContainer>
        )}
      </sc.SmallCard>
      <PodFeedbackPrompt strainId={strain.id} />
    </>
  );
};

type SmallCardProps = {
  color: string;
};

type PartnerLogoProps = {
  large?: boolean;
};

const sc = {
  Classification: styled.span`
    ${Text.PlaaxLight}
    font-size: 18px;
    line-height: 1.2;
    margin-left: 8px;
    text-transform: uppercase;
  `,

  CompositionContainer: styled.div`
    display: flex;
    margin-top: 10px;

    & div:first-child::after {
      color: var(--very-light-pink);
      content: "|";
      margin: 0 8px;
    }
  `,

  Link: styled(Link)`
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
  `,

  PartnerLogo: styled(PartnerLogo)<PartnerLogoProps>`
    align-items: center;
    background-color: var(--white);
    border-radius: 64px;
    display: flex;
    height: ${({ large }): SimpleInterpolation => (large ? "80" : "64")}px;
    justify-content: center;
    margin-bottom: ${({ large }): SimpleInterpolation =>
      large ? "12" : "8"}px;
    position: relative;
    width: ${({ large }): SimpleInterpolation => (large ? "80" : "64")}px;

    & .pod-switcher {
      bottom: 0;
      height: 36px;
      position: absolute;
      right: -12px;
      width: 36px;
    }

    & .pod-switcher svg {
      height: 20px;
      margin: 9px;
      width: 20px;
    }
  `,

  PartnerName: styled.div`
    ${Text.PlaaxLight}
    font-size: 18px;
    line-height: 1.2;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `,

  Separator: styled.span`
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    margin: 0 12px;
  `,

  SmallCard: styled(Card.Small)<SmallCardProps>`
    padding: 16px 20px;
    position: relative;
    ${({ color }): SimpleInterpolation => `
    background-image: radial-gradient(
      circle at 50% -200%,
      ${color},
      var(--white-0) 90%,
      var(--white-0) 90%
    );`}
  `,

  Subtitle: styled.div`
    align-items: baseline;
    display: flex;
    font-size: 14px;
    justify-content: center;
    line-height: 1.14;
    text-align: center;
    width: 236px;
  `,

  Super: styled.span`
    font-size: 10px;
    vertical-align: top;
  `,

  Text: styled.div`
    ${Text.PlaaxLight}
    font-size: 14px;
    line-height: 1.14;
    text-align: center;
    width: 236px;
  `,

  Title: styled.div`
    font-size: 22px;
    margin-bottom: 4px;
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 90%;
  `,
};
