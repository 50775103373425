import React from "react";
import styled, { SimpleInterpolation } from "styled-components";

import * as Strain from "../../api/consumer/models/Strain";
import { StrainJson } from "../../api/consumer/types";
import { getGeoStateLabel } from "../../pods/utils";
import * as Text from "../../shared/components/Text";

export type PodRegionProps = {
  profileRegion?: string;
  strain: StrainJson;
};

export const PodRegion: React.FC<PodRegionProps> = ({
  profileRegion,
  strain,
}) => {
  if (Strain.isStrainCanadian(strain)) {
    return <sc.Location data-testid="strain-region">Canada</sc.Location>;
  }

  if (Strain.isStrainAmerican(strain)) {
    return (
      <sc.Location
        data-testid="strain-region"
        isPreferred={strain.geoState === profileRegion}
      >
        {getGeoStateLabel(strain.geoState)}
      </sc.Location>
    );
  }

  return null;
};

type LocationProps = {
  isPreferred?: boolean;
};

const sc = {
  Location: styled.span<LocationProps>`
    ${(props): SimpleInterpolation =>
      props.isPreferred ? Text.Plaax : Text.PlaaxLight};
    font-size: 14px;
    line-height: 0.89;
    margin-right: 8px;

    &::before {
      content: "•";
      display: inline-block;
      font-size: 12px;
      font-weight: 500;
      margin: 0 6px;
    }
  `,
};
