import React, { useState } from "react";
import styled from "styled-components";

import * as Text from "../../../shared/components/Text";
import * as text from "../../../shared/text";

import * as SC from "../../styledComponents";

type NotesInputProps = {
  shouldRenderExpanded?: boolean;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => unknown;
  value?: string;
};

export const NotesInput: React.FC<NotesInputProps> = ({
  shouldRenderExpanded = false,
  onChange,
  value,
  ...props
}) => {
  const [isTextAreaExpanded, setIsTextAreaExpanded] =
    useState<boolean>(shouldRenderExpanded);

  const handleAddNoteClick = (): void => {
    setIsTextAreaExpanded(true);
  };

  return (
    <sc.Container data-testid="notes" {...props}>
      <SC.Subtitle
        bold
        isClickable={!isTextAreaExpanded}
        onClick={handleAddNoteClick}
      >
        {text.ADD_NOTE}
      </SC.Subtitle>
      {isTextAreaExpanded && (
        <sc.Textarea
          data-testid="notes-textarea"
          onChange={onChange}
          value={value}
        />
      )}
    </sc.Container>
  );
};

const sc = {
  Container: styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: 8px;
  `,

  Textarea: styled.textarea`
    ${Text.PlaaxLight}
    border-color: var(--very-light-pink-5);
    border-radius: 8px;
    font-size: 16px;
    height: 146px;
    line-height: 1.25;
    margin-top: 12px;
    padding: 12px;
    resize: none;
    width: 328px;
  `,
};
