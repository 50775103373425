import { connect } from "react-redux";

import { MainAppState } from "../../main/types";

import { sendEmailVerificationRequest } from "../actions";
import { SsoEmailVerificationTokenExpiredPage } from "../components/SsoEmailVerificationTokenExpiredPage";

const mapStateToProps = (state: MainAppState) => {
  return state.sso.sendEmailVerificationRequest;
};

const mapDispatchToProps = {
  sendEmailVerificationRequest,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SsoEmailVerificationTokenExpiredPage);
