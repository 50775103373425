import React from "react";
import styled, { css } from "styled-components";

import ExperienceModeGraphic from "../../../assets/images/device-mode/experience-mode-graphic.png";
import TemperatureModeGraphic from "../../../assets/images/device-mode/temp-mode-graphic.png";
import { ModalProps } from "../../../modal/components/Modal";
import { PlaaxButton } from "../../../shared/components/Button";
import * as Text from "../../../shared/components/Text";
import * as text from "../../../shared/text";

type DevcieModeModalProps = ModalProps & {
  isExperienceModeSelected: boolean;
  setIsExperienceModeSelected: (isExperienceModeSelected: boolean) => void;
};

export const DeviceModeModalContent: React.FC<DevcieModeModalProps> = ({
  isExperienceModeSelected,
  setIsExperienceModeSelected,
}) => {
  const graphicSrc = isExperienceModeSelected
    ? ExperienceModeGraphic
    : TemperatureModeGraphic;
  const explanation = isExperienceModeSelected
    ? text.EXPERIENCE_MODE_EXPLANATION
    : text.TEMPERATURE_MODE_EXPLANATION;

  return (
    <>
      <sc.Title>{text.DEVICE_MODE}</sc.Title>
      <sc.ButtonBar>
        <sc.Button
          active={isExperienceModeSelected}
          disabled={isExperienceModeSelected}
          onClick={() => setIsExperienceModeSelected(true)}
        >
          {text.EXPERIENCES}
        </sc.Button>
        <sc.Button
          active={!isExperienceModeSelected}
          disabled={!isExperienceModeSelected}
          onClick={() => setIsExperienceModeSelected(false)}
        >
          {text.TEMPERATURE}
        </sc.Button>
      </sc.ButtonBar>
      <sc.Graphic src={graphicSrc} />
      <sc.Text>{explanation}</sc.Text>
    </>
  );
};

type ButtonProps = {
  active: boolean;
};

const inactiveStyles = css`
  background: none;
  border: none;
  color: var(--black);
`;

const sc = {
  Button: styled(PlaaxButton)<ButtonProps>`
    ${Text.PlaaxBold}
    border-radius: 16px;
    font-size: 14px;
    height: 34px;
    line-height: 0;
    opacity: 1;
    width: 152px;

    ${({ active }) => (active ? "" : inactiveStyles)}
  `,

  ButtonBar: styled.div`
    border: 1px solid var(--black);
    border-radius: 16px;
    box-sizing: border-box;
    display: flex;
    height: 34px;
    margin-top: 24px;
    width: 296px;
  `,

  Graphic: styled.img`
    margin-top: 16px;
  `,

  Text: styled.div`
    ${Text.Plaax}
    font-size: 14px;
    line-height: 20px;
    margin-top: 16px;
  `,

  Title: styled.div`
    ${Text.PlaaxBold}
    font-size: 20px;
    line-height: 24px;
  `,
};
