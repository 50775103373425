import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import * as consumerApi from "../../api/consumer";
import * as r from "../../main/routes";
import { MainAppState } from "../../main/types";
import * as sharedHooks from "../../shared/hooks";
import { SearchParam } from "../../shared/types";

import { SendEmailVerificationRequest } from "../actions";
import { useSsoPageReset } from "../hooks";
import * as text from "../text";
import { SsoPage, SsoProps } from "../types";
import * as utils from "../utils";
import { SsoChrome } from "./SsoChrome";
import { SsoErrorMessage } from "./SsoErrorMessage";
import * as SsoForm from "./SsoForm";

export type SsoEmailVerificationRequestSentPageProps = SsoProps & {
  failureCode?: consumerApi.types.ErrorCode;
  getUserEmail: (state: MainAppState) => string;
  sendEmailVerificationRequest: SendEmailVerificationRequest;
  useSearchParam: sharedHooks.UseSearchParam;
};

export const SsoEmailVerificationRequestSentPage: React.FC<SsoEmailVerificationRequestSentPageProps> =
  ({
    endSsoFlow = utils.endSsoFlow,
    failureCode,
    getUserEmail = consumerApi.selectors.user.getUserEmail,
    sendEmailVerificationRequest,
    ssoApiReturnTo = utils.ssoApiReturnTo,
    useSearchParam = sharedHooks.useSearchParam,
  }) => {
    const redirectTo = useSearchParam(SearchParam.REDIRECT) || r.home();
    const shouldCloseWindow = useSearchParam(SearchParam.CLOSE) || "false";
    const returnTo = ssoApiReturnTo(redirectTo);
    const email = useSelector(getUserEmail);
    const [message, setMessage] = useState<string>("");

    useSsoPageReset(SsoPage.sendEmailVerificationRequest);

    const handleSkipClick = (): void => {
      endSsoFlow(redirectTo, shouldCloseWindow);
    };

    return (
      <SsoChrome>
        <SsoForm.Form
          data-testid="send-email-verification-request-form"
          onSubmit={(e): void => {
            e.preventDefault();
            if (!email) return;
            sendEmailVerificationRequest({ email, returnTo });
            setMessage(text.EMAIL_SENT_CHECK_INBOX);
          }}
        >
          <SsoForm.Field>
            <SsoForm.Title>{text.VERIFY_EMAIL_SENT_TITLE}</SsoForm.Title>
            <SsoForm.P>{text.VERIFY_EMAIL_SENT_TEXT_1}</SsoForm.P>
            <SsoForm.P>{text.VERIFY_EMAIL_SENT_TEXT_2}</SsoForm.P>
          </SsoForm.Field>
          <SsoForm.Field>
            <SsoForm.Label htmlFor="email-input">
              {text.EMAIL_LABEL}
            </SsoForm.Label>
            <SsoForm.Input
              data-testid="email-input"
              disabled
              hasError={utils.isUsernameInError(failureCode)}
              id="email-input"
              required
              type="email"
              value={email}
            />
          </SsoForm.Field>
          {failureCode && <SsoErrorMessage failureCode={failureCode} />}
          <SsoForm.ButtonsContainer>
            <SsoForm.Button data-testid="submit-button" type="submit">
              {text.SEND_AGAIN_BUTTON}
            </SsoForm.Button>
            {message && <SsoForm.Message>{message}</SsoForm.Message>}
          </SsoForm.ButtonsContainer>
          <SsoForm.Footer>
            <Link
              data-testid="skip-email-verification-link"
              to="#"
              onClick={handleSkipClick}
            >
              {text.SKIP_LINK}
            </Link>
          </SsoForm.Footer>
        </SsoForm.Form>
      </SsoChrome>
    );
  };
