import React, { Dispatch, SetStateAction } from "react";
import styled from "styled-components";

import * as ps from "../../../shared/components/PillSelect";
import * as Text from "../../../shared/components/Text";
import { STRAIN } from "../../../shared/text";

import { StrainsFilter } from "../../types";
import { getStrainPillOptions, handlePillOptionChange } from "../../utils";

import * as SC from "./styledComponents";

type PodsStrainClassificationFilterProps = {
  filterUpdate: StrainsFilter;
  setFilterUpdate: Dispatch<SetStateAction<StrainsFilter>>;
};

export const PodsStrainClassificationFilter: React.FC<PodsStrainClassificationFilterProps> =
  ({ filterUpdate, setFilterUpdate }) => {
    return (
      <SC.Filter>
        <SC.Label>{STRAIN}</SC.Label>
        <ps.PillSelect
          Container={sc.Container}
          onChange={(options) =>
            handlePillOptionChange(
              options,
              filterUpdate.strainClassifications,
              "strainClassifications",
              filterUpdate,
              setFilterUpdate
            )
          }
          options={getStrainPillOptions(filterUpdate.strainClassifications)}
          PillOption={sc.PillOption}
        />
      </SC.Filter>
    );
  };

const sc = {
  Container: styled.div`
    column-gap: 19px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 12px;
  `,

  PillOption: styled(SC.BasePill)<SC.BasePillProps>`
    ${Text.MediumLLBold}
    background-color: ${({ active, activeBackgroundColor }) =>
      active ? activeBackgroundColor : "var(--white)"};
    border: 3px solid ${({ activeBackgroundColor }) => activeBackgroundColor};
    border-radius: 24px;
    box-shadow: none;
    color: var(--black);
    height: 48px;

    &:first-child {
      color: ${({ active }) => (active ? "var(--white)" : "var(--black)")};
    }

    &:last-child {
      color: ${({ active }) => (active ? "var(--white)" : "var(--black)")};
    }
  `,
};
