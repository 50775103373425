import * as t from "../types";

type StrainReviewValues = {
  rating: t.reviews.StrainRating;
} & Partial<t.reviews.StrainReviewJson>;

export const getStrainReviewJson = (
  values: StrainReviewValues
): t.reviews.StrainReviewJson => {
  return {
    clientTimestamp: new Date().toISOString(),
    effects: [],
    id: "",
    recommendationType: "",
    recommendedPods: [],
    selectedEffects: [],
    strainId: "",
    text: "",
    ...values,
  };
};
