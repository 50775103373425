import * as c from "../pods/constants";

export type ResponsiveTheme = {
  connectionModal: {
    deviceShakingAnimationHeight: string;
    minHeight: string;
    padding: string;
    titleFontSize: string;
    width: string;
  };
  exploreDetailsPage: {
    minHeight: string;
  };
  flashBanner: {
    closerWidth: number;
  };
  myPax: {
    headerFontSize: (short: boolean) => string;
    headerHeight: (short: boolean) => string;
    pageContentWidth: string;
  };
  pageWidth: string;
  podCard: {
    height: string;
    width: string;
  };
  queryField: {
    width: string;
  };
};

export const mobileTheme: ResponsiveTheme = {
  connectionModal: {
    deviceShakingAnimationHeight: "35vh",
    minHeight: "100%",
    padding: "40px 20px",
    titleFontSize: "28px",
    width: "100%",
  },
  exploreDetailsPage: {
    minHeight: "calc(100vh - 74px)",
  },
  flashBanner: {
    closerWidth: 32,
  },
  myPax: {
    headerFontSize: (short) => (short ? "22px" : "32px"),
    headerHeight: (short) => (short ? "120px" : "140px"),
    pageContentWidth: "560px",
  },
  pageWidth: "872px",
  podCard: {
    height: `${c.POD_CARD_HEIGHT_MOBILE}px`,
    width: "160px",
  },
  queryField: {
    width: "100%",
  },
};

export const desktopTheme: ResponsiveTheme = {
  ...mobileTheme,
  connectionModal: {
    deviceShakingAnimationHeight: "246px",
    minHeight: "604px",
    padding: "40px",
    titleFontSize: "32px",
    width: "424px",
  },
  exploreDetailsPage: {
    minHeight: "calc(100vh - 100px)",
  },
  myPax: {
    headerFontSize: (short) => (short ? "28px" : "32px"),
    headerHeight: (short) => (short ? "120px" : "140px"),
    pageContentWidth: "838px",
  },
  pageWidth: "882px",
  podCard: {
    height: `${c.POD_CARD_HEIGHT_DESKTOP}px`,
    width: "282px",
  },
  queryField: {
    width: "350px",
  },
};
