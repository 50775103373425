import React, { useState } from "react";

import { ErrorCode } from "../../api/consumer/types";
import * as r from "../../main/routes";
import * as sharedHooks from "../../shared/hooks";
import { SearchParam } from "../../shared/types";

import { ForgotPassword } from "../actions";
import { useSsoPageReset } from "../hooks";
import * as text from "../text";
import { SsoPage, SsoProps } from "../types";
import * as utils from "../utils";
import { SsoChrome } from "./SsoChrome";
import { SsoErrorMessage } from "./SsoErrorMessage";
import * as SsoForm from "./SsoForm";

export type SsoResetPasswordTokenExpiredPageProps = SsoProps & {
  failureCode?: ErrorCode;
  forgotPassword: ForgotPassword;
  success?: boolean;
  useSearchParam: sharedHooks.UseSearchParam;
};

export const SsoResetPasswordTokenExpiredPage: React.FC<SsoResetPasswordTokenExpiredPageProps> =
  ({
    failureCode,
    forgotPassword,
    ssoRedirect = utils.ssoRedirect,
    ssoApiReturnTo = utils.ssoApiReturnTo,
    success,
    useSearchParam = sharedHooks.useSearchParam,
  }) => {
    const redirectTo = useSearchParam(SearchParam.REDIRECT) || r.home();
    const shouldCloseWindow = useSearchParam(SearchParam.CLOSE) || "false";
    const returnTo = ssoApiReturnTo(redirectTo);
    const [email, setEmail] = useState<string>("");

    useSsoPageReset(SsoPage.forgotPassword);

    if (success) {
      ssoRedirect(
        r.ssoResetPasswordEmailSent({
          close: shouldCloseWindow,
          returnTo: redirectTo,
        })
      );
      return null;
    }

    return (
      <SsoChrome>
        <SsoForm.Form
          data-testid="reset-password-token-expired-form"
          onSubmit={(e): void => {
            e.preventDefault();
            forgotPassword({ email, returnTo });
          }}
        >
          <SsoForm.Field>
            <SsoForm.Title>{text.TOKEN_EXPIRED_TITLE}</SsoForm.Title>
            <SsoForm.P>{text.PASSWORD_TOKEN_EXPIRED}</SsoForm.P>
          </SsoForm.Field>
          <SsoForm.Field>
            <SsoForm.Label htmlFor="email-input">
              {text.EMAIL_LABEL}
            </SsoForm.Label>
            <SsoForm.Input
              data-testid="email-input"
              hasError={utils.isUsernameInError(failureCode)}
              id="email-input"
              onChange={(e): void => setEmail(e.target.value)}
              required
              type="email"
            />
          </SsoForm.Field>
          {failureCode && <SsoErrorMessage failureCode={failureCode} />}
          <SsoForm.ButtonsContainer>
            <SsoForm.Button data-testid="submit-button" type="submit">
              {text.SEND_AGAIN_BUTTON}
            </SsoForm.Button>
          </SsoForm.ButtonsContainer>
        </SsoForm.Form>
      </SsoChrome>
    );
  };
