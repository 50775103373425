export enum EraProSessionAction {
  SESSION_START = 1,
  DRAW_MODE_STOP = 2,
  SESSION_ACTIVE = 3,
  LOCKOUT_ACTIVE = 4,
  INACTIVE = 5,
  LOCK_OUT_START = 6,
  LOCK_OUT_STOP = 7,
}

export type Session = {
  action: EraProSessionAction;
  doseId: number;
  initialLockoutSeconds: number;
  initialTotalUsage: number;
  isActive: boolean;
  isLockedOut: boolean;
  lockoutSecondsRemaining: number;
  totalUsage: number;
  usageTowardCompletion: number;
};

export type LogEvent = {
  eventCrc: number;
  eventIndex: number;
  eventTime: string;
  eventTimeOriginal: string;
  eventTypeCode: LogType;
  eventValue: string;
};

export enum LogType {
  RESET_V1 = 64,
  ERROR = 65,
  RESET_V2 = 66,
  PUFF_V1 = 69,
  PUFF_V2 = 70,
  PUFF_V3 = 71,
  PUFF_V4 = 72,
  HEATER_SET_POINT_CHANGED = 74,
  LOCK = 75,
  UNLOCK = 76,
  BRIGHTNESS = 77,
  CHARGER_INSERTED = 80,
  CHARGER_REMOVED = 81,
  CHARGE_COMPLETE = 82,
  STATE_OF_CHARGE_CHANGED = 83,
  POD_INSERTED = 90,
  POD_REMOVED = 91,
  POD_TAG_READ = 93,
  MOTION_DATA = 103,
  BLUETOOTH_CONNECT = 110,
  BLUETOOTH_DISCONNECT = 111,
  BLUETOOTH_APP_COMMAND = 112,
  BLUETOOTH_NAME_CHANGED = 113,
  DFU_START = 120,
  TIME_SYNC = 130,
  BLE_ERROR = 136,
  ASSERT = 140,
  UNKNOWN = -1,
}

export const isPuffEventLog = (eventTypeCode: LogType): boolean => {
  return (
    eventTypeCode === LogType.PUFF_V1 ||
    eventTypeCode === LogType.PUFF_V2 ||
    eventTypeCode === LogType.PUFF_V3 ||
    eventTypeCode === LogType.PUFF_V4
  );
};

export type PodData = {
  podStatus: number;
  cmFieldVersion: number;
  fillerFieldVersion: number;
  deviceFieldVersion: number;
  productId: string | null;
  userPin: string | null;
  vendorId: string | null;
  fillerDefaultTemp: number;
  fillerRecommendedTempFlavor: number;
  fillerRecommendedTempVapor: number;
  userPreferredTemp: number;
  userPreferredSessionSize: number;
  userPreferredLockoutTime: number;
  autoSessionSize: number;
  autoSessionLockoutTime: number;
  currentSessionProgress: number;
  userPreferredSessionId: number;
  autoSessionId?: number;
  podType?: number;
  podId?: string;
};

export enum EncryptionExchangeSequence {
  SHARED_KEY = 1,
  PERIPHERAL_ID = 2,
  CENTRAL_ID = 3,
  COMBINED_ID = 4,
  COMBINED_ID_WITH_NONCE = 5,
  ACKNOWLEDGE = 6,
}

export enum BleDisData {
  SYSTEM_ID = 1,
  MODEL_NUMBER = 2,
  SERIAL_NUMBER = 3,
  FW_REVISION = 4,
  HW_REVISION = 5,
  SW_REVISION = 6,
  MANUF_NAME = 7,
  IEEE_CERT_DATA = 8,
  PNP_ID = 9,
}

export type FindMyPaxStatus = {
  mode: number;
  timeout: number;
};
