import { MainAppState } from "../../../main/types";

import { StrainReviewJson } from "../types/reviews";

type StrainReviewProps = {
  strainId?: string | null;
};

export const getStrainReview = (
  state: MainAppState,
  { strainId }: StrainReviewProps
): StrainReviewJson | null =>
  strainId ? state.consumerApi.strainReviews[strainId] : null;
