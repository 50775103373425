import * as t from "../types";

export const REPLAY_ACTION_USER_START = 1;
export const REPLAY_ACTION_USER_STOP = 2;
export const REPLAY_ACTION_CURRENT_STATE = 3;
export const REPLAY_ACTION_FINISHED = 4;
export const REPLAY_ACTION_TIMED_OUT = 5;
export const REPLAY_ACTION_ERROR = 6;
export const REPLAY_ACTION_CONFIGURE = 7;
export const REPLAY_ACTION_LOCK_OUT_EXPIRED = 8;

export const COLOR_MODE_DEFAULT: t.ColorMode = {
  animation: t.HEATING_STATE_COLOR_MODE_ANIMATIONS.DEFAULT,
  color1: {
    blue: 255,
    green: 255,
    red: 255,
  },
  color2: {
    blue: 0,
    green: 0,
    red: 0,
  },
  frequency: t.COLOR_MODE_FREQUENCIES.DISPLAY_FREQ_1HZ,
};
export const COLOR_MODE_OCEAN: t.ColorMode = {
  animation: t.HEATING_STATE_COLOR_MODE_ANIMATIONS.DISPLAY_HEATING_PLASMA,
  color1: {
    blue: 67,
    green: 82,
    red: 36,
  },
  color2: {
    blue: 255,
    green: 0,
    red: 0,
  },
  frequency: t.COLOR_MODE_FREQUENCIES.DISPLAY_FREQ_0P5HZ,
};
export const COLOR_MODE_SUNSET: t.ColorMode = {
  animation: t.HEATING_STATE_COLOR_MODE_ANIMATIONS.DISPLAY_HEATING_PLASMA,
  color1: {
    blue: 40,
    green: 149,
    red: 253,
  },
  color2: {
    blue: 136,
    green: 0,
    red: 153,
  },
  frequency: t.COLOR_MODE_FREQUENCIES.DISPLAY_FREQ_0P75HZ,
};
export const COLOR_MODE_MARS: t.ColorMode = {
  animation: t.HEATING_STATE_COLOR_MODE_ANIMATIONS.DISPLAY_HEATING_PLASMA,
  color1: {
    blue: 0,
    green: 0,
    red: 255,
  },
  color2: {
    blue: 0,
    green: 0,
    red: 0,
  },
  frequency: t.COLOR_MODE_FREQUENCIES.DISPLAY_FREQ_0P5HZ,
};
