import { connect } from "react-redux";

import * as t from "../../main/types";

import { createMailingListSubscription } from "../actions";
import { MailingListForm as Component } from "../components/MailingListForm";

const mapStateToProps = (state: t.MainAppState) => ({
  isCreatingSubscription: state.mailingList.isCreatingSubscription,
});

const mapDispatchToProps = {
  createMailingListSubscription,
};

export const MailingListForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);
