import React from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import styled, { css } from "styled-components";

import * as PaxBle from "../../../pax-ble";

import { getConnectedDevice } from "../../bluetooth/connectedDevice";
import BatteryIndicator from "../../device/components/BatteryIndicator";
import MobileThermostat from "../../device/containers/MobileThermostat/MobileThermostat";
import * as selectors from "../../device/selectors";
import { getIsDeviceExperienceModesCompatible } from "../../firmware/selectors";
import * as r from "../../main/routes";
import { getIsFirmwareModalOpen } from "../../modal/selectors";
import { Row } from "../../shared/components/Flex";
import * as Text from "../../shared/components/Text";
import { VaporEffect } from "../../shared/DynamicVisualization/components/VaporEffect";
import { VisualizationPage } from "../../shared/DynamicVisualization/components/VisualizationPage";
import * as t from "../../shared/text";
import { desktopBreakpoint } from "../../shared/utils";

import { VAPOR_EFFECT_COLOR_MAP } from "../constants";
import * as SC from "../StyledComponents";
import { ModeInformation } from "./ModeInformation";
import { ModuleBottomSheet } from "./ModuleBottomSheet";
import { SessionInformationCard } from "./SessionInformationCard";

export const ExperienceModesPage: React.FC = () => {
  const isFirmwareModalOpen = useSelector(getIsFirmwareModalOpen);
  const isDeviceConnected = useSelector(selectors.getIsDeviceConnected);
  const isDeviceExperienceModesCompatible = useSelector(
    getIsDeviceExperienceModesCompatible
  );
  const activeUiFunction = useSelector(selectors.getActiveUiFunction);
  const deviceName = useSelector(selectors.getDeviceName);
  const isCustomTemperatureModeActive = useSelector(
    selectors.getIsCustomTemperatureModeActive
  );

  const device = getConnectedDevice() as PaxBle.EraProDevice;

  if (!isFirmwareModalOpen && !isDeviceConnected) {
    return <Redirect to={r.device()} />;
  }

  if (!isDeviceExperienceModesCompatible) {
    return <Redirect to={r.device(PaxBle.Types.DeviceType.ERA_PRO)} />;
  }

  if (!activeUiFunction || !device.vaporEngine) return null;

  const mainControls = isCustomTemperatureModeActive ? (
    <sc.MobileThermostatContainer>
      <MobileThermostat />
    </sc.MobileThermostatContainer>
  ) : (
    <>
      <ModeInformation activeUiFunction={activeUiFunction} />
      <sc.ButtonRow>
        <SC.Button
          active={
            activeUiFunction === PaxBle.Types.VaporEngine.UiFunction.PETAL_1
          }
          onClick={() => device.vaporEngine?.writeActivePetalMode(1)}
        >
          {t.STEALTH}
        </SC.Button>
        <SC.Button
          active={
            activeUiFunction === PaxBle.Types.VaporEngine.UiFunction.PETAL_2
          }
          onClick={() => device.vaporEngine?.writeActivePetalMode(2)}
        >
          {t.EFFICIENCY}
        </SC.Button>
        <SC.Button
          active={
            activeUiFunction === PaxBle.Types.VaporEngine.UiFunction.PETAL_3
          }
          onClick={() => device.vaporEngine?.writeActivePetalMode(3)}
        >
          {t.BALANCED}
        </SC.Button>
        <SC.Button
          active={
            activeUiFunction === PaxBle.Types.VaporEngine.UiFunction.PETAL_4
          }
          onClick={() => device.vaporEngine?.writeActivePetalMode(4)}
        >
          {t.BOOST}
        </SC.Button>
      </sc.ButtonRow>
    </>
  );

  return (
    <>
      <sc.Container>
        <sc.PageHeader>
          <sc.PageHeaderLeft>
            <sc.DeviceType>{t.PAX_ERA_PRO}</sc.DeviceType>
            <sc.DeviceName>{deviceName}</sc.DeviceName>
          </sc.PageHeaderLeft>
          <sc.PageHeaderRight>
            <sc.BatteryIndicator />
          </sc.PageHeaderRight>
        </sc.PageHeader>
        <sc.MainView>{mainControls}</sc.MainView>
        <SessionInformationCard />
        <ModuleBottomSheet />
      </sc.Container>
      <VaporEffect
        backgroundColor={
          VAPOR_EFFECT_COLOR_MAP[activeUiFunction].backgroundColor
        }
        vaporColor={VAPOR_EFFECT_COLOR_MAP[activeUiFunction].vaporColor}
      />
    </>
  );
};

const sc = {
  BatteryIndicator: styled(BatteryIndicator)`
    color: var(--white);

    & > g > path:last-child {
      fill: var(--white);
    }
  `,

  ButtonRow: styled(Row)`
    justify-content: space-between;
    margin-bottom: 20px;
    width: 100%;
  `,

  Container: styled(VisualizationPage)`
    display: flex;
    flex-direction: column;
    height: calc(100vh - var(--mobile-nav-bar-height));
    max-width: 1036px;
    padding: 16px;

    ${desktopBreakpoint(
      css`
        height: 100vh;
        margin: 20px auto 0;
        padding: 0;
      `
    )}
  `,

  DeviceName: styled.div`
    ${Text.PlaaxBold}
    color: var(--white);
    font-size: 14px;
    line-height: 14px;
  `,

  DeviceType: styled.div`
    ${Text.PlaaxBold}
    color: var(--white-50);
    font-size: 10px;
    line-height: 12px;
  `,

  MainView: styled.div`
    flex: 1;
  `,

  MobileThermostatContainer: styled.div`
    display: flex;
    justify-content: center;
  `,

  PageHeader: styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
  `,

  PageHeaderLeft: styled.div`
    align-items: baseline;
    display: flex;
    flex: 1 1;
    flex-direction: column;
    min-width: 0;
  `,

  PageHeaderRight: styled.div`
    align-items: center;
    display: flex;
  `,

  Text: styled.div`
    ${Text.PlaaxBold}
    color: var(--white);
    font-size: 12px;
    line-height: 18px;
  `,
};
