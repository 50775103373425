import React, { Dispatch, SetStateAction } from "react";

import * as ps from "../../../shared/components/PillSelect";
import { useDesktopMediaQuery } from "../../../shared/hooks";
import * as t from "../../../shared/text";

import { StrainsFilter } from "../../types";
import { getEffectOptions, handlePillOptionChange } from "../../utils";

import * as SC from "./styledComponents";

type PodsEffectsFilterProps = {
  filterUpdate: StrainsFilter;
  setFilterUpdate: Dispatch<SetStateAction<StrainsFilter>>;
};

export const PodsEffectsFilter: React.FC<PodsEffectsFilterProps> = ({
  filterUpdate,
  setFilterUpdate,
}) => {
  const isDesktop = useDesktopMediaQuery();

  return (
    <SC.Filter>
      <SC.Label>{t.EFFECT}</SC.Label>
      <ps.PillSelect
        Container={SC.IconPillSelectContainer}
        onChange={(options) =>
          handlePillOptionChange(
            options,
            filterUpdate.selectedEffects,
            "selectedEffects",
            filterUpdate,
            setFilterUpdate
          )
        }
        options={getEffectOptions(filterUpdate.selectedEffects)}
        PillOption={isDesktop ? SC.RowIconPillOption : SC.IconPillOption}
      />
    </SC.Filter>
  );
};
