import { ErrorCode } from "../api/consumer/types";
import { Profile } from "../api/consumer/types/user";

export type CreateAccountRequest = {
  anonymousUuid: string;
  email: string;
  mailingList?: string;
  password: string;
  profile?: Profile;
  returnTo?: string;
};

export type CreatedEmailVerificationRequest = {
  email: string;
  expiresAt: string;
  id: string;
  sentAt: string;
};

export type EmailVerificationRequest = {
  email: string;
  returnTo?: string;
};

export type ForgotPasswordRequest = {
  email: string;
  returnTo?: string;
};

export type LoginRequest = {
  anonymousUuid: string;
  email: string;
  password: string;
};

export type ResetPasswordRequest = {
  anonymousUuid: string;
  email: string;
  password: string;
  token: string;
};

export type SsoPageState = {
  failureCode?: ErrorCode;
  initialEmail?: string;
  success?: boolean;
};

type EndSsoFlow = (redirectTo: string, shouldClose?: string) => void;

export type GoToSso = (
  ssoRoute: string,
  openWindow?: (url?: string, target?: string) => Window | null
) => void;

export type SsoApiReturnTo = (returnToPath: string) => string;

export type SsoRedirect = (redirectTo: string) => void;

export type SsoProps = {
  endSsoFlow?: EndSsoFlow;
  goToSso?: GoToSso;
  ssoApiReturnTo?: SsoApiReturnTo;
  ssoRedirect?: SsoRedirect;
};

export enum SsoPage {
  createAccount = "createAccount",
  forgotPassword = "forgotPassword",
  login = "login",
  resetPassword = "resetPassword",
  sendEmailVerificationRequest = "sendEmailVerificationRequest",
  updateUser = "updateUser",
  verifyEmail = "verifyEmail",
}

export type SsoState = {
  [SsoPage.createAccount]: SsoPageState;
  [SsoPage.forgotPassword]: SsoPageState;
  [SsoPage.login]: SsoPageState;
  [SsoPage.resetPassword]: SsoPageState;
  [SsoPage.sendEmailVerificationRequest]: SsoPageState;
  [SsoPage.verifyEmail]: SsoPageState;
  [SsoPage.updateUser]: SsoPageState;
};
