import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import * as PaxBle from "../../../../pax-ble";

import { getConnectedDevice } from "../../../bluetooth/connectedDevice";
import { getIsDosePulseActive } from "../../../device/selectors";
import * as Text from "../../../shared/components/Text";
import * as t from "../../../shared/text";

import { ModuleCard } from "./ModuleCard";

export const DosePulseModule: React.FC = () => {
  const eraProDevice = getConnectedDevice() as PaxBle.EraProDevice;

  const isDosePulseActive = useSelector(getIsDosePulseActive);

  return (
    <ModuleCard
      isActive={isDosePulseActive}
      onClick={() => eraProDevice.vaporEngine?.setDosePulse(!isDosePulseActive)}
      title={t.DOSE_PULSE}
    >
      <sc.Container>
        <sc.OuterCircle isActive={isDosePulseActive}>
          <sc.InnerCircle isActive={isDosePulseActive}>
            <sc.Text>{isDosePulseActive ? t.ON : t.OFF}</sc.Text>
          </sc.InnerCircle>
        </sc.OuterCircle>
      </sc.Container>
    </ModuleCard>
  );
};

type isActiveProps = {
  isActive: boolean;
};

const sc = {
  Container: styled.div`
    align-items: center;
    display: flex;
    flex: 1;
    justify-content: center;
    padding: 16px;
  `,

  InnerCircle: styled.div<isActiveProps>`
    align-items: center;
    background-color: ${({ isActive }) =>
      isActive ? "var(--white)" : "var(--black)"};
    border-radius: 32px;
    color: ${({ isActive }) => (isActive ? "var(--black)" : "var(--white)")};
    display: flex;
    height: 64px;
    justify-content: center;
    width: 64px;
  `,

  OuterCircle: styled.div<isActiveProps>`
    align-items: center;
    background-color: ${({ isActive }) =>
      isActive ? "var(--white-60)" : "var(--black-60)"};
    border-radius: 50px;
    display: flex;
    height: 100px;
    justify-content: center;
    width: 100px;
  `,

  Text: styled.div`
    ${Text.PlaaxBold}
    font-size: 16px;
  `,
};
