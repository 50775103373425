import { Field, Form } from "formik";
import styled, { css, SimpleInterpolation } from "styled-components";

import LocationPin from "../assets/images/location_pin.png";
import { Button } from "../shared/components/Button";
import { Column, Row } from "../shared/components/Flex";
import { Heart } from "../shared/components/Icons";
import * as Text from "../shared/components/Text";
import { desktopBreakpoint } from "../shared/utils";

export const FavoriteIcon = styled(Heart)`
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: var(--z-index-favorite-heart);

  > path {
    fill: var(--very-light-pink-6);
    stroke: var(--very-light-pink-6);
  }
`;

const InputStyles = css`
  ${Text.PlaaxLight}
  border: solid 1px var(--very-light-pink);
  border-radius: 20px;
  font-size: 16px;
  height: 40px;
  justify-content: flex-start;
  margin: 8px 0;
  outline: none;
  padding: 0 68px 0 48px;
  width: 100%;

  &:focus,
  &:hover {
    border-color: var(--black);
  }
`;

export const LegalFooter = styled.div`
  margin-top: auto;
  padding-top: 20px;
`;

export const LegalText = styled.div`
  ${Text.Plaax}
  color: var(--black);
  font-size: 12px;
  letter-spacing: -0.09px;
  line-height: 1;
  opacity: 0.3;
`;

export const PodCardContainer = styled.div`
  display: flex;
  flex: 0 1 ${(props) => props.theme.podCard.width};
  margin-right: 16px;
  min-width: ${(props) => props.theme.podCard.width};

  &:last-child {
    margin-right: 0;
  }
`;

export const PodGridContainer = styled.div`
  margin: 0 auto;

  ${desktopBreakpoint(
    css`
      flex: 1;
      margin: 0;
    `
  )}
`;

export const PodGridRow = styled(Row)`
  padding-top: 2px;

  &:first-child {
    padding-left: 2px;
  }
`;

type ButtonProps = {
  disabled: boolean;
};

type FooterProps = {
  hideDivider?: boolean;
};

export const PodsLocationInputComponents = {
  Button: styled(Button)<ButtonProps>`
    ${Text.PlaaxLight}
    flex: 1;
    height: 40px;
    opacity: ${({ disabled }): string => (disabled ? "0.3" : "1")};
    text-transform: capitalize;
    width: 158px;
  `,

  Clear: styled.div`
    position: absolute;
    right: 30px;
    top: 19px;

    &:hover {
      cursor: pointer;
    }
  `,

  Error: styled(Text.ErrorSpan)`
    font-style: normal;
  `,

  Field: styled(Field)`
    ${InputStyles}
    background: url(${LocationPin}) no-repeat scroll 12px center;
    border-color: var(--black);
    color: var(--black);
    position: relative;
  `,

  Footer: styled.div<FooterProps>`
    align-items: center;
    border-top: ${(props): SimpleInterpolation =>
      props.hideDivider ? "none" : "solid 1px var(--black-10)"};
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: auto;
    padding: 16px 0;
    width: calc(100% + 32px);

    ${desktopBreakpoint(css`
      border-top: none;
      padding: 20px 0 0;
      width: 100%;
    `)}
  `,

  Form: styled(Form)`
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: column;
  `,

  FormTop: styled(Column)`
    align-items: stretch;
    max-width: 500px;
    position: relative;
    width: 100%;
  `,
};

export const Page = styled(Column)`
  flex: 1;
  margin: 0 auto;
  max-width: ${(props) => props.theme.pageWidth};
  min-height: calc(100% - var(--mobile-nav-bar-height));

  ${desktopBreakpoint(
    css`
      max-width: 1240px;
      min-height: calc(100vh - 80px);
    `
  )}
`;

export const DetailsPage = styled(Page)`
  margin-top: 16px;
  min-height: ${(props) => props.theme.exploreDetailsPage.minHeight};
  padding: 0 16px 20px;
  position: relative;

  ${desktopBreakpoint(
    css`
      max-width: ${(props) => props.theme.pageWidth};
      min-height: ${(props) => props.theme.exploreDetailsPage.minHeight};
      padding: 0;
    `
  )}
`;

export const PodsHomeContentContainer = styled.div`
  display: block;
  margin: 0 16px;

  ${desktopBreakpoint(
    css`
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin: 0;
    `
  )}
`;

export const PodsCarouselContainer = styled.div`
  ${desktopBreakpoint(
    css`
      flex: 1;
      overflow-x: hidden;
      padding-bottom: 2px;
      padding-left: 6px;
    `
  )}
`;

export const PodSectionTitleStyles = css`
  ${Text.MediumLLBold}
  font-size: 16px;
  line-height: 22px;
  text-transform: uppercase;
`;
