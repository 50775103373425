import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";

import { desktopBreakpoint } from "../utils";
import { Row } from "./Flex";
import * as Icons from "./Icons";
import { TouchTarget } from "./TouchTarget";

type ToastProps = {
  isDismissable?: boolean;
  onClick?: () => unknown;
  timeout?: number;
};

export const Toast: React.FC<ToastProps> = ({
  isDismissable = true,
  timeout,
  ...props
}) => {
  const [shouldUnmount, setShouldUnmount] = useState<boolean>(false);

  useEffect(() => {
    if (!timeout) return;

    const unmountTimeout = setTimeout(() => setShouldUnmount(true), timeout);

    return () => clearTimeout(unmountTimeout);
  }, [timeout]);

  const handleCloseClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setShouldUnmount(true);
  };

  if (shouldUnmount) return null;

  return (
    <sc.Container {...props}>
      {isDismissable && (
        <sc.TouchTarget onClick={handleCloseClick}>
          <Icons.Clear color="var(--white)" size={16} />
        </sc.TouchTarget>
      )}
      <Row>{props.children}</Row>
    </sc.Container>
  );
};

type ContainerProps = {
  onClick?: () => unknown;
};

const sc = {
  Container: styled.div<ContainerProps>`
    align-items: center;
    background: var(--black);
    border-radius: 16px;
    bottom: calc(var(--mobile-nav-bar-height) + 8px);
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.32);
    color: var(--white);
    cursor: ${({ onClick }) => (onClick ? "pointer" : "auto")};
    display: flex;
    height: 102px;
    padding: 16px;
    position: fixed;
    right: 50%;
    transform: translateX(50%);
    width: 344px;
    z-index: var(--z-index-toast);

    ${desktopBreakpoint(css`
      bottom: 32px;
      right: 32px;
      transform: unset;
    `)}
  `,

  TouchTarget: styled(TouchTarget)`
    position: absolute;
    right: -4px;
    top: -4px;
    z-index: var(--z-index-toast-closer);

    ${desktopBreakpoint(css`
      right: 12px;
      top: 12px;
    `)}
  `,
};
