import { isEqual } from "lodash";
import React, { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { CSSTransition } from "react-transition-group";
import styled, { css } from "styled-components";

import { trackEffectsClick } from "../../../analytics/pods/effectsClick";
import * as consumerTypes from "../../../api/consumer/types";
import { EffectJson } from "../../../api/consumer/types/strain";
import { ChevronCard } from "../../../shared/components/ChevronCard";
import { Column, Row } from "../../../shared/components/Flex";
import { PartnerLogoWithCheckmark } from "../../../shared/components/PartnerLogoWithCheckmark";
import * as Text from "../../../shared/components/Text";
import { TextButton } from "../../../shared/components/TextButton";
import * as text from "../../../shared/text";
import { desktopBreakpoint } from "../../../shared/utils";

import * as c from "../../constants";
import { PodSectionTitleStyles } from "../../styledComponents";

import * as effectsUtils from "./utils";

const MAX_DEFAULT_EFFECTS_SHOWN = 2;
const TRANSITION_TIMEOUT = 300;

export type EffectsProps = {
  onEffectClick: (effectData: EffectJson) => void;
  strain: consumerTypes.StrainJson;
};

const EffectsComponent: React.FC<EffectsProps> = ({
  onEffectClick,
  strain,
}) => {
  const dispatch = useDispatch();

  const [expanded, setExpanded] = useState<boolean>(false);

  const { effectData, numEffectReviews } = strain;
  const arrayString = JSON.stringify(effectData);

  const sortedEffectData = useMemo(
    () =>
      // Sort effects by their percentage
      [...effectData].sort((a, b) => {
        if (a.percentEffect < b.percentEffect) {
          return 1;
        }
        if (a.percentEffect > b.percentEffect) {
          return -1;
        }
        return 0;
      }),
    // only sort when effectData elements change, we need to compare the stringified version
    // because putting the array itself will always trigger a rerender
    // https://github.com/facebook/react/issues/14476#issuecomment-471199055
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [arrayString]
  );

  const handleEffectClick = (effectData: EffectJson, index: number): void => {
    dispatch(trackEffectsClick(effectData, strain, index + 1));

    onEffectClick(effectData);
  };

  if (!effectsUtils.shouldShowEffects(effectData, numEffectReviews))
    return null;

  return (
    <>
      <sc.Title>{text.EFFECTS}</sc.Title>
      <sc.SubHeader>
        {effectsUtils.getEffectSummary(sortedEffectData)}
      </sc.SubHeader>
      <Column>
        {sortedEffectData.map((data, index) => {
          return (
            <CSSTransition
              key={data.effect}
              in={expanded || index < MAX_DEFAULT_EFFECTS_SHOWN}
              classNames="fade"
              timeout={TRANSITION_TIMEOUT}
              unmountOnExit
            >
              <sc.ChevronCard
                data-testid="selected-effects-card"
                key={data.effect}
                onClick={() => handleEffectClick(data, index)}
              >
                <sc.Row>
                  {c.EFFECTS_ICON_MAP[data.effect]}
                  <sc.CardContentColumn>
                    <sc.CardHeader>
                      {c.EFFECTS_TEXT_MAP[data.effect]}
                    </sc.CardHeader>
                    {data.percentEffect > 0 && (
                      <sc.CardSubHeader>
                        {text.percerntOfPeopleFeltThis(data.percentEffect)}
                      </sc.CardSubHeader>
                    )}
                  </sc.CardContentColumn>
                  {data.wasBpSelected && (
                    <PartnerLogoWithCheckmark partner={strain.partner} />
                  )}
                </sc.Row>
              </sc.ChevronCard>
            </CSSTransition>
          );
        })}
        {effectData.length > 2 && (
          <sc.TextButton onClick={() => setExpanded((prev) => !prev)}>
            {expanded ? text.SEE_LESS : text.SEE_MORE}
          </sc.TextButton>
        )}
      </Column>
    </>
  );
};

const compareProps = (
  {
    strain: {
      effectData: prevEffectData,
      numEffectReviews: prevNumEffectReviews,
      partner: prevPartner,
    },
  }: EffectsProps,
  {
    strain: {
      effectData: nextEffectData,
      numEffectReviews: nextNumEffectReviews,
      partner: nextPartner,
    },
  }: EffectsProps
) => {
  return (
    isEqual(prevEffectData, nextEffectData) &&
    prevPartner === nextPartner &&
    prevNumEffectReviews === nextNumEffectReviews
  );
};

export const Effects = React.memo(EffectsComponent, compareProps);

const sc = {
  CardContentColumn: styled(Column)`
    flex-grow: 1;
    padding: 0 16px;

    & :first-child {
      margin-bottom: 2px;
    }
  `,

  CardHeader: styled.div`
    font-size: 14px;
    letter-spacing: -0.105px;
    line-height: 20px;
  `,

  CardSubHeader: styled.div`
    ${Text.MediumLLMedium}
    font-size: 14px;
    letter-spacing: -0.105px;
    line-height: 20px;
  `,

  ChevronCard: styled(ChevronCard)`
    margin: 8px 0;
    padding: 16px 20px;

    &.fade-enter {
      opacity: 0.01;
    }

    &.fade-enter-active {
      opacity: 1;
      transition: opacity ${TRANSITION_TIMEOUT}ms ease-in;
    }

    &.fade-exit {
      opacity: 1;
    }

    &.fade-exit-active {
      opacity: 0.01;
      transition: opacity ${TRANSITION_TIMEOUT}ms ease-in;
    }

    ${desktopBreakpoint(
      css`
        height: 82px;
      `
    )}
  `,

  Row: styled(Row)`
    align-items: center;
    padding-right: 15px;
    position: relative;
    width: 100%;
  `,

  SubHeader: styled.div`
    ${Text.MediumLLLight}
    font-size: 14px;
    letter-spacing: -0.105px;
    line-height: 18px;
    margin-bottom: 8px;
  `,

  TextButton: styled(TextButton)`
    align-self: baseline;
    margin-top: 8px;
  `,

  Title: styled.div`
    ${PodSectionTitleStyles}
    margin-bottom: 8px;
  `,
};
