export enum DeviceModel {
  ERA = "ERA",
  ERA_PRO = "ERA_PRO",
  PAX_3 = "PAX_3",
  PAX_3_5 = "PAX_3_5",
}

export enum DeviceShellColor {
  AMBER = "AMBER",
  BLUE = "BLUE",
  BURGUNDY = "BURGUNDY",
  GLOSSY_BLACK = "GLOSSY_BLACK",
  GLOSSY_GOLD = "GLOSSY_GOLD",
  GLOSSY_RED = "GLOSSY_RED",
  GLOSSY_ROSE_GOLD = "GLOSSY_ROSE_GOLD",
  GLOSSY_SILVER = "GLOSSY_SILVER",
  MATTE_BLACK = "MATTE_BLACK",
  MATTE_FUCHSIA = "MATTE_FUCHSIA",
  MATTE_JADE = "MATTE_JADE",
  MATTE_RED = "MATTE_RED",
  MATTE_ROSE_GOLD = "MATTE_ROSE_GOLD",
  MATTE_SILVER = "MATTE_SILVER",
  MATTE_TEAL = "MATTE_TEAL",
  MIDNIGHT_RAIN = "MIDNIGHT_RAIN",
  OCEAN = "OCEAN",
  ONYX = "ONYX",
  SAGE = "SAGE",
  SAND = "SAND",
  SAPPHIRE = "SAPPHIRE",
  SHADOW_RAIN = "SHADOW_RAIN",
}

export type DeviceRequest = {
  appType: "WEB";
  clientTimestamp: string;
  device: PostDeviceJson;
};

export type DeviceLocation = {
  appType: "ANDROID" | "WEB";
  latitude: number;
  longitude: number;
  setAt: string;
};

type EncryptionContext = {
  centralId: string;
  centralNonce: string;
  peripheralId: string;
  peripheralNonce: string;
  serviceUuid: string;
  sharedKey: string;
};

// Deprecated - Is now at the User level
type DataSharingPreferences = {
  setAt: string;
  shareData?: boolean;
  shareLocationData?: boolean;
};

export type PostDeviceJson = {
  dataSharingPreferences?: DataSharingPreferences;
  deviceName: string;
  encryptionKey?: EncryptionContext | null;
  lastLogReadIndex?: number;
  location?: DeviceLocation;
  logSyncOffset?: number;
  model: DeviceModel;
  serialNumber: string;
  shellColor: DeviceShellColor;
};

export type DeviceJson = {
  dataSharingPreferences?: DataSharingPreferences;
  desiredFirmwareVersion?: {
    version: string;
    blob: {
      downloadUrl: string;
      fileId: string;
    };
    datePublished: string;
  };
  deviceName: string;
  earliestClientTimestamp: string;
  encryptionKey?: EncryptionContext;
  lastLogReadIndex?: number;
  latestClientTimestamp: string;
  location?: DeviceLocation;
  logSyncOffset: number;
  model: DeviceModel;
  peripheralId: string;
  serialNumber: string;
  shellColor: DeviceShellColor;
};
