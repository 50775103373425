import { createSelector } from "reselect";

import { MainAppState } from "../../../main/types";

import { FlashBannerState } from "./reducer";

export const getFlashBanner = (state: MainAppState): FlashBannerState => {
  return {
    ...state.flashBanner,
    timeout: state.flashBanner.timeout,
  };
};

export const getShouldShowFlashBanner = createSelector(
  getFlashBanner,
  (flashBanner) => !!flashBanner.message
);

export const getFlashBannerMessage = (state: MainAppState): string | null =>
  state.flashBanner.message;
