import { connect } from "react-redux";

import * as cookies from "../../cookies";
import { MainAppState } from "../../main/types";

import { createAccount } from "../actions";
import { SsoCreateAccountPage } from "../components/SsoCreateAccountPage";

const mapStateToProps = (state: MainAppState) => {
  return {
    anonymousUuid: cookies.getAnonymousUserUuid(),
    ...state.sso.createAccount,
  };
};

const mapDispatchToProps = {
  createAccount,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SsoCreateAccountPage);
