import { set as lsSet } from "local-storage";

import * as c from "../constants";
import { BaseLogStore, LogEventAnalyticsType } from "../shared/BaseLogStore";
import * as t from "../types";
import {
  getEraLogRequestFromLocalStorage,
  getLogStoreAnalyticsData,
} from "../utils";
import { EraDevice } from "./EraDevice";

const DEFAULT_LOG_REQUEST_OPTIONS = {
  eventIndex: 0,
  timestamp: 0,
  timestampOffset: 0,
};

const LOG_EVENT_OFFSETS = {
  DATA: 0,
  TIME: 4,
  TYPE: 3,
};

export type EraLogRequestOptions = {
  eventIndex?: number;
  timestampOffset: number;
  timestamp: number;
};

export class EraLogStore extends BaseLogStore {
  logNotify?: BluetoothRemoteGATTCharacteristic;
  options: EraLogRequestOptions;
  timeSyncBuffer: Uint8Array = new Uint8Array(4);

  constructor(device: EraDevice) {
    super(device);
    this.options = this.getLogRequestOptionsFromLocalStorage();
  }

  async initialize(): Promise<void> {
    const logService = await this.getLogService(c.ERA_LOG_SERVICE_UUID);

    this.logNotify = await logService.getCharacteristic(
      c.ERA_CHARACTERISTICS.LogNotify
    );

    this.logRead = await logService.getCharacteristic(
      c.ERA_CHARACTERISTICS.LogRead
    );

    this.logNotify.addEventListener(
      "characteristicvaluechanged",
      this.handleLogsReceived
    );
    await this.logNotify.startNotifications();
  }

  disconnect(): void {
    this.logNotify?.removeEventListener(
      "characteristicvaluechanged",
      this.handleLogsReceived
    );
  }

  fetchLogs = (): void => {
    // Log requests for ERA devices require a timestamp and an offset.
    // The first argument is the timestamp of the log event being requested.
    // There can be multiple log events for a given timestamp.
    // The second argument is the offset of the log event needed at that timestamp.
    // If no log event exists for the given timestamp and offset, the next event is returned.

    if (!this.device.connected || !this.shouldFetchLogs) return;

    this.device.writeAttribute(c.ATTRIBUTE_LOG_SYNC_REQUEST, {
      timestamp: this.options.timestamp,
      timestampOffset: this.options.timestampOffset,
    });
  };

  handleLogsReceived = async (event: Event): Promise<void> => {
    if (!event || !event.target || !this.logRead) {
      return;
    }

    const value = await this.logRead.readValue();
    const buffer = new Uint8Array(value.buffer);

    if (buffer.length === 0) {
      return;
    }

    this.device.events.emit("onLogReceived");
    this.parseLogEvents(buffer);

    setTimeout(this.fetchLogs, c.LOG_FETCH_TIMEOUT);
  };

  parseLogEvents = (buffer: Uint8Array): void => {
    /************** Log Event Format ************
     *    data     *     type      *    time    *
     *   3 bytes   *    1 byte     *   4 bytes  *
     ********************************************/

    if (buffer.length === 0) return;

    while (buffer.length > 0) {
      const view: DataView = new DataView(buffer.buffer);

      const eventValueBuffer: Uint8Array = new Uint8Array(4);

      // Need to add extra '0' byte so we can parse as Uint32
      eventValueBuffer.set(buffer.slice(LOG_EVENT_OFFSETS.DATA, 3), 0);
      eventValueBuffer.set([0], 3);
      const eventValueView: DataView = new DataView(eventValueBuffer.buffer);

      const eventTime = view.getUint32(LOG_EVENT_OFFSETS.TIME, true);

      const event: t.Era.LogEvent = {
        eventIndex: this.options.eventIndex,
        eventTime,
        eventTimeOriginal: eventTime,
        eventTypeCode: view.getUint8(LOG_EVENT_OFFSETS.TYPE),
        eventValue: eventValueView.getUint32(0, true),
      };

      if (event.eventTypeCode === t.Era.LogType.RESET) {
        // Reset logs occur after the device chip resets, and this will always result
        // in the system clock reverting to 0. We assume all events before the reset
        // and after any previous time sync have a valid time because we cannot know how
        // much time passed between the log before a reset and the reset itself

        this.timeSyncLogs(0, LogEventAnalyticsType.RESET_EVENT);
      }

      this.unsyncedEvents.push(event);

      if (event.eventTypeCode === t.Era.LogType.TIME_SYNC_HIGH) {
        this.timeSyncBuffer.set(eventValueBuffer.slice(0, 2), 2);
      }

      if (event.eventTypeCode === t.Era.LogType.TIME_SYNC_LOW) {
        this.timeSyncBuffer.set(eventValueBuffer.slice(0, 2));

        const timeSync = new DataView(this.timeSyncBuffer.buffer).getUint32(
          0,
          true
        );
        const timeDelta = timeSync - event.eventTime;

        this.timeSyncLogs(timeDelta, LogEventAnalyticsType.TIME_SYNC_EVENT);
      }

      this.options.timestampOffset++;

      if (event.eventTime !== this.options.timestamp) {
        this.options.timestamp = event.eventTime;
        this.options.timestampOffset = 1;
      }

      if (typeof this.options.eventIndex !== "undefined") {
        this.options.eventIndex++;
      }

      buffer = buffer.slice(8);
    }
  };

  timeSyncLogs(timeDelta: number, logEventType: LogEventAnalyticsType): void {
    const syncedLogEvents: t.Era.LogEvent[] = this.unsyncedEvents.map(
      (logEvent) => {
        const syncedLogEvent = { ...(logEvent as t.Era.LogEvent) };
        syncedLogEvent.eventTime += timeDelta;
        return syncedLogEvent;
      }
    );

    this.device.events.emit(
      "eventLogUpload",
      getLogStoreAnalyticsData(
        this.unsyncedEvents.length,
        logEventType,
        timeDelta
      )
    );

    this.device.events.emit("receivedLogEvents", {
      analyticsEvent: "log_event",
      syncedLogEvents,
    });

    this.setLogRequestOptionsToLocalStorage({
      ...this.options,
      timestampOffset: this.options.timestampOffset + 1,
    });

    this.unsyncedEvents = [];
  }

  getLogRequestOptionsFromLocalStorage(): EraLogRequestOptions {
    const localLogRequest = getEraLogRequestFromLocalStorage(
      this.device.systemId
    );

    // Initialize log event index request to 0
    if (localLogRequest) {
      localLogRequest.eventIndex = localLogRequest.eventIndex || 0;
    }

    return localLogRequest || DEFAULT_LOG_REQUEST_OPTIONS;
  }

  setLogRequestOptionsToLocalStorage(options: EraLogRequestOptions): void {
    if (!this.device.systemId) {
      throw new Error("device systemId not set");
    }

    lsSet<string>(
      `__paxWeb.deviceLogs.${this.device.systemId}.nextLogToRequest`,
      JSON.stringify(options)
    );
  }

  updateOffset(newTimestamp: number): void {
    if (this.options.timestamp >= newTimestamp) return;

    this.shouldFetchLogs = false;

    // Wait for current log process to finish.
    setTimeout(() => {
      this.options.timestamp = newTimestamp;
      this.options.timestampOffset = 0;
      this.timeSyncBuffer = new Uint8Array(4);
      this.unsyncedEvents = [];
      this.shouldFetchLogs = true;
      this.setLogRequestOptionsToLocalStorage({ ...this.options });
      this.fetchLogs();
    }, c.LOG_FETCH_TIMEOUT + 50);
  }
}
