import { ThunkDispatch } from "@reduxjs/toolkit";
import { remove as lsRemove, set as lsSet } from "local-storage";

import * as PaxBle from "../../pax-ble";
import { EraLogStore } from "../../pax-ble/era/EraLogStore";
import { EraProLogStore } from "../../pax-ble/era-pro/EraProLogStore";

import { trackEventAction } from "../analytics/actions";
import * as deviceAnalytics from "../analytics/device/device";
import * as consumerActions from "../api/consumer/actions";
import { fetch } from "../api/consumer/index";
import { podBatchResults } from "../api/consumer/paths/pods";
import { getShareDataFlagForUser } from "../api/consumer/selectors/user";
import * as apiTypes from "../api/consumer/types";
import * as connectedDevice from "../bluetooth/connectedDevice";
import { MainAppState } from "../main/types";
import { setHasSeenFirmwareModal } from "../modal/actions";
import { Action, AppThunk, GetState } from "../shared/types";
import * as c from "./constants";
import { getExpertTempC10 } from "./selectors";
import { NullableDeviceType } from "./types";
import * as u from "./utils";

export { connectToDevice } from "./actions/connectToDevice";
export type { ConnectToDevice } from "./actions/connectToDevice";

export type SelectDeviceType = (deviceType: NullableDeviceType) => Action;

export const selectDeviceType: SelectDeviceType = (deviceType) => ({
  payload: { deviceType },
  type: c.SELECT_DEVICE_TYPE,
});

export type LogEventNotification = {
  analyticsEvent: string;
  syncedLogEvents: PaxBle.Types.LogEvent[];
};

export const syncDeviceTime = (device: PaxBle.BaseDevice): Action => {
  const currentUTCTime: Date = new Date();
  const epochSecondsUTC: number = Math.round(currentUTCTime.getTime() / 1000);

  device.writeAttribute(PaxBle.Constants.ATTRIBUTE_TIME, epochSecondsUTC);

  return {
    type: c.DEVICE_TIME_SYNC,
  };
};

export const deviceRequested = (): Action => ({
  type: c.DEVICE_REQUESTED,
});

export type DeviceConnecting = () => AppThunk;

export const deviceConnecting: DeviceConnecting =
  () =>
  (dispatch, getState): void => {
    dispatch({
      type: c.DEVICE_CONNECTING,
    });

    deviceAnalytics.trackDeviceConnectionStatus(
      deviceAnalytics.AnalyticsDeviceConnectionStatus.CONNECTING
    )(dispatch, getState, null);
  };

type DeviceConnectedActionPayload = {
  firmwareRevision: string;
  deviceSerial?: {
    k3Serial?: string;
    k4Serial?: string;
    pax3Serial?: string;
  };
  podStrainId?: string;
};

type DeviceConnectedOptions = {
  serial?: string;
  manualPodStrainId?: string;
};

export const deviceConnected =
  (
    firmwareRevision = "",
    deviceType: NullableDeviceType,
    options?: DeviceConnectedOptions
  ): AppThunk =>
  (dispatch, getState): void => {
    deviceAnalytics.trackDeviceConnectionStatus(
      deviceAnalytics.AnalyticsDeviceConnectionStatus.CONNECTED,
      firmwareRevision
    )(dispatch, getState, null);
    deviceAnalytics.trackDeviceAdded()(dispatch, getState, null);

    const payload: DeviceConnectedActionPayload = { firmwareRevision };
    if (deviceType === PaxBle.Types.DeviceType.ERA && options?.serial) {
      payload.deviceSerial = { k3Serial: options?.serial };
      lsSet<string>(c.LS_KEY_K3_SERIAL, options?.serial);
    }

    if (deviceType === PaxBle.Types.DeviceType.ERA_PRO && options?.serial) {
      payload.deviceSerial = { k4Serial: options?.serial };
      lsSet<string>(c.LS_KEY_K4_SERIAL, options?.serial);
    }

    if (u.isPax3(deviceType) && options?.serial) {
      payload.deviceSerial = { pax3Serial: options?.serial };
      lsSet<string>(c.LS_KEY_P3_SERIAL, options?.serial);
    }

    lsSet<string>(c.LS_KEY_IS_DEVICE_CONNECTED, "true");

    if (options?.manualPodStrainId) {
      payload.podStrainId = options.manualPodStrainId;

      consumerActions.connectedPods.recordConnectedPod(
        options.manualPodStrainId
      )(dispatch, getState, null);
    }

    dispatch({ payload, type: c.DEVICE_CONNECTED });
  };

export const receivedAttribute = (
  attributeId: number,
  value: unknown
): Action => ({
  payload: { attributeId, value },
  type: c.RECEIVED_ATTRIBUTE,
});

export const restartDevice =
  (): AppThunk =>
  (dispatch, getState): Action => {
    const device = connectedDevice.getConnectedDevice();

    if (!device) dispatch({ type: c.DEVICE_RESTARTING });

    deviceAnalytics.trackDeviceConnectionStatus(
      deviceAnalytics.AnalyticsDeviceConnectionStatus.DISCONNECTING
    )(dispatch, getState, null);
    deviceAnalytics.trackDeviceRemoved()(dispatch, getState, null);

    connectedDevice.disconnectConnectedDevice();

    deviceAnalytics.trackDeviceConnectionStatus(
      deviceAnalytics.AnalyticsDeviceConnectionStatus.DISCONNECTED
    )(dispatch, getState, null);

    return dispatch({ type: c.DEVICE_RESTARTING });
  };

export type SetBrightness = (percentage: number) => AppThunk;

export const setBrightness: SetBrightness =
  (percentage) => async (): Promise<void> => {
    const device = connectedDevice.getConnectedDevice();
    await device?.writeBrightness(percentage);

    device?.readBrightness();
  };

export type SetManualIdPod = (strainId: string) => AppThunk;

export const setManualIdPod: SetManualIdPod =
  (strainId) =>
  (dispatch, getState): void => {
    lsSet<string>(c.LS_KEY_CURRENT_POD_STRAIN_ID, strainId);

    dispatch({
      payload: { strainId },
      type: c.SET_MANUAL_ID_POD,
    });

    consumerActions.connectedPods.recordConnectedPod(strainId)(
      dispatch,
      getState,
      null
    );
  };

export type ClearPodId = () => Action;

export const clearPodId: ClearPodId = () => {
  lsRemove(c.LS_KEY_CURRENT_POD_STRAIN_ID);

  return {
    type: c.CLEAR_CURRENT_POD,
  };
};

export type SetDeviceError = (error: string) => Action;

export const setDeviceError: SetDeviceError = (error) => ({
  payload: { error },
  type: c.DEVICE_ERROR,
});

export type SetDeviceName = (deviceName: string) => AppThunk;

export const setDeviceName: SetDeviceName =
  (deviceName) => async (): Promise<void> => {
    const device = connectedDevice.getConnectedDevice();
    await device?.writeDeviceName(deviceName);

    device?.readDeviceName();
  };

export type SetHaptics = (value: number) => AppThunk;

export const setHaptics: SetHaptics = (value) => async (): Promise<void> => {
  const device = connectedDevice.getConnectedDevice();
  await device?.writeHaptics(value);

  device?.readHaptics();
};

export type SetHeaterSetPoint = (
  temperature: number,
  unit: apiTypes.user.PreferredTemperatureUnit
) => void;

export const setHeaterSetPoint =
  (
    temperature: number,
    unit: apiTypes.user.PreferredTemperatureUnit
  ): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const device = connectedDevice.getConnectedDevice();
    if (!device || !device.connected) return;

    let heaterSetPoint: number;
    if (unit === apiTypes.user.PreferredTemperatureUnit.FAHRENHEIT) {
      heaterSetPoint = Math.round(
        u.fahrenheitToSetPoint(temperature, device.type)
      );
    } else {
      heaterSetPoint = Math.round(
        u.celsiusToSetPoint(temperature, device.type)
      );
    }

    // Set to exact expert temp if the value is within 2 degrees Celsius.
    const expertTempC10 = getExpertTempC10(getState());
    if (
      expertTempC10 &&
      Math.abs(heaterSetPoint - expertTempC10) <= c.PRESET_TEMP_BUFFER_C10
    ) {
      heaterSetPoint = expertTempC10;
    }

    const canCollectUsageData = getShareDataFlagForUser(getState());

    if (canCollectUsageData) {
      trackEventAction("temperature_set", {
        temperature: heaterSetPoint,
        thermostatTemperature: heaterSetPoint,
      })(dispatch, getState, null);
    }

    await device.writeHeaterSetPoint(heaterSetPoint);

    device.readHeaterSetPoint();
  };

export type SetSerial = (
  serial: string,
  deviceType: NullableDeviceType
) => Action;

export const setSerial: SetSerial = (serial, deviceType) => {
  if (deviceType === PaxBle.Types.DeviceType.ERA) {
    return {
      payload: { k3Serial: serial },
      type: c.SET_K3_SERIAL,
    };
  }

  if (deviceType === PaxBle.Types.DeviceType.ERA_PRO) {
    return {
      payload: { k4Serial: serial },
      type: c.SET_K4_SERIAL,
    };
  }

  if (u.isPax3(deviceType)) {
    return {
      payload: { pax3Serial: serial },
      type: c.SET_PAX_3_SERIAL,
    };
  }

  return { type: c.NO_OP };
};

export const toggleDeviceLock =
  (toggle: boolean): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const device = connectedDevice.getConnectedDevice();
    await device?.writeLockState(toggle ? 1 : 0);

    deviceAnalytics.trackLockSet(toggle)(dispatch, getState, null);

    device?.readLockState();
  };

export const updateLogOffsetInUserAccount =
  (
    device?: PaxBle.BaseDevice,
    updateLogOffsetForDevice = consumerActions.devices.updateLogOffsetForDevice,
    updateLogOffsetForEra = consumerActions.devices.updateLogOffsetForEra
  ): AppThunk =>
  async (dispatch, getState): Promise<unknown> => {
    if (!device || !device.supportsLogs || !device.serial) return;

    if (device.type === PaxBle.Types.DeviceType.ERA) {
      const logStore = device.logStore as EraLogStore;

      return updateLogOffsetForEra(
        logStore.options.timestamp,
        logStore.options.eventIndex || 0,
        device.serial
      )(dispatch, getState, null);
    } else if (device.type === PaxBle.Types.DeviceType.ERA_PRO) {
      const logStore = device.logStore as EraProLogStore;

      return updateLogOffsetForDevice(logStore.options.offset, device.serial)(
        dispatch,
        getState,
        null
      );
    }
  };

export const receivedLogEvents =
  (logsToBeSentToAnalytics: LogEventNotification): AppThunk =>
  (dispatch, getState): void => {
    const { analyticsEvent, syncedLogEvents } = logsToBeSentToAnalytics;
    const device = connectedDevice.getConnectedDevice();
    const canCollectUsageData = getShareDataFlagForUser(getState());

    syncedLogEvents.forEach((logEvent) => {
      if (
        !canCollectUsageData &&
        u.doesLogEventRequireOptIn(logEvent.eventTypeCode)
      ) {
        return;
      }

      trackEventAction(analyticsEvent, logEvent, {
        integrations: { "Google Analytics": false },
      })(dispatch, getState, null);
    });

    // Only update users account after logs have been synced and sent to analytics
    updateLogOffsetInUserAccount(device)(dispatch, getState, null);

    dispatch({
      type: c.RECEIVED_LOG_EVENTS,
    });
  };

export const setLogSyncingStatus = (isSyncing: boolean): Action => ({
  payload: { isSyncing },
  type: c.SET_SYNCING_STATUS,
});

export const setHasEncryptionErrors = (
  hasEncryptionErrors: boolean
): Action => ({
  payload: { hasEncryptionErrors },
  type: c.SET_HAS_ENCRYPTION_ERRORS,
});

export const fetchBatchResult =
  (podId: string): AppThunk =>
  async (dispatch): Promise<void> => {
    const path = podBatchResults({ podId });

    try {
      const response = await fetch<apiTypes.TestResultJson>(path);
      dispatch(receivedBatchResult(response));
    } catch (e) {
      // Ignore the error; UI doesn't need to respond.
    }
  };

const receivedBatchResult = (response: apiTypes.TestResultJson): Action => ({
  payload: { response },
  type: c.RECEIVED_BATCH_RESULT,
});

const deviceIsDisconnecting = (): Action => {
  return { type: c.DEVICE_DISCONNECTING };
};

const deviceIsDisconnected = (error?: string): Action => {
  return {
    payload: { error },
    type: c.DEVICE_DISCONNECTED,
  };
};

type DisconnectDeviceParams = { error?: string };

export type DisconnectDevice = (options?: DisconnectDeviceParams) => AppThunk;

let isDeviceDisconnecting = false;

export const disconnectDevice: DisconnectDevice = ({
  error,
}: DisconnectDeviceParams = {}) => {
  return async (
    dispatch: ThunkDispatch<MainAppState, unknown, Action>,
    getState: GetState
  ): Promise<Action> => {
    // For convenience, disconnectDevice is dispatched both when a disconnect is requested and when one occurs.
    // But we only want to disconnect the device once.
    if (isDeviceDisconnecting) {
      deviceAnalytics.trackDeviceConnectionStatus(
        deviceAnalytics.AnalyticsDeviceConnectionStatus.DISCONNECTING
      )(dispatch, getState, null);

      return dispatch(deviceIsDisconnecting());
    }

    isDeviceDisconnecting = true;
    connectedDevice.disconnectConnectedDevice();
    isDeviceDisconnecting = false;

    deviceAnalytics.trackDeviceConnectionStatus(
      deviceAnalytics.AnalyticsDeviceConnectionStatus.DISCONNECTED
    )(dispatch, getState, null);

    dispatch(setHasSeenFirmwareModal(false));

    return dispatch(deviceIsDisconnected(error));
  };
};

export type RemoveDeviceInUserAccount = (
  device: apiTypes.device.DeviceJson,
  removeDevice?: consumerActions.devices.RemoveDevice
) => AppThunk;

export const removeDeviceInUserAccount: RemoveDeviceInUserAccount =
  (
    device: apiTypes.device.DeviceJson,
    removeDevice = consumerActions.devices.removeDevice
  ): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    removeDevice(device)(dispatch, getState, null);
    deviceAnalytics.trackDeviceRemoved()(dispatch, getState, null);
  };
