import aesjs from "aes-js";

import * as u from "../utils";

export const ERA_AES_KEY_SIZE = 16;

// prettier-ignore
const AES_KEY = [0xF7, 0xC8, 0x66, 0xC3, 0x8F, 0x78, 0x75, 0x30, 0x86, 0x29, 0x3B, 0xD5, 0x7D, 0xD3, 0x25, 0x40];

export class LegacyDeviceEncryption {
  serial: string;
  initializationVector?: Uint8Array;
  key: Uint8Array;

  constructor(serial: string, initializationVector?: Uint8Array) {
    this.serial = serial;
    this.initializationVector = initializationVector;

    if (!this.initializationVector) {
      // Generate a random initialization vector of 16 bytes.
      this.initializationVector = aesjs.utils.utf8.toBytes(
        u.getUuid(ERA_AES_KEY_SIZE)
      );
    }

    // Construct the encryption key.
    // AES in ECB mode, no padding, trimmed to 16 bytes.
    const aesEcb = new aesjs.ModeOfOperation.ecb(AES_KEY);
    const serialBytes = aesjs.utils.utf8.toBytes(this.serial + this.serial);
    const encrypted = aesEcb.encrypt(serialBytes);
    this.key = encrypted.slice(0, ERA_AES_KEY_SIZE);
  }

  encrypt(message: Uint8Array): Uint8Array {
    if (!this.initializationVector)
      throw new Error("initializationVector is blank");

    const encrypted = this.aesEncrypt(message);

    // Return the encrypted message plus initialization vector.
    return u.mergeTypedArrays(
      encrypted,
      new Uint8Array(this.initializationVector)
    );
  }

  decrypt(message: Uint8Array): Uint8Array {
    const decrypted = this.aesEncrypt(message);
    return decrypted;
  }

  private aesEncrypt(message: Uint8Array): Uint8Array {
    if (!this.initializationVector)
      throw new Error("initializationVector is blank");

    // Encrypt the message with AES in OFB mode, no padding.
    const aesOfb = new aesjs.ModeOfOperation.ofb(
      this.key,
      this.initializationVector
    );

    return aesOfb.encrypt(message);
  }
}
