import React, { useEffect, useState } from "react";
import * as redux from "react-redux";
import styled, { css } from "styled-components";

import * as consumerApi from "../../api/consumer";
import { Modal, ModalProps } from "../../modal/components/Modal";
import { UseDispatch } from "../../shared/types";
import { desktopBreakpoint } from "../../shared/utils";

import * as c from "../constants";
import { PodFeedbackModalEditContent } from "./PodFeedbackModalEditContent";
import { PodFeedbackModalPreviewContent } from "./PodFeedbackModalPreviewContent";

export enum PodFeedbackModalType {
  EDIT = "EDIT",
  PREVIEW = "PREVIEW",
}

export type PodFeedbackModalProps = ModalProps & {
  initialModalType?: PodFeedbackModalType;
  initialValues?: Partial<consumerApi.types.reviews.StrainReviewJson> | null;
  reviewId?: string;
  saveReview?: consumerApi.actions.reviews.SaveReview;
  strainId: string;
  submitLabel?: string;
  useDispatch?: UseDispatch;
};

export const PodFeedbackModal: React.FC<PodFeedbackModalProps> = ({
  isOpen,
  isTransparent = false,
  onRequestClose,
  ...props
}) => {
  const [modalType, setModalType] = useState<PodFeedbackModalType>(
    props.initialModalType || PodFeedbackModalType.EDIT
  );

  const isSignedIn = redux.useSelector(
    consumerApi.selectors.user.getIsUserSignedIn
  );

  useEffect(() => {
    if (!props.initialModalType) return;
    setModalType(props.initialModalType);
  }, [props.initialModalType]);

  if (!isSignedIn) return null;

  const modalProps = {
    allowScroll: false, // do not allow scrolling for feedback modal since it is the only non-fullscreen modal
    isOpen,
    isTransparent,
    onRequestClose,
  };

  const podFeedbackModalContent =
    modalType === PodFeedbackModalType.EDIT ? (
      <PodFeedbackModalEditContent {...modalProps} {...props} />
    ) : (
      <PodFeedbackModalPreviewContent
        {...modalProps}
        onEditClick={() => setModalType(PodFeedbackModalType.EDIT)}
        review={
          props.initialValues as consumerApi.types.reviews.StrainReviewJson
        }
      />
    );

  return (
    <sc.FeedbackModal
      {...modalProps}
      data-testid="pod-feedback-modal"
      timeout={{ close: c.MODAL_TRANSITION_DURATION }}
    >
      {podFeedbackModalContent}
    </sc.FeedbackModal>
  );
};

const sc = {
  FeedbackModal: styled(Modal)`
    height: 100vh;

    & .close-modal {
      display: none;
    }

    ${desktopBreakpoint(css`
      height: initial;
      max-height: 100vh;
      overflow: hidden;
    `)}
  `,
};
