import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { components, ControlProps, OptionProps } from "react-select";
import styled from "styled-components";

import { Row } from "../../../shared/components/Flex";
import { Star } from "../../../shared/components/Icons";
import * as t from "../../../shared/text";
import { DropdownOption } from "../../../shared/types";

import { STRAIN_AVERAGE_RATING_FILTERS } from "../../constants";
import { StrainsFilter } from "../../types";

import * as SC from "./styledComponents";

type PodsAvgRatingProps = {
  filterUpdate: StrainsFilter;
  setFilterUpdate: Dispatch<SetStateAction<StrainsFilter>>;
};

export const PodsAverageRatingFilter: React.FC<PodsAvgRatingProps> = ({
  filterUpdate,
  setFilterUpdate,
}) => {
  const [selectedRatingOption, setSelectedRatingOption] =
    useState<DropdownOption>();

  useEffect(() => {
    const option =
      STRAIN_AVERAGE_RATING_FILTERS.find((option) => {
        return option.value === filterUpdate.minAvgRating;
      }) || STRAIN_AVERAGE_RATING_FILTERS[0];
    setSelectedRatingOption(option);
  }, [filterUpdate]);

  const handleAverageRatingByChange = (option: DropdownOption): void => {
    setSelectedRatingOption(option);

    setFilterUpdate({ ...filterUpdate, minAvgRating: option.value || "" });
  };

  return (
    <SC.Filter>
      <SC.Label htmlFor="strain-rating-dropdown">{t.RATING}</SC.Label>
      <SC.Dropdown
        data-testid="strain-rating-dropdown"
        components={{ Control: StarControl, Option: StarOption }}
        inputId="strain-rating-dropdown"
        name="strain-rating-dropdown"
        onChange={handleAverageRatingByChange}
        options={STRAIN_AVERAGE_RATING_FILTERS}
        placeholder={STRAIN_AVERAGE_RATING_FILTERS[0].label}
        value={selectedRatingOption}
      />
    </SC.Filter>
  );
};

const StarControl: React.FC<ControlProps<DropdownOption, false>> = ({
  children,
  ...props
}) => {
  return (
    <components.Control {...props}>
      <sc.Star $isFilled /> {children}
    </components.Control>
  );
};

const StarOption: React.FC<OptionProps<DropdownOption, false>> = ({
  ...props
}) => {
  return (
    <components.Option {...props}>
      <sc.Container>
        <sc.Star $isFilled={!props.isSelected} $unfilledColor="var(--white)" />
        {props.data.label}
      </sc.Container>
    </components.Option>
  );
};

const sc = {
  Container: styled(Row)`
    align-items: center;

    & > svg {
      margin-right: 8px;
    }
  `,

  Star: styled(Star)`
    height: 13px;
    width: 13px;
  `,
};
