import { connect } from "react-redux";

import { getConnectedDevice } from "../../bluetooth/connectedDevice";
import * as a from "../../device/selectors";
import * as t from "../../main/types";

import { startEraSession, stopEraSession } from "../actions";
import {
  EraSessionControlMobile,
  StateProps,
} from "../components/EraSessionControlMobile";

const mapStateToProps = (state: t.MainAppState): StateProps => {
  const connectedDevice = getConnectedDevice();

  return {
    heaterSetPoint: a.getHeaterSetPoint(state),
    isDeviceLocked: a.getIsDeviceLocked(state),
    replay: a.getEraReplay(state),
    supportsSessionControl: !!connectedDevice?.supportsSessionControl,
  };
};

const mapDispatchToProps = {
  onStartEraSession: startEraSession,
  onStopEraSession: stopEraSession,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EraSessionControlMobile);
