import React, { useEffect, useState } from "react";
import styled from "styled-components";

import * as reviewTypes from "../../api/consumer/types/reviews";
import { Row } from "../../shared/components/Flex";
import { Star, StarIconProps } from "../../shared/components/Icons";

import * as a from "../animations";

const MAX_STARS = 5;

export type StarRatingProps = {
  disabled?: boolean;
  onChange?: (rating: reviewTypes.StrainRating) => unknown;
  outlinedStars?: boolean;
  showZeroStars?: boolean;
  value?: reviewTypes.StrainRating | null;
};

export const StarRating: React.FC<StarRatingProps> = ({
  disabled = false,
  onChange,
  outlinedStars = false,
  showZeroStars = false,
  value,
  ...props
}) => {
  const [filledStars, setFilledStars] = useState<number>(0);
  const [shouldAnimate, setShouldAnimate] = useState<boolean>(false);

  useEffect(() => {
    if (!value) {
      setFilledStars(0);
      return;
    }

    setFilledStars(value);
  }, [value]);

  useEffect(() => {
    if (!shouldAnimate) return;

    setTimeout(() => setShouldAnimate(false), 500);
  }, [shouldAnimate]);

  const handleStarClick = (rating: number) => {
    if (disabled || rating === filledStars) return;

    // Animate the stars when first setting a rating
    if (filledStars === 0) setShouldAnimate(true);

    setFilledStars(rating);
    if (onChange) onChange(rating as reviewTypes.StrainRating);
  };

  if (!filledStars && !showZeroStars) return null;

  return (
    <sc.Row {...props}>
      {Array(MAX_STARS)
        .fill("")
        .map((_, index) => (
          <sc.Star
            $animationDelay={(MAX_STARS - index - 1) * 50}
            $isFilled={MAX_STARS - index <= filledStars}
            $outlinedStars={outlinedStars}
            $shouldAnimate={shouldAnimate}
            className={MAX_STARS - index <= filledStars ? "filled" : "empty"}
            data-testid={
              MAX_STARS - index <= filledStars ? "star-filled" : "star-empty"
            }
            disabled={disabled}
            key={`star-${MAX_STARS - index}`}
            onClick={() => handleStarClick(MAX_STARS - index)}
          />
        ))}
    </sc.Row>
  );
};

type StarProps = StarIconProps & {
  $animationDelay: number;
  $isFilled: boolean;
  $outlinedStars: boolean;
  $shouldAnimate: boolean;
};

const sc = {
  Row: styled(Row)`
    flex-direction: row-reverse;
    justify-content: flex-end;
  `,

  Star: styled(Star)<StarProps>`
    cursor: ${({ disabled }) => (disabled ? "auto" : "pointer")};
    height: 12px;
    margin: 0 2px;
    pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
    width: 12px;

    & path {
      fill: ${({ $isFilled, $outlinedStars }) => {
        if ($isFilled) {
          return "var(--black)";
        } else {
          return $outlinedStars ? "none" : "var(--brown-grey-40)";
        }
      }};
      stroke: ${({ $outlinedStars }) =>
        $outlinedStars ? "var(--black)" : "none"};
    }

    &:hover path,
    &:hover ~ svg path {
      fill: var(--black);
    }

    ${({ $animationDelay, $isFilled, $shouldAnimate }) =>
      $shouldAnimate && $isFilled ? a.starBlipAnimation($animationDelay) : ""}
  `,
};
