import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { trackPodLowEventFromPodHistory } from "../../../analytics/fuel-gauge/podLow";
import * as hooks from "../../../api/consumer/paginatedFetch";
import * as p from "../../../api/consumer/paths/connectedPods";
import { UsePaginatedFetch } from "../../../api/consumer/types";
import * as t from "../../../api/consumer/types/connectedPods";
import InfiniteLoadingList from "../../../shared/components/InfiniteLoadingList";
import { getRowReducer } from "../../../shared/utils";

import { ROW_SPACE } from "../../constants";
import * as SC from "../../styledComponents";
import { MyPodsType } from "../../types";

import { MyPodCard } from "./MyPodCard";
import { MyPodsLoadingContent } from "./MyPodsLoadingContent";
import { NoPods } from "./NoPods";

export type MyHistoryProps = {
  cardWidth: number;
  columnCount: number;
  rowHeight: number;
  usePaginatedFetch?: UsePaginatedFetch<t.ConnectedPodJson>;
  width: number;
};

export const MyHistory: React.FC<MyHistoryProps> = ({
  cardWidth,
  columnCount,
  rowHeight,
  usePaginatedFetch = hooks.usePaginatedFetch,
  width,
}) => {
  const dispatch = useDispatch();

  const [
    { count, data, hasFetchedFirstPage, isFetching, nextFetchUrl },
    { fetchNextPage },
  ] = usePaginatedFetch(p.connectedPods(t.ConnectedPodsFilter.NONE), {
    requiresAuthentication: true,
  });

  useEffect(() => {
    if (isFetching || count === 0) return;

    dispatch(trackPodLowEventFromPodHistory(data));
  }, [count, data, dispatch, isFetching]);

  if (isFetching && !hasFetchedFirstPage) {
    return (
      <MyPodsLoadingContent
        cardWidth={cardWidth}
        columnCount={columnCount}
        rowHeight={rowHeight}
        width={width}
      />
    );
  }

  if (hasFetchedFirstPage && count === 0) {
    return <NoPods />;
  }

  const rows = data.reduce(getRowReducer(columnCount), []).map((podRow) => (
    <SC.PodsRow height={rowHeight} key={`row-${podRow[0].strain.id}`}>
      {podRow.map((pod) => (
        <MyPodCard
          height={rowHeight - ROW_SPACE}
          key={pod.strain.id}
          pod={pod}
          podType={MyPodsType.HISTORY}
          width={cardWidth}
        />
      ))}
    </SC.PodsRow>
  ));

  return (
    <InfiniteLoadingList
      hasMoreItems={!!nextFetchUrl}
      loadMoreRows={fetchNextPage}
      rowHeight={rowHeight}
      rows={rows}
      width={width}
    />
  );
};
