import React from "react";
import styled, { css } from "styled-components";

import { Column, Row } from "../../../shared/components/Flex";
import * as Text from "../../../shared/components/Text";
import * as t from "../../../shared/text";
import { StrainColor } from "../../../shared/types";
import * as utils from "../../../shared/utils";

import { PodSectionTitleStyles } from "../../styledComponents";

import { EffectsSpectrumBar } from "./EffectsSpectrumBar";

type EffectsSpectrumProps = {
  effectsScore: number;
  strainColor: StrainColor;
};
export const EffectsSpectrum: React.FC<EffectsSpectrumProps> = ({
  effectsScore,
  strainColor,
}) => {
  return (
    <sc.Container>
      <sc.Title>{t.SPECTRUM}</sc.Title>
      <sc.ContentContainer>
        <sc.BarLabelsContainer>
          <sc.BarLabels>{t.CALMING}</sc.BarLabels>
          <sc.BarLabels>{t.ENERGIZING}</sc.BarLabels>
        </sc.BarLabelsContainer>
        {strainColor.primary.length > 0 && (
          <EffectsSpectrumBar
            effectsScore={effectsScore}
            strainColor={strainColor}
          />
        )}
      </sc.ContentContainer>
    </sc.Container>
  );
};

const sc = {
  BarLabels: styled.div`
    ${Text.MediumLLLight}
    font-size: 14px;
    letter-spacing: -0.105px;
    line-height: 18px;
  `,

  BarLabelsContainer: styled(Row)`
    justify-content: space-between;
    margin: 8px 0 0;

    ${utils.desktopBreakpoint(
      css`
        margin: 0 0 12px;
      `
    )}
  `,

  Container: styled.div`
    ${utils.mobileBreakpoint(
      css`
        padding-bottom: 20px;
      `
    )}
  `,

  ContentContainer: styled(Column)`
    flex-direction: column-reverse;
    width: 100%;

    ${utils.desktopBreakpoint(
      css`
        flex-direction: column;
      `
    )}
  `,

  Title: styled.div`
    ${PodSectionTitleStyles}
    display: none;
    margin-bottom: 8px;

    ${utils.desktopBreakpoint(
      css`
        display: block;
      `
    )}
  `,
};
