import React from "react";
import { useHistory } from "react-router-dom";
import styled, { css } from "styled-components";

import { Column } from "../../shared/components/Flex";
import { ChevronLeft } from "../../shared/components/Icons";

import { desktopBreakpoint } from "../utils";

type GoBackProps = {
  onClick?: () => void;
  to: string;
};

const GoBack: React.FC<GoBackProps> = ({ onClick, to, ...props }) => {
  const history = useHistory();

  const handleClick = (): void => {
    if (onClick) onClick();
    if (history.length <= 2) {
      history.push(to);
      return;
    }

    history.goBack();
  };

  return (
    <sc.Container onClick={handleClick} {...props}>
      <sc.ChevronLeft />
    </sc.Container>
  );
};

export default GoBack;

const sc = {
  ChevronLeft: styled(ChevronLeft)`
    height: 100%;
    width: 100%;
  `,

  Container: styled(Column)`
    align-items: center;
    border-radius: 100%;
    cursor: pointer;
    height: 30px;
    justify-content: center;
    padding: 4px;
    width: 30px;

    ${desktopBreakpoint(
      css`
        height: 48px;
        width: 48px;
      `
    )}

    &:hover {
      background: var(--black);
      > svg > path {
        fill: var(--white);
      }
    }
  `,
};
