import { connect } from "react-redux";

import * as PaxBleTypes from "../../../pax-ble/types";

import * as consumerApiActions from "../../api/consumer/actions/devices";
import * as deviceActions from "../../device/actions";
import * as c from "../../device/constants";
import * as deviceSelectors from "../../device/selectors";
import * as t from "../../main/types";
import { showFlashBanner } from "../../shared/components/FlashBanner";

import { setColorMode, setDefaultColorMode } from "../actions";
import Pax3DeviceSettingsModal, {
  Pax3DeviceSettingsModalProps,
} from "../components/Pax3DeviceSettingsModal";

const mapStateToProps = (
  state: t.MainAppState,
  ownProps: Pax3DeviceSettingsModalProps
) => {
  const colorMode = state.device.attributes[c.ATTRIBUTE_COLOR_THEME];
  const serialNumber = deviceSelectors.getDeviceSerialNumber(
    state,
    PaxBleTypes.DeviceType.PAX_3
  );

  return {
    ...ownProps,
    brightness: deviceSelectors.getBrightness(state),
    colorMode,
    deviceName: deviceSelectors.getDeviceName(state),
    firmwareRevision: deviceSelectors.getFirmwareRevision(state),
    haptics: deviceSelectors.getHapticsForDevice(state),
    isDeviceLocked: deviceSelectors.getIsDeviceLocked(state),
    serialNumber,
  };
};

const mapDispatchToProps = {
  disconnectDevice: deviceActions.disconnectDevice,
  setBrightness: deviceActions.setBrightness,
  setColorMode,
  setDefaultColorMode,
  setDeviceName: window.paxConnectedDevice?.supportsRenameDevice
    ? deviceActions.setDeviceName
    : undefined,
  setHaptics: deviceActions.setHaptics,
  showFlashBanner,
  updateDevice: consumerApiActions.updateDevice,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Pax3DeviceSettingsModal);
