import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { trackViewAllPodsClicked } from "../../analytics/search/viewAll";
import * as s from "../../api/consumer/selectors/user";
import * as r from "../../main/routes";
import * as Text from "../../shared/components/Text";
import * as text from "../../shared/text";

import { buildStrainsFilter } from "../models/StrainsFilter";

export const ViewAllPodsInStateLink: React.FC = () => {
  const dispatch = useDispatch();
  const location = useSelector(s.getUserLocation);
  const locationCode = useSelector(s.getUserLocationCode);

  if (!location || !location.displayName || !location?.isoCode) return null;

  const podsSearchByLocation = r.podsSearch(
    buildStrainsFilter({ region: location.region, state: location.isoCode })
  );

  const handleClick = () => {
    dispatch(trackViewAllPodsClicked(locationCode, !!location.isoCode));
  };

  return (
    <sc.ViewAllPodsInStateLink
      data-testid="view-all-pods-in-state-link"
      onClick={handleClick}
      to={podsSearchByLocation}
    >
      {text.viewAllPodsInLocation(location.displayName)}
    </sc.ViewAllPodsInStateLink>
  );
};

const sc = {
  ViewAllPodsInStateLink: styled(Link)`
    ${Text.PlaaxLight}
    color: var(--brown-grey);
    font-size: 16px;
    margin: 40px auto;
    text-decoration: none;
  `,
};
