import React from "react";
import styled, { css } from "styled-components";

import { NullableDeviceType } from "../../device/types";

import { desktopBreakpoint } from "../utils";

const deviceLogosMap = {
  ERA: "ERA CLASSIC",
  ERA_PRO: "ERA PRO",
  PAX_3: "PAX 3",
  PAX_35: "PAX 3.5",
};

type DeviceLogoProps = {
  deviceType: NullableDeviceType;
};

export const DeviceLogo: React.FC<DeviceLogoProps> = ({
  deviceType,
  ...props
}) => {
  const logo = deviceType ? deviceLogosMap[deviceType] : "PAX";

  return <sc.LogoText {...props}>{logo}</sc.LogoText>;
};

const sc = {
  LogoText: styled.div`
    font-size: 18px;
    font-weight: 600;
    text-align: left;

    ${desktopBreakpoint(
      css`
        text-align: center;
      `
    )}
  `,
};
