import React from "react";
import styled from "styled-components";

import { Modal, ModalProps } from "../modal/components/Modal";

type ExperienceModesModalProps = ModalProps & {
  render: (modalProps: ModalProps) => React.ReactNode;
};

export const ExperienceModesModal: React.FC<ExperienceModesModalProps> = ({
  isOpen,
  isTransparent = false,
  onRequestClose,
  render,
  ...props
}) => {
  const modalProps = {
    allowScroll: true,
    isOpen,
    isTransparent,
    onRequestClose,
  };

  return (
    <sc.Modal {...modalProps}>{render({ ...modalProps, ...props })}</sc.Modal>
  );
};

const sc = {
  Modal: styled(Modal)`
    background-color: var(--very-light-pink-7);
    border-radius: 16px;
    height: 560px;
    margin: 16px auto;
    padding: 16px;
    position: relative;
    width: 328px;

    & .close-modal {
      position: absolute;
      right: 22px;
      top: 16px;
    }
  `,
};
