import React, { useCallback, useEffect, useState } from "react";

import { Modal } from "../../modal/components/Modal";

import * as t from "../types";

export type DeviceConnectionModalProps = {
  allowOuterElementInteraction?: boolean;
  deviceConnectionFlow: t.DeviceConnectionFlow;
  hideCloser?: boolean;
  initialStep: t.ModalStep;
  isConnectionModalOpen: boolean;
  isTransparent: boolean;
  onRequestClose?: () => unknown;
  setIsConnectionModalOpen: (isConnectionModalOpen: boolean) => void;
};

export const DeviceConnectionModal: React.FC<DeviceConnectionModalProps> = ({
  allowOuterElementInteraction,
  deviceConnectionFlow,
  hideCloser = true,
  initialStep,
  isConnectionModalOpen,
  isTransparent,
  onRequestClose,
  setIsConnectionModalOpen,
}) => {
  const [Step, setStep] = useState<t.ModalStep | string>(initialStep);

  useEffect(() => {
    if (isConnectionModalOpen) return;
    setStep(initialStep);
  }, [initialStep, isConnectionModalOpen]);

  const handleStepCompleted: t.OnDeviceConnectionStepCompleted = useCallback(
    (currentStep, goBack = false): void => {
      if (currentStep === t.DeviceConnectionStep.CLOSE_MODAL) return;
      const nextStep = goBack
        ? deviceConnectionFlow[currentStep]?.back
        : deviceConnectionFlow[currentStep]?.next;

      if (nextStep === t.DeviceConnectionStep.CLOSE_MODAL) {
        setStep(initialStep);
        setIsConnectionModalOpen(false);
        return;
      }
      setStep(nextStep);
    },
    [deviceConnectionFlow, initialStep, setIsConnectionModalOpen]
  );

  const modalProps = {
    allowOuterElementInteraction,
    "data-testid": "device-connection-modal",
    hideCloser: hideCloser,
    isOpen: isConnectionModalOpen,
    isTransparent,
    onRequestClose,
  };

  return (
    <Modal {...modalProps}>
      <Step onStepCompleted={handleStepCompleted} />
    </Modal>
  );
};
