import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { trackViewAllPodsClicked } from "../../analytics/search/viewAll";
import * as s from "../../api/consumer/selectors/user";
import { getGeoStateLabel } from "../../pods/utils";
import { PlaaxButton } from "../../shared/components/Button";
import { ButtonWrapper } from "../../shared/components/ButtonWrapper";
import { Column } from "../../shared/components/Flex";
import * as Text from "../../shared/components/Text";
import * as text from "../../shared/text";
import { SsoCountry } from "../../sso/constants";

import { setStrainsFilterAndRedirect } from "../actions";
import { buildStrainsFilter } from "../models/StrainsFilter";
import { StrainsFilter } from "../types";
import { ReportMissingStrainModal } from "./ReportMissingStrainModal";

type PodsUnavailableProps = {
  textHelper: (location: string) => string;
};

export const PodsUnavailableInLocation: React.FC<PodsUnavailableProps> = ({
  textHelper,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useSelector(s.getUserLocation);
  const locationCode = useSelector(s.getUserLocationCode);
  const [isMissingStrainModalOpen, setIsMissingStrainModalOpen] =
    useState<boolean>(false);

  const onSubmit = () => {
    setIsMissingStrainModalOpen(false);
  };

  // TODO - remove INTERNATIONAL check when API support is added: https://paxlabs.atlassian.net/browse/PCP-1345
  // Show all US pods for international users since there aren't any international results.
  const viewAllFilterOptions: Partial<StrainsFilter> = {
    region:
      !location?.region || location?.region === SsoCountry.INTERNATIONAL
        ? SsoCountry.US
        : location.region,
  };

  const viewAllFilter = buildStrainsFilter(viewAllFilterOptions);

  const handleViewAllClick = () => {
    dispatch(trackViewAllPodsClicked(locationCode));
    dispatch(setStrainsFilterAndRedirect(history, viewAllFilter));
  };

  return (
    <Column>
      <sc.ViewAllPodsText>
        {textHelper(getGeoStateLabel(location?.isoCode))}
      </sc.ViewAllPodsText>
      <sc.ViewAllPodsButton
        data-testid="view-all-pods-button"
        onClick={handleViewAllClick}
      >
        {text.VIEW_ALL_PODS}
      </sc.ViewAllPodsButton>
      <sc.ReportMissingStrainLink
        onClick={() => setIsMissingStrainModalOpen(true)}
      >
        {text.REPORT_MISSING_STRAIN}
      </sc.ReportMissingStrainLink>
      <ReportMissingStrainModal
        isOpen={isMissingStrainModalOpen}
        onRequestClose={onSubmit}
        onSubmit={onSubmit}
      />
    </Column>
  );
};

const sc = {
  ReportMissingStrainLink: styled(ButtonWrapper)`
    ${Text.MediumLL}
    cursor: pointer;
    font-size: 16px;
    margin: 40px auto;
    text-align: center;
    text-decoration: underline;

    &:hover {
      font-weight: 500;
    }
  `,

  ViewAllPodsButton: styled(PlaaxButton)`
    align-self: center;
  `,

  ViewAllPodsText: styled.div`
    ${Text.MediumLL}
    margin: 40px auto;
    padding: 0 16px;
  `,
};
