import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { StrainJson } from "../../../api/consumer/types";
import { getDevicePod } from "../../../device/selectors";

export type PodSwitcherProps = {
  Icon: React.ReactElement;
  HoverIcon?: React.ReactElement;
  onClick: () => void;
  strain?: StrainJson | null;
};

export const PodSwitcher: React.FC<PodSwitcherProps> = ({
  Icon,
  HoverIcon,
  onClick,
  strain,
}) => {
  const [iconToRender, setIconToRender] = useState<React.ReactElement>(Icon);
  const devicePod = useSelector(getDevicePod);

  useEffect(() => {
    setIconToRender(Icon);
  }, [Icon]);

  const handleMouseEnter = (): void => {
    if (!HoverIcon) return;

    setIconToRender(HoverIcon);
  };

  const handleMouseLeave = (): void => {
    setIconToRender(Icon);
  };

  // The autoId’d pod has strain info, so we don't allow override
  if (devicePod.isAutoId && devicePod.strainId && strain) {
    return null;
  }

  return (
    <sc.Container
      className="pod-switcher"
      data-testid="pod-switcher"
      onClick={onClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {iconToRender}
    </sc.Container>
  );
};

const sc = {
  Container: styled.div`
    background: var(--white);
    border-radius: 50px;
    bottom: 0;
    box-shadow: 0 1px 6px 0 var(--light-grey-blue-50);
    height: 48px;
    position: absolute;
    right: 0;
    width: 48px;
    z-index: var(--z-index-pod-switcher);

    &:hover {
      background: var(--white-three);
      cursor: pointer;
    }

    & svg {
      height: 22px;
      margin: 13px;
      width: 22px;
    }
  `,
};
