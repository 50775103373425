import Bugsnag from "@bugsnag/js";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { DeviceType } from "../../pax-ble/types";

import { DEVICE_TYPE_MAP } from "../api/consumer/constants";
import * as consumerHooks from "../api/consumer/hooks";
import { useConnectedDevice } from "../device/hooks";
import { receivedFirmwareInfo } from "./actions";
import * as p from "./paths";
import { FirmwareInfoResponse } from "./types";

export type UseFetchFirmwareImage = (url: string) => {
  imageData: Uint8Array | null;
  error: string | null;
};

export const useFetchFirmwareImage: UseFetchFirmwareImage = (url: string) => {
  const [imageData, setImageData] = useState<Uint8Array | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      const request = new XMLHttpRequest();
      request.open("GET", url, true);
      request.responseType = "arraybuffer";

      request.onerror = function (): void {
        setError("Error fetching firmware image");
      };

      request.onload = function (): void {
        if (!request.response) {
          setError("Empty response when fetching firmware image");
          return;
        }

        const imageData = new Uint8Array(request.response);

        setImageData(imageData);
      };

      request.send();
    };

    if (url) {
      fetchData();
    }
  }, [url]);

  if (error) Bugsnag.notify(new Error(error));

  return { error, imageData };
};

type UseFetchFirmwareInfo = (
  usePendingReactQuery?: consumerHooks.UsePendingReactQuery<FirmwareInfoResponse>
) => void;

export const useFetchFirmwareInfo: UseFetchFirmwareInfo = (
  usePendingReactQuery = consumerHooks.usePendingReactQuery
) => {
  const dispatch = useDispatch();
  const connectedDevice = useConnectedDevice();

  const { data } = usePendingReactQuery({
    path: p.firmwareBySerial(
      DEVICE_TYPE_MAP[connectedDevice?.type || DeviceType.ERA_PRO],
      connectedDevice?.serial || ""
    ),
    pendingCondition: connectedDevice?.serial && connectedDevice?.type,
  });

  useEffect(() => {
    if (!data) return;

    dispatch(receivedFirmwareInfo(data.version, data.blob.downloadUrl));
  }, [data, dispatch]);
};
