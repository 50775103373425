import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import * as PaxBle from "../../pax-ble";

import { getConnectedDevice } from "../bluetooth/connectedDevice";
import { getIsDeviceConnected } from "./selectors";

// This hook will get the currently connected BLE device.
export const useConnectedDevice = (): PaxBle.BaseDevice | undefined => {
  const [connectedDevice, setConnectedDevice] = useState<PaxBle.BaseDevice>();
  const isDeviceConnected = useSelector(getIsDeviceConnected);

  useEffect(() => {
    setConnectedDevice(getConnectedDevice());
  }, [isDeviceConnected]);

  return connectedDevice;
};
