import React from "react";
import { Redirect, Route, RouteComponentProps, Switch } from "react-router-dom";

import { AccountSettingsPage } from "./components/AccountSettingsPage";
import { MyPods } from "./components/MyPods";
import myDevicesPage from "./containers/MyDevices";
import MyPaxHomePage from "./containers/MyPaxHomePage";

export const AccountRoutes: React.FC<RouteComponentProps> = () => (
  <Switch>
    <Route exact path="/account" component={MyPaxHomePage} />
    <Route exact path="/account/settings" component={AccountSettingsPage} />
    <Route exact path="/account/devices" component={myDevicesPage} />
    <Route exact path="/account/pods/:selectedMyPodsType" component={MyPods} />
    <Redirect from="*" to="/account" />
  </Switch>
);
