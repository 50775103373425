import React from "react";
import { Route } from "react-router-dom";

import { MainApp } from "../../main/components/MainApp";
import { PodsHomePage } from "../../pods/components/PodsHomePage";

import { UnsupportedDevicePage } from "./UnsupportedDevicePage";

export const ExploreApp: React.FC = () => {
  return (
    <MainApp>
      <Route exact path="/" component={PodsHomePage} />
      <Route path="/device" component={UnsupportedDevicePage} />
    </MainApp>
  );
};
