import Bugsnag from "@bugsnag/js";
import aesjs from "aes-js";

import * as c from "../constants";
import * as u from "../utils";
import { EraProEncryptionContext } from "./EraProEncryptionContext";

const MAX_DECRYPTION_ERRORS = 3;

export class EraProDeviceEncryption {
  decryptionAttemptIndex: number;
  decryptionLastErrorIndex: number;
  encryptionContext: EraProEncryptionContext;
  hasMaxConsecutiveDecryptionErrors: boolean;
  consecutiveDecryptionErrorCount: number;

  constructor() {
    this.decryptionAttemptIndex = 0;
    this.decryptionLastErrorIndex = 0;
    this.hasMaxConsecutiveDecryptionErrors = false;
    this.consecutiveDecryptionErrorCount = 0;

    // This is where a new shared key is generated.
    this.encryptionContext = new EraProEncryptionContext();
  }

  buildEncryptedPacket(data: Uint8Array): Uint8Array {
    const crc16 = u.generateCrc16(data);
    const dataWithCrc = u.mergeTypedArrays(data, crc16);
    const initVector = this.encryptionContext.getIncrementedCentralNonce();

    // Build the encryption packet header
    const buffer = new ArrayBuffer(4);
    const view = new DataView(buffer);
    view.setUint8(0, c.ATTRIBUTE_ENCRYPTION_PACKET);
    view.setUint8(1, 0x11); // encryption type (AES-256-CTR)
    view.setUint16(2, dataWithCrc.length, true);

    const encryptionHeader = u.mergeTypedArrays(
      new Uint8Array(buffer),
      initVector
    );

    // Return the encryption header with encrypted data
    return u.mergeTypedArrays(
      encryptionHeader,
      this.encrypt(dataWithCrc, initVector)
    );
  }

  getDecryptedPacket(data: Uint8Array): {
    decryptedPacket: Uint8Array;
    updatedErrorCount: number;
  } {
    this.decryptionAttemptIndex++;

    // Reset error count if errors are not consecutive.
    if (this.decryptionAttemptIndex !== this.decryptionLastErrorIndex + 1) {
      this.consecutiveDecryptionErrorCount = 0;
    }

    const peripheralNonce = data.slice(4, 20);
    const encryptedBuffer = data.slice(20);
    const decryptedValue = this.decrypt(encryptedBuffer, peripheralNonce);
    const packetEnd = decryptedValue.length - 2;
    const decryptedPacket = decryptedValue.slice(0, packetEnd);
    const deviceCrc16 = u.decodeTextValue(decryptedValue.slice(packetEnd));
    const generatedCrc16 = u.decodeTextValue(u.generateCrc16(decryptedPacket));

    this.encryptionContext.peripheralNonce = peripheralNonce;

    if (deviceCrc16 !== generatedCrc16) {
      Bugsnag.notify(u.getDecryptionError(data, decryptedValue));

      if (
        this.decryptionAttemptIndex === this.decryptionLastErrorIndex + 1 ||
        this.decryptionLastErrorIndex === 0
      ) {
        this.consecutiveDecryptionErrorCount++;
      }

      this.decryptionLastErrorIndex = this.decryptionAttemptIndex;

      if (this.consecutiveDecryptionErrorCount >= MAX_DECRYPTION_ERRORS) {
        this.hasMaxConsecutiveDecryptionErrors = true;
        throw new Error("Maximum consecutive decryption errors");
      }
    }

    return {
      decryptedPacket,
      updatedErrorCount: this.consecutiveDecryptionErrorCount,
    };
  }

  encrypt(data: Uint8Array, initVector: Uint8Array): Uint8Array {
    const aesCtr = new aesjs.ModeOfOperation.ctr(
      this.encryptionContext.sharedKey,
      new aesjs.Counter(initVector)
    );

    const encrypted = aesCtr.encrypt(data);
    return encrypted;
  }

  decrypt(data: Uint8Array, initVector: Uint8Array): Uint8Array {
    const aesCtr = new aesjs.ModeOfOperation.ctr(
      this.encryptionContext.sharedKey,
      new aesjs.Counter(initVector)
    );

    const decrypted = aesCtr.decrypt(data);
    return decrypted;
  }

  get firmwareRevision(): Uint8Array | undefined {
    return this.encryptionContext.firmwareRevision;
  }

  get peripheralId(): string {
    return this.encryptionContext.peripheralId
      ? u.decodeTextValue(this.encryptionContext.peripheralId)
      : "";
  }

  get systemId(): Uint8Array | undefined {
    return this.encryptionContext.systemId;
  }
}
