import { connect } from "react-redux";

import { MainAppState } from "../../main/types";

import { updateUser } from "../actions";
import { SsoEditAccountPage } from "../components/SsoEditAccountPage";

const mapStateToProps = (state: MainAppState) => {
  return {
    ...state.sso.updateUser,
  };
};

const mapDispatchToProps = {
  updateUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(SsoEditAccountPage);
