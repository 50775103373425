// TODO: remove use of any
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import styled, { StyledComponent } from "styled-components";

import * as Text from "../../shared/components/Text";

export type PillSelectOption = {
  active: boolean;
  activeBackgroundColor?: string;
  Icon?: React.ReactNode;
  label: string;
  value: any;
};

export type PillSelectProps = {
  Container?: StyledComponent<"div", any, any>;
  onChange: (option: PillSelectOption) => any;
  options: PillSelectOption[];
  PillOption?: StyledComponent<any, any, PillSelectOption>;
};

export const PillSelect: React.FC<PillSelectProps> = ({
  Container = sc.PillSelectContainer,
  options,
  onChange,
  PillOption = sc.PillOption,
  ...props
}) => {
  return (
    <Container {...props}>
      {options.map((option, index) => (
        <PillOption
          data-active={option.active}
          data-testid={`pill-select-${option.value}`}
          key={`${index}-${option.value}`}
          onClick={() => onChange(option)}
          {...option}
        >
          {option.Icon && <sc.IconContainer>{option.Icon}</sc.IconContainer>}
          {option.label}
        </PillOption>
      ))}
    </Container>
  );
};

const sc = {
  IconContainer: styled.div`
    height: 20px;
    margin-right: 12px;
    width: 20px;
  `,

  PillOption: styled.div<PillSelectOption>`
    ${Text.Plaax}
    align-items: center;
    background-color: ${(props) =>
      props.active ? "var(--black)" : "var(--white)"};
    border: 1px solid var(--very-light-pink);
    border-bottom-color: ${(props) =>
      props.active ? "var(--black)" : "var(--very-light-pink)"};
    border-top-color: ${(props) =>
      props.active ? "var(--black)" : "var(--very-light-pink)"};
    color: ${(props) => (props.active ? "var(--white)" : "var(--black)")};

    &:first-child {
      border-bottom-right-radius: 19px;
      border-top-right-radius: 19px;
    }

    &:nth-child(n + 2) {
      border-right: none;
    }

    &:last-child {
      border-bottom-left-radius: 19px;
      border-top-left-radius: 19px;
    }

    cursor: pointer;
    display: flex;
    flex: 1;
    justify-content: center;

    &:hover,
    &:hover ~ & {
      background-color: var(--black);
      border-bottom-color: var(--black);
      border-left-color: var(--very-light-pink);
      border-right-color: var(--very-light-pink);
      border-top-color: var(--black);
      color: var(--white);
    }
  `,

  PillSelectContainer: styled.div`
    display: flex;
    width: 100%;
  `,
};

export const PillOption = sc.PillOption;
