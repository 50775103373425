import { connect } from "react-redux";

import * as s from "../../device/selectors";
import * as t from "../../main/types";

import { Pax3DevicePage } from "../components/Pax3DevicePage";

const mapStateToProps = (state: t.MainAppState) => {
  return {
    deviceName: s.getDeviceName(state),
    isDeviceConnected: s.getIsDeviceConnected(state),
    isDeviceLocked: s.getIsDeviceLocked(state),
  };
};

export default connect(mapStateToProps)(Pax3DevicePage);
