import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import * as Card from "../../shared/components/Card";
import * as Text from "../../shared/components/Text";

export type ComingSoonCardProps = {
  isDeviceLocked?: boolean;
  text: React.ReactNode;
  title: React.ReactNode;
};

const ComingSoonCard: React.FC<ComingSoonCardProps> = ({
  isDeviceLocked,
  text,
  title,
}: ComingSoonCardProps) => {
  if (isDeviceLocked) {
    return (
      <Card.Small data-testid="locked-coming-soon-card">
        <sc.Title isDeviceLocked={isDeviceLocked}>{title}</sc.Title>
      </Card.Small>
    );
  }

  return (
    <Card.Small data-testid="coming-soon-card">
      <sc.Title>{title}</sc.Title>
      <sc.Text>{text}</sc.Text>
    </Card.Small>
  );
};

type TitleProps = {
  isDeviceLocked?: boolean;
};

const sc = {
  Link: styled(Link)`
    color: var(--bluey-grey-80);
    font-size: 20px;
    padding-top: 25px;
  `,

  Text: styled.div`
    ${Text.PlaaxLight}
    color: var(--brownish-grey);
    font-size: 14px;
  `,

  Title: styled.h3<TitleProps>`
    color: ${(props) =>
      props.isDeviceLocked ? "var(--very-light-pink)" : "var(--brownish-grey)"};
    font-size: 20px;
    margin: 0 0 8px;
    text-transform: uppercase;
  `,
};

export default ComingSoonCard;
