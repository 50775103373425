import React from "react";
import styled, { css } from "styled-components";

import { Modal, ModalProps } from "../../modal/components/Modal";
import Button from "../../shared/components/Button";
import { Column } from "../../shared/components/Flex";
import * as Text from "../../shared/components/Text";
import * as text from "../../shared/text";
import { desktopBreakpoint } from "../../shared/utils";

export type DisableFindMyPaxModalProps = ModalProps & {
  disableFindMyPax: () => void;
};

export const DisableFindMyPaxModal: React.FC<DisableFindMyPaxModalProps> = ({
  disableFindMyPax,
  ...props
}) => {
  return (
    <sc.Modal {...props}>
      <DisableFindMyPaxModalContent
        disableFindMyPax={disableFindMyPax}
        {...props}
      />
    </sc.Modal>
  );
};

const DisableFindMyPaxModalContent: React.FC<DisableFindMyPaxModalProps> = ({
  disableFindMyPax,
  onRequestClose,
}) => {
  const handleTurnOffClick = (): void => {
    disableFindMyPax();
    if (onRequestClose) onRequestClose();
  };
  return (
    <sc.Column>
      <sc.Title>{text.DISABLE_LOCATION}</sc.Title>
      <sc.Subtitle>{text.DISABLING_PAXFINDER_WILL_REMOVE}</sc.Subtitle>
      <sc.Button onClick={handleTurnOffClick}>Turn Off</sc.Button>
    </sc.Column>
  );
};

const sc = {
  Button: styled(Button)`
    margin: 0 auto;
    width: 116px;
  `,

  Column: styled(Column)`
    height: 100%;
    justify-content: center;
  `,

  Modal: styled(Modal)`
    padding: 40px;

    ${desktopBreakpoint(
      css`
        height: 328px;
        width: var(--default-modal-width);
      `
    )}
  `,

  Subtitle: styled.div`
    ${Text.PlaaxLight}
    font-size: 18px;
    height: 117px;
    margin-top: 28px;
  `,

  Title: styled.div`
    font-size: 24px;
    text-align: center;
    text-transform: uppercase;
  `,
};
