import * as t from "../types";

export enum RequestType {
  SET,
  CONNECT,
  DISCONNECT,
  CREATE_BOND,
  REMOVE_BOND,
  WRITE,
  READ,
  WRITE_DESCRIPTOR,
  READ_DESCRIPTOR,
  BEGIN_RELIABLE_WRITE,
  EXECUTE_RELIABLE_WRITE,
  ABORT_RELIABLE_WRITE,
  ENABLE_NOTIFICATIONS,
  ENABLE_INDICATIONS,
  DISABLE_NOTIFICATIONS,
  DISABLE_INDICATIONS,
  WAIT_FOR_NOTIFICATION,
  WAIT_FOR_INDICATION,
  ENABLE_SERVICE_CHANGED_INDICATIONS,
  REQUEST_MTU,
  REQUEST_CONNECTION_PRIORITY,
  SET_PREFERRED_PHY,
  READ_PHY,
  READ_RSSI,
  REFRESH_CACHE,
  SLEEP,
}

export default class Request {
  static Type = RequestType;

  type: RequestType;
  resolve: t.PromiseResolve;
  reject: t.PromiseReject;

  constructor(
    type: RequestType,
    resolve: t.PromiseResolve,
    reject: t.PromiseReject
  ) {
    this.type = type;
    this.resolve = resolve;
    this.reject = reject;
  }

  hasMorePackets(): boolean {
    return false;
  }
}
