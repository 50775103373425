import { PayloadAction } from "@reduxjs/toolkit";

import { Action } from "../../../shared/types";

import { fetch } from "../index";
import { publicPartners } from "../paths/partners";
import * as t from "../types";

export const FETCH_PARTNERS_SUCCESS = "FETCH_PARTNERS_SUCCESS";

export type FetchPartners = () => (
  dispatch: React.Dispatch<Action>
) => Promise<void>;

export const fetchPartners: FetchPartners = () => async (dispatch) => {
  const path = publicPartners({
    page: 1,
    pageSize: 100,
  });

  try {
    const response = await fetch<t.CollectionResponse<t.partner.PartnerJson>>(
      path
    );
    dispatch(receivedPartners(response));
  } catch (e) {
    // Ignore the error; UI doesn't need to respond.
  }
};

const receivedPartners = (
  response: t.CollectionResponse<t.partner.PartnerJson>
): PayloadAction<{
  response: t.CollectionResponse<t.partner.PartnerJson>;
}> => ({
  payload: { response },
  type: FETCH_PARTNERS_SUCCESS,
});
