import objectFitImages from "object-fit-images";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { navigator } from "../../navigator";

import * as analyticsHooks from "../analytics/hooks";
import * as consumerApi from "../api/consumer";
import { getBannerCookie } from "../cookies";
import * as FlashBanner from "../shared/components/FlashBanner";
import { getFlashBannerMessage } from "../shared/components/FlashBanner/selectors";
import * as t from "../shared/text";
import * as ssoHooks from "../sso/hooks";

const BANNER_TYPE_MAP: { [key: string]: FlashBanner.BannerType } = {
  ERROR: FlashBanner.BannerType.ERROR,
  INFO: FlashBanner.BannerType.INFO,
  SUCCESS: FlashBanner.BannerType.SUCCESS,
  WARNING: FlashBanner.BannerType.WARNING,
};

const useCookieFlashBanner = (): void => {
  const dispatch = useDispatch();
  const bannerCookie = getBannerCookie();

  useEffect(() => {
    if (!bannerCookie) return;

    const [message, bannerType] = bannerCookie.split(":");

    dispatch(FlashBanner.showFlashBanner(message, BANNER_TYPE_MAP[bannerType]));
  }, [dispatch, bannerCookie]);
};

const useObjectFitImages = (): void => {
  useEffect(() => {
    objectFitImages(null);
  }, []);
};

const useOnlineStatus = (): boolean => {
  const [onlineStatus, setOnlineStatus] = useState(navigator.onLine ?? true);

  const goOnline = () => setOnlineStatus(true);
  const goOffline = () => {
    setOnlineStatus(false);
  };

  useEffect(() => {
    window.addEventListener("online", goOnline);
    window.addEventListener("offline", goOffline);

    return () => {
      window.removeEventListener("online", goOnline);
      window.removeEventListener("offline", goOffline);
    };
  }, []);

  return onlineStatus;
};

const useOfflineBanner = (): void => {
  const dispatch = useDispatch();
  const bannerMessage = useSelector(getFlashBannerMessage);
  const isOffline = !useOnlineStatus();

  useEffect(() => {
    if (isOffline) {
      dispatch(
        FlashBanner.showFlashBanner(
          t.UH_OH_LOOKS_LIKE_YOU_ARE_OFFLINE,
          FlashBanner.BannerType.SUCCESS,
          true
        )
      );
      return;
    }
    if (bannerMessage === t.UH_OH_LOOKS_LIKE_YOU_ARE_OFFLINE)
      dispatch(FlashBanner.clearFlashBanner());
  }, [bannerMessage, dispatch, isOffline]);
};

export const useAppHooks = (): void => {
  consumerApi.hooks.useUser();
  consumerApi.hooks.useExperiments();
  useCookieFlashBanner();
  useObjectFitImages();
  useOfflineBanner();
  analyticsHooks.usePageViews();
  ssoHooks.useSsoCookieCheck();
};
