import { DeviceType } from "../../../pax-ble/types";

import { DeviceColor } from "../../device/types";
import * as text from "../../shared/text";

import * as t from "./types/device";
import { OrderBy, Potency } from "./types/strain";

export const DEVICE_COLOR_STRING_MAP: {
  [key in t.DeviceShellColor]: DeviceColor;
} = {
  [t.DeviceShellColor.AMBER]: DeviceColor.AMBER,
  [t.DeviceShellColor.GLOSSY_BLACK]: DeviceColor.GLOSSY_BLACK,
  [t.DeviceShellColor.GLOSSY_SILVER]: DeviceColor.GLOSSY_SILVER,
  [t.DeviceShellColor.GLOSSY_GOLD]: DeviceColor.GLOSSY_GOLD,
  [t.DeviceShellColor.GLOSSY_ROSE_GOLD]: DeviceColor.GLOSSY_ROSE_GOLD,
  [t.DeviceShellColor.GLOSSY_RED]: DeviceColor.GLOSSY_RED,
  [t.DeviceShellColor.BLUE]: DeviceColor.BLUE,
  [t.DeviceShellColor.MATTE_BLACK]: DeviceColor.MATTE_BLACK,
  [t.DeviceShellColor.MATTE_SILVER]: DeviceColor.MATTE_SILVER,
  [t.DeviceShellColor.MATTE_ROSE_GOLD]: DeviceColor.MATTE_ROSE_GOLD,
  [t.DeviceShellColor.MATTE_TEAL]: DeviceColor.MATTE_TEAL,
  [t.DeviceShellColor.MATTE_FUCHSIA]: DeviceColor.MATTE_FUCHSIA,
  [t.DeviceShellColor.MATTE_RED]: DeviceColor.MATTE_RED,
  [t.DeviceShellColor.MATTE_JADE]: DeviceColor.MATTE_JADE,
  [t.DeviceShellColor.BURGUNDY]: DeviceColor.BURGUNDY,
  [t.DeviceShellColor.SAGE]: DeviceColor.SAGE,
  [t.DeviceShellColor.SAND]: DeviceColor.SAND,
  // The color code for sapphire and midnight rain are the same most likely because they are LTO  devices
  // Prioritizing P3 color naming because there are a lot more of them
  [t.DeviceShellColor.SAPPHIRE]: DeviceColor.MIDNIGHT_RAIN,
  [t.DeviceShellColor.ONYX]: DeviceColor.ONYX,
  [t.DeviceShellColor.SHADOW_RAIN]: DeviceColor.SHADOW_RAIN,
  [t.DeviceShellColor.MIDNIGHT_RAIN]: DeviceColor.MIDNIGHT_RAIN,
  [t.DeviceShellColor.OCEAN]: DeviceColor.OCEAN,
};

export const DEVICE_COLOR_NUMBER_MAP: {
  [key in DeviceColor]: t.DeviceShellColor;
} = {
  [DeviceColor.AMBER]: t.DeviceShellColor.AMBER,
  [DeviceColor.GLOSSY_BLACK]: t.DeviceShellColor.GLOSSY_BLACK,
  [DeviceColor.GLOSSY_SILVER]: t.DeviceShellColor.GLOSSY_SILVER,
  [DeviceColor.GLOSSY_GOLD]: t.DeviceShellColor.GLOSSY_GOLD,
  [DeviceColor.GLOSSY_ROSE_GOLD]: t.DeviceShellColor.GLOSSY_ROSE_GOLD,
  [DeviceColor.GLOSSY_RED]: t.DeviceShellColor.GLOSSY_RED,
  [DeviceColor.BLUE]: t.DeviceShellColor.BLUE,
  [DeviceColor.MATTE_BLACK]: t.DeviceShellColor.MATTE_BLACK,
  [DeviceColor.MATTE_SILVER]: t.DeviceShellColor.MATTE_SILVER,
  [DeviceColor.MATTE_ROSE_GOLD]: t.DeviceShellColor.MATTE_ROSE_GOLD,
  [DeviceColor.MATTE_TEAL]: t.DeviceShellColor.MATTE_TEAL,
  [DeviceColor.MATTE_FUCHSIA]: t.DeviceShellColor.MATTE_FUCHSIA,
  [DeviceColor.MATTE_RED]: t.DeviceShellColor.MATTE_RED,
  [DeviceColor.MATTE_JADE]: t.DeviceShellColor.MATTE_JADE,
  [DeviceColor.BURGUNDY]: t.DeviceShellColor.BURGUNDY,
  [DeviceColor.SAGE]: t.DeviceShellColor.SAGE,
  [DeviceColor.SAND]: t.DeviceShellColor.SAND,
  [DeviceColor.ONYX]: t.DeviceShellColor.ONYX,
  [DeviceColor.SHADOW_RAIN]: t.DeviceShellColor.SHADOW_RAIN,
  [DeviceColor.MIDNIGHT_RAIN]: t.DeviceShellColor.MIDNIGHT_RAIN,
  [DeviceColor.OCEAN]: t.DeviceShellColor.OCEAN,
  [DeviceColor.UNASSIGNED]: t.DeviceShellColor.MATTE_BLACK,
};

export const DEVICE_MODEL_MAP: {
  [key in t.DeviceModel]: DeviceType;
} = {
  [t.DeviceModel.ERA]: DeviceType.ERA,
  [t.DeviceModel.ERA_PRO]: DeviceType.ERA_PRO,
  [t.DeviceModel.PAX_3]: DeviceType.PAX_3,
  [t.DeviceModel.PAX_3_5]: DeviceType.PAX_35,
};

export const DEVICE_TYPE_MAP: {
  [key in DeviceType]: t.DeviceModel;
} = {
  [DeviceType.ERA]: t.DeviceModel.ERA,
  [DeviceType.ERA_PRO]: t.DeviceModel.ERA_PRO,
  [DeviceType.PAX_3]: t.DeviceModel.PAX_3,
  [DeviceType.PAX_35]: t.DeviceModel.PAX_3_5,
};

export const ORDER_BY_APP_DEFAULT = OrderBy.RELEVANCE;

export const STRAIN_POTENCY_DISPLAY_MAP: {
  [key in Potency]: string;
} = {
  [Potency.MILD]: text.MILD,
  [Potency.MODERATE]: text.MODERATE,
  [Potency.STRONG]: text.STRONG,
  [Potency.VERY_STRONG]: text.VERY_STRONG,
};

export const PAX_PARTNER_ID = "aW";
