import React from "react";
import * as ReactDeviceDetect from "react-device-detect";
import { useSelector } from "react-redux";
import styled, { css } from "styled-components";

import * as navigator from "../../../navigator";

import { MINIMUM_IOS_VERSION } from "../../appConstants";
import { MailingListForm } from "../../mailing-list/containers/MailingListForm";
import { getUserRegionMailingList } from "../../mailing-list/selectors";
import Button from "../../shared/components/Button";
import { Column } from "../../shared/components/Flex";
import { PaxLogo } from "../../shared/components/Icons";
import Page from "../../shared/components/Page";
import * as Text from "../../shared/components/Text";
import { desktopBreakpoint } from "../../shared/utils";

export type UnsupportedDevicePageProps = {
  isIOS: boolean;
  isIOSUpgradeNeeded: boolean;
};

export const UnsupportedDevicePage: React.FC<UnsupportedDevicePageProps> = ({
  isIOS = ReactDeviceDetect.isIOS,
  isIOSUpgradeNeeded = navigator.isIOSUpgradeNeeded(),
}) => {
  const mailingList = useSelector(getUserRegionMailingList);

  let browserName = "Google Chrome";
  let browserLink = "https://www.google.com/chrome";

  if (isIOS) {
    browserName = "Connect Browser";
    browserLink = "https://apps.apple.com/us/app/connect-browser/id1543475842";
  }

  const installSoftware = (
    <sc.Text>
      Device connections are not possible in your browser. Please install{" "}
      {browserName} to use the PAX Web App.
      <br />
      <br />
      <sc.Button
        as="a"
        href={browserLink}
        rel="noopener noreferrer"
        target="_blank"
      >
        Get {browserName}
      </sc.Button>
    </sc.Text>
  );

  const upgradeOS = (
    <sc.Text>
      Device connections are not possible in your version of iOS. Please upgrade
      to iOS {MINIMUM_IOS_VERSION} or above.
    </sc.Text>
  );

  return (
    <sc.Page>
      <sc.Container>
        <sc.PaxLogo />
        {isIOSUpgradeNeeded ? upgradeOS : installSoftware}
        {mailingList && (
          <>
            <sc.Text>
              To stay up to date with all PAX announcements, including updates
              on this situation, join our email list.
            </sc.Text>
            <MailingListForm mailingList={mailingList} />
          </>
        )}
      </sc.Container>
    </sc.Page>
  );
};

const sc = {
  Button: styled(Button)`
    display: inline-block;
    text-decoration: none;
  `,

  Container: styled(Column)`
    padding: 16px;
    text-align: center;

    ${desktopBreakpoint(css`
      margin-top: 260px;
      padding: 0;
      width: 518px;
    `)}
  `,

  Page: styled(Page)`
    align-items: center;
    height: 100vh;
    margin: 0;
  `,

  PaxLogo: styled(PaxLogo)`
    height: 36px;
    margin: 40px 0;

    ${desktopBreakpoint(
      css`
        margin-top: 0;
      `
    )}
  `,

  Text: styled.p`
    ${Text.PlaaxLight};
    font-size: 14px;
    line-height: 20px;
    margin: 0 0 40px;
    text-align: center;
  `,
};
