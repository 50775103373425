import React from "react";
import { useHistory } from "react-router-dom";
import styled, { css } from "styled-components";

import * as routes from "../../../main/routes";
import Button from "../../../shared/components/Button";
import * as Text from "../../../shared/components/Text";
import * as text from "../../../shared/text";
import { desktopBreakpoint } from "../../../shared/utils";

export const NoPods: React.FC = () => {
  const history = useHistory();

  const handleButtonClick = () => {
    history.push(routes.podsHome());
  };

  return (
    <sc.Container data-testid="no-pods">
      {text.NO_PODS_HERE_YET}
      <sc.ExplorePodsButton onClick={handleButtonClick} role="link">
        {text.EXPLORE_PODS}
      </sc.ExplorePodsButton>
    </sc.Container>
  );
};

const sc = {
  Container: styled.div`
    ${Text.PlaaxLight}
    align-items: center;
    border: none;
    color: var(--gunmetal);
    display: flex;
    flex-direction: column;
    font-size: 16px;
    height: calc(100vh - 240px);
    justify-content: center;
    max-width: ${(props) => props.theme.myPax.pageContentWidth};
    ${desktopBreakpoint(
      css`
        height: 240px;
      `
    )}
  `,

  ExplorePodsButton: styled(Button)`
    margin-top: 24px;
  `,
};
