import { ExperimentCode } from "../types/experiments";

export const assignedVariations = (): string =>
  "/experiments/assigned-variations";

export const successes = (experimentCode: ExperimentCode): string =>
  `/experiments/${experimentCode}/successes`;

export const views = (experimentCode: ExperimentCode): string =>
  `/experiments/${experimentCode}/views`;
