import React from "react";
import { useSelector } from "react-redux";
import styled, { css } from "styled-components";

import * as consumerApi from "../../../api/consumer/";
import * as routes from "../../../main/routes";
import { useFavoritePod } from "../../../my-pax/hooks";
import { useDesktopMediaQuery } from "../../../shared/hooks";

import { useSignInUpsellModal } from "../../hooks";
import * as t from "../../text";
import { desktopBreakpoint } from "../../utils";

import { Heart } from "../Icons";

export type FavoriteHeartButtonProps = {
  strain: consumerApi.types.strain.StrainJson;
};

export const FavoriteHeartButton: React.FC<FavoriteHeartButtonProps> = ({
  strain,
  ...props
}) => {
  const isDesktop = useDesktopMediaQuery();

  const isUserSignedIn = useSelector(
    consumerApi.selectors.user.getIsUserSignedIn
  );

  const { createFavorite, deleteFavorite, isFavorited } =
    useFavoritePod(strain);

  const { handleShowSignInUpsell, signInUpsellModal } = useSignInUpsellModal(
    t.weKnowYouLoveThisPod(strain.name),
    <Heart />,
    routes.podDetails(strain.id),
    t.SIGN_UP_TO_SAVE
  );

  const handleHeartClick = async (): Promise<void> => {
    if (!isUserSignedIn) {
      handleShowSignInUpsell();
      return;
    }

    isFavorited ? deleteFavorite() : createFavorite();
  };

  return (
    <>
      <sc.Container $isFavorited={isFavorited} onClick={handleHeartClick}>
        <sc.Heart
          $isFavorited={!isDesktop && isFavorited}
          data-testid="favorite-heart-button"
          {...props}
        />
      </sc.Container>
      {signInUpsellModal}
    </>
  );
};

type ContainerProps = {
  $isFavorited?: boolean;
};

const sc = {
  Container: styled.div<ContainerProps>`
    align-items: center;
    cursor: pointer;
    display: flex;
    height: 32px;
    justify-content: center;
    padding: 8px;
    width: 34px;
    z-index: var(--z-index-favorite-heart);

    ${desktopBreakpoint(
      css<ContainerProps>`
        background-color: ${({ $isFavorited }) =>
          $isFavorited ? "var(--black)" : "var(--white)"};
        border: solid 3px var(--black);
        border-radius: 4px;
        box-shadow: none;
        height: 46px;
        margin-right: 16px;
        right: 0;
        width: 46px;

        &:hover {
          background: var(--white-three);
        }
      `
    )}
  `,

  Heart: styled(Heart)`
    height: 100%;
    width: 100%;

    ${desktopBreakpoint(
      css`
        height: 70%;
        width: 70%;
      `
    )}
  `,
};
