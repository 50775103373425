import { connect } from "react-redux";

import * as t from "../../main/types";
import { openFirmwareModal } from "../../modal/actions";

import * as firmwareUpdateBanner from "../components/FirmwareUpdateBanner";

const mapStateToProps = (
  state: t.MainAppState
): firmwareUpdateBanner.StateProps => {
  return {
    latestFirmwareAvailable: state.firmware.firmwareInfo.version,
  };
};

const mapDispatchToProps: firmwareUpdateBanner.DispatchProps = {
  openFirmwareModal,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(firmwareUpdateBanner.FirmwareUpdateBanner);
