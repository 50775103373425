import React, { FormEvent, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import * as consumerApi from "../../api/consumer";
import * as cookies from "../../cookies";
import { home } from "../../main/routes";
import { BannerType } from "../../shared/components/FlashBanner";
import { PasswordInput } from "../../shared/components/PasswordInput";
import * as sharedHooks from "../../shared/hooks";
import { SearchParam } from "../../shared/types";

import { useSsoPageReset } from "../hooks";
import * as text from "../text";
import { SsoPage, SsoProps } from "../types";
import * as utils from "../utils";
import { SsoChrome } from "./SsoChrome";
import { SsoErrorMessage } from "./SsoErrorMessage";
import * as SsoForm from "./SsoForm";

export const PASSWORD_UPDATE_SUCCESS = "Password updated successfully!";

export type SsoEditAccountPageProps = SsoProps & {
  failureCode?: consumerApi.types.ErrorCode;
  setBannerCookie?: cookies.SetBannerCookie;
  success?: boolean;
  updateUser: (updateUser: consumerApi.types.user.UpdateUser) => void;
  useSearchParam: sharedHooks.UseSearchParam;
};

export const SsoEditAccountPage: React.FC<SsoEditAccountPageProps> = ({
  endSsoFlow = utils.endSsoFlow,
  failureCode,
  setBannerCookie = cookies.setBannerCookie,
  success,
  updateUser,
  useSearchParam = sharedHooks.useSearchParam,
}) => {
  const redirectTo = useSearchParam(SearchParam.REDIRECT) || home();
  const shouldCloseWindow = useSearchParam(SearchParam.CLOSE) || "false";
  const [password, setPassword] = useState<string>("");
  const email = useSelector(consumerApi.selectors.user.getUserEmail);
  useSsoPageReset(SsoPage.updateUser);

  if (success) {
    setBannerCookie(PASSWORD_UPDATE_SUCCESS, BannerType.SUCCESS);
    endSsoFlow(redirectTo, shouldCloseWindow);
    return null;
  }

  return (
    <SsoChrome>
      <SsoForm.Form
        data-testid="edit-account-form"
        onSubmit={(e: FormEvent<HTMLFormElement>): void => {
          e.preventDefault();
          updateUser({ password });
        }}
      >
        <SsoForm.Field>
          <sc.Title>{text.EDIT_ACCOUNT_TITLE}</sc.Title>
          <SsoForm.Subtitle></SsoForm.Subtitle>
        </SsoForm.Field>
        <sc.EmailField>
          <SsoForm.Label htmlFor="email-input">
            {text.EMAIL_LABEL}
          </SsoForm.Label>
          <SsoForm.Input
            id="email-input"
            data-testid="email-input"
            disabled
            type="email"
            value={email}
          />
        </sc.EmailField>
        <SsoForm.Field>
          <SsoForm.Label htmlFor="password-input">
            {text.CHANGE_PASSWORD_LABEL}
          </SsoForm.Label>
          <SsoForm.Input disabled value="••••••••••••" />
          <PasswordInput
            data-testid="password-input"
            hasError={utils.isPasswordInError(failureCode)}
            id="password-input"
            Input={SsoForm.PasswordInput}
            onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
              setPassword(e.target.value)
            }
            placeholder={text.PASSWORD_PLACEHOLDER}
            required
          />
        </SsoForm.Field>
        {failureCode && (
          <SsoForm.Field>
            <SsoErrorMessage failureCode={failureCode} isNewPassword />
          </SsoForm.Field>
        )}
        <SsoForm.ButtonsContainer>
          <SsoForm.Button data-testid="submit-button" type="submit">
            {text.UPDATE_BUTTON}
          </SsoForm.Button>
        </SsoForm.ButtonsContainer>
      </SsoForm.Form>
    </SsoChrome>
  );
};

const sc = {
  EmailField: styled(SsoForm.Field)`
    margin-bottom: 40px;
  `,

  Title: styled(SsoForm.Title)`
    font-size: 24px;
    margin: 40px 0 0 0;
  `,
};
