import { createSelector } from "reselect";

import { getUserLocation } from "../api/consumer/selectors/user";
import { MAILING_LIST_MAP } from "./constants";
import { MailingList } from "./types";

export const getUserRegionMailingList = createSelector(
  [getUserLocation],
  (location): MailingList | null => {
    if (!location) return null;

    return MAILING_LIST_MAP[location.region];
  }
);
