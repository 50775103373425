import styled, { css } from "styled-components";

import { desktopBreakpoint } from "../utils";

const Page = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 16px auto 0;
  max-width: 1036px;
  padding: 0 16px 10px;

  ${desktopBreakpoint(
    css`
      margin: 40px auto;
      padding: 0;
    `
  )}
`;

export default Page;
