import { Location } from "history";
import React from "react";
import { useSelector } from "react-redux";
import * as ReactRouterDOM from "react-router-dom";
import styled, { css } from "styled-components";

import { MainAppState } from "../../main/types";
import { desktopBreakpoint } from "../../shared/utils";

import { getActiveStrainFiltersCount } from "../selectors";

export type ActiveStrainFiltersIndicatorProps = {
  useLocation?: () => Location;
};

export const ActiveStrainFiltersIndicator: React.FC<ActiveStrainFiltersIndicatorProps> =
  ({ useLocation = ReactRouterDOM.useLocation }) => {
    const location = useLocation();

    const activeStrainFiltersCount = useSelector((state: MainAppState) =>
      getActiveStrainFiltersCount(state, { location })
    );

    if (activeStrainFiltersCount <= 0) return null;

    return (
      <sc.ActiveStrainFiltersCountIndicator data-testid="active-strain-filters-indicator">
        {activeStrainFiltersCount}
      </sc.ActiveStrainFiltersCountIndicator>
    );
  };

const sc = {
  ActiveStrainFiltersCountIndicator: styled.div`
    align-items: center;
    background-color: var(--black);
    border-radius: 8px;
    color: var(--white);
    display: flex;
    font-size: 10px;
    height: 16px;
    justify-content: center;
    position: absolute;
    right: 53px;
    top: 10px;
    width: 16px;

    ${desktopBreakpoint(css`
      right: -3px;
      top: 0px;
    `)}
  `,
};
