import React from "react";
import { useSelector } from "react-redux";
import { RouteComponentProps } from "react-router";
import styled, { css } from "styled-components";

import { STRAIN_POTENCY_DISPLAY_MAP } from "../../api/consumer/constants";
import { Potency } from "../../api/consumer/types/strain";
import { ButtonWrapper } from "../../shared/components/ButtonWrapper";
import { Row } from "../../shared/components/Flex";
import { Clear, Star } from "../../shared/components/Icons";
import * as Text from "../../shared/components/Text";
import * as t from "../../shared/text";
import * as u from "../../shared/utils";

import * as s from "../selectors";
import { OnStrainsFilterSet, StrainsFilter } from "../types";

type PodsActiveFiltersProps = RouteComponentProps & {
  setFilter: OnStrainsFilterSet;
  strainsFilter: StrainsFilter;
};

export const PodsActiveFilters: React.FC<PodsActiveFiltersProps> = ({
  setFilter,
  strainsFilter,
}) => {
  const activePartnerOption = useSelector(s.getSelectedPartnerOption);
  const activePotencyOptions = useSelector(s.getSelectedPotencyOptions);
  const activeRatingOption = useSelector(s.getSelectedRatingOption);
  const activeRegionOption = useSelector(s.getSelectedRegionOption);
  const activeStateFilter = useSelector(s.getStateOrProvinceFromFilter);

  const handleRegionClick = (): void => {
    const newFilter = { ...strainsFilter };
    delete newFilter.region;

    setFilter(newFilter);
  };

  const handleStateClick = (): void => {
    const newFilter = { ...strainsFilter };
    delete newFilter.state;
    delete newFilter.region;

    setFilter(newFilter);
  };

  const handleRatingClick = (): void => {
    const newFilter = { ...strainsFilter };
    delete newFilter.minAvgRating;

    setFilter(newFilter);
  };

  const handlePartnerClick = (): void => {
    const newFilter = { ...strainsFilter };
    delete newFilter.partnerId;

    setFilter(newFilter);
  };

  const handleEffectClick = (removedEffect: string): void => {
    const newEffects = strainsFilter.desiredEffects.filter(
      (effect) => effect !== removedEffect
    );

    setFilter({ ...strainsFilter, desiredEffects: newEffects });
  };

  const handleSelectedEffectClick = (removedEffect: string): void => {
    const newEffects = strainsFilter.selectedEffects.filter(
      (effect) => effect !== removedEffect
    );

    setFilter({ ...strainsFilter, selectedEffects: newEffects });
  };

  const handleFlavorClick = (removedFlavor: string): void => {
    const newEffects = strainsFilter.flavors.filter(
      (flavor) => flavor !== removedFlavor
    );

    setFilter({ ...strainsFilter, flavors: newEffects });
  };

  const handlePotencyClick = (removedPotency: Potency): void => {
    const newPotency = strainsFilter.potency.filter(
      (potency) => potency !== removedPotency
    );

    setFilter({ ...strainsFilter, potency: newPotency });
  };

  const handleClassificationClick = (removedClassification: string): void => {
    const newClassifications = strainsFilter.strainClassifications.filter(
      (classification) => classification !== removedClassification
    );

    setFilter({
      ...strainsFilter,
      strainClassifications: newClassifications,
    });
  };

  const filters: React.ReactNode[] = [];

  if (activeStateFilter) {
    filters.push(
      <sc.Pill key="state" onClick={handleStateClick}>
        <sc.Text>{activeStateFilter.toLocaleLowerCase()}</sc.Text>
        <sc.ClearIcon color="var(--white)" />
      </sc.Pill>
    );
  } else if (activeRegionOption) {
    filters.push(
      <sc.Pill key="region" onClick={handleRegionClick}>
        <sc.Text>{activeRegionOption.label.toLocaleLowerCase()}</sc.Text>
        <sc.ClearIcon color="var(--white)" />
      </sc.Pill>
    );
  }

  if (activeRatingOption) {
    filters.push(
      <sc.Pill key="rating" onClick={handleRatingClick}>
        <sc.Text>{t.starFilterPillText(activeRatingOption)}</sc.Text>
        <sc.ClearIcon color="var(--white)" />
      </sc.Pill>
    );
  }

  if (activePartnerOption) {
    filters.push(
      <sc.Pill key="partner" onClick={handlePartnerClick}>
        <sc.Text>{activePartnerOption.label}</sc.Text>
        <sc.ClearIcon color="var(--white)" />
      </sc.Pill>
    );
  }

  activePotencyOptions?.forEach((potency) => {
    filters.push(
      <sc.Pill key={potency} onClick={(): void => handlePotencyClick(potency)}>
        <sc.Text>{STRAIN_POTENCY_DISPLAY_MAP[potency]}</sc.Text>
        <sc.ClearIcon color="var(--white)" />
      </sc.Pill>
    );
  });

  strainsFilter.desiredEffects?.forEach((effect) => {
    filters.push(
      <sc.Pill key={effect} onClick={(): void => handleEffectClick(effect)}>
        <sc.Text>{effect.toLocaleLowerCase()}</sc.Text>
        <sc.ClearIcon color="var(--white)" />
      </sc.Pill>
    );
  });

  strainsFilter.flavors?.forEach((flavor) => {
    filters.push(
      <sc.Pill key={flavor} onClick={(): void => handleFlavorClick(flavor)}>
        <sc.Text>{u.removeUnderscore(flavor).toLocaleLowerCase()}</sc.Text>
        <sc.ClearIcon color="var(--white)" />
      </sc.Pill>
    );
  });

  strainsFilter.selectedEffects?.forEach((effect) => {
    filters.push(
      <sc.Pill
        key={effect}
        onClick={(): void => handleSelectedEffectClick(effect)}
      >
        <sc.Text>{effect.toLocaleLowerCase()}</sc.Text>
        <sc.ClearIcon color="var(--white)" />
      </sc.Pill>
    );
  });

  strainsFilter.strainClassifications?.forEach((classification) => {
    filters.push(
      <sc.Pill
        key={classification}
        onClick={(): void => handleClassificationClick(classification)}
        data-testid="pods-filter"
      >
        <sc.Text>
          {u.getStrainClassificationFormattedText(classification)}
        </sc.Text>
        <sc.ClearIcon color="var(--white)" data-testid="clear-pods-filter" />
      </sc.Pill>
    );
  });

  return <sc.Container>{filters}</sc.Container>;
};

const sc = {
  ClearIcon: styled(Clear)`
    height: 16px;
    margin-left: 8px;
    width: 14px;

    ${u.desktopBreakpoint(css`
      display: initial;
    `)}
  `,

  Container: styled(Row)`
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-bottom: 10px;

    ${u.desktopBreakpoint(css`
      margin-bottom: 12px;
    `)}
  `,

  Pill: styled(ButtonWrapper)`
    align-items: center;
    background-color: var(--black);
    border-radius: 6px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    margin: 0 8px 8px 0;
    padding: 15px 20px;

    &:last-child {
      margin-right: 0;
    }

    ${u.desktopBreakpoint(css`
      margin: 0 16px 8px 0;
    `)}
  `,

  Star: styled(Star)`
    height: 12px;
    margin-right: 4px;
    width: 12px;
  `,

  Text: styled.div`
    ${Text.MediumLLBold}
    color: var(--white);
    font-size: 14px;
    line-height: 18px;
    text-transform: capitalize;
  `,
};
