import { connect } from "react-redux";

import { getIsShareDataFlagForUserUnset } from "../../api/consumer/selectors/user";
import { getConnectedDevice } from "../../bluetooth/connectedDevice";
import * as t from "../../main/types";
import * as ModalActions from "../../modal/actions";
import * as modalSelectors from "../../modal/selectors";

import * as connectDevicePage from "../components/ConnectDevicePage";
import * as deviceSelectors from "../selectors";

const mapStateToProps = (
  state: t.MainAppState
): connectDevicePage.StateProps => {
  return {
    connectedDevice: getConnectedDevice(),
    deviceError: deviceSelectors.getDeviceError(state),
    hasAcceptedPrivacyPolicy: modalSelectors.getHasAcceptedPrivacyPolicy(state),
    isPrivacyPolicyModalOpen: modalSelectors.getIsPrivacyPolicyModalOpen(state),
    isShareDataFlagUnsetForUser: getIsShareDataFlagForUserUnset(state),
    isUsageAgreementModalOpen:
      modalSelectors.getIsUsageAgreementModalOpen(state),
  };
};

const mapDispatchToProps: connectDevicePage.DispatchProps = {
  closePrivacyPolicyModal: ModalActions.closePrivacyPolicyModal,
  closeUsageAgreementModal: ModalActions.closeUsageAgreementModal,
  openPrivacyPolicyModal: ModalActions.openPrivacyPolicyModal,
  openUsageAgreementModal: ModalActions.openUsageAgreementModal,
};

export const ConnectDevicePage = connect(
  mapStateToProps,
  mapDispatchToProps
)(connectDevicePage.ConnectDevicePage);
