import compareVersions from "compare-versions";

import * as c from "../constants";
import { BaseDevice } from "../shared/BaseDevice";
import {
  ERA_AES_KEY_SIZE,
  LegacyDeviceEncryption,
} from "../shared/LegacyDeviceEncryption";
import * as t from "../types";
import * as u from "../utils";
import { EraLogRequestOptions, EraLogStore } from "./EraLogStore";
import { EraReplay } from "./EraReplay";

const ERA_BRIGHTNESS_MIN = 0;
const ERA_BRIGHTNESS_MAX = 128;
// For NONE, MICRO, SMALL, MEDIUM, LARGE sessions.
const ERA_PRESET_DOSAGES = [0, 2500, 5000, 10000, 12500];

const ERR_NOT_SUPPORTED = "Not supported by device";

export class EraDevice extends BaseDevice {
  isInitializing = false;
  type = t.DeviceType.ERA;

  encryption?: LegacyDeviceEncryption;

  async initialize(
    attributeIdsToRead: number[],
    options: t.DeviceOptions
  ): Promise<void> {
    this.isInitializing = true;
    this.firmwareRevision = await this.readFirmwareRevision();
    this.supportsLogs = compareVersions(this.firmwareRevision, "0.7.0") !== -1;
    this.supportsRenameDevice =
      compareVersions(this.firmwareRevision, "0.7.0") !== -1;
    this.supportsSessionControl =
      compareVersions(this.firmwareRevision, "0.7.0") !== -1;
    this.systemId = (await this.readSystemId()).toString();

    this.serial = options?.serial || "";
    this.encryption = new LegacyDeviceEncryption(this.serial);

    const service = await this.getService(c.ERA_PAX_SERVICE_UUID);

    this.notify = await service.getCharacteristic(c.ERA_CHARACTERISTICS.Notify);
    this.read = await service.getCharacteristic(c.ERA_CHARACTERISTICS.Read);
    this.write = await service.getCharacteristic(c.ERA_CHARACTERISTICS.Write);

    this.notify.addEventListener(
      "characteristicvaluechanged",
      this.onCharacteristicValueChanged
    );
    await this.notify.startNotifications();

    if (this.supportsLogs) {
      this.logStore = new EraLogStore(this);
      await this.logStore.initialize();
    }

    // For Era devices with firmware <0.6.90, this is a no-op.
    // These devices provide attribute value notifications upon connecting.
    this.readAttributes(attributeIdsToRead);

    return new Promise((resolve, reject) => {
      // Detect a bad identifier by waiting for notifications on this.notify.
      // Don't use await to avoid delaying initialize from returning.
      setTimeout(() => {
        this.isInitializing = false;

        if (!this.hasReceivedNotification || this.hasReceivedUnknownAttribute) {
          return reject(new Error(c.ERR_BAD_IDENTIFIER));
        }

        resolve();
      }, c.CONNECT_WAIT_TIME - 100);
    });
  }

  disconnect(): void {
    this.notify?.removeEventListener(
      "characteristicvaluechanged",
      this.onCharacteristicValueChanged
    );

    if (this.logStore) this.logStore.disconnect();
    if (this.gatt.connected) this.gatt.disconnect();
  }

  onCharacteristicValueChanged = async (event: Event): Promise<void> => {
    this.hasReceivedNotification = true;

    if (!event || !event.target || !this.read) return;

    // Received a notification from the "data ready" characteristic.
    // Read this data from the "read" characteristic.
    const value = await this.read.readValue();
    const bytes = new Uint8Array(value.buffer);

    // Decrypt the data.
    const data = bytes.slice(0, -1 * ERA_AES_KEY_SIZE);
    const encryptionInitializationVector = bytes.slice(-1 * ERA_AES_KEY_SIZE);
    const encryption = new LegacyDeviceEncryption(
      this.serial || "",
      encryptionInitializationVector
    );
    const decryptedData = encryption.decrypt(data);

    // Parse the attribute value and notify listeners.
    const attributeId = decryptedData[0];
    const attributeValue = this.parseAttributeValue(decryptedData);
    this.events.emit("receivedAttribute", attributeId, attributeValue);
  };

  readBrightness(): void {
    this.readAttribute(c.ATTRIBUTE_BRIGHTNESS);
  }

  readColorTheme(): void {
    this.readAttribute(c.ATTRIBUTE_COLOR_THEME);
  }

  readDeviceName(): void {
    this.readAttribute(c.ATTRIBUTE_DEVICE_NAME);
  }

  readHaptics(): void {
    throw new Error(ERR_NOT_SUPPORTED);
  }

  readHeaterSetPoint(): void {
    this.readAttribute(c.ATTRIBUTE_HEATER_SET_POINT);
  }

  readLockState(): void {
    this.readAttribute(c.ATTRIBUTE_LOCKED);
  }

  readReplay(): void {
    this.readAttribute(c.ATTRIBUTE_REPLAY);
  }

  startReplay(presetDoseId: number, heaterSetPoint: number): void {
    const usage = ERA_PRESET_DOSAGES[presetDoseId];

    const replay = new EraReplay(c.Era.REPLAY_ACTION_USER_START, presetDoseId, [
      { heaterSetPoint, usage },
    ]);

    this.writeAttribute(c.ATTRIBUTE_REPLAY, replay);
  }

  stopReplay(doseId: number): void {
    const replay = new EraReplay(c.Era.REPLAY_ACTION_USER_STOP, doseId);

    this.writeAttribute(c.ATTRIBUTE_REPLAY, replay);
  }

  writeBrightness(percentage: number): void {
    const brightness = this.percentageToBrightness(percentage);

    this.writeAttribute(c.ATTRIBUTE_BRIGHTNESS, brightness);
  }

  writeColorMode(colorMode: t.ColorMode): void {
    this.writeAttribute(c.ATTRIBUTE_COLOR_THEME, colorMode);
  }

  writeDeviceName(deviceName: string): void {
    if (!this.supportsRenameDevice) return;
    this.writeAttribute(c.ATTRIBUTE_DEVICE_NAME, deviceName);
  }

  writeHaptics(): void {
    throw new Error(ERR_NOT_SUPPORTED);
  }

  writeHeaterSetPoint(heaterSetPoint: number): void {
    this.writeAttribute(c.ATTRIBUTE_HEATER_SET_POINT, heaterSetPoint);
  }

  writeLockState(value: number): void {
    this.writeAttribute(c.ATTRIBUTE_LOCKED, value);
  }

  getWriteOperationBuffer(attributeId: number, value: unknown): Uint8Array {
    if (!this.encryption) throw new Error("not initialized");

    const buffer = new ArrayBuffer(16);
    const view = new DataView(buffer);
    view.setUint8(0, attributeId);

    switch (attributeId) {
      case c.ATTRIBUTE_BRIGHTNESS:
        view.setUint8(1, value as number);
        // Set brightness and default brightness.
        view.setUint8(2, 0);
        break;

      case c.ATTRIBUTE_COLOR_THEME:
        view.setUint8(1, 1);
        const colorTheme = value as t.ColorMode;
        view.setUint8(2, colorTheme.color1.red);
        view.setUint8(3, colorTheme.color1.green);
        view.setUint8(4, colorTheme.color1.blue);
        view.setUint8(5, colorTheme.color2.red);
        view.setUint8(6, colorTheme.color2.green);
        view.setUint8(7, colorTheme.color2.blue);
        view.setUint8(8, colorTheme.animation);
        view.setUint8(9, colorTheme.frequency);
        break;

      case c.ATTRIBUTE_DEVICE_NAME:
        const deviceName = value as string;
        view.setUint8(1, deviceName.length);

        const encodedName = u.encodeTextValue(deviceName);
        for (let i = 0; i < encodedName.length; i++) {
          view.setUint8(2 + i, encodedName[i]);
        }
        break;

      case c.ATTRIBUTE_HAPTICS:
        view.setUint8(1, value as number);
        // Trigger haptics.
        view.setUint8(2, 1);
        break;

      case c.ATTRIBUTE_HEATER_SET_POINT:
        view.setUint16(1, value as number, true);
        break;

      case c.ATTRIBUTE_LOCKED:
        view.setUint8(1, value as number);
        break;

      case c.ATTRIBUTE_LOG_SYNC_REQUEST:
        const logSyncValue = value as EraLogRequestOptions;

        view.setUint32(1, logSyncValue.timestamp, true);
        view.setUint8(5, logSyncValue.timestampOffset);
        break;

      case c.ATTRIBUTE_REPLAY:
        const replay = value as EraReplay;
        replay.writeToView(view, 1);
        break;

      case c.ATTRIBUTE_SESSION_CONTROL:
        const valueBuffer = value as Uint8Array;
        for (let i = 0; i < valueBuffer.length; i++) {
          view.setUint8(1 + i, valueBuffer[i]);
        }
        break;

      case c.ATTRIBUTE_STATUS_UPDATE:
        view.setBigUint64(1, BigInt(value as number), true);
        break;

      case c.ATTRIBUTE_TIME:
        view.setUint32(1, value as number, true);
        break;

      default:
        throw new Error(`Unrecognized attribute: ${attributeId}`);
    }

    return this.encryption.encrypt(new Uint8Array(buffer));
  }

  protected async readFirmwareRevision(): Promise<string> {
    const data: DataView = await this.readCharacteristicValue(
      c.DEVICE_INFO_SERVICE_UUID,
      c.ERA_CHARACTERISTICS.FirmwareRevision
    );
    const revision: string = u.decodeTextValue(data);

    return revision;
  }

  protected async readSystemId(): Promise<bigint> {
    const data: DataView = await this.readCharacteristicValue(
      c.DEVICE_INFO_SERVICE_UUID,
      c.ERA_CHARACTERISTICS.SystemId
    );

    return data.getBigUint64(0, true);
  }

  private brightnessToPercentage(brightness: number): number {
    return (
      (brightness - ERA_BRIGHTNESS_MIN) /
      (ERA_BRIGHTNESS_MAX - ERA_BRIGHTNESS_MIN)
    );
  }

  private bytesToColorMode(bytes: Uint8Array): t.ColorMode {
    bytes = bytes.map((byte) => byte & 0xff);

    const colorMode: t.ColorMode = {
      animation: bytes[6],
      color1: {
        blue: bytes[2],
        green: bytes[1],
        red: bytes[0],
      },
      color2: {
        blue: bytes[5],
        green: bytes[4],
        red: bytes[3],
      },
      frequency: bytes[7],
    };

    return colorMode;
  }

  private parseAttributeValue(bytes: Uint8Array): unknown {
    const attributeId = bytes[0];
    const view = new DataView(bytes.buffer);

    switch (attributeId) {
      // Attribute with 1-byte uint8/boolean values.
      case c.ATTRIBUTE_BATTERY:
      case c.ATTRIBUTE_GAME_MODE:
      case c.ATTRIBUTE_LOCKED:
      case c.ATTRIBUTE_POD_INSERTED:
      case c.ATTRIBUTE_SHELL_COLOR:
      case c.ATTRIBUTE_UI_MODE:
        return bytes[1];

      case c.ATTRIBUTE_BRIGHTNESS:
        return this.brightnessToPercentage(bytes[1]);

      case c.ATTRIBUTE_CHARGE_STATUS:
        const chargeStatus = bytes[1].toString(2);

        return {
          isCharging: chargeStatus[0] === "1",
          isChargingComplete: chargeStatus[1] === "1",
        };

      case c.ATTRIBUTE_COLOR_THEME:
        // Format is 1 byte for number of color modes, followed by 8 bytes per mode.
        const colorModesCount = bytes[1];
        const colorModes: t.ColorMode[] = [];

        for (let i = 0; i < colorModesCount; i++) {
          const colorBytes = bytes.slice(2 + i * 8, 10 + i * 8);

          const colorMode = this.bytesToColorMode(colorBytes);
          colorModes.push(colorMode);
        }

        return colorModes;

      case c.ATTRIBUTE_DEVICE_NAME:
        const nameLength = bytes[1];
        const data = bytes.slice(2, 2 + nameLength);
        const name = u.decodeTextValue(data);

        return name;

      case c.ATTRIBUTE_HEATER_SET_POINT:
        const heaterSetPoint = view.getUint16(1, true);
        return heaterSetPoint;

      case c.ATTRIBUTE_REPLAY:
        const replayBytes = bytes.slice(1);
        const replay = EraReplay.fromBytes(replayBytes);
        return replay.toJson();

      case c.ATTRIBUTE_SUPPORTED_ATTRIBUTES:
        const bitfield = view.getBigUint64(1);
        const binary = bitfield.toString(2);
        return binary;

      case c.ATTRIBUTE_TIME:
        const epochInSeconds = view.getUint32(1, true);
        return epochInSeconds;

      case c.ATTRIBUTE_USAGE:
        const eraUsage: t.Era.Usage = {
          isEndOfPuff: view.getUint8(6) === 1,
          puffCj: view.getUint8(5),
          totalSessionCj: view.getUint32(1),
        };
        return eraUsage;

      case c.ATTRIBUTE_USAGE_LIMIT:
        return view.getUint32(1, true);

      // TODO These attributes are not used in the UI. They are not parsed properly.
      case c.ATTRIBUTE_HEATER_RANGES:
        return Array.from(bytes.slice(1));

      default:
        this.hasReceivedUnknownAttribute = true;

        // Silence unrecognized attribute ids during initialization.
        // The serial number is probably incorrect.
        if (!this.isInitializing) {
          console.error(`Unrecognized Era attribute: ${attributeId}`);
        }

        return bytes[1];
    }
  }

  private percentageToBrightness(percentage: number): number {
    return Math.round(percentage * ERA_BRIGHTNESS_MAX);
  }
}
