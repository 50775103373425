import { connect } from "react-redux";

import * as a from "../../device/actions";
import * as s from "../../device/selectors";
import * as t from "../../main/types";

import { SerialInput } from "../components/SerialInput";

const mapStateToProps = (state: t.MainAppState) => {
  const selectedDeviceType = s.getSelectedDeviceType(state);
  const initialSerial =
    s.getDeviceSerialNumber(state, selectedDeviceType) || "";

  return {
    deviceError: s.getDeviceError(state),
    initialSerial,
    selectedDeviceType,
  };
};

const mapDispatchToProps = {
  selectDeviceType: a.selectDeviceType,
  setSerial: a.setSerial,
};

export default connect(mapStateToProps, mapDispatchToProps)(SerialInput);
