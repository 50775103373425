import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import * as consumerApi from "../../api/consumer";
import { MainAppState } from "../../main/types";
import { RecommendedPodCard } from "../../pods/components/RecommendedPodCard";
import Button from "../../shared/components/Button";
import { Column, Row } from "../../shared/components/Flex";
import * as Text from "../../shared/components/Text";
import * as text from "../../shared/text";

import { StrainDetailsCard } from "./PodFeedbackForm/StrainDetailsCard";

export type ThankYouPageProps = {
  onDoneClick?: () => unknown;
  onPodRecommendationClick?: () => unknown;
  strain: consumerApi.types.strain.StrainJson;
};

export const ThankYouPage: React.FC<ThankYouPageProps> = ({
  onDoneClick,
  onPodRecommendationClick,
  strain,
  ...props
}) => {
  const strainReview = useSelector((state: MainAppState) =>
    consumerApi.selectors.reviews.getStrainReview(state, {
      strainId: strain.id,
    })
  );

  let recommendedPodCards = null;

  if (strainReview && strainReview.recommendedPods.length > 0) {
    recommendedPodCards = strainReview.recommendedPods
      .slice(0, 2)
      .map((pod) => {
        return (
          <RecommendedPodCard
            key={pod.id}
            onPodRecommendationClick={onPodRecommendationClick}
            recommendedStrain={pod}
            referenceStrainReview={strainReview}
          />
        );
      });
  }

  return (
    <sc.Container {...props}>
      <sc.Title>{text.THANK_YOU}!</sc.Title>
      <sc.Subtitle>{text.YOUR_REVIEW_WILL_HELP}</sc.Subtitle>
      <StrainDetailsCard strain={strain} />
      {recommendedPodCards && (
        <sc.RecommendationsContainer>
          <sc.RecommendationsTitle>
            {text.RECOMMENDED_FOR_YOU}
          </sc.RecommendationsTitle>
          <sc.NearYou>
            {text.becauseYouLikedDisliked(
              strainReview?.recommendationType ===
                consumerApi.types.reviews.RecommendationType.SIMILAR_STRAINS,
              strain.name
            )}
          </sc.NearYou>
          <sc.PodsRow>{recommendedPodCards}</sc.PodsRow>
        </sc.RecommendationsContainer>
      )}
      <sc.Button onClick={onDoneClick}>{text.DONE}</sc.Button>
    </sc.Container>
  );
};

const sc = {
  Button: styled(Button)`
    border-radius: 100px;
    height: 48px;
    margin-top: 50px;
    opacity: ${({ disabled }): string => (disabled ? "0.5" : "1")};
    text-transform: capitalize;
    width: 328px;
  `,

  Container: styled(Column)`
    align-items: center;
    margin-top: 32px;
  `,

  NearYou: styled.div`
    ${Text.Plaax}
    font-size: 14px;
    font-weight: 300;
    line-height: 24px;
    margin-top: 4px;
    width: 328px;
  `,

  PodsRow: styled(Row)`
    margin-top: 20px;
  `,

  RecommendationsContainer: styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    width: 328px;
  `,

  RecommendationsTitle: styled.div`
    ${Text.PlaaxBold}
    font-size: 20px;
    line-height: 28px;
  `,

  Subtitle: styled.div`
    ${Text.Plaax}
    font-size: 14px;
    font-weight: 300;
    line-height: 24px;
    margin-top: 16px;
    text-align: center;
    width: 328px;
  `,

  Title: styled.div`
    ${Text.PlaaxBold}
    font-size: 30px;
    line-height: 36px;
    text-align: center;
  `,
};
