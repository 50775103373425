import styled, { SimpleInterpolation } from "styled-components";

import * as Text from "../../../shared/components/Text";

type HeroProps = {
  color?: string;
  image?: string;
  short: boolean;
};

export const Hero = styled.div<HeroProps>`
  ${Text.Plaax}
  align-items: center;
  background-color: var(--very-light-pink-4);
  background-image: url("${({ image }): SimpleInterpolation => image || ""}");
  background-position: center;
  background-size: cover;
  border: solid 1px var(--very-light-pink);
  border-radius: 8px;
  color: ${({ color }): SimpleInterpolation => color || "var(--white)"};
  display: flex;
  font-size: ${({ short, theme }): string => theme.myPax.headerFontSize(short)};
  height: ${({ short, theme }): string => theme.myPax.headerHeight(short)};
  justify-content: center;
  text-transform: uppercase;
  width: 100%;
`;
