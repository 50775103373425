export class Request {
  characteristic: BluetoothRemoteGATTCharacteristic;

  encryptedWrite?: boolean;
  value?: Uint8Array;

  constructor(
    characteristic: BluetoothRemoteGATTCharacteristic,
    value?: Uint8Array,
    encryptedWrite?: boolean
  ) {
    this.characteristic = characteristic;
    this.value = value;
    this.encryptedWrite = encryptedWrite;
  }

  async send(): Promise<void> {
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await (this.characteristic.writeValue as any)(this.value as Uint8Array, {
        encryption: this.encryptedWrite,
      });
    } catch (e) {
      console.error("Request#send error", e);
    }
  }
}
