import React, { useEffect, useState } from "react";
import styled, { SimpleInterpolation } from "styled-components";

import { PreferredTemperatureUnit } from "../../../api/consumer/types/user";
import { TEMPERATURE_UNITS } from "../../../data/temperatureUnits";
import Thermostat from "../../../device/containers/Thermostat/Thermostat";
import * as Card from "../../../shared/components/Card";
import { Row } from "../../../shared/components/Flex";
import * as Text from "../../../shared/components/Text";

import FlowerDynamicModes from "../../containers/Thermostat/FlowerDynamicModes";
import FlowerOvenState from "../../containers/Thermostat/FlowerOvenState";

export type FlowerThermostatCardProps = {
  isDeviceLocked: boolean;
  deviceTemperature?: number;
  temperatureRange: number[];
  temperatureUnit: PreferredTemperatureUnit;
};

export const FlowerThermostatCard: React.FC<FlowerThermostatCardProps> = ({
  isDeviceLocked,
  deviceTemperature,
  temperatureRange,
  temperatureUnit,
}) => {
  const [displayedTemperature, setDisplayedTemperature] = useState(
    deviceTemperature ? Math.round(deviceTemperature) : ""
  );
  const [tempMin, tempMax] = temperatureRange;

  useEffect(() => {
    if (!deviceTemperature) return;
    setDisplayedTemperature(Math.round(deviceTemperature));
  }, [deviceTemperature]);

  const handleThermostatDrag = (deviceTemperature: number): void => {
    setDisplayedTemperature(Math.round(deviceTemperature));
  };

  if (isDeviceLocked) {
    return (
      <sc.BigCard data-testid="locked-thermostat-card" isDeviceLocked>
        <sc.Temperature isDeviceLocked>---</sc.Temperature>
        <FlowerOvenState isDeviceLocked />
        <Row alignItems="flex-end">
          <sc.TemperatureLabel>{tempMin}°</sc.TemperatureLabel>
          <sc.ThermostatContainer>
            <Thermostat
              isDisabled={true}
              tempRange={[tempMin, tempMax]}
              temperatureUnit={temperatureUnit}
            />
          </sc.ThermostatContainer>
          <sc.TemperatureLabel>{tempMax}°</sc.TemperatureLabel>
        </Row>
        <FlowerDynamicModes isDeviceLocked />
      </sc.BigCard>
    );
  }

  return (
    <sc.BigCard data-testid="thermostat-card">
      <sc.Temperature>
        {displayedTemperature}
        {displayedTemperature && (
          <sc.UnitContainer>
            <sc.DegreeSymbol>°</sc.DegreeSymbol>
            <sc.DegreeType>
              {TEMPERATURE_UNITS[temperatureUnit].shorthand}
            </sc.DegreeType>
          </sc.UnitContainer>
        )}
      </sc.Temperature>
      <FlowerOvenState />
      <Row alignItems="flex-end">
        <sc.TemperatureLabel>{tempMin}°</sc.TemperatureLabel>
        <sc.ThermostatContainer>
          <Thermostat
            onDrag={handleThermostatDrag}
            tempRange={[tempMin, tempMax]}
            temperatureUnit={temperatureUnit}
          />
        </sc.ThermostatContainer>
        <sc.TemperatureLabel>{tempMax}°</sc.TemperatureLabel>
      </Row>
      <FlowerDynamicModes />
    </sc.BigCard>
  );
};

type DeviceLockedProps = {
  isDeviceLocked?: boolean;
};

const sc = {
  BigCard: styled(Card.Big)<DeviceLockedProps>`
    height: 570px;
    justify-content: ${(props): SimpleInterpolation =>
      props.isDeviceLocked ? "flex-start" : "center"};
    margin-top: 8px;
  `,

  DegreeSymbol: styled.span`
    letter-spacing: 0.81px;
  `,

  DegreeType: styled.span`
    letter-spacing: 0.75px;
  `,

  OvenState: styled.div`
    font-size: 20px;
    text-transform: uppercase;
  `,

  Temperature: styled.div<DeviceLockedProps>`
    ${Text.PlaaxLight}
    font-size: 80px;
    height: 80px;
    margin-bottom: 10px;
    position: relative;

    ${({ isDeviceLocked }): SimpleInterpolation =>
      isDeviceLocked ? "color: var(--very-light-pink);" : ""}
  `,

  TemperatureLabel: styled.div`
    color: var(--light-grey-blue);
    font-size: 20px;
    margin-bottom: 16px;
  `,

  ThermostatContainer: styled.div`
    margin: 0 4px;
  `,

  UnitContainer: styled.div`
    font-size: 24px;
    position: absolute;
    right: -25px;
    top: 16px;
  `,
};
