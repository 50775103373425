import { AppThunk } from "../../shared/types";

import { trackEventAction } from "../actions";

const EVENT_NAME = "qr_code_scanned";

type QRCodeScannedEventProperties = {
  hasReviewed: boolean;
  isFirstUsage: boolean;
  strainId: string;
};

type TrackQRCodeScanned = (
  strainId: string,
  options: { isFirstUsage: boolean; hasReviewed: boolean }
) => AppThunk;

export const trackQRCodeScanned: TrackQRCodeScanned =
  (strainId, { isFirstUsage, hasReviewed }) =>
  (dispatch, getState) => {
    trackEventAction<QRCodeScannedEventProperties>(EVENT_NAME, {
      hasReviewed,
      isFirstUsage,
      strainId,
    })(dispatch, getState, null);
  };
