import React, { ReactElement, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { getDevicePod } from "../../device/selectors";
import { useStrainById } from "../../pods/hooks";
import { BottomSheet } from "../../shared/components/BottomSheet";
import { Row } from "../../shared/components/Flex";

import { useResponsiveModuleRows } from "../hooks";
import { DeviceModeModule } from "./modules/DeviceModeModule";
import { DeviceSettingsModule } from "./modules/DeviceSettingsModule";
import { DoseControlModule } from "./modules/DoseControlModule";
import { DosePulseModule } from "./modules/DosePulseModule";
import { LockDeviceModule } from "./modules/LockDeviceModule";
import { PodDetailsModule } from "./modules/PodDetailsModule";

const LARGE_MODULE_CARD_HEIGHT = 237;
const MARGIN_BETWEEN_SMALL_MODULE_CARDS = 16;
const MOBILE_NAV_BAR_HEIGHT = 54;
const SMALL_MODULE_CARD_SIZE = 156;

const getModulesContainerHeight = (
  rowsCount: number,
  shouldAddPodDetailsHeight: boolean
): number => {
  const moduleRowsHeight = rowsCount * SMALL_MODULE_CARD_SIZE;

  const moduleRowsMarginsHeight =
    rowsCount * MARGIN_BETWEEN_SMALL_MODULE_CARDS +
    MARGIN_BETWEEN_SMALL_MODULE_CARDS;

  const defaultHeight = moduleRowsHeight + moduleRowsMarginsHeight;

  return shouldAddPodDetailsHeight
    ? defaultHeight + LARGE_MODULE_CARD_HEIGHT + 16
    : defaultHeight;
};

export const ModuleBottomSheet: React.FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const pod = useSelector(getDevicePod);
  const { strain } = useStrainById(pod.strainId);

  const modules: ReactElement[] = [
    /* modules go here - put in the order we want them displayed */
    <DoseControlModule key="dose-control-module" />,
    <DosePulseModule key="dose-pulse-module" />,
    <DeviceModeModule key="device-mode-module" />,
    <DeviceSettingsModule key="device-settings-module" />,
    <LockDeviceModule key="lock-device-module" />,
  ];

  const { gridWidth, moduleRows } = useResponsiveModuleRows(modules);

  const modulesContainerHeight = useMemo(() => {
    return getModulesContainerHeight(moduleRows.length, !!strain);
  }, [moduleRows.length, strain]);

  // Opens bottom sheet to 16px from the top when modules fill the screen
  const defaultBottomSheetHeight = window.screen.height - 16;

  // Decreases bottom sheet opening height if modules dont fill the screen
  const bottomSheetHeight = Math.min(
    defaultBottomSheetHeight,
    modulesContainerHeight
  );

  const visibleScreenHeight = window.screen.height - MOBILE_NAV_BAR_HEIGHT;

  const bottomSheetBottom =
    modulesContainerHeight < visibleScreenHeight ? 0 : undefined;

  return (
    <>
      <sc.TouchTarget
        data-testid="experience-bottom-sheet-handle"
        onClick={() => setIsOpen(true)}
      >
        <sc.Handle />
      </sc.TouchTarget>
      {isOpen && (
        <BottomSheet
          bottomSheetBottom={bottomSheetBottom}
          height={bottomSheetHeight}
          isOpen={isOpen}
          onRequestClose={() => setIsOpen(false)}
          shouldHideCloser
          shouldScroll
          width={window.screen.width}
        >
          <sc.Container>
            <sc.ModulesContainer
              height={modulesContainerHeight}
              width={gridWidth}
            >
              <PodDetailsModule strain={strain} width={gridWidth} />
              {moduleRows.map((row, index) => (
                <Row key={"module-row" + index}>
                  {row.map((module) => module)}
                </Row>
              ))}
            </sc.ModulesContainer>
          </sc.Container>
        </BottomSheet>
      )}
    </>
  );
};

type ModulesContainerProps = {
  height: number;
  width: number;
};

const sc = {
  Container: styled.div`
    background-color: var(--grey);
    display: flex;
    justify-content: center;
    overflow-y: scroll;
    padding: 8px 8px calc(var(--mobile-nav-bar-height) + 8px);
  `,

  Handle: styled.div`
    background-color: var(--white-80);
    border-radius: 2px;
    height: 4px;
    width: 64px;
  `,

  ModulesContainer: styled.div<ModulesContainerProps>`
    height: ${({ height }) => height}px;
    width: ${({ width }) => width}px;
  `,

  TouchTarget: styled.div`
    align-items: center;
    display: flex;
    height: 36px;
    justify-content: center;
    width: 100%;
  `,
};
