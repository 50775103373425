import { connect } from "react-redux";

import * as PaxBleTypes from "../../../pax-ble/types";

import * as consumerApiActions from "../../api/consumer/actions/devices";
import * as deviceActions from "../../device/actions";
import * as deviceSelectors from "../../device/selectors";
import * as t from "../../main/types";
import { showFlashBanner } from "../../shared/components/FlashBanner/actions";

import * as eraProDeviceSettingsModal from "../components/EraProDeviceSettingsModal";

const mapStateToProps = (
  state: t.MainAppState
): eraProDeviceSettingsModal.StateProps => ({
  brightness: deviceSelectors.getBrightness(state),
  deviceName: deviceSelectors.getDeviceName(state),
  firmwareRevision: deviceSelectors.getFirmwareRevision(state),
  haptics: deviceSelectors.getHapticsForDevice(state),
  isDeviceLocked: deviceSelectors.getIsDeviceLocked(state),
  serialNumber: deviceSelectors.getDeviceSerialNumber(
    state,
    PaxBleTypes.DeviceType.ERA_PRO
  ),
});

const mapDispatchToProps: eraProDeviceSettingsModal.DispatchProps = {
  disconnectDevice: deviceActions.disconnectDevice,
  setBrightness: deviceActions.setBrightness,
  setDeviceName: deviceActions.setDeviceName,
  setHaptics: deviceActions.setHaptics,
  showFlashBanner,
  updateDevice: consumerApiActions.updateDevice,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(eraProDeviceSettingsModal.EraProDeviceSettingsModal);
