import { MainAppState } from "../main/types";
import * as c from "./constants";

export type GetHasBeenViewed = (
  state: MainAppState,
  announcementCode: c.FEATURE_ANNOUNCEMENT_CODE
) => boolean | undefined;

export const getHasBeenViewed: GetHasBeenViewed = (state, announcementCode) =>
  state.announcements[announcementCode].viewed;
