import * as t from "../types";
import Request, { RequestType } from "./Request";

export default class WriteRequest extends Request {
  characteristicUUID: BluetoothCharacteristicUUID;
  length: number;
  offset: number;
  serviceUUID: BluetoothServiceUUID;
  payload: Uint8Array;

  constructor(
    resolve: t.PromiseResolve,
    reject: t.PromiseReject,
    characteristicUUID: BluetoothCharacteristicUUID,
    serviceUUID: BluetoothServiceUUID,
    payload: Uint8Array,
    offset = 0
  ) {
    super(RequestType.WRITE, resolve, reject);
    this.characteristicUUID = characteristicUUID;
    this.length = payload ? payload.length : 0;
    this.offset = offset;
    this.payload = payload;
    this.serviceUUID = serviceUUID;
  }
}
