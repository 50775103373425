export type McuManagerResponseType = {
  rc?: number;
};

export default class McuManagerResponse {
  static isSuccess(response: McuManagerResponseType): boolean {
    return (
      response.rc === null || response.rc === undefined || response.rc === 0
    );
  }
}
