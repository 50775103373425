import { isEqual } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled, { css } from "styled-components";

import { fetchPartners } from "../../../api/consumer/actions/partners";
import { getPartners } from "../../../api/consumer/selectors/partners";
import * as Button from "../../../shared/components/Button";
import { Column, Row } from "../../../shared/components/Flex";
import * as t from "../../../shared/text";
import { desktopBreakpoint } from "../../../shared/utils";

import * as sf from "../../models/StrainsFilter";
import { getStrainsFilter } from "../../selectors";
import { OnStrainsFilterSet, StrainsFilter } from "../../types";

import { PodsAverageRatingFilter } from "./PodsAverageRatingFilter";
import { PodsEffectsFilter } from "./PodsEffectsFilter";
import { PodsFlavorFilter } from "./PodsFlavorFilter";
import { PodsPartnerFilter } from "./PodsPartnerFilter";
import { PodsPotencyFilter } from "./PodsPotencyFilter";
import { PodsSortBy } from "./PodsSortBy";
import { PodsStrainClassificationFilter } from "./PodsStrainClassificationFilter";

export type PodsFiltersProps = {
  disableWhenEmpty?: boolean;
  onRequestClose?: () => void;
  onStrainsFilterSet?: OnStrainsFilterSet;
  showReset?: boolean;
};

export const PodsFilters: React.FC<PodsFiltersProps> = ({
  disableWhenEmpty = false,
  onRequestClose,
  onStrainsFilterSet,
  showReset = false,
  ...props
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchPartners());
  }, [dispatch]);

  const allPartners = useSelector(getPartners);
  const strainsFilter = useSelector(getStrainsFilter);

  const [filterUpdate, setFilterUpdate] =
    useState<StrainsFilter>(strainsFilter);
  const [hasUpdatedFilters, setHasUpdatedFilters] = useState<boolean>(false);

  useEffect(() => {
    if (disableWhenEmpty) return;

    if (isEqual(strainsFilter, filterUpdate)) {
      setHasUpdatedFilters(false);
      return;
    }

    setHasUpdatedFilters(true);
  }, [disableWhenEmpty, filterUpdate, strainsFilter]);

  const handleApplyClick = (): void => {
    if (!onStrainsFilterSet) return;

    onStrainsFilterSet(filterUpdate);
    if (onRequestClose) onRequestClose();
  };

  const handleCancelClick = (): void => {
    if (!onStrainsFilterSet) return;

    if (showReset) {
      const emptyFilter = sf.buildStrainsFilter({});
      setFilterUpdate(emptyFilter);
      onStrainsFilterSet(emptyFilter);
    }
    if (onRequestClose) onRequestClose();
  };

  return (
    <sc.Container data-testid="pods-filter" {...props}>
      <PodsSortBy
        filterUpdate={filterUpdate}
        setFilterUpdate={setFilterUpdate}
      />
      <PodsAverageRatingFilter
        filterUpdate={filterUpdate}
        setFilterUpdate={setFilterUpdate}
      />
      <PodsPartnerFilter
        allPartners={allPartners}
        filterUpdate={filterUpdate}
        setFilterUpdate={setFilterUpdate}
      />
      <PodsStrainClassificationFilter
        filterUpdate={filterUpdate}
        setFilterUpdate={setFilterUpdate}
      />
      <PodsEffectsFilter
        filterUpdate={filterUpdate}
        setFilterUpdate={setFilterUpdate}
      />
      <PodsPotencyFilter
        filterUpdate={filterUpdate}
        setFilterUpdate={setFilterUpdate}
      />
      <PodsFlavorFilter
        filterUpdate={filterUpdate}
        setFilterUpdate={setFilterUpdate}
      />
      <sc.ButtonDesktopContainer>
        <sc.ButtonOuterContainer>
          <sc.ButtonContainer>
            <sc.CancelButton
              onClick={handleCancelClick}
              data-testid="reset-button"
            >
              {showReset ? t.RESET : t.CANCEL}
            </sc.CancelButton>
            <sc.ApplyButton
              data-testid="apply-button"
              disabled={
                (disableWhenEmpty && sf.isStrainsFilterEmpty(filterUpdate)) ||
                (!disableWhenEmpty && !hasUpdatedFilters)
              }
              onClick={handleApplyClick}
            >
              {t.APPLY}
            </sc.ApplyButton>
          </sc.ButtonContainer>
        </sc.ButtonOuterContainer>
      </sc.ButtonDesktopContainer>
    </sc.Container>
  );
};

const buttonCss = css`
  flex: 1;
  height: 40px;
`;

const sc = {
  ApplyButton: styled(Button.PrimarySquareButton)`
    ${buttonCss}
  `,

  ButtonContainer: styled(Row)`
    height: 84px;
    margin: auto;
    max-width: 500px;
    padding: 20px;
    width: 100%;
  `,

  ButtonDesktopContainer: styled.div`
    ${desktopBreakpoint(css`
      bottom: 0;
      box-shadow: -20px -20px 30px -30px var(--black-50);
      margin-top: auto;
      position: sticky;
    `)}
  `,

  ButtonOuterContainer: styled.div`
    background-color: var(--white);
    border-top: 1px solid var(--grey-beard);
    bottom: 0;
    height: 84px;
    left: 0px;
    position: fixed;
    width: 100%;
    z-index: var(--z-index-pods-filter-modal-buttons);

    ${desktopBreakpoint(css`
      border: 1px solid var(--grey-beard);
      left: -20px;
      position: relative;
      width: 314px;
    `)}
  `,

  CancelButton: styled(Button.SquareButton)`
    ${buttonCss}
    margin-right: 10px;
  `,

  Container: styled(Column)`
    margin: 0 auto;
    max-width: 500px;
    padding: 20px;
    padding-bottom: 40px;
    padding-top: 0;
    width: 100vw;
  `,
};
