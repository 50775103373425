import * as PaxBle from "../../pax-ble";

import { PodData } from "../pods/types";

export type ConnectionStatusTextMap = Record<ConnectionStatus, string>;

export type NullableDeviceType = PaxBle.Types.DeviceType | null | undefined;

export type DeviceState = {
  attributes: {
    // TODO: remove use of any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
  };
  connected: boolean;
  connecting: boolean;
  error?: string;
  firmwareRevision: string;
  hasEncryptionErrors: boolean;
  isSyncing: boolean;
  k3Serial: string;
  k4Serial: string;
  pax3Serial: string;
  pod: PodData;
  requested: boolean;
  selectedDeviceType: NullableDeviceType;
  supportedAttributes: {
    [key: number]: string;
  };
};

export enum DeviceColor {
  GLOSSY_BLACK = 0,
  GLOSSY_SILVER = 1,
  GLOSSY_GOLD = 2,
  GLOSSY_ROSE_GOLD = 3,
  GLOSSY_RED = 4,
  BLUE = 5,
  MATTE_BLACK = 6,
  MATTE_SILVER = 7,
  MATTE_ROSE_GOLD = 8,
  MATTE_TEAL = 9,
  MATTE_FUCHSIA = 10,
  MATTE_RED = 11,
  MATTE_JADE = 12,
  BURGUNDY = 13,
  SAGE = 14,
  SAND = 15,
  ONYX = 16,
  AMBER = 17,
  SHADOW_RAIN = 18,
  MIDNIGHT_RAIN = 19,
  OCEAN = 27,
  UNASSIGNED = 225,
}

export const PAX_35_COLORS = [
  DeviceColor.BURGUNDY,
  DeviceColor.SAGE,
  DeviceColor.SAND,
  DeviceColor.ONYX,
  DeviceColor.SHADOW_RAIN,
  DeviceColor.MIDNIGHT_RAIN,
  DeviceColor.OCEAN,
];

export type DeviceAssets = {
  [key in DeviceColor]?: string;
};

export type Point = {
  x: number;
  y: number;
};

export type TemperatureRange = [number, number];

export enum ConnectionStatus {
  CONNECTED = "CONNECTED",
  CONNECTING = "CONNECTING",
  NOT_CONNECTED = "NOT_CONNECTED",
}

export enum PresetType {
  EXPERT = "EXPERT",
  IDEAL_FLAVOR = "IDEAL_FLAVOR",
  IDEAL_VAPOR = "IDEAL_VAPOR",
}

export type DeviceChargeStatus = {
  isCharging: boolean;
  isChargingComplete: boolean;
};
