import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import styled, { css, SimpleInterpolation } from "styled-components";

import * as PaxBle from "../../../pax-ble/";

import { FEATURE_ANNOUNCEMENT_CODE } from "../../announcements/constants";
import { useAnnouncement } from "../../announcements/hooks";
import BatteryIndicator from "../../device/components/BatteryIndicator";
import { DevicePodDetailsCard } from "../../device/components/DevicePodDetailsCard";
import { PodDeviceControlsMobile } from "../../device/components/PodDeviceControlsMobile";
import LockedIndicator from "../../device/containers/LockedIndicator";
import PodTestResultsCard from "../../device/containers/PodTestResultsCard";
import PenThermostatCard from "../../device/containers/Thermostat/PenThermostatCard";
import { isExperienceModesEnabled } from "../../featureFlags";
import * as r from "../../main/routes";
import { ModalAction } from "../../modal/actions";
import * as hooks from "../../notifications/hooks";
import { Row } from "../../shared/components/Flex";
import * as Icons from "../../shared/components/Icons";
import Page from "../../shared/components/Page";
import { useDesktopMediaQuery } from "../../shared/hooks";
import { desktopBreakpoint } from "../../shared/utils";

import EraProDeviceSettingsModal from "../containers/EraProDeviceSettingsModal";
import EraProDoseControlCard from "../containers/EraProDoseControlCard";
import { useEraProSerialModal } from "../hooks";

export type StateProps = {
  deviceName?: string;
  isDeviceConnected?: boolean;
  isDeviceExperienceModesCompatible: boolean;
  isDeviceLocked?: boolean;
  isFirmwareModalOpen: boolean;
  shouldShowFirmwareModal: boolean;
};

export type DispatchProps = {
  openFirmwareModal: ModalAction;
};

export type EraProDevicePageProps = StateProps & DispatchProps;

export const EraProDevicePage: React.FC<EraProDevicePageProps> = ({
  deviceName,
  isDeviceConnected,
  isDeviceExperienceModesCompatible,
  isDeviceLocked,
  isFirmwareModalOpen,
  openFirmwareModal,
  shouldShowFirmwareModal,
}) => {
  const isDesktop = useDesktopMediaQuery();

  const [isDeviceSettingsModalOpen, setIsDeviceSettingsModalOpen] =
    useState<boolean>(false);

  const [serialPromptModal, isSerialPromptModalOpen] = useEraProSerialModal();
  const [emailCollectionModal] = useAnnouncement(
    FEATURE_ANNOUNCEMENT_CODE.EMAIL_COLLECTION
  );

  const { notificationsIcon, notificationsModal } =
    hooks.useNotificationsModal();

  useEffect(() => {
    if (!isDeviceConnected) {
      setIsDeviceSettingsModalOpen(false);
    }
  }, [isDeviceConnected, setIsDeviceSettingsModalOpen]);

  useEffect(() => {
    if (
      isDeviceConnected &&
      shouldShowFirmwareModal &&
      !isSerialPromptModalOpen
    ) {
      openFirmwareModal();
    }
  }, [
    isDeviceConnected,
    isSerialPromptModalOpen,
    openFirmwareModal,
    shouldShowFirmwareModal,
  ]);

  if (!isFirmwareModalOpen && !isDeviceConnected) {
    return <Redirect to={r.device()} />;
  }

  if (
    isExperienceModesEnabled &&
    isDeviceExperienceModesCompatible &&
    !isFirmwareModalOpen
  ) {
    return <Redirect to={r.experienceModes()} />;
  }

  const handleSettingsClick = (): void => {
    setIsDeviceSettingsModalOpen(true);
  };

  const handleDeviceSettingsModalRequestClose = (): void => {
    setIsDeviceSettingsModalOpen(false);
  };

  return (
    <>
      <Page data-testid="era-pro-page">
        <sc.PageHeader>
          <sc.PageHeaderLeft>
            <BatteryIndicator />
            <sc.DeviceName>
              {deviceName}
              {isDeviceLocked && isDesktop && <sc.Locked> (Locked)</sc.Locked>}
            </sc.DeviceName>
          </sc.PageHeaderLeft>
          <sc.PageHeaderRight>
            <LockedIndicator />
            <sc.SettingsIconContainer
              data-testid="settings-modal-opener"
              isActive={isDeviceSettingsModalOpen}
              onClick={handleSettingsClick}
            >
              <Icons.Settings />
            </sc.SettingsIconContainer>
            {notificationsIcon}
          </sc.PageHeaderRight>
        </sc.PageHeader>
        <DeviceControlContent
          isDesktop={isDesktop}
          isDeviceLocked={isDeviceLocked}
        />
      </Page>
      <EraProDeviceSettingsModal
        isOpen={isDeviceSettingsModalOpen}
        hideCloser={!isDesktop}
        onModalClose={handleDeviceSettingsModalRequestClose}
        onRequestClose={handleDeviceSettingsModalRequestClose}
      />
      {emailCollectionModal}
      {serialPromptModal}
      {notificationsModal}
    </>
  );
};

type DeviceControlContentProps = {
  isDesktop: boolean;
  isDeviceLocked: boolean | undefined;
};

const DeviceControlContent: React.FC<DeviceControlContentProps> = ({
  isDesktop,
  isDeviceLocked,
}) => {
  if (isDesktop) {
    return (
      <sc.CardsContainer>
        <Row>
          <PodTestResultsCard />
          <DevicePodDetailsCard isDeviceLocked={isDeviceLocked} />
          <EraProDoseControlCard />
        </Row>
        <PenThermostatCard />
      </sc.CardsContainer>
    );
  }

  return (
    <PodDeviceControlsMobile
      deviceType={PaxBle.Types.DeviceType.ERA_PRO}
      isDeviceLocked={isDeviceLocked}
    />
  );
};

type SettingsIconContainerProps = {
  isActive: boolean;
  onClick: () => void;
};

const sc = {
  CardsContainer: styled.div`
    margin: 40px auto 0;
  `,

  DeviceName: styled.div`
    flex: 1;
    font-size: 24px;
    margin-left: 8px;
    overflow: hidden;
    padding: 2px 0 4px;
    text-overflow: ellipsis;
    text-transform: uppercase;
    white-space: nowrap;

    ${desktopBreakpoint(
      css`
        font-size: 32px;
        padding-bottom: 8px;
      `
    )}
  `,

  Locked: styled.span`
    color: var(--pale-red);
  `,

  PageHeader: styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
  `,

  PageHeaderLeft: styled.div`
    align-items: center;
    display: flex;
    flex: 1 1;
    min-width: 0;
  `,

  PageHeaderRight: styled.div`
    align-items: center;
    display: flex;
  `,

  SettingsIconContainer: styled.div<SettingsIconContainerProps>`
    align-items: center;
    background-color: ${(props): SimpleInterpolation =>
      props.isActive ? "var(--black)" : "transparent"};
    border-radius: 100%;
    cursor: pointer;
    display: flex;
    height: 48px;
    justify-content: center;
    width: 48px;

    & > svg > use {
      stroke: ${(props): SimpleInterpolation =>
        props.isActive ? "var(--white)" : "var(--black)"};
    }

    &:hover {
      background-color: var(--black);

      & > svg > use {
        stroke: var(--white);
      }
    }
  `,
};
