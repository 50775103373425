import { animated, useTransition } from "@react-spring/web";
import React from "react";
import styled from "styled-components";

import * as Icons from "../../../shared/components/Icons";

import { MobileMenu } from "./MobileMenu";

type MenuSlideInProps = {
  isMenuOpen: boolean;
  onRequestClose: () => void;
};

export const MenuSlideIn: React.FC<MenuSlideInProps> = ({
  isMenuOpen,
  onRequestClose,
}) => {
  const transitions = useTransition(isMenuOpen, {
    config: { friction: 26, mass: 1, tension: 200 },
    enter: { opacity: 1, x: 1 },
    from: { opacity: 0, x: 0 },
    leave: { opacity: 0, x: 0 },
  });

  return transitions(
    (styles, isMenuOpen) =>
      isMenuOpen && (
        <sc.Container>
          <sc.Background
            onClick={onRequestClose}
            style={{
              opacity: styles.opacity,
            }}
          />
          <sc.SlideIn
            style={{
              opacity: styles.opacity,
              width: styles.x.to((x) => `${x * 75}%`),
            }}
          >
            <sc.Closer onClick={onRequestClose} />
            <MobileMenu />
          </sc.SlideIn>
        </sc.Container>
      )
  );
};

const sc = {
  Background: styled(animated.div)`
    background-color: var(--black-20);
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
  `,

  Closer: styled(Icons.ClearThin)`
    cursor: pointer;
    position: absolute;
    right: 16px;
    top: 10px;
  `,

  Container: styled.div`
    bottom: calc(var(--mobile-nav-bar-height) * -1);
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: var(--z-index-pax-menu);
  `,

  SlideIn: styled(animated.div)`
    background-color: var(--white);
    height: 100%;
    max-width: 560px;
    padding: 50px 15px 0;
    position: relative;
    width: 75%;
    z-index: var(--z-index-pax-menu);
  `,
};
