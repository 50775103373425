// Spec:
// https://github.com/PaxLabs/pax-logging/blob/develop/schemas/events/app/search/view_all_0.json

import { AppThunk } from "../../shared/types";

import { trackEventAction } from "../actions";

const EVENT_NAME = "view_all";

type ViewAllEventProperties = {
  isoLocation: string;
  isStateLevel: boolean;
};

export const trackViewAllPodsClicked =
  (isoLocation: string, isStateLevel = false): AppThunk =>
  (dispatch, getState) => {
    trackEventAction<ViewAllEventProperties>(EVENT_NAME, {
      isStateLevel,
      isoLocation,
    })(dispatch, getState, null);
  };
