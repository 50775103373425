import McuManagerImageHeader from "../McuManagerImageHeader";
import McuManagerImageTlvInfo from "./McuManagerImageTlvInfo";
import McuManagerImageTlvTrailerEntry from "./McuManagerImageTlvTrailerEntry";

// Represents a type-length-value table for firmware images using McuBoot or the legacy Apache Mynewt bootloader.
export default class McuManagerImageTlv {
  static IMG_TLV_SHA256 = 0x10;
  static IMG_TLV_SHA256_V1 = 0x01;
  static IMG_TLV_INFO_MAGIC = 0x6907;

  data: Uint8Array;
  header: McuManagerImageHeader;
  trailerEntries: McuManagerImageTlvTrailerEntry[];

  tlvInfo?: McuManagerImageTlvInfo;

  static fromBytes(
    data: Uint8Array,
    header: McuManagerImageHeader
  ): McuManagerImageTlv {
    const tlv = new McuManagerImageTlv(data, header);
    const end = tlv.data.length;

    let offset = (header.headerSize || 0) + (header.imageSize || 0);

    // If image is legacy, skip the tlv info.
    if (!header.isLegacy()) {
      tlv.tlvInfo = McuManagerImageTlvInfo.fromBytes(tlv.data, offset);
      offset += McuManagerImageTlvInfo.MIN_SIZE;
    }

    // Parse each trailer item.
    while (offset + McuManagerImageTlvTrailerEntry.MIN_SIZE < end) {
      const tlvEntry = McuManagerImageTlvTrailerEntry.fromBytes(data, offset);
      tlv.trailerEntries.push(tlvEntry);
      offset += tlvEntry.getEntryLength();
    }

    return tlv;
  }

  constructor(data: Uint8Array, header: McuManagerImageHeader) {
    this.data = data;
    this.header = header;
    this.trailerEntries = [];
  }

  getHash(): Uint8Array {
    let hash;

    const isLegacyHeader = this.header.isLegacy();
    this.trailerEntries.forEach((entry) => {
      if (
        (isLegacyHeader &&
          entry.type === McuManagerImageTlv.IMG_TLV_SHA256_V1) ||
        (!isLegacyHeader && entry.type === McuManagerImageTlv.IMG_TLV_SHA256)
      ) {
        hash = entry.value;
      }
    });

    return hash || new Uint8Array();
  }
}
