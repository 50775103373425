import { Location } from "history";
import { createSelector } from "reselect";

import { ORDER_BY_APP_DEFAULT } from "../api/consumer/constants";
import * as consumerApiSelectors from "../api/consumer/selectors";
import * as consumerApiTypes from "../api/consumer/types";
import { OrderBy, Potency } from "../api/consumer/types/strain";
import { MainAppState } from "../main/types";
import { DropdownOption, SearchParams } from "../shared/types";
import { buildStrainsFilter } from "./models/StrainsFilter";
import * as c from "./constants";
import * as t from "./types";
import { getGeoStateLabel } from "./utils";

export const getClickedPodCount = (state: MainAppState): number =>
  state.pods.clickedPodCount;

const getLocation = (
  _: MainAppState,
  props: { location: Location }
): Location => props.location;

export const getSearchParams = createSelector(
  [getLocation],
  (location): SearchParams => {
    if (!location.search) return {};

    const search: string[] = location.search.slice(1).split("&");

    const initialSearchParams: SearchParams = {};
    const searchParams = search.reduce((acc, param) => {
      const [key, value] = param.split("=");
      acc[key] = decodeURIComponent(value);
      return acc;
    }, initialSearchParams);

    return searchParams;
  }
);

export const getStrainsFilter = (state: MainAppState): t.StrainsFilter =>
  state.pods.filter;

const getOrderByString = (orderBy: string): OrderBy => {
  switch (orderBy) {
    case c.ALPHABETICAL_BY_BRAND:
      return OrderBy.DEFAULT;
    case c.ALPHABETICAL_BY_POD:
      return OrderBy.ALPHABETIC;
    case c.HIGHEST_RATED:
      return OrderBy.AVERAGE_RATING;
    case c.NEWEST:
      return OrderBy.NEWEST;
    case c.RANDOM:
      return OrderBy.RANDOM;
    case c.RELEVANCE:
      return OrderBy.RELEVANCE;
    default:
      return ORDER_BY_APP_DEFAULT;
  }
};

export const getStrainsFilterFromSearchParams = createSelector(
  [getSearchParams],
  (searchParams: SearchParams): t.StrainsFilter => {
    const initialFilter: Partial<t.StrainsFilter> = {};
    const partialFilter = Object.entries(searchParams).reduce(
      (acc, [key, value]) => {
        if (key === "desiredEffects") acc.desiredEffects = value.split(",");
        if (key === "flavors") acc.flavors = value.split(",");
        if (key === "minAvgRating") acc.minAvgRating = value;
        if (key === "orderBy") acc.orderBy = getOrderByString(value);
        if (key === "partnerId") acc.partnerId = value;
        if (key === "potency") acc.potency = value.split(",") as Potency[];
        if (key === "query") acc.query = value;
        if (key === "region") acc.region = value;
        if (key === "selectedEffects") acc.selectedEffects = value.split(",");
        if (key === "state") acc.state = value;
        if (key === "strainClassifications")
          acc.strainClassifications = value.split(",");

        return acc;
      },
      initialFilter
    );

    // If no order is set, set to the default.
    if (!partialFilter.orderBy) partialFilter.orderBy = ORDER_BY_APP_DEFAULT;

    return buildStrainsFilter(partialFilter);
  }
);

// Different URL params affect the total filters count differently
const listParams = [
  "desiredEffects",
  "flavors",
  "potency",
  "selectedEffects",
  "strainClassifications",
];
const stringParams = ["minAvgRating", "partnerId"];
const locationParams = ["region", "state"];

export const getActiveStrainFiltersCount = createSelector(
  [getSearchParams],
  (searchParams: SearchParams): number => {
    let locationParamCounted = false;
    const activeStrainFiltersCount = Object.entries(searchParams).reduce(
      (acc, [key, value]) => {
        if (!value) return acc;

        if (listParams.includes(key)) {
          return (acc += value.split(",").length);
        } else if (!locationParamCounted && locationParams.includes(key)) {
          locationParamCounted = true;
          return ++acc;
        } else if (stringParams.includes(key)) {
          return ++acc;
        }

        return acc;
      },
      0
    );

    return activeStrainFiltersCount;
  }
);

const getPodsState = (state: MainAppState): t.PodsState => state.pods;

export const getStrainRows = (
  state: MainAppState,
  screenSize: t.ResponsiveScreenSize
): consumerApiTypes.StrainJson[][] => {
  const { strains } = getPodsState(state);
  const columnCount = c.RESPONSIVE_POD_COLUMN_COUNT[screenSize];
  const rows: consumerApiTypes.StrainJson[][] = [];

  let i = 0;
  while (i < strains.length) {
    rows.push(strains.slice(i, i + columnCount));
    i += columnCount;
  }

  return rows;
};

export const getIsFetchingPods = (state: MainAppState): boolean =>
  state.pods.isFetching;

export const getNextFetchUrl = (state: MainAppState): string | null =>
  state.pods.nextFetchUrl;

export const getSelectedPotencyOptions = createSelector(
  [getStrainsFilter],
  (strainsFilter) => strainsFilter.potency
);

export const getSelectedRatingOption = createSelector(
  [getStrainsFilter],
  (strainsFilter) => {
    return (
      strainsFilter.minAvgRating && parseInt(strainsFilter.minAvgRating, 10)
    );
  }
);

export const getSelectedRegionOption = createSelector(
  [getStrainsFilter],
  (strainsFilter) => {
    return c.REGION_OPTIONS.find(
      (option) => option.value === strainsFilter.region
    );
  }
);

export const getStateOrProvinceFromFilter = createSelector(
  [getStrainsFilter],
  (strainsFilter) => {
    if (!strainsFilter.state) return undefined;

    return getGeoStateLabel(strainsFilter.state);
  }
);

const mapPartnersToDropdownOptions = (
  partners: consumerApiTypes.partner.PartnerJson[]
): DropdownOption[] => {
  const partnerOptions = partners
    .map((partner: consumerApiTypes.partner.PartnerJson) => ({
      label: partner.partnerName.trim(),
      value: partner.id,
    }))
    .sort((a, b) => {
      const labelA = a.label.toUpperCase();
      const labelB = b.label.toUpperCase();

      if (labelA < labelB) return -1;
      if (labelA > labelB) return 1;
      return 0;
    });

  return [{ label: "All Brands", value: "" }, ...partnerOptions];
};

const getAllPartnerOptions = createSelector(
  [consumerApiSelectors.partners.getPartners],
  (partners) => {
    return mapPartnersToDropdownOptions(partners);
  }
);

export const getSelectedPartnerOption = createSelector(
  [getStrainsFilter, getAllPartnerOptions],
  (strainsFilter, partnerOptions) => {
    if (!strainsFilter.partnerId) return undefined;

    return partnerOptions.find(
      (option) => option.value === strainsFilter.partnerId
    );
  }
);

export const getPartnerOptions = (
  partners: consumerApiTypes.partner.PartnerJson[],
  filter: Partial<t.StrainsFilter>
): DropdownOption[] => {
  const { region, state } = filter;

  const filteredPartners = partners.filter(
    (partner: consumerApiTypes.partner.PartnerJson) => {
      // Filter by region, if present.
      if (!region) return true;
      if (partner.region !== region) return false;

      // Filter by state, if present.
      if (!state) return true;
      return partner.states.includes(state);
    }
  );

  return mapPartnersToDropdownOptions(filteredPartners);
};

export const getIsUserLocationUndetermined = (state: MainAppState): boolean => {
  return !!state.pods.isUserLocationUndetermined;
};
