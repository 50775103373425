import React, { useState } from "react";

import { PhotoJson, VideoJson } from "../../../api/consumer/types";
import { Carousel } from "../../../shared/components/Carousel";
import { MediaModal } from "../../../shared/components/MediaModal";
import { renderMediaThumbnails } from "../../../shared/components/MediaThumbnails";
import { useDesktopMediaQuery } from "../../../shared/hooks";
import * as text from "../../../shared/text";

const MEDIA_CAROUSEL_PAGE_SIZE = 3;

type PartnerMediaCarouselProps = {
  photos?: PhotoJson[];
  videos?: VideoJson[];
};

export const PartnerMediaCarousel: React.FC<PartnerMediaCarouselProps> = ({
  photos = [],
  videos = [],
}) => {
  const isDesktop = useDesktopMediaQuery();

  const [initialCarouselPage, setInitialCarouselPage] = useState<number>(1);
  const [mediaModalIndex, setMediaModalIndex] = useState<number | null>(null);

  const partnerMediaCarouselSizeMobile = photos.length + videos.length;

  const partnerMediaNumPages = isDesktop ? undefined : 1;

  const partnerMediaCarouselSize = isDesktop
    ? MEDIA_CAROUSEL_PAGE_SIZE
    : partnerMediaCarouselSizeMobile;

  const handleMediaModalClose = (): void => setMediaModalIndex(null);

  const handleMediaModalIndexChange = (newIndex: number): void => {
    setInitialCarouselPage(1 + Math.floor(newIndex / partnerMediaCarouselSize));
  };

  const handleThumbnailClick = (index: number): void =>
    setMediaModalIndex(index);

  if (photos.length === 0 && videos.length === 0) return null;

  return (
    <>
      <Carousel
        initialPage={initialCarouselPage}
        numPages={partnerMediaNumPages}
        pageSize={partnerMediaCarouselSize}
        title={isDesktop ? text.PHOTOS_AND_VIDEOS : ""}
      >
        {renderMediaThumbnails({
          onMediaClick: handleThumbnailClick,
          photos: photos,
          videos: videos,
        })}
      </Carousel>
      <MediaModal
        initialIndex={mediaModalIndex}
        isOpen={mediaModalIndex !== null}
        onRequestClose={handleMediaModalClose}
        onIndexChange={handleMediaModalIndexChange}
        photos={photos}
        videos={videos}
      />
    </>
  );
};
