import { ErrorCode } from "../api/consumer/types";

export const ERROR_DUPLICATE_EMAIL =
  "This email is already associated with an account";
export const ERROR_INVALID_LOGIN = "Invalid email or password";
export const ERROR_INVALID_USERNAME = "Please enter a valid email";
export const ERROR_INVALID_PASSWORD = "Please enter a valid password";
export const ERROR_INVALID_TOKEN =
  "Email verification token expired, please try again";
export const ERROR_REUSED_PASSWORD = "Password cannot be reused";
export const ERROR_UNKNOWN_ERROR = "An unexpected error occurred";

export const ERROR_MESSAGES: { [key: string]: string } = {
  [ErrorCode.DUPLICATE_EMAIL]: ERROR_DUPLICATE_EMAIL,
  [ErrorCode.INVALID_LOGIN]: ERROR_INVALID_LOGIN,
  [ErrorCode.INVALID_USERNAME]: ERROR_INVALID_USERNAME,
  [ErrorCode.INVALID_PASSWORD]: ERROR_INVALID_PASSWORD,
  [ErrorCode.INVALID_TOKEN]: ERROR_INVALID_TOKEN,
  [ErrorCode.REUSED_PASSWORD]: ERROR_REUSED_PASSWORD,
  [ErrorCode.UNKNOWN_ERROR]: ERROR_UNKNOWN_ERROR,
};

export const PASSWORD_ERROR_MESSAGE =
  "Password must be 8 or more characters with at least one uppercase, one lowercase, a number, and a symbol";

export enum SsoCountry {
  CANADA = "CANADA",
  US = "US",
  INTERNATIONAL = "INTERNATIONAL",
}

export const SSO_CONFIRMATION_TIMEOUT = 3000;
