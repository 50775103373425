import { createSelector } from "reselect";

import { MainAppState } from "../main/types";

export const isModalOpen = (state: MainAppState): boolean => {
  return state.modal.isOpen;
};

export const getHasAcceptedPrivacyPolicy = (state: MainAppState): boolean => {
  return state.modal.hasAcceptedPrivacyPolicy;
};

export const getHasSeenFirmwareModal = (state: MainAppState): boolean => {
  return state.modal.hasSeenFirmwareModal;
};

export const getIsFirmwareModalOpen = (state: MainAppState): boolean => {
  return state.modal.isFirmwareModalOpen;
};

const getIsPrivacyPolicyModalOpenState = (state: MainAppState): boolean => {
  return state.modal.isPrivacyPolicyModalOpen;
};

export const getIsPrivacyPolicyModalOpen = createSelector(
  [getHasAcceptedPrivacyPolicy, getIsPrivacyPolicyModalOpenState],
  (hasAcceptedPrivacyPolicy, isPrivacyPolicyModalOpen) => {
    return !hasAcceptedPrivacyPolicy && isPrivacyPolicyModalOpen;
  }
);

export const getIsUsageAgreementModalOpen = (state: MainAppState): boolean => {
  return state.modal.isUsageAgreementModalOpen;
};
