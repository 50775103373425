import React, { useState } from "react";
import { useSelector } from "react-redux";
import { RouteComponentProps } from "react-router-dom";

import { getUserNeedsVerification } from "../../api/consumer/selectors/user";
import { ssoEmailVerificationRequestSent } from "../../main/routes";
import * as text from "../../shared/text";
import { SendEmailVerificationRequest } from "../../sso/actions";
import { SsoProps } from "../../sso/types";
import * as ssoUtils from "../../sso/utils";

import * as SC from "../styledComponents";

export type StateProps = {
  email: string;
};

export type DispatchProps = {
  sendEmailVerificationRequest: SendEmailVerificationRequest;
};

export type EmailNotificationProps = StateProps &
  DispatchProps &
  SsoProps &
  RouteComponentProps;

export const EmailVerificationNotification: React.FC<EmailNotificationProps> =
  ({
    email,
    goToSso = ssoUtils.goToSso,
    location,
    sendEmailVerificationRequest,
    ssoApiReturnTo = ssoUtils.ssoApiReturnTo,
  }) => {
    const [hasClickedSend, setHasClickedSend] = useState<boolean>(false);

    const needsVerification = useSelector(getUserNeedsVerification);

    if (!needsVerification || hasClickedSend) return null;

    const handleVerifyClick = (): void => {
      const currentPage = location.pathname;
      const returnTo = ssoApiReturnTo(currentPage);

      sendEmailVerificationRequest({ email, returnTo });
      goToSso(ssoEmailVerificationRequestSent({ returnTo: currentPage }));
      setHasClickedSend(true);
    };

    return (
      <SC.Notification>
        <SC.Title>{text.VERIFY_YOUR_EMAIL}</SC.Title>
        <SC.Message>{text.VERIFICATION_EMAIL_SENT}</SC.Message>
        <SC.Button onClick={handleVerifyClick}>{text.RESEND}</SC.Button>
      </SC.Notification>
    );
  };
