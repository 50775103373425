import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

if (!global.Intl) {
  require("intl");
}

if (!DataView.prototype.getBigUint64) {
  DataView.prototype.getBigUint64 = function (byteOffset, littleEndian) {
    const lowWord = this.getUint32(
      byteOffset + (littleEndian ? 0 : 4),
      littleEndian
    );
    const highWord = this.getUint32(
      byteOffset + (littleEndian ? 4 : 0),
      littleEndian
    );
    const returnInt = BigInt(lowWord) | (BigInt(highWord) << BigInt(32));
    return returnInt;
  };
}

if (!DataView.prototype.setBigUint64) {
  DataView.prototype.setBigUint64 = function (byteOffset, value, littleEndian) {
    const lowWord = value & BigInt(Math.pow(2, 32) - 1);
    const highWord = (value & BigInt(0xffffffff00000000)) >> BigInt(32);
    this.setUint32(
      byteOffset + (littleEndian ? 0 : 4),
      Number(lowWord),
      littleEndian
    );
    this.setUint32(
      byteOffset + (littleEndian ? 4 : 0),
      Number(highWord),
      littleEndian
    );
  };
}
