import * as t from "../types/VaporEngine";

export const DOSE_LOCKOUT_TIME = 5;
export const MAX_TEMP_C10 = 4200;
export const MAX_PRESSURE_DIFF = 0;
export const PRESSURE_SLOPE = 0;

export const DEFAULT_WRITE_CONFIGURATION_OPTIONS: t.WriteConfigurationOptions =
  {
    setPetalConfiguration: true,
    useActiveDosePulse: false,
    useActiveDoseSize: false,
  };

export const WRITE_REQUEST_SIZE: {
  [action in t.Action]: number;
} = {
  [t.Action.READ_CURRENT_STATE]: 2,
  [t.Action.READ_PETAL_CONFIG]: 4,
  [t.Action.RESET]: 2,
  [t.Action.SET_ACTIVE_PETAL]: 3,
  [t.Action.WRITE_CONFIG]: 48,
};

// Default Experience Mode Configs
export const LS_KEY_HAS_RECEIVED_REFINED_EXPERIENCE_MODES =
  "__paxWeb.hasReceivedRefinedExperienceModes";

export const STEALTH: t.Configuration = {
  doseLockoutTime: DOSE_LOCKOUT_TIME,
  dosePulse: 0,
  doseSize: 0,
  heatingCurve: [
    {
      milliseconds: 10000,
      tempC10: 220,
    },
    {
      milliseconds: 0,
      tempC10: 0,
    },
    {
      milliseconds: 0,
      tempC10: 0,
    },
    {
      milliseconds: 0,
      tempC10: 0,
    },
    {
      milliseconds: 0,
      tempC10: 0,
    },
    {
      milliseconds: 0,
      tempC10: 0,
    },
    {
      milliseconds: 0,
      tempC10: 0,
    },
    {
      milliseconds: 0,
      tempC10: 0,
    },
  ],
  maxPressureDiff: MAX_PRESSURE_DIFF,
  maxTempC10: MAX_TEMP_C10,
  podRev: t.PodType.D3N,
  pressureSlope: PRESSURE_SLOPE,
  uiFunction: t.UiFunction.PETAL_1,
};

export const EFFICIENCY: t.Configuration = {
  doseLockoutTime: DOSE_LOCKOUT_TIME,
  dosePulse: 1,
  doseSize: 0,
  heatingCurve: [
    {
      milliseconds: 500,
      tempC10: 3000,
    },
    {
      milliseconds: 1500,
      tempC10: 2500,
    },
    {
      milliseconds: 10000,
      tempC10: 2500,
    },
    {
      milliseconds: 0,
      tempC10: 0,
    },
    {
      milliseconds: 0,
      tempC10: 0,
    },
    {
      milliseconds: 0,
      tempC10: 0,
    },
    {
      milliseconds: 0,
      tempC10: 0,
    },
    {
      milliseconds: 0,
      tempC10: 0,
    },
  ],
  maxPressureDiff: MAX_PRESSURE_DIFF,
  maxTempC10: MAX_TEMP_C10,
  podRev: t.PodType.D3N,
  pressureSlope: PRESSURE_SLOPE,
  uiFunction: t.UiFunction.PETAL_2,
};

export const BALANCED: t.Configuration = {
  doseLockoutTime: DOSE_LOCKOUT_TIME,
  dosePulse: 1,
  doseSize: 0,
  heatingCurve: [
    {
      milliseconds: 10000,
      tempC10: 3200,
    },
    {
      milliseconds: 0,
      tempC10: 0,
    },
    {
      milliseconds: 0,
      tempC10: 0,
    },
    {
      milliseconds: 0,
      tempC10: 0,
    },
    {
      milliseconds: 0,
      tempC10: 0,
    },
    {
      milliseconds: 0,
      tempC10: 0,
    },
    {
      milliseconds: 0,
      tempC10: 0,
    },
    {
      milliseconds: 0,
      tempC10: 0,
    },
  ],
  maxPressureDiff: MAX_PRESSURE_DIFF,
  maxTempC10: MAX_TEMP_C10,
  podRev: t.PodType.D3N,
  pressureSlope: PRESSURE_SLOPE,
  uiFunction: t.UiFunction.PETAL_3,
};

export const BOOST: t.Configuration = {
  doseLockoutTime: DOSE_LOCKOUT_TIME,
  dosePulse: 1,
  doseSize: 0,
  heatingCurve: [
    {
      milliseconds: 500,
      tempC10: 3700,
    },
    {
      milliseconds: 1500,
      tempC10: 4200,
    },
    {
      milliseconds: 10000,
      tempC10: 4200,
    },
    {
      milliseconds: 0,
      tempC10: 0,
    },
    {
      milliseconds: 0,
      tempC10: 0,
    },
    {
      milliseconds: 0,
      tempC10: 0,
    },
    {
      milliseconds: 0,
      tempC10: 0,
    },
    {
      milliseconds: 0,
      tempC10: 0,
    },
  ],
  maxPressureDiff: MAX_PRESSURE_DIFF,
  maxTempC10: MAX_TEMP_C10,
  podRev: t.PodType.D3N,
  pressureSlope: PRESSURE_SLOPE,
  uiFunction: t.UiFunction.PETAL_4,
};

// Error Messages
export const ERROR_INVALID_CONFIG = "Invalid vapor enginge configuration";
export const ERROR_GETTING_LOCAL_STORAGE =
  "Error getting item from local storage";
export const ERROR_SETTING_LOCAL_STORAGE =
  "Error setting item from local storage";
export const invalidConfigErrorMessage = (config: t.Configuration): string =>
  `Invalid vapor enginge configuration: ${JSON.stringify(config)}`;
export const vaporEngineNotSupportedErrorMessage = (
  firmwareRevision: string
): string =>
  `This firmware version does not support the vapor engine: ${firmwareRevision}`;
