import Bugsnag from "@bugsnag/browser";
import compareVersions from "compare-versions";
import { createSelector } from "reselect";

import { FirmwareUpgradeState as McuFirmwareUpgradeState } from "../../mcumgr-js/src/dfu/FirmwareUpgradeManager";
import { DeviceType } from "../../pax-ble/types";

import * as deviceSelectors from "../device/selectors";
import { isPax3 } from "../device/utils";
import { isExperienceModesEnabled } from "../featureFlags";
import { MainAppState } from "../main/types";
import { getHasSeenFirmwareModal } from "../modal/selectors";
import * as text from "../shared/text";
import * as c from "./constants";
import { FirmwareInfo, FirmwareUpgradeState } from "./types";

export const getFirmwareInfo = (state: MainAppState): FirmwareInfo =>
  state.firmware.firmwareInfo;

export const getFirmwareUpgradeMessage = (
  state: MainAppState
): string | undefined => state.firmware.firmwareUpgradeState.message;

export const getFirmwareUpgradeState = (
  state: MainAppState
): FirmwareUpgradeState => state.firmware.firmwareUpgradeState;

export const getIsFirmwareUpgradeComplete = (state: MainAppState): boolean =>
  state.firmware.firmwareUpgradeState.mcuState ===
  McuFirmwareUpgradeState.SUCCESS;

export const getIsFirmwareUpgradeInProgress = (state: MainAppState): boolean =>
  state.firmware.firmwareUpgradeState.mcuState !== McuFirmwareUpgradeState.NONE;

export const getIsDeviceRestarting = (state: MainAppState): boolean =>
  state.firmware.firmwareUpgradeState.mcuState ===
  McuFirmwareUpgradeState.RESET;

export const getLatestFirmwareVersion = (state: MainAppState): string =>
  state.firmware?.firmwareInfo.version;

export const getIsNewerFirmwareAvailable = createSelector(
  [
    deviceSelectors.getFirmwareRevision,
    getLatestFirmwareVersion,
    deviceSelectors.getSelectedDeviceType,
  ],
  (firmwareRevision, latestFirmwareVersion, deviceType) => {
    if (
      !firmwareRevision ||
      !latestFirmwareVersion ||
      deviceType === DeviceType.ERA ||
      isPax3(deviceType)
    )
      return false;

    try {
      return compareVersions(firmwareRevision, latestFirmwareVersion) === -1;
    } catch (error) {
      Bugsnag.notify(
        text.errorComparingFirmwareVersions(
          firmwareRevision,
          latestFirmwareVersion
        )
      );
      return false;
    }
  }
);

export const getShouldShowFirmwareModal = createSelector(
  [getHasSeenFirmwareModal, getIsNewerFirmwareAvailable],
  (hasSeenFirmwareModal, isNewerFirmwareAvailable) => {
    return !hasSeenFirmwareModal && isNewerFirmwareAvailable;
  }
);

export const getShouldShowBetaBanner = createSelector(
  [deviceSelectors.getFirmwareRevision],
  (firmwareRevision): boolean => {
    // do not show if the user is already in the Beta App or in local development
    if (!firmwareRevision || isExperienceModesEnabled) return false;

    return (
      compareVersions(firmwareRevision, c.LATEST_PRODUCTION_FIRMWARE_VERSION) >
      0
    );
  }
);

export const getIsDeviceExperienceModesCompatible = createSelector(
  deviceSelectors.getFirmwareRevision,
  (firmwareRevision): boolean => {
    if (!firmwareRevision) return false;

    return (
      compareVersions(
        firmwareRevision,
        c.EXPERIENCE_MODE_COMPATIBLE_FIRMWARE_VERSION
      ) >= 0
    );
  }
);
