import React from "react";
import { useDispatch, useSelector } from "react-redux";
import CreatableSelect from "react-select/creatable";
import { CSSObject, CSSProperties } from "styled-components";

import { trackEventAction } from "../../analytics/actions";
import { SortedBy } from "../../analytics/search/search";
import * as s from "../../api/consumer/selectors/user";
import SearchIcon from "../../assets/images/search.svg";
import { useRecommendedSearches } from "../../pods/hooks";
import { DropdownOption } from "../../shared/types";
import { getLocationString } from "../../shared/utils";

import { useDesktopMediaQuery } from "../hooks";
import * as t from "../text";
import { DropdownProps } from "./Dropdown";

type QueryFieldDropdownProps = DropdownProps & {
  shouldShowDropdown?: boolean;
  onChange?: (option: DropdownOption | null) => void;
  onFocus?: () => void;
};

export const QueryFieldDropdown: React.FC<QueryFieldDropdownProps> = ({
  onChange,
  onFocus,
  ...props
}) => {
  const isDesktop = useDesktopMediaQuery();

  const dispatch = useDispatch();
  const { isSuggestedQueries, recommendedSearches } = useRecommendedSearches();
  const locationCode = useSelector(s.getUserLocationCode);
  const location = useSelector(s.getUserLocation);

  const options: DropdownOption[] = recommendedSearches.map((queryOption) => ({
    label: queryOption,
    value: queryOption,
  }));

  const queryFieldDropdownOverrides = props.shouldShowDropdown
    ? undefined
    : {
        Menu: () => null,
      };

  const handleQueryFieldFocus = () => {
    if (onFocus) onFocus();

    // [SEARCH_EVENT] - recent/suggested queries on load
    dispatch(
      trackEventAction("search", {
        action: "load",
        isRecentQuery: !isSuggestedQueries,
        isSuggestedQuery: isSuggestedQueries,
        isoLocation: locationCode,
        sortedBy: SortedBy.LANDING,
      })
    );
  };

  const handleQueryFieldChange = (option: DropdownOption | null) => {
    if (onChange) onChange(option);

    // [SEARCH_EVENT] - recent/suggested queries on click
    dispatch(
      trackEventAction("search", {
        action: "click",
        isRecentQuery: !isSuggestedQueries,
        isSuggestedQuery: isSuggestedQueries,
        isoLocation: locationCode,
        query: option?.value || null,
        sortedBy: SortedBy.LANDING,
      })
    );
  };

  const locationString = getLocationString({ asCity: true, location });
  const placeholder = locationString
    ? `${t.SEARCH_IN} ${locationString}`
    : t.SEARCH_PODS;

  return (
    <div data-testid={props["data-testid"]}>
      <CreatableSelect
        aria-label="query-field-dropdown"
        components={queryFieldDropdownOverrides}
        formatCreateLabel={(query: string) => query}
        noOptionsMessage={() => null}
        options={options}
        placeholder={placeholder}
        styles={QueryFieldDropdownStyles(!isDesktop)}
        onChange={handleQueryFieldChange}
        onFocus={handleQueryFieldFocus}
        {...props}
      />
    </div>
  );
};

const QueryFieldDropdownStyles = (isMobile: boolean): DropdownProps => ({
  control: (provided: CSSProperties): CSSObject => ({
    ...provided,
    "&:hover": {
      borderColor: "var(--black)",
    },
    backgroundColor: "var(--white)",
    border: isMobile ? "none" : "3px solid var(--black)",
    borderRadius: isMobile ? 0 : 6,
    boxShadow: "none",
    cursor: "pointer",
    fontFamily: "MediumLL, sans-serif",
    fontSize: "16px",
    fontWeight: 300,
    height: isMobile ? "64px" : "46px",
    justifyContent: "flex-start",
    width: "350px",
  }),
  dropdownIndicator: (provided: CSSProperties): CSSProperties => ({
    ...provided,
    display: "none",
  }),
  indicatorSeparator: (provided: CSSProperties): CSSProperties => ({
    ...provided,
    display: "none",
  }),
  input: (provided: CSSProperties): CSSObject => ({
    ...provided,
    input: {
      opacity: "1 !important",
    },
  }),
  menu: (provided: CSSProperties): CSSProperties => ({
    ...provided,
    backgroundColor: "var(--white)",
    border: "3px solid var(--black)",
    borderRadius: 6,
    boxShadow: "none",
    overflow: "hidden",
    width: "350px",
  }),
  menuList: (provided: CSSProperties): CSSProperties => ({
    ...provided,
    padding: 0,
  }),
  option: (provided: CSSProperties): CSSObject => ({
    ...provided,
    "&:hover": {
      backgroundColor: "var(--bluey-grey-80)",
      color: "var(--white)",
      cursor: "pointer",
    },
    "&:last-of-type": {
      borderBottom: "none",
    },
    background: "var(--white)",
    backgroundImage: `url(${SearchIcon})`,
    backgroundPosition: "16px",
    backgroundRepeat: "no-repeat",
    backgroundSize: "14px",
    borderBottom: "1px solid var(--very-light-pink-2)",
    color: "var(--black) !important",
    fontSize: "14px",
    fontWeight: 300,
    paddingLeft: "41px",
  }),
  placeholder: (provided: CSSProperties): CSSProperties => ({
    ...provided,
    color: "var(--bluey-grey-80)",
  }),
});
