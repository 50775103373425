import { Action } from "../shared/types";
import * as c from "./constants";
import * as t from "./types";

export const getInitialState = (): t.AnnouncementsState => ({
  [c.FEATURE_ANNOUNCEMENT_CODE.EMAIL_COLLECTION]: { viewed: undefined },
  [c.FEATURE_ANNOUNCEMENT_CODE.FIND_MY_PAX]: { viewed: undefined },
  [c.FEATURE_ANNOUNCEMENT_CODE.LOW_POD_ALERTS]: { viewed: undefined },
});

export const reducer = (
  state: t.AnnouncementsState = getInitialState(),
  action: Action
): t.AnnouncementsState => {
  const { type } = action;

  switch (type) {
    case c.SET_VIEWED_STATUS:
      const { announcementCode, hasBeenViewed } = (
        action as t.SetViewedStatusAction
      ).payload;

      return {
        ...state,
        [announcementCode]: { viewed: hasBeenViewed },
      };

    case c.RESET_ANNOUNCEMENTS_STATE:
      return getInitialState();

    default:
      return state;
  }
};
