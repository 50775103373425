import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { toggleDeviceLock } from "../../../device/actions";
import { getIsDeviceLocked } from "../../../device/selectors";
import { Locked, Unlocked } from "../../../shared/components/Icons";
import * as text from "../../../shared/text";

import { ModuleCard } from "./ModuleCard";

export const LockDeviceModule: React.FC = () => {
  const dispatch = useDispatch();
  const isLocked = useSelector(getIsDeviceLocked);

  const LockIcon = isLocked ? Locked : Unlocked;
  const title = isLocked ? text.LOCK_DEVICE : text.UNLOCK_DEVICE;

  return (
    <ModuleCard
      isActive={isLocked}
      onClick={() => dispatch(toggleDeviceLock(!isLocked))}
      title={title}
    >
      <sc.LockIconContainer isLocked={isLocked}>
        <LockIcon size={50} />
      </sc.LockIconContainer>
    </ModuleCard>
  );
};

type LockIconProps = {
  isLocked: boolean;
};

const sc = {
  LockIconContainer: styled.div<LockIconProps>`
    margin: 40px auto 0;

    & > svg > g > g > rect {
      stroke: ${({ isLocked }) => (isLocked ? "var(--white)" : "var(--black)")};
    }

    & > svg > g > g > path {
      fill: ${({ isLocked }) => (isLocked ? "var(--white)" : "var(--black)")};
    }
  `,
};
