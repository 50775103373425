import React from "react";
import styled, { css } from "styled-components";

import SsoLeftImage from "../../assets/images/sso/sso-left.png";
import SsoRightImage from "../../assets/images/sso/sso-right.png";

type SsoChromeProps = {
  children?: React.ReactNode;
};

export const SsoChrome: React.FC<SsoChromeProps> = ({ children }) => (
  <sc.OuterContainer>
    <sc.ImageContainer>
      <sc.LeftImage src={SsoLeftImage} />
      <sc.RightImage src={SsoRightImage} />
    </sc.ImageContainer>
    {children}
  </sc.OuterContainer>
);

const sharedImageStyles = css`
  object-fit: contain;

  @media (max-height: 500px) {
    display: none;
  }
`;

const sc = {
  ImageContainer: styled.div`
    bottom: 0;
    display: flex;
    justify-content: space-between;
    left: 0;
    position: absolute;
    width: 100%;
    z-index: -5;
  `,

  LeftImage: styled.img`
    ${sharedImageStyles}
    max-height: 793px;
    max-width: 391px;

    @media (max-height: 800px), (max-width: 1024px) {
      max-height: 396px;
      max-width: 195px;
    }

    @media (max-width: 840px) {
      display: none;
    }
  `,

  OuterContainer: styled.div`
    align-items: center;
    background-color: var(--gin);
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
    overflow: auto;
    overflow-y: hidden;
    position: sticky;

    @media (max-width: 840px) {
      justify-content: start;
      padding-top: 40px;
    }

    @media (max-width: 438px) {
      height: 100%;
      padding-top: 0px;
      position: relative;
    }
  `,

  RightImage: styled.img`
    ${sharedImageStyles}
    max-height: 645px;
    max-width: 391px;

    @media (max-height: 800px), (max-width: 1024px) {
      max-height: 322px;
      max-width: 195px;
    }

    @media (max-width: 840px) {
      display: none;
    }
  `,
};
