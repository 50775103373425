import React from "react";
import styled from "styled-components";

import { StrainJson } from "../../api/consumer/types";
import { getStrainColors } from "../../shared/colors/Strain";
import * as Text from "../../shared/components/Text";
import { StrainColor } from "../../shared/types";

type StrainClassificationPillProps = {
  fontSize?: string;
  height?: string;
  strain: StrainJson;
};

export const StrainClassificationPill: React.FC<StrainClassificationPillProps> =
  ({ fontSize, height, strain, ...props }) => {
    const colors = getStrainColors(strain);

    return (
      <sc.Pill colors={colors} fontSize={fontSize} height={height} {...props}>
        {strain.classification}
      </sc.Pill>
    );
  };

type PillProps = {
  colors: StrainColor;
  fontSize: string | undefined;
  height: string | undefined;
};

const sc = {
  Pill: styled.div<PillProps>`
    ${Text.MediumLLBold}
    align-items: center;
    background-color: ${({ colors }) => colors.primary};
    border: 2px solid ${({ colors }) => colors.secondary};
    border-radius: 666px;
    color: ${({ colors }) => colors.secondary};
    display: flex;
    font-size: ${({ fontSize }) => fontSize || "12px"};
    height: ${({ height }) => height || "28px"};
    justify-content: center;
    line-height: 14px;
    padding: 7px 16px;
    text-transform: uppercase;
    width: fit-content;
  `,
};
