import { Action } from "../../../shared/types";

import { StrainJson } from "../types";

export const RECEIVED_STRAIN = "RECEIVED_STRAIN";

type ReceivedStrain = (strain: StrainJson) => Action;

export const receivedStrain: ReceivedStrain = (strain) => ({
  payload: { strain },
  type: RECEIVED_STRAIN,
});
