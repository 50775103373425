import { connect } from "react-redux";

import * as PaxBle from "../../../pax-ble";

import { selectDeviceType } from "../../device/actions";
import { getDeviceError, getSelectedDeviceType } from "../../device/selectors";
import * as t from "../../main/types";

import * as deviceTypeSelector from "../components/DeviceTypeSelector";

const mapStateToProps = (
  state: t.MainAppState
): deviceTypeSelector.StateProps => {
  const deviceTypes = [
    PaxBle.Types.DeviceType.ERA_PRO,
    PaxBle.Types.DeviceType.ERA,
    PaxBle.Types.DeviceType.PAX_3,
  ];

  return {
    deviceError: getDeviceError(state),
    deviceTypes,
    selectedDeviceType: getSelectedDeviceType(state),
  };
};

const mapDispatchToProps: deviceTypeSelector.DispatchProps = {
  selectDeviceType,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(deviceTypeSelector.DeviceTypeSelector);
