import React, { Suspense } from "react";
import { Route } from "react-router-dom";

import { useUser } from "../../api/consumer/hooks";
import * as Layout from "../../shared/components/Layout";

import { SsoRoutes } from "../SsoRoutes";

export const SsoApp: React.FC = () => {
  useUser();

  return (
    <Layout.Container>
      <Suspense fallback="">
        <Route path="/sso" render={SsoRoutes} />
      </Suspense>
    </Layout.Container>
  );
};
