import { StrainJson } from "../../api/consumer/types";
import { AppThunk } from "../../shared/types";

import { trackEventAction } from "../actions";

const EVENT_NAME = "strain_favorite";

type StrainFavoriteEventProperties = {
  isFavorited: boolean;
  partnerId?: string;
  strainId?: string;
};

type TrackStrainFavorite = (
  strain: StrainJson,
  isFavorited: boolean
) => AppThunk;

export const trackStrainFavorite: TrackStrainFavorite =
  (strain, isFavorited) =>
  (dispatch, getState): void => {
    return trackEventAction<StrainFavoriteEventProperties>(EVENT_NAME, {
      isFavorited,
      partnerId: strain.partner.id,
      strainId: strain.id,
    })(dispatch, getState, null);
  };
