import { SsoCountry } from "../../../sso/constants";

import { DeviceJson } from "./device";
import { StrainJson } from "./strain";

export enum PreferredTemperatureUnit {
  FAHRENHEIT = "FAHRENHEIT",
  CELSIUS = "CELSIUS",
}

export enum ProfileImageType {
  DESKTOP_BANNER = "DESKTOP_BANNER",
  MOBILE_BANNER = "MOBILE_BANNER",
  THUMBNAIL = "THUMBNAIL",
}

export enum UserType {
  ANONYMOUS = "ANONYMOUS",
  PROGRAMMATIC = "PROGRAMMATIC",
  REGISTERED = "REGISTERED",
}

export type DataSharingPreferences = {
  shareUsageData: boolean;
};

export type Profile = {
  isSubscribedToMailingList?: boolean;
  preferredTemperatureUnit?: PreferredTemperatureUnit;
  region?: SsoCountry;
  theme?: Theme;
};

type ProfileImage = {
  downloadUrl: string;
  fileId: string;
  type: ProfileImageType;
};

export type Theme = {
  id: string;
  images?: ProfileImage[];
  textColor?: string;
};

export type User = {
  anonymousUsageHistory?: string[];
  dataSharingPreferences?: DataSharingPreferences;
  devices: DeviceJson[];
  email?: string;
  hasVerifiedEmail?: boolean;
  location?: UserLocation;
  peripheralId: string;
  profile?: Profile;
  recentlyViewedPods: StrainJson[];
  recentSearches: string[];
  roles?: string[];
  type: UserType;
  uuid: string;
};

export type UserLocation = {
  city: string;
  displayName: string;
  isOverridden: boolean;
  isoCode: string;
  postalCode: string;
  query: string | null;
  region: SsoCountry;
};

type Credentials = {
  token: string;
};

export type UserResponse = {
  credentials: Credentials;
  token: string;
  user: User;
};

export type UpdateUser = {
  dataSharingPreferences?: DataSharingPreferences;
  email?: string;
  password?: string;
  profile?: Profile;
  recentSearches?: string[];
};
