import React, { useEffect, useState } from "react";
import { Redirect } from "react-router";
import styled, { css, SimpleInterpolation } from "styled-components";

import * as PaxBle from "../../../pax-ble/";

import { FEATURE_ANNOUNCEMENT_CODE } from "../../announcements/constants";
import { useAnnouncement } from "../../announcements/hooks";
import BatteryIndicator from "../../device/components/BatteryIndicator";
import { DevicePodDetailsCard } from "../../device/components/DevicePodDetailsCard";
import { PodDeviceControlsMobile } from "../../device/components/PodDeviceControlsMobile";
import LockedIndicator from "../../device/containers/LockedIndicator";
import PodTestResultsCard from "../../device/containers/PodTestResultsCard";
import PenThermostatCard from "../../device/containers/Thermostat/PenThermostatCard";
import * as r from "../../main/routes";
import * as hooks from "../../notifications/hooks";
import { Row } from "../../shared/components/Flex";
import * as Icons from "../../shared/components/Icons";
import Page from "../../shared/components/Page";
import { useDesktopMediaQuery } from "../../shared/hooks";
import { desktopBreakpoint } from "../../shared/utils";

import EraDeviceSettingsModal from "../containers/EraDeviceSettingsModal";
import EraSessionControlCard from "../containers/EraSessionControlCard";

export type EraDevicePageProps = {
  deviceName?: string;
  isDeviceConnected: boolean;
  isDeviceLocked: boolean;
};

export const EraDevicePage: React.FC<EraDevicePageProps> = ({
  deviceName,
  isDeviceConnected,
  isDeviceLocked,
}) => {
  const isDesktop = useDesktopMediaQuery();
  const [isDeviceSettingsModalOpen, setIsDeviceSettingsModalOpen] =
    useState(false);

  const [emailCollectionModal] = useAnnouncement(
    FEATURE_ANNOUNCEMENT_CODE.EMAIL_COLLECTION
  );

  const { notificationsIcon, notificationsModal } =
    hooks.useNotificationsModal();

  useEffect(() => {
    if (!isDeviceConnected) {
      setIsDeviceSettingsModalOpen(false);
    }
  }, [isDeviceConnected, setIsDeviceSettingsModalOpen]);

  if (!isDeviceConnected) {
    return <Redirect to={r.device()} />;
  }

  const handleSettingsClick = (): void => {
    setIsDeviceSettingsModalOpen(true);
  };

  const handleDeviceSettingsModalRequestClose = (): void => {
    setIsDeviceSettingsModalOpen(false);
  };

  return (
    <>
      <Page>
        <sc.PageHeader>
          <sc.PageHeaderLeft>
            <BatteryIndicator />
            <sc.DeviceName>
              {deviceName}
              {isDeviceLocked && isDesktop && <sc.Locked> (Locked)</sc.Locked>}
            </sc.DeviceName>
          </sc.PageHeaderLeft>
          <sc.PageHeaderRight>
            <LockedIndicator />
            <sc.SettingsIconContainer
              data-testid="settings-modal-opener"
              isActive={isDeviceSettingsModalOpen}
              onClick={handleSettingsClick}
            >
              <Icons.Settings />
            </sc.SettingsIconContainer>
            {notificationsIcon}
          </sc.PageHeaderRight>
        </sc.PageHeader>
        {isDesktop ? (
          <sc.CardsContainer>
            <Row>
              <PodTestResultsCard />
              <DevicePodDetailsCard isDeviceLocked={isDeviceLocked} />
              <EraSessionControlCard />
            </Row>
            <PenThermostatCard />
          </sc.CardsContainer>
        ) : (
          <PodDeviceControlsMobile
            deviceType={PaxBle.Types.DeviceType.ERA}
            isDeviceLocked={isDeviceLocked}
          />
        )}
      </Page>
      <EraDeviceSettingsModal
        isOpen={isDeviceSettingsModalOpen}
        hideCloser={!isDesktop}
        onModalClose={handleDeviceSettingsModalRequestClose}
        onRequestClose={handleDeviceSettingsModalRequestClose}
      />
      {emailCollectionModal}
      {notificationsModal}
    </>
  );
};

type SettingsIconContainerProps = {
  isActive: boolean;
  onClick: () => void;
};

const sc = {
  CardsContainer: styled.div`
    margin: 40px auto 0;
  `,

  DeviceName: styled.div`
    flex: 1;
    font-size: 24px;
    margin-left: 8px;
    overflow: hidden;
    padding: 2px 0 4px;
    text-overflow: ellipsis;
    text-transform: uppercase;
    white-space: nowrap;

    ${desktopBreakpoint(
      css`
        font-size: 32px;
        padding-bottom: 8px;
      `
    )}
  `,

  Locked: styled.span`
    color: var(--pale-red);
  `,

  PageHeader: styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
  `,

  PageHeaderLeft: styled.div`
    align-items: center;
    display: flex;
    flex: 1 1;
    min-width: 0;
  `,

  PageHeaderRight: styled.div`
    align-items: center;
    display: flex;
  `,

  SettingsIconContainer: styled.div<SettingsIconContainerProps>`
    align-items: center;
    background-color: ${(props): SimpleInterpolation =>
      props.isActive ? "var(--black)" : "transparent"};
    border-radius: 100%;
    cursor: pointer;
    display: flex;
    height: 48px;
    justify-content: center;
    width: 48px;

    & > svg > use {
      stroke: ${(props): SimpleInterpolation =>
        props.isActive ? "var(--white)" : "var(--black)"};
    }

    &:hover {
      background-color: var(--black);

      & > svg > use {
        stroke: var(--white);
      }
    }
  `,
};
