import * as dateFns from "date-fns";
import emojiRegex from "emoji-regex";
import { isMobile } from "react-device-detect";
import * as styled from "styled-components";

import { UserLocation } from "../api/consumer/types/user";
import { SsoCountry } from "../sso/constants";
import { Breakpoint } from "./constants";
import * as text from "./text";

// ########################### Breakpoints ####################################
const breakpointCss = <P extends styled.CSSProperties, T>(
  breakpoint: Breakpoint,
  style: styled.FlattenInterpolation<styled.ThemedStyledProps<P, T>>
): styled.FlattenInterpolation<styled.ThemedStyledProps<P, T>> | undefined => {
  // Ignore desktop styling for any mobile device
  if (isMobile && breakpoint === Breakpoint.DESKTOP) return;

  return styled.css`
    @media ${breakpoint} {
      ${style}
    }
  `;
};

export const desktopBreakpoint = <P extends styled.CSSProperties, T>(
  style: styled.FlattenInterpolation<styled.ThemedStyledProps<P, T>>
): styled.FlattenInterpolation<styled.ThemedStyledProps<P, T>> | undefined =>
  breakpointCss(Breakpoint.DESKTOP, style);

export const mobileBreakpoint = <P extends styled.CSSProperties, T>(
  style: styled.FlattenInterpolation<styled.ThemedStyledProps<P, T>>
): styled.FlattenInterpolation<styled.ThemedStyledProps<P, T>> | undefined =>
  breakpointCss(Breakpoint.MOBILE, style);

export const smallScreenBreakpoint = <P extends styled.CSSProperties, T>(
  style: styled.FlattenInterpolation<styled.ThemedStyledProps<P, T>>
): styled.FlattenInterpolation<styled.ThemedStyledProps<P, T>> | undefined =>
  breakpointCss(Breakpoint.SMALL_SCREEN, style);

// ############################ Text Utils ################################
export type GetLocationStringProps = {
  location: UserLocation | undefined;
  asCity?: boolean;
};

export const getLocationString = ({
  location,
  asCity = false,
}: GetLocationStringProps): string => {
  if (asCity && location?.city) return location.city;

  if (location?.displayName) {
    return location?.displayName;
  } else if (location?.region) {
    return location.region === SsoCountry.US
      ? text.UNITED_STATES
      : capitalize(location.region);
  }

  return "";
};

export const capitalize = (text: string): string => {
  const lowerCaseText = text.toLocaleLowerCase();
  return lowerCaseText.charAt(0).toUpperCase() + lowerCaseText.slice(1);
};

export const capitalizeRemoveUnderscores = (text: string): string => {
  return text
    .toLowerCase()
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const getStrainClassificationFormattedText = (
  classification: string
): string => {
  return classification === text.CBD ? text.CBD : capitalize(classification);
};

export const getStrainClassificationInitial = (
  classification: string
): string => {
  return classification.slice(0, 1).toUpperCase();
};

export const getLastUsed = (dateString: string): string => {
  if (!dateString) return "";

  const now = new Date();
  const date = dateFns.parseISO(dateString);
  const twelveHoursAgo = dateFns.add(new Date(), { hours: -12 });
  const oneYearAgo = new Date().setDate(now.getDate() - 365);

  // Date is within the last 12 hours. Show localized time, i.e. 9:30 am
  if (dateFns.isWithinInterval(date, { end: now, start: twelveHoursAgo })) {
    return dateFns.format(date, "p").toLocaleLowerCase();
  }

  // Date is within the last year. Show hours, days, or months ago, i.e. 5 days ago
  if (dateFns.isWithinInterval(date, { end: now, start: oneYearAgo })) {
    const lastUsed = dateFns.formatDistanceToNowStrict(date);
    return [lastUsed, text.AGO].join(" ");
  }

  // Date is more than a year old. Show human readable date, i.e. April 20, 2020
  return date.toLocaleDateString("en-US", {
    day: "numeric",
    month: "short",
    year: "numeric",
  });
};

export function formatTimestampWithDots(
  isFullYear: boolean,
  timestamp: string
): string {
  const date = new Date(timestamp);

  let stringMonth = (date.getMonth() + 1).toString();
  stringMonth = stringMonth.length === 1 ? "0" + stringMonth : stringMonth;

  let stringDate = date.getDate().toString();
  stringDate = stringDate.length === 1 ? "0" + stringDate : stringDate;

  const stringYear = isFullYear
    ? date.getFullYear().toString()
    : date.getFullYear().toString().slice(2);

  return stringMonth + "." + stringDate + "." + stringYear;
}

export const removeEmojis = (emojiString: string): string => {
  return emojiString.replace(emojiRegex(), "");
};

export const removeUnderscore = (value: string): string =>
  value.replace("_", " ");

// ###################### Misc Utils ########################################
// TODO: remove use of any
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const arrayToObject = <T extends Record<string, any>>(
  array: T[],
  key: string
): { [key: string]: T } => {
  const initialValue = {};
  return array.reduce((acc, item) => {
    return {
      ...acc,
      [item[key]]: item,
    };
  }, initialValue);
};

export const getOffsetRelativeToDocument = (
  ref: React.MutableRefObject<HTMLDivElement | null>
): { left: number; top: number } | void => {
  if (!ref?.current) return;

  const rect = ref.current.getBoundingClientRect();

  return {
    left: rect.left + window.pageXOffset,
    top: rect.top + window.pageYOffset,
  };
};

export function getRowReducer<T>(numColumns: number) {
  return (acc: T[][], item: T): T[][] => {
    const lastRow = acc[acc.length - 1];

    if (acc.length > 0 && lastRow.length < numColumns) {
      lastRow.push(item);
    } else {
      acc.push([item]);
    }

    return acc;
  };
}

export const isEmptyArray = (arr: unknown[]): boolean => arr.length === 0;

// For use with simple objects that only contain primitive data (read: without
// functions or DOM references). Key ordering matters
export const isEqualJSONObject = (obj1: unknown, obj2: unknown): boolean => {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
};

export const isGeoStateAmerican = (geoState: string): boolean => {
  return !!geoState?.startsWith("US-");
};

export const isGeoStateCanadian = (geoState: string): boolean => {
  return !!geoState?.startsWith("CA-");
};

export const calculatePercentage = (
  numerator: number,
  denominator: number
): number => {
  return Math.round((numerator / denominator) * 100);
};

export const isWithinRange = (
  value: number,
  min: number,
  max: number
): boolean => {
  return min <= value && value <= max;
};

export const getScrollPosition = (): number =>
  document.documentElement.scrollTop;
