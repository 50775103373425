import React from "react";

import { ModalProps } from "../../../modal/components/Modal";
import * as t from "../../../shared/text";

import { OnStrainsFilterSet } from "../../types";

import * as SC from "./styledComponents";

import { PodsFilters } from ".";

export type PodsFilterModalProps = ModalProps & {
  disableWhenEmpty?: boolean;
  hideCloser?: boolean;
  onStrainsFilterSet: OnStrainsFilterSet;
};

export const PodsFilterModal: React.FC<PodsFilterModalProps> = ({
  hideCloser = false,
  isOpen,
  onRequestClose,
  ...props
}) => {
  const modalProps = {
    hideCloser,
    isOpen,
    onRequestClose,
  };

  return (
    <SC.PodsModal {...modalProps}>
      <SC.Header>{t.FILTER}</SC.Header>
      <PodsFilters onRequestClose={onRequestClose} {...props} />
    </SC.PodsModal>
  );
};
