import React, { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import * as s from "../../../device/selectors";
import { useDoseStatus } from "../../../era-pro/hooks";
import * as Text from "../../../shared/components/Text";
import * as t from "../../../shared/text";
import { calculatePercentage } from "../../../shared/utils";

import { ExperienceModesModal } from "../../ExperienceModesModal";
import { getSessionProgressText } from "../../utils";

import { DoseControlModalContent } from "../modals/DoseControlModalContent";
import { ModuleCard } from "./ModuleCard";

export const DoseControlModule: React.FC = () => {
  const isDeviceLocked = useSelector(s.getIsDeviceLocked);
  const session = useSelector(s.getSession);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { isSessionInProgress, shouldShowTimeout } = useDoseStatus(session);

  const { doseId, initialTotalUsage, usageTowardCompletion } = session;

  const isDoseSelected = doseId !== 0;

  const percentageCompleted = calculatePercentage(
    usageTowardCompletion,
    initialTotalUsage
  );

  const doseText = isDoseSelected ? t.nDose(doseId) : t.OFF;

  const handleCardClick = () => {
    if (isDeviceLocked) return;

    setIsModalOpen(true);
  };

  return (
    <>
      <ModuleCard
        isActive={isSessionInProgress || isDoseSelected}
        onClick={handleCardClick}
        title={t.DOSE_CONTROL}
      >
        <sc.Container>
          <sc.Circle isActive={isSessionInProgress || isDoseSelected}>
            <sc.Text>
              {isSessionInProgress
                ? getSessionProgressText(percentageCompleted, shouldShowTimeout)
                : doseText}
            </sc.Text>
          </sc.Circle>
        </sc.Container>
      </ModuleCard>
      <ExperienceModesModal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        render={(props) => <DoseControlModalContent {...props} />}
      />
    </>
  );
};

type isActiveProps = {
  isActive: boolean;
};

const sc = {
  Circle: styled.div<isActiveProps>`
    align-items: center;
    border: solid 5px;
    border-color: ${({ isActive }) =>
      isActive ? "var(--white)" : "var(--black)"};
    border-radius: 50px;
    color: ${({ isActive }) => (isActive ? "var(--white)" : "var(--black)")};
    display: flex;
    height: 100px;
    justify-content: center;
    width: 100px;
  `,

  Container: styled.div`
    align-items: center;
    display: flex;
    flex: 1;
    justify-content: center;
    padding: 16px;
  `,

  Text: styled.div`
    ${Text.PlaaxBold}
    font-size: 16px;
    text-transform: uppercase;
  `,
};
