import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { withRouter } from "react-router";
import { RouteComponentProps } from "react-router-dom";
import styled from "styled-components";

import { MainAppState } from "../../../main/types";
import { useResizeObserver } from "../../../shared/hooks";

import { getPrioritizedBanner } from "./selectors";

export const BannerContainerComponent: React.FC<RouteComponentProps> = () => {
  const highestPriorityBanner = useSelector((state: MainAppState) =>
    getPrioritizedBanner(state)
  );
  const ContainerRef = useRef<HTMLDivElement>(null);
  const containerHeight = useResizeObserver(ContainerRef);

  return (
    <sc.Container ref={ContainerRef} top={containerHeight}>
      {highestPriorityBanner}
    </sc.Container>
  );
};

type ContainerProps = {
  top: number;
};

const sc = {
  Container: styled.div<ContainerProps>`
    position: sticky;
    top: 0;
    z-index: var(--z-index-banner-drawer);

    & ~ div > .sticky-banner-sibling {
      top: ${(props) => props.top}px;
    }
  `,
};

export const BannerContainer = withRouter(BannerContainerComponent);
