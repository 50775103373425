import React, { ReactNode, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import styled, { css } from "styled-components";

import { usePodsSearchAnalytics } from "../../analytics/search/search";
import { UserLocation } from "../../api/consumer/types/user";
import * as sharedHooks from "../../shared/hooks";
import * as text from "../../shared/text";
import * as utils from "../../shared/utils";

import * as a from "../actions";
import * as hooks from "../hooks";
import { Page, PodsHomeContentContainer } from "../styledComponents";
import * as t from "../types";
import { PodsFilterSidebar } from "./PodsFilter/PodsFilterSidebar";
import { PodsLoadingGrid } from "./PodsLoadingGrid";
import { PodsRecentSearches } from "./PodsRecentSearches";
import { PodsSearchGrid } from "./PodsSearchGrid";
import { PodsSearchPageHeader } from "./PodsSearchPageHeader";
import { PodsUnavailableInLocation } from "./PodsUnavailableInLocation";

export type StateProps = {
  isFetchingPods: boolean;
  nextFetchUrl: string | null;
  strainsFilter: t.StrainsFilter;
  userLocation?: UserLocation;
};

export type DispatchProps = {
  fetchStrains: a.FetchStrains;
  fetchStrainsFromUrl: a.FetchStrainsFromUrl;
  setStrainsFilterAndRedirect: a.SetStrainsFilterAndRedirect;
};

export type PodsSearchPageProps = StateProps &
  DispatchProps & {
    useDesktopMediaQuery?: sharedHooks.UseDesktopMediaQuery;
    useResponsiveStrainRows: hooks.UseResponsiveStrainRows;
  };

export const PodsSearchPage: React.FC<PodsSearchPageProps> = ({
  fetchStrains,
  fetchStrainsFromUrl,
  isFetchingPods,
  nextFetchUrl,
  setStrainsFilterAndRedirect,
  strainsFilter,
  useDesktopMediaQuery = sharedHooks.useDesktopMediaQuery,
  useResponsiveStrainRows = hooks.useResponsiveStrainRows,
  userLocation,
}) => {
  const history = useHistory();
  const routerLocation = useLocation<t.PodSearchPageRouterLocationProps>();
  const isFirstRender = sharedHooks.useIsFirstRender();
  const { gridWidth, strainRows } = useResponsiveStrainRows();
  const isDesktop = useDesktopMediaQuery();

  const [hasFetchedPods, setHasFetchedPods] = useState<boolean>(false);
  const [shouldShowRecents, setShouldShowRecents] = useState<boolean>(
    !isDesktop && !!routerLocation.state?.showRecentSearches
  );

  // [SEARCH_EVENT] - on query/filter/sort change
  usePodsSearchAnalytics(isFetchingPods, strainRows, strainsFilter);

  // Set the state.pods.filter based on the search params
  useEffect(() => {
    setStrainsFilterAndRedirect(history, strainsFilter);
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, []);

  // Fetch the first page when the filter changes.
  useEffect(() => {
    if (!userLocation || isFirstRender) return;

    const fetchSearchResults = async () => {
      await fetchStrains({
        filter: strainsFilter,
        page: 1,
      });

      setHasFetchedPods(true);
    };

    fetchSearchResults();
  }, [fetchStrains, strainsFilter, userLocation, isFirstRender]);

  const fetchNextStrainsPage = async (): Promise<void> => {
    if (isFetchingPods || isFirstRender) return;

    if (nextFetchUrl) {
      fetchStrainsFromUrl(nextFetchUrl);
    }
  };

  const handlePodSearchQuerySet: t.OnPodSearchQuerySet = (query) => {
    setStrainsFilterAndRedirect(history, { ...strainsFilter, query });
    setShouldShowRecents(false);
  };

  const handleStrainsFilterSet: t.OnStrainsFilterSet = (filter) => {
    // Scroll to top when filters are set
    window.scrollTo(0, 0);
    setStrainsFilterAndRedirect(history, filter);
    setShouldShowRecents(false);
  };

  let content: ReactNode;

  if (shouldShowRecents) {
    content = (
      <sc.ContentContainer>
        <PodsRecentSearches onPodSearchQuerySet={handlePodSearchQuerySet} />
      </sc.ContentContainer>
    );
  } else if (!hasFetchedPods || isFetchingPods) {
    content = (
      <sc.CenteredContentContainer>
        <PodsLoadingGrid />
      </sc.CenteredContentContainer>
    );
  } else if (hasFetchedPods && strainRows.length === 0) {
    content = (
      <PodsUnavailableInLocation
        textHelper={text.noPodsInYourAreaWithSearchCriteria}
      />
    );
  } else {
    content = (
      <sc.CenteredContentContainer>
        <PodsSearchGrid
          fetchNextPage={fetchNextStrainsPage}
          gridWidth={gridWidth}
          hasMoreItems={!!nextFetchUrl}
          strainRows={strainRows}
        />
      </sc.CenteredContentContainer>
    );
  }

  return (
    <sc.Page>
      <PodsSearchPageHeader
        isClearable
        onPodSearchQuerySet={handlePodSearchQuerySet}
        onStrainsFilterSet={handleStrainsFilterSet}
        shouldShowActiveFilters={!shouldShowRecents && !isDesktop}
        shouldShowSearchDropdown={!shouldShowRecents}
        strainsFilter={strainsFilter}
      />
      <PodsHomeContentContainer>
        {isDesktop && (
          <PodsFilterSidebar onStrainsFilterSet={handleStrainsFilterSet} />
        )}
        {content}
      </PodsHomeContentContainer>
    </sc.Page>
  );
};

const sc = {
  CenteredContentContainer: styled.div`
    display: flex;
    justify-content: center;

    ${utils.desktopBreakpoint(
      css`
        justify-content: flex-start;
        padding-left: 10px;
      `
    )}
  `,

  ContentContainer: styled.div`
    padding-left: 18px;
  `,

  Page: styled(Page)`
    ${utils.mobileBreakpoint(css`
      padding: 0 0 54px;
      position: absolute;
      top: 62px;
      width: 100%;
    `)}

    ${utils.desktopBreakpoint(
      css`
        padding: 0 16px;
        position: relative;
      `
    )}
  `,
};
