import { StrainJson } from "../api/consumer/types/strain";

export type OnHeartClick = ((strain: StrainJson) => Promise<void>) | null;

export type StrainWithFavoriteStatus = {
  strain: StrainJson;
  isFavorited: boolean;
};

export type StrainWithFavoriteStatusMap = {
  [strainId: string]: StrainWithFavoriteStatus;
};

export enum MyPodsType {
  FAVORITES = "favorites",
  HISTORY = "history",
}
