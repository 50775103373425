import React from "react";
import { isIOS } from "react-device-detect";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

import { isConnectBrowser } from "../../../../navigator";

import { trackPartnerClick } from "../../../analytics/navigation/podDetails";
import { StrainJson } from "../../../api/consumer/types";
import * as r from "../../../main/routes";
import { FavoriteHeartButton } from "../../../shared/components/FavoriteHeart/FavoriteHeartButton";
import { Row } from "../../../shared/components/Flex";
import GoBack from "../../../shared/components/GoBack";
import * as Text from "../../../shared/components/Text";
import { useDesktopMediaQuery } from "../../../shared/hooks";
import * as utils from "../../../shared/utils";

import { PodAverageRating } from "../PodAverageRating";
import { PodRegion } from "../PodRegion";
import { StrainClassificationPill } from "../StrainClassificationPill";
import { BreadCrumb } from "./BreadCrumb";

type PodDetailsHeaderProps = {
  goBackTo: string;
  isLoading: boolean;
  podSwitcherOpener: React.ReactElement | false;
  strain: StrainJson;
};

export const PodDetailsHeader: React.FC<PodDetailsHeaderProps> = ({
  goBackTo,
  isLoading,
  podSwitcherOpener,
  strain,
}) => {
  const dispatch = useDispatch();
  const isDesktop = useDesktopMediaQuery();

  const resetPageScroll = (): void => {
    // fixes a rendering issue with webkit, where the page turns blank
    // only apply this workaround on webkit browsers
    if (!(isConnectBrowser || isIOS)) return;
    // opens the browser address bar
    window.scrollTo(0, -1);
  };

  return (
    <>
      <sc.NavRow>
        {isDesktop ? (
          <BreadCrumb strain={strain} />
        ) : (
          <GoBack
            onClick={resetPageScroll}
            to={goBackTo}
            data-testid="go-back-button"
          />
        )}
        <Row>
          {isDesktop && podSwitcherOpener}
          <FavoriteHeartButton
            data-testid="favorite-pod-button"
            strain={strain}
          />
        </Row>
      </sc.NavRow>
      <sc.Header>
        <sc.StrainName data-testid="strain-name">{strain.name}</sc.StrainName>
        {!isLoading && (
          <>
            <Row alignItems="center">
              <sc.PartnerName
                data-testid="partner-name"
                onClick={() =>
                  dispatch(trackPartnerClick(strain.partner.id, strain.id))
                }
                to={r.partnerDetails(strain.partner.id)}
              >
                {strain.partner.partnerName}
              </sc.PartnerName>
              <PodRegion strain={strain} />
              {isDesktop && (
                <PodAverageRating
                  ratingsAverage={strain.averageRating}
                  ratingsCount={strain.numRatings}
                  shouldShowSuffix
                />
              )}
            </Row>
            <sc.RatingsRow>
              <StrainClassificationPill
                fontSize={"18px"}
                height={"40px"}
                strain={strain}
              />
              {!isDesktop && (
                <PodAverageRating
                  ratingsAverage={strain.averageRating}
                  ratingsCount={strain.numRatings}
                  shouldShowSuffix
                />
              )}
            </sc.RatingsRow>
          </>
        )}
      </sc.Header>
    </>
  );
};

const sc = {
  Header: styled.div`
    margin-top: 7px;

    ${utils.desktopBreakpoint(
      css`
        border-top: 1px solid var(--very-light-pink-6);
        margin-top: 24px;
        padding: 32px 0;
      `
    )}
  `,

  NavRow: styled(Row)`
    height: 46px;
    justify-content: space-between;
  `,

  PartnerName: styled(Link)`
    ${Text.MediumLL}
    color: var(--black);
    font-size: 19px;

    ${utils.desktopBreakpoint(
      css`
        font-size: 14px;
      `
    )}
  `,

  RatingsRow: styled(Row)`
    justify-content: space-between;
    margin-top: 16px;
  `,

  StrainName: styled.div`
    ${Text.MediumLLBold}
    font-size: 44px;
    letter-spacing: -0.04em;
    line-height: 39px;
    margin-bottom: 8px;
    text-transform: uppercase;

    ${utils.desktopBreakpoint(
      css`
        font-size: 76px;
        line-height: 64px;
        margin-bottom: 24px;
      `
    )}
  `,
};
