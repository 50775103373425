export const getSha256Digest = async (
  data: ArrayBuffer
): Promise<ArrayBuffer> => {
  if (process.env.NODE_ENV === "test") {
    // Use Node Crypto.
    // https://nodejs.org/api/crypto.html
    const crypto = require("crypto");
    return crypto.createHash("sha256").update(data);
  }

  // Use Web Crypto.
  // https://developer.mozilla.org/en-US/docs/Web/API/Web_Crypto_API
  return crypto.subtle.digest("SHA-256", data);
};
