import React from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

import * as r from "../../main/routes";
import { Modal, ModalProps } from "../../modal/components/Modal";
import { goToSso, ssoApiReturnTo } from "../../sso/utils";

import * as t from "../text";
import { desktopBreakpoint } from "../utils";
import { Button } from "./Button";
import { Row } from "./Flex";
import * as Text from "./Text";

type SignInUpsellProps = ModalProps & {
  description: string;
  Icon: React.ReactElement;
  returnUrl: string;
  title: string;
};

export const SignInUpsellModal: React.FC<SignInUpsellProps> = ({
  description,
  Icon,
  isOpen,
  onRequestClose,
  returnUrl,
  title,
  ...props
}) => {
  const handleSsoButtonClick = (route: string): void => {
    goToSso(route);
    if (onRequestClose) onRequestClose();
  };

  const modalProps = {
    isOpen,
    onRequestClose,
  };

  return (
    <sc.Modal {...modalProps} {...props}>
      <sc.IconContainer>{Icon}</sc.IconContainer>
      <sc.Title>{title}</sc.Title>
      <sc.Text>{description}</sc.Text>
      <sc.ButtonRow>
        <Link
          to="#"
          onClick={(): void =>
            handleSsoButtonClick(
              r.ssoCreateAccount({
                returnTo: ssoApiReturnTo(returnUrl),
              })
            )
          }
        >
          <Button data-testid="sign-up" transparent>
            {t.SIGN_UP}
          </Button>
        </Link>
        <Link
          to="#"
          onClick={(): void =>
            handleSsoButtonClick(
              r.ssoLogin({
                returnTo: ssoApiReturnTo(returnUrl),
              })
            )
          }
        >
          <Button data-testid="sign-in">{t.SIGN_IN}</Button>
        </Link>
      </sc.ButtonRow>
    </sc.Modal>
  );
};

const sc = {
  ButtonRow: styled(Row)`
    justify-content: center;

    & ${Button} {
      width: 100px;
    }

    & a:first-child {
      margin-right: 32px;
    }
  `,

  IconContainer: styled.div`
    border: solid 1px var(--black);
    border-radius: 64px;
    display: flex;
    height: 48px;
    margin: 0 auto 20px;
    width: 48px;

    & svg {
      height: 24px;
      margin: auto;
      width: 24px;
    }
  `,

  Modal: styled(Modal)`
    display: flex;
    justify-content: center;
    padding: 40px;

    ${desktopBreakpoint(
      css`
        padding: 40px;
        position: absolute;
        top: 88px;
        width: var(--default-modal-width);
      `
    )}
  `,

  Text: styled.div`
    ${Text.PlaaxLight}
    font-size: 18px;
    margin-bottom: 56px;
  `,

  Title: styled.div`
    font-size: 24px;
    padding-bottom: 32px;
    text-align: center;
    text-transform: uppercase;
  `,
};
