import React from "react";
import styled, { css, SimpleInterpolation } from "styled-components";

import * as consumerApi from "../../api/consumer";

export type StateProps = {
  activeThemeId?: string;
  themes: consumerApi.types.user.Theme[];
};

export type DispatchProps = {
  updateUser: (updateUser: consumerApi.types.user.UpdateUser) => void;
};

export type ProfileThemePickerProps = StateProps & DispatchProps;

export const ProfileThemePicker: React.FC<ProfileThemePickerProps> = ({
  activeThemeId,
  themes,
  updateUser,
  ...props
}) => {
  const handleThemeChange = (themeId: string): void => {
    const updatedUser: consumerApi.types.user.UpdateUser = {
      profile: {
        theme: {
          id: themeId,
        },
      },
    };

    updateUser(updatedUser);
  };

  return (
    <sc.ThemePicker data-testid="theme-picker" {...props}>
      {themes?.map((theme) => {
        const thumbnailUrl: string = consumerApi.utils.getThemeImageUrl(
          theme,
          consumerApi.types.user.ProfileImageType.THUMBNAIL
        );

        return (
          <sc.ProfileThumbnail
            key={theme.id}
            active={activeThemeId === theme.id}
            src={thumbnailUrl}
            onClick={(): void => handleThemeChange(theme.id)}
            data-testid={`theme-${theme.id}`}
            alt="Profile Theme Thumbnail"
          />
        );
      })}
    </sc.ThemePicker>
  );
};

type ProfileThumbnailProps = {
  active: boolean;
};

const activeThemeStyles = css`
  border-radius: 128px;
  box-shadow: 0 0 0 4px var(--black);
`;

const sc = {
  ProfileThumbnail: styled.img<ProfileThumbnailProps>`
    height: 100px;
    margin: 0 25px;
    width: 100px;

    ${({ active }): SimpleInterpolation => (active ? activeThemeStyles : "")}
  `,

  ThemePicker: styled.div`
    align-items: center;
    cursor: pointer;
    display: flex;
    height: 140px;
    min-height: 140px;
    position: relative;
    width: ${(props) => props.theme.pageWidth};
  `,
};
