import { History } from "history";
import React, { ReactElement, useEffect } from "react";
import { shallowEqual, useDispatch } from "react-redux";
import * as reactRouterDom from "react-router-dom";
import styled, { css } from "styled-components";

import * as recommendedPodsAnalytics from "../../analytics/pods/recommendedPod";
import * as searchAnalytics from "../../analytics/search/search";
import * as consumerTypes from "../../api/consumer/types";
import { OrderBy, StrainMetaDataJson } from "../../api/consumer/types/strain";
import { getAreStrainJsonArraysEqual } from "../../api/consumer/utils";
import * as r from "../../main/routes";
import { Row } from "../../shared/components/Flex";
import * as Text from "../../shared/components/Text";
import { VIEW_ALL } from "../../shared/text";
import { desktopBreakpoint, isEqualJSONObject } from "../../shared/utils";

import { usePodsPerCarousel } from "../hooks";
import { buildStrainsFilter } from "../models/StrainsFilter";
import * as SC from "../styledComponents";
import { StrainsFilter } from "../types";
import { PodCard, PodLoadingCard } from "./PodCard";

export type PodsCarouselProps = {
  filterPartial?: Partial<StrainsFilter>;
  isLoading?: boolean;
  isRecentlyViewed?: boolean;
  isRecommendedForYou?: boolean;
  isTopPod?: boolean;
  metaData?: StrainMetaDataJson;
  shouldShowViewAll?: boolean;
  strains: consumerTypes.StrainJson[];
  title: string;
  useHistory?: () => History;
};

const PodCarouselComponent: React.FC<PodsCarouselProps> = ({
  filterPartial = {},
  isLoading = false,
  isRecentlyViewed = false,
  isRecommendedForYou = false,
  metaData,
  isTopPod = false,
  shouldShowViewAll = false,
  strains,
  title,
  useHistory = reactRouterDom.useHistory,
  ...props
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const podsPerCarousel = usePodsPerCarousel();

  useEffect(() => {
    // [SEARCH_EVENT] - on recommended for you load
    if (!isRecommendedForYou || !metaData || strains.length === 0) return;

    strains.forEach((strain) =>
      dispatch(
        recommendedPodsAnalytics.trackRecommendedForYouPod({
          isClicked: false,
          metaData,
          recommendedStrainId: strain.id,
        })
      )
    );
  }, [dispatch, isRecommendedForYou, metaData, strains]);

  useEffect(() => {
    // [SEARCH_EVENT] - on recently viewed load
    if (!isRecentlyViewed || strains.length === 0) return;

    dispatch(
      searchAnalytics.trackPodsLoaded(
        strains.map((strain) => strain.id),
        {
          isRecentlyViewed,
        }
      )
    );
  }, [dispatch, isRecentlyViewed, strains]);

  useEffect(() => {
    // [SEARCH_EVENT] - on top pod load
    if (!isTopPod || strains.length === 0) return;

    dispatch(
      searchAnalytics.trackPodsLoaded(
        strains.map((strain) => strain.id),
        {
          isFeaturedStatePod: true,
          isoLocation: strains[0].geoState,
        }
      )
    );
  }, [dispatch, isTopPod, strains]);

  const handlePodCardClick = (
    strain: consumerTypes.StrainJson,
    index: number
  ) => {
    // [SEARCH_EVENT] - clicked on pod in carousel
    dispatch(
      searchAnalytics.trackFilteredSearchResultClicked(filterPartial, strain, {
        clickedOffset: index + 1,
        isRecentlyViewed,
        sortedBy: searchAnalytics.SortedBy.LANDING,
      })
    );

    // [SEARCH_EVENT] - clicked on top pod card
    if (isTopPod) dispatch(searchAnalytics.trackTopPodClicked(strain));

    // [SEARCH_EVENT] - clicked on recommended for you
    if (isRecommendedForYou && metaData)
      dispatch(
        recommendedPodsAnalytics.trackRecommendedForYouPod({
          isClicked: true,
          metaData,
          recommendedStrainId: strain.id,
        })
      );
  };

  const handleViewAllClick = () => {
    // [SEARCH_EVENT] - clicked view all in carousel
    dispatch(searchAnalytics.trackViewAllClicked(filterPartial));

    const filter = buildStrainsFilter({
      ...filterPartial,
      orderBy: OrderBy.RELEVANCE,
    });

    history.push(r.podsSearch(filter));
  };

  if (strains.length === 0 && !isLoading) return null;

  let podCards: ReactElement[] = [];

  if (isLoading) {
    for (let i = 0; i < podsPerCarousel; i++) {
      podCards.push(
        <SC.PodCardContainer key={`loading-${i}`}>
          <PodLoadingCard />
        </SC.PodCardContainer>
      );
    }
  } else if (strains) {
    podCards = strains.map((strain, index) => (
      <SC.PodCardContainer key={strain.id}>
        <PodCard
          Icon={strain.isFavorited ? <SC.FavoriteIcon /> : null}
          onClick={() => handlePodCardClick(strain, index)}
          strain={strain}
        />
      </SC.PodCardContainer>
    ));
  }

  return (
    <sc.Container {...props}>
      <sc.TitleRow>
        <sc.Title data-testid={title.replace(" ", "-").toLowerCase()}>
          {title}
        </sc.Title>
        {shouldShowViewAll && (
          <sc.ViewAll onClick={handleViewAllClick}>{VIEW_ALL}</sc.ViewAll>
        )}
      </sc.TitleRow>
      <sc.ContentRow data-testid="pods-carousel">{podCards}</sc.ContentRow>
    </sc.Container>
  );
};

const compareCarouselProps = (
  {
    filterPartial: prevFilter,
    strains: prevStrains,
    ...prevProps
  }: PodsCarouselProps,
  {
    filterPartial: nextFilter,
    strains: nextStrains,
    ...nextProps
  }: PodsCarouselProps
) => {
  return (
    getAreStrainJsonArraysEqual(prevStrains, nextStrains) &&
    isEqualJSONObject(prevFilter, nextFilter) &&
    shallowEqual(prevProps, nextProps)
  );
};

export const PodsCarousel = React.memo(
  PodCarouselComponent,
  compareCarouselProps
);

const sc = {
  Container: styled.div`
    width: 100%;

    ${desktopBreakpoint(
      css`
        padding: 2px 0;
      `
    )}
  `,

  ContentRow: styled(Row)`
    align-items: left;
    overflow-x: auto;
    padding: 2px 8px 12px 2px;
    user-select: none;

    ${desktopBreakpoint(
      css`
        overflow-x: auto;
        padding: 4px;
      `
    )}
  `,

  Title: styled.div`
    ${Text.MediumLLBold}
    font-size: 16px;
    text-transform: uppercase;

    ${desktopBreakpoint(
      css`
        font-size: 28px;
        padding-left: 4px;
      `
    )}
  `,

  TitleRow: styled(Row)`
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
    user-select: none;
    width: 100%;

    ${desktopBreakpoint(
      css`
        margin-bottom: 20px;
        min-height: 28px;
      `
    )}
  `,

  ViewAll: styled.div`
    ${Text.MediumLLBold}
    color: var(--black);
    cursor: pointer;
    font-size: 14px;
    text-decoration-line: underline;
  `,
};
