import React, { Dispatch, SetStateAction, useEffect, useState } from "react";

import { PartnerJson } from "../../../api/consumer/types/partner";
import * as t from "../../../shared/text";
import { DropdownOption } from "../../../shared/types";

import { getPartnerOptions } from "../../selectors";
import { StrainsFilter } from "../../types";

import * as sc from "./styledComponents";

type PodsPartnerFilterProps = {
  allPartners: PartnerJson[];
  filterUpdate: StrainsFilter;
  setFilterUpdate: Dispatch<SetStateAction<StrainsFilter>>;
};

export const PodsPartnerFilter: React.FC<PodsPartnerFilterProps> = ({
  allPartners,
  filterUpdate,
  setFilterUpdate,
}) => {
  const [partnerOptions, setPartnerOptions] = useState<DropdownOption[]>(
    getPartnerOptions(allPartners, filterUpdate)
  );
  const [selectedPartnerOption, setSelectedPartnerOption] =
    useState<DropdownOption>(partnerOptions[0]);

  useEffect(() => {
    const partnerOptions = getPartnerOptions(allPartners, filterUpdate);
    setPartnerOptions(partnerOptions);

    const partnerOption =
      partnerOptions.find((option) => {
        return option.value === filterUpdate.partnerId;
      }) || partnerOptions[0];
    setSelectedPartnerOption(partnerOption);
  }, [allPartners, filterUpdate]);

  const handlePartnerChange = (option: DropdownOption): void => {
    setSelectedPartnerOption(option);

    setFilterUpdate({ ...filterUpdate, partnerId: option.value || "" });
  };

  return (
    <sc.Filter>
      <sc.Label htmlFor="brand-dropdown">{t.BRAND}</sc.Label>
      <sc.Dropdown
        data-testid="brand-dropdown"
        inputId="brand-dropdown"
        name="brand-dropdown"
        onChange={handlePartnerChange}
        options={partnerOptions}
        placeholder={partnerOptions[0].label}
        value={selectedPartnerOption}
      />
    </sc.Filter>
  );
};
