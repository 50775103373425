import { Location } from "history";
import React from "react";
import { useSelector } from "react-redux";
import * as reactRouter from "react-router-dom";
import styled from "styled-components";

import { StrainJson } from "../../../api/consumer/types";
import * as r from "../../../main/routes";
import { MainAppState } from "../../../main/types";
import * as Text from "../../../shared/components/Text";
import * as t from "../../../shared/text";

import { getStrainsFilterFromSearchParams } from "../../selectors";

export type BreadCrumbProps = {
  strain: StrainJson;
  useLocation?: () => Location;
};

export const BreadCrumb: React.FC<BreadCrumbProps> = ({
  strain,
  useLocation = reactRouter.useLocation,
}) => {
  const location = useLocation();

  const filter = useSelector((state: MainAppState) =>
    getStrainsFilterFromSearchParams(state, { location })
  );
  filter.strainClassifications = [strain.classification];

  return (
    <sc.Container>
      <sc.BreadCrumbLink to={r.podsHome()}>{t.EXPLORE}</sc.BreadCrumbLink>
      <sc.Slash>/</sc.Slash>
      <sc.BreadCrumbLink to={r.podsSearch(filter)}>
        {`${strain.classification.toLowerCase()} ${t.STRAINS}`}
      </sc.BreadCrumbLink>
      <sc.Slash>/</sc.Slash>
      {strain.name}
    </sc.Container>
  );
};

const sc = {
  BreadCrumbLink: styled(reactRouter.Link)`
    color: var(--black);
    cursor: pointer;
    text-decoration: underline;
    text-decoration-line: underline;
    text-transform: capitalize;
  `,

  Container: styled.div`
    ${Text.MediumLL}
    align-items: center;
    display: flex;
    font-size: 14px;
    line-height: 17px;
  `,

  Slash: styled.span`
    margin: 0 4px;
  `,
};
