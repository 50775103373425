import React from "react";
import styled from "styled-components";

import { EffectsPillSelect } from "../../../shared/components/EffectsPillSelect";
import { Column } from "../../../shared/components/Flex";
import * as text from "../../../shared/text";

import * as SC from "../../styledComponents";

type EffectsInputProps = {
  onChange: (selectedEffects: string[]) => void;
  value?: string[];
};

export const EffectsInput: React.FC<EffectsInputProps> = ({
  onChange,
  value,
  ...props
}) => {
  return (
    <sc.Container data-testid="effects" {...props}>
      <SC.Title>{text.WHICH_EFFECTS_DID_YOU_FEEL}</SC.Title>
      <SC.Subtitle marginTop={8}>{text.SELECT_UP_TO_2}</SC.Subtitle>
      <sc.EffectsPillContainer>
        <EffectsPillSelect onChange={onChange} value={value} />
      </sc.EffectsPillContainer>
    </sc.Container>
  );
};

const sc = {
  Container: styled(Column)`
    align-items: center;
    margin-top: 26px;
    width: 328px;
  `,

  EffectsPillContainer: styled.div`
    display: flex;
    justify-content: center;
    margin-top: 16px;
  `,
};
