import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as ReactRouterDom from "react-router-dom";
import styled, { css } from "styled-components";

import * as analytics from "../../../analytics/navigation/podDetails";
import * as consumerApi from "../../../api/consumer";
import * as Strain from "../../../api/consumer/models/Strain";
import * as r from "../../../main/routes";
import { PodFeedbackSection } from "../../../pod-feedback/components/PodFeedbackSection";
import * as strainColors from "../../../shared/colors/Strain";
import { Row } from "../../../shared/components/Flex";
import * as Icons from "../../../shared/components/Icons";
import { PartnerLogo } from "../../../shared/components/PartnerLogo";
import * as Text from "../../../shared/components/Text";
import { useDesktopMediaQuery } from "../../../shared/hooks";
import * as hooks from "../../../shared/PodSwitcher";
import * as text from "../../../shared/text";
import * as utils from "../../../shared/utils";
import { isGeoStateAmerican } from "../../../shared/utils";

import { PAX_STRAIN_PACKAGING_MAP } from "../../constants";
import * as podsHooks from "../../hooks";
import * as SC from "../../styledComponents";
import { StrainsFilter } from "../../types";
import { getLegalDisclaimer } from "../../utils";

import { Effects } from "./Effects";
import { EffectsDetails } from "./EffectsDetails";
import { EffectsSpectrum } from "./EffectsSpectrum";
import { Flavors } from "./Flavors";
import { PodAddedToast } from "./PodAddedToast";
import { PodDetailsHeader } from "./PodDetailsHeader";
import { PodStrength } from "./PodStrength";
import { PodUsage } from "./PodUsage";
import { TestResultSection } from "./TestResultsSection";
import * as podUtils from "./utils";

const MAX_LENGTH_SINGLE_COLUMN = 1000;

export type PodDetailsPageProps = ReactRouterDom.RouteComponentProps<{
  strainId: string;
}> & {
  goBackTo: string;
  devicePodId: string | null;
  strainId: string;
  strainsFilter: StrainsFilter;
  useBatchListFetch: podsHooks.UseBatchListFetch;
  usePodDetailsPagePodSwitcher: hooks.PodDetailsPagePodSwitcher;
  useStrainById?: podsHooks.UseStrainById;
  wasQRCodeScanned: boolean;
};

export const PodDetailsPage: React.FC<PodDetailsPageProps> = ({
  devicePodId,
  goBackTo,
  strainId,
  strainsFilter,
  useBatchListFetch = podsHooks.useBatchListFetch,
  usePodDetailsPagePodSwitcher = hooks.usePodDetailsPagePodSwitcher,
  useStrainById = podsHooks.useStrainById,
  wasQRCodeScanned,
}) => {
  const history = ReactRouterDom.useHistory();
  const dispatch = useDispatch();
  const isDesktop = useDesktopMediaQuery();

  const [isOpen, setIsOpen] = useState(false);
  const [selectedEffect, setSelectedEffect] =
    useState<consumerApi.types.strain.EffectJson>();
  const [strain, setStrain] = useState<consumerApi.types.StrainJson>(
    Strain.getLoadingStrainJson()
  );
  const [showPodAddedToast, setShowPodAddedToast] = useState(false);

  const podSwitcher = usePodDetailsPagePodSwitcher(devicePodId, strain, () =>
    setShowPodAddedToast(true)
  );
  const batchList = useBatchListFetch(strain.id);
  const strainFetch = useStrainById(strainId, { shouldShowErrorBanner: true });
  podsHooks.usePodQRCodeScanned(strainId, wasQRCodeScanned);

  // Ensure pod details page is scrolled to top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!strainFetch.strain) return;

    setStrain(strainFetch.strain);
    dispatch(
      consumerApi.actions.users.receivedNewViewedPod(strainFetch.strain)
    );
  }, [dispatch, strainFetch.strain]);

  useEffect(() => {
    dispatch(analytics.trackPodDetails(strainsFilter, strainId));
  }, [dispatch, strainId, strainsFilter]);

  const latestBatchId =
    batchList.find((batch) => batch.parsedBatchResult)?.batchId || "";

  const podFlavors = podUtils.getFlavors(strain.id);

  const strainColor = strainColors.getStrainColors(strain);

  const isPaxStrain = podUtils.isPaxStrain(strain.partner.id);

  const handlePartnerLogoClick = (): void => {
    dispatch(analytics.trackPartnerClick(strain.partner.id, strainId));

    history.push(r.partnerDetails(strain.partner.id));
  };

  const handleEffectClick = (
    effectData: consumerApi.types.strain.EffectJson
  ): void => {
    setSelectedEffect(effectData);
    setIsOpen(true);
  };

  if (strainFetch.isError) return <ReactRouterDom.Redirect to={goBackTo} />;

  const descriptionRow = (
    <sc.DescriptionRow>
      <sc.DescriptionContainer>
        <sc.AboutTitle>{text.ABOUT}</sc.AboutTitle>
        <sc.Description
          data-testid="strain-description"
          isMultiColumn={strain.description.length > MAX_LENGTH_SINGLE_COLUMN}
        >
          {strain.description ? strain.description : text.LOADING_DESCRIPTION}
        </sc.Description>
      </sc.DescriptionContainer>
      <sc.PartnerLogoDesktop>
        <sc.PartnerLogo
          onClick={handlePartnerLogoClick}
          partner={strain.partner}
        />
      </sc.PartnerLogoDesktop>
    </sc.DescriptionRow>
  );

  return (
    <>
      <sc.Page>
        <PodDetailsHeader
          goBackTo={goBackTo}
          isLoading={strainFetch.isLoading}
          strain={strain}
          podSwitcherOpener={podSwitcher.opener}
        />
        {!isDesktop &&
          (!isPaxStrain ? (
            <sc.PartnerLogo
              onClick={handlePartnerLogoClick}
              partner={strain.partner}
            />
          ) : (
            <sc.PackagingImageContainer>
              <sc.PackagingImage
                alt={`${strain.name} Package`}
                data-testid="product-packaging"
                src={
                  PAX_STRAIN_PACKAGING_MAP[
                    strain.id as consumerApi.types.strain.PAX_STRAIN
                  ]
                }
              />
            </sc.PackagingImageContainer>
          ))}
        {!isDesktop && (
          <>
            <sc.SpectrumContainer>
              <EffectsSpectrum
                effectsScore={strain.spectrumScore}
                strainColor={strainColor}
              />
            </sc.SpectrumContainer>
            {podSwitcher.opener}
          </>
        )}
        {strain.id && (
          <sc.PodFeedbackContainer>
            <PodFeedbackSection strainId={strain.id} />
          </sc.PodFeedbackContainer>
        )}
        {descriptionRow}
        <sc.CollapsingRow>
          <sc.CollapsingSection>
            {isDesktop && (
              <EffectsSpectrum
                effectsScore={strain.spectrumScore}
                strainColor={strainColor}
              />
            )}
          </sc.CollapsingSection>
          <sc.CollapsingSection>
            <PodStrength strain={strain} />
          </sc.CollapsingSection>
        </sc.CollapsingRow>
        <sc.CollapsingRow>
          {podUtils.shouldShowEffects(
            strain.effectData,
            strain.numEffectReviews
          ) && (
            <sc.CollapsingSection>
              <Effects onEffectClick={handleEffectClick} strain={strain} />
            </sc.CollapsingSection>
          )}
          {podFlavors.length > 0 && (
            <sc.CollapsingSection>
              <Flavors flavors={podFlavors} />
            </sc.CollapsingSection>
          )}
          <sc.CollapsingSection>
            <TestResultSection
              latestBatchId={latestBatchId}
              strainId={strain.id}
            />
          </sc.CollapsingSection>
        </sc.CollapsingRow>
        {strain.id && <PodUsage strainId={strain.id} />}
        <sc.SafetyRow>
          <Icons.Shield />
          <sc.SafetyHeading>{text.SAFETY}</sc.SafetyHeading>
        </sc.SafetyRow>
        <sc.SafetyText>
          {text.podSafety(isGeoStateAmerican(strain.geoState))}
        </sc.SafetyText>
        {showPodAddedToast && <PodAddedToast />}
        <SC.LegalFooter>
          <SC.LegalText>
            {getLegalDisclaimer(isPaxStrain, strain.partner.partnerName)}
          </SC.LegalText>
        </SC.LegalFooter>
      </sc.Page>
      <EffectsDetails
        effectData={selectedEffect}
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        strain={strain}
      />
      {podSwitcher.modal}
    </>
  );
};

type DescriptionProps = {
  isMultiColumn?: boolean;
};

const sc = {
  AboutTitle: styled.div`
    ${SC.PodSectionTitleStyles}
    margin-bottom: 10px;
    margin-top: 20px;

    ${utils.desktopBreakpoint(
      css`
        margin-top: 0px;
      `
    )}
  `,

  CollapsingRow: styled.div`
    display: flex;
    flex-direction: column;

    ${utils.desktopBreakpoint(
      css`
        flex-flow: row wrap;
        justify-content: space-between;
      `
    )}
  `,

  CollapsingSection: styled.div`
    margin-top: 24px;
    width: 100%;

    ${utils.desktopBreakpoint(
      css`
        max-width: 400px;
      `
    )}
  `,

  Description: styled.p<DescriptionProps>`
    ${Text.MediumLLLight}
    column-count: 1;
    font-size: 14px;
    line-height: normal;
    margin-bottom: 0px;
    margin-top: 8px;

    ${utils.desktopBreakpoint(
      css<DescriptionProps>`
        margin: 0;
      `
    )}
  `,

  DescriptionContainer: styled.div`
    ${utils.desktopBreakpoint(
      css`
        padding: 25px 0 25px 0;
      `
    )}
  `,

  DescriptionRow: styled(Row)`
    align-items: center;

    ${utils.desktopBreakpoint(
      css`
        display: flex;
        justify-content: space-between;
      `
    )}
  `,

  LegalFooter: styled.div`
    margin-top: 53px;

    ${utils.desktopBreakpoint(css`
      margin: 30px 0 80px;
    `)}
  `,

  PackagingImage: styled.img`
    margin-top: 40px;
    max-height: 350px;
    max-width: 378px;
    object-fit: cover;
  `,

  PackagingImageContainer: styled.div`
    display: flex;
    justify-content: center;
    max-height: 350px;
  `,

  Page: styled(SC.DetailsPage)`
    margin-top: 5px;

    ${utils.desktopBreakpoint(
      css`
        margin-top: 24px;
        min-width: 575px;
      `
    )}
  `,

  PartnerLogo: styled(PartnerLogo)`
    align-self: center;
    background-color: var(--white);
    border-radius: 100px;
    height: 124px;
    margin-top: 28px;
    width: 124px;

    ${utils.desktopBreakpoint(
      css`
        align-self: right;
        height: 96px;
        margin: 25px 0 25px 32px;
        width: 96px;
      `
    )}
  `,

  PartnerLogoDesktop: styled.div`
    display: inline;

    ${utils.mobileBreakpoint(
      css`
        display: none;
      `
    )}
  `,

  PodFeedbackContainer: styled.div`
    margin-top: 14px;
  `,

  SafetyHeading: styled.div`
    ${SC.PodSectionTitleStyles}
    margin-left: 8px;
  `,

  SafetyRow: styled(Row)`
    align-items: center;
    margin-top: 24px;
  `,

  SafetyText: styled.div`
    ${Text.MediumLLLight}
    font-size: 14px;
    letter-spacing: -0.11px;
    line-height: normal;
    margin-top: 4px;
  `,

  SpectrumContainer: styled.div`
    padding-top: 20px;
  `,
};
