import * as PaxBleTypes from "../../pax-ble/types";

export const DOSE_PROGRESS_THRESHOLD = 2;

export const VAPOR_EFFECT_COLOR_MAP: {
  [key in PaxBleTypes.VaporEngine.UiFunction]: {
    backgroundColor: string;
    vaporColor: string;
  };
} = {
  [PaxBleTypes.VaporEngine.UiFunction.PETAL_1]: {
    backgroundColor: "#9966ff",
    vaporColor: "#80dfff",
  },
  [PaxBleTypes.VaporEngine.UiFunction.PETAL_2]: {
    backgroundColor: "#ffff00",
    vaporColor: "#00cc00",
  },
  [PaxBleTypes.VaporEngine.UiFunction.PETAL_3]: {
    backgroundColor: "#ffff4d",
    vaporColor: "#ffffcc",
  },
  [PaxBleTypes.VaporEngine.UiFunction.PETAL_4]: {
    backgroundColor: "#ff8000",
    vaporColor: "#ffff60",
  },
  [PaxBleTypes.VaporEngine.UiFunction.CUSTOM]: {
    backgroundColor: "#ffff60",
    vaporColor: "#eb3ff7",
  },
};
