import { History } from "history";
import React from "react";
import { useDispatch } from "react-redux";
import styled, { css } from "styled-components";

import * as consumerApi from "../../../api/consumer";
import * as actions from "../../../device/actions";
import { Modal, ModalProps } from "../../../modal/components/Modal";

import Button, { SecondaryButton } from "../../components/Button";
import { Row } from "../../components/Flex";
import * as Icons from "../../components/Icons";
import * as Text from "../../components/Text";
import * as t from "../../text";
import { desktopBreakpoint, smallScreenBreakpoint } from "../../utils";

import { PodSwitcherRequestType } from "../types";

type OwnProps = {
  clearPodId?: actions.ClearPodId;
  podSwitcherRequestType?: PodSwitcherRequestType;
  setManualIdPod?: actions.SetManualIdPod;
  strain: consumerApi.types.StrainJson;
  onConfirm?: () => void;
  useHistory?: () => History;
};

export type PodSwitcherConfirmationModalProps = OwnProps & ModalProps;

export const PodSwitcherConfirmationModal: React.FC<PodSwitcherConfirmationModalProps> =
  ({
    clearPodId = actions.clearPodId,
    closerProps,
    isOpen,
    onConfirm,
    onRequestClose,
    podSwitcherRequestType,
    setManualIdPod = actions.setManualIdPod,
    strain,
  }) => {
    const dispatch = useDispatch();

    let icon = <Icons.Add data-testid="add-icon" />;
    let descriptor = "Add";
    if (podSwitcherRequestType?.clear) {
      icon = <Icons.Clear data-testid="clear-icon" />;
      descriptor = "Remove";
    } else if (podSwitcherRequestType?.switch) {
      icon = <Icons.Switch data-testid="switch-icon" />;
      descriptor = "Set";
    }

    const handleCancelClick = (): void => {
      if (onRequestClose) onRequestClose();
    };

    const handleConfirmClick = (): void => {
      podSwitcherRequestType?.clear
        ? dispatch(clearPodId())
        : dispatch(setManualIdPod(strain.id));
      if (onConfirm && !podSwitcherRequestType?.clear) onConfirm();
      if (onRequestClose) onRequestClose();
    };

    const modalProps = {
      closerProps,
      isOpen,
      onRequestClose,
    };

    return (
      <Modal {...modalProps}>
        <sc.Container data-testid="pod-switcher-confirmation-modal">
          <sc.SwitcherIconContainer>{icon}</sc.SwitcherIconContainer>
          <sc.Description>
            {descriptor}{" "}
            {t.STRAIN_NAME_AS_CURRENT_POD(
              strain.partner.partnerName,
              strain.name
            )}
          </sc.Description>
          <sc.ButtonsRow>
            <SecondaryButton data-testid="cancel" onClick={handleCancelClick}>
              {t.CANCEL}
            </SecondaryButton>
            <Button data-testid="confirm" onClick={handleConfirmClick}>
              {t.CONFIRM}
            </Button>
          </sc.ButtonsRow>
        </sc.Container>
      </Modal>
    );
  };

const sc = {
  ButtonsRow: styled(Row)`
    justify-content: center;
    margin: 32px 110px 0;

    & > button {
      flex: 1;
      margin-right: 20px;
    }

    & > button:last-child {
      margin-right: 0;
    }
  `,

  Container: styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 262px;
    justify-content: center;
    margin: auto;
    padding: 16px;
    width: 360px;

    ${smallScreenBreakpoint(
      css`
        width: 320px;
      `
    )}

    ${desktopBreakpoint(
      css`
        padding: 40px 20px;
        width: var(--default-modal-width);
      `
    )}
  `,

  Description: styled.div`
    ${Text.PlaaxLight}
    font-size: 18px;
    height: 46px;
    line-height: 1;
    text-align: center;
  `,

  SwitcherIconContainer: styled.div`
    align-items: center;
    border: 1px solid var(--black);
    border-radius: 100%;
    display: flex;
    height: 48px;
    justify-content: center;
    margin-bottom: 20px;
    width: 48px;
  `,
};
