import { connect } from "react-redux";

import * as consumerApiSelectors from "../../api/consumer/selectors";
import * as deviceActions from "../../device/actions";
import * as t from "../../main/types";

import { RecentDevices } from "../components/RecentDevices";

const mapStateToProps = (state: t.MainAppState) => ({
  hasFetchedUser: consumerApiSelectors.user.getHasFetchedUser(state),
  recentDevices: consumerApiSelectors.devices
    .getOrderedAccountDevices(state)
    .slice(0, 3),
});

const mapDispatchToProps = {
  selectDeviceType: deviceActions.selectDeviceType,
  setSerial: deviceActions.setSerial,
};

export default connect(mapStateToProps, mapDispatchToProps)(RecentDevices);
