import { AxiosResponse } from "axios";

import { ErrorCode } from "./types";

export const getErrorCode = (response: AxiosResponse): ErrorCode => {
  if (isBadProvince(response)) {
    return ErrorCode.BAD_PROVINCE;
  } else if (isDuplicateEmail(response)) {
    return ErrorCode.DUPLICATE_EMAIL;
  } else if (isGeocodingError(response)) {
    return ErrorCode.GEOCODING_ERROR;
  } else if (isGeostateError(response)) {
    return ErrorCode.GEO_STATE_DOES_NOT_EXIST;
  } else if (isInvalidLogin(response)) {
    return ErrorCode.INVALID_LOGIN;
  } else if (isInvalidPassword(response)) {
    return ErrorCode.INVALID_PASSWORD;
  } else if (isInvalidToken(response)) {
    return ErrorCode.INVALID_TOKEN;
  } else if (isInvalidUsername(response)) {
    return ErrorCode.INVALID_USERNAME;
  } else if (isNoGeocodingResult(response)) {
    return ErrorCode.NO_GEOCODING_RESULT;
  } else if (isRequiresPasswordReset(response)) {
    return ErrorCode.REQUIRES_PASSWORD_RESET;
  } else if (isReusedPassword(response)) {
    return ErrorCode.REUSED_PASSWORD;
  } else {
    return ErrorCode.UNKNOWN_ERROR;
  }
};

const isBadProvince = (response: AxiosResponse): boolean =>
  response.status === 400 &&
  response.data?.errorCode === ErrorCode.BAD_PROVINCE;

const isDuplicateEmail = (response: AxiosResponse): boolean =>
  response.status === 409 &&
  response.data?.errorCode === ErrorCode.DUPLICATE_EMAIL;

const isGeocodingError = (response: AxiosResponse): boolean =>
  response.status === 422 &&
  response.data?.errorCode === ErrorCode.GEOCODING_ERROR;

const isGeostateError = (response: AxiosResponse): boolean =>
  response.status === 422 &&
  response.data?.errorCode === ErrorCode.GEO_STATE_DOES_NOT_EXIST;

const isInvalidLogin = (response: AxiosResponse): boolean =>
  response.status === 400 &&
  response.data?.errorCode === ErrorCode.INVALID_LOGIN;

const isInvalidPassword = (response: AxiosResponse): boolean =>
  response.status === 422 &&
  Array.isArray(response.data?.errors) &&
  response.data.errors.some((e: string) => e.indexOf("password") !== -1);

const isInvalidToken = (response: AxiosResponse): boolean =>
  response.status === 422 &&
  response.data?.errorCode === ErrorCode.INVALID_TOKEN;

const isInvalidUsername = (response: AxiosResponse): boolean =>
  response.status === 422 &&
  Array.isArray(response.data?.errors) &&
  response.data.errors.some((e: string) => e.indexOf("email") !== -1);

const isNoGeocodingResult = (response: AxiosResponse): boolean =>
  response.status === 400 &&
  response.data?.errorCode === ErrorCode.NO_GEOCODING_RESULT;

const isReusedPassword = (response: AxiosResponse): boolean =>
  response.status === 409 &&
  response.data?.errorCode === ErrorCode.REUSED_PASSWORD;

const isRequiresPasswordReset = (response: AxiosResponse): boolean =>
  response.status === 409 &&
  response.data?.errorCode === ErrorCode.REQUIRES_PASSWORD_RESET;
