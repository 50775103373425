/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// Adapted into a module from:
// https://github.com/anonyco/FastestSmallestTextEncoderDecoder

// In this NodeJS version, Buffers are supported and used as fallback in versions that do not support Typed Arrays
const log = Math.log;
const LN2 = Math.LN2;
const clz32 =
  Math.clz32 ||
  function (x) {
    return (31 - log(x >>> 0) / LN2) | 0;
  };
const fromCharCode = String["fromCharCode"];
const Object_prototype_toString = {}["toString"];

const NativeSharedArrayBuffer = global["SharedArrayBuffer"];
const sharedArrayBufferString = NativeSharedArrayBuffer
  ? Object_prototype_toString.call(NativeSharedArrayBuffer)
  : "";
const NativeUint8Array = global["Uint8Array"];
const arrayBufferPrototypeString = NativeUint8Array
  ? Object_prototype_toString.call(ArrayBuffer.prototype)
  : "";
let NativeBuffer = global["Buffer"];
try {
  if (!NativeBuffer && global["require"])
    NativeBuffer = global["require"]("Buffer");
  var NativeBufferPrototype = NativeBuffer.prototype;
  var globalBufferPrototypeString = NativeBuffer
    ? Object_prototype_toString.call(NativeBufferPrototype)
    : "";
} catch (e) {
  // Ignore.
}
const usingTypedArrays = !!NativeUint8Array && !NativeBuffer;

// NativeBufferHasArrayBuffer is true if there is no global.Buffer or if native global.Buffer instances have a Buffer property for the internal ArrayBuffer
const NativeBufferHasArrayBuffer =
  !NativeBuffer ||
  (!!NativeUint8Array &&
    // eslint-disable-next-line
    NativeUint8Array.prototype.isPrototypeOf(NativeBufferPrototype));

function decoderReplacer(encoded) {
  let codePoint = encoded.charCodeAt(0) << 24;
  const leadingOnes = clz32(~codePoint) | 0;
  let endPos = 0,
    stringLen = encoded.length | 0;
  let result = "";
  if (leadingOnes < 5 && stringLen >= leadingOnes) {
    codePoint = (codePoint << leadingOnes) >>> (24 + leadingOnes);
    for (endPos = 1; endPos < leadingOnes; endPos = (endPos + 1) | 0)
      codePoint =
        (codePoint << 6) | (encoded.charCodeAt(endPos) & 0x3f) /*0b00111111*/;
    if (codePoint <= 0xffff) {
      // BMP code point
      result += fromCharCode(codePoint);
    } else if (codePoint <= 0x10ffff) {
      // https://mathiasbynens.be/notes/javascript-encoding#surrogate-formulae
      codePoint = (codePoint - 0x10000) | 0;
      result += fromCharCode(
        ((codePoint >> 10) + 0xd800) | 0, // highSurrogate
        ((codePoint & 0x3ff) + 0xdc00) | 0 // lowSurrogate
      );
    } else endPos = 0; // to fill it in with INVALIDs
  }
  for (; endPos < stringLen; endPos = (endPos + 1) | 0) result += "\ufffd"; // replacement character
  return result;
}
/** @constructor */
export function TextDecoder() {}
function decode(inputArrayOrBuffer) {
  const buffer =
    (inputArrayOrBuffer && inputArrayOrBuffer.buffer) || inputArrayOrBuffer;
  const asString = Object_prototype_toString.call(buffer);
  if (
    asString !== arrayBufferPrototypeString &&
    asString !== globalBufferPrototypeString &&
    asString !== sharedArrayBufferString &&
    asString !== "[object ArrayBuffer]"
  )
    throw Error(
      "Failed to execute 'decode' on 'TextDecoder': The provided value is not of type '(ArrayBuffer or ArrayBufferView)'"
    );
  const inputAs8 = NativeBufferHasArrayBuffer
    ? new NativeUint8Array(buffer)
    : buffer;
  let resultingString = "";
  let index = 0,
    len = inputAs8.length | 0;
  for (; index < len; index = (index + 32768) | 0)
    resultingString += fromCharCode.apply(
      0,
      inputAs8[NativeBufferHasArrayBuffer ? "subarray" : "slice"](
        index,
        (index + 32768) | 0
      )
    );

  return resultingString.replace(/[\xc0-\xff][\x80-\xbf]*/g, decoderReplacer);
}
TextDecoder.prototype["decode"] = decode;
//////////////////////////////////////////////////////////////////////////////////////
function encoderReplacer(nonAsciiChars) {
  // make the UTF string into a binary UTF-8 encoded string
  let point = nonAsciiChars.charCodeAt(0) | 0;
  if (point >= 0xd800 && point <= 0xdbff) {
    const nextcode = nonAsciiChars.charCodeAt(1) | 0;
    //if (nextcode !== nextcode)
    //  // NaN because string is 1 code point long
    //  return fromCharCode(
    //    0xef /*11101111*/,
    //    0xbf /*10111111*/,
    //    0xbd /*10111101*/
    //  );
    // https://mathiasbynens.be/notes/javascript-encoding#surrogate-formulae
    if (nextcode >= 0xdc00 && nextcode <= 0xdfff) {
      point = (((point - 0xd800) << 10) + nextcode - 0xdc00 + 0x10000) | 0;
      if (point > 0xffff)
        return fromCharCode(
          (0x1e /*0b11110*/ << 3) | (point >>> 18),
          (0x2 /*0b10*/ << 6) | ((point >>> 12) & 0x3f) /*0b00111111*/,
          (0x2 /*0b10*/ << 6) | ((point >>> 6) & 0x3f) /*0b00111111*/,
          (0x2 /*0b10*/ << 6) | (point & 0x3f) /*0b00111111*/
        );
    } else return fromCharCode(0xef, 0xbf, 0xbd);
  }
  if (point <= 0x007f) return nonAsciiChars;
  else if (point <= 0x07ff) {
    return fromCharCode(
      (0x6 << 5) | (point >>> 6),
      (0x2 << 6) | (point & 0x3f)
    );
  } else
    return fromCharCode(
      (0xe /*0b1110*/ << 4) | (point >>> 12),
      (0x2 /*0b10*/ << 6) | ((point >>> 6) & 0x3f) /*0b00111111*/,
      (0x2 /*0b10*/ << 6) | (point & 0x3f) /*0b00111111*/
    );
}
/** @constructor */
export function TextEncoder() {}
function encode(inputString) {
  // 0xc0 => 0b11000000; 0xff => 0b11111111; 0xc0-0xff => 0b11xxxxxx
  // 0x80 => 0b10000000; 0xbf => 0b10111111; 0x80-0xbf => 0b10xxxxxx
  const encodedString =
    inputString === void 0
      ? ""
      : ("" + inputString).replace(
          /[\x80-\uD7ff\uDC00-\uFFFF]|[\uD800-\uDBFF][\uDC00-\uDFFF]?/g,
          encoderReplacer
        );
  const len = encodedString.length | 0,
    result = usingTypedArrays
      ? new NativeUint8Array(len)
      : NativeBuffer["alloc"]
      ? NativeBuffer["alloc"](len)
      : new NativeBuffer(len);
  let i = 0;
  for (; i < len; i = (i + 1) | 0) result[i] = encodedString.charCodeAt(i) | 0;
  return result;
}
TextEncoder.prototype["encode"] = encode;
function factory(obj) {
  if (!obj["TextDecoder"]) obj["TextDecoder"] = TextDecoder;
  if (!obj["TextEncoder"]) obj["TextEncoder"] = TextEncoder;
  if (obj !== global) {
    obj["decode"] = decode;
    obj["encode"] = encode;
  }
  return obj;
}

typeof define == typeof factory && global.define["amd"]
  ? global.define(function () {
      return factory({});
    })
  : factory(typeof exports == "object" ? exports : global);
