import React from "react";
import styled, { css } from "styled-components";

import { ModalProps } from "../../modal/components/Modal";
import { Column } from "../../shared/components/Flex";
import { useDesktopMediaQuery } from "../../shared/hooks";
import * as t from "../../shared/text";
import { desktopBreakpoint } from "../../shared/utils";

import * as SC from "./PodsFilter/styledComponents";
import { PodsLocationModalContent } from "./PodsLocationModalContent";

export const PodsLocationModal: React.FC<ModalProps> = ({
  isOpen,
  onRequestClose,
}) => {
  const isDesktop = useDesktopMediaQuery();

  const modalProps = {
    hideCloser: !isDesktop,
    isOpen,
    onRequestClose,
  };

  return (
    <SC.PodsModal {...modalProps}>
      <sc.Header>
        <SC.BackArrow onClick={onRequestClose} />
        {t.SET_YOUR_LOCATION}
      </sc.Header>
      <sc.Container>
        <PodsLocationModalContent onRequestClose={onRequestClose} />
      </sc.Container>
    </SC.PodsModal>
  );
};

const sc = {
  Container: styled(Column)`
    height: calc(100% - 60px);
    padding: 0 16px;

    ${desktopBreakpoint(
      css`
        height: 100%;
        padding-bottom: 40px;
      `
    )}
  `,

  Header: styled(SC.Header)`
    margin-bottom: 12px;
    padding-left: 40px;
  `,
};
