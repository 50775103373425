import { createSelector } from "reselect";

import { MainAppState } from "../../../main/types";

import { getUser } from "./user";

export const getOrderedAccountDevices = createSelector([getUser], (user) => {
  return [...user.devices].sort((a, b) => {
    if (a.latestClientTimestamp < b.latestClientTimestamp) return 1;
    if (a.latestClientTimestamp > b.latestClientTimestamp) return -1;
    return 0;
  });
});

export const getLastConnectedAccountDevice = createSelector(
  [getOrderedAccountDevices],
  (devices) => {
    return devices[0];
  }
);

type GetAccountDeviceProps = {
  serial?: string;
};

export const getAccountDevice = createSelector(
  [
    getOrderedAccountDevices,
    (_: MainAppState, props: GetAccountDeviceProps) => props.serial,
  ],
  (devices, serial?: string) => {
    if (!serial) return;

    return devices.find((device) => device.serialNumber === serial);
  }
);

export const getShareLocationDataFlagForDevice = createSelector(
  [getAccountDevice],
  (device) => device?.dataSharingPreferences?.shareLocationData
);
