import Bugsnag from "@bugsnag/browser";
import { get as lsGet, set as lsSet } from "local-storage";
import { debounce, throttle } from "lodash";

import * as c from "../../constants";
import * as t from "../../types/VaporEngine";

import { EraProDevice } from "../EraProDevice";

const VAPOR_ENGINE_READ_WRITE_DELAY = 1000; // ms
const VAPOR_ENGINE_RETRY_DELAY = 500; // ms

export class VaporEngine {
  device: EraProDevice;
  activeUiFunction?: t.UiFunction;
  configurations: {
    [key in t.UiFunction]?: t.Configuration;
  };

  get activeConfiguration(): t.Configuration | undefined {
    if (!this.activeUiFunction) return undefined;

    return this.configurations[this.activeUiFunction];
  }

  constructor(device: EraProDevice) {
    this.configurations = {};
    this.device = device;
  }

  initialize(): void {
    this.readCurrentState();
    debounce(() => this.readCurrentConfig(), VAPOR_ENGINE_READ_WRITE_DELAY)();

    const lsKey = `${c.VaporEngine.LS_KEY_HAS_RECEIVED_REFINED_EXPERIENCE_MODES}.${this.device.serial}`;

    try {
      const hasReceivedRefinedExperienceModes = !!lsGet<boolean>(lsKey);

      if (!hasReceivedRefinedExperienceModes) {
        debounce(
          () => this.writeDefaultConfigurations(),
          VAPOR_ENGINE_READ_WRITE_DELAY * 2
        )();
      }
    } catch (err) {
      Bugsnag.notify(`${c.VaporEngine.ERROR_GETTING_LOCAL_STORAGE}: ${lsKey}`);
    }
  }

  readCurrentState(): void {
    this.device.writeAttribute(c.ATTRIBUTE_VAPOR_ENGINE, {
      action: t.Action.READ_CURRENT_STATE,
    });
    this.device.readAttribute(c.ATTRIBUTE_VAPOR_ENGINE);
  }

  readCurrentConfig(podRev: t.PodType = t.PodType.D3N): void {
    // In this case, t.UiFunction.CUSTOM means read the active petal configuration.
    this.readPetalConfig(t.UiFunction.CUSTOM, podRev);
  }

  readPetalConfig = throttle(
    (uiFunction: t.UiFunction, podRev: t.PodType = t.PodType.D3N): void => {
      const vaporEngineConfig: Partial<t.Configuration> = {
        action: t.Action.READ_PETAL_CONFIG,
        podRev,
        uiFunction,
      };

      this.device.writeAttribute(c.ATTRIBUTE_VAPOR_ENGINE, vaporEngineConfig);
      this.device.readAttribute(c.ATTRIBUTE_VAPOR_ENGINE);
    },
    VAPOR_ENGINE_READ_WRITE_DELAY
  );

  readPetalConfigs(uiFunctions: t.UiFunction[]): void {
    if (uiFunctions.length === 0) return;

    uiFunctions.forEach((uiFunction, index) => {
      debounce(
        () => this.readPetalConfig(uiFunction),
        index * VAPOR_ENGINE_READ_WRITE_DELAY
      )();
    });
  }

  writeActivePetalMode(petal: 1 | 2 | 3 | 4): void {
    const vaporEngineConfig: Partial<t.Configuration> = {
      action: t.Action.SET_ACTIVE_PETAL,
      uiFunction: petal,
    };

    this.device.writeAttribute(c.ATTRIBUTE_VAPOR_ENGINE, vaporEngineConfig);
    this.readCurrentState();
    this.readCurrentConfig();
    this.device.readSessionControl();
  }

  writeConfiguration = throttle(
    (
      config: t.Configuration,
      options: t.WriteConfigurationOptions = c.VaporEngine
        .DEFAULT_WRITE_CONFIGURATION_OPTIONS
    ): void => {
      this.device.writeAttribute(c.ATTRIBUTE_VAPOR_ENGINE, {
        ...config,
        action: t.Action.WRITE_CONFIG,
        dosePulse: options.useActiveDosePulse
          ? this.activeConfiguration?.dosePulse
          : config.dosePulse,
        doseSize: options.useActiveDoseSize
          ? this.activeConfiguration?.doseSize
          : config.doseSize,
      });

      this.readPetalConfig(config.uiFunction);

      if (config.uiFunction === t.UiFunction.CUSTOM) {
        this.activeUiFunction = t.UiFunction.CUSTOM;
      } else if (options.setPetalConfiguration) {
        this.writeActivePetalMode(config.uiFunction);
      }
    },
    VAPOR_ENGINE_READ_WRITE_DELAY
  );

  private writeConfigurations(
    configurations: t.Configuration[],
    options: t.WriteConfigurationOptions = { setPetalConfiguration: false }
  ): void {
    if (configurations.length === 0) return;

    configurations.forEach((configuration, index) => {
      debounce(
        () => this.writeConfiguration(configuration, options),
        VAPOR_ENGINE_READ_WRITE_DELAY * index
      )();
    });
  }

  private writeDefaultConfigurations(): void {
    this.writeConfigurations(
      [
        c.VaporEngine.STEALTH,
        c.VaporEngine.EFFICIENCY,
        c.VaporEngine.BALANCED,
        c.VaporEngine.BOOST,
      ],
      {
        setPetalConfiguration: false,
        useActiveDosePulse: false,
        useActiveDoseSize: false,
      }
    );

    const lsKey = `${c.VaporEngine.LS_KEY_HAS_RECEIVED_REFINED_EXPERIENCE_MODES}.${this.device.serial}`;

    try {
      lsSet<boolean>(lsKey, true);
    } catch (err) {
      Bugsnag.notify(`${c.VaporEngine.ERROR_SETTING_LOCAL_STORAGE}: ${lsKey}`);
    }
  }

  writeCustomTemp(tempC10: number, podRev: t.PodType = t.PodType.D3N): void {
    if (!this.activeConfiguration) {
      this.readCurrentConfig(podRev);
      debounce(
        () => this.writeCustomTemp(tempC10, podRev),
        VAPOR_ENGINE_RETRY_DELAY
      )();
      return;
    }

    this.writeConfiguration({
      ...this.activeConfiguration,
      heatingCurve: [
        {
          milliseconds: 10000,
          tempC10,
        },
        {
          milliseconds: 0,
          tempC10: 0,
        },
        {
          milliseconds: 0,
          tempC10: 0,
        },
        {
          milliseconds: 0,
          tempC10: 0,
        },
        {
          milliseconds: 0,
          tempC10: 0,
        },
        {
          milliseconds: 0,
          tempC10: 0,
        },
        {
          milliseconds: 0,
          tempC10: 0,
        },
        {
          milliseconds: 0,
          tempC10: 0,
        },
      ],
      podRev,
      uiFunction: t.UiFunction.CUSTOM,
    });
  }

  setDoseControl(
    doseSize: t.DoseSize,
    doseLockoutTimeInSeconds = c.VaporEngine.DOSE_LOCKOUT_TIME,
    podRev = t.PodType.D3N
  ): void {
    if (!this.activeConfiguration) {
      this.readCurrentConfig(podRev);
      debounce(
        () => this.setDoseControl(doseSize, doseLockoutTimeInSeconds, podRev),
        VAPOR_ENGINE_RETRY_DELAY
      )();
      return;
    }

    this.writeConfiguration({
      ...this.activeConfiguration,
      doseLockoutTime: doseLockoutTimeInSeconds,
      // Product requirement. Dose pulse and dose control can not both be turned on.
      dosePulse:
        doseSize === t.DoseSize.NONE ? this.activeConfiguration.dosePulse : 0,
      doseSize,
      podRev,
    });
  }

  setDosePulse(enabled: boolean, podRev = t.PodType.D3N): void {
    if (!this.activeConfiguration) {
      this.readCurrentConfig(podRev);
      debounce(
        () => this.setDosePulse(enabled, podRev),
        VAPOR_ENGINE_RETRY_DELAY
      )();
      return;
    }

    this.writeConfiguration({
      ...this.activeConfiguration,
      dosePulse: enabled ? 1 : 0,
      // Product requirement. Dose pulse and dose control can not both be turned on.
      doseSize: enabled ? t.DoseSize.NONE : this.activeConfiguration.doseSize,
      podRev,
    });
  }

  reset(): void {
    this.device.writeAttribute(c.ATTRIBUTE_VAPOR_ENGINE, {
      action: t.Action.RESET,
    });
  }
}
