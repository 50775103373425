import { Formik } from "formik";
import { debounce } from "lodash";
import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled, { css } from "styled-components";
import usePlacesAutocomplete from "use-places-autocomplete";

import { updateUserLocation } from "../../api/consumer/actions/users";
import * as s from "../../api/consumer/selectors/user";
import * as icons from "../../shared/components/Icons";
import * as Text from "../../shared/components/Text";
import { useOutsideClickListener } from "../../shared/hooks";
import * as t from "../../shared/text";
import { desktopBreakpoint } from "../../shared/utils";

import { PodsLocationInputComponents } from "../styledComponents";
import { isValidPostalCode } from "../utils";
import { PodsLocationReset } from "./PodsLocationReset";

const RESET_LOCATION_MODAL_TIMEOUT = 1500;

type PlacesFormValues = {
  address: string;
};

const placesFormValidation = (values: PlacesFormValues) => {
  const errors: Partial<PlacesFormValues> = {};
  if (!values.address) {
    errors.address = t.ADDRESS_REQUIRED;
  }
  return errors;
};

const getInitialAddress = (address: string | null | undefined): string => {
  // If the returned query value is a postal code, don't initialize the form with that value
  if (!address || isValidPostalCode(address)) return "";
  return address;
};

const POWERED_BY_GOOGLE_ICON_URL =
  "https://developers.google.com/maps/documentation/images/powered_by_google_on_white.png";

type AddressSuggestionsProps = {
  clearValues: () => void;
  data: google.maps.places.AutocompletePrediction[];
  handleSelect: (value: string) => void;
  onClick: (address: string) => void;
};

const AddressSuggestions: React.FC<AddressSuggestionsProps> = ({
  clearValues,
  data,
  handleSelect,
  onClick,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  useOutsideClickListener(clearValues, ref);

  const suggestions = data.map(({ place_id, description }) => (
    <sc.Suggestion
      key={place_id}
      onClick={() => {
        onClick(description);
        handleSelect(description);
      }}
    >
      <div>{description}</div>
    </sc.Suggestion>
  ));

  return (
    <sc.SuggestionsContainer ref={ref}>
      {suggestions}
      <sc.PoweredByGoogle>
        <img src={POWERED_BY_GOOGLE_ICON_URL} alt={t.POWERED_BY_GOOGLE} />
      </sc.PoweredByGoogle>
    </sc.SuggestionsContainer>
  );
};

type PodsPlacesFormProps = {
  hideDivider?: boolean;
  onRequestClose?: () => unknown;
};

export const PodsPlacesForm: React.FC<PodsPlacesFormProps> = ({
  hideDivider = false,
  onRequestClose,
}) => {
  const dispatch = useDispatch();
  const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(true);
  const location = useSelector(s.getUserLocation);

  const placesAutocomplete = usePlacesAutocomplete({
    debounce: 500,
    requestOptions: {
      componentRestrictions: { country: ["us", "ca"] },
    },
  });

  const clearValues = (): void => {
    placesAutocomplete.clearSuggestions();
    placesAutocomplete.setValue("");
  };
  const ref = useRef<HTMLDivElement>(null);
  useOutsideClickListener(clearValues, ref);

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>): void => {
    placesAutocomplete.setValue(e.target.value);
  };

  const handleSelect = (value: string) => {
    placesAutocomplete.setValue(value, false);
    placesAutocomplete.clearSuggestions();
  };

  const handleSubmit = async () => {
    dispatch(updateUserLocation(placesAutocomplete.value));
    onRequestClose && onRequestClose();
  };

  const handleSubmitButtonChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsSubmitDisabled(
      e.target.value === "" || e.target.value === location?.query
    );
  };

  const handleLocationReset = () => {
    setIsSubmitDisabled(true);
    clearValues();
    debounce(() => {
      onRequestClose && onRequestClose();
    }, RESET_LOCATION_MODAL_TIMEOUT)();
  };

  // TODO - un-comment when API supports this (https://paxlabs.atlassian.net/browse/PCP-1398)
  // const handleSeeAllPodsInCountry = (region: SsoCountry) => {
  //   dispatch(updateUserLocation(region));
  //   onRequestClose && onRequestClose();
  // };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        address: getInitialAddress(location?.query),
      }}
      onSubmit={handleSubmit}
      validate={placesFormValidation}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {({ errors, handleChange, setFieldValue, values }) => (
        <PodsLocationInputComponents.Form>
          <PodsLocationInputComponents.FormTop>
            <PodsLocationInputComponents.Field
              autoCapitalize="off"
              autoComplete="off"
              autoCorrect="off"
              data-testid="address-field-input"
              disabled={!placesAutocomplete.ready}
              name="address"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleChange(e);
                handleInput(e);
                handleSubmitButtonChange(e);
              }}
              placeholder={t.ENTER_YOUR_ADDRESS}
              required
              type="text"
            />
            {values.address !== "" && (
              <PodsLocationInputComponents.Clear
                onClick={() => {
                  clearValues();
                  setFieldValue("address", "");
                }}
              >
                <icons.Clear size={14} />
              </PodsLocationInputComponents.Clear>
            )}
            {placesAutocomplete.suggestions?.status === "OK" && (
              <AddressSuggestions
                clearValues={clearValues}
                data={placesAutocomplete.suggestions.data}
                handleSelect={handleSelect}
                onClick={(address: string) => {
                  setFieldValue("address", address);
                }}
              />
            )}
            {errors.address && (
              <PodsLocationInputComponents.Error>
                {errors.address}
              </PodsLocationInputComponents.Error>
            )}
            {location && (
              <sc.PodsLocationReset
                location={location}
                onReset={handleLocationReset}
              />
            )}
            {/* TODO - un-comment when API supports this (https://paxlabs.atlassian.net/browse/PCP-1398) */}
            {/* <sc.PodsInCountry
              onClick={() => handleSeeAllPodsInCountry(SsoCountry.US)}
              underline
            >
              {t.VIEW_ALL_PODS_IN_UNITED_STATES}
            </sc.PodsInCountry>
            <sc.PodsInCountry
              onClick={() => handleSeeAllPodsInCountry(SsoCountry.CANADA)}
              underline
            >
              {t.VIEW_ALL_PODS_IN_CANADA}
            </sc.PodsInCountry> */}
          </PodsLocationInputComponents.FormTop>
          <PodsLocationInputComponents.Footer hideDivider={hideDivider}>
            <PodsLocationInputComponents.Button
              disabled={isSubmitDisabled}
              type="submit"
            >
              {t.APPLY}
            </PodsLocationInputComponents.Button>
          </PodsLocationInputComponents.Footer>
        </PodsLocationInputComponents.Form>
      )}
    </Formik>
  );
};

const sc = {
  PlacesContainer: styled.div`
    position: relative;
    width: 100%;
  `,

  PodsInCountry: styled(Text.Link)`
    ${Text.PlaaxItalicLight};
    cursor: pointer;
    font-size: 14px;
    margin: 12px 0 0 26px;
  `,

  PodsLocationReset: styled(PodsLocationReset)`
    margin-top: 12px;
  `,

  PoweredByGoogle: styled.li`
    display: flex;
    justify-content: flex-end;
    list-style-type: none;
    padding: 10px;
  `,

  Suggestion: styled.li`
    border-bottom: 1px solid var(--very-light-pink-2);
    list-style-type: none;
    padding: 10px;

    &:first-child {
      border-radius: 17px 17px 0 0;
    }

    &:hover {
      background-color: var(--bluey-grey-80);
      color: var(--white);
      cursor: pointer;
    }
  `,

  SuggestionsContainer: styled.div`
    background-color: var(--white);
    border: 1px solid var(--very-light-pink);
    border-radius: 18px;

    position: absolute;
    top: 48px;
    width: calc(100% - 30px);
    z-index: var(--z-index-location-menu);

    ${desktopBreakpoint(css`
      height: 140px;
      overflow: auto;
    `)}
  `,
};
