// Spec:
// https://github.com/PaxLabs/pax-logging/blob/develop/schemas/events/app/device/log_audit_0.json

import * as PaxBle from "../../../pax-ble";

import { AppThunk } from "../../shared/types";

import { trackEventAction } from "../actions";

const EVENT_NAME = "log_audit";

export const trackLogAudit =
  (
    data: PaxBle.Types.LogStoreAnalyticsData,
    device: PaxBle.BaseDevice
  ): AppThunk =>
  (dispatch, getState): void => {
    let currentOffset: number | undefined;

    if (device.type === PaxBle.Types.DeviceType.ERA_PRO) {
      const localLogRequest = PaxBle.Utils.getEraProLogRequestFromLocalStorage(
        device.systemId
      );

      currentOffset = localLogRequest?.offset;
    } else if (device.type === PaxBle.Types.DeviceType.ERA) {
      const localLogRequest = PaxBle.Utils.getEraLogRequestFromLocalStorage(
        device.systemId
      );

      currentOffset = localLogRequest?.timestampOffset;
    }

    currentOffset = currentOffset || -1;

    trackEventAction<PaxBle.Types.LogStoreAnalyticsData>(EVENT_NAME, {
      ...data,
      maxIndex: currentOffset,
    })(dispatch, getState, null);
  };
