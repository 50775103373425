import crypto from "crypto";

import { byteArrayToUnsignedInt } from "../../mcumgr-js/src/utils";

import { Endian } from "../types";
import * as u from "../utils";

// prettier-ignore
export const ADVERTISING_INIT_VECTOR = Uint8Array.of(
  0xde, 0xad, 0xbe, 0xef,
  0xde, 0xad, 0xbe, 0xef,
  0xde, 0xad, 0xbe, 0xef,
  0xde, 0xad, 0xbe, 0xef
);

export class EraProEncryptionContext {
  centralId: Uint8Array; // generated by web-app
  centralNonce: Uint8Array; // updated by web-app
  peripheralId?: Uint8Array; // serial number from device
  peripheralNonce?: Uint8Array; // updated by device
  serviceUuid = ""; // encrypted value of ERA_PRO_PAX_SERVICE_UUID
  sharedKey: Uint8Array; // generated by web-app

  // Values captured in EraProEncryptionHandshake and set on encrypted EraPro
  firmwareRevision?: Uint8Array;
  systemId?: Uint8Array;

  constructor() {
    this.centralId = u.encodeTextValue(u.getUuid(8));
    this.centralNonce = new Uint8Array(16);
    this.sharedKey = this.generateSharedKey();
  }

  getSharedKeyWithCrc16(): Uint8Array {
    const crc16 = u.generateCrc16(this.sharedKey);
    const keyWithCrc = u.mergeTypedArrays(this.sharedKey, crc16);

    return keyWithCrc;
  }

  getIncrementedCentralNonce(): Uint8Array {
    const oldNonceValue = BigInt(
      byteArrayToUnsignedInt(this.centralNonce, 0, Endian.LITTLE, 4)
    );
    const updatedNonceValue = oldNonceValue + BigInt(1);

    const buffer = new ArrayBuffer(16);
    const view = new DataView(buffer);
    view.setBigUint64(0, updatedNonceValue, true);
    const updatedNonce = new Uint8Array(buffer);
    this.centralNonce = updatedNonce;

    return updatedNonce;
  }

  getCombinedId(): Uint8Array {
    if (!this.peripheralId) throw new Error("Peripheral ID not yet set");
    const combinedId = u.mergeTypedArrays(this.peripheralId, this.centralId);

    return combinedId;
  }

  private generateSharedKey(): Uint8Array {
    return crypto.randomBytes(32);
  }
}
