import { get as lsGet, remove as lsRemove, set as lsSet } from "local-storage";

import * as PaxBle from "../../pax-ble/";

import { LS_KEY_LOCKOUT_TIMEOUT_START, OPT_IN_LOG_TYPES } from "./constants";
import { NullableDeviceType, TemperatureRange } from "./types";

export const doesLogEventRequireOptIn = (
  eventType: PaxBle.Types.LogEventType
): boolean => {
  return OPT_IN_LOG_TYPES.includes(eventType);
};

export const isPax3 = (deviceType: NullableDeviceType): boolean => {
  return (
    deviceType === PaxBle.Types.DeviceType.PAX_3 ||
    deviceType === PaxBle.Types.DeviceType.PAX_35
  );
};

export const isWithinRange = (
  n: number | null | undefined,
  [min, max]: TemperatureRange
): boolean => {
  if (n === null || n === undefined) return false;

  return min < n && n < max;
};

export const removeDeviceLockoutStarted = (): void => {
  lsRemove(LS_KEY_LOCKOUT_TIMEOUT_START);
};

export const setDeviceLockoutStarted = (
  lockoutStartedAt: number,
  timeoutLength: number
): void => {
  const existingTimeoutStart = lsGet<number>(LS_KEY_LOCKOUT_TIMEOUT_START);

  // set a new timeout start time if there isn't one or if existing one is stale
  if (
    !existingTimeoutStart ||
    existingTimeoutStart + timeoutLength * 1000 - Date.now() < 0
  )
    lsSet<number>(LS_KEY_LOCKOUT_TIMEOUT_START, lockoutStartedAt);
};

//################## DEVICE HEATER FUNCTIONS BELOW ###########################

/*
################### Pod heater calculation functions ##########################
2ºF offset is used when converting between ºC and ºF so that temperature 
displayed in ºF is a multiple of 10 for Era's four default temp settings.

https://paxlabs.atlassian.net/wiki/spaces/PMA/pages/40042561/Pen+Advertised+Temperature+Conversions
*/

export function heaterSetPointToFahrenheit(setPoint: number): number {
  return (setPoint * 9) / 50 + 34;
}

export function fahrenheitToHeaterSetPoint(fahrenheit: number): number {
  return Math.round(((fahrenheit - 34) * 50 + 25) / 9);
}

export function heaterSetPointToCelsius(setPoint: number): number {
  return setPoint / 10;
}

export function celsiusToHeaterSetPoint(celsius: number): number {
  return Math.round(celsius * 10);
}

export function celsiusToFahrenheit(celsius: number): number {
  return (celsius * 9) / 5 + 34;
}

export function fahrenheitToCelcius(fahrenheit: number): number {
  return heaterSetPointToCelsius(fahrenheitToHeaterSetPoint(fahrenheit));
}

/*
################### Flower heater calculation functions #######################
Temperatures sent to and received from the device are set points of the 
thermistor (temperature sensor) on the oven, not average oven temperature, 
which we cannot measure. These methods convert the oven temperature to the user
advertised temperature.

More info: https://paxlabs.atlassian.net/wiki/spaces/PMA/pages/20086800/Flower+Advertised+Temperature+Conversions
*/

export function ovenSetPointToAdvertisedCelsius(setPoint: number): number {
  if (setPoint < 1980) {
    return ((setPoint + 4) * 10) / 109 + 3;
  } else {
    return setPoint / 12 + 20;
  }
}

export function advertisedCelsiusToOvenSetPoint(celsius: number): number {
  if (celsius < 185) {
    return ((celsius - 3) * 109) / 10 + 6;
  } else {
    return (celsius - 20) * 12 + 10;
  }
}

export function ovenSetPointToAdvertisedFahrenheit(setPoint: number): number {
  if (setPoint < 1986) {
    return (setPoint * 6) / 37 + 37;
  } else {
    return setPoint / 6 + 28;
  }
}

export function advertisedFahrenheitToOvenSetPoint(fahrenheit: number): number {
  if (fahrenheit < 360) {
    return ((fahrenheit - 38) * 37) / 6 + 5;
  } else {
    return (fahrenheit - 29) * 6 + 5;
  }
}
// ########### End device specific heater calculation functions ###############

const { PAX_3, PAX_35 } = PaxBle.Types.DeviceType;

export function setPointToCelsius(
  setPoint: number,
  deviceType: NullableDeviceType
): number {
  if (!deviceType) throw new Error("Device type not defined");

  if (deviceType === PAX_3 || deviceType === PAX_35)
    return ovenSetPointToAdvertisedCelsius(setPoint) - 1;

  return heaterSetPointToCelsius(setPoint);
}

export function celsiusToSetPoint(
  celsius: number,
  deviceType: NullableDeviceType
): number {
  if (!deviceType) throw new Error("Device type not defined");

  if (deviceType === PAX_3 || deviceType === PAX_35)
    return advertisedCelsiusToOvenSetPoint(celsius);

  return celsiusToHeaterSetPoint(celsius);
}

export function setPointToFahrenheit(
  setPoint: number,
  deviceType: NullableDeviceType
): number {
  if (!deviceType) throw new Error("Device type not defined");
  if (deviceType === PAX_3 || deviceType === PAX_35)
    return ovenSetPointToAdvertisedFahrenheit(setPoint);

  return heaterSetPointToFahrenheit(setPoint);
}

export function fahrenheitToSetPoint(
  fahrenheit: number,
  deviceType: NullableDeviceType
): number {
  if (!deviceType) throw new Error("Device type not defined");

  if (deviceType === PAX_3 || deviceType === PAX_35)
    return advertisedFahrenheitToOvenSetPoint(fahrenheit);

  return fahrenheitToHeaterSetPoint(fahrenheit);
}
