import Bugsnag from "@bugsnag/js";

import { Request } from "./Request";

export class RequestQueue {
  isRequestInProgress = false;
  queue: Request[] = [];

  push(request: Request): void {
    this.queue.push(request);
    this.sendNextRequest();
  }

  private async sendNextRequest(): Promise<void> {
    if (this.isRequestInProgress) return;

    const request = this.queue.shift();
    if (!request) return;

    this.isRequestInProgress = true;
    try {
      await request.send();
    } catch (e) {
      console.error("request error", request);
      Bugsnag.notify(e as Error);
    }
    this.isRequestInProgress = false;

    this.sendNextRequest();
  }
}
