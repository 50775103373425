import styled from "styled-components";

import { PlaaxLight } from "./Text";

export const TextButton = styled.button`
  ${PlaaxLight}
  background: none;
  border: none;
  cursor: pointer;
  font-size: 14px;
  margin-left: 8px;
  padding: 0 0 2px;
  text-decoration: underline;
`;
