import React from "react";
import { RouteComponentProps } from "react-router-dom";
import styled, { css } from "styled-components";

import * as consumerApi from "../../../api/consumer";
import * as r from "../../../main/routes";
import { Column } from "../../../shared/components/Flex";
import GoBack from "../../../shared/components/GoBack";
import { PartnerLogo } from "../../../shared/components/PartnerLogo";
import * as Text from "../../../shared/components/Text";
import TruncatedText from "../../../shared/components/TruncatedText";
import { useDesktopMediaQuery } from "../../../shared/hooks";
import * as text from "../../../shared/text";
import { desktopBreakpoint } from "../../../shared/utils";

import { usePodsPerCarousel } from "../../hooks";
import { buildStrainsFilter } from "../../models/StrainsFilter";
import { DetailsPage, LegalFooter, LegalText } from "../../styledComponents";
import { getLegalDisclaimer } from "../../utils";

import * as podUtils from "../PodDetails/utils";
import { PodsCarousel } from "../PodsCarousel";
import { PartnerMediaCarousel } from "./PartnerMediaCarousel";
import { PartnerStates } from "./PartnerStates";

const PARTNER_DESCRIPTION_TRUNCATED_LENGTH = 575;
const PARTNER_DESCRIPTION_TRUNCATED_LENGTH_MOBILE = 230;

export type PartnerDetailsPageProps = RouteComponentProps<{
  partnerId: string;
}> & {
  usePartnerQuery?: consumerApi.hooks.UseReactQuery<consumerApi.types.partner.PartnerJson>;
  useStrainsQuery?: consumerApi.hooks.UseReactQuery<
    consumerApi.types.CollectionResponse<consumerApi.types.StrainJson>
  >;
};

export const PartnerDetailsPage: React.FC<PartnerDetailsPageProps> = ({
  match,
  usePartnerQuery = consumerApi.hooks.useReactQuery,
  useStrainsQuery = consumerApi.hooks.useReactQuery,
}) => {
  const { partnerId } = match.params;
  const isDesktop = useDesktopMediaQuery();
  const podsPerCarousel = usePodsPerCarousel();

  const { data: partner } = usePartnerQuery({
    path: consumerApi.paths.partners.publicPartner(partnerId),
  });

  const filter = buildStrainsFilter({
    orderBy: consumerApi.types.strain.OrderBy.RANDOM,
    partnerId,
  });

  const { data: partnerStrains, isLoading: partnerStrainsAreLoading } =
    useStrainsQuery({
      path: consumerApi.paths.strains.publicStrains({
        filter,
        page: 1,
        pageSize: podsPerCarousel,
      }),
    });

  const truncatedTextLength = isDesktop
    ? PARTNER_DESCRIPTION_TRUNCATED_LENGTH
    : PARTNER_DESCRIPTION_TRUNCATED_LENGTH_MOBILE;

  const partnerMedia = (
    <PartnerMediaCarousel photos={partner?.photos} videos={partner?.videos} />
  );

  return (
    <>
      <DetailsPage>
        <sc.GoBack
          to={r.podsSearch(buildStrainsFilter({ partnerId }))}
          data-testid="go-back-button"
        />
        <sc.PartnerLogo partner={partner} />
        <sc.SectionContainer>
          <sc.Title data-testid="partner-name">{partner?.partnerName}</sc.Title>
          <PartnerStates states={partner?.states} />
        </sc.SectionContainer>
        <sc.SectionContainer>{!isDesktop && partnerMedia}</sc.SectionContainer>
        <sc.SectionContainer>
          <sc.SectionTitle data-testid="about-partner-header">
            {text.aboutBrandPartner(partner?.partnerName)}
          </sc.SectionTitle>
          <TruncatedText
            maxLength={truncatedTextLength}
            text={partner?.description}
            data-testid="about-partner-description"
          />
        </sc.SectionContainer>
        <sc.SectionContainer>
          <PodsCarousel
            filterPartial={{ partnerId, region: partner?.region }}
            isLoading={partnerStrainsAreLoading}
            key={"partner-pods"}
            shouldShowViewAll={!!partnerStrains?.next}
            strains={partnerStrains?.results || []}
            title={text.podsByBrandPartner(partner?.partnerName)}
          />
        </sc.SectionContainer>
        <sc.SectionContainer>{isDesktop && partnerMedia}</sc.SectionContainer>
        <LegalFooter>
          <LegalText>
            {getLegalDisclaimer(
              podUtils.isPaxStrain(partnerId),
              partner?.partnerName
            )}
          </LegalText>
        </LegalFooter>
      </DetailsPage>
    </>
  );
};

const sc = {
  GoBack: styled(GoBack)`
    left: 8px;
    position: absolute;
    top: 0;

    ${desktopBreakpoint(css`
      left: 0;
    `)}
  `,

  PartnerLogo: styled(PartnerLogo)`
    height: 124px;
    margin: 28px auto 12px;
    width: 124px;

    ${desktopBreakpoint(
      css`
        height: 140px;
        margin: 0 auto 32px;
        width: 140px;
      `
    )}
  `,

  SectionContainer: styled(Column)`
    margin-bottom: 19px;
    text-align: left;
    width: 100%;

    ${desktopBreakpoint(
      css`
        margin-bottom: 32px;
      `
    )}
  `,

  SectionTitle: styled.div`
    ${Text.Plaax}
    font-size: 18px;
    margin-bottom: 10px;
    text-transform: capitalize;

    ${desktopBreakpoint(
      css`
        font-size: 20px;
        margin-bottom: 12px;
      `
    )}
  `,

  Title: styled.div`
    ${Text.Plaax}
    font-size: 32px;
    line-height: 1.06;
    margin-bottom: 12px;

    ${desktopBreakpoint(
      css`
        font-size: 40px;
        line-height: 40px;
        margin: 0 auto 8px;
      `
    )}
  `,
};
