import React from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

import { TestResultJson } from "../../api/consumer/types/strain";
import * as r from "../../main/routes";
import { Row } from "../../shared/components/Flex";
import { ChevronRight } from "../../shared/components/Icons";
import * as Text from "../../shared/components/Text";
import { batchId } from "../../shared/text";
import { desktopBreakpoint, formatTimestampWithDots } from "../../shared/utils";

type PodsBatchListProps = {
  batchesWithReports: TestResultJson[];
  hasAutoIdTestReport: boolean;
};

const PodsBatchList: React.FC<PodsBatchListProps> = ({
  batchesWithReports,
  hasAutoIdTestReport,
}) => {
  if (batchesWithReports.length < 1) return null;

  return (
    <>
      <sc.ListHeading>
        {hasAutoIdTestReport ? "Other Batches" : "All Batches"}
      </sc.ListHeading>
      {batchesWithReports.map((batch) => (
        <sc.Link
          to={r.podManualTestResults(batch.batchId, batch.strainId)}
          key={batch.batchId}
        >
          <div data-testid={batch.batchId}>
            <sc.Row justifyContent="space-between">
              <Row>
                <sc.DateContainer>
                  <sc.Date>
                    {formatTimestampWithDots(
                      false,
                      batch.parsedBatchResult
                        ? batch.parsedBatchResult.testDate
                        : ""
                    )}
                  </sc.Date>
                </sc.DateContainer>
                <sc.Dot>&#183;</sc.Dot>
                <sc.Batch>{batchId(batch.batchId)}</sc.Batch>
              </Row>
              <sc.ChevronRight />
            </sc.Row>
          </div>
        </sc.Link>
      ))}
    </>
  );
};

const sc = {
  Batch: styled.div`
    ${Text.PlaaxLight}
    color: var(--black);
    font-size: 16px;
    letter-spacing: 0.3px;
    margin-left: 12px;
  `,

  ChevronRight: styled(ChevronRight)`
    height: 24px;
    margin-right: 17px;
    width: 24px;

    & path {
      fill: var(--black);
    }
  `,

  Date: styled.div`
    ${Text.PlaaxLight}
    color: var(--brown-grey);
    font-size: 16px;
    letter-spacing: 0.3px;
  `,

  DateContainer: styled.div`
    margin-right: 10px;
    width: 60px;
  `,

  Dot: styled.span`
    color: var(--brown-grey);
    font-size: 16px;
  `,

  Link: styled(Link)`
    border-top: dotted 1.5px var(--very-light-pink);
    text-decoration: none;

    &:last-child {
      border-bottom: dotted 1.5px var(--very-light-pink);
    }

    ${desktopBreakpoint(css`
      &:last-child {
        border-bottom: none;
      }
    `)}
  `,

  ListHeading: styled.div`
    font-size: 20px;
    margin-bottom: 12px;
    text-align: left;
    text-transform: uppercase;

    ${desktopBreakpoint(css`
      margin-bottom: 16px;
    `)}
  `,

  Row: styled(Row)`
    align-items: center;
    padding: 12px 0;

    ${desktopBreakpoint(css`
      border-bottom: none;
    `)}
  `,
};

export default PodsBatchList;
