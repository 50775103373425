import { navigator } from "../../navigator";

import * as consumerApi from "../api/consumer";
import { getAccountDevice } from "../api/consumer/selectors/devices";
import { getConnectedDevice } from "../bluetooth/connectedDevice";
import { BannerType, showFlashBanner } from "../shared/components/FlashBanner";
import * as t from "../shared/text";
import { Action, AppThunk } from "../shared/types";
import * as c from "./constants";

const GET_LOCATION_TIMEOUT = 270_000; // 4.5 minutes

const finishRequestingLocation = (): Action => ({
  type: c.REQUEST_LOCATION_DONE,
});

export const saveLocation =
  (): AppThunk =>
  (dispatch, getState): void => {
    const connectedDevice = getConnectedDevice();
    if (!connectedDevice?.serial) return;

    const deviceJson = getAccountDevice(getState(), {
      serial: connectedDevice.serial,
    });
    const shareLocationDataFlag =
      consumerApi.selectors.devices.getShareLocationDataFlagForDevice(
        getState(),
        {
          serial: connectedDevice.serial,
        }
      );

    if (!shareLocationDataFlag) return;

    const onGetCurrentPositionError = (
      error: GeolocationPositionError
    ): void => {
      if (error.code === error.PERMISSION_DENIED) {
        dispatch(showFlashBanner(t.LOCATION_ACCESS_REQUIRED, BannerType.ERROR));
      } else {
        dispatch(
          showFlashBanner(t.LOCATION_COULD_NOT_BE_DETERMINED, BannerType.ERROR)
        );
      }

      if (!connectedDevice.serial) return;

      if (deviceJson)
        dispatch(
          consumerApi.actions.devices.setShareLocationDataFlag(
            false,
            deviceJson
          )
        );
    };

    const onGetCurrentPositionSuccess = (
      position: GeolocationPosition
    ): void => {
      dispatch(finishRequestingLocation());
      const connectedDevice = getConnectedDevice();
      if (!connectedDevice?.serial) return;

      const shareLocationDataFlag =
        consumerApi.selectors.devices.getShareLocationDataFlagForDevice(
          getState(),
          {
            serial: connectedDevice.serial,
          }
        );

      if (!shareLocationDataFlag) return;

      const { coords, timestamp } = position;
      const location: consumerApi.types.device.DeviceLocation = {
        appType: "WEB",
        latitude: coords.latitude,
        longitude: coords.longitude,
        setAt: new Date(timestamp).toISOString(),
      };

      const updates: Partial<consumerApi.types.device.PostDeviceJson> = {
        location,
      };

      consumerApi.actions.devices.updateDevice(connectedDevice.serial, updates)(
        dispatch,
        getState,
        null
      );
    };

    dispatch(startRequestingLocation());
    navigator.geolocation.getCurrentPosition(
      onGetCurrentPositionSuccess,
      onGetCurrentPositionError,
      { timeout: GET_LOCATION_TIMEOUT }
    );
  };

const startRequestingLocation = (): Action => ({
  type: c.REQUEST_LOCATION_START,
});
