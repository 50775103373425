import { useFormik } from "formik";
import React from "react";
import styled, { css } from "styled-components";

import * as consumerApi from "../../../api/consumer";
import { PlaaxButton } from "../../../shared/components/Button";
import * as text from "../../../shared/text";

import { DEFAULT_FORM_VALUES } from "../../constants";
import { usePodFeedbackFormController } from "../../hooks";
import * as SC from "../../styledComponents";

import { EffectsInput } from "./EffectsInput";
import { NotesInput } from "./NotesInput";
import { StarRatingInput } from "./StarRatingInput";

type PodFeedbackFormProps = {
  initialValues?: Partial<consumerApi.types.reviews.StrainReviewJson> | null;
  isSubmitDisabled?: boolean;
  onFieldChange?: (
    fieldName: string,
    values: Partial<consumerApi.types.reviews.StrainReviewJson>
  ) => unknown;
  onSubmit?: (
    values: Partial<consumerApi.types.reviews.StrainReviewJson>
  ) => unknown;
  submitLabel?: string;
};

export const PodFeedbackForm: React.FC<PodFeedbackFormProps> = ({
  initialValues,
  isSubmitDisabled,
  onFieldChange,
  onSubmit,
  submitLabel = text.SUBMIT_REVIEW,
  ...props
}) => {
  const handleRatingChange = (
    rating: consumerApi.types.reviews.StrainRating
  ): void => {
    formik.setFieldValue("rating", rating);

    if (onFieldChange) onFieldChange("rating", { ...formik.values, rating });
  };

  const handleEffectsChange = (selectedEffects: string[]): void => {
    formik.setFieldValue("selectedEffects", selectedEffects);

    if (onFieldChange)
      onFieldChange("selectedEffects", { ...formik.values, selectedEffects });
  };

  const handleNotesChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ): void => {
    formik.setFieldValue("text", e.target.value);

    if (onFieldChange)
      onFieldChange("text", { ...formik.values, text: e.target.value });
  };

  const handleSubmitClick = (
    values: Partial<consumerApi.types.reviews.StrainReviewJson>
  ) => {
    if (onSubmit) onSubmit(values);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { ...DEFAULT_FORM_VALUES, ...initialValues },
    onSubmit: handleSubmitClick,
  });

  const formViewOptions = usePodFeedbackFormController(formik.values);

  return (
    <sc.Form onSubmit={formik.handleSubmit} {...props}>
      <StarRatingInput
        onChange={handleRatingChange}
        value={formik.values.rating}
      />
      <sc.ConditionalFields condition={formViewOptions.shouldShowEffects}>
        <EffectsInput
          onChange={handleEffectsChange}
          value={formik.values.selectedEffects}
        />
        <sc.ConditionalFields
          condition={!formViewOptions.shouldShowNotesAndSubmit}
        >
          <sc.SkipEffects
            bold
            isClickable
            onClick={formViewOptions.skipEffects}
            marginTop={8}
          >
            {text.SKIP_EFFECTS}
          </sc.SkipEffects>
        </sc.ConditionalFields>
        <sc.ConditionalFields
          condition={formViewOptions.shouldShowNotesAndSubmit}
        >
          <NotesInput
            onChange={handleNotesChange}
            shouldRenderExpanded={!!formik.values.text}
            value={formik.values.text}
          />
          <sc.Button
            data-testid="submit-button"
            disabled={isSubmitDisabled || !formik.values.rating}
            type="submit"
          >
            {submitLabel}
          </sc.Button>
        </sc.ConditionalFields>
      </sc.ConditionalFields>
    </sc.Form>
  );
};

type ConditionalFieldsProps = {
  condition?: boolean;
};

const hiddenCss = css`
  height: 0;
  margin: 0;
  opacity: 0;
  pointer-events: none;

  & > * {
    height: 0;
    margin: 0;
    opacity: 0;
    pointer-events: none;
  }
`;

const sc = {
  Button: styled(PlaaxButton)`
    border-radius: 100px;
    height: 48px;
    margin-top: 16px;
    text-transform: capitalize;
    width: 328px;
  `,

  ConditionalFields: styled.div<ConditionalFieldsProps>`
    transition: opacity 0.5s;

    ${({ condition }) => (condition ? "" : hiddenCss)}
  `,

  Form: styled.form`
    align-items: center;
    display: flex;
    flex-direction: column;
  `,

  SkipEffects: styled(SC.Subtitle)`
    text-align: center;
  `,
};
