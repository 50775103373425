import { AnalyticsState } from "../analytics/types";
import { AnnouncementsState } from "../announcements/types";
import { ConsumerApiState } from "../api/consumer/reducer";
import { DeviceState } from "../device/types";
import { FindMyPaxState } from "../find-my-pax/types";
import { FirmwareState } from "../firmware/types";
import { MailingListState } from "../mailing-list/types";
import { ModalState } from "../modal/reducer";
import { PodsState } from "../pods/types";
import { FlashBannerState } from "../shared/components/FlashBanner/reducer";
import { SsoState } from "../sso/types";

export type MainAppState = {
  analytics: AnalyticsState;
  announcements: AnnouncementsState;
  consumerApi: ConsumerApiState;
  device: DeviceState;
  findMyPax: FindMyPaxState;
  firmware: FirmwareState;
  flashBanner: FlashBannerState;
  mailingList: MailingListState;
  modal: ModalState;
  pods: PodsState;
  sso: SsoState;
};

export enum Page {
  ACCOUNT = "/account",
  DEV = "/device/_dev",
  DEVICE = "/device",
  EXPLORE = "/explore",
  HOME = "/",
  MY_PAX = "/my-pax",
}
