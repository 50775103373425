import { trackStrainReview } from "../../../analytics/pods/strainReview";
import { Action, AppThunk } from "../../../shared/types";

import { fetch } from "../index";
import * as p from "../paths";
import { getStrain } from "../selectors/strains";
import * as t from "../types/reviews";

export const RECEIVED_REVIEW = "RECEIVED_REVIEW";

const receivedReview = (review: t.StrainReviewJson): Action => ({
  payload: { review },
  type: RECEIVED_REVIEW,
});

export type SaveReview = (
  strainId: string,
  review: Partial<t.CreateStrainReviewJson>,
  reviewId?: string
) => AppThunk;

export const saveReview: SaveReview =
  (strainId, review, reviewId) =>
  async (dispatch, getState): Promise<void> => {
    if (!review.rating || !strainId) return;

    const strain = getStrain(getState(), { strainId });

    try {
      const reviewResponse = await fetch<t.StrainReviewJson>(
        p.reviews.strainReview(strainId, reviewId),
        {
          data: { ...review, clientTimestamp: new Date().toISOString() },
          method: reviewId ? "PUT" : "POST",
        }
      );

      dispatch(receivedReview(reviewResponse));

      dispatch(
        trackStrainReview(reviewResponse, {
          isNewReview: !reviewId,
          partnerId: strain?.partner.id,
        })
      );
    } catch (err) {
      // Do nothing, fetch will handle the error.
    }
  };
