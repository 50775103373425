import React from "react";
import styled from "styled-components";

import { ClickableBox } from "./Box";
import { ChevronRight } from "./Icons";

type ChevronCarProps = {
  hideChevron?: boolean;
  onClick?: () => void;
};

export const ChevronCard: React.FC<ChevronCarProps> = ({
  hideChevron = false,
  children,
  ...props
}) => {
  return (
    <sc.Card hideChevron={hideChevron} {...props}>
      {children}
      {!hideChevron && <sc.ChevronRight />}
    </sc.Card>
  );
};

type CardProps = {
  hideChevron?: boolean;
  onClick?: () => void;
};

const sc = {
  Card: styled(ClickableBox)<CardProps>`
    cursor: ${({ hideChevron, onClick }) => {
      return !hideChevron || !!onClick ? "pointer" : "auto";
    }};
    transition: transform 0.2s;
    width: 100%;

    &:hover {
      transform: ${({ hideChevron, onClick }) =>
        !hideChevron || !!onClick ? "scale(1.03)" : "none"};
    }
  `,

  ChevronRight: styled(ChevronRight)`
    height: 24px;
    margin: 0 0 0 auto;
    width: 24px;

    & path {
      fill: var(--khaki-40);
    }
  `,
};
