import React from "react";
import styled, { SimpleInterpolation } from "styled-components";

import { EraReplayAction, EraReplayJson } from "../../../pax-ble/types/Era";

import { SessionProgressBar } from "../../device/components/SessionProgressBar";
import * as Card from "../../shared/components/Card";
import ComingSoonCard from "../../shared/components/ComingSoonCard";
import * as ps from "../../shared/components/PillSelect";
import * as Text from "../../shared/components/Text";

import { StartEraSession, StopEraSession } from "../actions";
import { DOSE_OPTIONS, ERA_SESSION_LOCKOUT_TIME } from "../constants";
import { useSessionControlStatus } from "../hooks";

export type StateProps = {
  heaterSetPoint: number;
  isDeviceLocked: boolean;
  replay?: EraReplayJson;
  supportsSessionControl: boolean;
};

export type EraSessionControlCardProps = StateProps & {
  onStartEraSession: StartEraSession;
  onStopEraSession: StopEraSession;
};

const EraSessionControlCard: React.FC<EraSessionControlCardProps> = ({
  heaterSetPoint,
  isDeviceLocked,
  replay,
  onStartEraSession,
  onStopEraSession,
  supportsSessionControl,
}) => {
  const { isPreviousLockoutActive, isSessionComplete } =
    useSessionControlStatus(heaterSetPoint, onStartEraSession, replay);

  const handleDoseChange = (selectedOption: ps.PillSelectOption): void => {
    const presetDoseId = selectedOption.value;
    onStartEraSession(presetDoseId, heaterSetPoint);
  };

  if (isDeviceLocked || !supportsSessionControl) {
    return (
      <ComingSoonCard
        isDeviceLocked={isDeviceLocked}
        text="Not supported by your device"
        title="Session Control"
      />
    );
  }

  if (replay && (replay.doseId || isPreviousLockoutActive)) {
    if (replay.action === EraReplayAction.USER_STOP) {
      onStopEraSession(replay.doseId);
    }

    const handleSessionCancelClick = (e: React.MouseEvent): void => {
      e.preventDefault();
      onStopEraSession(replay.doseId);
    };

    const label = replay.doseId
      ? `${DOSE_OPTIONS[replay.doseId].label} Dose`
      : "Session Control";

    return (
      <Card.Small>
        <SessionProgressBar
          initialLockoutSeconds={ERA_SESSION_LOCKOUT_TIME}
          label={label}
          onCancelClick={handleSessionCancelClick}
          progressPercentage={replay.progressPercentage}
          showTimeout={isSessionComplete || isPreviousLockoutActive}
        />
      </Card.Small>
    );
  }

  return (
    <Card.Small>
      <sc.TitleRow>
        <sc.Title>Session Control</sc.Title>
      </sc.TitleRow>
      <sc.Text>Choose desired session size</sc.Text>
      <ps.PillSelect
        Container={sc.PillSelectContainer}
        PillOption={sc.PillOption}
        data-testid="session-control-pill-select"
        onChange={handleDoseChange}
        options={Object.values(DOSE_OPTIONS).reverse()}
      />
    </Card.Small>
  );
};

type TitleRowProps = {
  activeSession?: boolean;
};

type TitleProps = {
  isDeviceLocked?: boolean;
};

const sc = {
  PillOption: styled(ps.PillOption)`
    font-size: 12px;
    padding-bottom: 2px;
  `,

  PillSelectContainer: styled.div`
    display: flex;
    flex-direction: row-reverse;
    height: 36px;
    width: 100%;
  `,

  Text: styled.div`
    ${Text.PlaaxLight}
    font-size: 14px;
    margin-bottom: 20px;
  `,

  Title: styled.div<TitleProps>`
    color: ${(props): SimpleInterpolation =>
      props.isDeviceLocked ? "var(--very-light-pink)" : "var(--black)"};
    font-size: 20px;
    margin-bottom: 8px;
    text-transform: uppercase;
  `,

  TitleActiveSession: styled.div`
    font-size: 20px;
    margin-bottom: 8px;
    text-transform: uppercase;
  `,

  TitleRow: styled.div<TitleRowProps>`
    margin-bottom: ${({ activeSession }): SimpleInterpolation =>
      activeSession ? "24px" : "0"};
    position: relative;
    text-align: center;
    width: 100%;
  `,
};

export default EraSessionControlCard;
