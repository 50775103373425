import React, { useEffect } from "react";
import styled from "styled-components";

import * as PaxBle from "../../../pax-ble";

import * as a from "../../device/actions";
import * as c from "../../device/constants";
import { NullableDeviceType } from "../../device/types";
import { SecondaryButton } from "../../shared/components/Button";
import { Column } from "../../shared/components/Flex";
import * as serialInputForm from "../../shared/components/SerialInputForm";
import * as text from "../../shared/text";

import * as t from "../types";
import * as SC from "./StyledComponents";

export type SerialInputProps = t.DeviceConnectionModalContentProps & {
  deviceError?: string;
  initialSerial: string;
  selectDeviceType: a.SelectDeviceType;
  selectedDeviceType: NullableDeviceType;
  setSerial: (serial: string, deviceType: NullableDeviceType) => void;
};

export const SerialInput: React.FC<SerialInputProps> = ({
  deviceError,
  initialSerial,
  onStepCompleted,
  selectedDeviceType,
  selectDeviceType,
  setSerial,
}) => {
  useEffect(() => {
    if (selectedDeviceType !== PaxBle.Types.DeviceType.ERA_PRO) return;

    setSerial("", PaxBle.Types.DeviceType.ERA_PRO);
    onStepCompleted(t.DeviceConnectionStep.SERIAL_INPUT);
  }, [onStepCompleted, selectedDeviceType, setSerial]);

  let serialNumberPrompt = "Select a device type";

  if (selectedDeviceType) {
    serialNumberPrompt = `Please enter the serial number found on the back of your ${c.deviceTypeMap[selectedDeviceType]} device`;
  }

  const handleCancelOnPress = (): void => {
    selectDeviceType(null);
    onStepCompleted(t.DeviceConnectionStep.SERIAL_INPUT, true);
  };

  const handleSubmit = async (
    formValues: serialInputForm.FormValues
  ): Promise<void> => {
    const serial = formValues.serial?.toUpperCase() || "";
    setSerial(serial, selectedDeviceType);

    onStepCompleted && onStepCompleted(t.DeviceConnectionStep.SERIAL_INPUT);
  };

  const cancelButton = (
    <SecondaryButton
      type="button"
      data-testid="cancel-button"
      onClick={handleCancelOnPress}
    >
      Cancel
    </SecondaryButton>
  );

  const serialLocationAsset =
    c.getDeviceSerialLocationAsset(selectedDeviceType);

  return (
    <sc.Container data-testid="device-serial-input">
      <SC.Title>{text.ENTER_SERIAL_NUMBER}</SC.Title>
      {selectedDeviceType && (
        <>
          {serialLocationAsset && (
            <sc.Image src={serialLocationAsset} alt="PAX device" />
          )}
          <div>
            <sc.Text>{serialNumberPrompt}</sc.Text>
            <serialInputForm.SerialInputForm
              cancelButton={cancelButton}
              deviceError={deviceError}
              initialSerial={initialSerial}
              onSubmit={handleSubmit}
              selectedDeviceType={selectedDeviceType}
            />
          </div>
        </>
      )}
    </sc.Container>
  );
};

const sc = {
  Container: styled(Column)`
    align-items: center;
    justify-content: center;
    min-height: ${(props) => props.theme.connectionModal.minHeight};
    overflow-x: hidden;
    padding: ${(props) => props.theme.connectionModal.padding};
    width: ${(props) => props.theme.connectionModal.width};
  `,

  Image: styled.img`
    display: block;
    height: 259px;
    margin-bottom: 42px;
  `,

  Text: styled.div`
    font-size: 14px;
    line-height: 1.43;
    margin-bottom: 20px;
    text-align: center;
    width: 260px;
  `,
};
