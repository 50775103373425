import React from "react";
import * as reactQuery from "react-query";
import { useDispatch } from "react-redux";
import styled, { css } from "styled-components";

import * as consumerApi from "../../../api/consumer";
import { ChevronCard } from "../../../shared/components/ChevronCard";
import { PartnerLogo } from "../../../shared/components/PartnerLogo";
import * as text from "../../../shared/text";
import * as utils from "../../../shared/utils";

import * as SC from "../../styledComponents";

type StrainDetailsCardProps = {
  strain: consumerApi.types.StrainJson;
  useQuery?: () => reactQuery.UseQueryResult<consumerApi.types.connectedPods.ConnectedPodJson>;
};

export const StrainDetailsCard: React.FC<StrainDetailsCardProps> = ({
  strain,
  useQuery = reactQuery.useQuery,
  ...props
}) => {
  const dispatch = useDispatch();

  const { data: connectedPod } =
    useQuery<consumerApi.types.connectedPods.ConnectedPodJson>(
      consumerApi.paths.connectedPods.connectedPod(strain.id),
      {
        onSuccess: (connectedPod) => {
          dispatch(
            consumerApi.actions.connectedPods.receivedConnectedPod(connectedPod)
          );
        },
      }
    );

  return (
    <sc.Card {...props} hideChevron>
      <sc.Container>
        <sc.ParterLogo partner={strain.partner} />
        <sc.StrainDetails>
          <sc.StrainName bold>{strain.name}</sc.StrainName>
          <SC.Subtitle>{strain.partner.partnerName}</SC.Subtitle>
          {connectedPod && (
            <SC.Subtitle>
              {[
                text.LAST_USED,
                utils.getLastUsed(connectedPod.latestClientTimestamp),
              ].join(" ")}
            </SC.Subtitle>
          )}
        </sc.StrainDetails>
      </sc.Container>
    </sc.Card>
  );
};

const sc = {
  Card: styled(ChevronCard)`
    border: solid 1px var(--black-10);
    box-shadow: none;
    height: 102px;
    margin-top: 20px;
    width: calc(100% - 32px);

    ${utils.desktopBreakpoint(css`
      margin-top: 24px;
    `)}
  `,

  Container: styled.div`
    display: flex;
  `,

  ParterLogo: styled(PartnerLogo)`
    height: 56px;
    width: 56px;
  `,

  StrainDetails: styled.div`
    margin-left: 24px;
  `,

  StrainName: styled(SC.Subtitle)`
    max-width: 180px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `,
};
