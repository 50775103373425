import React, { useEffect } from "react";
import * as redux from "react-redux";
import { Link, useHistory } from "react-router-dom";
import styled, { css } from "styled-components";

import * as consumerApi from "../../api/consumer";
import { getUserLocation } from "../../api/consumer/selectors/user";
import { PlaaxButton } from "../../shared/components/Button";
import * as Text from "../../shared/components/Text";
import * as sharedHooks from "../../shared/hooks";
import * as text from "../../shared/text";
import { UseDispatch } from "../../shared/types";
import { capitalize, desktopBreakpoint } from "../../shared/utils";
import { SsoCountry } from "../../sso/constants";

import { resetStrainsFilter, setStrainsFilterAndRedirect } from "../actions";
import * as c from "../constants";
import * as hooks from "../hooks";
import * as SC from "../styledComponents";
import { OnStrainsFilterSet } from "../types";
import { getGeoStateLabel } from "../utils";
import { PodsFilterSidebar } from "./PodsFilter/PodsFilterSidebar";
import { PodsCarousel } from "./PodsCarousel";
import { PodsHomeLoadingPage } from "./PodsHomeLoadingPage";
import { PodsHomePageHeader } from "./PodsHomePageHeader";
import { PodsLocationInput } from "./PodsLocationInput";
import { PodsUnavailableInLocation } from "./PodsUnavailableInLocation";
import { ViewAllPodsInStateLink } from "./ViewAllPodsInStateLink";

export type PodsHomePageProps = {
  useDispatch?: UseDispatch;
  usePodsQuery: hooks.UseStrainsForLocation;
  useRecentlyViewedPods: hooks.UseRecentlyViewedPods;
  useDesktopMediaQuery?: sharedHooks.UseDesktopMediaQuery;
  useUpsellPodsQuery?: hooks.UseUpsellPodsQuery;
};

export const PodsHomePage: React.FC<PodsHomePageProps> = ({
  useDispatch = redux.useDispatch,
  usePodsQuery = hooks.useStrainsForLocation,
  useRecentlyViewedPods = hooks.useRecentlyViewedPods,
  useDesktopMediaQuery = sharedHooks.useDesktopMediaQuery,
  useUpsellPodsQuery = hooks.useUpsellPodsQuery,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isDesktop = useDesktopMediaQuery();
  const podsPerCarousel = hooks.usePodsPerCarousel();
  const location = redux.useSelector(getUserLocation);

  // Ensure explore pods is scrolled to top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch(resetStrainsFilter());
  }, [dispatch]);

  const { data: topPods, isLoading: topPodsAreLoading } = useUpsellPodsQuery(
    consumerApi.paths.strains.topStrains(location?.isoCode || "")
  );

  const {
    data: recommendForYouPods,
    isLoading: recommendForYouIsLoading,
    metaData: recommendedForYouMetaData,
  } = useUpsellPodsQuery(
    consumerApi.paths.strains.recommendedForYou(location?.isoCode || "")
  );

  const { data: recentlyViewedPods, isLoading: recentlyViewedPodsAreLoading } =
    useRecentlyViewedPods();

  const { data: indicaPods, isLoading: indicaPodsAreLoading } = usePodsQuery(
    { strainClassifications: [c.INDICA] },
    podsPerCarousel
  );

  const { data: sativaPods, isLoading: sativaPodsAreLoading } = usePodsQuery(
    { strainClassifications: [c.SATIVA] },
    podsPerCarousel
  );

  const { data: hybridPods, isLoading: hybridPodsAreLoading } = usePodsQuery(
    { strainClassifications: [c.HYBRID] },
    podsPerCarousel
  );

  const { data: cbdPods, isLoading: cbdPodsAreLoading } = usePodsQuery(
    { strainClassifications: [c.CBD] },
    podsPerCarousel
  );

  const podsAreLoading =
    topPodsAreLoading ||
    recommendForYouIsLoading ||
    recentlyViewedPodsAreLoading ||
    indicaPodsAreLoading ||
    sativaPodsAreLoading ||
    hybridPodsAreLoading ||
    cbdPodsAreLoading;

  if (podsAreLoading) {
    return <PodsHomeLoadingPage />;
  }

  let titleLocation = "";
  if (location?.isoCode) {
    titleLocation = getGeoStateLabel(location?.isoCode);
  } else if (location?.region === SsoCountry.US) {
    titleLocation = text.THE_UNITED_STATES;
  } else if (location?.region === SsoCountry.CANADA) {
    titleLocation = capitalize(location.region);
  }

  const hasPodsOtherThanRecentlyViewed =
    topPods.length !== 0 ||
    recommendForYouPods.length !== 0 ||
    indicaPods?.count !== 0 ||
    sativaPods?.count !== 0 ||
    hybridPods?.count !== 0 ||
    cbdPods?.count !== 0;

  const podsUnavailableInLocation =
    !hasPodsOtherThanRecentlyViewed && !podsAreLoading;

  const handleStrainsFilterSet: OnStrainsFilterSet = (filter) => {
    dispatch(setStrainsFilterAndRedirect(history, filter));
  };

  return (
    <sc.ExplorePage>
      <PodsHomePageHeader />
      <PodsLocationInput />
      <sc.PodsHomeContentContainer>
        {isDesktop && (
          <PodsFilterSidebar
            disableWhenEmpty
            onStrainsFilterSet={handleStrainsFilterSet}
          />
        )}
        <SC.PodsCarouselContainer>
          <sc.PodsCarousel
            isTopPod
            key="top-pods"
            strains={topPods}
            title={text.POPULAR_PODS_THIS_MONTH}
          />
          <sc.PodsCarousel
            isRecommendedForYou
            key="recommended-for-you-pods"
            metaData={recommendedForYouMetaData}
            strains={recommendForYouPods}
            title={text.RECOMMENDED_FOR_YOU_TITLE(
              recommendedForYouMetaData?.recommendationSource,
              getGeoStateLabel(location?.isoCode)
            )}
          />
          <sc.PodsCarousel
            isRecentlyViewed
            key="recently-viewed-pods"
            strains={recentlyViewedPods}
            title={text.RECENTLY_VIEWED_PODS}
          />
          <sc.PodsCarousel
            filterPartial={{ strainClassifications: [c.INDICA] }}
            key="indica-pods"
            shouldShowViewAll={!!indicaPods?.next}
            strains={indicaPods?.results || []}
            title={text.indicaPodsInLocation(titleLocation)}
          />
          <sc.PodsCarousel
            filterPartial={{ strainClassifications: [c.SATIVA] }}
            key="sativa-pods"
            shouldShowViewAll={!!sativaPods?.next}
            strains={sativaPods?.results || []}
            title={text.sativaPodsInLocation(titleLocation)}
          />
          <sc.PodsCarousel
            filterPartial={{ strainClassifications: [c.HYBRID] }}
            key="hybrid-pods"
            shouldShowViewAll={!!hybridPods?.next}
            strains={hybridPods?.results || []}
            title={text.hybridPodsInLocation(titleLocation)}
          />
          <sc.PodsCarousel
            filterPartial={{ strainClassifications: [c.CBD] }}
            key="cbd-pods"
            shouldShowViewAll={!!cbdPods?.next}
            strains={cbdPods?.results || []}
            title={text.cbdPodsInLocation(titleLocation)}
          />
          {podsUnavailableInLocation ? (
            <PodsUnavailableInLocation textHelper={text.noPodsInYourArea} />
          ) : (
            <ViewAllPodsInStateLink />
          )}
        </SC.PodsCarouselContainer>
      </sc.PodsHomeContentContainer>
    </sc.ExplorePage>
  );
};

const rowStyles = css`
  margin-bottom: 20px;

  ${desktopBreakpoint(
    css`
      margin-bottom: 32px;
    `
  )}
`;

const sc = {
  ExplorePage: styled(SC.Page)`
    padding-bottom: 14px;
    width: 100vw;

    ${desktopBreakpoint(
      css`
        margin-top: 4px;
        padding: 0 16px;
        position: relative;
        width: 100%;
      `
    )}
  `,

  PodsCarousel: styled((props) => <PodsCarousel {...props} />)`
    ${rowStyles}
  `,

  PodsHomeContentContainer: styled(SC.PodsHomeContentContainer)`
    padding-left: 1px;
  `,

  ViewAllPodsButton: styled(PlaaxButton)`
    align-self: center;
  `,

  ViewAllPodsLink: styled(Link)`
    display: block;
    text-align: center;
  `,

  ViewAllPodsText: styled.div`
    ${Text.PlaaxLight}
    font-size: 16px;
    margin: 40px auto;
  `,
};
