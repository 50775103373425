import { connect } from "react-redux";

import { MainAppState } from "../../main/types";

import * as firmwareUpdateAvailableModalContent from "../components/FirmwareUpdateAvailableModalContent";
import { getLatestFirmwareVersion } from "../selectors";
import { FirmwareModalContentProps } from "../types";

const mapStateToProps = (
  state: MainAppState,
  firmwareModalContentProps: FirmwareModalContentProps
): firmwareUpdateAvailableModalContent.StateProps => ({
  ...firmwareModalContentProps,
  latestFirmwareVersion: getLatestFirmwareVersion(state),
});

export default connect(mapStateToProps)(
  firmwareUpdateAvailableModalContent.FirmwareUpdateAvailableModalContent
);
