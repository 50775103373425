import { encode } from "cborg";

import McuManagerBleTransport, {
  MAX_PACKET_LENGTH,
} from "../ble/McuManagerBleTransport";
import { McuManagerResponseType } from "../ble/McuManagerResponse";
import { getSha256Digest } from "../crypto";
import Upload, { UploadCallback, UploadResponse } from "../transfer/Upload";
import McuManager from "./McuManager";

const GROUP_IMAGE = 1;
const ID_STATE = 0;
const ID_UPLOAD = 1;

export type ImageSlot = {
  // The slot number: 0 or 1.
  slot: number;

  // The image version string.
  version: string;

  // The image hash.
  hash: Uint8Array;

  // An image is bootable when the Boot Loader verified that the image is valid.
  bootable: boolean;

  // An image is pending when it was scheduled to be swapped to slot 0.
  // That is, after the `test` or `confirm` commands were sent, but before
  // the device was reset.
  pending: boolean;

  // An image is confirmed when it managed to boot on slot 0.
  confirmed: boolean;

  // An image is active when it is running on slot 0.
  active: boolean;

  // An image is permanent after it was confirmed using <i>confirm</i> command.
  permanent: boolean;
};

export type ImageManagerListResponse = McuManagerResponseType & {
  images: ImageSlot[];
  splitStatus: number;
};

const TRUNCATED_HASH_LEN = 3;

export default class ImageManager extends McuManager {
  constructor(transport: McuManagerBleTransport) {
    super(GROUP_IMAGE, transport);
  }

  async list(): Promise<ImageManagerListResponse> {
    const response = (await this.queueOperation(
      McuManager.OP_READ,
      0,
      0,
      ID_STATE
    )) as ImageManagerListResponse;
    return response;
  }

  async uploadImage(
    imageData: Uint8Array,
    callback: UploadCallback
  ): Promise<void> {
    const upload = new Upload(imageData, this.sendPacket);

    while (!upload.isFinished()) {
      try {
        await upload.sendNext();

        callback.onUploadProgressChanged(
          upload.offset,
          upload.data.length,
          Date.now()
        );
      } catch (e) {
        return callback.onUploadFailed(e as Error);
      }
    }

    return callback.onUploadCompleted();
  }

  sendPacket = async (
    data: Uint8Array,
    offset: number
  ): Promise<UploadResponse> => {
    const payloadMap: ImageUploadPayload = await this.buildUploadPayload(
      data,
      offset
    );

    const response = (await this.queueOperation(
      McuManager.OP_WRITE,
      0,
      0,
      ID_UPLOAD,
      payloadMap
    )) as UploadResponse;

    return response;
  };

  async buildUploadPayload(
    data: Uint8Array,
    offset: number
  ): Promise<ImageUploadPayload> {
    // Get chunk of image data to send
    const dataLength = Math.min(
      MAX_PACKET_LENGTH - this.calculatePacketOverhead(data, offset),
      data.length - offset
    );
    const sendBuffer = data.slice(offset, offset + dataLength);

    const payloadMap: ImageUploadPayload = {
      data: sendBuffer,
      off: offset,
    };

    if (offset === 0) {
      // Only send the length of the image in the first packet of the upload
      payloadMap.len = data.length;

      // The device keeps track of unfinished uploads based on the SHA256 hash
      // over the image data. When an upload request is received which contains
      // the same hash of a partially finished upload, the device will send the
      // offset to continue from. The hash is truncated to save packet space.
      const hash = await getSha256Digest(data);
      payloadMap.sha = new Uint8Array(hash).slice(0, TRUNCATED_HASH_LEN);
    }

    return payloadMap;
  }

  /**
   * Test an image on the device.
   * <p>
   * Testing an image will verify the image and put it in a pending state. That is, when the
   * device resets, the pending image will be booted into.
   *
   * @param hash the hash of the image to test.
   */
  async test(hash: Uint8Array): Promise<ImageManagerListResponse> {
    const payloadMap = { confirm: false, hash };
    const response = (await this.queueOperation(
      McuManager.OP_WRITE,
      0,
      0,
      ID_STATE,
      payloadMap
    )) as ImageManagerListResponse;

    return response;
  }

  /**
   * Confirm an image on the device.
   * Confirming an image will make it the default to boot into.
   * If hash is not provided, the current image running on the device is made permanent.
   */
  async confirm(
    hash: Uint8Array | undefined
  ): Promise<ImageManagerListResponse> {
    const payloadMap = { confirm: true, hash };
    if (!hash) delete payloadMap.hash;

    const response = (await this.queueOperation(
      McuManager.OP_WRITE,
      0,
      0,
      ID_STATE,
      payloadMap
    )) as ImageManagerListResponse;

    return response;
  }

  calculatePacketOverhead(data: Uint8Array, offset: number): number {
    const overheadTestMap: ImageUploadPayload = {
      data: new Uint8Array(),
      off: offset,
    };

    if (offset === 0) {
      overheadTestMap.len = data.length;
      overheadTestMap.sha = new Uint8Array(TRUNCATED_HASH_LEN);
    }

    const cborData: Uint8Array = encode(overheadTestMap);

    // 8 bytes for McuMgr header, 2 bytes for data length.
    return cborData.length + 8 + 2;
  }
}

type ImageUploadPayload = {
  data: Uint8Array;
  off: number;
  len?: number;
  sha?: Uint8Array;
};
