import { connect } from "react-redux";

import { MainAppState } from "../../main/types";

import { forgotPassword } from "../actions";
import { SsoResetPasswordEmailSentPage } from "../components/SsoResetPasswordEmailSentPage";

const mapStateToProps = (state: MainAppState) => {
  return state.sso.forgotPassword;
};

const mapDispatchToProps = {
  forgotPassword,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SsoResetPasswordEmailSentPage);
