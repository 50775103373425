import McuManagerImageTlv from "./tlv/McuManagerImageTlv";
import McuManagerImageHeader from "./McuManagerImageHeader";

// Represents a firmware image for devices using McuBoot or the legacy Apache Mynewt bootloader.
// On initialization, the image data will be validated.
// A firmware image of this format contains an image header and type-length-value trailer for image metadata.
export default class McuManagerImage {
  imageData: Uint8Array;
  header: McuManagerImageHeader;
  tlv: McuManagerImageTlv;
  hash: Uint8Array;

  static getHash(imageData: Uint8Array): Uint8Array {
    return new McuManagerImage(imageData).hash;
  }

  constructor(imageData: Uint8Array) {
    this.imageData = imageData;
    this.header = McuManagerImageHeader.fromBytes(imageData);
    this.tlv = McuManagerImageTlv.fromBytes(imageData, this.header);
    this.hash = this.tlv.getHash();

    if (!this.hash) {
      throw new Error("Image TLV trailer does not contain an image hash!");
    }
  }
}
