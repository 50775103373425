import { connect } from "react-redux";

import {
  getDeviceSerialNumber,
  getSelectedDeviceType,
} from "../../device/selectors";
import * as t from "../../main/types";

import { ShakeInstructions } from "../components/ShakeInstructions";

const mapStateToProps = (state: t.MainAppState) => {
  const deviceType = getSelectedDeviceType(state);
  const serial = getDeviceSerialNumber(state, deviceType) || "";

  return {
    deviceType,
    serial,
  };
};

export default connect(mapStateToProps)(ShakeInstructions);
