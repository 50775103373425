import React from "react";
import styled from "styled-components";

import * as Strain from "../../api/consumer/models/Strain";
import * as t from "../../api/consumer/types";

import * as Text from "./Text";

export type StrainCompositionProps = {
  ingredient: t.StrainIngredient;
  isMobileDevicePage?: boolean;
  strain: t.StrainJson;
};

export const StrainComposition: React.FC<StrainCompositionProps> = ({
  ingredient,
  isMobileDevicePage,
  strain,
}) => {
  const ingredientValue = Strain.getIngredientPercentageOrRangeString(
    strain,
    ingredient
  );

  if (isMobileDevicePage) {
    return (
      <sc.MobileComposition data-testid={`strain-${ingredient}`}>
        {ingredient} <span>{ingredientValue}</span>
      </sc.MobileComposition>
    );
  }

  return (
    <sc.Composition data-testid={`strain-${ingredient}`}>
      {ingredientValue} <span>{ingredient}</span>
    </sc.Composition>
  );
};

const sc = {
  Composition: styled.div`
    ${Text.PlaaxLight}
    font-size: 16px;
    text-transform: uppercase;
  `,

  MobileComposition: styled.div`
    ${Text.PlaaxLight}
    display: flex;
    font-size: 16px;
    justify-content: space-around;
    text-transform: uppercase;

    & span {
      color: #717171;
    }
  `,
};
