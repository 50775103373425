import { connect } from "react-redux";

import { MainAppState } from "../../main/types";

import * as modalActions from "../actions";
import * as privacyPolicyModal from "../components/PrivacyPolicyModal";
import { getIsPrivacyPolicyModalOpen } from "../selectors";

const mapStateToProps = (
  state: MainAppState
): privacyPolicyModal.StateProps => ({
  isPrivacyPolicyModalOpen: getIsPrivacyPolicyModalOpen(state),
});

const mapDispatchToProps: privacyPolicyModal.DispatchProps = {
  closePrivacyPolicyModal: modalActions.closePrivacyPolicyModal,
  setHasAcceptedPrivacyPolicy: modalActions.setHasAcceptedPrivacyPolicy,
};

export const PrivacyPolicyModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(privacyPolicyModal.PrivacyPolicyModal);
