import * as reviewTypes from "../../api/consumer/types/reviews";
import * as strainTypes from "../../api/consumer/types/strain";
import { AppThunk } from "../../shared/types";

import { trackEventAction } from "../actions";

const EVENT_NAME = "recommended_pod";

type RecommendedPodEventProperties = {
  isClicked?: boolean;
  recommendationType?: reviewTypes.RecommendationType;
  recommendedStrainId?: string;
  reviewedRating: reviewTypes.StrainRating;
  reviewedStrainId: string;
};

type TrackRecommendedPod = (
  podReview: reviewTypes.StrainReviewJson,
  recommendedStrainId?: string
) => AppThunk;

type RecommendedForYouPodEventProperties = {
  popularPodsNearbyAvailable?: boolean;
  recommendationType?: strainTypes.StrainMetaDataRecommendationSource;
  recommendedStrainId?: string;
  recommendationId?: string;
  isClicked: boolean;
};

type TrackRecommendedForYouPod = ({
  isClicked,
  metaData,
  recommendedStrainId,
}: {
  isClicked: boolean;
  metaData: strainTypes.StrainMetaDataJson;
  recommendedStrainId: string;
}) => AppThunk;

export const trackRecommendedPodDisplayed: TrackRecommendedPod =
  (podReview, recommendedStrainId) =>
  (dispatch, getState): void => {
    trackEventAction<RecommendedPodEventProperties>(EVENT_NAME, {
      isClicked: false,
      recommendationType: podReview.recommendationType || undefined,
      recommendedStrainId,
      reviewedRating: podReview.rating,
      reviewedStrainId: podReview.strainId,
    })(dispatch, getState, null);
  };

export const trackRecommendedPodClicked: TrackRecommendedPod =
  (podReview, recommendedStrainId) =>
  (dispatch, getState): void => {
    trackEventAction<RecommendedPodEventProperties>(EVENT_NAME, {
      isClicked: true,
      recommendationType: podReview.recommendationType || undefined,
      recommendedStrainId,
      reviewedRating: podReview.rating,
      reviewedStrainId: podReview.strainId,
    })(dispatch, getState, null);
  };

export const trackRecommendedForYouPod: TrackRecommendedForYouPod =
  ({ isClicked, metaData, recommendedStrainId }) =>
  (dispatch, getState): void => {
    trackEventAction<RecommendedForYouPodEventProperties>(EVENT_NAME, {
      isClicked,
      popularPodsNearbyAvailable: metaData.popularPodsNearbyAvailable,
      recommendationId: metaData.recommendationId,
      recommendationType: metaData.recommendationSource,
      recommendedStrainId,
    })(dispatch, getState, null);
  };
