// TODO: remove use of any
/* eslint-disable @typescript-eslint/no-explicit-any */
import { decode } from "cborg";

import * as c from "../constants";
import SmpMerger from "./SmpMerger";

export default class CharacteristicChangedPacketHandler {
  onSuccess: (value: any) => any;
  onError: (error: Error) => any;
  merger: SmpMerger;

  constructor(resolve: (value: any) => any, reject: (error: Error) => any) {
    this.onSuccess = resolve;
    this.onError = reject;
    this.merger = new SmpMerger();
  }

  async onResponsePacket(packet: Uint8Array): Promise<void> {
    this.merger.merge(packet);

    if (!this.hasAllPackets()) {
      return;
    }

    const payload = this.merger.buffer.slice(
      c.HEADER_LENGTH,
      this.merger.buffer.length
    );

    const result = await decode(payload);

    this.onSuccess(result);
  }

  hasAllPackets(): boolean {
    return this.merger.hasAllPackets();
  }
}
