import React, { useState } from "react";
import styled, { css } from "styled-components";

import * as apiTypes from "../../api/consumer/types/user";
import { TEMPERATURE_UNITS } from "../../data/temperatureUnits";
import { Modal, ModalProps } from "../../modal/components/Modal";
import Button from "../../shared/components/Button";
import { Dropdown } from "../../shared/components/Dropdown";
import { Column } from "../../shared/components/Flex";
import { Pencil } from "../../shared/components/Icons";
import * as text from "../../shared/text";
import { DropdownOption } from "../../shared/types";
import { desktopBreakpoint } from "../../shared/utils";

type PreferencesModalProps = ModalProps & {
  updateUser: (updateUser: apiTypes.UpdateUser) => void;
  userProfile?: apiTypes.Profile;
};

export const PreferencesModal: React.FC<PreferencesModalProps> = ({
  isOpen,
  onRequestClose,
  ...props
}) => {
  const modalProps = {
    isOpen,
    onRequestClose,
  };
  return (
    <Modal {...modalProps}>
      <PreferencesModalContent {...modalProps} {...props} />
    </Modal>
  );
};

const PreferencesModalContent: React.FC<PreferencesModalProps> = ({
  onRequestClose,
  updateUser,
  userProfile,
}) => {
  const temperatureUnitOptions: DropdownOption[] = Object.entries(
    TEMPERATURE_UNITS
  )
    .map(([key, unit]) => ({
      label: unit.label,
      value: key,
    }))
    .sort((a, b) => (a.label > b.label ? 1 : -1));

  const initialTemperatureUnitSelection = temperatureUnitOptions.find(
    (unit) => unit.value === userProfile?.preferredTemperatureUnit
  );

  const [selectedTemperatureUnitOption, setSelectedTemperatureUnitOption] =
    useState<DropdownOption | undefined>(initialTemperatureUnitSelection);

  const handleTemperatureUnitChange = (option: DropdownOption): void => {
    setSelectedTemperatureUnitOption(option);
  };

  const handleApplyClick = (): void => {
    const updatedPreferences: apiTypes.UpdateUser = {
      profile: {
        ...userProfile,
        preferredTemperatureUnit:
          selectedTemperatureUnitOption?.value as apiTypes.PreferredTemperatureUnit,
      },
    };

    updateUser(updatedPreferences);
    if (onRequestClose) onRequestClose();
  };

  return (
    <sc.Container>
      <sc.EditIcon>
        <Pencil />
      </sc.EditIcon>
      <sc.Title>{text.ACCOUNT_PREFERENCES}</sc.Title>
      {/* TODO: Icons for dropdowns from designs */}
      <sc.InfoContainer>
        <sc.InfoLabel>{text.TEMPERATURE_UNIT}:</sc.InfoLabel>
        <Dropdown
          data-testid="temperature-dropdown"
          onChange={handleTemperatureUnitChange}
          options={temperatureUnitOptions}
          placeholder={"Choose a Temperature Unit"}
          value={selectedTemperatureUnitOption}
        />
      </sc.InfoContainer>
      <sc.Button data-testid="apply-button" onClick={handleApplyClick}>
        {text.APPLY}
      </sc.Button>
    </sc.Container>
  );
};

const sc = {
  Button: styled(Button)`
    margin-top: 40px;
  `,

  Container: styled.div`
    align-items: center;
    background-color: var(--white);
    border-radius: 8px;
    box-shadow: 0 1px 12px 0 var(--bluey-grey-80);
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-top: 45px;
    width: 100%;

    ${desktopBreakpoint(
      css`
        height: 330px;
        justify-content: center;
        padding-top: 0;
        width: var(--default-modal-width);
      `
    )}
  `,

  EditIcon: styled.div`
    border: solid 1px var(--black);
    border-radius: 32px;
    height: 48px;
    width: 48px;

    & svg {
      height: 20px;
      margin: 12px;
      width: 20px;
    }
  `,

  InfoContainer: styled(Column)`
    justify-content: space-between;
    max-width: 340px;
    padding: 0 16px;
    width: 100%;

    ${desktopBreakpoint(
      css`
        max-width: none;
        width: 340px;
      `
    )}
  `,

  InfoLabel: styled.div`
    font-size: 16px;
    line-height: 1.13;
    margin: 16px 0 12px;
  `,

  Title: styled.div`
    font-size: 18px;
    margin-top: 40px;
    max-width: 340px;
    padding-left: 16px;
    text-transform: uppercase;
    width: 100%;

    ${desktopBreakpoint(
      css`
        max-width: none;
        width: 340px;
      `
    )}
  `,
};
