import { useEffect, useState } from "react";

import { StrainReviewJson } from "../api/consumer/types/reviews";
import { isEmptyArray } from "../shared/utils";
import * as c from "./constants";

type UsePodFeedbackFormController = (formValues: Partial<StrainReviewJson>) => {
  shouldShowEffects: boolean;
  shouldShowNotesAndSubmit: boolean;
  skipEffects: () => void;
};

export const usePodFeedbackFormController: UsePodFeedbackFormController = (
  formValues
) => {
  const [shouldShowEffects, setShouldShowEffects] = useState<boolean>(
    !!formValues.rating
  );
  const [shouldShowNotesAndSubmit, setShouldShowNotesAndSubmit] =
    useState<boolean>(false);

  useEffect(() => {
    if (
      (!formValues.selectedEffects ||
        isEmptyArray(formValues.selectedEffects)) &&
      !formValues.text
    )
      return;

    setShouldShowNotesAndSubmit(true);
  }, [formValues.selectedEffects, formValues.text]);

  useEffect(() => {
    setShouldShowEffects(!!formValues.rating);
  }, [formValues.rating]);

  return {
    shouldShowEffects,
    shouldShowNotesAndSubmit,
    skipEffects: () => setShouldShowNotesAndSubmit(true),
  };
};

type UsePodFeedbackModalController = (isModalOpen: boolean) => {
  contentOpacity: number;
  desktopWidth: number;
  shouldShowThankYouPage: boolean;
  showThankYouPage: () => void;
};

export const usePodFeedbackModalController: UsePodFeedbackModalController = (
  isModalOpen
) => {
  const [contentOpacity, setContentOpacity] = useState<number>(0);
  const [desktopWidth, setDesktopWidth] = useState<number>(0);
  const [shouldShowThankYouPage, setShouldShowThankYouPage] =
    useState<boolean>(false);

  useEffect(() => {
    if (isModalOpen) {
      setDesktopWidth(c.MODAL_WIDTH);
      const timeout = setTimeout(() => {
        setContentOpacity(1);
      }, c.MODAL_TRANSITION_DURATION);

      return () => clearTimeout(timeout);
    }

    setDesktopWidth(0);
    setContentOpacity(0);
  }, [isModalOpen]);

  return {
    contentOpacity,
    desktopWidth,
    shouldShowThankYouPage,
    showThankYouPage: () => setShouldShowThankYouPage(true),
  };
};
