import React from "react";
import styled, { css } from "styled-components";

import Button, { SecondaryButton } from "../../shared/components/Button";
import { Column, Row } from "../../shared/components/Flex";
import * as Text from "../../shared/components/Text";
import { desktopBreakpoint } from "../../shared/utils";

import { FirmwareModalContentProps, FirmwareStep } from "../types";

export type StateProps = {
  latestFirmwareVersion: string;
};

type FirmwareUpdateAvailableModalContentProps = FirmwareModalContentProps &
  StateProps;

export const FirmwareUpdateAvailableModalContent: React.FC<FirmwareUpdateAvailableModalContentProps> =
  ({ latestFirmwareVersion, onStepCompleted }) => {
    const handleButtonClick = (shouldSkip: boolean): void => {
      onStepCompleted(FirmwareStep.UPDATE_AVAILABLE, shouldSkip);
    };

    return (
      <sc.Container>
        <sc.Title data-testid="firmware-update-available-title">
          Update Available
        </sc.Title>
        <sc.TextContainer>
          <sc.Subtitle>
            New firmware <Text.Bold>{latestFirmwareVersion}</Text.Bold> update
            is available! Would you like to update now?
          </sc.Subtitle>
          <sc.Subtitle>
            <Text.Bold>This update includes:</Text.Bold>
          </sc.Subtitle>
          <sc.Features>
            <span>• Battery and performance improvements</span>
            <span>• Bug fixes</span>
          </sc.Features>
        </sc.TextContainer>
        <sc.ButtonContainer>
          <SecondaryButton
            data-testid="skip-button"
            onClick={(): void => handleButtonClick(true)}
          >
            Skip
          </SecondaryButton>
          <Button
            data-testid="update-button"
            onClick={(): void => handleButtonClick(false)}
          >
            Update
          </Button>
        </sc.ButtonContainer>
      </sc.Container>
    );
  };

const sc = {
  ButtonContainer: styled(Row)`
    font-size: 16px;
    margin-bottom: 60px;
    margin-top: 130px;

    > button:first-child {
      margin-right: 60px;
    }

    ${desktopBreakpoint(
      css`
        margin-top: 166px;
      `
    )}
  `,

  Container: styled(Column)`
    align-items: center;
    justify-content: center;
    margin: auto;
    max-width: 424px;
    padding: 0 40px;

    ${desktopBreakpoint(
      css`
        height: 588px;
        max-width: none;
        width: 424px;
      `
    )}
  `,

  Features: styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 20px;
  `,

  Subtitle: styled.div`
    ${Text.PlaaxLight}
    color: var(--black);
    line-height: 1.25;
    margin-top: 20px;
    text-align: left;
  `,

  TextContainer: styled.div`
    font-size: 18px;
    height: 200px;
    margin-top: 32px;
  `,

  Title: styled.div`
    ${Text.Plaax}
    font-size: 24px;
    margin-top: 60px;
    text-transform: uppercase;
  `,
};
