import React from "react";
import { useSelector } from "react-redux";

import { getDeviceName } from "../../device/selectors";
import * as text from "../../shared/text";

import * as SC from "../styledComponents";

export const SyncingNotification: React.FC = () => {
  const deviceName = useSelector(getDeviceName);

  return (
    <SC.Notification>
      <SC.Title>{text.DEVICE_SYNCING}</SC.Title>
      <SC.SpinnerRow>
        <SC.SimpleMessage>
          {deviceName} {text.IS_SYNCING}…
        </SC.SimpleMessage>
        <SC.Spinner />
      </SC.SpinnerRow>
    </SC.Notification>
  );
};
