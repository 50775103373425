import React from "react";
import styled from "styled-components";

import { SafetyTestResultJson } from "../../api/consumer/types/strain";
import { Row } from "../../shared/components/Flex";
import * as Text from "../../shared/components/Text";

import { BigCard } from "./PodsTestResultsCard";

export type PodsSafetyTestsCardProps = {
  tests: SafetyTestResultJson[];
};

const PodsSafetyTestsCard: React.FC<PodsSafetyTestsCardProps> = ({ tests }) => {
  if (tests.length === 0) {
    return null;
  }

  tests.sort(function (a, b) {
    return Number(a.hasPassed) - Number(b.hasPassed);
  });

  return (
    <BigCard data-testid="pods-safety-tests-card" borderStyle="dotted">
      <sc.Title>Safety Tests</sc.Title>
      <sc.Description>
        Tests vary by state but typically screen for heavy metals, pesticides,
        mycotoxins, residual solvents or other microbial contaminants.
      </sc.Description>
      {tests.slice(0, 4).map(({ testName, hasPassed }, index) => (
        <Row justifyContent="space-between" key={testName}>
          <sc.TestName data-testid={`test-${index}`}>
            {testName.toLowerCase().replace(/_/g, " ")}
          </sc.TestName>
          <sc.TestResult
            data-testid={`test-status-${index}`}
            isPass={hasPassed}
          >
            {hasPassed ? "Pass" : "Fail"}
          </sc.TestResult>
        </Row>
      ))}
    </BigCard>
  );
};

type HighlighterProps = {
  isPass: boolean;
};

const sc = {
  Description: styled.p`
    ${Text.PlaaxLight}
    color: var(--black);
    font-size: 14px;
    line-height: 1;
    margin: 0 0 12px;
    max-width: var(--pod-test-result-content-width);
    text-align: left;
  `,

  TestName: styled.div`
    color: var(--black);
    display: inline-block;
    font-size: 16px;
    height: 14px;
    margin-bottom: 8px;
    text-transform: capitalize;
  `,

  TestResult: styled.div<HighlighterProps>`
    ${Text.PlaaxLight}
    color: ${(props) =>
      props.isPass ? "var(--teal-green)" : "var(--pale-red)"};
    display: inline-block;
    font-size: 16px;
    height: 14px;
    margin-bottom: 8px;
    text-transform: uppercase;
  `,

  Title: styled.div`
    color: var(--black);
    font-size: 20px;
    line-height: 0.8;
    margin-bottom: 10px;
    text-transform: uppercase;
  `,
};

export default PodsSafetyTestsCard;
