import Bugsnag from "@bugsnag/js";
import { set as lsSet } from "local-storage";

import * as c from "../constants";
import { BaseLogStore, LogEventAnalyticsType } from "../shared/BaseLogStore";
import * as t from "../types";
import { isPuffEventLog } from "../types/EraPro";
import {
  getEraProLogRequestFromLocalStorage,
  getLogStoreAnalyticsData,
} from "../utils";
import { EraProDevice } from "./EraProDevice";
import { EraProDeviceEncryption } from "./EraProDeviceEncryption";

const DEFAULT_LOG_REQUEST_OPTIONS = { hasReceivedTimeSync: false, offset: 0 };

const LOG_EVENT_OFFSETS = {
  DATA: 15,
  DATA_BYTE_COUNT: 14,
  INDEX: 10,
  TIME: 2,
  TYPE: 0,
};

export type EraProLogRequestOptions = {
  offset: number;
  hasReceivedTimeSync: boolean;
};

export class EraProLogStore extends BaseLogStore {
  encryption?: EraProDeviceEncryption;
  logNotify?: BluetoothRemoteGATTCharacteristic;
  options: EraProLogRequestOptions;
  activeLogFetchesMap: { [key: number]: boolean } = {};

  constructor(device: EraProDevice, encryption?: EraProDeviceEncryption) {
    super(device);
    this.encryption = encryption;
    this.options = this.getLogRequestOptionsFromLocalStorage();
  }

  async initialize(): Promise<void> {
    const logService = await this.getLogService(c.ERA_PRO_LOG_SERVICE_UUID);

    this.logNotify = await logService.getCharacteristic(
      c.ERA_PRO_CHARACTERISTICS.LogNotify
    );

    this.logNotify.addEventListener(
      "characteristicvaluechanged",
      this.handleLogsReceived
    );

    await this.logNotify.startNotifications();
  }

  disconnect(): void {
    this.logNotify?.removeEventListener(
      "characteristicvaluechanged",
      this.handleLogsReceived
    );
  }

  // Log requests for ERA PRO devices require an offset.
  // The offset is the eventIndex of a log event.
  // If no log event exists for the given offset, an empty buffer is returned.
  fetchLogs = async (): Promise<void> => {
    if (
      !this.device.connected ||
      !this.shouldFetchLogs ||
      this.encryption?.hasMaxConsecutiveDecryptionErrors
    )
      return;

    if (this.activeLogFetchesMap[this.options.offset]) return;
    this.activeLogFetchesMap[this.options.offset] = true;

    try {
      this.device.writeAttribute(c.ATTRIBUTE_LOG_REQUEST, this.options.offset);
    } catch (err) {
      Bugsnag.notify(err as Error);
    }
  };

  handleLogsReceived = (event: Event): void => {
    if (!event || !event.target) {
      return;
    }

    const characteristic = event.target as BluetoothRemoteGATTCharacteristic;
    if (!characteristic.value) {
      return;
    }

    const buffer = new Uint8Array(characteristic.value.buffer);

    if (buffer.length === 0) {
      return;
    }

    this.device.events.emit("onLogReceived");

    try {
      this.parseLogEvents(buffer);
    } catch (err) {
      Bugsnag.notify(err as Error);
    }
  };

  parseLogEvents = (buffer: Uint8Array): void => {
    /***************************** Log Event Format *****************************
     *  code   *     time     *   index   *   count  *     value     *   crc    *
     * 2 bytes *    8 bytes   *  4 bytes  *  1 byte  * {count} bytes *  1 byte  *
     ****************************************************************************/

    if (this.encryption) {
      try {
        const initialErrorCount =
          this.encryption.consecutiveDecryptionErrorCount;

        const { decryptedPacket, updatedErrorCount } =
          this.encryption.getDecryptedPacket(buffer);

        if (updatedErrorCount > initialErrorCount) {
          // Ignore the bad decrypted packet and just update the offset.
          this.options.offset = this.options.offset + 2;
          return;
        } else {
          buffer = decryptedPacket;
        }
      } catch (err) {
        Bugsnag.notify(err as Error);
        this.device.events.emit("onConsecutiveDecryptionErrors");
        return;
      }
    }

    while (buffer.length > 0) {
      try {
        const view: DataView = new DataView(buffer.buffer);
        const dataByteCount = view.getUint8(LOG_EVENT_OFFSETS.DATA_BYTE_COUNT);
        const eventBufferLength = LOG_EVENT_OFFSETS.DATA + dataByteCount + 1;
        const eventTime = view.getBigUint64(LOG_EVENT_OFFSETS.TIME, true);
        const eventValueBuffer = buffer.slice(
          LOG_EVENT_OFFSETS.DATA,
          LOG_EVENT_OFFSETS.DATA + dataByteCount
        );
        const eventIndex = view.getUint32(LOG_EVENT_OFFSETS.INDEX, true);

        delete this.activeLogFetchesMap[eventIndex];

        const event: t.EraPro.LogEvent = {
          eventCrc: view.getUint8(LOG_EVENT_OFFSETS.DATA + dataByteCount),
          eventIndex,
          eventTime: eventTime.toString(),
          eventTimeOriginal: eventTime.toString(),
          eventTypeCode: view.getUint16(LOG_EVENT_OFFSETS.TYPE, true),
          eventValue: Buffer.from(eventValueBuffer).toString("base64"),
        };

        if (isPuffEventLog(event.eventTypeCode)) {
          // count amount of puff logs, used in log audit event tracking
          this.puffLogsCount++;
        }

        if (
          !this.options.hasReceivedTimeSync &&
          this.unsyncedEvents.length >= c.UNSYCNED_LOG_EVENTS_THRESHOLD
        ) {
          this.timeSyncLogs(
            BigInt(0),
            LogEventAnalyticsType.UNSYNCED_LOGS_THRESHOLD
          );
        } else if (
          event.eventTypeCode === t.EraPro.LogType.RESET_V1 ||
          event.eventTypeCode === t.EraPro.LogType.RESET_V2
        ) {
          // Reset logs occur after the device chip resets, and this will always result
          // in the system clock reverting to 0. We assume all events before the reset
          // and after any previous time sync have a valid time because we cannot know how
          // much time passed between the log before a reset and the reset itself

          this.timeSyncLogs(BigInt(0), LogEventAnalyticsType.RESET_EVENT);
        }

        this.unsyncedEvents.push(event);

        if (
          event.eventTypeCode === t.EraPro.LogType.TIME_SYNC &&
          event.eventValue
        ) {
          const eventDataView: DataView = new DataView(
            buffer.slice(
              LOG_EVENT_OFFSETS.DATA,
              LOG_EVENT_OFFSETS.DATA + dataByteCount
            ).buffer
          );

          const beforeMicroseconds = eventDataView.getBigUint64(0, true);
          const afterMicroseconds = eventDataView.getBigUint64(8, true);

          const timeDelta = afterMicroseconds - beforeMicroseconds;

          this.timeSyncLogs(timeDelta, LogEventAnalyticsType.TIME_SYNC_EVENT);
        }

        //update the offset to be the index after the last log parsed
        this.options.offset = eventIndex + 1;

        buffer = buffer.slice(eventBufferLength);
      } catch (e) {
        Bugsnag.notify(e as Error);
        this.options.offset--;
        break;
      }
    }

    // Request next batch of logs.
    setTimeout(this.fetchLogs, c.LOG_FETCH_TIMEOUT);
  };

  // Device time is stored as seconds since the device was active.
  // Device time is reset to 0 with hard resets and stops counting when in shelf mode.
  // Due to this, device time can become wildly out of sync with real time.
  // Time syncs will add the delta between device time and real time to fix log timestamps.
  timeSyncLogs(timeDelta: bigint, logEventType: LogEventAnalyticsType): void {
    const syncedLogEvents: t.EraPro.LogEvent[] = this.unsyncedEvents.map(
      (logEvent) => {
        const syncedLogEvent = { ...(logEvent as t.EraPro.LogEvent) };
        syncedLogEvent.eventTime = (
          BigInt(syncedLogEvent.eventTime) + timeDelta
        ).toString();
        return syncedLogEvent;
      }
    );

    this.device.events.emit(
      "eventLogUpload",
      getLogStoreAnalyticsData(
        this.unsyncedEvents.length,
        logEventType,
        timeDelta,
        this.puffLogsCount
      )
    );

    this.device.events.emit("receivedLogEvents", {
      analyticsEvent: "log_event_v2",
      syncedLogEvents,
    });

    this.setLogRequestOptionsToLocalStorage({
      ...this.options,
      offset: this.options.offset + 1,
    });

    this.unsyncedEvents = [];
    this.puffLogsCount = 0;
  }

  getLogRequestOptionsFromLocalStorage(): EraProLogRequestOptions {
    return (
      getEraProLogRequestFromLocalStorage(this.device.systemId) ||
      DEFAULT_LOG_REQUEST_OPTIONS
    );
  }

  setLogRequestOptionsToLocalStorage(options: EraProLogRequestOptions): void {
    if (!this.device.systemId) {
      throw new Error("device systemId not set");
    }

    lsSet<string>(
      `__paxWeb.deviceLogs.${this.device.systemId}.nextLogToRequest`,
      JSON.stringify(options)
    );
  }

  updateOffset(newOffset: number): void {
    if (this.options.offset >= newOffset) return;

    this.shouldFetchLogs = false;

    // Wait for current log process to finish.
    setTimeout(() => {
      this.options.offset = newOffset;
      this.options.hasReceivedTimeSync = false;
      this.unsyncedEvents = [];
      this.shouldFetchLogs = true;
      this.setLogRequestOptionsToLocalStorage({ ...this.options });
      this.fetchLogs();
    }, c.LOG_FETCH_TIMEOUT + 50);
  }
}
