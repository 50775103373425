import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { ErrorCode } from "../../api/consumer/types";
import { ssoForgotPassword } from "../../main/routes";

import { ERROR_MESSAGES, PASSWORD_ERROR_MESSAGE } from "../constants";
import * as text from "../text";

const NEW_PASSWORD_ERROR_MESSAGES = {
  ...ERROR_MESSAGES,
  [ErrorCode.INVALID_PASSWORD]: PASSWORD_ERROR_MESSAGE,
};

export type SsoErrorMessageProps = {
  failureCode: ErrorCode;
  isNewPassword?: boolean;
  redirectTo?: string;
};

export const SsoErrorMessage: React.FC<SsoErrorMessageProps> = ({
  failureCode,
  isNewPassword,
  redirectTo,
}) => {
  if (failureCode === ErrorCode.REQUIRES_PASSWORD_RESET) {
    return (
      <span data-testid="password-reset-error-message">
        <sc.PasswordResetText>
          {text.PASSWORD_RESET_REQUIRED_1}{" "}
          <sc.PasswordResetLink
            data-testid="reset-password-link"
            to={ssoForgotPassword({ returnTo: redirectTo })}
          >
            {text.RESET_PASSWORD_LINK}
          </sc.PasswordResetLink>{" "}
          {text.PASSWORD_RESET_REQUIRED_2}
        </sc.PasswordResetText>
        <sc.PasswordResetText>
          {text.PASSWORD_RESET_REQUIRED_3}{" "}
          <sc.PaxLink
            data-testid="pax-link"
            href="https://www.pax.com"
            rel="noopener noreferrer"
            target="_blank"
          >
            {text.PAX_LINK}
          </sc.PaxLink>
          {text.PASSWORD_RESET_REQUIRED_4}
        </sc.PasswordResetText>
      </span>
    );
  }

  const errorMessages = isNewPassword
    ? NEW_PASSWORD_ERROR_MESSAGES
    : ERROR_MESSAGES;
  const message =
    errorMessages[failureCode] || errorMessages[ErrorCode.UNKNOWN_ERROR];

  return <sc.Error data-testid="error-message">{message}</sc.Error>;
};

const sc = {
  Error: styled.span`
    color: var(--pale-red);
    font-size: 14px;
    font-style: italic;
  `,

  PasswordResetLink: styled(Link)`
    color: var(--black);
    font-size: 14px;
  `,

  PasswordResetText: styled.div`
    color: var(--black);
    font-size: 14px;
    margin-top: 14px;
  `,

  PaxLink: styled.a`
    color: var(--black);
    font-size: 14px;
  `,
};
