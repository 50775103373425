import { connect } from "react-redux";

import * as t from "../../../main/types";

import { setHeaterSetPoint } from "../../actions";
import {
  FineControls,
  StateProps,
} from "../../components/MobileThermostat/FineControls";
import * as s from "../../selectors";

const mapStateToProps = (state: t.MainAppState): StateProps => ({
  deviceTemperature: s.getDeviceTemperature(state),
  isDeviceLocked: s.getIsDeviceLocked(state),
  temperatureRange: s.getTemperatureRange(state),
  temperatureUnit: s.getPreferredTemperatureUnit(state),
});

const mapDispatchToProps = {
  onChange: setHeaterSetPoint,
};

export default connect(mapStateToProps, mapDispatchToProps)(FineControls);
