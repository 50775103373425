import * as c from "../constants";
import * as u from "../utils";

export default class SmpMerger {
  buffer: Uint8Array;
  expectedLength: number;
  packetCount: number;

  constructor() {
    this.buffer = new Uint8Array();
    this.expectedLength = -1;
    this.packetCount = 0;
  }

  merge(packet: Uint8Array): void {
    if (this.packetCount === 0) {
      this.expectedLength = this.getExpectedLength(packet);
    }

    this.buffer = u.mergeTypedArrays(
      new Uint8Array(this.buffer),
      new Uint8Array(packet)
    );

    this.packetCount++;
  }

  hasAllPackets(): boolean {
    return this.buffer.length === this.expectedLength;
  }

  getExpectedLength(packet: Uint8Array): number {
    if (!packet || packet.length < c.HEADER_LENGTH) {
      throw new Error("Invalid McuMgrHeader");
    }

    const header = u.parseHeader(
      new Uint8Array(packet.slice(0, c.HEADER_LENGTH))
    );

    return c.HEADER_LENGTH + header.length;
  }
}
