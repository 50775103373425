import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import styled, { css } from "styled-components";

import { StrainJson } from "../../api/consumer/types";
import { getDevicePod } from "../../device/selectors";
import * as r from "../../main/routes";
import { getPrimaryStrainColor } from "../../shared/colors/Strain";
import { BannerSibling } from "../../shared/components/BannerContainer/BannerSibling";
import GoBack from "../../shared/components/GoBack";
import { Hexagon } from "../../shared/components/Icons";
import * as Text from "../../shared/components/Text";
import * as sharedHooks from "../../shared/hooks";
import * as text from "../../shared/text";
import * as utils from "../../shared/utils";

import * as hooks from "../hooks";
import PodsBatchList from "./PodsBatchList";
import PodsYourBatch from "./PodsYourBatch";

const MOBILE_HEADER_HEIGHT = 112;
const SCROLL_THRESHOLD = 25;
const SHORT_MOBILE_HEADER_HEIGHT = 72;

export type PodsBatchListPageProps = RouteComponentProps<{
  strainId: string;
}> & {
  useBatchListFetch?: hooks.UseBatchListFetch;
  useStrainById?: hooks.UseStrainById;
};

export const PodsBatchListPage: React.FC<PodsBatchListPageProps> = ({
  match,
  useBatchListFetch = hooks.useBatchListFetch,
  useStrainById = hooks.useStrainById,
}) => {
  const batchList = useBatchListFetch(match.params.strainId);
  const isDesktop = sharedHooks.useDesktopMediaQuery();
  const isPastScrollThreshold =
    sharedHooks.useScrollThresholdListener(SCROLL_THRESHOLD);
  const { strain } = useStrainById(match.params.strainId, {
    shouldShowErrorBanner: true,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { strainId, autoBatchId, hasTestReport } = useSelector(getDevicePod);

  const pageTitle = (
    <sc.PageTitleContainer $isPastThreshold={isPastScrollThreshold} mobileOnly>
      <sc.GoBack to={r.podDetails(match.params.strainId)} />
      <sc.PageTitle $isPastThreshold={isPastScrollThreshold}>
        <div>
          {podsBatchListTitle(isDesktop, isPastScrollThreshold, strain)}
        </div>
        {!isDesktop && strain && (
          <sc.StrainDetails $isPastThreshold={isPastScrollThreshold}>
            <sc.PartnerName>{strain.partner.partnerName}</sc.PartnerName>
            <div>
              <sc.Hexagon color={getPrimaryStrainColor(strain)} size={12} />
              {isPastScrollThreshold
                ? utils.getStrainClassificationFormattedText(
                    strain.classification
                  )
                : strain.name}
            </div>
          </sc.StrainDetails>
        )}
      </sc.PageTitle>
    </sc.PageTitleContainer>
  );

  const batchesWithReports = batchList.filter(
    (batch) => batch.parsedBatchResult
  );

  let hasBatchesToDisplay = batchesWithReports.length > 0;
  if (!hasBatchesToDisplay) {
    return (
      <div>
        {pageTitle}
        <sc.BatchListCard data-testid="no-reports-card">
          <sc.ComingSoon>
            No test reports available for this strain.
          </sc.ComingSoon>
        </sc.BatchListCard>
      </div>
    );
  }

  const autoIdReport = batchesWithReports.find(
    (report) => report.batchId === autoBatchId && report.strainId === strainId
  );
  if (autoIdReport) {
    const indexOfReport = batchesWithReports.indexOf(autoIdReport);
    if (indexOfReport !== -1) batchesWithReports.splice(indexOfReport, 1);
    hasBatchesToDisplay = batchesWithReports.length > 0;
  }

  return (
    <div>
      {pageTitle}
      <sc.BatchListCard data-testid="batch-list-card">
        <PodsYourBatch
          autoIdReport={autoIdReport}
          hasBatchesToDisplay={hasBatchesToDisplay}
        />
        <PodsBatchList
          batchesWithReports={batchesWithReports}
          hasAutoIdTestReport={hasTestReport}
        />
      </sc.BatchListCard>
    </div>
  );
};

type ScrollProps = {
  $isPastThreshold: boolean;
};

const podsBatchListTitle = (
  isDesktop: boolean,
  isPastScrollThreshold: boolean,
  strain: StrainJson | null
): string =>
  !isDesktop && isPastScrollThreshold && strain
    ? strain.name
    : text.POD_BATCH_LIST;

const sc = {
  BatchListCard: styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    min-height: 45px;
    padding: 16px;

    ${utils.desktopBreakpoint(css`
      border: 2px var(--very-light-pink);
      border-radius: 8px;
      border-style: dotted;
      flex: 0 0 ${(props) => props.theme.pageWidth};
      margin: 0 auto;
      padding: 20px;
      width: ${(props) => props.theme.pageWidth};
    `)}
  `,

  ComingSoon: styled.div`
    ${Text.PlaaxLight}
    font-size: 16px;
    height: 16px;
    text-align: center;
  `,

  GoBack: styled(GoBack)`
    left: 10px;
    position: absolute;
    top: 16px;

    ${utils.desktopBreakpoint(css`
      left: initial;
      position: initial;
      top: initial;
    `)}
  `,

  Hexagon: styled(Hexagon)`
    margin-right: 4px;
  `,

  PageTitle: styled.div<ScrollProps>`
    font-size: ${({ $isPastThreshold }) =>
      $isPastThreshold ? "16px" : "20px"};
    margin: auto;
    margin-top: ${({ $isPastThreshold }) =>
      $isPastThreshold ? "20px" : "28px"};
    text-transform: uppercase;
    transition: 85ms ease-out;

    ${utils.desktopBreakpoint(css`
      font-size: 28px;
      margin: 0 0 0 252px;
    `)}
  `,

  PageTitleContainer: styled(BannerSibling)<ScrollProps>`
    background: var(--white);
    border-bottom: solid 0.5px var(--very-light-pink);
    display: flex;
    flex: 1;
    height: ${({ $isPastThreshold }) =>
      $isPastThreshold ? SHORT_MOBILE_HEADER_HEIGHT : MOBILE_HEADER_HEIGHT}px;
    position: sticky;
    text-align: center;
    top: 0;
    transition: 85ms ease-out;
    width: 100%;
    z-index: var(--z-index-sticky-headers);

    ${utils.desktopBreakpoint(css`
      border: none;
      height: initial;
      margin: 61px auto 42px;
      max-width: ${(props) => props.theme.pageWidth};
      position: initial;
      top: initial;
    `)}
  `,

  PartnerName: styled.div`
    &::after {
      content: "•";
      font-size: 8px;
      margin: 0 12px;
    }
  `,

  StrainDetails: styled.div<ScrollProps>`
    color: var(--brown-grey);
    display: flex;
    font-size: 16px;
    margin-top: ${({ $isPastThreshold }) =>
      $isPastThreshold ? "5px" : "16px"};
    text-transform: capitalize;
  `,
};
