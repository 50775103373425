import React, { useEffect } from "react";
import { shallowEqual } from "react-redux";
import styled, { css } from "styled-components";

import * as consumerApi from "../../../api/consumer";
import * as apiTypes from "../../../api/consumer/types";
import * as apiUtils from "../../../api/consumer/utils";
import * as Text from "../../../shared/components/Text";
import { CHOOSE_YOUR_PROFILE_THEME } from "../../../shared/text";
import * as utils from "../../../shared/utils";

import ProfileThemePicker from "../../containers/ProfileThemePicker";

import { Hero } from "./Hero";

export type MyPaxHeaderProps = {
  children?: React.ReactChild;
  fetchThemes?: () => Promise<void>;
  fontSize?: string;
  hasFetchedUser: boolean;
  isSignedIn: boolean;
  setDefaultProfileTheme: () => void;
  short?: boolean;
  themes: apiTypes.user.Theme[];
  userTheme?: apiTypes.user.Theme;
};

const MyPaxHeaderComponent: React.FC<MyPaxHeaderProps> = ({
  children,
  fetchThemes = consumerApi.actions.users.fetchThemes,
  hasFetchedUser,
  isSignedIn,
  setDefaultProfileTheme,
  short = true,
  themes,
  userTheme,
}) => {
  // Ensures all MyPax pages open with page scrolled to top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const initialThemesLength = themes.length;
  useEffect(() => {
    if (!hasFetchedUser || initialThemesLength > 0) return;

    fetchThemes();
  }, [fetchThemes, hasFetchedUser, initialThemesLength]);

  // Give users a default theme if they navigate away from the page without selecting one.
  useEffect(() => {
    return (): void => {
      setDefaultProfileTheme();
    };

    // eslint-disable-next-line
  }, []);

  if (themes.length === 0)
    return (
      <Hero
        color="var(--black)"
        data-testid="my-pax-banner-empty"
        short={short}
      >
        {children}
      </Hero>
    );

  if (hasFetchedUser && isSignedIn && !userTheme) {
    return (
      <sc.Container>
        <sc.ProfileThemePicker />
        <sc.ChooseProfileText>{CHOOSE_YOUR_PROFILE_THEME}</sc.ChooseProfileText>
      </sc.Container>
    );
  }

  const bannerTextColor = userTheme?.textColor || themes[0].textColor;
  const bannerUrl = apiUtils.getThemeImageUrl(
    userTheme || themes[0],
    apiTypes.user.ProfileImageType.DESKTOP_BANNER
  );

  return (
    <Hero
      color={bannerTextColor}
      data-testid="my-pax-banner"
      image={hasFetchedUser ? bannerUrl : ""}
      short={short}
    >
      {children}
    </Hero>
  );
};

const compareProps = (
  { userTheme: prevTheme, ...prevProps }: MyPaxHeaderProps,
  { userTheme: nextTheme, ...nextProps }: MyPaxHeaderProps
) => {
  return prevTheme?.id === nextTheme?.id && shallowEqual(prevProps, nextProps);
};

export const MyPaxHeader = React.memo(MyPaxHeaderComponent, compareProps);

const sc = {
  ChooseProfileText: styled.div`
    ${Text.PlaaxLight}
    color: var(--gunmetal);
    font-size: 16px;
    height: 16px;
    line-height: 1;
    margin-bottom: 8px;
    text-align: center;
    width: 100%;

    ${utils.desktopBreakpoint(
      css`
        ${Text.PlaaxLight}
        font-size: 20px;
        height: 18px;
        line-height: 0.9;
      `
    )}
  `,

  Container: styled.div`
    max-width: ${(props) => props.theme.myPax.pageContentWidth};

    ${utils.desktopBreakpoint(
      css`
        width: 100%;
      `
    )}
  `,

  ProfileThemePicker: styled(ProfileThemePicker)`
    align-items: center;
    height: 80px;
    justify-content: center;
    min-height: 80px;
    width: 100%;

    & img {
      height: 52px;
      margin: 0 10px;
      width: 52px;
    }

    & img:hover {
      height: 57px;
      margin: 0 5px;
      width: 57px;
    }

    ${utils.smallScreenBreakpoint(
      css`
        & img {
          height: 44px;
          margin: 0 4px;
          width: 44px;
        }

        & img:hover {
          height: 46px;
          margin: 0 2px;
          width: 46px;
        }
      `
    )}

    ${utils.desktopBreakpoint(
      css`
        height: 140px;
        min-height: 140px;

        & img {
          height: 100px;
          margin: 0 25px;
          width: 100px;
        }

        & img:hover {
          height: 120px;
          margin: 0 15px;
          width: 120px;
        }
      `
    )}
  `,
};
