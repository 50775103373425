import { Endian } from "../../types";
import * as u from "../../utils";

import McuManagerImageTlv from "./McuManagerImageTlv";

// Represents a type-length-value info table for firmware images using McuBoot or the legacy Apache Mynewt bootloader.
export default class McuManagerImageTlvInfo {
  magic?: number;
  total?: number;

  static MIN_SIZE = 4;

  static fromBytes(b: Uint8Array, offset = 0): McuManagerImageTlvInfo {
    const size = b.length - offset;
    if (size < this.MIN_SIZE) {
      throw new Error(
        `The byte array is too short to be a McuManagerImageTlvInfo: length=${b.length}, offset=${offset}, minSize=${this.MIN_SIZE}`
      );
    }

    const info = new McuManagerImageTlvInfo();
    info.magic = u.byteArrayToUnsignedInt(b, offset, Endian.LITTLE, 2);
    info.total = u.byteArrayToUnsignedInt(b, offset + 2, Endian.LITTLE, 2);

    if (info.magic !== McuManagerImageTlv.IMG_TLV_INFO_MAGIC) {
      throw new Error(
        `Wrong magic number: magic=${info.magic} instead of ${McuManagerImageTlv.IMG_TLV_INFO_MAGIC}`
      );
    }
    return info;
  }
}
