import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import * as consumerApi from "../api/consumer";
import { getConnectedDevice } from "../bluetooth/connectedDevice";
import * as ff from "../featureFlags";
import { MainAppState } from "../main/types";
import * as a from "./actions";
import * as c from "./constants";

export const useFindMyPax = (): void => {
  const dispatch = useDispatch();
  const requestLocationInterval = useRef<number>();
  const connectedDevice = getConnectedDevice();
  const shareLocationDataFlag = useSelector((state: MainAppState) =>
    consumerApi.selectors.devices.getShareLocationDataFlagForDevice(state, {
      serial: connectedDevice?.serial,
    })
  );

  useEffect(() => {
    if (!ff.isFindMyPaxEnabled || !shareLocationDataFlag) {
      clearInterval(requestLocationInterval.current);
      return;
    }

    dispatch(a.saveLocation());
    requestLocationInterval.current = window.setInterval(
      () => dispatch(a.saveLocation()),
      c.REQUEST_LOCATION_INTERVAL
    );

    return (): void => {
      clearInterval(requestLocationInterval.current);
    };
  }, [dispatch, shareLocationDataFlag]);
};
