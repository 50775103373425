import * as consumerApiTypes from "../api/consumer/types";
import { getConnectedDevice } from "../bluetooth/connectedDevice";
import { getLastUsed } from "../shared/utils";
import * as CardImages from "./components/MyPaxCardImages";
import * as t from "./types";

type DeviceCardMap = {
  [key in consumerApiTypes.device.DeviceShellColor]?: string;
};

const { EraPro, Pax3 } = CardImages;

const ERA_PRO_CARD_MAP: DeviceCardMap = {
  [consumerApiTypes.device.DeviceShellColor.MATTE_BLACK]: EraPro.BLACK,
  [consumerApiTypes.device.DeviceShellColor.MATTE_JADE]: EraPro.JADE,
  [consumerApiTypes.device.DeviceShellColor.MATTE_RED]: EraPro.RED,
  [consumerApiTypes.device.DeviceShellColor.MATTE_SILVER]: EraPro.SILVER,
  [consumerApiTypes.device.DeviceShellColor.SAPPHIRE]: EraPro.SAPPHIRE,
  [consumerApiTypes.device.DeviceShellColor.MIDNIGHT_RAIN]: EraPro.SAPPHIRE,
};

const PAX_3_CARD_MAP: DeviceCardMap = {
  [consumerApiTypes.device.DeviceShellColor.AMBER]: Pax3.AMBER,
  [consumerApiTypes.device.DeviceShellColor.BLUE]: Pax3.BLUE,
  [consumerApiTypes.device.DeviceShellColor.GLOSSY_BLACK]: Pax3.GLOSSY_BLACK,
  [consumerApiTypes.device.DeviceShellColor.GLOSSY_GOLD]: Pax3.GLOSSY_GOLD,
  [consumerApiTypes.device.DeviceShellColor.GLOSSY_RED]: Pax3.GLOSSY_RED,
  [consumerApiTypes.device.DeviceShellColor.GLOSSY_ROSE_GOLD]:
    Pax3.GLOSSY_ROSE_GOLD,
  [consumerApiTypes.device.DeviceShellColor.GLOSSY_SILVER]: Pax3.GLOSSY_SILVER,
  [consumerApiTypes.device.DeviceShellColor.MATTE_BLACK]: Pax3.MATTE_BLACK,
  [consumerApiTypes.device.DeviceShellColor.MATTE_FUCHSIA]: Pax3.MATTE_FUCHSIA,
  [consumerApiTypes.device.DeviceShellColor.MATTE_ROSE_GOLD]:
    Pax3.MATTE_ROSE_GOLD,
  [consumerApiTypes.device.DeviceShellColor.MATTE_SILVER]: Pax3.MATTE_SILVER,
  [consumerApiTypes.device.DeviceShellColor.MATTE_TEAL]: Pax3.MATTE_TEAL,
};

export const getDeviceCard = (
  model: consumerApiTypes.device.DeviceModel,
  color?: consumerApiTypes.device.DeviceShellColor
): string => {
  if (!color) return CardImages.UnknownDeviceCard;

  switch (model) {
    case consumerApiTypes.device.DeviceModel.ERA:
      return CardImages.Era;
    case consumerApiTypes.device.DeviceModel.ERA_PRO:
      return ERA_PRO_CARD_MAP[color] ?? CardImages.UnknownDeviceCard;
    case consumerApiTypes.device.DeviceModel.PAX_3:
    case consumerApiTypes.device.DeviceModel.PAX_3_5:
      return PAX_3_CARD_MAP[color] ?? CardImages.UnknownDeviceCard;
    default:
      return CardImages.UnknownDeviceCard;
  }
};

export const getIsMyDeviceConnected = (deviceSerial: string): boolean =>
  deviceSerial === getConnectedDevice()?.serial;

export const getMyPodsLastUsedText = (
  dateString?: string,
  isLow?: boolean
): string => {
  if (!dateString) return "";

  const lastUsed = getLastUsed(dateString);

  return isLow ? [lastUsed, "•", "Pod low"].join(" ") : lastUsed;
};

export const getStrainWithFavoriteStatusMap = (
  favorites: consumerApiTypes.favorites.FavoriteStrainJson[]
): t.StrainWithFavoriteStatusMap => {
  return favorites.reduce(
    (favoritedAccumulator, favorite) => ({
      ...favoritedAccumulator,
      [favorite.strainId]: { isFavorited: true, strain: favorite.strain },
    }),
    {}
  );
};
