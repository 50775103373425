import * as t from "../types";

type DeviceValues = {
  deviceName: string;
  model: t.device.DeviceModel;
};

export const getDeviceJson = (
  values: Partial<t.device.DeviceJson> & DeviceValues
): Partial<t.device.DeviceJson> => ({
  earliestClientTimestamp: new Date().toISOString(),
  latestClientTimestamp: new Date().toISOString(),
  logSyncOffset: 0,
  ...values,
});
