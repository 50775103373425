import React from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

import * as r from "../../main/routes";
import { Row } from "../../shared/components/Flex";
import * as Text from "../../shared/components/Text";
import { useDesktopMediaQuery } from "../../shared/hooks";
import { desktopBreakpoint, formatTimestampWithDots } from "../../shared/utils";

import { SmallCard } from "./PodsTestResultsCard";

export type PodsTestingDetailsCardProps = {
  batchId: string;
  labName: string;
  testDate: string;
  strainId: string;
};

export const PodsTestingDetailsCard: React.FC<PodsTestingDetailsCardProps> = ({
  batchId,
  labName,
  testDate,
  strainId,
}) => {
  const isDesktop = useDesktopMediaQuery();

  return (
    <SmallCard data-testid="pods-testing-details-card" borderStyle="dotted">
      <sc.Title>Testing Details</sc.Title>
      {isDesktop && (
        <Row justifyContent="space-between">
          <sc.Label>Lab:</sc.Label>
          <sc.Value>{labName}</sc.Value>
        </Row>
      )}
      <Row justifyContent="space-between">
        <sc.Label>Batch:</sc.Label>
        <sc.BatchListLink to={r.podsBatchList(strainId)}>
          {batchId}
        </sc.BatchListLink>
      </Row>
      <Row justifyContent="space-between">
        <sc.Label>Testing Date:</sc.Label>
        <sc.Value>{formatTimestampWithDots(true, testDate)}</sc.Value>
      </Row>
    </SmallCard>
  );
};

const sc = {
  BatchListLink: styled(Link)`
    ${Text.MediumLLLight}
    color: var(--black);
    font-size: 16px;
    line-height: 1;
    margin-bottom: 12px;
  `,

  Label: styled.div`
    color: var(--black);
    font-size: 16px;
    line-height: 1;
    margin-bottom: 12px;
    text-transform: uppercase;
  `,

  SubTitle: styled.div`
    color: var(--gunmetal);
    font-style: italic;
    text-align: center;
  `,

  Title: styled.div`
    color: var(--black);
    display: none;
    font-size: 20px;
    line-height: 0.8;
    margin-bottom: 16px;
    text-transform: uppercase;

    ${desktopBreakpoint(css`
      display: block;
    `)}
  `,

  Value: styled.div`
    ${Text.MediumLLLight}
    color: var(--black);
    font-size: 16px;
    line-height: 1;
    margin-bottom: 12px;
    text-transform: capitalize;
  `,
};
