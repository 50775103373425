import styled, { css } from "styled-components";

import * as Text from "./Text";

type StyledButtonProps = {
  disabled?: boolean;
  transparent?: boolean;
  transparentWithBorder?: boolean;
};

const disabledCSS = css`
  cursor: not-allowed;
  opacity: 0.3;
  pointer-events: none;
`;

const transparentCSS = css`
  background: none;
  border: none;
  color: var(--black);
`;

const transparentWithBorderCSS = css`
  background: none;
  color: var(--black);
`;

// @deprecated - use PlaaxButton
export const Button = styled.button<StyledButtonProps>`
  background-color: var(--black);
  border: solid 1px var(--black);
  border-radius: 22px;
  color: var(--white);
  cursor: pointer;
  font-size: 16px;
  height: 36px;
  line-height: 1;
  outline: none;
  padding: 8px 16px;
  text-transform: uppercase;

  &:hover {
    font-weight: 600;
  }

  ${({ disabled }) => (disabled ? disabledCSS : "")}
  ${({ transparent }) => (transparent ? transparentCSS : "")}
  ${({ transparentWithBorder }) =>
    transparentWithBorder ? transparentWithBorderCSS : ""}
`;

export default Button;

// @deprecated - use PlaaxButtonWhite
export const SecondaryButton = styled(Button)`
  background: none;
  border: none;
  color: var(--black);
`;

export const PlaaxButton = styled.button<StyledButtonProps>`
  ${Text.PlaaxLight}
  background-color: var(--black);
  border: solid 1px var(--black);
  border-radius: 20px;
  color: var(--white);
  cursor: pointer;
  font-size: 16px;
  letter-spacing: -0.12px;
  line-height: 1.25;
  padding: 9px 20px 11px;

  ${({ disabled }) => (disabled ? disabledCSS : "")}
`;

export const PlaaxButtonWhite = styled(PlaaxButton)`
  background-color: var(--white);
  border: solid 1px var(--black);
  color: var(--black);
`;

export const SquareButton = styled.button<StyledButtonProps>`
  ${Text.MediumLLBold}
  align-items: center;
  background-color: var(--white);
  border: 3px solid var(--black);
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  justify-content: center;
  letter-spacing: -0.16px;
  text-transform: uppercase;

  ${({ disabled }) => (disabled ? disabledCSS : "")}
`;

export const PrimarySquareButton = styled(SquareButton)<StyledButtonProps>`
  background-color: var(--pax-yellow);
  border-color: var(--pax-yellow);
`;
