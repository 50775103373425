import React from "react";
import { useSelector } from "react-redux";
import styled, { css } from "styled-components";

import * as s from "../../api/consumer/selectors/user";
import { Column } from "../../shared/components/Flex";
import * as Text from "../../shared/components/Text";
import * as t from "../../shared/text";
import { desktopBreakpoint } from "../../shared/utils";

import { PodsPlacesForm } from "./PodsPlacesForm";

type PodsLocationModalContentProps = {
  hideDivider?: boolean;
  onRequestClose?: () => unknown;
};

export const PodsLocationModalContent: React.FC<PodsLocationModalContentProps> =
  ({ hideDivider = false, onRequestClose }) => {
    const isUserInUS = useSelector(s.getIsUserInUS);

    return (
      <sc.Container>
        <sc.Description>{t.postalCodeExplanation(isUserInUS)}</sc.Description>
        <PodsPlacesForm
          hideDivider={hideDivider}
          onRequestClose={onRequestClose}
        />
      </sc.Container>
    );
  };

const sc = {
  Container: styled(Column)`
    flex: 1;
    padding: 0;
    width: 100%;

    ${desktopBreakpoint(
      css`
        padding: 0 40px;
      `
    )}
  `,

  Description: styled.div`
    ${Text.PlaaxLight}
    margin: 0 auto 12px;
    max-width: 500px;
  `,
};
