import { AppThunk } from "../../shared/types";

import { trackEventAction } from "../actions";

export enum AnalyticsDeviceConnectionStatus {
  DISCONNECTED = 0,
  CONNECTING = 1,
  CONNECTED = 2,
  DISCONNECTING = 3,
}

type PeripheralConnectionStatusEventProperties = {
  peripheralConnectionStatus: number;
  firmwareVersion?: string;
};

export const trackDeviceConnectionStatus =
  (
    status: AnalyticsDeviceConnectionStatus,
    firmwareRevision?: string
  ): AppThunk =>
  (dispatch, getState): void => {
    const properties: PeripheralConnectionStatusEventProperties = {
      peripheralConnectionStatus: status,
    };

    if (status === AnalyticsDeviceConnectionStatus.CONNECTED) {
      properties.firmwareVersion = firmwareRevision;
    }

    trackEventAction<PeripheralConnectionStatusEventProperties>(
      "peripheral_connection_status",
      properties
    )(dispatch, getState, null);
  };

export const trackDeviceAdded =
  (): AppThunk =>
  (dispatch, getState): void => {
    trackEventAction("device_added")(dispatch, getState, null);
  };

export const trackDeviceRemoved =
  (): AppThunk =>
  (dispatch, getState): void => {
    trackEventAction("device_removed")(dispatch, getState, null);
  };

export const trackDynamicModeSet =
  (dynamicMode: number): AppThunk =>
  (dispatch, getState): void => {
    trackEventAction("dynamic_mode_set", { dynamicMode })(
      dispatch,
      getState,
      null
    );
  };

export const trackLockSet =
  (lockWasSet: boolean): AppThunk =>
  (dispatch, getState): void => {
    trackEventAction("lock_set", { lockWasSet })(dispatch, getState, null);
  };
