// Spec:
// https://github.com/PaxLabs/pax-logging/blob/develop/schemas/events/app/fuel-gauge/pod_low_0.json

import { ConnectedPodJson } from "../../api/consumer/types/connectedPods";
import { PodData, PodType } from "../../pods/types";
import { AppThunk } from "../../shared/types";

import { trackEventAction } from "../actions";

const EVENT_NAME = "pod_low";

type PodLowEventProperties = {
  isLows: boolean[];
  lowStrainsCount: number;
  page: "device_controls" | "pod_history";
  podTypes: PodType[];
  strainIds: string[];
  tagUuids: (string | null)[];
  totalStrainsCount: number;
};

export const trackPodLowEventFromDeviceControlsPage =
  (pod: PodData, connectedPod: ConnectedPodJson): AppThunk =>
  (dispatch, getState): void => {
    const isLows = [pod.isAutoId && connectedPod.isLow];
    const podTypes = [pod.isAutoId ? PodType.D3N : PodType.D3];
    const strainIds = [pod.strainId as string]; // Only unflashed pods should have missing strain ids.
    const serialNumber = connectedPod.podSerialNumbers[0] || null;

    trackEventAction<PodLowEventProperties>(EVENT_NAME, {
      isLows,
      lowStrainsCount: isLows.filter(Boolean).length,
      page: "device_controls",
      podTypes,
      strainIds,
      tagUuids: [serialNumber],
      totalStrainsCount: 1,
    })(dispatch, getState, null);
  };

export const trackPodLowEventFromPodHistory =
  (pods: ConnectedPodJson[]): AppThunk =>
  (dispatch, getState): void => {
    const isLows = pods.map((pod) => pod.isLow);
    const strainIds = pods.map((pod) => pod.strain.id);
    const tagUuids = pods.map((pod) => pod.podSerialNumbers[0] || null);

    trackEventAction<PodLowEventProperties>(EVENT_NAME, {
      isLows,
      lowStrainsCount: isLows.filter(Boolean).length,
      page: "pod_history",
      podTypes: [],
      strainIds,
      tagUuids,
      totalStrainsCount: pods.length,
    })(dispatch, getState, null);
  };
