import Bugsnag from "@bugsnag/js";
import { isMobile } from "react-device-detect";
import { ThunkDispatch } from "redux-thunk";

import McuManagerBleTransport from "../../mcumgr-js/src/ble/McuManagerBleTransport";
import FirmwareUpgradeCallback from "../../mcumgr-js/src/dfu/FirmwareUpgradeCallback";
import FirmwareUpgradeManager, {
  FirmwareUpgradeMode,
  FirmwareUpgradeState as McuFirmwareUpgradeState,
} from "../../mcumgr-js/src/dfu/FirmwareUpgradeManager";
import * as PaxBle from "../../pax-ble";

import { trackEventAction } from "../analytics/actions";
import { MainAppState } from "../main/types";
import { Action, AppThunk, GetState } from "../shared/types";
import * as t from "./types";

export const RECEIVED_FIRMWARE_INFO = "RECEIVED_FIRMWARE_INFO";
export const SET_FIRMWARE_UPGRADE_PROGRESS = "SET_FIRMWARE_UPGRADE_PROGRESS";
export const SET_FIRMWARE_UPGRADE_STATE = "SET_FIRMWARE_UPGRADE_STATE";

export const TITLE_CANCELED = "Installation was canceled!";
export const TITLE_ERROR = "Oops! There was a problem";
export const TITLE_FINISHING_UPLOAD = "Finishing Up";
export const TITLE_RESTARTING = "Restarting Device";
export const TITLE_SUCCESS = "Success!";
export const TITLE_UPLOADING = "Uploading";
export const TITLE_VALIDATING = "Validating";
export const TITLE_VERIFYING = "Verifying Update";

export const MESSAGE_ERROR =
  "Please refresh the page and try upgrading your device again.";
export const MESSAGE_FINISHING_UPLOAD =
  "Disconnecting and restarting your device to complete the update.";
export const MESSAGE_IN_PROGRESS = isMobile
  ? "Please keep your device close to your phone while we update it"
  : "Please keep your device next to your computer while we update it";
export const MESSAGE_RESTARTING =
  "You will need to re-connect your device after restart";

const DEVICE_RESTART_WAIT_TIME = 15_000;
const DEVICE_FINISHING_UP_TIME = DEVICE_RESTART_WAIT_TIME / 3;

export const receivedFirmwareInfo = (
  version: string,
  downloadUrl: string
): Action => ({
  payload: { downloadUrl, version },
  type: RECEIVED_FIRMWARE_INFO,
});

export type InstallFirmware = (
  device: PaxBle.BaseDevice,
  firmwareInfo: t.FirmwareInfo,
  imageData: Uint8Array
) => AppThunk;

export const installFirmware: InstallFirmware =
  (device, firmwareInfo, imageData) =>
  async (dispatch, getState): Promise<void> => {
    device.setShouldFetchLogs(false);

    const firmwareUpgradeCallback = getFirmwareUpgradeCallback(
      dispatch,
      getState,
      device,
      firmwareInfo
    );

    const transport = new McuManagerBleTransport(device.device);
    await transport.initialize();

    const firmwareUpgradeManager = new FirmwareUpgradeManager(
      transport,
      imageData,
      FirmwareUpgradeMode.TEST_AND_CONFIRM,
      firmwareUpgradeCallback
    );

    return firmwareUpgradeManager.start();
  };

export const getFirmwareUpgradeCallback = (
  dispatch: ThunkDispatch<MainAppState, null, Action>,
  getState: GetState,
  device: PaxBle.BaseDevice,
  firmwareInfo: t.FirmwareInfo,
  deviceRestartWaitTime: number = DEVICE_RESTART_WAIT_TIME
): FirmwareUpgradeCallback => {
  return {
    onStateChanged(_, newState: McuFirmwareUpgradeState): void {
      const mcuState = newState;

      switch (mcuState) {
        case McuFirmwareUpgradeState.NONE:
          Bugsnag.notify(
            new Error("Unexpected NONE state during firmware upgrade")
          );
          dispatch(
            setFirmwareUpgradeState({
              mcuState,
              message: MESSAGE_ERROR,
              progress: 0,
              title: TITLE_ERROR,
            })
          );
          break;

        case McuFirmwareUpgradeState.VALIDATE:
          dispatch(
            setFirmwareUpgradeState({
              mcuState,
              message: MESSAGE_IN_PROGRESS,
              progress: 0,
              title: TITLE_VALIDATING,
            })
          );
          break;

        case McuFirmwareUpgradeState.UPLOAD:
          dispatch(
            setFirmwareUpgradeState({
              mcuState,
              message: MESSAGE_IN_PROGRESS,
              progress: 0,
              title: TITLE_UPLOADING,
            })
          );
          break;

        case McuFirmwareUpgradeState.TEST:
          dispatch(
            setFirmwareUpgradeState({
              mcuState,
              message: MESSAGE_IN_PROGRESS,
              progress: 0,
              title: TITLE_VERIFYING,
            })
          );
          break;

        case McuFirmwareUpgradeState.RESET:
          setTimeout(() => {
            dispatch(onDeviceRestartTimeout());
          }, deviceRestartWaitTime);

          trackEventAction("firmware_updated", {
            fromVersion: device.firmwareRevision,
            toVersion: firmwareInfo.version,
          })(dispatch, getState, null);

          setTimeout(() => {
            dispatch(
              setFirmwareUpgradeState({
                mcuState,
                message: MESSAGE_RESTARTING,
                progress: 100,
                title: TITLE_RESTARTING,
              })
            );
          }, DEVICE_FINISHING_UP_TIME);

          dispatch(
            setFirmwareUpgradeState({
              mcuState,
              message: MESSAGE_FINISHING_UPLOAD,
              progress: 0,
              title: TITLE_FINISHING_UPLOAD,
            })
          );

          break;
      }
    },

    onUpgradeCanceled(): void {
      dispatch(
        setFirmwareUpgradeState({
          mcuState: McuFirmwareUpgradeState.NONE,
          progress: 0,
          title: TITLE_CANCELED,
        })
      );
    },

    onUpgradeCompleted(): void {},

    onUpgradeFailed(_, error: Error): void {
      Bugsnag.notify(error);
      dispatch(
        setFirmwareUpgradeState({
          mcuState: McuFirmwareUpgradeState.NONE,
          message: error.message,
          progress: 0,
          title: TITLE_ERROR,
        })
      );
    },

    onUpgradeStarted(): void {},

    onUploadProgressChanged(bytesSent: number, imageSize: number): void {
      const progress = Math.round((100 * bytesSent) / imageSize);
      dispatch(setFirmwareUpgradeProgress(progress));
    },
  };
};

const onDeviceRestartTimeout = (): Action => ({
  payload: {
    firmwareUpgradeState: {
      mcuState: McuFirmwareUpgradeState.SUCCESS,
      progress: undefined,
      title: TITLE_SUCCESS,
    },
  },
  type: SET_FIRMWARE_UPGRADE_STATE,
});

export const setFirmwareUpgradeState = (
  firmwareUpgradeState: t.FirmwareUpgradeState
): Action => ({
  payload: { firmwareUpgradeState },
  type: SET_FIRMWARE_UPGRADE_STATE,
});

export type SetFirmwareUpgradeProgress = (
  progress: number | undefined
) => Action;

export const setFirmwareUpgradeProgress: SetFirmwareUpgradeProgress = (
  progress
) => ({
  payload: { progress },
  type: SET_FIRMWARE_UPGRADE_PROGRESS,
});
