import { connect } from "react-redux";

import * as s from "../../api/consumer/selectors";
import { MainAppState } from "../../main/types";

import { MyPaxHomePage, MyPaxHomePageProps } from "../components/MyPaxHomePage";

const mapStateToProps = (state: MainAppState): MyPaxHomePageProps => ({
  hasFetchedUser: s.user.getHasFetchedUser(state),
  isSignedIn: s.user.getIsUserSignedIn(state),
  lastConnectedDevice: s.devices.getLastConnectedAccountDevice(state),
});

export default connect(mapStateToProps)(MyPaxHomePage);
