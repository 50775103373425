import { connect } from "react-redux";

import * as t from "../../main/types";

import { toggleDeviceLock } from "../actions";
import { LockedIndicator } from "../components/LockedIndicator";
import * as c from "../constants";

const mapStateToProps = (state: t.MainAppState) => {
  return {
    isDeviceLocked: state.device.attributes[c.ATTRIBUTE_LOCKED] === 1,
  };
};

const mapDispatchToProps = {
  toggleDeviceLock,
};

export default connect(mapStateToProps, mapDispatchToProps)(LockedIndicator);
