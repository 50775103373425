import React from "react";
import styled from "styled-components";

import { PartnerJson } from "../../api/consumer/types/partner";

import * as Icons from "./Icons";
import { PartnerLogo } from "./PartnerLogo";

type PartnerLogoWithCheckmarkProps = {
  onClick?: () => void;
  partner?: PartnerJson;
};

export const PartnerLogoWithCheckmark: React.FC<PartnerLogoWithCheckmarkProps> =
  ({ partner, ...props }) => {
    return (
      <sc.Container {...props} cursorPointer={!!props.onClick}>
        <sc.PartnerLogo partner={partner} />
        <sc.CheckmarkContainer>
          <Icons.CheckWhite />
        </sc.CheckmarkContainer>
      </sc.Container>
    );
  };

type ContainerProps = {
  cursorPointer: boolean;
};

const sc = {
  CheckmarkContainer: styled.div`
    align-items: center;
    background-color: var(--black);
    border-radius: 7px;
    bottom: 0;
    display: flex;
    height: 14px;
    justify-content: center;
    position: absolute;
    right: 0;
    width: 14px;

    & svg {
      height: 9px;
      width: 9px;
    }
  `,

  Container: styled.div<ContainerProps>`
    cursor: ${({ cursorPointer }) => (cursorPointer ? "pointer" : "default")};
    position: relative;
  `,

  PartnerLogo: styled(PartnerLogo)`
    height: 40px;
    width: 40px;
  `,
};
