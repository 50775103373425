import React from "react";
import styled from "styled-components";

import { Row } from "../../shared/components/Flex";
import * as Text from "../../shared/components/Text";
import * as t from "../../shared/text";

export type PodsSearchResultsHeaderProps = {
  handleResetClick: () => void;
  initialQuery: string;
};

export const PodsSearchResultsHeader: React.FC<PodsSearchResultsHeaderProps> =
  ({ handleResetClick, initialQuery }) => {
    return (
      <sc.Header>
        <sc.Row>
          <sc.Title>{t.SEARCH_RESULTS}</sc.Title>
          <sc.ClearLink onClick={handleResetClick}>{t.RESET}</sc.ClearLink>
        </sc.Row>
        {initialQuery && `"${initialQuery}"`}
      </sc.Header>
    );
  };

const sc = {
  ClearLink: styled.div`
    font-size: 14px;
    text-decoration: underline;

    &:hover {
      cursor: pointer;
    }
  `,

  Header: styled.div`
    ${Text.MediumLLBold}
    padding: 20px;
    text-transform: uppercase;
  `,

  Row: styled(Row)`
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
  `,

  Title: styled.div`
    font-size: 28px;
  `,
};
