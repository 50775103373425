import * as t from "../types";
import { BaseDevice } from "./BaseDevice";

export enum LogEventAnalyticsType {
  RESET_EVENT = "resetEvent",
  TIME_SYNC_EVENT = "timeSyncEvent",
  UNSYNCED_LOGS_THRESHOLD = "unsyncedLogsThreshold",
}

export abstract class BaseLogStore {
  device: BaseDevice;
  logNotify?: BluetoothRemoteGATTCharacteristic;
  logRead?: BluetoothRemoteGATTCharacteristic;
  puffLogsCount = 0;
  shouldFetchLogs = true;
  unsyncedEvents: (t.Era.LogEvent | t.EraPro.LogEvent)[] = [];

  constructor(device: BaseDevice) {
    this.device = device;
  }

  abstract initialize(): Promise<void>;
  abstract disconnect(): void;
  abstract fetchLogs(): void;
  abstract handleLogsReceived(event: Event): void;
  abstract parseLogEvents(buffer: Uint8Array): void;
  abstract timeSyncLogs(
    timeDelta: bigint | number,
    logEventAnalyticsType: LogEventAnalyticsType
  ): void;
  abstract updateOffset(offset: number): void;

  protected getLogService(
    logServiceId: string
  ): Promise<BluetoothRemoteGATTService> {
    return this.device.gatt.getPrimaryService(logServiceId);
  }
}
