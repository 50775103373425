import React, { useState } from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import styled from "styled-components";

import * as r from "../../main/routes";
import { Banner } from "../../shared/components/Banner";

import * as text from "../text";
import { EmailVerificationRequest, SsoProps } from "../types";
import * as utils from "../utils";

export type SsoEmailVerificationBannerProps = SsoProps &
  RouteComponentProps & {
    email: string;
    sendEmailVerificationRequest: (
      r: EmailVerificationRequest
    ) => Promise<void>;
  };

export const SsoEmailVerificationBanner: React.FC<SsoEmailVerificationBannerProps> =
  ({
    email,
    goToSso = utils.goToSso,
    location,
    sendEmailVerificationRequest,
    ssoApiReturnTo = utils.ssoApiReturnTo,
  }) => {
    const currentPage = location.pathname;
    const returnTo = ssoApiReturnTo(currentPage);
    const [hasClickedVerify, setHasClickedVerify] = useState<boolean>(false);

    const handleLinkClick = async (): Promise<void> => {
      await sendEmailVerificationRequest({ email, returnTo });

      setHasClickedVerify(true);
      goToSso(
        r.ssoEmailVerificationRequestSent({
          returnTo: currentPage,
        })
      );
    };

    const bannerContent = hasClickedVerify ? (
      <span>{text.VERIFICATION_BANNER_CLICKED}</span>
    ) : (
      <span>
        {text.VERIFICATION_BANNER}
        <sc.UpdateLink
          to={currentPage}
          onClick={handleLinkClick}
          data-testid="send-verification-link"
        >
          {text.VERIFICATION_BANNER_LINK}
        </sc.UpdateLink>
      </span>
    );

    return (
      <Banner data-testid="email-verification-banner">{bannerContent}</Banner>
    );
  };

const sc = {
  UpdateLink: styled(Link)`
    color: var(--white);
    margin-left: 4px;
  `,
};
