import React from "react";
import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import ResizeObserver from "resize-observer-polyfill";

import * as consumerApi from "../api/consumer";
import { SignInUpsellModal } from "./components/SignInUpsellModal";
import * as c from "./constants";
import { MediaQueryChangeListener, SearchParam } from "./types";

type EventHandler = (event: React.TouchEvent | React.MouseEvent) => void;

export const useEventListener = (
  eventName: string,
  handler: EventHandler
): void => {
  const savedHandler = React.useRef<EventHandler>();

  React.useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  React.useEffect(() => {
    const eventHandler = (event: Event): void => {
      if (!savedHandler.current) return;
      savedHandler.current(event as unknown as React.MouseEvent);
    };

    window.addEventListener(eventName, eventHandler, { passive: false });

    return (): void => {
      window.removeEventListener(eventName, eventHandler);
    };
  }, [eventName]);
};

const useMediaQuery = (
  breakPoint: c.Breakpoint,
  initialMatch: boolean
): boolean => {
  const [isMatch, setIsMatch] = React.useState(initialMatch);

  React.useEffect(() => {
    // Ignore desktop check for all mobile devices
    if (isMobile && breakPoint === c.Breakpoint.DESKTOP) {
      setIsMatch(false);
      return;
    }

    const mediaQuery = window.matchMedia(breakPoint);

    const handleMediaQueryChange: MediaQueryChangeListener = (event) => {
      setIsMatch(event.matches);
    };

    try {
      mediaQuery.addEventListener("change", handleMediaQueryChange);
    } catch (err) {
      // addEventListener is not yet supported by Safari.
      mediaQuery.addListener(handleMediaQueryChange);
    }

    return (): void => {
      try {
        mediaQuery.removeEventListener("change", handleMediaQueryChange);
      } catch (err) {
        // addEventListener is not yet supported by Safari.
        mediaQuery.removeListener(handleMediaQueryChange);
      }
    };
  }, [breakPoint]);

  return isMatch;
};

export type UseDesktopMediaQuery = () => boolean;

export const useDesktopMediaQuery: UseDesktopMediaQuery = () => {
  const initialMatch = !isMobile && window.innerWidth > c.MOBILE_MAX_WIDTH;
  return useMediaQuery(c.Breakpoint.DESKTOP, initialMatch);
};

export const useSmallScreenMediaQuery = (): boolean => {
  const initialMatch = window.innerWidth <= c.SMALL_SCREEN_MAX_WIDTH;
  return useMediaQuery(c.Breakpoint.SMALL_SCREEN, initialMatch);
};

export const useBodyScrollOverflow = (
  overflow: string,
  autoSet = true
): React.Dispatch<React.SetStateAction<boolean>> => {
  const initialOverflow = React.useRef("");
  const [shouldSetOverflow, setShouldSetOverflow] =
    React.useState<boolean>(autoSet);

  React.useEffect(() => {
    initialOverflow.current = document.body.style.overflow;
  }, []);

  React.useEffect(() => {
    if (!shouldSetOverflow) {
      document.body.style.overflow = initialOverflow.current;
    } else {
      document.body.style.overflow = overflow;
    }

    return () => {
      document.body.style.overflow = initialOverflow.current;
    };
  }, [initialOverflow, overflow, shouldSetOverflow]);

  return setShouldSetOverflow;
};

export const useIsFirstRender = (): boolean => {
  const isFirstRender = React.useRef(true);

  if (!isFirstRender.current) return false;

  isFirstRender.current = false;
  return true;
};

export const useOutsideClickListener = (
  outsideClickHandler: () => void,
  ref: React.RefObject<HTMLDivElement>
): void => {
  React.useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        outsideClickHandler();
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [outsideClickHandler, ref]);
};

export const useResizeObserver = (
  elementRef: React.RefObject<HTMLDivElement>
): number => {
  const currentElement = elementRef.current;

  const observer = React.useRef<ResizeObserver | null>(null);

  const [containerHeight, setContainerHeight] = React.useState<number>(
    currentElement ? currentElement.clientHeight : 0
  );

  React.useEffect(() => {
    if (!currentElement) return;

    if (observer.current) {
      observer.current.unobserve(currentElement);
    }

    observer.current = new ResizeObserver((entries: ResizeObserverEntry[]) => {
      setContainerHeight(entries[0].contentRect.height);
    });
    observer.current.observe(currentElement);

    return () => {
      if (!currentElement || !observer?.current) return;
      observer.current.unobserve(currentElement);
    };
  }, [currentElement, elementRef]);

  return containerHeight;
};

export const useScrollThresholdListener = (threshold: number): boolean => {
  const [isPastThreshold, setIsPastThreshold] = React.useState<boolean>(false);

  React.useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      setIsPastThreshold(scrollTop > threshold);
    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => window.removeEventListener("scroll", handleScroll);
  }, [threshold]);

  return isPastThreshold;
};

export type UseSearchParam = (searchParam: SearchParam) => string | null;

export const useSearchParam = (searchParam: SearchParam): string | null => {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);

  return searchParams.get(searchParam);
};

export const useGetComponentWidth = (
  ref: React.RefObject<HTMLDivElement>,
  maxWidth?: number,
  minWidth?: number
): number => {
  const [width, setWidth] = React.useState<number>(0);

  const setWidthMinMax = React.useCallback(
    (width: number) => {
      if (maxWidth && width > maxWidth) setWidth(maxWidth);
      else if (minWidth && width < minWidth) setWidth(minWidth);
      else setWidth(width);
    },
    [maxWidth, minWidth]
  );

  React.useEffect(() => {
    const handleResize = () => {
      setWidthMinMax(ref.current ? ref.current.offsetWidth : 0);
    };

    setWidthMinMax(ref.current ? ref.current.offsetWidth : 0);

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [ref, maxWidth, minWidth, width, setWidthMinMax]);

  return width;
};

type UseSignInUpsellModal = (
  description: string,
  Icon: React.ReactElement,
  returnUrl: string,
  title: string,
  onRequestClose?: () => unknown
) => {
  handleShowSignInUpsell: () => void;
  signInUpsellModal: React.ReactElement;
};

export const useSignInUpsellModal: UseSignInUpsellModal = (
  description,
  Icon,
  returnUrl,
  title,
  onRequestClose
) => {
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
  const isUserSignedIn = useSelector(
    consumerApi.selectors.user.getIsUserSignedIn
  );

  const handleShowSignInUpsell = (): void => {
    if (isUserSignedIn) return;

    setIsModalOpen(true);
  };

  const handleModalClose = (): void => {
    setIsModalOpen(false);

    if (onRequestClose) onRequestClose();
  };

  const modal = (
    <SignInUpsellModal
      description={description}
      Icon={Icon}
      isOpen={isModalOpen}
      onRequestClose={handleModalClose}
      returnUrl={returnUrl}
      title={title}
    />
  );

  return { handleShowSignInUpsell, signInUpsellModal: modal };
};
