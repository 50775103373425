import React, { useEffect, useState } from "react";
import styled, { SimpleInterpolation } from "styled-components";

import * as PaxBle from "../../../pax-ble";

import { SessionProgressBar } from "../../device/components/SessionProgressBar";
import { Dropdown } from "../../shared/components/Dropdown";
import { QuestionMarkWhite } from "../../shared/components/Icons";
import * as ps from "../../shared/components/PillSelect";
import * as Text from "../../shared/components/Text";
import { WHATS_DOSE_CONTROL } from "../../shared/text";

import { StartEraProSession, StopEraProSession } from "../actions";
import { ERA_PRO_DOSE_OPTIONS, ERA_PRO_LOCKOUT_TIME } from "../constants";
import { useDoseStatus } from "../hooks";
import DoseControlExplainerModal from "./DoseControlExplainerModal";

export type StateProps = {
  isDeviceLocked: boolean;
  isPodInserted: boolean;
  session?: PaxBle.Types.EraPro.Session;
};

export type EraProDoseControlMobileProps = StateProps & {
  onStartEraProSession: StartEraProSession;
  onStopEraProSession: StopEraProSession;
};

export const EraProDoseControlMobile: React.FC<EraProDoseControlMobileProps> =
  ({
    isDeviceLocked,
    isPodInserted,
    session,
    onStartEraProSession,
    onStopEraProSession,
  }) => {
    const [selectedOption, setSelectedOption] = useState<ps.PillSelectOption>();
    const [isExplainerModalOpen, setIsExplainerModalOpen] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);

    const { isSessionInProgress, shouldShowTimeout } = useDoseStatus(session);

    useEffect(() => {
      if (!isPodInserted) {
        setSelectedOption(undefined);
      }
      if (isDeviceLocked) {
        setIsDisabled(true);
      } else {
        setIsDisabled(false);
      }
    }, [isDeviceLocked, isPodInserted, setSelectedOption, selectedOption]);

    const handleDoseChange = (selectedOption: ps.PillSelectOption): void => {
      const presetDoseId = selectedOption.value;
      setSelectedOption(selectedOption);
      onStartEraProSession(presetDoseId);
    };

    const handleSessionCancelClick = (e: React.MouseEvent): void => {
      e.preventDefault();
      onStopEraProSession();
      setSelectedOption(undefined);
    };

    const handleQuestionMarkClick = (): void => {
      setIsExplainerModalOpen(true);
    };

    const handleExplainerModalRequestClose = (): void => {
      setIsExplainerModalOpen(false);
    };

    if (
      !session ||
      session.action === PaxBle.Types.EraPro.EraProSessionAction.INACTIVE
    ) {
      return (
        <>
          <sc.Container isDisabled={isDisabled}>
            <Dropdown
              data-testid="dose-control-dropdown"
              key={`select_key_${selectedOption}`}
              options={ERA_PRO_DOSE_OPTIONS}
              placeholder="Dose Control"
              onChange={handleDoseChange}
              value={selectedOption}
            />
            <sc.ModalOpener
              data-testid="explainer-modal-opener"
              onClick={handleQuestionMarkClick}
            >
              <sc.QuestionMark />
              {WHATS_DOSE_CONTROL}
            </sc.ModalOpener>
          </sc.Container>
          <DoseControlExplainerModal
            isOpen={isExplainerModalOpen}
            onRequestClose={handleExplainerModalRequestClose}
          />
        </>
      );
    }

    const { doseId, usageTowardCompletion, initialTotalUsage } = session;
    const nDoses = `${doseId} dose${doseId > 1 ? "s" : ""}`;
    const percentageCompleted = Math.round(
      (100 * usageTowardCompletion) / initialTotalUsage
    );

    return (
      <sc.Container isDisabled={isDisabled}>
        <SessionProgressBar
          initialLockoutSeconds={ERA_PRO_LOCKOUT_TIME}
          label={nDoses}
          onCancelClick={handleSessionCancelClick}
          progressPercentage={isSessionInProgress ? percentageCompleted : 0}
          showTimeout={shouldShowTimeout}
        />
      </sc.Container>
    );
  };

type ContainerProps = {
  isDisabled: boolean;
};

const sc = {
  Container: styled.div<ContainerProps>`
    opacity: ${({ isDisabled }): SimpleInterpolation =>
      isDisabled ? "0.3" : "1"};
    padding: 10px 8px 0;
    pointer-events: ${({ isDisabled }): SimpleInterpolation =>
      isDisabled ? "none" : "auto"};

    & > progress {
      width: 100%;
    }
  `,

  ModalOpener: styled.div`
    ${Text.PlaaxItalicLight}
    align-items: flex-end;
    color: var(--brownish-grey);
    display: flex;
    font-size: 14px;
    margin-top: 12px;
  `,

  QuestionMark: styled(QuestionMarkWhite)`
    background-color: var(--brown-grey);
    border-radius: 100%;
    height: 14px;
    margin-right: 5px;
    width: 14px;
  `,

  Text: styled.div`
    ${Text.PlaaxLight}
    font-size: 14px;
    margin-bottom: 20px;
    text-align: center;
  `,
};
