import { DeviceModel, DeviceShellColor } from "../api/consumer/types/device";
import * as c from "./constants";

export const getFindMyPaxMapIconUrl = (
  model: DeviceModel,
  shellColor: DeviceShellColor,
  muted?: boolean
): string => {
  const iconMap = c.FindMyPaxIconsMap[model][shellColor];

  if (!iconMap) return "";
  return (
    c.PUBLIC_ASSETS_BASE_URL + (muted ? iconMap.mutedMarker : iconMap.marker)
  );
};
