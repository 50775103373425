import React from "react";
import styled from "styled-components";

import * as Card from "../../../shared/components/Card";
import * as Text from "../../../shared/components/Text";

const CARD_TITLE = "Pod Test Results";

const PodTestResultsCardNoPodSelected: React.FC = () => {
  return (
    <Card.Small data-testid="pod-test-results-card-no-pod-selected">
      <sc.TitleRow>
        <sc.Title>{CARD_TITLE}</sc.Title>
      </sc.TitleRow>
      <sc.Text>Select pod to view test report</sc.Text>
    </Card.Small>
  );
};

const sc = {
  Text: styled.div`
    ${Text.PlaaxLight}
    color: var(--black);
    font-size: 14px;
    line-height: 1.14;
  `,

  Title: styled.h3`
    ${Text.Plaax}
    color: var(--black);
    font-size: 20px;
    margin: 0 0 8px;
    text-transform: uppercase;
  `,

  TitleRow: styled.div`
    position: relative;
    text-align: center;
    width: 100%;
  `,
};

export default PodTestResultsCardNoPodSelected;
