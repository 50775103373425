import React from "react";
import styled from "styled-components";

import * as Text from "../../shared/components/Text";

type Pax3ColorModeProps = {
  borderColor?: string;
  color: string;
  isSelected?: boolean;
  onClick: () => void;
  title: string;
};

type Pax3ColorModeCircleProps = {
  borderColor?: string;
  color: string;
};

type Pax3ColorModeNameProps = {
  bold?: boolean;
};

const Pax3ColorMode: React.FC<Pax3ColorModeProps> = ({
  borderColor,
  color,
  isSelected,
  onClick,
  title,
}) => {
  if (isSelected) {
    borderColor = "black";
  }

  return (
    <sc.ColorMode onClick={onClick} data-testid="pax-3-color-mode">
      <sc.ColorModeCircle color={color} borderColor={borderColor} />
      <sc.ColorModeName bold={isSelected}>{title}</sc.ColorModeName>
    </sc.ColorMode>
  );
};

const sc = {
  ColorMode: styled.div`
    cursor: pointer;
  `,

  ColorModeCircle: styled.div<Pax3ColorModeCircleProps>`
    background-color: ${(props) => `${props.color}`};
    border: ${(props) =>
      props.borderColor ? `solid 1px var(--${props.borderColor})` : "none"};
    border-radius: 100%;
    height: 36px;
    margin: 0 auto;
    width: 36px;
  `,

  ColorModeName: styled.div<Pax3ColorModeNameProps>`
    ${Text.PlaaxLight}
    font-size: 14px;
    font-weight: ${(props) => (props.bold ? "bold" : "normal")};
    margin-top: 8px;
    text-transform: uppercase;
  `,
};

export default Pax3ColorMode;
