import * as consumerApi from "../api/consumer";
import { Action, AppThunk } from "../shared/types";
import * as c from "./constants";
import * as t from "./types";

const createSetViewedStatusAction = (
  announcementCode: c.FEATURE_ANNOUNCEMENT_CODE,
  hasBeenViewed: boolean
): t.SetViewedStatusAction => ({
  payload: {
    announcementCode,
    hasBeenViewed,
  },
  type: c.SET_VIEWED_STATUS,
});

export type FetchViewedStatus = (
  announcementCode: c.FEATURE_ANNOUNCEMENT_CODE
) => AppThunk;

export const fetchViewedStatus: FetchViewedStatus =
  (announcementCode): AppThunk =>
  async (dispatch): Promise<void> => {
    const { viewed } =
      await consumerApi.fetch<consumerApi.types.announcements.AnnouncementJson>(
        consumerApi.paths.announcements.announcements(announcementCode)
      );

    dispatch(createSetViewedStatusAction(announcementCode, viewed));
  };

export type MarkAsViewed = (
  announcementCode: c.FEATURE_ANNOUNCEMENT_CODE
) => AppThunk;

export const markAsViewed: MarkAsViewed =
  (announcementCode) =>
  async (dispatch): Promise<void> => {
    consumerApi.fetch(
      consumerApi.paths.announcements.announcements(announcementCode, true),
      {
        method: "POST",
      }
    );

    dispatch(createSetViewedStatusAction(announcementCode, true));
  };

type ResetState = () => Action;

export const resetState: ResetState = () => ({
  type: c.RESET_ANNOUNCEMENTS_STATE,
});
