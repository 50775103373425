import { navigator } from "../navigator";
import { EraDevice } from "./era/EraDevice";
import { EraProDevice } from "./era-pro/EraProDevice";
import { Pax3Device } from "./pax-3/Pax3Device";
import { BaseDevice } from "./shared/BaseDevice";
import * as c from "./constants";
import * as t from "./types";

export * as Utils from "./utils";
export {
  BaseDevice,
  EraDevice,
  EraProDevice,
  Pax3Device,
  c as Constants,
  t as Types,
};

type NullableRequestDeviceMock = (
  options: t.DeviceRequestOptions
) => Promise<BluetoothDevice>;

export type RequestDevice = (
  deviceType: t.DeviceType | null | undefined,
  requestDeviceMock?: NullableRequestDeviceMock
) => Promise<BaseDevice>;

export const requestDevice: RequestDevice = async (
  deviceType,
  requestDeviceMock?
) => {
  let deviceRequestOptions: t.DeviceRequestOptions = { acceptAllDevices: true };
  if (deviceType) {
    deviceRequestOptions = DeviceRequestOptionsMap[deviceType];
  }

  let bluetoothDevice: BluetoothDevice;
  try {
    // The browser throws an error if we store `navigator.bluetooth.requestDevice` in a variable and then executing it.
    if (requestDeviceMock) {
      bluetoothDevice = await requestDeviceMock(deviceRequestOptions);
    } else {
      bluetoothDevice = await navigator.bluetooth.requestDevice(
        deviceRequestOptions
      );
    }
  } catch (e) {
    // User canceled the device chooser or unknown error.
    throw new Error(c.ERR_DEVICE_CONNECTION_MESSAGE);
  }

  if (!bluetoothDevice.gatt) throw new Error("Device is not compatible.");

  let gatt: BluetoothRemoteGATTServer;
  try {
    gatt = await bluetoothDevice.gatt.connect();
  } catch (e) {
    throw new Error(c.ERR_DEVICE_CONNECTION_MESSAGE);
  }

  if (!deviceType) {
    throw new Error("TODO read model from device and set deviceType.");
  }

  const DeviceClass = DeviceClassMap[deviceType];
  const device = new DeviceClass(bluetoothDevice, gatt);

  return device;
};

const DeviceClassMap = {
  [t.DeviceType.ERA]: EraDevice,
  [t.DeviceType.ERA_PRO]: EraProDevice,
  [t.DeviceType.PAX_3]: Pax3Device,
  [t.DeviceType.PAX_35]: Pax3Device,
};

const K4_5X_ENCRYPTED_DEVICE_NAME = "DeviceX";

const DeviceRequestOptionsMap: {
  [key: string]: t.DeviceRequestOptions;
} = {
  [t.DeviceType.ERA]: {
    filters: [
      { services: [c.ERA_PAX_SERVICE_UUID] },
      { services: [c.SMP_SERVICE_UUID] },
      { services: [c.DEVICE_INFO_SERVICE_UUID] },
    ],
    optionalServices: [c.DEVICE_INFO_SERVICE_UUID, c.ERA_LOG_SERVICE_UUID],
  },
  [t.DeviceType.ERA_PRO]: {
    filters: [
      { name: K4_5X_ENCRYPTED_DEVICE_NAME },
      { services: [c.ERA_PRO_LOG_SERVICE_UUID] },
      { services: [c.ERA_PRO_PAX_SERVICE_UUID] },
      { services: [c.SMP_SERVICE_UUID] },
    ],
    optionalServices: [c.DEVICE_INFO_SERVICE_UUID],
  },
  [t.DeviceType.PAX_3]: {
    filters: [
      { services: [c.ERA_PAX_SERVICE_UUID] },
      { services: [c.SMP_SERVICE_UUID] },
    ],
    optionalServices: [c.DEVICE_INFO_SERVICE_UUID],
  },
  [t.DeviceType.PAX_35]: {
    filters: [
      { services: [c.ERA_PAX_SERVICE_UUID] },
      { services: [c.SMP_SERVICE_UUID] },
    ],
    optionalServices: [c.DEVICE_INFO_SERVICE_UUID],
  },
};
