import React, { lazy, Suspense } from "react";
import TagManager from "react-gtm-module";
import { Redirect, Route, Switch } from "react-router-dom";
import { ThemeProvider } from "styled-components";

import { navigator } from "../../../navigator";

import { DeviceRoutes } from "../../device/DeviceRoutes";
import * as ff from "../../featureFlags";
import { AccountRoutes } from "../../my-pax/AccountRoutes";
import { MyPaxRoutes } from "../../my-pax/MyPaxRoutes";
import { MobilePaxNav } from "../../pax-nav/components/mobile/MobilePaxNav";
import { PodQRCodeRedirect } from "../../pods/components/PodQRCodeRedirect";
import { ExploreRoutes } from "../../pods/ExploreRoutes";
import { BannerContainer } from "../../shared/components/BannerContainer/BannerContainer";
import * as Layout from "../../shared/components/Layout";
import { useDesktopMediaQuery } from "../../shared/hooks";
import { SsoRoutes } from "../../sso/SsoRoutes";
import { useResponsiveTheme } from "../../themes/hooks";

import { useAppHooks } from "../hooks";
import { ResponsiveNavBar } from "./ResponsiveNavBar";
import { Topbar } from "./Topbar";

const TestPage = lazy(() => import("../../test-page/TestPage"));

const isProduction = process.env.NODE_ENV === "production";
const isTest = process.env.NODE_ENV === "test";
const gtmAuthDev = process.env.REACT_APP_WEB_GTM_AUTH_DEVELOPMENT || "";
const gtmAuthProd = process.env.REACT_APP_WEB_GTM_AUTH_PRODUCTION || "";
const gtmId = process.env.REACT_APP_WEB_GTM_ID || "";

const tagManagerArgs = {
  auth: isProduction ? gtmAuthProd : gtmAuthDev,
  gtmId: isTest ? "mockId" : gtmId,
  preview: isProduction ? "env-1" : "env-4",
};

TagManager.initialize(tagManagerArgs);

export const MainApp: React.FC = ({ children }) => {
  useAppHooks();
  const theme = useResponsiveTheme();
  const isDesktop = useDesktopMediaQuery();

  return (
    <ThemeProvider theme={theme}>
      <Layout.Container>
        {!isDesktop && <MobilePaxNav />}
        <Topbar />
        <Suspense fallback="">
          <Switch>
            <Route path="/sso" render={SsoRoutes} />
            <Route path="/">
              <Layout.App>
                <BannerContainer />
                <Switch>
                  {!!navigator.bluetooth && (
                    <Route path="/device" render={DeviceRoutes} />
                  )}
                  <Route path="/explore" render={ExploreRoutes} />
                  <Route
                    path="/scan/pods/:strainId"
                    component={PodQRCodeRedirect}
                  />
                  <Route path="/account" render={AccountRoutes} />
                  {/*
                    All my-pax routes redirect to /account/... paths to avoid
                    confusion with MyPAX Personalization
                  */}
                  <Route path="/my-pax" render={MyPaxRoutes} />
                  {ff.isTestPageEnabled && (
                    <Route exact path="/_test" component={TestPage} />
                  )}
                  {children}
                  <Redirect from="*" to="/" />
                </Switch>
              </Layout.App>
              <ResponsiveNavBar />
            </Route>
          </Switch>
        </Suspense>
      </Layout.Container>
    </ThemeProvider>
  );
};
