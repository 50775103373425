import { remove as lsRemove } from "local-storage";

import { BaseDevice } from "../../pax-ble";

import { LS_KEY_IS_DEVICE_CONNECTED } from "../device/constants";

type GetConnectedDevice = () => BaseDevice | undefined;

export const getConnectedDevice: GetConnectedDevice = () =>
  window.paxConnectedDevice;

export const setConnectedDevice = (device: BaseDevice): void => {
  window.paxConnectedDevice = device;
};

export const disconnectConnectedDevice = (): void => {
  lsRemove(LS_KEY_IS_DEVICE_CONNECTED);

  if (!window.paxConnectedDevice) return;

  window.paxConnectedDevice.disconnect();
  delete window.paxConnectedDevice;
};
