import AccountSettings from "../../assets/images/my-pax-cards/account-settings.jpg";
import Era from "../../assets/images/my-pax-cards/era-card.jpg";
import EraProBlackCard from "../../assets/images/my-pax-cards/era-pro-black-card.jpg";
import EraProJadeCard from "../../assets/images/my-pax-cards/era-pro-jade-card.jpg";
import EraProRedCard from "../../assets/images/my-pax-cards/era-pro-red-card.jpg";
import EraProSapphireCard from "../../assets/images/my-pax-cards/era-pro-sapphire-card.png";
import EraProSilverCard from "../../assets/images/my-pax-cards/era-pro-silver-card.jpg";
import MyPods from "../../assets/images/my-pax-cards/my-pods.jpg";
import Pax3AmberCard from "../../assets/images/my-pax-cards/p-3-amber-card.png";
import Pax3BlueCard from "../../assets/images/my-pax-cards/p-3-blue-card.jpg";
import Pax3GlossyBlackCard from "../../assets/images/my-pax-cards/p-3-glossy-black-card.jpg";
import Pax3GlossyGoldCard from "../../assets/images/my-pax-cards/p-3-glossy-gold-card.jpg";
import Pax3GlossyRedCard from "../../assets/images/my-pax-cards/p-3-glossy-red-card.jpg";
import Pax3GlossyRoseGoldCard from "../../assets/images/my-pax-cards/p-3-glossy-rose-gold-card.jpg";
import Pax3GlossySilverCard from "../../assets/images/my-pax-cards/p-3-glossy-silver-card.jpg";
import Pax3MatteBlackCard from "../../assets/images/my-pax-cards/p-3-matte-black-card.jpg";
import Pax3MatteFuchsiaCard from "../../assets/images/my-pax-cards/p-3-matte-fuchsia-card.jpg";
import Pax3MatteRoseGoldCard from "../../assets/images/my-pax-cards/p-3-matte-rose-gold-card.jpg";
import Pax3MatteSilverCard from "../../assets/images/my-pax-cards/p-3-matte-silver-card.jpg";
import Pax3MatteTealCard from "../../assets/images/my-pax-cards/p-3-matte-teal-card.jpg";
import UnknownDeviceCard from "../../assets/images/my-pax-cards/unknown-device.jpg";

const EraPro = {
  BLACK: EraProBlackCard,
  JADE: EraProJadeCard,
  RED: EraProRedCard,
  SAPPHIRE: EraProSapphireCard,
  SILVER: EraProSilverCard,
};

const Pax3 = {
  AMBER: Pax3AmberCard,
  BLUE: Pax3BlueCard,
  GLOSSY_BLACK: Pax3GlossyBlackCard,
  GLOSSY_GOLD: Pax3GlossyGoldCard,
  GLOSSY_RED: Pax3GlossyRedCard,
  GLOSSY_ROSE_GOLD: Pax3GlossyRoseGoldCard,
  GLOSSY_SILVER: Pax3GlossySilverCard,
  MATTE_BLACK: Pax3MatteBlackCard,
  MATTE_FUCHSIA: Pax3MatteFuchsiaCard,
  MATTE_ROSE_GOLD: Pax3MatteRoseGoldCard,
  MATTE_SILVER: Pax3MatteSilverCard,
  MATTE_TEAL: Pax3MatteTealCard,
};

export { AccountSettings, UnknownDeviceCard, MyPods, Era, EraPro, Pax3 };
