import { History } from "history";
import React, { Dispatch, SetStateAction } from "react";

import { Potency } from "../api/consumer/types/strain";
import { REGION_STATES } from "../data/regionStates";
import { podsSearch } from "../main/routes";
import { PillSelectOption } from "../shared/components/PillSelect";
import * as text from "../shared/text";
import { removeUnderscore } from "../shared/utils";
import { buildStrainsFilter } from "./models/StrainsFilter";
import * as c from "./constants";
import { StrainsFilter } from "./types";

export const getCoaLegalDisclaimerForGeoState = (geoState?: string): string => {
  switch (geoState) {
    case "US-AZ":
    case "US-CO":
    case "US-MA":
    case "US-WA":
      return text.coaPassedRegulationsInLocation(getGeoStateLabel(geoState));
    default:
      return text.COA_PASSED_REGULATIONS;
  }
};

export const getGeoStateLabel = (geoState?: string): string => {
  if (!geoState) return "";

  const region = REGION_STATES[geoState.slice(0, 2)];
  if (!region) return "";

  return region[geoState]?.label || "";
};

export type GoToPodsSearchPage = (
  history: History,
  showRecentSearches?: boolean
) => void;

export const goToPodsSearchPage: GoToPodsSearchPage = (
  history,
  showRecentSearches = false
) => {
  const strainsFilter = buildStrainsFilter({});

  history.push(podsSearch(strainsFilter), {
    showRecentSearches: showRecentSearches,
  });
};

const isValidUSZipCode = (code: string): boolean => {
  return /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(code);
};

const isValidCanadianPostalCode = (code: string): boolean => {
  return /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/.test(code);
};

export const isValidPostalCode = (postalCode: string): boolean => {
  return isValidCanadianPostalCode(postalCode) || isValidUSZipCode(postalCode);
};

export const getLegalDisclaimer = (
  isPaxStrain: boolean,
  partnerName: string | undefined
): string => {
  return isPaxStrain
    ? text.informationAboutHazePod
    : text.informationAboutPod(partnerName);
};

export const getStrainPillOptions = (strains: string[]): PillSelectOption[] => {
  return c.STRAIN_CLASSIFICATIONS.map((strainOptionProps) => {
    const active = strains.includes(strainOptionProps.value);
    return {
      active,
      activeBackgroundColor: strainOptionProps.activeBackgroundColor,
      label: strainOptionProps.value,
      value: strainOptionProps.value,
    };
  });
};

export const getEffectOptions = (effects: string[]): PillSelectOption[] => {
  return c.STRAIN_SELECTED_EFFECTS.map((strainOptionProps) => {
    const active = effects.includes(strainOptionProps.value);
    return {
      Icon: (
        <strainOptionProps.Icon
          color={active ? "var(--white)" : "var(--black)"}
        />
      ),
      active,
      label: strainOptionProps.value,
      value: strainOptionProps.value,
    };
  });
};

export const getPotencyOptions = (potency: string[]): PillSelectOption[] => {
  return c.STRAIN_POTENCY.map((strainOptionProps) => {
    const active = potency.includes(strainOptionProps.value);
    return {
      active,
      label: strainOptionProps.label,
      value: strainOptionProps.value,
    };
  });
};

export const getFlavorOptions = (flavors: string[]): PillSelectOption[] => {
  return c.STRAIN_FLAVORS_DATA.map((strainOptionProps) => {
    const active = flavors.includes(strainOptionProps.value);
    return {
      Icon: (
        <strainOptionProps.Icon
          color={active ? "var(--white)" : "var(--black)"}
        />
      ),
      active,
      label: removeUnderscore(strainOptionProps.value),
      value: strainOptionProps.value,
    };
  });
};

export const handlePillOptionChange = (
  changedOption: PillSelectOption,
  filterData: string[] | Potency[],
  filterKey: string,
  filterUpdate: StrainsFilter,
  setFilterUpdate: Dispatch<SetStateAction<StrainsFilter>>
): void => {
  const { active, value } = changedOption;

  const newFilterData = (filterData || []).filter((data) => data !== value);

  if (!active && !newFilterData.includes(value)) {
    newFilterData.push(value);
  }

  setFilterUpdate({
    ...filterUpdate,
    [filterKey]: newFilterData,
  });
};
