import { connect } from "react-redux";

import * as t from "../../../main/types";
import { showFlashBanner } from "../../../shared/components/FlashBanner";

import { setHeaterSetPoint } from "../../actions";
import {
  MobileThermostat,
  StateProps,
} from "../../components/MobileThermostat/MobileThermostat";
import * as s from "../../selectors";

const mapStateToProps = (state: t.MainAppState): StateProps => ({
  deviceTemperature: s.getDeviceTemperature(state),
  isDeviceLocked: s.getIsDeviceLocked(state),
  podStrainId: s.getDevicePodStrainId(state),
  temperatureRange: s.getTemperatureRange(state),
  temperatureUnit: s.getPreferredTemperatureUnit(state),
});

const mapDispatchToProps = {
  onChange: setHeaterSetPoint,
  showFlashBanner: showFlashBanner,
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileThermostat);
