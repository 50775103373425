// TODO: remove use of any
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactNode } from "react";
import styled, { css } from "styled-components";

import { PhotoJson, VideoJson } from "../../api/consumer/types";

import { PlayButton } from "./Icons";

type RenderMediaThumbnailsProps = {
  onMediaClick?: (index: number) => any;
  photos?: PhotoJson[];
  videos?: VideoJson[];
};

export const renderMediaThumbnails = ({
  onMediaClick,
  photos,
  videos,
}: RenderMediaThumbnailsProps): ReactNode[] => {
  const videoThumbnails =
    videos?.map((video, videoIndex) => {
      return (
        <sc.VideoContainer
          key={video.fileId}
          onClick={() => onMediaClick && onMediaClick(videoIndex)}
        >
          <sc.Video>
            <source src={video.downloadUrl} type="video/mp4" />
          </sc.Video>
          <sc.PlayButtonIcon color="rgba(255, 255, 255, 0.9)" size={60} />
        </sc.VideoContainer>
      );
    }) || [];

  const photoThumbnails =
    photos?.map((photo, photoIndex) => {
      return (
        <sc.Photo
          key={photo.fileId}
          onClick={() =>
            onMediaClick && onMediaClick(videoThumbnails.length + photoIndex)
          }
          url={photo.downloadUrl}
        />
      );
    }) || [];

  return [...videoThumbnails, ...photoThumbnails];
};

const thumbnailDimensions = css`
  height: 180px;
  width: 272px;
`;

const mediaContainerStyles = css`
  ${thumbnailDimensions}
  cursor: pointer;
  margin-right: 8px;
  min-width: 272px;

  &:last-child {
    margin-right: 0;
  }
`;

const mediaStyles = css`
  ${thumbnailDimensions}
  border-radius: 8px;
`;

const sc = {
  Container: styled.div`
    background-color: var(--black);
    border-radius: 8px;
    padding: 60px 40px;
    width: 420px;
  `,

  Photo: styled.div<{ url: string }>`
    ${mediaContainerStyles}
    ${mediaStyles}
    background: url(${(props) => props.url}) no-repeat center center;
    background-size: cover;
  `,

  PlayButtonIcon: styled(PlayButton)`
    position: absolute;
    right: calc(50% - ${(props) => props.size / 2}px);
    top: calc(50% - ${(props) => props.size / 2}px);
  `,

  Video: styled.video`
    ${mediaStyles}
    background-color: var(--black);
  `,

  VideoContainer: styled.div`
    ${mediaContainerStyles}
    position: relative;
  `,
};
