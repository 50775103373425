import React, { Suspense, useEffect, useState } from "react";
import * as r from "react-redux";

import * as userSelectors from "../api/consumer/selectors/user";
import { MainAppState } from "../main/types";
import { ModalProps } from "../modal/components/Modal";
import { UseDispatch } from "../shared/types";
import * as a from "./actions";
import * as c from "./constants";
import * as s from "./selectors";

type MarkAnnouncementAsViewed = () => void;

type UseAnnouncement = (
  announcementCode: c.FEATURE_ANNOUNCEMENT_CODE,
  fetchViewedStatus?: a.FetchViewedStatus,
  getAuthenticationToken?: userSelectors.GetAuthenticationToken,
  getHasBeenViewed?: s.GetHasBeenViewed,
  markAsViewed?: a.MarkAsViewed,
  useDispatch?: UseDispatch
) => [React.ReactNode, boolean, MarkAnnouncementAsViewed];

export const useAnnouncement: UseAnnouncement = (
  announcementCode,
  fetchViewedStatus = a.fetchViewedStatus,
  getAuthenticationToken = userSelectors.getAuthenticationToken,
  getHasBeenViewed = s.getHasBeenViewed,
  markAsViewed = a.markAsViewed,
  useDispatch = r.useDispatch
) => {
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const token = r.useSelector(getAuthenticationToken);
  const hasBeenViewed = r.useSelector((state: MainAppState) =>
    getHasBeenViewed(state, announcementCode)
  );

  useEffect(() => {
    if (hasBeenViewed !== undefined || !token) return;

    dispatch(fetchViewedStatus(announcementCode));
  }, [announcementCode, dispatch, fetchViewedStatus, hasBeenViewed, token]);

  useEffect(() => {
    setIsModalOpen(hasBeenViewed === false);
  }, [hasBeenViewed]);

  const markAnnouncementAsViewed = (): void => {
    dispatch(markAsViewed(announcementCode));
  };

  const modalProps: ModalProps = {
    isOpen: isModalOpen,
    onRequestClose: markAnnouncementAsViewed,
  };

  const Modal = c.FeatureAnnouncementModalMap[announcementCode];

  const modal = (
    <Suspense fallback="">
      <Modal {...modalProps} />
    </Suspense>
  );

  return [modal, isModalOpen, markAnnouncementAsViewed];
};
