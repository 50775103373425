import React, { useEffect, useState } from "react";
import { Redirect } from "react-router";
import styled from "styled-components";

import { navigator } from "../../../navigator";
import * as PaxBle from "../../../pax-ble";

import { ConnectDevice } from "../../device/components/ConnectDevice";
import * as r from "../../main/routes";
import { ModalAction } from "../../modal/actions";

export type StateProps = {
  connectedDevice?: PaxBle.BaseDevice;
  deviceError?: string;
  hasAcceptedPrivacyPolicy: boolean;
  isPrivacyPolicyModalOpen: boolean;
  isShareDataFlagUnsetForUser: boolean;
  isUsageAgreementModalOpen: boolean;
};

export type DispatchProps = {
  closePrivacyPolicyModal: ModalAction;
  closeUsageAgreementModal: ModalAction;
  openPrivacyPolicyModal: ModalAction;
  openUsageAgreementModal: ModalAction;
};

export type ConnectDevicePageProps = StateProps & DispatchProps;

export const ConnectDevicePage: React.FC<ConnectDevicePageProps> = ({
  connectedDevice,
  closePrivacyPolicyModal,
  closeUsageAgreementModal,
  deviceError,
  hasAcceptedPrivacyPolicy,
  isPrivacyPolicyModalOpen,
  isShareDataFlagUnsetForUser,
  isUsageAgreementModalOpen,
  openPrivacyPolicyModal,
  openUsageAgreementModal,
}) => {
  const [shouldShowPrivacyPolicyModal, setShouldShowPrivacyPolicyModal] =
    useState<boolean>(!hasAcceptedPrivacyPolicy);
  const [shouldShowUsageAgreementModal, setShouldShowUsageAgreementModal] =
    useState<boolean>(isShareDataFlagUnsetForUser);

  // Update state if the flag changed after associating the account device
  useEffect(() => {
    setShouldShowUsageAgreementModal(isShareDataFlagUnsetForUser);
  }, [isShareDataFlagUnsetForUser]);

  // Open privacy policy modal first if it hasn't been seen.
  useEffect(() => {
    if (
      !connectedDevice ||
      !shouldShowPrivacyPolicyModal ||
      isPrivacyPolicyModalOpen ||
      isUsageAgreementModalOpen
    )
      return;

    openPrivacyPolicyModal();
    setShouldShowPrivacyPolicyModal(false);
  }, [
    connectedDevice,
    isPrivacyPolicyModalOpen,
    isUsageAgreementModalOpen,
    openPrivacyPolicyModal,
    shouldShowPrivacyPolicyModal,
  ]);

  // Open data usage modal after privacy policy modal is closed (if it was opened).
  useEffect(() => {
    if (
      !connectedDevice ||
      !shouldShowUsageAgreementModal ||
      shouldShowPrivacyPolicyModal ||
      isPrivacyPolicyModalOpen ||
      isUsageAgreementModalOpen
    )
      return;

    openUsageAgreementModal();
    setShouldShowUsageAgreementModal(false);
  }, [
    connectedDevice,
    isPrivacyPolicyModalOpen,
    isUsageAgreementModalOpen,
    openUsageAgreementModal,
    shouldShowPrivacyPolicyModal,
    shouldShowUsageAgreementModal,
  ]);

  // Close all modals and try again if device error
  useEffect(() => {
    if (!deviceError) return;

    closePrivacyPolicyModal();
    closeUsageAgreementModal();
    setShouldShowPrivacyPolicyModal(!hasAcceptedPrivacyPolicy);
    setShouldShowUsageAgreementModal(isShareDataFlagUnsetForUser);
  }, [
    closePrivacyPolicyModal,
    closeUsageAgreementModal,
    deviceError,
    isShareDataFlagUnsetForUser,
    hasAcceptedPrivacyPolicy,
  ]);

  // Redirect only if the privacy policy and usage modals have been shown or skipped.
  if (
    connectedDevice &&
    navigator.onLine &&
    !isPrivacyPolicyModalOpen &&
    !isUsageAgreementModalOpen &&
    !shouldShowPrivacyPolicyModal &&
    !shouldShowUsageAgreementModal
  ) {
    return <Redirect to={r.device(connectedDevice.type)} />;
  }

  return (
    <sc.Container>
      <ConnectDevice />
    </sc.Container>
  );
};

const sc = {
  Container: styled.div`
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-top: 268px;
    max-width: 1012px;
    padding-left: 165px;
  `,
};
