import { connect } from "react-redux";

import { connectToDevice, setDeviceError } from "../../device/actions";
import * as s from "../../device/selectors";
import * as t from "../../main/types";

import { PairingInstructions } from "../components/PairingInstructions";

const mapStateToProps = (state: t.MainAppState) => {
  const deviceType = s.getSelectedDeviceType(state);
  const serial = s.getDeviceSerialNumber(state, deviceType) || "";

  return {
    deviceError: s.getDeviceError(state),
    deviceType,
    isDeviceConnected: s.getIsDeviceConnected(state),
    isDeviceConnecting: s.getIsDeviceConnecting(state),
    serial,
  };
};

const mapDispatchToProps = {
  connectToDevice,
  setDeviceError,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PairingInstructions);
