import React from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

import * as r from "../../main/routes";
import { ModalAction } from "../../modal/actions";
import { Modal } from "../../modal/components/Modal";
import Button from "../../shared/components/Button";
import { PaxLogo } from "../../shared/components/Icons";
import * as Text from "../../shared/components/Text";
import { PRIVACY_POLICY_URL } from "../../shared/constants";
import { WELCOME_TO_PAX } from "../../shared/text";
import { desktopBreakpoint } from "../../shared/utils";

export type StateProps = {
  isPrivacyPolicyModalOpen: boolean;
};

export type DispatchProps = {
  closePrivacyPolicyModal: ModalAction;
  setHasAcceptedPrivacyPolicy: ModalAction;
};

export type PrivacyPolicyModalProps = StateProps & DispatchProps;

export const PrivacyPolicyModal: React.FC<PrivacyPolicyModalProps> = ({
  isPrivacyPolicyModalOpen,
  ...props
}) => {
  const modalProps = {
    hideCloser: true,
    isOpen: isPrivacyPolicyModalOpen,
  };

  return (
    <Modal data-testid="privacy-policy-modal" {...modalProps}>
      <PrivacyPolicyModalContent {...props} />
    </Modal>
  );
};

const PrivacyPolicyModalContent: React.FC<DispatchProps> = ({
  closePrivacyPolicyModal,
  setHasAcceptedPrivacyPolicy,
}) => {
  const handleContinueClick = () => {
    setHasAcceptedPrivacyPolicy();
    closePrivacyPolicyModal();
  };

  return (
    <sc.Container>
      <sc.PaxLogo data-testid="pax-logo" />
      <sc.Title data-testid="privacy-policy-title">{WELCOME_TO_PAX}</sc.Title>
      <sc.TextContainer data-testid="privacy-message">
        <sc.Text>
          We take your privacy seriously and understand it is important to you.
          When you use the app and when you pair your PAX Product to the app, we
          collect data to:
        </sc.Text>
        <sc.Text removeMargin>
          - Allow you to use the app with your PAX Product
        </sc.Text>
        <sc.Text removeMargin>- Aid in troubleshooting</sc.Text>
        <sc.Text removeMargin>
          - Enable you to customize your experience
        </sc.Text>
        <sc.Text>
          Please review our privacy policy{" "}
          <sc.PrivacyLink
            data-testid="privacy-policy-link"
            href={PRIVACY_POLICY_URL}
            rel="noopener noreferrer"
            target="_blank"
          >
            here
          </sc.PrivacyLink>{" "}
          which explains the data we collect, how we use and share that data,
          the controls you have over your data, and the measures we take to keep
          it safe. By clicking continue below, you acknowledge you have read the
          Privacy Policy and agree to it.
        </sc.Text>
      </sc.TextContainer>
      <Link
        data-testid="continue-button"
        onClick={handleContinueClick}
        to={r.home()}
      >
        <sc.ContinueButton>Continue</sc.ContinueButton>
      </Link>
    </sc.Container>
  );
};

type TextProps = {
  removeMargin?: boolean;
};

const sc = {
  Container: styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 40px;

    ${desktopBreakpoint(
      css`
        height: 588px;
        width: 424px;
      `
    )}
  `,

  ContinueButton: styled(Button)`
    margin-top: 18px;
  `,

  PaxLogo: styled(PaxLogo)`
    height: 32px;
    width: 100%;
  `,

  PrivacyLink: styled.a`
    color: var(--black);
    font-weight: 600;
  `,

  Text: styled.p<TextProps>`
    ${Text.PlaaxLight}
    font-size: 16px;
    line-height: 1.25;
    margin: ${(props) => (props.removeMargin ? "0" : "16px 0")};
    text-align: left;
  `,

  TextContainer: styled.div`
    margin: 12px auto 0;
    max-width: 424px;
  `,

  Title: styled.div`
    ${Text.Plaax}
    font-size: 28px;
    margin-top: 30px;
    text-align: center;
    text-transform: capitalize;
  `,
};
