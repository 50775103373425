import { get as lsGet, set as lsSet } from "local-storage";
import { AnyAction } from "redux";

import * as a from "./actions";

// @deprecated
// Legacy onboarding key kept for backward compatability.
const LS_KEY_PRIVACY_POLICY_ACCEPTED = "__paxWeb.onboarding.completed";

export type ModalState = {
  hasAcceptedPrivacyPolicy: boolean;
  hasSeenFirmwareModal: boolean;
  isFirmwareModalOpen: boolean;
  isOpen: boolean;
  isPrivacyPolicyModalOpen: boolean;
  isUsageAgreementModalOpen: boolean;
};

export const getInitialState = (): ModalState => ({
  hasAcceptedPrivacyPolicy:
    lsGet<boolean>(LS_KEY_PRIVACY_POLICY_ACCEPTED) || false,
  hasSeenFirmwareModal: false,
  isFirmwareModalOpen: false,
  isOpen: false,
  isPrivacyPolicyModalOpen: false,
  isUsageAgreementModalOpen: false,
});

export const reducer = (
  state: ModalState = getInitialState(),
  action: AnyAction
): ModalState => {
  const { payload } = action;

  switch (action.type) {
    case a.MODAL_CLOSED:
      return { ...state, isOpen: false };

    case a.MODAL_OPENED:
      return { ...state, isOpen: true };

    case a.CLOSE_FIRMWARE_MODAL:
      return { ...state, isFirmwareModalOpen: false };

    case a.OPEN_FIRMWARE_MODAL:
      return {
        ...state,
        hasSeenFirmwareModal: true,
        isFirmwareModalOpen: true,
      };

    case a.SET_HAS_SEEN_FIRMWARE_MODAL:
      const { hasSeenFirmwareModal } = payload;
      return { ...state, hasSeenFirmwareModal };

    case a.CLOSE_PRIVACY_POLICY_MODAL:
      return { ...state, isPrivacyPolicyModalOpen: false };

    case a.OPEN_PRIVACY_POLICY_MODAL:
      return { ...state, isPrivacyPolicyModalOpen: true };

    case a.SET_HAS_ACCEPTED_PRIVACY_POLICY:
      lsSet<boolean>(LS_KEY_PRIVACY_POLICY_ACCEPTED, true);
      return { ...state, hasAcceptedPrivacyPolicy: true };

    case a.CLOSE_USAGE_AGREEMENT_MODAL:
      return { ...state, isUsageAgreementModalOpen: false };

    case a.OPEN_USAGE_AGREEMENT_MODAL:
      return { ...state, isUsageAgreementModalOpen: true };

    default:
      return state;
  }
};
