import { useFormik } from "formik";
import React, { useState } from "react";
import styled, { css, SimpleInterpolation } from "styled-components";

import * as PaxBle from "../../../pax-ble";

import { UpdateDevice } from "../../api/consumer/actions/devices";
import * as deviceActions from "../../device/actions";
import { DEVICE_NAME_MAX_LENGTH } from "../../device/constants";
import { Modal, ModalProps } from "../../modal/components/Modal";
import Button from "../../shared/components/Button";
import * as flashBanner from "../../shared/components/FlashBanner";
import { Column, Row } from "../../shared/components/Flex";
import * as Icons from "../../shared/components/Icons";
import { Slider } from "../../shared/components/Slider";
import * as Text from "../../shared/components/Text";
import * as text from "../../shared/text";
import { desktopBreakpoint, removeEmojis } from "../../shared/utils";

import { SetColorMode } from "../actions";
import EraColorMode from "./EraColorMode";

export type StateProps = {
  brightness: number;
  colorMode: PaxBle.Types.ColorMode;
  deviceName: string;
  firmwareRevision: string;
  isDeviceLocked: boolean;
  serialNumber?: string;
  supportsRenameDevice: boolean;
};

export type EraDeviceSettingsModalProps = ModalProps &
  StateProps & {
    disconnectDevice: deviceActions.DisconnectDevice;
    onModalClose: () => void;
    setBrightness: deviceActions.SetBrightness;
    setDeviceName: deviceActions.SetDeviceName;
    setColorMode: SetColorMode;
    showFlashBanner: flashBanner.ShowFlashBanner;
    updateDevice: UpdateDevice;
  };

export const EraDeviceSettingsModal: React.FC<EraDeviceSettingsModalProps> = ({
  brightness,
  colorMode,
  deviceName,
  disconnectDevice,
  firmwareRevision,
  hideCloser,
  isDeviceLocked,
  isOpen,
  onModalClose,
  onRequestClose,
  serialNumber,
  setBrightness,
  setDeviceName,
  setColorMode,
  showFlashBanner,
  supportsRenameDevice,
  updateDevice,
}) => {
  const handleBrightnessChange = (value: number): void => {
    const percentage = value / 100;
    setBrightness(percentage);
  };

  const handleDisconnectClick = (): void => {
    disconnectDevice();
  };

  const modalProps = {
    hideCloser,
    isOpen,
    onRequestClose,
  };

  return (
    <Modal {...modalProps}>
      <sc.Container data-testid="era-settings-modal">
        <sc.Header>
          <sc.BackButton onClick={onModalClose}>
            <Icons.ChevronLeft />
          </sc.BackButton>
          <sc.SettingsIconContainer>
            <Icons.Settings />
          </sc.SettingsIconContainer>
          <DeviceNameRow
            deviceName={deviceName}
            isDeviceLocked={isDeviceLocked}
            serialNumber={serialNumber}
            setDeviceName={setDeviceName}
            showFlashBanner={showFlashBanner}
            supportsRenameDevice={supportsRenameDevice}
            updateDevice={updateDevice}
          />
        </sc.Header>
        <sc.Group>
          <sc.Label>Brightness</sc.Label>
          <sc.SliderContainer>
            <Slider
              isDisabled={isDeviceLocked}
              value={brightness * 100}
              onAfterChange={handleBrightnessChange}
            />
          </sc.SliderContainer>
        </sc.Group>
        {!isDeviceLocked && (
          <sc.Group>
            <sc.Label>Color Mode</sc.Label>
            <sc.ColorModesRow>
              <EraColorMode
                color="var(--white)"
                borderColor="very-light-pink"
                isSelected={PaxBle.Utils.areEraColorModesEqual(
                  colorMode,
                  PaxBle.Constants.Era.COLOR_MODE_DEFAULT
                )}
                onClick={(): void =>
                  setColorMode(PaxBle.Constants.Era.COLOR_MODE_DEFAULT)
                }
                title="Default"
              />
              <EraColorMode
                color="var(--pale-sky-blue)"
                isSelected={PaxBle.Utils.areEraColorModesEqual(
                  colorMode,
                  PaxBle.Constants.Era.COLOR_MODE_OCEAN
                )}
                onClick={(): void =>
                  setColorMode(PaxBle.Constants.Era.COLOR_MODE_OCEAN)
                }
                title="Ocean"
              />
              <EraColorMode
                color="var(--maize)"
                isSelected={PaxBle.Utils.areEraColorModesEqual(
                  colorMode,
                  PaxBle.Constants.Era.COLOR_MODE_SUNSET
                )}
                onClick={(): void =>
                  setColorMode(PaxBle.Constants.Era.COLOR_MODE_SUNSET)
                }
                title="Sunset"
              />
              <EraColorMode
                color="var(--salmon)"
                isSelected={PaxBle.Utils.areEraColorModesEqual(
                  colorMode,
                  PaxBle.Constants.Era.COLOR_MODE_MARS
                )}
                onClick={(): void =>
                  setColorMode(PaxBle.Constants.Era.COLOR_MODE_MARS)
                }
                title="Mars"
              />
            </sc.ColorModesRow>
          </sc.Group>
        )}
        <sc.BottomSection>
          <sc.ButtonsRow>
            <sc.Button
              data-testid="switch-device"
              onClick={handleDisconnectClick}
            >
              Switch Devices
            </sc.Button>
            <sc.Button
              data-testid="disconnect-device"
              onClick={handleDisconnectClick}
            >
              Disconnect
            </sc.Button>
          </sc.ButtonsRow>
          {firmwareRevision && (
            <sc.Firmware>Firmware v{firmwareRevision}</sc.Firmware>
          )}
        </sc.BottomSection>
      </sc.Container>
    </Modal>
  );
};

type DeviceNameRowProps = {
  deviceName: string;
  isDeviceLocked: boolean;
  serialNumber?: string;
  setDeviceName: (deviceName: string) => void;
  supportsRenameDevice: boolean;
  showFlashBanner: flashBanner.ShowFlashBanner;
  updateDevice: UpdateDevice;
};

const DeviceNameRow: React.FC<DeviceNameRowProps> = ({
  deviceName = "",
  isDeviceLocked,
  serialNumber,
  setDeviceName,
  supportsRenameDevice,
  showFlashBanner,
  updateDevice,
}) => {
  const [editMode, setEditMode] = useState(false);

  const handleDeviceNameClick = (): void => setEditMode(true);

  const formik = useFormik({
    initialValues: { deviceName },
    onSubmit: (values) => {
      const updatedDeviceName = removeEmojis(values.deviceName);

      if (supportsRenameDevice) setDeviceName(updatedDeviceName);
      setEditMode(false);
      if (!serialNumber) return;

      try {
        updateDevice(serialNumber, { deviceName: updatedDeviceName });
      } catch (err) {
        showFlashBanner(
          text.OOPS_AN_ERROR_OCCURRED_TRY_AGAIN,
          flashBanner.BannerType.ERROR
        );
      }
    },
  });

  if (editMode) {
    return (
      <sc.DeviceNameRow>
        <form data-testid="device-name-form" onSubmit={formik.handleSubmit}>
          <input
            data-testid="device-name-input"
            id="deviceName"
            maxLength={DEVICE_NAME_MAX_LENGTH}
            name="deviceName"
            onBlur={(): void => formik.handleSubmit()}
            onChange={formik.handleChange}
            required
            type="text"
            value={removeEmojis(formik.values.deviceName)}
          />
        </form>
      </sc.DeviceNameRow>
    );
  }

  return (
    <sc.DeviceNameRow>
      <sc.DeviceName
        data-testid="device-name"
        onClick={!isDeviceLocked ? handleDeviceNameClick : undefined}
      >
        {deviceName}
      </sc.DeviceName>
      {!isDeviceLocked && (
        <sc.Pencil
          data-testid="device-name-edit"
          onClick={handleDeviceNameClick}
        />
      )}
    </sc.DeviceNameRow>
  );
};

const sc = {
  BackButton: styled.button`
    background: none;
    border: none;
    left: 6px;
    position: absolute;
    top: 20px;

    ${desktopBreakpoint(css`
      display: none;
    `)}
  `,

  BottomSection: styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: flex-end;
  `,

  Button: styled(Button)`
    font-size: 14px;
    min-width: 157px;
  `,

  ButtonsRow: styled(Column)`
    align-items: center;
    justify-content: space-between;
    margin: 54px 0 20px;

    & > button {
      margin-bottom: 15px;
    }

    & > button:last-child {
      margin-bottom: 0;
    }

    ${desktopBreakpoint(
      css`
          flex-direction: row;
          & > button {
            flex: 1;
            margin-bottom: 0;
            margin-right: 20px;
          }
      
          & > button:last-child {
            margin-right: 0;
        `
    )}
  `,

  ColorModesRow: styled(Row)`
    justify-content: space-between;
    margin: 4px 30px 0;

    ${desktopBreakpoint(
      css`
        margin: 4px 0 0;
      `
    )}
  `,

  Container: styled(Column)`
    min-height: 100%;
    padding: 40px;

    ${desktopBreakpoint(css`
      width: var(--default-modal-width);
    `)}
  `,

  DeviceName: styled.div`
    border-bottom: 1px solid transparent;
    cursor: ${(props): SimpleInterpolation =>
      props.onClick ? "pointer" : "auto"};
    font-size: 24px;
    text-transform: uppercase;

    &:hover {
      border-bottom: ${(props): SimpleInterpolation =>
        props.onClick ? "1px solid var(--black)" : ""};
    }
  `,

  DeviceNameRow: styled(Row)`
    align-items: center;
    margin-bottom: 44px;
  `,

  Firmware: styled.div`
    ${Text.PlaaxLight}
    color: var(--brown-grey);
    font-size: 14px;
    margin: 0 auto;
  `,

  Group: styled(Column)`
    margin-bottom: 28px;
  `,

  Header: styled(Column)`
    align-items: center;
  `,

  Label: styled.div`
    ${Text.PlaaxLight}
    font-size: 18px;
    margin-bottom: 8px;
    padding: 0 20px;
    text-transform: uppercase;

    &:last-child {
      margin-top: 28px;
    }

    ${desktopBreakpoint(
      css`
        padding: 0;
      `
    )}
  `,

  Pencil: styled(Icons.Pencil)`
    cursor: pointer;
    height: 17px;
    margin-left: 12px;
    width: 16px;
  `,

  SettingsIconContainer: styled.div`
    align-items: center;
    border: 1px solid var(--black);
    border-radius: 100%;
    display: flex;
    height: 48px;
    justify-content: center;
    margin-bottom: 16px;
    width: 48px;
  `,

  SliderContainer: styled.div`
    padding: 0 30px;

    ${desktopBreakpoint(
      css`
        padding: 0;
      `
    )}
  `,
};
