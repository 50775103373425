import styled from "styled-components";

const Base = styled.div`
  border-radius: 8px;
  box-shadow: 0 1px 8px 0 var(--bluey-grey-30);
`;

export const Big = styled(Base)`
  align-items: center;
  border: solid 1px var(--very-light-pink);
  box-shadow: none;
  display: flex;
  flex: 0 0 910px;
  flex-direction: column;
  height: 452px;
  justify-content: center;
  margin-right: 8px;
  min-width: 910px;
  padding: 40px 32px;
  width: 910px;
`;

export const Small = styled(Base)`
  align-items: center;
  border: solid 1px var(--very-light-pink);
  box-shadow: none;
  display: flex;
  flex: 0 0 298px;
  flex-direction: column;
  height: 188px;
  margin-right: 8px;
  min-width: 298px;
  padding: 24px 20px;

  &:last-child {
    margin-right: 0;
  }
`;
