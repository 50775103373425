import React, { useState } from "react";
import styled from "styled-components";

import { CannabisCompoundJson } from "../../api/consumer/types/strain";
import { Row } from "../../shared/components/Flex";
import { Clear, QuestionMarkWhite } from "../../shared/components/Icons";
import * as Text from "../../shared/components/Text";

import * as c from "../constants";
import { BigCard } from "./PodsTestResultsCard";

export type PodsTerpenesCardProps = {
  terpenes: CannabisCompoundJson[];
};

export const PodsTerpenesCard: React.FC<PodsTerpenesCardProps> = ({
  terpenes,
}) => {
  terpenes.sort(function (a, b) {
    return b.fieldValue - a.fieldValue;
  });

  const [showExplainer, setShowExplainer] = useState(false);

  const handleQuestionMarkClick = () => {
    setShowExplainer(true);
  };

  const handleClearClick = () => {
    setShowExplainer(false);
  };

  if (terpenes.length === 0) {
    return null;
  }

  if (showExplainer) {
    return (
      <BigCard data-testid="pods-terpenes-explainer-card" borderStyle="dotted">
        <Row justifyContent="space-between">
          <sc.TitleOverflow>Primary Terpenes</sc.TitleOverflow>
          <sc.Clear
            data-testid="terpenes-overflow-closer"
            onClick={handleClearClick}
          />
        </Row>
        <sc.DescriptionOverflow>
          Terpenes can be found in most species of flora. They are chiefly
          responsible for the aromatic and flavor diversity in the plant
          kingdom. There are over 200 naturally occuring terpenes in cannabis,
          here are some of the most common:
        </sc.DescriptionOverflow>
        <sc.TerpenesContainer>
          {c.COMMON_TERPENES.map((terpene) => (
            <Row justifyContent="space-between" key={terpene}>
              <sc.RowItem>{terpene}</sc.RowItem>
              <sc.RowItem isHighlighted={true}>
                {c.getTerpeneFlavors(terpene.toUpperCase())}
              </sc.RowItem>
            </Row>
          ))}
        </sc.TerpenesContainer>
      </BigCard>
    );
  }

  return (
    <BigCard data-testid="pods-terpenes-card" borderStyle="dotted">
      <Row justifyContent="space-between">
        <sc.Title>Terpenes</sc.Title>
        <sc.QuestionMarkWhite
          data-testid="terpenes-overflow-opener"
          onClick={handleQuestionMarkClick}
        />
      </Row>
      <sc.Description>
        Terpenes are the compounds found in plants that are chiefly responsible
        for aromas and flavors.
      </sc.Description>
      {terpenes.slice(0, 7).map(({ fieldName }, index) => (
        <Row justifyContent="space-between" key={fieldName}>
          <sc.TerpeneName data-testid={`terpene-${index}`}>
            {fieldName.toLowerCase().replace(/_/g, " ")}
          </sc.TerpeneName>
          <sc.TerpeneFlavors data-testid={`terpene-flavor-${index}`}>
            {c.getTerpeneFlavors(fieldName)}
          </sc.TerpeneFlavors>
        </Row>
      ))}
    </BigCard>
  );
};

type HighlighterProps = {
  isHighlighted?: boolean | false;
};

const sc = {
  Clear: styled(Clear)`
    cursor: pointer;
    height: 18px;
    width: 18px;
  `,

  Description: styled.p`
    ${Text.MediumLLLight}
    color: var(--black);
    font-size: 14px;
    line-height: 1;
    margin: 0 0 12px;
    max-width: var(--pod-test-result-content-width);
    text-align: left;
  `,

  DescriptionOverflow: styled.p`
    ${Text.MediumLLLight}
    color: var(--black);
    font-size: 12px;
    line-height: 1;
    margin: 0 0 14px;
    max-width: 366px;
    text-align: center;
  `,

  QuestionMarkWhite: styled(QuestionMarkWhite)`
    background-color: var(--very-light-pink-2);
    border-radius: 100%;
    cursor: pointer;
    height: 16px;
    padding: 3px;
    width: 16px;
  `,

  RowItem: styled.div<HighlighterProps>`
    color: ${(props) =>
      props.isHighlighted ? "var(--brown-grey)" : "var(--black)"};
    font-size: 13px;
    line-height: 1.14;
    margin-bottom: 6px;
    text-transform: capitalize;
  `,

  TerpeneFlavors: styled.div`
    ${Text.MediumLLLight}
    color: var(--black);
    font-size: 14px;
    margin-bottom: 8px;
  `,

  TerpeneName: styled.div<HighlighterProps>`
    ${Text.MediumLLLight}
    color: var(--teal-green);
    font-size: 16px;
    margin-bottom: 6px;
    text-transform: capitalize;
  `,

  TerpenesContainer: styled.div`
    margin-left: 66px;
    margin-right: 66px;
  `,

  Title: styled.div`
    color: var(--black);
    font-size: 20px;
    margin-bottom: 10px;
    text-transform: uppercase;
  `,

  TitleOverflow: styled.div`
    color: var(--black);
    font-size: 18px;
    margin: 4px auto 8px;
    text-align: center;
    text-transform: uppercase;
  `,
};
