import React, { useState } from "react";
import styled from "styled-components";

import { Settings } from "../../../shared/components/Icons";
import * as text from "../../../shared/text";

import { ExperienceModesModal } from "../../ExperienceModesModal";

import { DeviceSettingsModalContent } from "../modals/DeviceSettingsModalContent";
import { ModuleCard } from "./ModuleCard";

export const DeviceSettingsModule: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  return (
    <>
      <ModuleCard
        onClick={() => setIsModalOpen(true)}
        title={text.DEVICE_SETTINGS}
      >
        <sc.SettingsIcon />
      </ModuleCard>
      <ExperienceModesModal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        render={(props) => <DeviceSettingsModalContent {...props} />}
      />
    </>
  );
};

const sc = {
  SettingsIcon: styled(Settings)`
    height: 48px;
    margin: 36px auto;
    width: 48px;
  `,
};
