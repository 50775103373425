import styled, { css, keyframes, SimpleInterpolation } from "styled-components";

import { ReactComponent as AddIcon } from "../../assets/images/add.svg";
import { ReactComponent as Battery50Icon } from "../../assets/images/battery-50.svg";
import { ReactComponent as Battery75Icon } from "../../assets/images/battery-75.svg";
import { ReactComponent as BatteryEmptyIcon } from "../../assets/images/battery-empty.svg";
import { ReactComponent as BatteryFullIcon } from "../../assets/images/battery-full.svg";
import { ReactComponent as BatteryLowIcon } from "../../assets/images/battery-low.svg";
import { ReactComponent as CheckWhiteIcon } from "../../assets/images/check-white.svg";
import { ReactComponent as ChevronDownIcon } from "../../assets/images/chevron-down.svg";
import { ReactComponent as ChevronLeftIcon } from "../../assets/images/chevron-left.svg";
import { ReactComponent as ChevronRightIcon } from "../../assets/images/chevron-right.svg";
import { ReactComponent as ClearIcon } from "../../assets/images/clear.svg";
import { ReactComponent as PaxDeviceIcon } from "../../assets/images/device-nav-button.svg";
import { ReactComponent as PaxDeviceIconActive } from "../../assets/images/device-nav-button-active.svg";
import { ReactComponent as EnergizedIcon } from "../../assets/images/effects/energized.svg";
import { ReactComponent as FocusIcon } from "../../assets/images/effects/focus.svg";
import { ReactComponent as ReliefIcon } from "../../assets/images/effects/relief.svg";
import { ReactComponent as RestIcon } from "../../assets/images/effects/rest.svg";
import { ReactComponent as UnwindIcon } from "../../assets/images/effects/unwind.svg";
import { ReactComponent as UpliftedIcon } from "../../assets/images/effects/uplifted.svg";
import { ReactComponent as EyeIcon } from "../../assets/images/eye.svg";
import { ReactComponent as BlueberryIcon } from "../../assets/images/flavors/blueberry.svg";
import { ReactComponent as CitrusIcon } from "../../assets/images/flavors/citrus.svg";
import { ReactComponent as CreamyIcon } from "../../assets/images/flavors/creamy.svg";
import { ReactComponent as EarthyIcon } from "../../assets/images/flavors/earthy.svg";
import { ReactComponent as FloralIcon } from "../../assets/images/flavors/floral.svg";
import { ReactComponent as FruityIcon } from "../../assets/images/flavors/fruity.svg";
import { ReactComponent as LavenderIcon } from "../../assets/images/flavors/lavender.svg";
import { ReactComponent as LemonPeelIcon } from "../../assets/images/flavors/lemon-peel.svg";
import { ReactComponent as OrangeIcon } from "../../assets/images/flavors/orange.svg";
import { ReactComponent as PineIcon } from "../../assets/images/flavors/pine.svg";
import { ReactComponent as PineappleIcon } from "../../assets/images/flavors/pineapple.svg";
import { ReactComponent as SpicyIcon } from "../../assets/images/flavors/spicy.svg";
import { ReactComponent as SugarIcon } from "../../assets/images/flavors/sugar.svg";
import { ReactComponent as TangyIcon } from "../../assets/images/flavors/tangy.svg";
import { ReactComponent as TropicalIcon } from "../../assets/images/flavors/tropical.svg";
import { ReactComponent as HamburgerIcon } from "../../assets/images/hamburger.svg";
import { ReactComponent as HeartIcon } from "../../assets/images/heart.svg";
import { ReactComponent as HexagonIcon } from "../../assets/images/hexagon.svg";
import { ReactComponent as LocationIcon } from "../../assets/images/location.svg";
import { ReactComponent as LocationOffIcon } from "../../assets/images/location-off.svg";
import { ReactComponent as LocationOnIcon } from "../../assets/images/location-on.svg";
import { ReactComponent as LockedIcon } from "../../assets/images/locked.svg";
import { ReactComponent as MailIcon } from "../../assets/images/mail.svg";
import { ReactComponent as NotificationIcon } from "../../assets/images/notification-bell.svg";
import { ReactComponent as NotificationOnIcon } from "../../assets/images/notification-bell-on.svg";
import { ReactComponent as WhiteOilIcon } from "../../assets/images/oil_thin.svg";
import { ReactComponent as OptionsIcon } from "../../assets/images/options.svg";
import { ReactComponent as PaxEraProLogoIcon } from "../../assets/images/pax_era_pro_registered.svg";
import { ReactComponent as PaxEraLogoIcon } from "../../assets/images/pax_era_registered.svg";
import { ReactComponent as PaxLabsLogoIcon } from "../../assets/images/pax-labs-logo.svg";
import { ReactComponent as PaxLogoIcon } from "../../assets/images/pax-logo.svg";
import { ReactComponent as PaxSymbolIcon } from "../../assets/images/pax-symbol.svg";
import { ReactComponent as Pax3LogoIcon } from "../../assets/images/pax3_tm.svg";
import { ReactComponent as PencilIcon } from "../../assets/images/pencil.svg";
import { ReactComponent as PlayButtonIcon } from "../../assets/images/play-button.svg";
import { ReactComponent as PodSwitcherAddIcon } from "../../assets/images/pod_switcher_add.svg";
import { ReactComponent as PodSwitcherCheckmarkIcon } from "../../assets/images/pod_switcher_checkmark.svg";
import { ReactComponent as PodSmallBlackIcon } from "../../assets/images/pod-small-black.svg";
import { ReactComponent as QuestionMarkWhiteIcon } from "../../assets/images/question-mark-white.svg";
import { ReactComponent as SavedFromIcon } from "../../assets/images/saved-from.svg";
import { ReactComponent as SearchIcon } from "../../assets/images/search.svg";
import { ReactComponent as SearchIconActive } from "../../assets/images/search-active.svg";
import { ReactComponent as SettingsIcon } from "../../assets/images/settings.svg";
import { ReactComponent as ShieldIcon } from "../../assets/images/shield.svg";
import { ReactComponent as StarIcon } from "../../assets/images/star.svg";
import { ReactComponent as SwitchIcon } from "../../assets/images/switch.svg";
import { ReactComponent as TempModeIcon } from "../../assets/images/temp-mode.svg";
import { ReactComponent as TestBadgeIcon } from "../../assets/images/test-badge.svg";
import { ReactComponent as ThinCrossIcon } from "../../assets/images/thin-cross.svg";
import { ReactComponent as UnlockedIcon } from "../../assets/images/unlocked.svg";
import { ReactComponent as UserIcon } from "../../assets/images/user.svg";
import { ReactComponent as UserIconActive } from "../../assets/images/user-active.svg";
import { ReactComponent as WhiteTestResultsIcon } from "../../assets/images/white_test_results.svg";

import { mobileBreakpoint } from "../utils";

type ColorProps = {
  color?: string;
};

type ColorAndSizeProps = {
  color?: string;
  size?: number;
};

const colorIconStyles = css<ColorProps>`
  & > path {
    fill: ${({ color }) => (color ? color : "var(--black)")};
  }
`;

const iconStyles = css`
  height: 32px;
  width: 32px;
`;

const mediumIconStyles = css`
  height: 24px;
  width: 24px;
`;

const responsiveIconStyles = css<Object>`
  height: 32px;
  width: 32px;
  ${mobileBreakpoint(
    css`
      height: 24px;
      width: 24px;
    `
  )}
`;

const opacityKeyframes = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 20%;
  }
  100% {
    opacity: 1;
  }
`;

const chargingAnimation = css`
  ${opacityKeyframes}: ease-in-out 2s infinite;
`;

type AddIconProps = {
  $isDisabled?: boolean;
};

export const Add = styled(AddIcon)<AddIconProps>`
  ${iconStyles}
  opacity: ${({ $isDisabled }) => ($isDisabled ? "0.2" : "1")};
`;

type BatteryIconProps = {
  $animate: boolean;
};

export const BatteryFull = styled(BatteryFullIcon)<BatteryIconProps>`
  ${iconStyles}
  animation: ${(props) => (props.$animate ? chargingAnimation : "none")};
`;

export const Battery75 = styled(Battery75Icon)<BatteryIconProps>`
  ${iconStyles}
  animation: ${(props) => (props.$animate ? chargingAnimation : "none")};
`;

export const Battery50 = styled(Battery50Icon)<BatteryIconProps>`
  ${iconStyles}
  animation: ${(props) => (props.$animate ? chargingAnimation : "none")};
`;

export const BatteryLow = styled(BatteryLowIcon)<BatteryIconProps>`
  ${iconStyles}
  animation: ${(props) => (props.$animate ? chargingAnimation : "none")};
`;

export const BatteryEmpty = styled(BatteryEmptyIcon)<BatteryIconProps>`
  ${iconStyles}
  animation: ${(props) => (props.$animate ? chargingAnimation : "none")};
`;

export const CheckWhite = styled(CheckWhiteIcon)`
  ${iconStyles}
`;

type ChevronProps = {
  $isDisabled?: boolean;
  size?: number;
};

const chevronStyles = css<ChevronProps>`
  cursor: ${(props) => (props.$isDisabled ? "auto" : "pointer")};
  height: ${(props) => (props.size ? props.size : 32)}px;
  opacity: ${(props) => (props.$isDisabled ? 0.2 : 1)};
  width: ${(props) => (props.size ? props.size : 32)}px;
`;

export const ChevronDown = styled(ChevronDownIcon)<ChevronProps>`
  ${chevronStyles}
`;

export const ChevronLeft = styled(ChevronLeftIcon)<ChevronProps>`
  ${chevronStyles}
`;

export const ChevronRight = styled(ChevronRightIcon)<ChevronProps>`
  ${chevronStyles}
`;

export const ChevronUp = styled(ChevronDownIcon)<ChevronProps>`
  ${chevronStyles}
  transform: rotate(180deg);
`;

type ClearIconProps = {
  color?: string;
  size?: number;
};

export const Clear = styled(ClearIcon)<ClearIconProps>`
  height: ${(props) => props.size || 32}px;
  width: ${(props) => props.size || 32}px;

  > g > g {
    fill: ${(props) => props.color || "var(--black)"};
  }
`;

export const ClearThin = styled(ThinCrossIcon)`
  height: 18px;
  width: 18px;
`;

export const EffectEnergized = styled(EnergizedIcon)`
  ${mediumIconStyles}
`;

export const EffectUplifted = styled(UpliftedIcon)`
  ${mediumIconStyles}
`;

export const EffectRelief = styled(ReliefIcon)`
  ${mediumIconStyles}
`;

export const EffectFocus = styled(FocusIcon)`
  ${mediumIconStyles}
`;

export const EffectUnwind = styled(UnwindIcon)`
  ${mediumIconStyles}
`;

export const EffectRest = styled(RestIcon)`
  ${mediumIconStyles}
`;

export const Energized = styled(EnergizedIcon)<ColorAndSizeProps>`
  height: ${({ size }) => (size ? size : "24")}px;
  width: ${({ size }) => (size ? size : "24")}px;

  & > path {
    fill: ${({ color }) => (color ? color : "var(--black)")};
  }
`;

type EyeIconProps = {
  $isRevealed: boolean;
};

export const Eye = styled(EyeIcon)<EyeIconProps>`
  background-color: ${({ $isRevealed }) =>
    $isRevealed ? "var(--black)" : "transparent"};
  border-radius: 100%;

  > g > path:first-child {
    fill: ${({ $isRevealed }) =>
      $isRevealed ? "var(--white)" : "var(--black)"};
  }

  &:hover {
    background-color: var(--black);

    > g > path:first-child {
      fill: var(--white);
    }
  }
`;

export const Focus = styled(FocusIcon)<ColorAndSizeProps>`
  height: ${({ size }) => (size ? size : "24")}px;
  width: ${({ size }) => (size ? size : "24")}px;

  & > path {
    fill: ${({ color }) => (color ? color : "var(--black)")};
  }
`;

export const Hamburger = styled(HamburgerIcon)`
  height: 31px;
  width: 31px;
`;

type HeartIconProps = {
  $isFavorited?: boolean;
};

export const Heart = styled(HeartIcon)<HeartIconProps>`
  > path {
    fill: ${({ $isFavorited }) =>
      $isFavorited ? "var(--black)" : "var(--white)"};
  }
`;

type HexagonProps = {
  color: string;
  size: number;
};

export const Hexagon = styled(HexagonIcon)<HexagonProps>`
  height: ${(props) => props.size}px;
  width: ${(props) => props.size}px;

  > path {
    fill: ${(props) => props.color};
  }
`;

type LocationProps = {
  $isBold?: boolean;
};

const boldLocationStyles = css`
  & > g > g > g > rect {
    fill: var(--black);
  }

  & > g > g > g > g > g > path {
    fill: var(--white);
  }
`;

export const Location = styled(LocationIcon)<LocationProps>`
  height: 36px;
  width: 36px;

  ${({ $isBold }): SimpleInterpolation => ($isBold ? boldLocationStyles : "")}
`;

export const LocationOff = styled(LocationOffIcon)`
  height: 20px;
  width: 20px;
`;

export const LocationOn = styled(LocationOnIcon)`
  height: 20px;
  width: 20px;
`;

type LockIconProps = {
  size?: number;
};

export const Locked = styled(LockedIcon)<LockIconProps>`
  ${iconStyles}
  height: ${(props) => props.size}px;
  width: ${(props) => props.size}px;
`;

export const Mail = styled(MailIcon)`
  height: 48px;
  width: 48px;
`;

export const Notification = styled(NotificationIcon)`
  ${responsiveIconStyles}
`;

export const NotificationOn = styled(NotificationOnIcon)`
  ${responsiveIconStyles}
`;

export const Options = styled(OptionsIcon)`
  height: 12px;
  width: 18px;
`;

export const PaxDevice = styled(PaxDeviceIcon)`
  height: 36px;
  width: 36px;
`;

export const PaxDeviceActive = styled(PaxDeviceIconActive)`
  height: 36px;
  width: 36px;
`;

export const Pax3Logo = Pax3LogoIcon;

export const PaxEraLogo = PaxEraLogoIcon;

export const PaxEraProLogo = PaxEraProLogoIcon;

export const PaxLabsLogo = PaxLabsLogoIcon;

export const PaxLogo = PaxLogoIcon;

type PaxSymbolProps = {
  $activePetal?: 1 | 2 | 3 | 4;
};

export const PaxSymbol = styled(PaxSymbolIcon)<PaxSymbolProps>`
  ${responsiveIconStyles}

  ${({ $activePetal }) =>
    $activePetal
      ? css`
          & > path:not(:nth-child(-n + ${$activePetal})) {
            opacity: 0.25;
          }
        `
      : ""}
`;

export const Pencil = PencilIcon;

type PodSmallBlackProps = {
  checkmarkcolor: string;
  podcolor: string;
  size: number;
};

export const PodSmallBlack = styled(PodSmallBlackIcon)<PodSmallBlackProps>`
  height: ${(props) => props.size}px;
  width: ${(props) => props.size}px;

  > g > g > rect {
    stroke: ${(props) => props.podcolor};
  }

  > g > g > path {
    fill: ${(props) => props.podcolor};
  }

  > g > g > g > circle {
    stroke: ${(props) => props.checkmarkcolor};
  }

  > g > g > g > path {
    fill: ${(props) => props.checkmarkcolor};
  }
`;

export const PodSwitcherAdd = styled(PodSwitcherAddIcon)<AddIconProps>`
  ${iconStyles}
  opacity: ${({ $isDisabled }) => ($isDisabled ? "0.2" : "1")};
`;

export const PodSwitcherCheckmark = styled(PodSwitcherCheckmarkIcon)`
  ${iconStyles}
`;

type PlayButtonProps = {
  color: string;
  size: number;
};

export const PlayButton = styled(PlayButtonIcon)<PlayButtonProps>`
  height: ${(props) => props.size}px;
  position: absolute;
  right: calc(50% - 30px);
  top: calc(50% - 30px);
  width: ${(props) => props.size}px;

  > path {
    fill: ${(props) => props.color};
  }
`;

export const QuestionMarkWhite = styled(QuestionMarkWhiteIcon)`
  background-color: var(--very-light-pink-2);
  height: 16px;
  width: 16px;
`;

export const Relief = styled(ReliefIcon)<ColorAndSizeProps>`
  height: ${({ size }) => (size ? size : "24")}px;
  width: ${({ size }) => (size ? size : "24")}px;

  & > path {
    fill: ${({ color }) => (color ? color : "var(--black)")};
  }
`;

export const Rest = styled(RestIcon)<ColorAndSizeProps>`
  height: ${({ size }) => (size ? size : "24")}px;
  width: ${({ size }) => (size ? size : "24")}px;

  & > path {
    fill: ${({ color }) => (color ? color : "var(--black)")};
  }
`;

export type StarIconProps = {
  $isFilled?: boolean;
  $unfilledColor?: string;
  disabled?: boolean;
};

const disabledStarStyles = css<StarIconProps>`
  fill: ${({ $isFilled }) =>
    $isFilled ? "var(--brown-grey)" : "var(--brown-grey-40)"};
`;

export const Star = styled(StarIcon)<StarIconProps>`
  > path {
    fill: ${({ $isFilled, $unfilledColor }) =>
      $isFilled ? "var(--black)" : $unfilledColor || "var(--brown-grey-40)"};
    stroke: none;

    ${({ disabled }) => (disabled ? disabledStarStyles : "")}
  }
`;

type SavedFromProps = {
  $isBold?: boolean;
};

const boldSavedFromStyles = css`
  & > g > g > g > g > rect {
    fill: var(--black);
  }

  & > g > g > g > g > path {
    fill: var(--white);
  }
`;

export const SavedFrom = styled(SavedFromIcon)<SavedFromProps>`
  height: 36px;
  width: 36px;

  ${({ $isBold }): SimpleInterpolation => ($isBold ? boldSavedFromStyles : "")}
`;

export const Search = styled(SearchIcon)`
  height: 18px;
  width: 18px;
`;

export const SearchActive = styled(SearchIconActive)`
  height: 36px;
  width: 36px;
`;

export const Settings = styled(SettingsIcon)`
  ${iconStyles}
`;

export const Shield = styled(ShieldIcon)`
  height: 16px;
  width: 13px;
`;

export const Switch = styled(SwitchIcon)`
  ${iconStyles}
`;

export const TempMode = styled(TempModeIcon)`
  height: 70px;
  width: 82px;
`;

export const TestBadge = styled(TestBadgeIcon)`
  height: 40px;
  width: 40px;
`;

export const Unlocked = styled(UnlockedIcon)<LockIconProps>`
  ${iconStyles}
  height: ${(props) => props.size}px;
  width: ${(props) => props.size}px;
`;

export const Unwind = styled(UnwindIcon)<ColorAndSizeProps>`
  height: ${({ size }) => (size ? size : "24")}px;
  width: ${({ size }) => (size ? size : "24")}px;

  & > path {
    fill: ${({ color }) => (color ? color : "var(--black)")};
  }
`;

export const Uplifted = styled(UpliftedIcon)<ColorAndSizeProps>`
  height: ${({ size }) => (size ? size : "24")}px;
  width: ${({ size }) => (size ? size : "24")}px;

  & > path {
    fill: ${({ color }) => (color ? color : "var(--black)")};
  }
`;

export const User = styled(UserIcon)`
  height: 24px;
  width: 24px;
`;

export const UserActive = styled(UserIconActive)`
  height: 36px;
  width: 36px;
`;

export const WhiteOil = styled(WhiteOilIcon)`
  height: 15px;
  width: 15px;
`;

export const WhiteTestResults = styled(WhiteTestResultsIcon)`
  height: 15px;
  width: 15px;
`;

// Flavor Icons

export const Blueberry = styled(BlueberryIcon)`
  ${mediumIconStyles}
  ${colorIconStyles}
`;

export const Citrus = styled(CitrusIcon)`
  ${mediumIconStyles}
  ${colorIconStyles}
`;

export const Creamy = styled(CreamyIcon)`
  ${mediumIconStyles}
  ${colorIconStyles}
`;

export const Earthy = styled(EarthyIcon)`
  ${mediumIconStyles}
  ${colorIconStyles}
`;

export const Floral = styled(FloralIcon)`
  ${mediumIconStyles}
  ${colorIconStyles}
`;

export const Fruity = styled(FruityIcon)`
  ${mediumIconStyles}
  ${colorIconStyles}
`;

export const Lavender = styled(LavenderIcon)`
  ${mediumIconStyles}
  ${colorIconStyles}
`;

export const LemonPeel = styled(LemonPeelIcon)`
  ${mediumIconStyles}
  ${colorIconStyles}
`;

export const Orange = styled(OrangeIcon)`
  ${mediumIconStyles}
  ${colorIconStyles}
`;

export const Pine = styled(PineIcon)`
  ${mediumIconStyles}
  ${colorIconStyles}
`;

export const Pineapple = styled(PineappleIcon)`
  ${mediumIconStyles}
  ${colorIconStyles}
`;

export const Spicy = styled(SpicyIcon)`
  ${mediumIconStyles}
  ${colorIconStyles}
`;

export const Sugar = styled(SugarIcon)`
  ${mediumIconStyles}
  ${colorIconStyles}
`;

export const Tangy = styled(TangyIcon)`
  ${mediumIconStyles}
  ${colorIconStyles}
`;

export const Tropical = styled(TropicalIcon)`
  ${mediumIconStyles}
  ${colorIconStyles}
`;
