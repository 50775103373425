import { connect } from "react-redux";

import * as c from "../../../device/constants";
import * as s from "../../../device/selectors";
import * as t from "../../../main/types";

import FlowerOvenState from "../../components/Thermostat/FlowerOvenState";

const mapStateToProps = (state: t.MainAppState) => {
  const heatingState = state.device.attributes[c.ATTRIBUTE_HEATING_STATE];

  const currentTemperature = s.getCurrentTemperature(state);
  const isDeviceCharging =
    state.device.attributes[c.ATTRIBUTE_CHARGE_STATUS]?.isCharging;

  return {
    currentTemperature,
    heatingState,
    isDeviceCharging,
  };
};

export default connect(mapStateToProps)(FlowerOvenState);
