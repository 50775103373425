import { Endian } from "../types";
import * as u from "../utils";
import McuManagerImageVersion from "./McuManagerImageVersion";

// Represents a firmware image header for devices using McuBoot or the legacy Apache Mynewt bootloader.
export default class McuManagerImageHeader {
  static IMG_HEADER_MAGIC = 0x96f3b83d;
  static IMG_HEADER_MAGIC_V1 = 0x96f3b83c;
  static HEADER_LENGTH = 4 + 4 + 2 + 2 + 4 + 4 + 4;
  static MIN_SIZE =
    McuManagerImageVersion.MIN_SIZE + McuManagerImageHeader.HEADER_LENGTH;

  magic?: number;
  loadAddress?: number;
  headerSize?: number;
  imageSize?: number;
  flags?: number;
  version?: McuManagerImageVersion;

  static fromBytes(b: Uint8Array, offset = 0): McuManagerImageHeader {
    if (b.length - offset < this.MIN_SIZE) {
      throw new Error(
        "The byte array is too short to be a McuManagerImageHeader"
      );
    }

    const header = new McuManagerImageHeader();
    header.magic = u.byteArrayToUnsignedInt(b, offset, Endian.LITTLE, 4);

    if (
      header.magic !== this.IMG_HEADER_MAGIC &&
      header.magic !== this.IMG_HEADER_MAGIC_V1
    ) {
      throw new Error(
        `Wrong magic number: header=${header.magic}, magic=${this.IMG_HEADER_MAGIC} or ${this.IMG_HEADER_MAGIC_V1}`
      );
    }

    header.loadAddress = u.byteArrayToUnsignedInt(
      b,
      4 + offset,
      Endian.LITTLE,
      4
    );
    header.headerSize = u.byteArrayToUnsignedInt(
      b,
      8 + offset,
      Endian.LITTLE,
      2
    );
    header.imageSize = u.byteArrayToUnsignedInt(
      b,
      12 + offset,
      Endian.LITTLE,
      4
    );
    header.flags = u.byteArrayToUnsignedInt(b, 16 + offset, Endian.LITTLE, 4);
    header.version = McuManagerImageVersion.fromBytes(b, 20 + offset);

    return header;
  }

  isLegacy(): boolean {
    return this.magic === McuManagerImageHeader.IMG_HEADER_MAGIC_V1;
  }
}
