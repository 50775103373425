export const CANADA_DEVICES_MENU_HEIGHT = 432;
export const CANADA_SHOP_MENU_HEIGHT = 244;
export const MENU_ITEM_HEIGHT = 64;
export const MENU_MARGIN = 15;
export const US_DEVICES_MENU_HEIGHT = 570;
export const US_SHOP_MENU_HEIGHT = 390;

// Links
export const CANADA_FAQ = "https://www.pax.com/en-ca/help";
export const CANADA_HOME = "https://www.pax.com/en-ca/";
export const US_FAQ = "https://www.pax.com/help";
export const US_HOME = "https://www.pax.com/";
