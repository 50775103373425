import { StrainRating } from "../api/consumer/types/reviews";
import * as text from "../shared/text";

export const MODAL_TRANSITION_DURATION = 300;
export const MODAL_WIDTH = 490;

export const DEFAULT_FORM_VALUES = {
  effects: [],
  selectedEffects: [],
  text: "",
};

export const RATING_WORD_MAP: { [key in StrainRating]: string } = {
  1: text.POOR,
  2: text.FAIR,
  3: text.GOOD,
  4: text.GREAT,
  5: text.EXCELLENT,
};
