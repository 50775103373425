import * as PaxBle from "../../pax-ble";

export function getDynamicModeFromType(
  type: PaxBle.Types.Pax3.DynamicModeType
): PaxBle.Types.Pax3.DynamicMode {
  switch (type) {
    case PaxBle.Types.Pax3.DynamicModeType.STANDARD:
      return PaxBle.Constants.Pax3.DYNAMIC_MODE_STANDARD;
    case PaxBle.Types.Pax3.DynamicModeType.BOOST:
      return PaxBle.Constants.Pax3.DYNAMIC_MODE_BOOST;
    case PaxBle.Types.Pax3.DynamicModeType.EFFICIENCY:
      return PaxBle.Constants.Pax3.DYNAMIC_MODE_EFFICIENCY;
    case PaxBle.Types.Pax3.DynamicModeType.STEALTH:
      return PaxBle.Constants.Pax3.DYNAMIC_MODE_STEALTH;
    case PaxBle.Types.Pax3.DynamicModeType.FLAVOR:
      return PaxBle.Constants.Pax3.DYNAMIC_MODE_FLAVOR;
    default:
      throw new Error("Dynamic mode type not defined");
  }
}
