import axios from "axios";

import { navigator } from "../../../navigator";

import * as cookies from "../../cookies";

import * as selectors from "./selectors/user";
import { UserResponse } from "./types/user";
import * as p from "./paths";

const CONSUMER_API_URL = process.env.REACT_APP_CONSUMER_API_URL || "";
const MAX_REQUEST_RETRIES = 5;

const consumerAxios = axios.create();
consumerAxios.defaults.withCredentials = true;

// Use interceptor to retry requests for network, token, or auth header errors.
consumerAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const existingToken = selectors.getAuthenticationToken();
    const existingUuid = selectors.getUserUuid();
    const retryConfig = error.config;
    const isNetworkError = error.message === "Network Error";
    const hasBadAuthHeader = error.response?.status === 401;
    const hasBadToken = error.response?.status === 403;
    const shouldRetry =
      navigator.onLine &&
      error.config &&
      error.config.retryCount < MAX_REQUEST_RETRIES &&
      (isNetworkError || hasBadAuthHeader || hasBadToken);

    // Return Error object with Promise
    if (!shouldRetry) return Promise.reject(error);

    switch (true) {
      case isNetworkError:
        // Network errors usually resolve so we retry the original request.
        retryConfig.retryCount++;
        break;

      case hasBadAuthHeader:
        // Missing a token or something is wrong with the Authorization header
        if (existingToken) {
          // Retry with the existing token a couple of times
          // If it continues to fail assume there is an issue with the token
          error.config.retryCount < 2
            ? (retryConfig.headers.Authorization = `Bearer ${existingToken}`)
            : cookies.clearCurrentTokens();
          retryConfig.retryCount++;
        } else {
          try {
            // Create a new anonymous user and use its token to retry
            // Use axios directly since we are intercepting a request in our consumerAxios instance
            const newAnonymousUser = await axios.post<UserResponse>(
              CONSUMER_API_URL + p.users.anonymousUsers(),
              { headers: { "Content-Type": "application/json" } }
            );

            const newToken = newAnonymousUser?.data?.credentials?.token;

            if (!newToken) return Promise.reject(error);

            cookies.setAnonymousCookie(newToken);
            retryConfig.headers.Authorization = `Bearer ${newToken}`;
          } catch (e) {
            // Do nothing. Our useUser hook will create a new anonymous user if this fails
          }

          // We created a fresh token for the retry so this should be the last attempt
          retryConfig.retryCount = MAX_REQUEST_RETRIES;
        }
        break;

      case hasBadToken:
        // Expired or invalid token value, so we clear out the tokens
        cookies.clearCurrentTokens();

        // Nothing left to do, just return the error.
        if (!existingUuid) return Promise.reject(error);

        // Refresh the authentication token with the users existing uuid to link user history
        // Use axios directly since we are catching an error in our consumerAxios instance
        try {
          const refreshedUser = await axios.post<UserResponse>(
            CONSUMER_API_URL + p.users.anonymousLogin(),
            {
              headers: { "Content-Type": "application/json" },
              uuid: existingUuid,
            }
          );

          const refreshedToken = refreshedUser?.data?.credentials?.token;

          if (!refreshedToken) return Promise.reject(error);

          cookies.setAnonymousCookie(refreshedToken);
          retryConfig.headers.Authorization = `Bearer ${refreshedToken}`;
        } catch (e) {
          // Do nothing. Our useUser hook will create a new anonymous user if this fails
        }

        // We refreshed our token for the retry
        // If that doesn't work we should start over
        retryConfig.retryCount = MAX_REQUEST_RETRIES;
        break;

      default:
        // Not an error we are intercepting, so we just pass it through
        return Promise.reject(error);
    }

    // Execute the retry with the updated retryConfig
    return consumerAxios.request(retryConfig);
  }
);

// Use interceptor to authenticate requests.
consumerAxios.interceptors.request.use((config) => {
  const token = selectors.getAuthenticationToken();

  if (
    (config.url?.includes("pax.com") || config.url?.includes("localhost")) &&
    token
  ) {
    config.headers = config.headers ?? {};
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

export default consumerAxios;
