import { animated, useSpring } from "@react-spring/web";
import { debounce } from "lodash";
import React, { useEffect, useState } from "react";
import Sticky from "react-stickynode";
import styled from "styled-components";

import * as h from "../hooks";
import * as u from "../utils";

const DESKTOP_NAV_BAR_HEIGHT = 146;
const MOBILE_NAV_BAR_HEIGHT = 62;
const STICKY_HEADER_SCROLL_DEBOUNCE = 10;

export type StickyHeaderProps = {
  containerHeight: number;
  disabled?: boolean;
  toggleAutoFocus?: (bool: boolean) => void;
};

export const StickyHeader: React.FC<StickyHeaderProps> = ({
  children,
  containerHeight,
  disabled,
  toggleAutoFocus,
}) => {
  const isDeskTop = h.useDesktopMediaQuery();

  const navBarHeight = isDeskTop
    ? DESKTOP_NAV_BAR_HEIGHT
    : MOBILE_NAV_BAR_HEIGHT;

  useEffect(() => {
    window.addEventListener("scroll", listenToScroll);

    return () => window.removeEventListener("scroll", listenToScroll);
  });

  const [lastScrollPosition, setLastScrollPosition] = useState<number>(0);
  const [shouldDisableAnimation, setShouldDisableAnimation] =
    useState<boolean>(true);
  const [shouldHideHeader, setShouldHideHeader] = useState<boolean>(true);

  const listenToScroll = debounce(() => {
    const scrollPosition = u.getScrollPosition();

    if (scrollPosition > navBarHeight + containerHeight) {
      // enable animation if scroll position not past end of container
      if (toggleAutoFocus) toggleAutoFocus(false);
      setShouldDisableAnimation(false);
    } else if (scrollPosition < navBarHeight) {
      // disable animation if scroll position is before start of container
      setShouldDisableAnimation(true);
      if (toggleAutoFocus) toggleAutoFocus(true);
    }

    // if scroll position has increased hide header
    if (scrollPosition > lastScrollPosition) {
      setShouldHideHeader(true);
    } else {
      setShouldHideHeader(false);
    }

    setLastScrollPosition(scrollPosition);
  }, STICKY_HEADER_SCROLL_DEBOUNCE);

  const [styles] = useSpring(
    () => ({
      config: { friction: 30, mass: 1, tension: 280 },
      from: {
        top: -containerHeight,
      },
      reset: !shouldHideHeader,
      reverse: shouldHideHeader,
      to: { top: 0 },
    }),
    [shouldHideHeader]
  );

  if (disabled || shouldDisableAnimation) return <>{children}</>;

  return (
    <Sticky innerZ={10}>
      <sc.AnimatedDiv style={{ ...styles }}>{children}</sc.AnimatedDiv>
    </Sticky>
  );
};

const sc = {
  AnimatedDiv: styled(animated.div)`
    background-color: var(--white);
    position: relative;
  `,
};
