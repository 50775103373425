export class EraReplaySample {
  static multipleFromBytes(bytes: Uint8Array): {
    remainingBytes: Uint8Array;
    samples: EraReplaySample[];
  } {
    const view = new DataView(bytes.buffer);

    const samplesCount = view.getUint8(0);
    bytes = bytes.slice(1);

    const samples = [];
    for (let i = 0; i < samplesCount; i++) {
      const sampleBytes = bytes.slice(0, 4);
      const replaySample = EraReplaySample.fromBytes(sampleBytes);
      samples.push(replaySample);

      bytes = bytes.slice(4);
    }

    return {
      remainingBytes: bytes,
      samples,
    };
  }

  static fromBytes(bytes: Uint8Array): EraReplaySample {
    const view = new DataView(bytes.buffer);
    const heaterSetPoint = view.getInt16(0, true);
    const usage = view.getInt16(2, true);

    return new EraReplaySample(heaterSetPoint, usage);
  }

  heaterSetPoint: number;
  usage: number;

  constructor(heaterSetPoint: number, usage: number) {
    this.heaterSetPoint = heaterSetPoint;
    this.usage = usage;
  }

  toJson(): {
    heaterSetPoint: number;
    usage: number;
  } {
    return {
      heaterSetPoint: this.heaterSetPoint,
      usage: this.usage,
    };
  }

  toBytes(): Uint8Array {
    const bytes = new Uint8Array(new ArrayBuffer(4));
    const view = new DataView(bytes.buffer);

    view.setInt16(0, this.heaterSetPoint, true);
    view.setInt16(2, this.usage, true);

    return bytes;
  }
}
