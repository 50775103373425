import React, { useState } from "react";
import styled, { css, SimpleInterpolation } from "styled-components";

import * as Text from "../../shared/components/Text";

import { desktopBreakpoint } from "../utils";
import { ChevronDown, ChevronUp } from "./Icons";

type TruncatedTextProps = {
  maxLength: number;
  text?: string;
};

const chevronStyles = css`
  height: 16px;
  width: 16px;
`;

const TruncatedText: React.FC<TruncatedTextProps> = ({
  maxLength,
  text,
  ...props
}) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  if (!text) return null;

  const columnCount = text.length < maxLength * 0.6 ? 1 : 2;

  if (text.length < maxLength) {
    return (
      <sc.TextContainer
        columnCount={columnCount}
        isExpanded={isExpanded}
        {...props}
      >
        <span>{text}</span>
      </sc.TextContainer>
    );
  }

  const Chevron = isExpanded ? sc.ChevronUp : sc.ChevronDown;

  return (
    <sc.TextContainer
      columnCount={columnCount}
      isExpanded={isExpanded}
      {...props}
    >
      <span>
        {isExpanded ? text : text.slice(0, maxLength) + "…"}{" "}
        <Chevron onClick={(): void => setIsExpanded(!isExpanded)} />
      </span>
    </sc.TextContainer>
  );
};

export default TruncatedText;

type TextLengthProps = {
  columnCount: number;
  isExpanded: boolean;
};

const sc = {
  ChevronDown: styled(ChevronDown)`
    ${chevronStyles}
  `,

  ChevronUp: styled(ChevronUp)`
    ${chevronStyles}
  `,

  TextContainer: styled.div<TextLengthProps>`
    ${Text.PlaaxLight}
    column-count: 1;
    column-fill: auto;
    font-size: 16px;
    line-height: 1;
    text-align: left;

    ${desktopBreakpoint(
      css<TextLengthProps>`
        column-count: ${(props): SimpleInterpolation => props.columnCount};
        line-height: 1.13;
      `
    )}
  `,
};
