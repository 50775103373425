import React from "react";
import styled from "styled-components";

import RegionCA from "../../assets/images/regions/ca@2x.png";
import RegionUS from "../../assets/images/regions/us@2x.png";
import RegionWorld from "../../assets/images/regions/world@2x.png";
import * as ps from "../../shared/components/PillSelect";

import * as c from "../constants";

type CountryPillSelectOption = ps.PillSelectOption & {
  imageUrl: string;
};

const COUNTRY_OPTIONS: CountryPillSelectOption[] = [
  {
    active: false,
    imageUrl: RegionUS,
    label: "",
    value: c.SsoCountry.US,
  },
  {
    active: false,
    imageUrl: RegionCA,
    label: "",
    value: c.SsoCountry.CANADA,
  },
  {
    active: false,
    imageUrl: RegionWorld,
    label: "",
    value: c.SsoCountry.INTERNATIONAL,
  },
];

export type SsoCountrySelectProps = {
  onChange: (selectedOption: ps.PillSelectOption) => void;
  value: c.SsoCountry;
};

export const SsoCountrySelect: React.FC<SsoCountrySelectProps> = ({
  onChange,
  value,
  ...props
}) => {
  const countryOptions = COUNTRY_OPTIONS.map((option) => {
    option.active = option.value === value;
    return option;
  });

  return (
    <ps.PillSelect
      onChange={onChange}
      options={countryOptions}
      PillOption={sc.CountryPillOption}
      {...props}
    />
  );
};

const sc = {
  CountryPillOption: styled.div<CountryPillSelectOption>`
    background: url(${(props) => props.imageUrl}) center center no-repeat;
    background-size: 40px;
    border: solid ${(props) => (props.active ? "2px" : "1px")} var(--black);
    border-radius: 40px;
    cursor: pointer;
    height: 40px;
    margin-right: 16px;
    position: relative;
    width: 40px;

    &:last-child {
      margin-right: 0;
    }

    ${(props) =>
      !props.active &&
      `
      &:after {
        background-color: rgba(75, 79, 84, 0.5);
        border-radius: 38px;
        content: " ";
        position: absolute;
        top: 1;
        left: 1;
        width: 38px;
        height: 38px;
      }
    `};
  `,
};
