// Spec:
// https://paxlabs.atlassian.net/wiki/spaces/PMA/pages/1518207002/Consumer+Data+Analytics+Spec#Pod-Feedback

import { StrainReviewJson } from "../../api/consumer/types/reviews";
import * as c from "../../pods/constants";
import { AppThunk } from "../../shared/types";

import { trackEventAction } from "../actions";

const EVENT_NAME = "strain_review";

type StrainReviewEventProperties = {
  celebrate: boolean;
  chill: boolean;
  create: boolean;
  currentPod: number;
  energize: boolean;
  focus: boolean;
  hustle: boolean;
  isNewReview: boolean;
  notes?: string | "";
  partnerId?: string;
  rating: number;
  relief: boolean;
  rest: boolean;
  strainId?: string;
  totalPodCount: number;
  unwind: boolean;
  uplift: boolean;
};

type TrackStrainReview = (
  review: StrainReviewJson,
  options?: { isNewReview?: boolean; partnerId?: string }
) => AppThunk;

export const trackStrainReview: TrackStrainReview =
  (review, options = {}) =>
  (dispatch, getState): void => {
    return trackEventAction<StrainReviewEventProperties>(EVENT_NAME, {
      celebrate: !!review.effects?.includes(c.CELEBRATE),
      chill: !!review.effects?.includes(c.CHILL),
      create: !!review.effects?.includes(c.CREATE),
      currentPod: 1,
      energize: !!review.selectedEffects?.includes(c.ENERGIZED),
      focus: !!review.selectedEffects?.includes(c.FOCUS),
      hustle: !!review.effects?.includes(c.HUSTLE),
      isNewReview: !!options.isNewReview,
      notes: review.text,
      partnerId: options?.partnerId,
      rating: review.rating,
      relief: !!review.selectedEffects?.includes(c.RELIEF),
      rest: !!review.selectedEffects?.includes(c.REST),
      strainId: review.strainId,
      totalPodCount: 1,
      unwind: !!review.selectedEffects?.includes(c.UNWIND),
      uplift: !!review.selectedEffects?.includes(c.UPLIFTED),
    })(dispatch, getState, null);
  };
