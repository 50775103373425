import React, { useEffect, useState } from "react";
import styled, { css, SimpleInterpolation } from "styled-components";

import { useDesktopMediaQuery } from "../../shared/hooks";

import { desktopBreakpoint } from "../utils";
import { Row } from "./Flex";
import { ChevronLeft, ChevronRight } from "./Icons";
import * as Text from "./Text";

export type CarouselProps = {
  children?: React.ReactNode;
  initialPage?: number;
  onNextPageClick?: (newPage: number) => unknown;
  onPreviousPageClick?: (newPage: number) => unknown;
  numPages?: number;
  pageSize: number;
  title?: string;
};

export const Carousel: React.FC<CarouselProps> = ({
  children,
  initialPage,
  onNextPageClick,
  onPreviousPageClick,
  pageSize,
  numPages,
  title,
  ...props
}) => {
  const isDesktop = useDesktopMediaQuery();
  const [page, setPage] = useState<number>(initialPage || 1);
  const childrenArray = React.Children.toArray(children);

  numPages = isDesktop
    ? numPages || Math.ceil(childrenArray.length / pageSize)
    : 1;

  useEffect(() => {
    // Resetting the page is needed when transitioning from desktop to mobile
    if (!isDesktop) {
      return setPage(1);
    }

    setPage(initialPage || 1);
  }, [initialPage, isDesktop]);

  const handleArrowLeftClick = (): void => {
    if (page <= 1) return;
    if (onPreviousPageClick) onPreviousPageClick(page - 1);

    setPage(page - 1);
  };

  const handleArrowRightClick = (): void => {
    if (numPages === undefined || page >= numPages) return;
    if (onNextPageClick) onNextPageClick(page + 1);

    setPage(page + 1);
  };

  const chevronContainerHeight = 28;
  const startIndex = (page - 1) * pageSize;
  const endIndex = isDesktop
    ? startIndex + pageSize
    : startIndex + pageSize + 1;

  return (
    <div {...props}>
      <sc.TitleRow>
        <sc.Title data-testid={title?.replace(" ", "-").toLowerCase()}>
          {title}
        </sc.Title>
        {numPages > 1 && (
          <Row>
            <sc.ChevronContainer
              height={chevronContainerHeight}
              isDisabled={page <= 1}
              marginRight={8}
            >
              <sc.ChevronLeft
                $isDisabled={page <= 1}
                data-testid="chevron-left"
                onClick={handleArrowLeftClick}
              />
            </sc.ChevronContainer>
            <sc.ChevronContainer
              height={chevronContainerHeight}
              isDisabled={page >= numPages}
              paddingRight={3}
            >
              <sc.ChevronRight
                $isDisabled={page >= numPages}
                data-testid="chevron-right"
                onClick={handleArrowRightClick}
              />
            </sc.ChevronContainer>
          </Row>
        )}
      </sc.TitleRow>
      <sc.ContentRow>{childrenArray.slice(startIndex, endIndex)}</sc.ContentRow>
    </div>
  );
};

const chevronStyles = css`
  height: 25px;
  width: 25px;
`;

type ChevronContainerProps = {
  isDisabled?: boolean;
  marginRight?: number;
  paddingRight?: number;
};

const sc = {
  ChevronContainer: styled(Row)<ChevronContainerProps>`
    align-items: center;
    border-radius: 100%;
    justify-content: center;
    margin-right: ${(props): SimpleInterpolation => props.marginRight || 0}px;
    padding-right: ${(props): SimpleInterpolation => props.paddingRight || 0}px;

    &:hover {
      background-color: ${(props): SimpleInterpolation =>
        props.isDisabled ? "transparent" : "rgba(204, 204, 204, 0.2)"};
    }
  `,

  ChevronLeft: styled(ChevronLeft)`
    ${chevronStyles}
  `,

  ChevronRight: styled(ChevronRight)`
    ${chevronStyles}
  `,

  ContentRow: styled(Row)`
    align-items: left;
    overflow-x: auto;
    padding: 2px 0 12px;
    user-select: none;
    width: 100%;

    ${desktopBreakpoint(
      css`
        overflow-x: visible;
        width: 100%;
      `
    )}
  `,

  Title: styled.div`
    ${Text.Plaax}
    font-size: 18px;

    ${desktopBreakpoint(
      css`
        font-size: 20px;
      `
    )}
  `,

  TitleRow: styled(Row)`
    justify-content: space-between;
    margin-bottom: 8px;
    user-select: none;
    width: 100%;

    ${desktopBreakpoint(
      css`
        min-height: 28px;
      `
    )}
  `,
};
