import React from "react";

import { STRAIN_POTENCY_DISPLAY_MAP } from "../api/consumer/constants";
import * as consumerTypes from "../api/consumer/types";
import { Flavors, OrderBy, Potency } from "../api/consumer/types/strain";
import * as icons from "../shared/components/Icons";
import * as text from "../shared/text";
import * as packagingImages from "./components/HazePackagingImages";
import * as t from "./types";

export const CBD = "CBD";
export const HYBRID = "HYBRID";
export const INDICA = "INDICA";
export const SATIVA = "SATIVA";

// Deprecated effects
export const CELEBRATE = "CELEBRATE";
export const CHILL = "CHILL";
export const CREATE = "CREATE";
export const HUSTLE = "HUSTLE";

// New Effects
export const ENERGIZED = "ENERGIZED";
export const FOCUS = "FOCUS";
export const RELIEF = "RELIEF";
export const REST = "REST";
export const UNWIND = "UNWIND";
export const UPLIFTED = "UPLIFTED";

export const ALPHABETICAL_BY_POD = "ALPHABETIC";
export const ALPHABETICAL_BY_BRAND = "DEFAULT";
export const HIGHEST_RATED = "AVERAGE_RATING";
export const NEWEST = "NEWEST";
export const RANDOM = "RANDOM";
export const RELEVANCE = "RELEVANCE";

export const PODS_PER_CAROUSEL_DESKTOP = 3;
export const PODS_PER_CAROUSEL_MOBILE = 5;

export const SUGGESTED_SEARCHES_DEFAULTS = [
  "blue",
  "pineapple",
  "lemon",
  "sour",
  "purple",
];

export const COMMON_TERPENES = [
  "Limonene",
  "Linalool",
  "Myrcene",
  "Pinene",
  "Humulene",
  "Caryophyllene",
  "Terpinolene",
];

const terpeneFlavorsMap: { [key: string]: string } = {
  BISABOLOL: "Lemon, Floral, Sharp",
  CAMPHENE: "Fir, Herbal, Sharp",
  CAMPHOR: "Mint, Herbal, Sharp",
  CARENE: "Cedar, Woody, Sweet",
  CARYOPHYLLENE: "Pepper, Herbal, Spice",
  CARYOPHYLLENE_OXIDE: "Lemon, Herbal, Sharp",
  CEDRENE: "Cedar, Woody, Spice",
  CYMENE: "Woody, Musk, Spice",
  EUCALYPTOL: "Eucalyptus, Herbal, Sweet",
  FENCHOL: "Camphor, Woody, Sweet",
  GERANIOL: "Citrus, Floral, Spice",
  GERANYL_ACETATE: "Floral, Herbal, Sweet",
  GUAIOL: "Pine, Floral, Musk",
  HUMULENE: "Hops, Woody, Sharp",
  ISOBORNEOL: "Camphor, Floral, Sweet",
  ISOPULEGOL: "Mint, Herbal, Sharp",
  ISOPULGEOL: "Mint, Herbal, Sweet",
  LIMONENE: "Lemon, Floral, Sharp",
  LINALOOL: "Citrus, Floral, Sweet",
  MENTHOL: "Mint, Herbal, Sharp",
  MYRCENE: "Citrus, Herbal, Spice",
  NEROLIDOL: "Apple-rose, Floral, Sweet",
  OCIMENE: "Mango, Herbal, Sharp",
  PHELLANDRENE: "Black Pepper, Herbal, Spice",
  PHYTOL: "Jasmine, Floral, Sweet",
  PINENE: "Pine, Sharp, Sweet",
  PULEGONE: "Camphor, Musk, Sharp",
  SABINENE: "Mint, Herbal, Sweet",
  TERPINENE: "Lemon, Herbal, Sweet",
  TERPINEOL: "Lilac, Floral, Sweet",
  TERPINOLENE: "Diesel, Musk, Sharp",
  VALENCENE: "Orange, Woody, Sweet",
};

export function getTerpeneFlavors(terpeneName: string): string {
  return terpeneFlavorsMap[terpeneName]
    ? terpeneFlavorsMap[terpeneName]
    : "n/a";
}

export const DEFAULT_BAR_HEIGHT = 20;
export const DESKTOP_SEARCH_HEADER_HEIGHT = 146;

export const POD_CARD_HEIGHT_MOBILE = 270;
export const POD_GRID_ROW_HEIGHT_MOBILE = POD_CARD_HEIGHT_MOBILE + 20;
export const POD_CARD_HEIGHT_DESKTOP = 326;
export const POD_GRID_ROW_HEIGHT_DESKTOP = POD_CARD_HEIGHT_DESKTOP + 20;

export const MAX_RECOMMENDED_SEARCHES = 5;

const REGION_OPTION_CANADA = { label: "Canada", value: "CANADA" };
const REGION_OPTION_US = { label: "United States", value: "US" };
export const REGION_OPTIONS = [REGION_OPTION_CANADA, REGION_OPTION_US];

export const RESPONSIVE_POD_COLUMN_COUNT: { [key: string]: t.PodColumnCount } =
  {
    [t.ResponsiveScreenSize.SMALL]: 1,
    [t.ResponsiveScreenSize.MEDIUM]: 2,
    [t.ResponsiveScreenSize.LARGE]: 3,
    [t.ResponsiveScreenSize.X_LARGE]: 4,
    [t.ResponsiveScreenSize.XX_LARGE]: 4,
    [t.ResponsiveScreenSize.DESKTOP_SMALL]: 1,
    [t.ResponsiveScreenSize.DESKTOP_MEDIUM]: 2,
    [t.ResponsiveScreenSize.DESKTOP_LARGE]: 3,
  };

// (PodCardWidth * PodColumnCount) + (PodCardMargin * PodColumnCount-1) + padding(2px on each side = 4)
export const RESPONSIVE_POD_ROW_WIDTH: { [key: string]: t.PodRowWidth } = {
  [t.ResponsiveScreenSize.SMALL]: 164,
  [t.ResponsiveScreenSize.MEDIUM]: 340,
  [t.ResponsiveScreenSize.LARGE]: 516,
  [t.ResponsiveScreenSize.X_LARGE]: 692,
  [t.ResponsiveScreenSize.XX_LARGE]: 692,
  [t.ResponsiveScreenSize.DESKTOP_SMALL]: 286,
  [t.ResponsiveScreenSize.DESKTOP_MEDIUM]: 584,
  [t.ResponsiveScreenSize.DESKTOP_LARGE]: 882,
};

// Desktop pods filter sidebar width and margin
export const FILTER_SIDEBAR_WIDTH = 274;
export const FILTER_SIDEBAR_MARGIN = 40;
export const FILTER_SIDEBAR_WIDTH_WITH_MARGIN_BUFFER =
  FILTER_SIDEBAR_WIDTH + FILTER_SIDEBAR_MARGIN + 16;

/* eslint-disable sort-keys */
export const STRAIN_CLASSIFICATIONS = [
  { value: INDICA, activeBackgroundColor: "var(--pax-navy-blue)" },
  { value: SATIVA, activeBackgroundColor: "var(--pax-yellow)" },
  { value: HYBRID, activeBackgroundColor: "var(--pax-lavender)" },
  { value: CBD, activeBackgroundColor: "var(--pax-forest-green)" },
];
/* eslint-enable sort-keys */

/* eslint-disable sort-keys */
export const STRAIN_SELECTED_EFFECTS = [
  { Icon: icons.Rest, value: REST },
  { Icon: icons.Unwind, value: UNWIND },
  { Icon: icons.Relief, value: RELIEF },
  { Icon: icons.Focus, value: FOCUS },
  { Icon: icons.Uplifted, value: UPLIFTED },
  { Icon: icons.Energized, value: ENERGIZED },
];
/* eslint-enable sort-keys */

/* eslint-disable sort-keys */
export const STRAIN_POTENCY = [
  {
    value: Potency.MILD,
    label: STRAIN_POTENCY_DISPLAY_MAP[Potency.MILD],
    activeBackgroundColor: "var(--white)",
  },
  {
    value: Potency.MODERATE,
    label: STRAIN_POTENCY_DISPLAY_MAP[Potency.MODERATE],
    activeBackgroundColor: "var(--white)",
  },
  {
    value: Potency.STRONG,
    label: STRAIN_POTENCY_DISPLAY_MAP[Potency.STRONG],
    activeBackgroundColor: "var(--white)",
  },
  {
    value: Potency.VERY_STRONG,
    label: STRAIN_POTENCY_DISPLAY_MAP[Potency.VERY_STRONG],
    activeBackgroundColor: "var(--white)",
  },
];
/* eslint-enable sort-keys */

export type StrainSortFilter = {
  value: string;
  label: string;
  orderByValue: consumerTypes.strain.OrderBy;
};

/* eslint-disable sort-keys */
export const STRAIN_SORT_FILTERS = [
  {
    value: RELEVANCE,
    label: text.RELEVANCE,
    orderByValue: OrderBy.RELEVANCE,
  },
  {
    value: ALPHABETICAL_BY_POD,
    label: text.ALPHABETICAL_BY_POD,
    orderByValue: OrderBy.ALPHABETIC,
  },
  {
    value: ALPHABETICAL_BY_BRAND,
    label: text.ALPHABETICAL_BY_BRAND,
    orderByValue: OrderBy.DEFAULT,
  },
  {
    value: HIGHEST_RATED,
    label: text.HIGHEST_RATED,
    orderByValue: OrderBy.AVERAGE_RATING,
  },
  {
    value: NEWEST,
    label: text.NEWEST,
    orderByValue: OrderBy.NEWEST,
  },
];
/* eslint-enable sort-keys */

export const STRAIN_AVERAGE_RATING_FILTERS = [
  { label: "All Ratings", value: "" },
  {
    label: "4.0 Stars",
    value: "4.0",
  },
  {
    label: "3.0 Stars",
    value: "3.0",
  },
  {
    label: "2.0 Stars",
    value: "2.0",
  },
  {
    label: "1.0 Stars",
    value: "1.0",
  },
];

export const EFFECTS_ICON_MAP: { [key: string]: React.ReactElement } = {
  ENERGIZED: <icons.EffectEnergized />,
  FOCUS: <icons.EffectFocus />,
  RELIEF: <icons.EffectRelief />,
  REST: <icons.EffectRest />,
  UNWIND: <icons.EffectUnwind />,
  UPLIFTED: <icons.EffectUplifted />,
};

export const EFFECTS_TEXT_MAP: { [key: string]: string } = {
  ENERGIZED: text.ENERGIZED,
  FOCUS: text.FOCUS,
  RELIEF: text.RELIEF,
  REST: text.REST,
  UNWIND: text.UNWIND,
  UPLIFTED: text.UPLIFTED,
};

export const PAX_STRAIN_FLAVOR_MAP: {
  [key in consumerTypes.strain.PAX_STRAIN]: consumerTypes.strain.Flavors[];
} = {
  [consumerTypes.strain.PAX_STRAIN.HAZE_BLUE_DREAM]: [
    consumerTypes.strain.Flavors.EARTHY,
    consumerTypes.strain.Flavors.BLUEBERRY,
  ],
  [consumerTypes.strain.PAX_STRAIN.HAZE_BLUEBERRY_OG]: [
    consumerTypes.strain.Flavors.TANGY,
    consumerTypes.strain.Flavors.FRUITY,
  ],
  [consumerTypes.strain.PAX_STRAIN.HAZE_CALIFORNIA_ORANGE]: [
    consumerTypes.strain.Flavors.CITRUS,
    consumerTypes.strain.Flavors.ORANGE,
  ],
  [consumerTypes.strain.PAX_STRAIN.DIAMONDS_DURBAN_POISON]: [
    consumerTypes.strain.Flavors.CITRUS,
    consumerTypes.strain.Flavors.SPICY,
  ],
  [consumerTypes.strain.PAX_STRAIN.DIAMONDS_GRANDDADDY_PURPLE]: [
    consumerTypes.strain.Flavors.BLUEBERRY,
  ],
  [consumerTypes.strain.PAX_STRAIN.HAZE_GUAVA_GELATO]: [
    consumerTypes.strain.Flavors.TROPICAL,
    consumerTypes.strain.Flavors.CREAMY,
  ],
  [consumerTypes.strain.PAX_STRAIN.HAZE_JACK_HERER]: [
    consumerTypes.strain.Flavors.FLORAL,
    consumerTypes.strain.Flavors.SPICY,
  ],
  [consumerTypes.strain.PAX_STRAIN.HAZE_LAVENDER_HAZE]: [
    consumerTypes.strain.Flavors.FRUITY,
    consumerTypes.strain.Flavors.LAVENDER,
  ],
  [consumerTypes.strain.PAX_STRAIN.HAZE_LEMON_CAKE]: [
    consumerTypes.strain.Flavors.LEMON_PEEL,
    consumerTypes.strain.Flavors.CITRUS,
  ],
  [consumerTypes.strain.PAX_STRAIN.HAZE_MOCHI_COOKIES]: [
    consumerTypes.strain.Flavors.SPICY,
    consumerTypes.strain.Flavors.EARTHY,
  ],
  [consumerTypes.strain.PAX_STRAIN.DIAMONDS_OG_KUSH]: [
    consumerTypes.strain.Flavors.CITRUS,
    consumerTypes.strain.Flavors.SPICY,
  ],
  [consumerTypes.strain.PAX_STRAIN.HAZE_PINEAPPLE_EXPRESS]: [
    consumerTypes.strain.Flavors.PINEAPPLE,
    consumerTypes.strain.Flavors.FRUITY,
  ],
  [consumerTypes.strain.PAX_STRAIN.DIAMONDS_STRAWBERRY_COUGH]: [
    consumerTypes.strain.Flavors.SUGAR,
  ],
  [consumerTypes.strain.PAX_STRAIN.DIAMONDS_SUNSET_SHERBET]: [
    consumerTypes.strain.Flavors.SUGAR,
    consumerTypes.strain.Flavors.FRUITY,
  ],
  [consumerTypes.strain.PAX_STRAIN.HAZE_TAHOE_ROSE]: [
    consumerTypes.strain.Flavors.EARTHY,
    consumerTypes.strain.Flavors.SPICY,
  ],
  [consumerTypes.strain.PAX_STRAIN.DIAMONDS_WATERMELON_Z]: [
    consumerTypes.strain.Flavors.SUGAR,
  ],
  [consumerTypes.strain.PAX_STRAIN.DIAMONDS_WEDDING_CAKE]: [
    consumerTypes.strain.Flavors.TANGY,
    consumerTypes.strain.Flavors.SUGAR,
  ],
  [consumerTypes.strain.PAX_STRAIN.HAZE_WIFI_MINTS]: [
    consumerTypes.strain.Flavors.SPICY,
    consumerTypes.strain.Flavors.FLORAL,
  ],
};

export const PAX_STRAIN_PACKAGING_MAP: {
  [key in consumerTypes.strain.PAX_STRAIN]: string;
} = {
  [consumerTypes.strain.PAX_STRAIN.HAZE_BLUE_DREAM]:
    packagingImages.HazeBlueDream,
  [consumerTypes.strain.PAX_STRAIN.HAZE_BLUEBERRY_OG]:
    packagingImages.HazeBlueberryOg,
  [consumerTypes.strain.PAX_STRAIN.HAZE_CALIFORNIA_ORANGE]:
    packagingImages.HazeCaliforniaOrange,
  [consumerTypes.strain.PAX_STRAIN.DIAMONDS_DURBAN_POISON]:
    packagingImages.DiamondsDurbanPoison,
  [consumerTypes.strain.PAX_STRAIN.DIAMONDS_GRANDDADDY_PURPLE]:
    packagingImages.DiamondsGranddaddyPurple,
  [consumerTypes.strain.PAX_STRAIN.HAZE_GUAVA_GELATO]:
    packagingImages.HazeGuavaGelato,
  [consumerTypes.strain.PAX_STRAIN.HAZE_JACK_HERER]:
    packagingImages.HazeJackHerer,
  [consumerTypes.strain.PAX_STRAIN.HAZE_LAVENDER_HAZE]:
    packagingImages.HazeLavenderHaze,
  [consumerTypes.strain.PAX_STRAIN.HAZE_LEMON_CAKE]:
    packagingImages.HazeLemonCake,
  [consumerTypes.strain.PAX_STRAIN.HAZE_MOCHI_COOKIES]:
    packagingImages.HazeMochiCookies,
  [consumerTypes.strain.PAX_STRAIN.DIAMONDS_OG_KUSH]:
    packagingImages.DiamondsOgKush,
  [consumerTypes.strain.PAX_STRAIN.HAZE_PINEAPPLE_EXPRESS]:
    packagingImages.HazePineappleExpress,
  [consumerTypes.strain.PAX_STRAIN.DIAMONDS_STRAWBERRY_COUGH]:
    packagingImages.DiamondsStrawberryCough,
  [consumerTypes.strain.PAX_STRAIN.DIAMONDS_SUNSET_SHERBET]:
    packagingImages.DiamondsSunsetSherbet,
  [consumerTypes.strain.PAX_STRAIN.HAZE_TAHOE_ROSE]:
    packagingImages.HazeTahoeRose,
  [consumerTypes.strain.PAX_STRAIN.DIAMONDS_WATERMELON_Z]:
    packagingImages.DiamondsWatermelonZ,
  [consumerTypes.strain.PAX_STRAIN.DIAMONDS_WEDDING_CAKE]:
    packagingImages.DiamondsWeddingCake,
  [consumerTypes.strain.PAX_STRAIN.HAZE_WIFI_MINTS]:
    packagingImages.HazeWifiMints,
};

export const STRAIN_FLAVOR_ICON_MAP: {
  [key in Flavors]: React.FunctionComponent<{ color?: string }>;
} = {
  BLUEBERRY: icons.Blueberry,
  CITRUS: icons.Citrus,
  CREAMY: icons.Creamy,
  EARTHY: icons.Earthy,
  FLORAL: icons.Floral,
  FRUITY: icons.Fruity,
  LAVENDER: icons.Lavender,
  LEMON_PEEL: icons.LemonPeel,
  ORANGE: icons.Orange,
  PINE: icons.Pine,
  PINEAPPLE: icons.Pineapple,
  SPICY: icons.Spicy,
  SUGAR: icons.Sugar,
  TANGY: icons.Tangy,
  TROPICAL: icons.Tropical,
};

export const STRAIN_FLAVORS_DATA = [
  {
    Icon: STRAIN_FLAVOR_ICON_MAP.BLUEBERRY,
    value: Flavors.BLUEBERRY,
  },
  {
    Icon: STRAIN_FLAVOR_ICON_MAP.CITRUS,
    value: Flavors.CITRUS,
  },
  {
    Icon: STRAIN_FLAVOR_ICON_MAP.CREAMY,
    value: Flavors.CREAMY,
  },
  {
    Icon: STRAIN_FLAVOR_ICON_MAP.EARTHY,
    value: Flavors.EARTHY,
  },
  {
    Icon: STRAIN_FLAVOR_ICON_MAP.FLORAL,
    value: Flavors.FLORAL,
  },
  {
    Icon: STRAIN_FLAVOR_ICON_MAP.FRUITY,
    value: Flavors.FRUITY,
  },
  {
    Icon: STRAIN_FLAVOR_ICON_MAP.LAVENDER,
    value: Flavors.LAVENDER,
  },
  {
    Icon: STRAIN_FLAVOR_ICON_MAP.LEMON_PEEL,
    value: Flavors.LEMON_PEEL,
  },
  {
    Icon: STRAIN_FLAVOR_ICON_MAP.ORANGE,
    value: Flavors.ORANGE,
  },
  {
    Icon: STRAIN_FLAVOR_ICON_MAP.PINE,
    value: Flavors.PINE,
  },
  {
    Icon: STRAIN_FLAVOR_ICON_MAP.PINEAPPLE,
    value: Flavors.PINEAPPLE,
  },
  {
    Icon: STRAIN_FLAVOR_ICON_MAP.SPICY,
    value: Flavors.SPICY,
  },
  { Icon: STRAIN_FLAVOR_ICON_MAP.SUGAR, value: Flavors.SUGAR },
  {
    Icon: STRAIN_FLAVOR_ICON_MAP.TANGY,
    value: Flavors.TANGY,
  },
  {
    Icon: STRAIN_FLAVOR_ICON_MAP.TROPICAL,
    value: Flavors.TROPICAL,
  },
];
