import { StrainsFilter } from "../types";

export const getDefaultStrainsFilter = (): StrainsFilter => ({
  desiredEffects: [],
  flavors: [],
  minAvgRating: "",
  orderBy: undefined,
  partnerId: "",
  potency: [],
  query: "",
  region: "",
  selectedEffects: [],
  state: "",
  strainClassifications: [],
});

export const buildStrainsFilter = (
  partialFilter: Partial<StrainsFilter>
): StrainsFilter => {
  return {
    ...getDefaultStrainsFilter(),
    ...partialFilter,
  };
};

export const isStrainsFilterEmpty = (
  filter: Partial<StrainsFilter>
): boolean => {
  for (const value of Object.values(filter)) {
    if (value && value.length > 0) {
      return false;
    }
  }

  return true;
};
