import { McuManagerResponseType } from "../ble/McuManagerResponse";

export default class Upload {
  data: Uint8Array;
  offset: number;
  sendPacket: (data: Uint8Array, offset: number) => Promise<UploadResponse>;

  constructor(
    data: Uint8Array,
    sendPacket: (data: Uint8Array, offset: number) => Promise<UploadResponse>
  ) {
    this.data = data;
    this.offset = 0;
    this.sendPacket = sendPacket;
  }

  sendNext(): Promise<void> {
    return this.send(this.offset);
  }

  async send(offset: number): Promise<void> {
    const response: UploadResponse = await this.sendPacket(this.data, offset);

    if (response.rc !== 0)
      throw new Error(`Error sending packet: rc=${response.rc}`);

    this.offset = response.off;
  }

  reset(): void {
    this.offset = 0;
  }

  isFinished(): boolean {
    return this.offset === this.data.length;
  }
}

export interface UploadCallback {
  /**
   * Called when a response has been received successfully.
   *
   * @param current the number of bytes sent so far.
   * @param total the size of the image in bytes.
   * @param timestamp the timestamp of when the response was received.
   */
  onUploadProgressChanged(
    current: number,
    total: number,
    timestamp: number
  ): void;

  /**
   * Called when the upload has failed.
   *
   * @param error the error. See the cause for more info.
   */
  onUploadFailed(error: Error): void;

  /**
   * Called when the upload has been canceled.
   */
  onUploadCanceled(): void;

  /**
   * Called when the upload has finished successfully.
   */
  onUploadCompleted(): void;
}

export type UploadResponse = McuManagerResponseType & {
  // The offset. The number of bytes that have been received.
  off: number;
};
