import React from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";

type SearchParamLinkProps = RouteComponentProps & {
  to: string;
  onClick?: () => void;
};

const SearchParamLink: React.FC<SearchParamLinkProps> = ({
  to,
  children,
  location,
  staticContext,
  ...props
}) => {
  return (
    <Link
      to={{
        pathname: to,
        search: location.search,
      }}
      {...props}
    >
      {staticContext}
      {children}
    </Link>
  );
};

export default withRouter(SearchParamLink);
