import styled, { css } from "styled-components";

import { Row } from "../shared/components/Flex";
import DefaultPage from "../shared/components/Page";
import { PartnerLogo } from "../shared/components/PartnerLogo";
import { desktopBreakpoint } from "../shared/utils";

export const Page = styled(DefaultPage)`
  margin: 0 auto;
  max-width: ${(props) => props.theme.pageWidth};
  padding: 16px;
  width: 100%;

  ${desktopBreakpoint(
    css`
      padding: 60px 0;
      width: ${(props) => props.theme.myPax.pageContentWidth};
    `
  )}
`;

type PodCardContainerProps = {
  height: number;
  width: number;
};

export const PodCardContainer = styled.div<PodCardContainerProps>`
  background-color: var(--white);
  border-radius: 8px;
  box-shadow: 0 1px 6px 0 var(--bluey-grey-30);
  cursor: pointer;
  height: ${({ height }) => height}px;
  margin: 2px 4px 0;
  width: ${({ width }) => width}px;

  & a {
    color: var(--black);
    display: flex;
    height: ${({ height }) => height}px;
    text-decoration: none;
    width: ${({ width }) => width - 40}px;
  }

  &:hover {
    background-color: var(--white-three);
  }

  ${desktopBreakpoint(
    css<PodCardContainerProps>`
      margin: 0 4px;
      overflow: hidden;
      position: relative;

      & a {
        align-items: center;
        flex-direction: column;
        width: ${({ width }) => width}px;
      }
    `
  )}
`;

type PodCardPartnerLogoProps = {
  borderColor: string;
  isFavorite?: boolean;
};

export const PodCardPartnerLogo = styled(PartnerLogo)<PodCardPartnerLogoProps>`
  border: solid 1px ${({ borderColor }) => borderColor};
  border-radius: 100px;
  height: 65px;
  margin: 20px 14px;
  min-width: 65px;
  padding: 1px;
  width: 65px;

  ${desktopBreakpoint(
    css<PodCardPartnerLogoProps>`
      margin: ${({ isFavorite }) =>
        isFavorite ? "30px auto 10px" : "25px auto 0"};
    `
  )}
`;

export const PodsRow = styled(Row)`
  margin-top: 2px;
`;
