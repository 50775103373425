import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled, { css } from "styled-components";

import * as r from "../../../main/routes";
import * as podsHooks from "../../../pods/hooks";
import { goToPodsSearchPage } from "../../../pods/utils";
import { PaxSymbol } from "../../../shared/components/Icons";
import { PartnerLogo as SharedPartnerLogo } from "../../../shared/components/PartnerLogo";
import { useDeviceControlsPodSwitcher } from "../../../shared/PodSwitcher";

import * as s from "../../selectors";

export type PartnerLogoProps = {
  isDeviceLocked?: boolean;
  useStrainById?: podsHooks.UseStrainById;
};

export const PartnerLogo: React.FC<PartnerLogoProps> = ({
  children,
  isDeviceLocked = false,
  useStrainById = podsHooks.useStrainById,
  ...props
}) => {
  const history = useHistory();

  const { strainId } = useSelector(s.getDevicePod);
  const doesDeviceSupportPods = useSelector(s.doesDeviceSupportPods);

  const { strain } = useStrainById(strainId);

  const handlePodSwitcherClick = (): void => {
    goToPodsSearchPage(history);
  };

  const handlePartnerLogoClick = (): void => {
    if (!strainId) return;

    history.push(r.podDetails(strainId));
  };

  const podSwitcher = useDeviceControlsPodSwitcher(
    handlePodSwitcherClick,
    strain
  );

  if (!doesDeviceSupportPods) {
    return (
      <sc.PartnerLogoPlaceholder
        data-testid="partner-logo-placeholder"
        {...props}
      >
        <sc.PaxLogo />
      </sc.PartnerLogoPlaceholder>
    );
  }

  if (isDeviceLocked) return <sc.PartnerLogo {...props} />;

  return (
    <sc.PartnerLogo
      onClick={handlePartnerLogoClick}
      partner={strain?.partner}
      {...props}
    >
      {children}
      {podSwitcher}
    </sc.PartnerLogo>
  );
};

const logoStyles = css`
  align-items: center;
  background-color: var(--white);
  border-radius: 70px;
  display: flex;
  height: 140px;
  justify-content: center;
  width: 140px;
`;

const sc = {
  PartnerLogo: styled(SharedPartnerLogo)`
    ${logoStyles}
  `,

  PartnerLogoPlaceholder: styled.div`
    ${logoStyles}
    border: solid 1px var(--very-light-pink);
  `,

  PaxLogo: styled(PaxSymbol)`
    height: 40%;
    width: 40%;
  `,
};
