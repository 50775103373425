import { Field, Form, Formik } from "formik";
import React from "react";
import styled, { css, SimpleInterpolation } from "styled-components";

import { NullableDeviceType } from "../../device/types";
import Button from "../../shared/components/Button";
import { Row } from "../../shared/components/Flex";
import { FormValidationTooltip } from "../../shared/components/FormValidationTooltip";
import * as Text from "../../shared/components/Text";

import * as text from "../text";

const SERIAL_LENGTH = 8;

export type FormValues = {
  serial: string;
};

type SerialFormError = {
  serial?: string;
};

const serialInputValidation = (values: FormValues) => {
  const errors: SerialFormError = {};
  if (!values.serial) {
    errors.serial = text.SERIAL_IS_REQUIRED;
  } else if (!/^[a-zA-Z0-9]*$/.test(values.serial)) {
    errors.serial = text.SERIAL_MUST_BE_ALPHANUMERIC;
  } else if (values.serial.length < SERIAL_LENGTH) {
    errors.serial = text.SERIAL_MUST_CONTAIN_8_CHARS;
  }
  return errors;
};

export type SerialInputFormProps = {
  cancelButton?: React.ReactElement;
  deviceError?: string;
  initialSerial?: string;
  onSubmit: (formValues: FormValues) => void | Promise<void>;
  selectedDeviceType: NullableDeviceType;
};

export const SerialInputForm: React.FC<SerialInputFormProps> = ({
  cancelButton,
  deviceError,
  initialSerial = "",
  onSubmit,
  selectedDeviceType,
}) => {
  const initialValues: FormValues = { serial: initialSerial };

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      enableReinitialize
      validate={serialInputValidation}
    >
      {({ errors }) => (
        <sc.Form data-testid="serial-input-form" noValidate>
          {selectedDeviceType && (
            <sc.SerialField data-testid="serial-field">
              <Field
                autoCapitalize="off"
                autoComplete="off"
                autoCorrect="off"
                maxLength={SERIAL_LENGTH}
                name="serial"
                placeholder="A7G27GBU"
                required
                title={text.PLEASE_USE_ONLY_ALPHANUMERIC_CHARS}
                type="text"
                data-testid="serial-field-input"
              />
            </sc.SerialField>
          )}
          <FormValidationTooltip error={errors.serial} />
          {deviceError && (
            <sc.Message data-testid="device-connect-message">
              <Text.ErrorSpan>{deviceError}</Text.ErrorSpan>
            </sc.Message>
          )}
          <sc.ButtonsRow>
            {cancelButton}
            <sc.ContinueButton
              data-testid="connect-button"
              disabled={!selectedDeviceType}
              type="submit"
            >
              {text.ENTER}
            </sc.ContinueButton>
          </sc.ButtonsRow>
        </sc.Form>
      )}
    </Formik>
  );
};

const disabledStyles = css`
  cursor: not-allowed;
  pointer-events: none;
`;

const sc = {
  ButtonsRow: styled(Row)`
    justify-content: center;
    margin-top: 32px;

    & a:first-child button {
      padding-right: 60px;
    }
  `,

  ContinueButton: styled(Button)`
    width: 108px;

    ${({ disabled }: { disabled?: boolean }): SimpleInterpolation =>
      disabled ? disabledStyles : ""}

    & button[disabled] {
      cursor: not-allowed;
      pointer-events: none;
    }
  `,

  Form: styled(Form)`
    position: relative;
  `,

  Message: styled.div`
    margin-top: 40px;
    min-height: 21px;
    text-align: center;
  `,

  SerialField: styled.div`
    text-align: center;

    & input {
      height: 36px;
      text-align: center;
      text-transform: uppercase;
    }
  `,
};
