import React from "react";
import styled from "styled-components";

type RoundTemperatureSliderProps = {
  isDisabled?: boolean;
  onClick: (e: React.MouseEvent<SVGPathElement>) => void;
  strokeDashArray: number;
  strokeDashOffset: number;
};

export const RoundTemperatureSlider: React.FC<RoundTemperatureSliderProps> = ({
  isDisabled = false,
  onClick,
  strokeDashArray,
  strokeDashOffset,
}) => {
  return (
    <svg height={240} width={240}>
      <defs>
        <linearGradient
          id="gradient"
          x1="0%"
          y1="0%"
          x2="70%"
          y2="0%"
          gradientTransform="rotate(90)"
        >
          <stop offset="0%" stopColor="var(--maize)" />
          <stop offset="100%" stopColor="var(--salmon)" />
        </linearGradient>
      </defs>
      <sc.Path
        d="M 120, 120 m 0, -107 a 107,107 0 0,1 0,214 a -107,-107 0 0,1 0,-214"
        data-testid="mobile-thermostat-rail"
        fill="none"
        onClick={onClick}
        stroke="var(--very-light-pink-3)"
        strokeDasharray={strokeDashArray}
        strokeLinecap="round"
        strokeWidth={25}
      />
      {!isDisabled && (
        <sc.Path
          d="M 120, 120 m 0, -107 a 107,107 0 0,1 0,214 a -107,-107 0 0,1 0,-214"
          data-testid="mobile-thermostat-rail-fill"
          fill="none"
          onClick={onClick}
          stroke="url(#gradient)"
          strokeDasharray={2 * strokeDashArray}
          strokeDashoffset={strokeDashOffset}
          strokeLinecap="round"
          strokeWidth={25}
        />
      )}
    </svg>
  );
};

const sc = {
  Path: styled.path`
    transform: rotate(226.5deg);
    transform-origin: center center;
  `,
};
