import { PAX_PARTNER_ID } from "../../../api/consumer/constants";
import * as consumerTypes from "../../../api/consumer/types";
import { Flavors, PAX_STRAIN } from "../../../api/consumer/types/strain";
import * as text from "../../../shared/text";

import { PAX_STRAIN_FLAVOR_MAP } from "../../constants";

const PAST_TENSE_EFFECTS = ["ENERGIZED", "RELIEF", "UPLIFTED"];
const REVIEW_COUNT_CUTOFF = 5;

export const PAST_TENSE_MAP: { [key: string]: string } = {
  ENERGIZED: "energized",
  RELIEF: "relieved",
  UPLIFTED: "uplifted",
};

export const getEffectSummary = (
  effectData: consumerTypes.strain.EffectJson[]
): string => {
  const topTwo = effectData.slice(0, 2);

  // Present tense effects
  const presentTenseEffects = topTwo.reduce((value, effect) => {
    if (!PAST_TENSE_EFFECTS.includes(effect.effect)) {
      if (value.length === 0) {
        return value + ` ${effect.effect.toLowerCase()}`;
      } else {
        return value + ` and ${effect.effect.toLowerCase()}`;
      }
    }
    return value;
  }, "");

  // Past tense effects
  let pastTenseEffects = topTwo.reduce((value, effect) => {
    if (PAST_TENSE_EFFECTS.includes(effect.effect)) {
      if (value.length === 0) {
        return value + ` ${PAST_TENSE_MAP[effect.effect]}`;
      } else {
        return value + ` and ${PAST_TENSE_MAP[effect.effect]}`;
      }
    }
    return value;
  }, "");

  // Prepend past tense effects with "feel"
  if (pastTenseEffects.length > 0) {
    if (presentTenseEffects.length > 0) {
      pastTenseEffects = " and feel" + pastTenseEffects;
    } else {
      pastTenseEffects = " feel" + pastTenseEffects;
    }
  }

  return (
    text.THIS_STRAIN_HELPED_MOST_PEOPLE + presentTenseEffects + pastTenseEffects
  );
};

export const isPaxStrain = (partnerId: string): boolean => {
  return partnerId === PAX_PARTNER_ID;
};

export const getFlavors = (strainId: string): Flavors[] => {
  return PAX_STRAIN_FLAVOR_MAP[strainId as PAX_STRAIN] || [];
};

export const shouldShowEffects = (
  effectData: consumerTypes.strain.EffectJson[],
  numEffectReviews: number
): boolean => {
  if (effectData.length === 0) return false;

  const bpEffectPresent = effectData.some((data) => data.wasBpSelected);

  return bpEffectPresent || numEffectReviews >= REVIEW_COUNT_CUTOFF;
};
