import React from "react";
import styled, { SimpleInterpolation } from "styled-components";

import * as c from "../../device/constants";
import { ConnectionStatus } from "../../device/types";
import * as Text from "../../shared/components/Text";

type MyDeviceConnectionStatusProps = {
  connectionStatus: ConnectionStatus;
  isDeviceActive: boolean;
};

export const MyDeviceConnectionStatus: React.FC<MyDeviceConnectionStatusProps> =
  ({ connectionStatus, isDeviceActive }) => {
    const connectionStatusText =
      isDeviceActive || connectionStatus === ConnectionStatus.CONNECTING
        ? c.connectionStatusTextMap[connectionStatus]
        : c.connectionStatusTextMap[ConnectionStatus.NOT_CONNECTED];

    return (
      <sc.ConnectionStatus connected={isDeviceActive}>
        {connectionStatusText}
      </sc.ConnectionStatus>
    );
  };

type ConnectionStatusProps = {
  connected: boolean;
};

const sc = {
  ConnectionStatus: styled.div<ConnectionStatusProps>`
    ${Text.PlaaxLight}
    color: ${({ connected }): SimpleInterpolation =>
      connected ? "var(--teal-green)" : "var(--brown-grey)"};
    font-size: 18px;
    text-align: center;
  `,
};
