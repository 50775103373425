const ERA_PRO_PRESET_DOSAGES = [0, 1250, 2500, 3750, 5000];

export class SessionControl {
  static ACTION_START = 1;
  static ACTION_STOP = 2;
  static ACTION_ACTIVE = 3;

  static getUsageForDose(doseId: number): number {
    return ERA_PRO_PRESET_DOSAGES[doseId];
  }
}
