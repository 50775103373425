import React, { useState } from "react";
import styled, { SimpleInterpolation } from "styled-components";

import { EraReplayAction, EraReplayJson } from "../../../pax-ble/types/Era";

import { SessionProgressBar } from "../../device/components/SessionProgressBar";
import { Dropdown } from "../../shared/components/Dropdown";
import * as ps from "../../shared/components/PillSelect";

import { StartEraSession, StopEraSession } from "../actions";
import { DOSE_OPTIONS, ERA_SESSION_LOCKOUT_TIME } from "../constants";
import { useSessionControlStatus } from "../hooks";

export type StateProps = {
  heaterSetPoint: number;
  isDeviceLocked: boolean;
  replay?: EraReplayJson;
  supportsSessionControl: boolean;
};

export type EraSessionControlMobileProps = StateProps & {
  onStartEraSession: StartEraSession;
  onStopEraSession: StopEraSession;
};

export const EraSessionControlMobile: React.FC<EraSessionControlMobileProps> =
  ({
    heaterSetPoint,
    isDeviceLocked,
    replay,
    onStartEraSession,
    onStopEraSession,
    supportsSessionControl,
  }) => {
    const { isPreviousLockoutActive, isSessionComplete } =
      useSessionControlStatus(heaterSetPoint, onStartEraSession, replay);
    const [selectedOption, setSelectedOption] = useState<ps.PillSelectOption>();

    const isDisabled = isDeviceLocked || !supportsSessionControl;
    const handleDoseChange = (selectedOption: ps.PillSelectOption): void => {
      const presetDoseId = selectedOption.value;
      setSelectedOption(selectedOption);
      onStartEraSession(presetDoseId, heaterSetPoint);
    };

    if (replay && (replay.doseId || isPreviousLockoutActive)) {
      if (replay.action === EraReplayAction.USER_STOP) {
        onStopEraSession(replay.doseId);
      }

      const handleSessionCancelClick = (e: React.MouseEvent): void => {
        e.preventDefault();
        setSelectedOption(undefined);
        onStopEraSession(replay.doseId);
      };

      const label = replay.doseId
        ? `${DOSE_OPTIONS[replay.doseId].label} Dose`
        : "Session Control";

      return (
        <sc.Container isDisabled={isDisabled}>
          <SessionProgressBar
            initialLockoutSeconds={ERA_SESSION_LOCKOUT_TIME}
            label={label}
            onCancelClick={handleSessionCancelClick}
            progressPercentage={replay.progressPercentage}
            showTimeout={isSessionComplete || isPreviousLockoutActive}
          />
        </sc.Container>
      );
    }

    return (
      <sc.Container isDisabled={isDisabled}>
        <Dropdown
          data-testid="session-control-dropdown"
          key={`session-control-${selectedOption}`}
          options={Object.values(DOSE_OPTIONS)}
          placeholder="Session Control"
          onChange={handleDoseChange}
          value={selectedOption}
        />
      </sc.Container>
    );
  };

type ContainerProps = {
  isDisabled: boolean;
};

const sc = {
  Container: styled.div<ContainerProps>`
    opacity: ${({ isDisabled }): SimpleInterpolation =>
      isDisabled ? "0.3" : "1"};
    padding: 10px 8px 0;
    pointer-events: ${({ isDisabled }): SimpleInterpolation =>
      isDisabled ? "none" : "auto"};

    & > progress {
      width: 100%;
    }
  `,
};
