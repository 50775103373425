import React from "react";
import { render } from "react-dom";

import ErrorBoundary from "../shared/components/ErrorBoundary";
import { SsoApp } from "./containers/SsoApp";

import "../styles/index.css";

render(
  <ErrorBoundary>
    <SsoApp />
  </ErrorBoundary>,
  document.getElementById("root")
);
