import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import * as s from "../../../device/selectors";
import * as a from "../../../era-pro/actions";
import { ERA_PRO_DOSE_OPTIONS } from "../../../era-pro/constants";
import { useDoseStatus } from "../../../era-pro/hooks";
import { Row } from "../../../shared/components/Flex";
import * as Text from "../../../shared/components/Text";
import * as t from "../../../shared/text";
import { calculatePercentage } from "../../../shared/utils";

import { getSessionProgressText } from "../../utils";

export const DoseControlModalContent: React.FC = () => {
  const dispatch = useDispatch();
  const session = useSelector(s.getSession);
  const { isSessionInProgress, shouldShowTimeout } = useDoseStatus(session);

  const { doseId, initialTotalUsage, usageTowardCompletion } = session;

  const isDoseSelected = doseId !== 0;

  const percentageCompleted = calculatePercentage(
    usageTowardCompletion,
    initialTotalUsage
  );

  const doseText = isDoseSelected ? t.nDose(doseId) : t.SELECT_DOSE;

  const handleCancelClick = () => {
    if (!isSessionInProgress) return;

    dispatch(a.stopEraProSession());
  };

  const handleDoseOptionClick = (doseId: number): void => {
    dispatch(a.startEraProSession(doseId));
  };

  return (
    <sc.Container>
      <sc.Title>{t.DOSE_CONTROL}</sc.Title>
      <sc.ExpandedContent>
        <sc.Circle>
          {isSessionInProgress || isDoseSelected ? (
            <sc.SessionProgressText>
              {getSessionProgressText(percentageCompleted, shouldShowTimeout)}
            </sc.SessionProgressText>
          ) : (
            <sc.SelectDose>{doseText}</sc.SelectDose>
          )}
        </sc.Circle>
      </sc.ExpandedContent>
      <sc.DoseOptions>
        {ERA_PRO_DOSE_OPTIONS.map((option) => {
          const isOptionActive =
            isSessionInProgress || isDoseSelected
              ? session.doseId === option.value
              : false;
          return (
            <sc.DoseOption
              key={option.value}
              isActive={isOptionActive}
              onClick={() => handleDoseOptionClick(option.value)}
            >
              {option.label}
            </sc.DoseOption>
          );
        })}
      </sc.DoseOptions>
      <sc.Cancel
        isActive={isSessionInProgress || isDoseSelected}
        onClick={handleCancelClick}
      >
        {t.CANCEL_DOSE}
      </sc.Cancel>
    </sc.Container>
  );
};

type ActiveProp = {
  isActive: boolean;
};

const sc = {
  Cancel: styled.div<ActiveProp>`
    align-items: center;
    background-color: ${({ isActive }) =>
      isActive ? "var(--black)" : "var(--black-50)"};
    border-radius: 24px;
    color: ${({ isActive }) => (isActive ? "var(--white)" : "var(--white-50)")};
    display: flex;
    height: 48px;
    justify-content: center;
    width: 100%;

    &:hover {
      cursor: ${({ isActive }) => (isActive ? "pointer" : "default")};
    }
  `,

  Circle: styled.div`
    align-items: center;
    border: solid 12px var(--black);
    border-radius: 100px;
    display: flex;
    height: 200px;
    justify-content: center;
    width: 200px;
  `,

  Container: styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 16px;
  `,

  DoseOption: styled.div<ActiveProp>`
    ${Text.PlaaxBold}
    align-items: center;
    background-color: ${({ isActive }) => (isActive ? "var(--black)" : "none")};
    border-radius: 20px;
    color: ${({ isActive }) => (isActive ? "var(--white)" : "none")};
    display: flex;
    font-size: 24px;
    height: 40px;
    justify-content: center;
    line-height: 24px;
    width: 40px;
  `,

  DoseOptions: styled(Row)`
    justify-content: space-between;
    margin: 0 auto 26px;
    max-width: 268px;
    width: 100%;
  `,

  ExpandedContent: styled.div`
    align-items: center;
    display: flex;
    flex: 1;
    justify-content: center;
  `,

  SelectDose: styled.div`
    ${Text.PlaaxBold}
    font-size: 20px;
    line-height: 24px;
  `,

  SessionProgressText: styled.div`
    ${Text.PlaaxBold}
    font-size: 30px;
    line-height: 36px;
  `,

  Title: styled.div`
    ${Text.PlaaxBold}
    font-size: 20px;
    line-height: 24px;
  `,
};
