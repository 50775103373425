import * as t from "./index";

export type ColorMode = {
  startup: t.ColorMode;
  heating: t.ColorMode;
  regulating: t.ColorMode;
  standby: t.ColorMode;
};

export enum HeatingState {
  HEATING = 0,
  READY = 1,
  LIP_BOOSTING = 2,
  LIP_COOLING = 3,
  MOTION_STANDBY = 4,
  OVEN_OFF = 5,
  TEMP_SET_MODE = 6,
}

export enum DynamicModeType {
  STANDARD = 0,
  BOOST = 1,
  EFFICIENCY = 2,
  STEALTH = 3,
  FLAVOR = 4,
}

export type HeatingParameters = {
  standbyTemperature: number;
  noMotionToStandbyTime: number;
  noLipCooldownTemperatureChange: number;
  noLipCooldownStart: number;
  noLipCooldownRate: number;
  noLipPowerOffTime: number;
  boostTemperatureChange: number;
  rampTargetTemperature: number;
  rampStartingTemperature: number;
  rampRate: number;
  options: number;
};

export type DynamicMode = {
  label: string;
  description: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Icon: React.FunctionComponent<any>;
  type: DynamicModeType;
  heatingParameters: HeatingParameters;
  brightness?: number;
};
