import * as c from "./constants";
import { Endian } from "./types";

export function mergeTypedArrays(a: Uint8Array, b: Uint8Array): Uint8Array {
  // Check for truthy values or empty arrays to avoid unnecessary construction of a new array.
  if (!b || b.length === 0) return a;
  if (!a || a.length === 0) return b;

  const c = new Uint8Array(a.length + b.length);
  c.set(a);
  c.set(b, a.length);

  return c;
}

export const parseHeader = (
  data: Uint8Array
): {
  commandId: number;
  flags: number;
  groupId: number;
  length: number;
  op: number;
  sequenceNumber: number;
} => {
  if (data.length < c.HEADER_LENGTH) {
    throw new Error("invalid data length");
  }

  const op = data[0];
  const flags = data[1];
  const length = (data[2] << 8) + data[3];
  const groupId = (data[4] << 8) + data[5];
  const sequenceNumber = data[6];
  const commandId = data[7];

  return {
    commandId,
    flags,
    groupId,
    length,
    op,
    sequenceNumber,
  };
};

const byteToUnsignedInt = (b: number): number => {
  return b & 0xff;
};

export const byteArrayToUnsignedInt = (
  data: Uint8Array,
  offset: number,
  endian: Endian,
  length: number
): number => {
  if (length < 0 || length > 4) {
    throw new Error(
      `Length must be between 0 and 4 inclusive (length=${length}).`
    );
  }

  let result = 0;

  for (let i = 0; i < length; i++) {
    const unsignedByte = byteToUnsignedInt(data[i + offset]);

    if (endian === null || endian === Endian.BIG) {
      result += unsignedByte * Math.pow(2, (length - 1 - i) * 8); // big endian
    } else {
      // Math.pow is used instead of the bitwise operator <<.
      // Shift operators in JS convert their operands to 32-bit integers which overflow when length >= 4.
      result += unsignedByte * Math.pow(2, i * 8);
    }
  }

  return result;
};

export const areByteArraysEqual = (a: Uint8Array, b: Uint8Array): boolean => {
  if (a.byteLength !== b.byteLength) return false;
  return a.every((val, i) => val === b[i]);
};

function leftPad(s: string, count: number, pad: string): string {
  while (s.length < count) {
    s = pad + s;
  }
  return s;
}

export function normalizeUuid(uuid: string | number): string {
  if (typeof uuid === "number" && uuid > 0) {
    return `${leftPad(uuid.toString(16), 8, "0")}-0000-1000-8000-00805f9b34fb`;
  } else {
    // TODO: handle standard UUID names, throw exception on invalid values
    return uuid.toString();
  }
}
