import { RELEVANCE } from "../../../pods/constants";
import { StrainsFilter } from "../../../pods/types";

import { Potency } from "../types/strain";

type PaginationParams = {
  page: number;
  pageSize: number;
};

type PublicStrainsParams = PaginationParams & {
  filter: StrainsFilter;
};

export const publicStrains = ({
  page,
  pageSize,
  filter,
}: PublicStrainsParams): string => {
  let path = "/public/strains";

  const search = strainsFilterToApiParams(filter);
  path += search;

  const pagination = `${search ? "&" : "?"}page=${page}&page_size=${pageSize}`;
  path += pagination;

  return path;
};

export const strainsFilterToApiParams = (filter: StrainsFilter): string => {
  const searchParts: string[] = [];

  Object.entries(filter).forEach(([key, value]) => {
    switch (key) {
      case "desiredEffects":
        const desiredEffects = value as string[];

        desiredEffects?.forEach((effect) => {
          searchParts.push(`desiredEffects=${effect}`);
        });

        break;

      case "flavors":
        const flavors = value as string[];

        flavors?.forEach((flavor) => {
          searchParts.push(`flavors=${flavor}`);
        });

        break;

      case "orderBy":
        const orderBy = value as string;

        // Default order by relevance
        searchParts.push(`order_by=${orderBy || RELEVANCE}`);

        break;

      case "potency":
        const potency = value as Potency[];

        potency?.forEach((potency) => {
          searchParts.push(`potency=${potency}`);
        });

        break;

      case "query":
        const query = value as string;

        if (!query) break;

        searchParts.push(`search=${encodeURIComponent(query)}`);

        break;

      case "selectedEffects":
        const selectedEffects = value as string[];

        // Send Selected Effects as DesiredEffects
        selectedEffects?.forEach((effect) => {
          searchParts.push(`desiredEffects=${effect}`);
        });

        break;

      case "strainClassifications":
        const strainClassifications = value as string[];

        strainClassifications?.forEach((classification) => {
          searchParts.push(`strainClassifications=${classification}`);
        });

        break;

      default:
        if (!value) break;

        searchParts.push(`${key}=${value}`);
    }
  });

  return searchParts.length === 0 ? "" : "?" + searchParts.join("&");
};

export const strainById = (strainId: string): string => {
  return `/public/strains/${strainId}`;
};

type BatchResultsParams = PaginationParams & {
  strainId: string;
};

export const strainBatchResults = ({
  strainId,
  page,
  pageSize,
}: BatchResultsParams): string => {
  return `/strains/${strainId}/batch-results?page=${page}&page_size=${pageSize}`;
};

type BatchResultParams = {
  batchId: string;
  strainId: string;
};

export const strainBatchResult = ({
  batchId,
  strainId,
}: BatchResultParams): string => {
  return `/strains/${strainId}/batch-results/${batchId}`;
};

export const topStrains = (isoCode: string): string =>
  `/states/${isoCode}/top-strains`;

export const recommendedForYou = (isoCode: string): string =>
  `/states/${isoCode}/strains/recommended`;

export const missingStrainReports = (): string => "/missing-strain-reports";
