import React, { useState } from "react";
import { useSelector } from "react-redux";
import styled, { css } from "styled-components";

import * as consumerSelectors from "../../../api/consumer/selectors/user";
import * as modalSelectors from "../../../modal/selectors";
import { PaxLogo } from "../../../shared/components/Icons";
import * as Icons from "../../../shared/components/Icons";
import * as Text from "../../../shared/components/Text";
import * as sharedHooks from "../../../shared/hooks";
import { desktopBreakpoint } from "../../../shared/utils";

import { CANADA_HOME, US_HOME } from "../../constants";

import { MenuSlideIn } from "./MenuSlideIn";

export const MobilePaxNav: React.FC = () => {
  const setShouldSetOverflow = sharedHooks.useBodyScrollOverflow(
    "hidden",
    false
  );
  const isUserInUS = useSelector(consumerSelectors.getIsUserInUS);
  const isModalOpen = useSelector(modalSelectors.isModalOpen);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const handleHamburgerClick = () => {
    setIsMenuOpen(true);
    setShouldSetOverflow(true);
  };

  const handleMobileMenuClose = () => {
    setIsMenuOpen(false);
    setShouldSetOverflow(false);
  };

  return (
    <>
      <sc.NavContainer isFixed={isModalOpen}>
        <sc.Nav>
          <sc.LeftSection>
            <sc.HamburgerButton>
              <Icons.Hamburger
                data-testid="pax-nav-hamburger"
                onClick={handleHamburgerClick}
              />
            </sc.HamburgerButton>
          </sc.LeftSection>
          <sc.CenterSection>
            <a
              href={isUserInUS ? US_HOME : CANADA_HOME}
              target="_blank"
              rel="noopener noreferrer"
            >
              <sc.PaxLogo />
            </a>
          </sc.CenterSection>
          <sc.RightSection />
        </sc.Nav>
      </sc.NavContainer>
      <MenuSlideIn
        isMenuOpen={isMenuOpen}
        onRequestClose={handleMobileMenuClose}
      />
    </>
  );
};

type ContainerProps = {
  isFixed: boolean;
};

const sectionStyles = css`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: row;
`;

const sc = {
  CenterSection: styled.div`
    ${sectionStyles}
    justify-content: center;
  `,

  DesktopPAXLink: styled.a`
    margin-right: 15px;
  `,

  HamburgerButton: styled.button`
    background-color: var(--transparent);
    border: none;
    height: 31px;
    width: 31px;
  `,

  LeftSection: styled.div`
    ${sectionStyles}
    justify-content: flex-start;
  `,

  Nav: styled.nav`
    align-items: center;
    display: flex;
    flex-direction: row;
    height: 100%;
    justify-content: center;
    margin: auto;
    max-width: var(--nav-bar-width);
    padding: 0 16px;
    width: 100%;

    ${desktopBreakpoint(
      css`
        padding: 0 3.125%;
      `
    )}
  `,

  NavContainer: styled.div<ContainerProps>`
    ${Text.MediumLL}
    background-color: var(--white);
    height: 62px;
    position: ${({ isFixed }) => (isFixed ? "fixed" : "unset")};
    width: 100%;
    z-index: var(--z-index-pax-menu);
  `,

  PaxLogo: styled(PaxLogo)`
    width: 94px;
  `,

  RightSection: styled.div`
    ${sectionStyles}
  `,
};
