import { Action } from "../shared/types";

export const MODAL_CLOSED = "MODAL_CLOSED";
export const MODAL_OPENED = "MODAL_OPENED";
export const CLOSE_FIRMWARE_MODAL = "CLOSE_FIRMWARE_MODAL";
export const OPEN_FIRMWARE_MODAL = "OPEN_FIRMWARE_MODAL";
export const CLOSE_PRIVACY_POLICY_MODAL = "CLOSE_PRIVACY_POLICY_MODAL";
export const OPEN_PRIVACY_POLICY_MODAL = "OPEN_PRIVACY_POLICY_MODAL";
export const CLOSE_USAGE_AGREEMENT_MODAL = "CLOSE_USAGE_AGREEMENT_MODAL";
export const OPEN_USAGE_AGREEMENT_MODAL = "OPEN_USAGE_AGREEMENT_MODAL";
export const SET_HAS_SEEN_FIRMWARE_MODAL = "SET_HAS_SEEN_FIRMWARE_MODAL";
export const SET_HAS_ACCEPTED_PRIVACY_POLICY =
  "SET_HAS_ACCEPTED_PRIVACY_POLICY";

export type ModalAction = () => Action;

export const modalClosed: ModalAction = () => ({
  type: MODAL_CLOSED,
});

export const modalOpened: ModalAction = () => ({
  type: MODAL_OPENED,
});

export const closeFirmwareModal: ModalAction = () => ({
  type: CLOSE_FIRMWARE_MODAL,
});

export const openFirmwareModal: ModalAction = () => ({
  type: OPEN_FIRMWARE_MODAL,
});

export const closePrivacyPolicyModal: ModalAction = () => ({
  type: CLOSE_PRIVACY_POLICY_MODAL,
});

export const openPrivacyPolicyModal: ModalAction = () => ({
  type: OPEN_PRIVACY_POLICY_MODAL,
});

export const closeUsageAgreementModal: ModalAction = () => ({
  type: CLOSE_USAGE_AGREEMENT_MODAL,
});

export const openUsageAgreementModal: ModalAction = () => ({
  type: OPEN_USAGE_AGREEMENT_MODAL,
});

export const setHasAcceptedPrivacyPolicy: ModalAction = () => ({
  type: SET_HAS_ACCEPTED_PRIVACY_POLICY,
});

type SetHasSeenFirmwareModal = (hasSeenFirmwareModal: boolean) => Action;

export const setHasSeenFirmwareModal: SetHasSeenFirmwareModal = (
  hasSeenFirmwareModal
) => ({
  payload: { hasSeenFirmwareModal },
  type: SET_HAS_SEEN_FIRMWARE_MODAL,
});
