import * as PaxBleTypes from "../../pax-ble/types";

export const ANALYTICS_SPEC_VERSION = "0.0.1";

export const PERIPHERAL_MODEL_MAP: { [key in string]: string } = {
  [PaxBleTypes.DeviceType.ERA]: "era",
  [PaxBleTypes.DeviceType.ERA_PRO]: "era_pro",
  [PaxBleTypes.DeviceType.PAX_3]: "pax3",
  [PaxBleTypes.DeviceType.PAX_35]: "pax35",
};
