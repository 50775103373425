import * as c from "./appConstants";

export const isDevPageEnabled = !c.IS_PRODUCTION && !c.IS_BETA;
export const isFindMyPaxEnabled = true;
export const isGoogleMapsApiEnabled =
  c.IS_PRODUCTION || c.IS_STAGING || c.IS_BETA;
export const isExternalSsoEnabled = true;
export const isTestPageEnabled = !c.IS_PRODUCTION && !c.IS_BETA;
export const isExperienceModesEnabled = c.IS_LOCAL || c.IS_BETA;
export const shouldBlockAnalytics = !c.IS_BETA;
