import React from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import { deleteUserLocation } from "../../api/consumer/actions/users";
import { UserLocation } from "../../api/consumer/types/user";
import { Row } from "../../shared/components/Flex";
import * as icons from "../../shared/components/Icons";
import * as Text from "../../shared/components/Text";
import { TextButton } from "../../shared/components/TextButton";
import * as t from "../../shared/text";

type PodsLocationResetProps = {
  location: UserLocation;
  onReset: () => void;
};

export const PodsLocationReset: React.FC<PodsLocationResetProps> = ({
  location,
  onReset,
  ...props
}) => {
  const dispatch = useDispatch();

  const { displayName, isOverridden } = location;

  const handleLocationResetClick = (): void => {
    onReset();
    dispatch(deleteUserLocation);
  };

  const UnsetLocation = () => (
    <sc.ButtonContainer>
      <icons.LocationOff />
      <TextButton type="button" onClick={handleLocationResetClick}>
        {t.USE_CURRENT_LOCATION}
      </TextButton>
    </sc.ButtonContainer>
  );

  const CurrentLocationText = () => (
    <sc.ButtonContainer>
      <icons.LocationOn />
      <sc.CurrentLocationText>
        {t.usingLocation(displayName)}
      </sc.CurrentLocationText>
    </sc.ButtonContainer>
  );

  return (
    <div {...props}>
      {isOverridden ? <UnsetLocation /> : <CurrentLocationText />}
      <sc.Text>{t.BASED_ON_YOUR_INTERNET_CONNECTION}</sc.Text>
    </div>
  );
};

const sc = {
  ButtonContainer: styled(Row)`
    margin-bottom: 2px;
  `,

  CurrentLocationText: styled.div`
    font-size: 14px;
    margin-left: 8px;
    padding: 0 0 2px;
  `,

  Text: styled.div`
    ${Text.PlaaxLight}
    font-size: 12px;
    margin-left: 28px;
  `,
};
