import React from "react";
import styled, { css, SimpleInterpolation } from "styled-components";

import * as PaxBle from "../../../../pax-ble";

import * as c from "../../../device/constants";
import * as Text from "../../../shared/components/Text";
import { useDesktopMediaQuery } from "../../../shared/hooks";
import { desktopBreakpoint } from "../../../shared/utils";

export type FlowerOvenStateProps = {
  currentTemperature?: number;
  heatingState?: PaxBle.Types.Pax3.HeatingState;
  isDeviceCharging?: boolean;
  isDeviceLocked?: boolean;
};

const FlowerOvenState: React.FC<FlowerOvenStateProps> = ({
  currentTemperature,
  heatingState,
  isDeviceCharging,
  isDeviceLocked,
}: FlowerOvenStateProps) => {
  const isDesktop = useDesktopMediaQuery();
  if (heatingState === undefined || !currentTemperature)
    return <sc.Placeholder />;

  if (isDeviceCharging) {
    return <sc.OvenState>Charging</sc.OvenState>;
  }

  return (
    <sc.OvenState isDeviceLocked={isDeviceLocked}>
      {c.getHeatingStateLabel(heatingState)}
      {isDesktop ? " • " : " "}
      {Math.round(currentTemperature)}°
    </sc.OvenState>
  );
};

export default FlowerOvenState;

type OvenStateProps = {
  isDeviceLocked?: boolean;
};
const sc = {
  OvenState: styled.div<OvenStateProps>`
    ${Text.PlaaxLight}
    font-size: 20px;
    margin: 28px auto 24px;
    text-transform: uppercase;

    ${desktopBreakpoint(
      css`
        margin: auto;
      `
    )}

    ${({ isDeviceLocked }): SimpleInterpolation =>
      isDeviceLocked ? "color: var(--very-light-pink);" : ""}
  `,

  Placeholder: styled.div`
    height: 23px;
    margin: 24px auto;
  `,
};
