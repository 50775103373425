import React from "react";
import styled, { css } from "styled-components";

import * as consumerApi from "../../api/consumer";
import { ModalProps } from "../../modal/components/Modal";
import { useStrainById } from "../../pods/hooks";
import { Column } from "../../shared/components/Flex";
import { Pencil } from "../../shared/components/Icons";
import * as Text from "../../shared/components/Text";
import * as text from "../../shared/text";
import { desktopBreakpoint } from "../../shared/utils";

import { usePodFeedbackModalController } from "../hooks";
import { ModalContentStyles } from "../styledComponents";
import { StrainDetailsCard } from "./PodFeedbackForm/StrainDetailsCard";
import { EffectsRow } from "./EffectsRow";
import { StarRating } from "./StarRating";

export type PodFeedbackModalPreviewContentProps = ModalProps & {
  onEditClick: () => void;
  review?: consumerApi.types.reviews.StrainReviewJson;
};

export const PodFeedbackModalPreviewContent: React.FC<PodFeedbackModalPreviewContentProps> =
  ({ onEditClick, onRequestClose, review, ...props }) => {
    const controller = usePodFeedbackModalController(props.isOpen);
    const { strain } = useStrainById(review?.strainId);

    if (!review || !strain) return null;

    const handleCloseClick = (): void => {
      if (onRequestClose) onRequestClose();
    };

    return (
      <ModalContentStyles.Container
        data-testid="pod-feedback-preview-modal"
        $desktopWidth={controller.desktopWidth}
        {...props}
      >
        <ModalContentStyles.Content
          className="modal-content"
          $opacity={controller.contentOpacity}
        >
          <ModalContentStyles.Header>
            <ModalContentStyles.Close
              data-testid="close-preview-modal"
              onClick={handleCloseClick}
            />
            <ModalContentStyles.HeaderTitle>
              {text.MY_REVIEW}
            </ModalContentStyles.HeaderTitle>
            <sc.PencilContainer data-testid="edit-button" onClick={onEditClick}>
              <Pencil />
            </sc.PencilContainer>
          </ModalContentStyles.Header>
          <sc.Body>
            <StrainDetailsCard strain={strain} />
            <sc.ReviewContainer>
              <sc.Subtitle>{text.RATING}</sc.Subtitle>
              <StarRating data-testid="rating" disabled value={review.rating} />
              <sc.Subtitle>{text.EFFECTS}</sc.Subtitle>
              <EffectsRow effects={review.selectedEffects} />
              {review.text.length > 0 && (
                <>
                  <sc.Subtitle>{text.REVIEW}</sc.Subtitle>
                  <sc.ReviewText data-testid="review-text">
                    {review.text}
                  </sc.ReviewText>
                </>
              )}
            </sc.ReviewContainer>
          </sc.Body>
        </ModalContentStyles.Content>
      </ModalContentStyles.Container>
    );
  };

const sc = {
  Body: styled(ModalContentStyles.Body)`
    ${desktopBreakpoint(css`
      margin: 0 64px;
    `)}
  `,

  PencilContainer: styled.div`
    cursor: pointer;
    height: 32px;
    left: 18px;
    position: absolute;
    top: 10px;
    width: 32px;
    z-index: var(--z-index-modal-closer);

    & svg {
      margin: 8px;
    }

    &:hover {
      background: black;
      border-radius: 16px;
    }

    &:hover svg path {
      fill: var(--white);
    }
  `,

  ReviewContainer: styled(Column)`
    margin: 0 16px 16px;
    width: calc(100% - 32px);
  `,

  ReviewText: styled.div`
    ${Text.PlaaxLight}
    font-size: 14px;
    line-height: 24px;
  `,

  Subtitle: styled.div`
    ${Text.PlaaxBold}
    font-size: 14px;
    margin: 16px 0 8px;
  `,
};
