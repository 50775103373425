import React from "react";
import { useSelector } from "react-redux";

import * as Icons from "../../shared/components/Icons";

import { getBatteryLevel, getChargeStatus } from "../selectors";

const BatteryIndicator: React.FC = ({ ...props }) => {
  const chargeStatus = useSelector(getChargeStatus);
  const batteryLevel = useSelector(getBatteryLevel);

  let BatteryIcon;
  if (batteryLevel <= 0) BatteryIcon = Icons.BatteryEmpty;
  else if (batteryLevel <= 25) BatteryIcon = Icons.BatteryLow;
  else if (batteryLevel <= 50) BatteryIcon = Icons.Battery50;
  else if (batteryLevel <= 75) BatteryIcon = Icons.Battery75;
  else BatteryIcon = Icons.BatteryFull;

  if (!chargeStatus) return null;

  return (
    <BatteryIcon
      $animate={chargeStatus.isCharging && !chargeStatus.isChargingComplete}
      {...props}
    />
  );
};

export default BatteryIndicator;
