import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { Button } from "../../../shared/components/Button";
import { Options } from "../../../shared/components/Icons";

import { setStrainsFilterAndRedirect } from "../../actions";
import * as t from "../../types";

import { ActiveStrainFiltersIndicator } from "../ActiveStrainFiltersIndicator";
import { PodsFilterModal } from "./PodsFilterModal";

export const PodsFilterOpener: React.FC = ({ ...props }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [isFilterModalOpen, setIsFilterModalOpen] = useState<boolean>(false);

  const onStrainsFilterSet: t.OnStrainsFilterSet = (filter) => {
    dispatch(setStrainsFilterAndRedirect(history, filter));
  };

  const closeFilterModal = () => {
    setIsFilterModalOpen(false);
  };

  return (
    <>
      <sc.FilterButton
        aria-label="pods-filter"
        onClick={() => setIsFilterModalOpen(true)}
        transparent
        {...props}
      >
        <Options />
      </sc.FilterButton>
      <ActiveStrainFiltersIndicator />
      <PodsFilterModal
        disableWhenEmpty
        isOpen={isFilterModalOpen}
        onRequestClose={closeFilterModal}
        onStrainsFilterSet={onStrainsFilterSet}
      />
    </>
  );
};

const sc = {
  FilterButton: styled(Button)`
    height: 42px;
    padding: 9px 12px 15px 12px;
    width: 42px;
  `,
};
