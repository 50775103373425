import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { getBannerCookie, getLoginCookie } from "../cookies";
import * as a from "./actions";
import { SsoPage } from "./types";

export const useSsoCookieCheck = (): void => {
  const dispatch = useDispatch();

  const [initialLoginCookie, setInitialLoginCookie] = useState<string>(
    getLoginCookie()
  );
  const [loginCookie, setLoginCookie] = useState<string>(getLoginCookie());

  useEffect(() => {
    const interval = setInterval(() => setLoginCookie(getLoginCookie()), 500);

    return (): void => clearInterval(interval);
  }, []);

  useEffect(() => {
    const hasLoginCookieChanged = loginCookie !== initialLoginCookie;

    if (!hasLoginCookieChanged) return;

    if (loginCookie) {
      const bannerCookie = getBannerCookie();

      if (bannerCookie.includes("Password")) {
        dispatch(a.receivedResetPasswordSuccess());
      } else {
        dispatch(a.receivedLoginSuccess());
      }
    } else {
      // Log the user out.
      dispatch(a.ssoSignOut());
    }

    // Set initial cookie to latest value to track further changes to the login cookie.
    setInitialLoginCookie(loginCookie);
  }, [dispatch, initialLoginCookie, loginCookie]);
};

export const useSsoPageReset = (page: SsoPage): void => {
  const dispatch = useDispatch();

  useEffect(() => {
    return (): void => {
      dispatch(a.resetSsoPage(page));
    };
  }, [dispatch, page]);
};
