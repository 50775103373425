type State = {
  label: string;
  region: string;
};

// This ordering is by state/province name (label)
/* eslint-disable sort-keys */
export const REGION_STATES: { [key: string]: { [key: string]: State } } = {
  US: {
    "US-AL": { region: "US-AL", label: "Alabama" },
    "US-AK": { region: "US-AK", label: "Alaska" },
    "US-AZ": { region: "US-AZ", label: "Arizona" },
    "US-AR": { region: "US-AR", label: "Arkansas" },
    "US-CA": { region: "US-CA", label: "California" },
    "US-CO": { region: "US-CO", label: "Colorado" },
    "US-CT": { region: "US-CT", label: "Connecticut" },
    "US-DE": { region: "US-DE", label: "Delaware" },
    "US-DC": { region: "US-DC", label: "District Of Columbia" },
    "US-FL": { region: "US-FL", label: "Florida" },
    "US-GA": { region: "US-GA", label: "Georgia" },
    "US-HI": { region: "US-HI", label: "Hawaii" },
    "US-ID": { region: "US-ID", label: "Idaho" },
    "US-IL": { region: "US-IL", label: "Illinois" },
    "US-IN": { region: "US-IN", label: "Indiana" },
    "US-IA": { region: "US-IA", label: "Iowa" },
    "US-KS": { region: "US-KS", label: "Kansas" },
    "US-KY": { region: "US-KY", label: "Kentucky" },
    "US-LA": { region: "US-LA", label: "Louisiana" },
    "US-ME": { region: "US-ME", label: "Maine" },
    "US-MD": { region: "US-MD", label: "Maryland" },
    "US-MA": { region: "US-MA", label: "Massachusetts" },
    "US-MI": { region: "US-MI", label: "Michigan" },
    "US-MN": { region: "US-MN", label: "Minnesota" },
    "US-MS": { region: "US-MS", label: "Mississippi" },
    "US-MO": { region: "US-MO", label: "Missouri" },
    "US-MT": { region: "US-MT", label: "Montana" },
    "US-NE": { region: "US-NE", label: "Nebraska" },
    "US-NV": { region: "US-NV", label: "Nevada" },
    "US-NH": { region: "US-NH", label: "New Hampshire" },
    "US-NJ": { region: "US-NJ", label: "New Jersey" },
    "US-NM": { region: "US-NM", label: "New Mexico" },
    "US-NY": { region: "US-NY", label: "New York" },
    "US-NC": { region: "US-NC", label: "North Carolina" },
    "US-ND": { region: "US-ND", label: "North Dakota" },
    "US-OH": { region: "US-OH", label: "Ohio" },
    "US-OK": { region: "US-OK", label: "Oklahoma" },
    "US-OR": { region: "US-OR", label: "Oregon" },
    "US-PA": { region: "US-PA", label: "Pennsylvania" },
    "US-RI": { region: "US-RI", label: "Rhode Island" },
    "US-SC": { region: "US-SC", label: "South Carolina" },
    "US-SD": { region: "US-SD", label: "South Dakota" },
    "US-TN": { region: "US-TN", label: "Tennessee" },
    "US-TX": { region: "US-TX", label: "Texas" },
    "US-UT": { region: "US-UT", label: "Utah" },
    "US-VT": { region: "US-VT", label: "Vermont" },
    "US-VA": { region: "US-VA", label: "Virginia" },
    "US-WA": { region: "US-WA", label: "Washington" },
    "US-WV": { region: "US-WV", label: "West Virginia" },
    "US-WI": { region: "US-WI", label: "Wisconsin" },
    "US-WY": { region: "US-WY", label: "Wyoming" },
  },
  CA: {
    "CA-AB": { region: "CA-AB", label: "Alberta" },
    "CA-BC": { region: "CA-BC", label: "British Columbia" },
    "CA-MB": { region: "CA-MB", label: "Manitoba" },
    "CA-NB": { region: "CA-NB", label: "New Brunswick" },
    "CA-NL": { region: "CA-NL", label: "Newfoundland and Labrador" },
    "CA-NT": { region: "CA-NT", label: "Northwest Territories" },
    "CA-NS": { region: "CA-NS", label: "Nova Scotia" },
    "CA-NU": { region: "CA-NU", label: "Nunavut" },
    "CA-ON": { region: "CA-ON", label: "Ontario" },
    "CA-PE": { region: "CA-PE", label: "Prince Edward Island" },
    "CA-QC": { region: "CA-QC", label: "Quebec" },
    "CA-SK": { region: "CA-SK", label: "Saskatchewan" },
    "CA-YT": { region: "CA-YT", label: "Yukon" },
  },
};
/* eslint-enable sort-keys */
