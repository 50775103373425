import React from "react";
import styled from "styled-components";

import * as PaxBle from "../../../pax-ble";

import EraDeviceInfoOverlay from "../../era/containers/EraDeviceInfoOverlay";
import EraMobileSessionControl from "../../era/containers/EraSessionControlMobile";
import EraProDeviceInfoOverlay from "../../era-pro/containers/EraProDeviceInfoOverlay";
import EraProDoseControlMobile from "../../era-pro/containers/EraProDoseControlMobile";

import MobileThermostat from "../containers/MobileThermostat/MobileThermostat";
import { DevicePodDetailsMobile } from "./DevicePodDetailsMobile";

type PodDeviceControlsMobileProps = {
  deviceType: PaxBle.Types.DeviceType;
  isDeviceLocked: boolean | undefined;
};

export const PodDeviceControlsMobile: React.FC<PodDeviceControlsMobileProps> =
  ({ deviceType, isDeviceLocked }) => {
    const ControlComponent =
      deviceType === PaxBle.Types.DeviceType.ERA
        ? EraMobileSessionControl
        : EraProDoseControlMobile;

    const Overlay =
      deviceType === PaxBle.Types.DeviceType.ERA
        ? sc.EraDeviceInfoOverlay
        : sc.EraProDeviceInfoOverlay;

    return (
      <>
        {isDeviceLocked ? (
          <sc.LockedText>Device Locked</sc.LockedText>
        ) : (
          <ControlComponent />
        )}
        <sc.MobileContentContainer>
          <MobileThermostat />
          <Overlay />
        </sc.MobileContentContainer>
        <sc.DevicePodDetailsMobile isDeviceLocked={isDeviceLocked} />
      </>
    );
  };

const sc = {
  DevicePodDetailsMobile: styled(DevicePodDetailsMobile)`
    margin-top: 20px;
  `,

  EraDeviceInfoOverlay: styled(EraDeviceInfoOverlay)`
    left: calc(50% - 69px);
    position: absolute;
    top: 81px;
  `,

  EraProDeviceInfoOverlay: styled(EraProDeviceInfoOverlay)`
    left: calc(50% - 69px);
    position: absolute;
    top: 81px;
  `,

  LockedText: styled.div`
    color: var(--pale-red);
    font-size: 20px;
    margin-top: 15px;
    text-align: center;
    text-transform: uppercase;
  `,

  MobileContentContainer: styled.div`
    display: flex;
    position: relative;
  `,
};
