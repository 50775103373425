import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { StrainJson } from "../../../api/consumer/types";
import * as r from "../../../main/routes";
import { PodStrength } from "../../../pods/components/PodDetails/PodStrength";
import { StrainClassificationPill } from "../../../pods/components/StrainClassificationPill";
import { Row } from "../../../shared/components/Flex";
import { PartnerLogo } from "../../../shared/components/PartnerLogo";
import * as Text from "../../../shared/components/Text";

import { ModuleCard } from "./ModuleCard";

type PodDetailsModuleProps = {
  strain: StrainJson | null;
  width: number;
};

export const PodDetailsModule: React.FC<PodDetailsModuleProps> = ({
  strain,
  width,
}) => {
  const history = useHistory();

  if (!strain) return null;

  const handleClick = (): void => {
    if (!strain.id) return;

    history.push(r.podDetails(strain.id));
  };

  return (
    <ModuleCard isLarge onClick={handleClick} width={width - 16}>
      <sc.Container>
        <Row justifyContent={"space-between"}>
          <sc.PartnerLogo partner={strain.partner} />
          <StrainClassificationPill strain={strain} />
        </Row>
        <sc.StrainName data-testid="strain-name">{strain.name}</sc.StrainName>
        <sc.PartnerName data-testid="partner-name">
          {strain.partner.partnerName}
        </sc.PartnerName>
        <PodStrength isModule strain={strain} />
      </sc.Container>
    </ModuleCard>
  );
};

const sc = {
  Container: styled.div`
    margin: 12px 16px;
  `,

  PartnerLogo: styled(PartnerLogo)`
    align-self: center;
    background-color: var(--white);
    border-radius: 100px;
    height: 42px;
    width: 42px;
  `,

  PartnerName: styled.div`
    ${Text.PlaaxLight}
    font-size: 14px;
    margin-top: 6px;
  `,

  StrainName: styled.div`
    ${Text.PlaaxBold}
    font-size: 20px;
    margin-top: 12px;
  `,
};
