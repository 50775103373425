import { SsoCountry } from "../sso/constants";
import { MailingList } from "./types";

type MailingListMap = {
  [key in SsoCountry]: MailingList;
};

export const MAILING_LIST_MAP: MailingListMap = {
  [SsoCountry.INTERNATIONAL]: MailingList.EU,
  [SsoCountry.CANADA]: MailingList.CANADA,
  [SsoCountry.US]: MailingList.US,
};
