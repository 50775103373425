import React from "react";
import styled from "styled-components";

import { Column } from "../../shared/components/Flex";
import * as Text from "../../shared/components/Text";

import { getModeDescription, getModeLabel } from "../utils";

type ModeInformationProps = {
  activeUiFunction: number;
};

export const ModeInformation: React.FC<ModeInformationProps> = ({
  activeUiFunction,
}) => {
  return (
    <sc.Container>
      <sc.Mode>{getModeLabel(activeUiFunction)}</sc.Mode>
      <sc.Description>{getModeDescription(activeUiFunction)}</sc.Description>
    </sc.Container>
  );
};

const sc = {
  Container: styled(Column)`
    align-items: center;
    min-height: 160px;
    padding: 20px 0;
  `,

  Description: styled.div`
    color: var(--white);
    font-size: 14px;
    line-height: 20px;
    max-width: 220px;
  `,

  Mode: styled.div`
    ${Text.PlaaxBold}
    color: var(--white);
    font-size: 30px;
    line-height: 36px;
  `,
};
