import React, { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import * as PaxBle from "../../../../pax-ble";

import { getConnectedDevice } from "../../../bluetooth/connectedDevice";
import * as deviceSelectors from "../../../device/selectors";
import * as icons from "../../../shared/components/Icons";
import * as Text from "../../../shared/components/Text";
import * as text from "../../../shared/text";

import { ExperienceModesModal } from "../../ExperienceModesModal";

import { DeviceModeModalContent } from "../modals/DeviceModeModalContent";
import { ModuleCard } from "./ModuleCard";

export const DeviceModeModule: React.FC = () => {
  const device = getConnectedDevice() as PaxBle.EraProDevice;
  const activeUiFunction = useSelector(deviceSelectors.getActiveUiFunction);
  const isCustomTemperatureModeActive = useSelector(
    deviceSelectors.getIsCustomTemperatureModeActive
  );
  const heaterSetPoint = useSelector(deviceSelectors.getHeaterSetPoint);
  const [isExperienceModeSelected, setIsExperienceModeSelected] =
    useState<boolean>(!isCustomTemperatureModeActive);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const Icon: React.ReactNode = isCustomTemperatureModeActive ? (
    <sc.TempModeIcon />
  ) : (
    <sc.PaxSymbol $activePetal={activeUiFunction as 1 | 2 | 3 | 4} />
  );
  const modeTitle = isCustomTemperatureModeActive
    ? text.TEMPERATURE
    : text.EXPERIENCES;

  if (!device.vaporEngine) return null;

  const handleModalClose = () => {
    setIsModalOpen(false);

    if (isExperienceModeSelected && isCustomTemperatureModeActive) {
      device.vaporEngine?.writeActivePetalMode(1);
      return;
    }

    if (!isExperienceModeSelected && !isCustomTemperatureModeActive) {
      device.vaporEngine?.writeCustomTemp(heaterSetPoint);
      return;
    }
  };

  return (
    <>
      <ModuleCard onClick={() => setIsModalOpen(true)} title={text.DEVICE_MODE}>
        {Icon}
        <sc.ModeTitle>{modeTitle}</sc.ModeTitle>
      </ModuleCard>
      <ExperienceModesModal
        isOpen={isModalOpen}
        onRequestClose={handleModalClose}
        render={(props) => (
          <DeviceModeModalContent
            isExperienceModeSelected={isExperienceModeSelected}
            setIsExperienceModeSelected={setIsExperienceModeSelected}
            {...props}
          />
        )}
      />
    </>
  );
};

const sc = {
  ModeTitle: styled.div`
    ${Text.PlaaxBold}
    font-size: 12px;
    line-height: 18px;
    margin: 0 auto;
    text-transform: uppercase;
  `,

  PaxSymbol: styled(icons.PaxSymbol)`
    height: 40px;
    margin: 34px auto 22px;
    width: 40px;
  `,

  TempModeIcon: styled(icons.TempMode)`
    margin: 20px auto 10px;
  `,
};
