import styled, { SimpleInterpolation } from "styled-components";

type FlexProps = {
  alignItems?: string;
  height?: number;
  justifyContent?: string;
  flexDirection?: string;
};

export const Row = styled.div<FlexProps>`
  align-items: ${(props): SimpleInterpolation => props.alignItems || "normal"};
  display: flex;
  height: ${(props): SimpleInterpolation => props.height + "px" || "auto"};
  justify-content: ${(props): SimpleInterpolation =>
    props.justifyContent || "normal"};
`;

export const Column = styled.div<FlexProps>`
  align-items: ${(props): SimpleInterpolation => props.alignItems || "normal"};
  display: flex;
  flex-direction: column;
  height: ${(props): SimpleInterpolation => props.height + "px" || "auto"};
  justify-content: ${(props): SimpleInterpolation =>
    props.justifyContent || "normal"};
`;
