import React, { useEffect, useState } from "react";
import * as reactQuery from "react-query";
import { UseQueryResult } from "react-query";
import { useDispatch, useSelector } from "react-redux";

import * as consumerApi from "../../api/consumer";
import * as routes from "../../main/routes";
import { MainAppState } from "../../main/types";
import { useStrainById } from "../../pods/hooks";
import { Star } from "../../shared/components/Icons";
import { useSignInUpsellModal } from "../../shared/hooks";
import * as t from "../../shared/text";

import { DEFAULT_FORM_VALUES } from "../constants";
import { PodFeedbackModal } from "./PodFeedbackModal";
import { PodFeedbackToast } from "./PodFeedbackToast";

export type PodFeedbackPromptProps = {
  strainId: string;
  useQuery?: () => UseQueryResult<consumerApi.types.connectedPods.ConnectedPodJson>;
};

export const PodFeedbackPrompt: React.FC<PodFeedbackPromptProps> = ({
  strainId,
  useQuery = reactQuery.useQuery,
}) => {
  const dispatch = useDispatch();

  const [initialRating, setInitialRating] =
    useState<consumerApi.types.reviews.StrainRating>();
  const [isPodFeedbackModalOpen, setIsPodFeedbackModalOpen] =
    useState<boolean>(false);
  const [shouldShowPrompt, setShouldShowPrompt] = useState<boolean>(false);

  const connectedPod = useSelector((state: MainAppState) =>
    consumerApi.selectors.strains.getPod(state, { strainId })
  );

  const { strain } = useStrainById(strainId);

  const { handleShowSignInUpsell, signInUpsellModal } = useSignInUpsellModal(
    t.signInToRateThisPod(strain ? strain.name : t.THIS_POD),
    <Star $isFilled />,
    routes.device(),
    t.SIGN_IN_TO_RATE
  );

  useQuery<consumerApi.types.connectedPods.ConnectedPodJson>(
    consumerApi.paths.connectedPods.connectedPod(strainId),
    {
      onSuccess: (connectedPod) => {
        dispatch(
          consumerApi.actions.connectedPods.receivedConnectedPod(connectedPod)
        );
      },
    }
  );

  useEffect(() => {
    if (!connectedPod) return;

    setShouldShowPrompt(!connectedPod.latestReview);
  }, [connectedPod]);

  const handleModalClose = (): void => {
    setIsPodFeedbackModalOpen(false);
  };

  const handlePromptClick = (): void => {
    handleShowSignInUpsell();

    setShouldShowPrompt(false);
    setIsPodFeedbackModalOpen(true);
  };

  const handleStarClick = (
    rating: consumerApi.types.reviews.StrainRating
  ): void => {
    setInitialRating(rating);
  };

  if (!connectedPod) return null;

  return (
    <>
      {shouldShowPrompt && (
        <PodFeedbackToast
          data-testid="pod-feedback-toast"
          strainId={strainId}
          onClick={handlePromptClick}
          onStarClick={handleStarClick}
        />
      )}
      <PodFeedbackModal
        initialValues={{ rating: initialRating, ...DEFAULT_FORM_VALUES }}
        isOpen={isPodFeedbackModalOpen}
        onRequestClose={handleModalClose}
        strainId={strainId}
      />
      {signInUpsellModal}
    </>
  );
};
