import React from "react";
import { Route } from "react-router-dom";

import { ConnectDevicePage } from "../../device/containers/ConnectDevicePage";
import { useFindMyPax } from "../../find-my-pax/hooks";
import FirmwareModal from "../../firmware/containers/FirmwareModal";
import { MainApp } from "../../main/components/MainApp";
import { UsageAgreementModal } from "../../modal/components/UsageAgreementModal";
import { PrivacyPolicyModal } from "../../modal/containers/PrivacyPolicyModal";
import { usePAXDevice } from "../../my-pax/hooks";

export const BluetoothApp: React.FC = () => {
  useFindMyPax();
  usePAXDevice();

  return (
    <>
      <MainApp>
        <Route exact path="/" component={ConnectDevicePage} />
      </MainApp>
      <PrivacyPolicyModal />
      <UsageAgreementModal />
      <FirmwareModal />
    </>
  );
};
