import * as t from "../types";
import * as u from "../utils";

// Represents a firmware image version for devices using McuBoot or the legacy Apache Mynewt bootloader.
export default class McuManagerImageVersion {
  major?: number;
  minor?: number;
  revision?: number;
  buildNumber?: number;

  static MIN_SIZE = 1 + 1 + 2 + 4;

  static fromBytes(b: Uint8Array, offset = 0): McuManagerImageVersion {
    if (b.length - offset < this.MIN_SIZE) {
      throw new Error("The byte array is too short to be a McuMgrImageVersion");
    }

    const version = new McuManagerImageVersion();
    version.major = b[offset++];
    version.minor = b[offset++];
    version.revision = u.byteArrayToUnsignedInt(b, offset, t.Endian.LITTLE, 2);
    version.buildNumber = u.byteArrayToUnsignedInt(
      b,
      offset + 2,
      t.Endian.LITTLE,
      4
    );

    return version;
  }
}
