import { AppThunk } from "../../shared/types";

import { trackEventAction } from "../actions";

const EVENT_NAME = "pod_id";

type PodIdEventProperties = {
  autoId: string | null;
  manualId: string | null;
  strainId: string;
  wasScanned: boolean;
};

type TrackPodId = (
  strainId: string,
  wasAutoId: boolean,
  wasScanned: boolean
) => AppThunk;

export const trackPodId: TrackPodId =
  (strainId, wasAutoId, wasScanned) =>
  (dispatch, getState): void =>
    trackEventAction<PodIdEventProperties>(EVENT_NAME, {
      autoId: wasAutoId ? strainId : null,
      manualId: !wasAutoId ? strainId : null,
      strainId: strainId,
      wasScanned,
    })(dispatch, getState, null);
