import React, { useEffect } from "react";
import styled, { css } from "styled-components";

import { DEVICE_MODEL_MAP } from "../../api/consumer/constants";
import * as deviceTypes from "../../api/consumer/types/device";
import { SelectDeviceType, SetSerial } from "../../device/actions";
import { DeviceCard } from "../../my-pax/components/DeviceCard";
import { ButtonWrapper } from "../../shared/components/ButtonWrapper";
import { Column } from "../../shared/components/Flex";
import * as Text from "../../shared/components/Text";
import * as text from "../../shared/text";
import { desktopBreakpoint } from "../../shared/utils";

import * as t from "../types";
import { Title } from "./StyledComponents";

type RecentDevicesProps = t.DeviceConnectionModalContentProps & {
  hasFetchedUser: boolean;
  recentDevices: deviceTypes.DeviceJson[];
  selectDeviceType: SelectDeviceType;
  setSerial: SetSerial;
};

export const RecentDevices: React.FC<RecentDevicesProps> = ({
  hasFetchedUser,
  onStepCompleted,
  recentDevices,
  selectDeviceType,
  setSerial,
}) => {
  useEffect(() => {
    if (hasFetchedUser && recentDevices.length === 0) {
      onStepCompleted(t.DeviceConnectionStep.RECENT_DEVICES, true);
    }
  }, [hasFetchedUser, onStepCompleted, recentDevices]);

  const handleDeviceCardClick = (device: deviceTypes.DeviceJson): void => {
    const deviceType = DEVICE_MODEL_MAP[device.model];
    selectDeviceType(deviceType);
    setSerial(device.serialNumber, deviceType);
    onStepCompleted(t.DeviceConnectionStep.RECENT_DEVICES);
  };

  const handleConnectNewDeviceClick = (): void => {
    onStepCompleted(t.DeviceConnectionStep.RECENT_DEVICES, true);
  };

  if (!hasFetchedUser) return null;

  const deviceCards = recentDevices.map((device) => {
    return (
      <sc.DeviceCard
        connected={false}
        device={device}
        key={device.serialNumber}
        onClick={(): void => handleDeviceCardClick(device)}
        shouldShowConnectionStatus={false}
      />
    );
  });

  return (
    <sc.Container>
      <sc.Title>{text.RECENT_DEVICES}</sc.Title>
      <sc.DeviceCardsContainer>{deviceCards}</sc.DeviceCardsContainer>
      <sc.ConnectNewDeviceText onClick={handleConnectNewDeviceClick}>
        {text.CONNECT_A_NEW_DEVICE}
      </sc.ConnectNewDeviceText>
    </sc.Container>
  );
};

const sc = {
  ConnectNewDeviceText: styled(ButtonWrapper)`
    ${Text.MediumLL};
    cursor: pointer;
    font-size: 16px;
    margin-top: 40px;
    text-decoration: underline;

    &:hover {
      ${Text.PlaaxMedium}
    }
  `,

  Container: styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 40px 0;
    width: 100%;

    ${desktopBreakpoint(
      css`
        height: 372px;
        padding: 40px;
      `
    )}
  `,

  DeviceCard: styled(DeviceCard)`
    margin: 0 0 8px 0;

    &:last-child {
      margin: 0;
    }

    ${desktopBreakpoint(
      css`
        margin: 0 12px 0 0;
      `
    )}
  `,

  DeviceCardsContainer: styled(Column)`
    & div {
      cursor: pointer;
    }

    & > div:hover {
      background: var(--very-light-pink-4);
    }

    ${desktopBreakpoint(
      css`
        flex-direction: row;
      `
    )}
  `,

  Title: styled(Title)`
    font-size: 32px;
  `,
};
