import { css, FlattenSimpleInterpolation, keyframes } from "styled-components";

const starBlip = keyframes`
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.25);
  }

  100% {
    transform: scale(1);
  }
`;

export const starBlipAnimation = (
  animationDelay: number
): FlattenSimpleInterpolation => css`
  animation: ${starBlip} 300ms;
  animation-delay: ${animationDelay}ms;
`;
