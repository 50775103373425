import React from "react";
import styled from "styled-components";

const RADIUS = 20;
const CIRCUMFERENCE = 2 * Math.PI * RADIUS;
const VIEW_BOX_SIZE = 44;

type CircularBar = {
  percent: number;
};

export const CircularBar: React.FC<CircularBar> = ({ percent, ...props }) => {
  return (
    <sc.Container {...props}>
      <sc.Svg viewBox={`0 0 ${VIEW_BOX_SIZE} ${VIEW_BOX_SIZE}`}>
        <sc.Bar
          r={RADIUS}
          cx={VIEW_BOX_SIZE / 2}
          cy={VIEW_BOX_SIZE / 2}
          fill="transparent"
          strokeWidth={2}
        />
        <sc.Fill
          r={RADIUS}
          cx={VIEW_BOX_SIZE / 2}
          cy={VIEW_BOX_SIZE / 2}
          fill="transparent"
          strokeDasharray={CIRCUMFERENCE}
          strokeDashoffset={CIRCUMFERENCE + (CIRCUMFERENCE * percent) / 100}
          strokeLinecap="round"
          strokeWidth={4}
        />
      </sc.Svg>
      <sc.TextContainer>
        <sc.Text>{percent}</sc.Text>
      </sc.TextContainer>
    </sc.Container>
  );
};

const sc = {
  Bar: styled.circle`
    stroke: var(--khaki-20);
  `,

  Container: styled.div`
    flex-shrink: 0;
    height: 40px;
    position: relative;
    width: 40px;
  `,

  Fill: styled.circle`
    stroke: var(--black);
  `,

  Svg: styled.svg`
    transform: rotate(-90deg) scaleY(-1);
  `,

  Text: styled.div`
    font-size: 12px;
    left: -50%;
    position: relative;
  `,

  TextContainer: styled.div`
    left: 50%;
    position: absolute;
    top: 12px;
  `,
};
