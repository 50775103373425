import packageJson from "../../../package.json";

import { navigator } from "../../navigator";

import { getConnectedDevice } from "../bluetooth/connectedDevice";
import * as cookies from "../cookies";
import { shouldBlockAnalytics } from "../featureFlags";
import * as podFeedback from "./pods/strainReview";
import * as search from "./search/search";
import * as c from "./constants";
import * as sso from "./sso";
import * as t from "./types";
import * as u from "./utils";

export { c as constants, podFeedback, search, sso };

export type TrackEvent = <T extends Object>(
  event: string,
  properties?: T,
  commonFields?: t.SegmentCommonFields
) => void;

export const trackEvent: TrackEvent = (
  event,
  properties,
  commonFields = {}
) => {
  if (shouldBlockAnalytics) return;

  const device = getConnectedDevice();

  const _properties: t.AttachedEventProperties = {
    ...properties,
    analyticsSpecVersion: c.ANALYTICS_SPEC_VERSION,
    anonymousPeripheralId: cookies.getAnonymousUserPeripheralId(),
    appVersion: packageJson.version,
    contextTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    platform: u.getPlatform(),
    userPeripheralId: cookies.getRegisteredUserPeripheralId(),

    // Continue providing these keys for legacy reasons.
    // eslint-disable-next-line sort-keys
    anonymousId: undefined,
    userId: undefined,
  };

  if (_properties.serial) {
    _properties.peripheralEventId = u.encodeDeviceId(_properties.serial);
    delete _properties.serial;
  }

  if (device) {
    _properties.peripheralModel =
      _properties.peripheralModel || c.PERIPHERAL_MODEL_MAP[device.type];

    if (device.serial) {
      _properties.peripheralEventId =
        _properties.peripheralEventId || u.encodeDeviceId(device.serial);
    }
  }

  window.analytics.track(event, _properties, commonFields);
};

export const trackPage = (
  path: string,
  properties = {},
  platform = u.getPlatform()
): void => {
  if (shouldBlockAnalytics) return;

  const defaultProperties = {
    analyticsSpecVersion: c.ANALYTICS_SPEC_VERSION,
    anonymousPeripheralId: cookies.getAnonymousUserPeripheralId(),
    appVersion: packageJson.version,
    contextTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    path,
    platform,
    userAgent: navigator.userAgent,
    userPeripheralId: cookies.getRegisteredUserPeripheralId(),
  };

  window.analytics.page(path, { ...defaultProperties, ...properties });
};
