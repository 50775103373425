import { Action } from "../../../shared/types";

import * as connectedPods from "./connectedPods";
import * as devices from "./devices";
import * as experiments from "./experiments";
import * as partners from "./partners";
import * as reviews from "./reviews";
import * as strains from "./strains";
import * as users from "./users";

export const RESET_CONSUMER_API_STATE = "RESET_CONSUMER_API_STATE";

export const resetState = (): Action => ({
  type: RESET_CONSUMER_API_STATE,
});

export {
  connectedPods,
  devices,
  experiments,
  partners,
  reviews,
  strains,
  users,
};
