import Bugsnag from "@bugsnag/js";
import { AxiosError } from "axios";

import * as cookies from "../../cookies";
import { BannerType } from "../../shared/components/FlashBanner";
import * as text from "../../shared/text";

import consumerAxios from "./consumerAxios";
import * as e from "./errors";
import * as t from "./types";

const CONSUMER_API_URL = process.env.REACT_APP_CONSUMER_API_URL || "";

export type Fetch = <T>(
  pathOrUrl: string,
  options?: t.FetchOptions
) => Promise<T>;

export const fetch: Fetch = async (pathOrUrl, options = {}) => {
  const url = options.fullUrl ? pathOrUrl : CONSUMER_API_URL + pathOrUrl;
  const requestData = {
    cancelToken: options.cancelToken,
    data: options.data,
    method: options.method || "GET",
    retryCount: options.retryCount || 0,
    url,
  };

  const { stack } = new Error();

  try {
    const response = await consumerAxios.request(requestData);
    return response.data;
  } catch (error) {
    handleFetchError(error as AxiosError, stack || "");
  }
};

export const handleFetchError = (
  error: AxiosError,
  stack: string,
  clearCurrentTokens = cookies.clearCurrentTokens,
  setBannerCookie = cookies.setBannerCookie
): void => {
  /*
    Assign the provided stack to the axios error, taking the first line of the 
    axios error, which provides the response code.
    See https://github.com/axios/axios/issues/2387
  */
  error.stack = [
    (error.stack || "").split("\n")[0],
    ...stack.split("\n").slice(1),
  ].join("\n");

  if (error.response) {
    const errorCode = e.getErrorCode(error.response);
    const { status } = error.response || {};

    if (errorCode !== t.ErrorCode.UNKNOWN_ERROR) {
      // Known error; throw so clients can handle.
      throw new t.ApiError(errorCode);
    } else if (status === 401 || status === 403) {
      // Unknown issue... Our axios interceptors should have handled these errors.
      // Force a new anonymous user to be created in useUser by clearing the tokens.
      clearCurrentTokens();
      Bugsnag.notify(error);
      throw error;
    } else if (400 <= status && status < 500) {
      // The request was made and the server responded with a status code that falls out of the range of 2xx.
      // Report 400s other than 404s to Bugsnag and show an error banner.
      if (status !== 404) {
        Bugsnag.notify(error, (event) => {
          if (!error.response) return;
          // Do not log any sensitive user data
          if (
            error.response?.config?.data?.includes("email") ||
            error.response?.config?.data?.includes("password")
          ) {
            error.response.config.data = {
              userData: text.USER_DATA_HIDDEN_FOR_PRIVACY_REASONS,
            };
          }
          event.addMetadata("response", error.response);
        });
        setBannerCookie(
          text.OOPS_AN_ERROR_OCCURRED_TRY_AGAIN,
          BannerType.ERROR
        );
      }
    }
  } else if (error.message === "Network Error") {
    setBannerCookie(text.OOPS_AN_ERROR_OCCURRED_TRY_AGAIN, BannerType.WARNING);
    return;
  } else if (error.message === "Request aborted") {
    // Do nothing. See: https://github.com/PaxLabs/pax-web/pull/1094
    return;
  } else {
    // Something happened in setting up the request that triggered an error.
    // Likely our fault; notify Bugsnag.
    Bugsnag.notify(error);
  }

  // Re-throw the error so that the UI can respond however it wants to.
  throw error;
};
