import { Canvas, Color, useFrame, useLoader } from "@react-three/fiber";
import React, { useMemo, useRef } from "react";
import styled, { css } from "styled-components";
import * as THREE from "three";

import VaporImage from "../../../assets/images/webGl/vapor.png";

import { desktopBreakpoint } from "../../utils";

const BASE_PARTICLE_COUNT = 20;
const BASE_Z_MAX = 200;
const DEFAULT_BACKGROUND_COLOR = "#000000";
const DEFAULT_HEIGHT = window.screen.height;
const DEFAULT_VAPOR_COLOR = "#ffffff";
const DEFAULT_VAPOR_INTENSITY_MULTIPLIER = 0.5;
const DEFAULT_WIDTH = window.screen.width;

type VaporEffectProps = {
  backgroundColor?: THREE.ColorRepresentation;
  height?: number;
  vaporColor?: Color;
  vaporIntensityMultiplier?: number;
  width?: number;
};

/*
VaporEffect uses the following WebGL Smoke example as inspiration:
https://codepen.io/teolitto/pen/KwOVvL
*/
export const VaporEffect: React.FC<VaporEffectProps> = ({
  backgroundColor = DEFAULT_BACKGROUND_COLOR,
  height = DEFAULT_HEIGHT,
  vaporColor = DEFAULT_VAPOR_COLOR,
  vaporIntensityMultiplier = DEFAULT_VAPOR_INTENSITY_MULTIPLIER,
  width = DEFAULT_WIDTH,
}) => {
  const particleCount = BASE_PARTICLE_COUNT * vaporIntensityMultiplier;
  const zMax = BASE_Z_MAX * vaporIntensityMultiplier;

  const Vapor = () => {
    const tempObject = useMemo(() => new THREE.Object3D(), []);
    const instancedMeshRef = useRef<THREE.InstancedMesh>(null);
    const vaporTexture = useLoader(THREE.TextureLoader, VaporImage);

    const particles = useMemo(() => {
      const vaporParticles = [];
      for (let p = 0; p < particleCount; p++) {
        const positionX = Math.random() * width - width / 2;
        const positionY = Math.random() * height - height / 2;
        const positionZ = Math.random() * zMax - 100;
        const rotationZ = Math.random() * 360;

        vaporParticles.push({
          positionX,
          positionY,
          positionZ,
          rotationZ,
        });
      }
      return vaporParticles;
    }, []);

    /* eslint-disable @typescript-eslint/no-non-null-assertion */
    useFrame((state, delta) => {
      particles.forEach((particle, i) => {
        const { positionX, positionY, positionZ, rotationZ } = particle;
        tempObject.position.set(positionX, positionY, positionZ);
        tempObject.rotation.set(0, 0, rotationZ);
        tempObject.updateMatrix();
        instancedMeshRef.current!.setMatrixAt(i, tempObject.matrix);
        particle.rotationZ += delta * 0.2;
      });
      instancedMeshRef.current!.instanceMatrix.needsUpdate = true;
    });
    /* eslint-enable @typescript-eslint/no-non-null-assertion */

    return (
      <instancedMesh
        ref={instancedMeshRef}
        args={[undefined, undefined, particleCount]}
      >
        <planeBufferGeometry args={[width, height]} />
        <meshLambertMaterial
          color={vaporColor}
          depthWrite={false}
          map={vaporTexture}
          opacity={vaporIntensityMultiplier}
          transparent
        />
      </instancedMesh>
    );
  };

  return (
    <sc.VaporContainer height={height} width={width}>
      <Canvas camera={{ near: 1, position: [0, 0, zMax] }}>
        <color attach="background" args={[backgroundColor]} />
        <directionalLight
          intensity={vaporIntensityMultiplier}
          position={[-1, 0, 1]}
        />
        <Vapor />
      </Canvas>
    </sc.VaporContainer>
  );
};

type VaporContainerProps = {
  height: number;
  width: number;
};

const sc = {
  VaporContainer: styled.div<VaporContainerProps>`
    height: ${({ height }) => height}px;
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: ${({ width }) => width}px;

    ${desktopBreakpoint(css`
      top: var(--top-nav-height);
    `)}
  `,
};
