// TODO: remove use of any
/* eslint-disable @typescript-eslint/no-explicit-any */
import RcSlider from "rc-slider";
import React from "react";
import styled from "styled-components";

import "rc-slider/assets/index.css";
import "./Slider.css";

type SliderProps = {
  isDisabled?: boolean;
  onAfterChange: (value: number) => any;
  value: number;
};

export const Slider: React.FC<SliderProps> = ({
  value,
  isDisabled,
  ...props
}) => {
  return (
    <sc.Slider
      data-testid="slider"
      defaultValue={value}
      disabled={isDisabled}
      handleStyle={sliderStyles.handle}
      max={100}
      min={0}
      railStyle={sliderStyles.rail}
      trackStyle={sliderStyles.track}
      {...props}
    />
  );
};

const sliderStyles = {
  handle: {
    backgroundColor: "var(--white-two)",
    border: "none",
    boxShadow: "0 1px 2px 0 var(--bluey-grey-50), inset 0 0 5px 0 var(--white)",
    height: 20,
    top: 1,
    width: 20,
  },
  rail: {
    backgroundColor: "var(--very-light-pink-3)",
  },
  track: {
    backgroundColor: "var(--black)",
  },
};

const sc = {
  Slider: styled(RcSlider)`
    align-items: center;
    display: flex;

    & .rc-slider-step {
      height: 52px;
    }
  `,
};
