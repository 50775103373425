import React from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

import { TestResultJson } from "../../api/consumer/types/strain";
import * as r from "../../main/routes";
import { Row } from "../../shared/components/Flex";
import { ChevronRight } from "../../shared/components/Icons";
import * as Text from "../../shared/components/Text";
import { useDesktopMediaQuery } from "../../shared/hooks";
import { desktopBreakpoint, formatTimestampWithDots } from "../../shared/utils";

type PodsYourBatchProps = {
  autoIdReport?: TestResultJson;
  hasBatchesToDisplay: boolean;
};

const PodsYourBatch: React.FC<PodsYourBatchProps> = ({
  autoIdReport,
  hasBatchesToDisplay,
}) => {
  const isDesktop = useDesktopMediaQuery();

  if (!autoIdReport) return null;

  return (
    <>
      <sc.ListHeading>Your Batch</sc.ListHeading>
      <sc.Link
        to={r.podManualTestResults(
          autoIdReport?.batchId,
          autoIdReport?.strainId
        )}
      >
        <div data-testid={autoIdReport?.batchId}>
          <sc.BatchRow justifyContent="space-between">
            <Row>
              <sc.DateContainer>
                <sc.Date>
                  {formatTimestampWithDots(
                    false,
                    autoIdReport?.parsedBatchResult
                      ? autoIdReport?.parsedBatchResult.testDate
                      : ""
                  )}
                </sc.Date>
              </sc.DateContainer>
              <sc.Dot>&#183;</sc.Dot>
              <sc.Batch>
                {"BATCH #"}
                {autoIdReport?.batchId}
              </sc.Batch>
            </Row>
            <sc.ChevronRight />
          </sc.BatchRow>
          {isDesktop && hasBatchesToDisplay && <sc.Separator />}
        </div>
      </sc.Link>
    </>
  );
};

const sc = {
  Batch: styled.div`
    ${Text.PlaaxLight}
    color: var(--teal-green);
    font-size: 16px;
    letter-spacing: 0.3px;
    margin-left: 12px;
  `,

  BatchRow: styled(Row)`
    align-items: center;
    border-bottom: dotted 1.5px var(--very-light-pink);
    border-top: dotted 1.5px var(--very-light-pink);
    margin-bottom: 34px;
    padding: 12px 0;

    ${desktopBreakpoint(css`
      border-bottom: none;
      margin-bottom: 0;
    `)}
  `,

  ChevronRight: styled(ChevronRight)`
    height: 24px;
    margin-right: 17px;
    width: 24px;

    & path {
      fill: var(--teal-green);
    }
  `,

  Date: styled.div`
    ${Text.PlaaxLight}
    color: var(--teal-green);
    font-size: 16px;
    letter-spacing: 0.3px;
  `,

  DateContainer: styled.div`
    margin-right: 10px;
    width: 60px;
  `,

  Dot: styled.span`
    color: var(--teal-green);
    font-size: 16px;
  `,

  Link: styled(Link)`
    text-decoration: none;
  `,

  ListHeading: styled.div`
    font-size: 20px;
    margin-bottom: 12px;
    text-align: left;
    text-transform: uppercase;

    ${desktopBreakpoint(css`
      margin-bottom: 16px;
    `)}
  `,

  Separator: styled.div`
    border-top: dotted 1.5px var(--very-light-pink);
    margin-bottom: 40px;
  `,
};

export default PodsYourBatch;
