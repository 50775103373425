import React, { FormEvent, useState } from "react";
import styled from "styled-components";

import Button from "../../shared/components/Button";
import { Row } from "../../shared/components/Flex";

import { CreateMailingListSubscription } from "../actions";
import { MailingList } from "../types";
import { EmailInput } from "./EmailInput";

export type MailingListFormProps = {
  createMailingListSubscription: CreateMailingListSubscription;
  isCreatingSubscription: boolean;
  mailingList: MailingList;
};

export const MailingListForm: React.FC<MailingListFormProps> = ({
  createMailingListSubscription,
  isCreatingSubscription,
  mailingList,
}: MailingListFormProps) => {
  const handleSubmit = (e: FormEvent): void => {
    e.preventDefault();
    createMailingListSubscription(mailingList, email);
  };

  const [email, setEmail] = useState<string>("");

  return (
    <form data-testid="mailing-list-form" onSubmit={handleSubmit}>
      <sc.Row justifyContent="center">
        <sc.EmailInput
          onChange={(e): void => setEmail(e.target.value)}
          data-testid="mailing-list-form-email-input"
          placeholder="example420@gmail.com"
          required
          type="email"
          value={email}
        />
        <sc.Button
          data-testid="mailing-list-form-submit-button"
          disabled={isCreatingSubscription}
          type="submit"
        >
          Sign Up
        </sc.Button>
      </sc.Row>
    </form>
  );
};

const sc = {
  Button: styled(Button)`
    margin: 8px;
  `,

  EmailInput: styled(EmailInput)`
    margin: 8px;
    max-width: 316px;
  `,

  Row: styled(Row)`
    flex-wrap: wrap;
  `,
};
