import * as React from "react";
import styled from "styled-components";

import { StrainRating } from "../../../api/consumer/types/reviews";
import * as text from "../../../shared/text";

import { RATING_WORD_MAP } from "../../constants";
import * as SC from "../../styledComponents";

import { StarRating } from "../StarRating";

type StarRatingInputProps = {
  onChange?: (rating: StrainRating) => unknown;
  value?: StrainRating | null;
};

export const StarRatingInput: React.FC<StarRatingInputProps> = ({
  onChange,
  value,
  ...props
}) => {
  return (
    <sc.Container data-testid="rating" {...props}>
      {value ? (
        <SC.Subtitle marginTop={8}>{RATING_WORD_MAP[value]}</SC.Subtitle>
      ) : (
        <SC.Title>{text.HOW_DID_YOU_LIKE_THIS_POD}</SC.Title>
      )}
      <sc.StarRating
        onChange={onChange}
        outlinedStars
        showZeroStars
        value={value}
      />
    </sc.Container>
  );
};

const sc = {
  Container: styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: 32px;
  `,

  StarRating: styled(StarRating)`
    margin-top: 16px;

    & svg {
      height: 20px;
      margin-right: 20px;
      width: 20px;
    }
  `,
};
