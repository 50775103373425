import styled from "styled-components";

type ProgressProps = {
  complete?: boolean;
};

const Progress = styled.progress<ProgressProps>`
  -webkit-appearance: none;
  appearance: none;
  border: solid 1px;
  border-radius: 32px;
  height: 36px;
  position: relative;
  width: 250px;

  &[value]::-webkit-progress-bar {
    background-color: var(--white);
    border-radius: 19px;
    overflow: hidden;
  }

  &[value]::-webkit-progress-value {
    background-image: linear-gradient(
      115deg,
      var(--black) 50%,
      var(--maize) 70%,
      #ffffff 110%
    );
    background-position: ${({ complete }) => (complete ? "100%" : "0")};
    background-size: 370%;
    border-bottom-left-radius: 19px;
    border-top-left-radius: 19px;
    transition: background-position 0.5s ease-out;
  }
`;

export default Progress;
