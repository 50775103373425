import { useFormik } from "formik";
import React, { useState } from "react";
import styled, { css, SimpleInterpolation } from "styled-components";

import * as PaxBle from "../../../pax-ble";

import { UpdateDevice } from "../../api/consumer/actions/devices";
import * as deviceActions from "../../device/actions";
import { DEVICE_NAME_MAX_LENGTH } from "../../device/constants";
import { Modal, ModalProps } from "../../modal/components/Modal";
import Button from "../../shared/components/Button";
import * as flashBanner from "../../shared/components/FlashBanner";
import { Column, Row } from "../../shared/components/Flex";
import * as Icons from "../../shared/components/Icons";
import { Slider } from "../../shared/components/Slider";
import * as Text from "../../shared/components/Text";
import * as text from "../../shared/text";
import { desktopBreakpoint, removeEmojis } from "../../shared/utils";

import Pax3ColorMode from "./Pax3ColorMode";

export type Pax3DeviceSettingsModalProps = ModalProps;

export type ConnectedPax3DeviceSettingsModalProps =
  Pax3DeviceSettingsModalProps & {
    brightness: number;
    colorMode: PaxBle.Types.Pax3.ColorMode;
    deviceName: string;
    disconnectDevice: deviceActions.DisconnectDevice;
    firmwareRevision: string;
    haptics: number;
    isDeviceLocked: boolean;
    serialNumber?: string;
    setBrightness: deviceActions.SetBrightness;
    setDeviceName?: deviceActions.SetDeviceName;
    setColorMode: (colorMode: PaxBle.Types.Pax3.ColorMode) => void;
    setDefaultColorMode: () => void;
    setHaptics: deviceActions.SetHaptics;
    showFlashBanner: flashBanner.ShowFlashBanner;
    updateDevice: UpdateDevice;
  };

const Pax3DeviceSettingsModal: React.FC<ConnectedPax3DeviceSettingsModalProps> =
  ({
    brightness,
    colorMode,
    deviceName,
    disconnectDevice,
    firmwareRevision,
    haptics,
    isDeviceLocked,
    isOpen,
    onRequestClose,
    serialNumber,
    setBrightness,
    setColorMode,
    setDefaultColorMode,
    setDeviceName,
    setHaptics,
    showFlashBanner,
    updateDevice,
  }) => {
    const handleBrightnessChange = (value: number): void => {
      const percentage = value / 100;
      setBrightness(percentage);
    };

    const handleHapticsChange = (value: number): void => {
      const percentage = value / 100;
      setHaptics(percentage);
    };

    const handleDisconnectClick = (): void => {
      disconnectDevice();
    };

    const modalProps = {
      isOpen,
      onRequestClose,
    };

    return (
      <Modal {...modalProps}>
        <sc.Container data-testid="pax-3-settings-modal">
          <sc.Header>
            <sc.SettingsIconContainer>
              <Icons.Settings />
            </sc.SettingsIconContainer>
            <DeviceNameRow
              deviceName={deviceName}
              isDeviceLocked={isDeviceLocked}
              serialNumber={serialNumber}
              setDeviceName={setDeviceName}
              showFlashBanner={showFlashBanner}
              updateDevice={updateDevice}
            />
          </sc.Header>
          <sc.Group>
            <sc.Label>Brightness</sc.Label>
            <Slider
              isDisabled={isDeviceLocked}
              value={brightness * 100}
              onAfterChange={handleBrightnessChange}
            />
          </sc.Group>
          <sc.Group>
            <sc.Label>Haptics</sc.Label>
            <Slider
              isDisabled={isDeviceLocked}
              value={haptics * 100}
              onAfterChange={handleHapticsChange}
            />
          </sc.Group>
          {!isDeviceLocked && (
            <sc.Group>
              <sc.Label>Color Mode</sc.Label>
              <sc.ColorModesRow>
                <Pax3ColorMode
                  color="var(--white)"
                  borderColor="very-light-pink"
                  isSelected={PaxBle.Utils.arePax3ColorModesEqual(
                    colorMode,
                    PaxBle.Constants.Pax3.COLOR_MODE_DEFAULT
                  )}
                  title="Default"
                  onClick={(): void => setDefaultColorMode()}
                />
                <Pax3ColorMode
                  color="var(--pale-sky-blue)"
                  isSelected={PaxBle.Utils.arePax3ColorModesEqual(
                    colorMode,
                    PaxBle.Constants.Pax3.COLOR_MODE_OCEAN
                  )}
                  title="Ocean"
                  onClick={(): void =>
                    setColorMode(PaxBle.Constants.Pax3.COLOR_MODE_OCEAN)
                  }
                />
                <Pax3ColorMode
                  color="var(--maize)"
                  isSelected={PaxBle.Utils.arePax3ColorModesEqual(
                    colorMode,
                    PaxBle.Constants.Pax3.COLOR_MODE_SUNSET
                  )}
                  title="Sunset"
                  onClick={(): void =>
                    setColorMode(PaxBle.Constants.Pax3.COLOR_MODE_SUNSET)
                  }
                />
                <Pax3ColorMode
                  color="var(--salmon)"
                  isSelected={PaxBle.Utils.arePax3ColorModesEqual(
                    colorMode,
                    PaxBle.Constants.Pax3.COLOR_MODE_MARS
                  )}
                  title="Mars"
                  onClick={(): void =>
                    setColorMode(PaxBle.Constants.Pax3.COLOR_MODE_MARS)
                  }
                />
              </sc.ColorModesRow>
            </sc.Group>
          )}
          <sc.ButtonsRow>
            <sc.Button
              data-testid="switch-device"
              onClick={handleDisconnectClick}
            >
              Switch Devices
            </sc.Button>
            <sc.Button
              data-testid="disconnect-device"
              onClick={handleDisconnectClick}
            >
              Disconnect
            </sc.Button>
          </sc.ButtonsRow>
          {firmwareRevision && (
            <sc.Firmware>Firmware v{firmwareRevision}</sc.Firmware>
          )}
        </sc.Container>
      </Modal>
    );
  };

export default Pax3DeviceSettingsModal;

type DeviceNameRowProps = {
  deviceName: string;
  isDeviceLocked: boolean;
  serialNumber?: string;
  setDeviceName?: (deviceName: string) => void;
  showFlashBanner: flashBanner.ShowFlashBanner;
  updateDevice: UpdateDevice;
};

const DeviceNameRow: React.FC<DeviceNameRowProps> = ({
  deviceName = "",
  isDeviceLocked,
  serialNumber,
  setDeviceName,
  showFlashBanner,
  updateDevice,
}) => {
  const [editMode, setEditMode] = useState(false);

  const handleDeviceNameClick = (): void => setEditMode(true);

  const formik = useFormik({
    initialValues: { deviceName },
    onSubmit: (values) => {
      const updatedDeviceName = removeEmojis(values.deviceName);

      if (setDeviceName) setDeviceName(updatedDeviceName);
      setEditMode(false);
      if (!serialNumber) return;

      try {
        updateDevice(serialNumber, { deviceName: updatedDeviceName });
      } catch (err) {
        showFlashBanner(
          text.OOPS_AN_ERROR_OCCURRED_TRY_AGAIN,
          flashBanner.BannerType.ERROR
        );
      }
    },
  });

  if (editMode) {
    return (
      <sc.DeviceNameRow>
        <form onSubmit={formik.handleSubmit}>
          <input
            data-testid="device-name-input"
            id="deviceName"
            maxLength={DEVICE_NAME_MAX_LENGTH}
            name="deviceName"
            onBlur={(): void => formik.handleSubmit()}
            onChange={formik.handleChange}
            required
            type="text"
            value={removeEmojis(formik.values.deviceName)}
          />
        </form>
      </sc.DeviceNameRow>
    );
  }

  return (
    <sc.DeviceNameRow>
      <sc.DeviceName
        data-testid="device-name"
        onClick={isDeviceLocked ? undefined : handleDeviceNameClick}
      >
        {deviceName}
      </sc.DeviceName>
      {!isDeviceLocked && <sc.Pencil onClick={handleDeviceNameClick} />}
    </sc.DeviceNameRow>
  );
};

type DeviceNameProps = {
  onClick?: (deviceName: string) => unknown;
};

const sc = {
  Button: styled(Button)`
    font-size: 14px;
    min-width: 157px;
  `,

  ButtonsRow: styled(Column)`
    align-items: center;
    justify-content: center;
    margin: 54px 0 20px;

    & > button {
      margin-bottom: 15px;
    }

    & > button:last-child {
      margin-bottom: 0;
    }

    ${desktopBreakpoint(
      css`
          flex-direction: row;
          & > button {
            flex: 1;
            margin-bottom: 0;
            margin-right: 20px;
          }
      
          & > button:last-child {
            margin-right: 0;
        `
    )}
  `,

  ColorModesRow: styled(Row)`
    justify-content: space-between;
    margin-top: 4px;
  `,

  Container: styled(Column)`
    min-height: 100%;
    padding: 40px;

    ${desktopBreakpoint(css`
      width: var(--default-modal-width);
    `)}
  `,

  DeviceName: styled.div<DeviceNameProps>`
    border-bottom: 1px solid transparent;
    cursor: ${(props): SimpleInterpolation =>
      props.onClick ? "pointer" : "auto"};
    font-size: 24px;
    text-transform: uppercase;

    &:hover {
      border-bottom: ${(props): SimpleInterpolation =>
        props.onClick ? "1px solid var(--black)" : ""};
    }
  `,

  DeviceNameRow: styled(Row)`
    align-items: center;
    margin-bottom: 44px;
  `,

  Firmware: styled.div`
    ${Text.PlaaxLight}
    color: var(--brown-grey);
    font-size: 14px;
    margin: 0 auto;
  `,

  Group: styled(Column)`
    margin-bottom: 28px;
  `,

  Header: styled(Column)`
    align-items: center;
  `,

  Label: styled.div`
    ${Text.PlaaxLight}
    font-size: 18px;
    margin-bottom: 8px;
    text-transform: uppercase;

    &:last-child {
      margin-top: 28px;
    }
  `,

  Pencil: styled(Icons.Pencil)`
    cursor: pointer;
    height: 17px;
    margin-left: 12px;
    width: 16px;
  `,

  SettingsIconContainer: styled.div`
    align-items: center;
    border: 1px solid var(--black);
    border-radius: 100%;
    display: flex;
    height: 48px;
    justify-content: center;
    margin-bottom: 16px;
    width: 48px;
  `,
};
