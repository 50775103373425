// TODO: remove use of any
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import styled, { css, SimpleInterpolation } from "styled-components";

import * as Icons from "../../shared/components/Icons";
import { desktopBreakpoint } from "../../shared/utils";

export type LockedIndicatorProps = {
  isDeviceLocked: boolean;
  toggleDeviceLock: (toggle: boolean) => any;
};

export const LockedIndicator: React.FC<LockedIndicatorProps> = ({
  isDeviceLocked,
  toggleDeviceLock,
}) => {
  const Icon = isDeviceLocked ? Icons.Locked : Icons.Unlocked;

  const handleClick = (): void => {
    toggleDeviceLock(!isDeviceLocked);
  };

  return (
    <sc.Container
      data-testid="locked-indicator"
      isDeviceLocked={isDeviceLocked}
      onClick={handleClick}
    >
      <Icon />
    </sc.Container>
  );
};

type ContainerProps = {
  isDeviceLocked?: boolean;
};

const sc = {
  Container: styled.div<ContainerProps>`
    align-items: center;
    border-radius: 100%;
    cursor: pointer;
    display: flex;
    height: 48px;
    justify-content: center;
    margin-right: 0;
    width: 48px;

    & > svg > g > g > rect {
      stroke: ${(props): SimpleInterpolation =>
        props.isDeviceLocked ? "var(--pale-red)" : "var(--black)"};
    }
    & > svg > g > g > path {
      fill: ${(props): SimpleInterpolation =>
        props.isDeviceLocked ? "var(--pale-red)" : "var(--black)"};
    }
    @media (hover: hover) {
      &:hover {
        background-color: var(--black);

        & > svg > g > g > rect {
          stroke: var(--white);
        }
        & > svg > g > g > path {
          fill: var(--white);
        }
      }
    }

    ${desktopBreakpoint(
      css<ContainerProps>`
        background-color: ${(props): SimpleInterpolation =>
          props.isDeviceLocked ? "var(--pale-red)" : "transparent"};
        margin-right: 8px;

        & > svg > g > g > rect {
          stroke: ${(props): SimpleInterpolation =>
            props.isDeviceLocked ? "var(--white)" : "var(--black)"};
        }
        & > svg > g > g > path {
          fill: ${(props): SimpleInterpolation =>
            props.isDeviceLocked ? "var(--white)" : "var(--black)"};
        }
      `
    )}
  `,
};
