import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled, { css, SimpleInterpolation } from "styled-components";

import * as PaxBleTypes from "../../../pax-ble/types";

import { getDeviceJson } from "../../api/consumer/models/Device";
import * as userSelectors from "../../api/consumer/selectors/user";
import * as t from "../../api/consumer/types/device";
import { getConnectedDevice } from "../../bluetooth/connectedDevice";
import { DeviceConnectionFlows } from "../../connection-modal/constants";
import AddDevice from "../../connection-modal/containers/AddDevice";
import { useDeviceConnectionModal } from "../../connection-modal/hooks";
import { DisconnectDevice } from "../../device/actions";
import { useConnectedDevice } from "../../device/hooks";
import { useEraProSerialModal } from "../../era-pro/hooks";
import * as ff from "../../featureFlags";
import { FindMyPaxCard } from "../../find-my-pax/components/FindMyPaxCard";
import * as routes from "../../main/routes";
import { ModalAction } from "../../modal/actions";
import {
  BannerType,
  ShowFlashBanner,
} from "../../shared/components/FlashBanner";
import { Column } from "../../shared/components/Flex";
import * as text from "../../shared/text";
import { desktopBreakpoint } from "../../shared/utils";

import { MyDevicePage } from "../components/MyDevicePage";
import MyPaxHeader from "../containers/MyPaxHeader/MyPaxHeader";
import * as SC from "../styledComponents";
import { AddDeviceCard, DeviceCard } from "./DeviceCard";
import { SignInHeader } from "./SignInHeader";

export type StateProps = {
  devices: t.DeviceJson[];
  isFirmwareModalOpen: boolean;
  isNewerFirmwareAvailable: boolean;
  supportsBluetooth: boolean;
};

export type DispatchProps = {
  disconnectDevice: DisconnectDevice;
  openFirmwareModal: ModalAction;
  showFlashBanner: ShowFlashBanner;
};

export type MyDevicesProps = StateProps &
  DispatchProps & {
    isFindMyPaxEnabled?: boolean;
  };

const DeviceCardsPlaceHolder: React.FC = () => {
  const customTime = new Date();
  customTime.setHours(16, 20);

  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);

  const deviceType = getConnectedDevice()?.type;

  return (
    <sc.CardRow>
      <sc.DeviceCard
        connected={deviceType === PaxBleTypes.DeviceType.ERA_PRO}
        device={
          getDeviceJson({
            deviceName: "PAX Era Pro",
            model: t.DeviceModel.ERA_PRO,
            peripheralId: "123456",
            serialNumber: "12345678",
            shellColor: t.DeviceShellColor.MATTE_SILVER,
          }) as t.DeviceJson
        }
      />
      <sc.DeviceCard
        connected={deviceType === PaxBleTypes.DeviceType.ERA}
        device={
          getDeviceJson({
            deviceName: "PAX Era",
            latestClientTimestamp: customTime.toISOString(),
            model: t.DeviceModel.ERA,
            peripheralId: "123456",
            serialNumber: "12345678",
            shellColor: t.DeviceShellColor.MATTE_BLACK,
          }) as t.DeviceJson
        }
      />
      <sc.DeviceCard
        connected={deviceType === PaxBleTypes.DeviceType.PAX_3}
        device={
          getDeviceJson({
            deviceName: "PAX 3",
            latestClientTimestamp: yesterday.toISOString(),
            model: t.DeviceModel.PAX_3,
            peripheralId: "123456",
            serialNumber: "12345678",
            shellColor: t.DeviceShellColor.BURGUNDY,
          }) as t.DeviceJson
        }
      />
    </sc.CardRow>
  );
};

export const MyDevices: React.FC<MyDevicesProps> = ({
  devices,
  disconnectDevice,
  isFindMyPaxEnabled = ff.isFindMyPaxEnabled,
  isFirmwareModalOpen,
  isNewerFirmwareAvailable,
  openFirmwareModal,
  showFlashBanner,
  supportsBluetooth,
}) => {
  const connectedDevice = useConnectedDevice();
  const [serialPromptModal] = useEraProSerialModal();
  const hasFetchedUser = useSelector(userSelectors.getHasFetchedUser);
  const isSignedIn = useSelector(userSelectors.getIsUserSignedIn);
  const [activeDevice, setActiveDevice] = useState<t.DeviceJson | null>(null);
  const [isAddingDevice, setIsAddingDevice] = useState<boolean>(false);

  const handleCloseAddDeviceModal = (): void => {
    setIsConnectionModalOpen(false);
  };

  const {
    deviceConnectionModal,
    isConnectionModalOpen,
    setIsConnectionModalOpen,
  } = useDeviceConnectionModal(
    DeviceConnectionFlows.addDevice,
    false,
    AddDevice,
    false,
    {
      allowOuterElementInteraction: false,
      hideCloser: false,
      onRequestClose: handleCloseAddDeviceModal,
    }
  );

  useEffect(() => {
    if (
      connectedDevice &&
      !isConnectionModalOpen &&
      isNewerFirmwareAvailable &&
      isAddingDevice
    ) {
      openFirmwareModal();
      setIsAddingDevice(false);
    }
  }, [
    connectedDevice,
    isAddingDevice,
    isConnectionModalOpen,
    isNewerFirmwareAvailable,
    openFirmwareModal,
  ]);

  useEffect(() => {
    if (!activeDevice) return;

    const updatedActiveDevice =
      devices.find((d) => d.serialNumber === activeDevice.serialNumber) || null;

    setActiveDevice(updatedActiveDevice);

    // `activeDevice` is intentionally left out of the dependencies array.
    // This is to avoid re-fetches when the activeDevice is set in the useEffect.
    //eslint-disable-next-line
  }, [devices]);

  useEffect(() => {
    if (!supportsBluetooth) {
      showFlashBanner(
        text.DEVICE_CONNECTION_ONLY_POSSIBLE_IN,
        BannerType.SUCCESS,
        true
      );
    }
  }, [showFlashBanner, supportsBluetooth]);

  const handleAddDeviceCardClick = (): void => {
    if (!supportsBluetooth) return;

    disconnectDevice();
    setIsAddingDevice(true);
    setIsConnectionModalOpen(true);
  };

  if (activeDevice) {
    return (
      <MyDevicePage
        device={activeDevice}
        onGoBackClick={(): void => setActiveDevice(null)}
      />
    );
  }

  return (
    <>
      <sc.Page data-testid="my-devices-page">
        <MyPaxHeader>{text.MY_DEVICES}</MyPaxHeader>
        {hasFetchedUser && !isSignedIn && (
          <SignInHeader returnTo={routes.accountDevices()} />
        )}
        <sc.Container disabled={hasFetchedUser && !isSignedIn}>
          <sc.SectionTitle>{text.MANAGE_DEVICES}</sc.SectionTitle>
          {hasFetchedUser && !isSignedIn ? (
            <DeviceCardsPlaceHolder />
          ) : (
            <sc.CardRow>
              {isFindMyPaxEnabled && (
                <sc.FindMyPaxCard hasDevices={devices.length > 0} />
              )}
              {devices.map((device) => {
                return (
                  <sc.DeviceCard
                    key={device.serialNumber}
                    connected={connectedDevice?.serial === device.serialNumber}
                    device={device}
                    onClick={(): void => setActiveDevice(device)}
                  />
                );
              })}
              <sc.AddDeviceCard
                isDisabled={!supportsBluetooth}
                onClick={handleAddDeviceCardClick}
              />
            </sc.CardRow>
          )}
        </sc.Container>
      </sc.Page>
      {deviceConnectionModal}
      {!isFirmwareModalOpen && serialPromptModal}
    </>
  );
};

type AddDeviceCardProps = {
  isDisabled?: boolean;
};

type ContainerProps = {
  disabled: boolean;
};

const sc = {
  AddDeviceCard: styled(AddDeviceCard)<AddDeviceCardProps>`
    cursor: ${({ isDisabled }): SimpleInterpolation =>
      isDisabled ? "auto" : "pointer"};
    margin: 8px 0 0 0;

    ${desktopBreakpoint(
      css`
        flex-basis: calc(25% - 8px);
        margin: 8px 8px 0 0;
      `
    )}
  `,

  CardRow: styled(Column)`
    margin: auto;

    ${desktopBreakpoint(
      css`
        flex-direction: row;
        flex-wrap: wrap;
        width: 816px;
      `
    )}
  `,

  Container: styled.div<ContainerProps>`
    margin: 0 auto;
    max-width: 466px;
    opacity: ${({ disabled }): SimpleInterpolation =>
      disabled ? "0.36" : "1"};
    pointer-events: ${({ disabled }): SimpleInterpolation =>
      disabled ? "none" : "auto"};
    width: 100%;

    ${desktopBreakpoint(
      css`
        margin: 0;
      `
    )}
  `,

  DeviceCard: styled(DeviceCard)`
    cursor: pointer;
    margin: 8px 0 0;

    ${desktopBreakpoint(
      css`
        flex-basis: calc(25% - 8px);
        margin: 8px 8px 0 0;
      `
    )}
  `,

  FindMyPaxCard: styled(FindMyPaxCard)`
    margin: 8px 0 0;
    width: 100%;

    ${desktopBreakpoint(
      css`
        flex-basis: calc(25% - 8px);
        margin: 8px 8px 0 0;
      `
    )};
  `,

  Page: styled(SC.Page)`
    max-width: ${(props) => props.theme.myPax.pageContentWidth};
  `,

  SectionTitle: styled.div`
    font-size: 20px;
    margin: 40px auto 4px;
    text-transform: uppercase;

    ${desktopBreakpoint(
      css`
        margin: 40px 0 4px;
      `
    )}
  `,
};
