import React from "react";
import styled, { css } from "styled-components";

import { Modal, ModalProps } from "../../modal/components/Modal";
import { Row } from "../../shared/components/Flex";
import { ChevronLeft } from "../../shared/components/Icons";
import { useDesktopMediaQuery } from "../../shared/hooks";
import { NOTIFICATIONS } from "../../shared/text";
import { desktopBreakpoint } from "../../shared/utils";

type NotificationsModalProps = ModalProps & {
  notifications: React.ReactNode;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const NotificationsModal: React.FC<NotificationsModalProps> = ({
  isOpen,
  onRequestClose,
  notifications,
  setIsOpen,
}) => {
  const isDesktop = useDesktopMediaQuery();

  const handleExitClick = (): void => {
    setIsOpen(false);
  };

  const modalProps = {
    "data-testid": "notifications-modal",
    hideCloser: !isDesktop,
    isOpen,
    onRequestClose,
  };

  return (
    <sc.Modal {...modalProps}>
      <sc.TitleRow alignItems="center">
        {!isDesktop && <ChevronLeft onClick={handleExitClick} />}
        <sc.Title>{NOTIFICATIONS}</sc.Title>
      </sc.TitleRow>
      <sc.Content>{notifications}</sc.Content>
    </sc.Modal>
  );
};

const sc = {
  Content: styled.div`
    margin: 72px auto 0;
    padding: 12px 16px;

    ${desktopBreakpoint(css`
      margin: 0 auto;
    `)}
  `,

  Modal: styled(Modal)`
    ${desktopBreakpoint(css`
      width: var(--default-modal-width);
    `)}
  `,

  Title: styled.div`
    font-size: 18px;
    margin: 0 auto;
    position: relative;
    right: 26px;
    text-transform: uppercase;
  `,

  TitleRow: styled(Row)`
    background-color: var(--white);
    border-radius: 8px 8px 0 0;
    box-shadow: 0 1px 6px var(--bluey-grey-30);
    height: 56px;
    padding-left: 16px;
    position: fixed;
    width: 100%;
    z-index: var(--z-index-modal-header);

    ${desktopBreakpoint(css`
      position: relative;
    `)}
  `,
};
