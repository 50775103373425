import { Action } from "../shared/types";
import * as a from "./actions";
import { MailingListState } from "./types";

export const getInitialState = (): MailingListState => ({
  isCreatingSubscription: false,
});

export const reducer = (
  state: MailingListState = getInitialState(),
  action: Action
): MailingListState => {
  switch (action.type) {
    case a.CREATE_MAILING_LIST_SUBSCRIPTION_START:
      return {
        isCreatingSubscription: true,
      };

    case a.CREATE_MAILING_LIST_SUBSCRIPTION_END:
      return {
        isCreatingSubscription: false,
      };

    default:
      return state;
  }
};
