import React, { useEffect, useState } from "react";

import * as consumerApi from "../../api/consumer";
import { ErrorCode, UseFetch } from "../../api/consumer/types";
import * as r from "../../main/routes";
import { PasswordInput } from "../../shared/components/PasswordInput";
import * as sharedHooks from "../../shared/hooks";
import { SearchParam } from "../../shared/types";

import { ResetPassword } from "../actions";
import { useSsoPageReset } from "../hooks";
import { pendingEmailVerificationRequests } from "../paths";
import * as text from "../text";
import * as t from "../types";
import * as utils from "../utils";
import { SsoChrome } from "./SsoChrome";
import { SsoErrorMessage } from "./SsoErrorMessage";
import * as SsoForm from "./SsoForm";
import { SsoResetPasswordConfirmationPage } from "./SsoResetPasswordConfirmationPage";

export type SsoResetPasswordPageProps = t.SsoProps & {
  anonymousUuid: string;
  failureCode?: ErrorCode;
  resetPassword: ResetPassword;
  success?: boolean;
  useFetch: UseFetch<t.CreatedEmailVerificationRequest>;
  useSearchParam: sharedHooks.UseSearchParam;
};

export const SsoResetPasswordPage: React.FC<SsoResetPasswordPageProps> = ({
  anonymousUuid,
  failureCode,
  resetPassword,
  ssoRedirect = utils.ssoRedirect,
  success,
  useFetch = consumerApi.hooks.useFetch,
  useSearchParam = sharedHooks.useSearchParam,
}) => {
  const token = useSearchParam(SearchParam.TOKEN) || "";
  const redirectTo = useSearchParam(SearchParam.REDIRECT) || r.home();
  const shouldCloseWindow = useSearchParam(SearchParam.CLOSE) || "false";
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [tokenError, setTokenError] = useState<string>("");

  // Get email from pending email verification request to prefill email field.
  const [emailVerificationRequestsFetch] = useFetch(
    pendingEmailVerificationRequests(token)
  );

  useEffect(() => {
    if (
      emailVerificationRequestsFetch.error ||
      !emailVerificationRequestsFetch.response
    ) {
      if (emailVerificationRequestsFetch.error) {
        setTokenError("Password reset token expired, please try again");
      }
      return;
    }
    setEmail(emailVerificationRequestsFetch.response.email);
  }, [emailVerificationRequestsFetch, setEmail, setTokenError, tokenError]);

  useSsoPageReset(t.SsoPage.resetPassword);

  if (tokenError) {
    ssoRedirect(
      r.ssoResetPasswordTokenExpired({
        close: shouldCloseWindow,
        returnTo: redirectTo,
      })
    );
    return null;
  }

  if (success) {
    return <SsoResetPasswordConfirmationPage redirectTo={redirectTo} />;
  }

  return (
    <SsoChrome>
      <SsoForm.Form
        data-testid="reset-password-form"
        onSubmit={(e: React.FormEvent<HTMLFormElement>): void => {
          e.preventDefault();
          resetPassword({
            anonymousUuid,
            email,
            password,
            token,
          });
        }}
      >
        <SsoForm.Field>
          <SsoForm.Title>{text.RESET_PASSWORD_TITLE}</SsoForm.Title>
          <SsoForm.P>{text.RESET_PASSWORD}</SsoForm.P>
        </SsoForm.Field>
        <SsoForm.Field>
          <SsoForm.Label htmlFor="email-input">
            {text.EMAIL_LABEL}
          </SsoForm.Label>
          <SsoForm.Input
            data-testid="email-input"
            disabled={true}
            id="email-input"
            type="email"
            value={email}
          />
        </SsoForm.Field>
        <SsoForm.Field>
          <SsoForm.Label htmlFor="password-input">
            {text.NEW_PASSWORD_LABEL}
          </SsoForm.Label>
          <PasswordInput
            data-testid="password-input"
            hasError={utils.isPasswordInError(failureCode)}
            id="password-input"
            Input={SsoForm.PasswordInput}
            onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
              setPassword(e.target.value)
            }
            required
          />
        </SsoForm.Field>
        {failureCode && (
          <SsoErrorMessage failureCode={failureCode} isNewPassword />
        )}
        <SsoForm.ButtonsContainer>
          <SsoForm.Button data-testid="submit-button" type="submit">
            {text.RESET_PASSWORD_BUTTON}
          </SsoForm.Button>
        </SsoForm.ButtonsContainer>
      </SsoForm.Form>
    </SsoChrome>
  );
};
