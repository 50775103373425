import { connect } from "react-redux";

import * as deviceSelectors from "../../device/selectors";
import * as firmwareSelectors from "../../firmware/selectors";
import * as t from "../../main/types";
import * as modalActions from "../../modal/actions";
import * as modalSelectors from "../../modal/selectors";

import * as eraProDevicePage from "../components/EraProDevicePage";

const mapStateToProps = (
  state: t.MainAppState
): eraProDevicePage.StateProps => {
  return {
    deviceName: deviceSelectors.getDeviceName(state),
    isDeviceConnected: deviceSelectors.getIsDeviceConnected(state),
    isDeviceExperienceModesCompatible:
      firmwareSelectors.getIsDeviceExperienceModesCompatible(state),
    isDeviceLocked: deviceSelectors.getIsDeviceLocked(state),
    isFirmwareModalOpen: modalSelectors.getIsFirmwareModalOpen(state),
    shouldShowFirmwareModal:
      firmwareSelectors.getShouldShowFirmwareModal(state),
  };
};

const mapDispatchToProps: eraProDevicePage.DispatchProps = {
  openFirmwareModal: modalActions.openFirmwareModal,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(eraProDevicePage.EraProDevicePage);
