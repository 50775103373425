import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { navigator } from "../../navigator";
import * as PaxBle from "../../pax-ble";

import { getConnectedDevice } from "../bluetooth/connectedDevice";
import { setSerial } from "../device/actions";
import * as deviceUtils from "../device/utils";
import { EraProSerialModal } from "./components/EraProSerialModal";
import { ERA_PRO_LOCKOUT_TIME } from "./constants";

export const useEraProSerialModal = (
  device = getConnectedDevice() as PaxBle.EraProDevice
): [React.ReactNode, boolean] => {
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  useEffect(() => {
    if (!device || device.serial) return;

    setIsModalOpen(true);
  }, [device]);

  if (!navigator.bluetooth) {
    return [null, false];
  }

  const handleModalClose = (serial?: string): void => {
    if (device && serial) {
      device.serial = serial;
      dispatch(setSerial(serial, PaxBle.Types.DeviceType.ERA_PRO));
    }
    setIsModalOpen(false);
  };

  const modal = (
    <EraProSerialModal isOpen={isModalOpen} onRequestClose={handleModalClose} />
  );

  return [modal, isModalOpen];
};

export const useDoseStatus = (
  session?: PaxBle.Types.EraPro.Session
): {
  isSessionInProgress: boolean;
  shouldShowTimeout: boolean;
} => {
  const [isSessionInProgress, setIsSessionInProgress] = useState<boolean>(true);
  const [shouldShowTimeout, setShouldShowTimeout] = useState<boolean>(false);

  useEffect(() => {
    switch (session?.action) {
      case PaxBle.Types.EraPro.EraProSessionAction.SESSION_START:
        deviceUtils.removeDeviceLockoutStarted();
        setIsSessionInProgress(true);
        setShouldShowTimeout(false);
        break;
      case PaxBle.Types.EraPro.EraProSessionAction.LOCK_OUT_STOP:
        deviceUtils.removeDeviceLockoutStarted();
        setIsSessionInProgress(false);
        setShouldShowTimeout(false);
        break;
      case PaxBle.Types.EraPro.EraProSessionAction.LOCKOUT_ACTIVE:
        deviceUtils.setDeviceLockoutStarted(Date.now(), ERA_PRO_LOCKOUT_TIME);
        setShouldShowTimeout(true);
        break;
      case PaxBle.Types.EraPro.EraProSessionAction.LOCK_OUT_START:
        deviceUtils.setDeviceLockoutStarted(Date.now(), ERA_PRO_LOCKOUT_TIME);
        setShouldShowTimeout(true);
        break;
      case PaxBle.Types.EraPro.EraProSessionAction.SESSION_ACTIVE:
        setIsSessionInProgress(true);
        break;
      case PaxBle.Types.EraPro.EraProSessionAction.INACTIVE:
        setIsSessionInProgress(false);
        break;
    }
  }, [session]);

  return { isSessionInProgress, shouldShowTimeout };
};
