import styled from "styled-components";

export type ArrowProps = {
  size: number;
};

export const Left = styled.div<ArrowProps>`
  border-bottom: ${(props) => props.size}px solid transparent;
  border-right: ${(props) => props.size}px solid var(--black);
  border-top: ${(props) => props.size}px solid transparent;
  height: 0;
  width: 0;
`;

export const Right = styled.div<ArrowProps>`
  border-bottom: ${(props) => props.size}px solid transparent;
  border-left: ${(props) => props.size}px solid var(--black);
  border-top: ${(props) => props.size}px solid transparent;
  height: 0;
  width: 0;
`;
