import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import styled, {
  css,
  CSSProperties,
  FlattenInterpolation,
  ThemedStyledProps,
} from "styled-components";

import * as PaxBle from "../../../../pax-ble";

import { trackDynamicModeSet } from "../../../analytics/device/device";
import { Column, Row } from "../../../shared/components/Flex";
import * as Text from "../../../shared/components/Text";
import { desktopBreakpoint } from "../../../shared/utils";

import { SetDynamicMode } from "../../actions";

export type FlowerDynamicModesProps = {
  activeDynamicMode?: PaxBle.Types.Pax3.DynamicMode;
  isDeviceLocked?: boolean;
  setDynamicMode: SetDynamicMode;
};

const FlowerDynamicModes: React.FC<FlowerDynamicModesProps> = ({
  activeDynamicMode,
  isDeviceLocked,
  setDynamicMode,
}: FlowerDynamicModesProps) => {
  const dispatch = useDispatch();
  const [hoverDynamicMode, setHoverDynamicMode] =
    useState<PaxBle.Types.Pax3.DynamicMode | null>(null);

  const [selectedDynamicMode, setSelectedDynamicMode] = useState<
    PaxBle.Types.Pax3.DynamicMode | undefined
  >(activeDynamicMode);

  useEffect(() => {
    setSelectedDynamicMode(activeDynamicMode);
  }, [activeDynamicMode]);

  const handleDynamicModeClick = (
    dynamicMode: PaxBle.Types.Pax3.DynamicMode
  ) => {
    setDynamicMode(dynamicMode);
    setSelectedDynamicMode(dynamicMode);
    dispatch(trackDynamicModeSet(dynamicMode.type));
  };

  return (
    <sc.DynamicMode isDeviceLocked={isDeviceLocked}>
      <sc.DynamicModeHeaderRow>
        <sc.DynamicModeHeader>Dynamic Modes™</sc.DynamicModeHeader>
      </sc.DynamicModeHeaderRow>

      <sc.DynamicModeRow>
        {PaxBle.Constants.Pax3.DYNAMIC_MODES.map((dynamicMode) => (
          <sc.DynamicModeButton
            key={dynamicMode.type}
            data-testid={
              dynamicMode.label.toLocaleLowerCase() + "-dynamic-mode"
            }
            isActive={selectedDynamicMode?.type === dynamicMode.type}
            onClick={() => handleDynamicModeClick(dynamicMode)}
            onMouseEnter={() => setHoverDynamicMode(dynamicMode)}
            onMouseLeave={() => setHoverDynamicMode(null)}
          >
            <dynamicMode.Icon />
            {dynamicMode.label}
          </sc.DynamicModeButton>
        ))}
      </sc.DynamicModeRow>

      <sc.P>
        {hoverDynamicMode && (
          <>
            <Text.Bold>{hoverDynamicMode.label} mode </Text.Bold>
            {hoverDynamicMode.description}
          </>
        )}

        {!hoverDynamicMode && selectedDynamicMode && (
          <>
            <Text.Bold>{selectedDynamicMode.label} mode </Text.Bold>
            {selectedDynamicMode.description}
          </>
        )}
      </sc.P>
    </sc.DynamicMode>
  );
};

export default FlowerDynamicModes;

const activeIconStyles = css`
  border: 2px solid;
  border-radius: 40px;
  height: 48px;
  width: 48px;
`;

const activeMobileIconStyles = css`
  border: 1px solid;
  border-radius: 40px;
  height: 24px;
  width: 24px;
`;

type DynamicModeButtonProps = {
  isActive?: boolean;
};

type DynamicModeProps = {
  isDeviceLocked?: boolean;
};

const lockedStateCss = css`
  opacity: 0.32;
  pointer-events: none;
`;

const sc = {
  DynamicMode: styled(Column)<DynamicModeProps>`
    align-items: center;
    justify-content: center;

    ${({ isDeviceLocked }) => (isDeviceLocked ? lockedStateCss : "")}
  `,

  DynamicModeButton: styled.div<DynamicModeButtonProps>`
    align-items: center;
    border: solid 1px;
    border-radius: 24px;
    display: flex;
    flex-direction: row;
    font-size: 10px;
    font-weight: 500;
    justify-content: normal;
    line-height: 1.43;
    margin: 0 8px 16px 0;
    padding: 4px;
    text-transform: uppercase;
    width: 108px;

    &:hover {
      cursor: pointer;
      font-weight: 500;

      ${desktopBreakpoint(css`
        font-weight: bold;
      `)}
    }

    &:hover svg {
      ${activeMobileIconStyles}

      ${desktopBreakpoint(activeIconStyles)}
    }

    & svg {
      height: 24px;
      margin-bottom: 0px;
      margin-right: 12px;
      width: 24px;

      ${desktopBreakpoint(css`
        height: initial;
        margin-bottom: 12px;
        margin-right: initial;
        width: initial;
      `)}

      ${({
        isActive,
      }):
        | FlattenInterpolation<ThemedStyledProps<CSSProperties, unknown>>
        | undefined => {
        if (!isActive) return;

        return css`
          ${activeMobileIconStyles}

          ${desktopBreakpoint(activeIconStyles)}
        `;
      }}
    }

    ${desktopBreakpoint(css<DynamicModeButtonProps>`
      border: initial;
      border-radius: initial;
      flex-direction: column;
      font-size: 14px;
      font-weight: ${({ isActive }) => (isActive ? "bold" : "normal")};
      justify-content: center;
      margin: initial;
      padding: initial;
      width: 70px;
    `)}

    ${({
      isActive,
    }):
      | FlattenInterpolation<ThemedStyledProps<CSSProperties, unknown>>
      | undefined => {
      if (isActive) {
        return css`
          background: var(--black);
          color: var(--white);

          ${desktopBreakpoint(css`
            background: none;
            color: var(--black);
          `)}
        `;
      }
    }}
  `,

  DynamicModeHeader: styled(Text.Bold)`
    color: var(--black);
    font-size: 20px;
    text-transform: uppercase;
  `,

  DynamicModeHeaderRow: styled(Row)`
    display: none;

    ${desktopBreakpoint(css`
      display: initial;
      margin-top: 56px;
    `)}
  `,

  DynamicModeRow: styled(Row)`
    flex-flow: row wrap;
    justify-content: center;

    ${desktopBreakpoint(css`
      flex-direction: row;
      justify-content: space-between;
      margin-top: 22px;
      width: 500px;
    `)}
  `,

  P: styled(Text.P)`
    ${Text.PlaaxLight}
    margin: 16px auto 0;
    max-width: 528px;
    min-height: 60px;
    text-align: center;
  `,
};
