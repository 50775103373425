import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";

import * as analytics from "../../analytics";
import { ErrorCode } from "../../api/consumer/types";
import * as r from "../../main/routes";
import * as sharedHooks from "../../shared/hooks";
import { SearchParam } from "../../shared/types";

import { ForgotPassword, updateSsoPageState } from "../actions";
import { useSsoPageReset } from "../hooks";
import * as text from "../text";
import { SsoPage, SsoProps } from "../types";
import * as utils from "../utils";
import { SsoChrome } from "./SsoChrome";
import { SsoErrorMessage } from "./SsoErrorMessage";
import * as SsoForm from "./SsoForm";

export type SsoForgotPasswordPageProps = SsoProps & {
  failureCode?: ErrorCode;
  forgotPassword: ForgotPassword;
  initialEmail?: string;
  success?: boolean;
  trackForgotPassword: analytics.sso.TrackForgotPassword;
  useSearchParam: sharedHooks.UseSearchParam;
};

export const SsoForgotPasswordPage: React.FC<SsoForgotPasswordPageProps> = ({
  failureCode,
  forgotPassword,
  initialEmail = "",
  ssoApiReturnTo = utils.ssoApiReturnTo,
  success,
  trackForgotPassword = analytics.sso.trackForgotPassword,
  useSearchParam = sharedHooks.useSearchParam,
}) => {
  const dispatch = useDispatch();
  const redirectTo = useSearchParam(SearchParam.REDIRECT) || r.home();
  const shouldCloseWindow = useSearchParam(SearchParam.CLOSE) || "false";

  const returnTo = ssoApiReturnTo(redirectTo);

  const createAccountLink = r.ssoInternalCreateAccount({
    close: shouldCloseWindow,
    returnTo: redirectTo,
  });
  const [email, setEmail] = useState<string>(initialEmail);

  useEffect(() => {
    trackForgotPassword();
  }, [trackForgotPassword]);

  useSsoPageReset(SsoPage.forgotPassword);

  if (success) {
    dispatch(
      updateSsoPageState(SsoPage.forgotPassword, {
        initialEmail: email,
      })
    );
    return (
      <Redirect
        to={r.ssoResetPasswordEmailSent({
          close: shouldCloseWindow,
          returnTo: redirectTo,
        })}
      />
    );
  }

  return (
    <SsoChrome>
      <SsoForm.Form
        data-testid="forgot-password-form"
        onSubmit={(e): void => {
          e.preventDefault();
          forgotPassword({ email, returnTo });
        }}
      >
        <SsoForm.Field>
          <SsoForm.Title>{text.FORGOT_PASSWORD_TITLE}</SsoForm.Title>
          <SsoForm.P>{text.FORGOT_PASSWORD}</SsoForm.P>
        </SsoForm.Field>
        <SsoForm.Field>
          <SsoForm.Label htmlFor="email-input">
            {text.EMAIL_LABEL}
          </SsoForm.Label>
          <SsoForm.Input
            data-testid="email-input"
            hasError={utils.isUsernameInError(failureCode)}
            id="email-input"
            onChange={(e): void => setEmail(e.target.value)}
            required
            type="email"
            value={email}
          />
        </SsoForm.Field>
        {failureCode && <SsoErrorMessage failureCode={failureCode} />}
        <SsoForm.ButtonsContainer>
          <SsoForm.Button data-testid="submit-button" type="submit">
            {text.RESET_PASSWORD_BUTTON}
          </SsoForm.Button>
        </SsoForm.ButtonsContainer>
        <SsoForm.Footer>
          <SsoForm.Link to={createAccountLink} data-testid="sign-up-link">
            {text.SIGN_UP_LINK}
          </SsoForm.Link>
        </SsoForm.Footer>
      </SsoForm.Form>
    </SsoChrome>
  );
};
