import { doesBrowserSupportTabs } from "../../navigator";
import * as PaxBleTypes from "../../pax-ble/types";

import { Potency } from "../api/consumer/types/strain";
import * as ff from "../featureFlags";
import { MyPodsType } from "../my-pax/types";
import { RELEVANCE } from "../pods/constants";
import { StrainsFilter } from "../pods/types";

const BASE_SSO_URL = process.env.REACT_APP_SSO_BASE_URL;

const deviceRoutes = {
  [PaxBleTypes.DeviceType.ERA]: "/device/era",
  [PaxBleTypes.DeviceType.ERA_PRO]: "/device/era-pro",
  [PaxBleTypes.DeviceType.PAX_3]: "/device/pax-3",
  [PaxBleTypes.DeviceType.PAX_35]: "/device/pax-3",
};

type UrlParams = {
  close?: string;
  country?: string;
  returnTo?: string;
};

export const account = (): string => `/account`;

export const accountDevices = (): string => `/account/devices`;

export const accountPods = (selectedMyPodsType?: MyPodsType): string =>
  `/account/pods/${selectedMyPodsType || MyPodsType.HISTORY}`;

export const accountSettings = (): string => `/my-pax/account`;

export const device = (deviceType?: PaxBleTypes.DeviceType): string => {
  return !deviceType ? "/device" : deviceRoutes[deviceType];
};

export const experienceModes = (): string => `/device/experience-modes`;

export const explore = (): string => `/explore`;

export const home = (): string => `/`;

export const partnerDetails = (partnerId: string): string =>
  `/explore/partners/${partnerId}`;

export const podsHome = (): string => `/explore/pods`;

export const podsSearch = (filter: StrainsFilter): string => {
  const search = getSearchPageSearchParams(filter);

  return `/explore/pods/search${search}`;
};

export const podsBatchList = (manualStrainId: string): string =>
  `/explore/pods/${manualStrainId}/batch-list`;

export const podAutoTestResults = (podId: string): string =>
  `/explore/pods/${podId}/pods-test-results`;

export const podManualTestResults = (
  batchId: string,
  strainId: string
): string => `/explore/pods/${strainId}/${batchId}/pods-test-results`;

export const podDetails = (strainId: string): string =>
  `/explore/pods/${strainId}`;

export type SsoRouteTo = (urlParams?: UrlParams) => string;

const ssoBaseUrl = (isExternalSsoEnabled: boolean): string | undefined => {
  return isExternalSsoEnabled ? BASE_SSO_URL : "/sso";
};

export const ssoSignOut: SsoRouteTo = (urlParams): string => {
  return encodeUrlParams(
    ssoBaseUrl(ff.isExternalSsoEnabled) + "/logout",
    addCloseToParams(urlParams)
  );
};

export const ssoCreateAccount: SsoRouteTo = (urlParams): string => {
  return encodeUrlParams(
    ssoBaseUrl(ff.isExternalSsoEnabled) + "/create-account",
    addCloseToParams(urlParams)
  );
};

export const ssoEditAccount: SsoRouteTo = (urlParams): string => {
  return encodeUrlParams(
    ssoBaseUrl(ff.isExternalSsoEnabled) + "/edit-account",
    addCloseToParams(urlParams)
  );
};

export const ssoLogin: SsoRouteTo = (urlParams): string => {
  return encodeUrlParams(
    ssoBaseUrl(ff.isExternalSsoEnabled) + "/login",
    addCloseToParams(urlParams)
  );
};

// Internal sso links for e-commerce
export const ssoInternalCreateAccount: SsoRouteTo = (urlParams): string => {
  return encodeUrlParams("/sso/create-account", addCloseToParams(urlParams));
};

export const ssoInternalLogin: SsoRouteTo = (urlParams): string => {
  return encodeUrlParams("/sso/login", addCloseToParams(urlParams));
};

export const ssoEmailVerificationRequestSent: SsoRouteTo = (
  urlParams
): string => {
  return encodeUrlParams(
    "/sso/email-verification-request-sent",
    addCloseToParams(urlParams)
  );
};

export const ssoEmailVerificationTokenExpired: SsoRouteTo = (
  urlParams
): string =>
  encodeUrlParams("/sso/email-verification-token-expired", urlParams);

export const ssoForgotPassword: SsoRouteTo = (urlParams): string =>
  encodeUrlParams("/sso/forgot-password", urlParams);

export const ssoResetPasswordEmailSent: SsoRouteTo = (urlParams): string =>
  encodeUrlParams("/sso/reset-password-email-sent", urlParams);

export const ssoResetPasswordTokenExpired: SsoRouteTo = (urlParams): string =>
  encodeUrlParams("/sso/reset-password-token-expired", urlParams);

const getSearchPageSearchParams = (filter: StrainsFilter): string => {
  const searchParts: string[] = [];

  Object.entries(filter).forEach(([key, value]) => {
    switch (key) {
      case "desiredEffects":
        const desiredEffects = value as string[];

        if (!desiredEffects || desiredEffects.length === 0) break;

        searchParts.push(`desiredEffects=${desiredEffects.join(",")}`);

        break;

      case "flavors":
        const flavors = value as string[];

        if (!flavors || flavors.length === 0) break;

        searchParts.push(`flavors=${flavors.join(",")}`);

        break;

      case "orderBy":
        const orderBy = value as string;

        searchParts.push(`orderBy=${orderBy || RELEVANCE}`);

        break;

      case "potency":
        const potency = value as Potency[];

        if (!potency || potency.length === 0) break;

        searchParts.push(`potency=${potency.join(",")}`);

        break;

      case "query":
        const query = value as string;

        if (!query) break;

        searchParts.push(`query=${encodeURIComponent(query)}`);

        break;

      case "selectedEffects":
        const selectedEffects = value as string[];

        if (!selectedEffects || selectedEffects.length === 0) break;

        searchParts.push(`selectedEffects=${selectedEffects.join(",")}`);

        break;

      case "strainClassifications":
        const strainClassifications = value as string[];

        if (!strainClassifications || strainClassifications.length === 0) break;

        searchParts.push(
          `strainClassifications=${strainClassifications.join(",")}`
        );

        break;

      default:
        if (!value) break;

        searchParts.push(`${key}=${value}`);
    }
  });

  return searchParts.length === 0 ? "" : "?" + searchParts.join("&");
};

export const encodeUrlParams = (url: string, params?: UrlParams): string => {
  if (!params) return url;

  const searchParams = new URLSearchParams();
  Object.entries(params).forEach(([key, value]) => {
    // re-assigning key here to avoid eslint warnings for camel_case in codebase
    if (key === "returnTo") key = "return_to";
    if (value) searchParams.append(key, value);
  });

  const encodedParams = searchParams.toString();
  return encodedParams ? `${url}?${encodedParams}` : url;
};

const addCloseToParams = (urlParams?: UrlParams): UrlParams => {
  return {
    ...urlParams,
    close: urlParams?.close || (doesBrowserSupportTabs() ? "true" : ""),
  };
};
