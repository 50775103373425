import { connect } from "react-redux";
import { withRouter } from "react-router";

import { getUserEmail } from "../../api/consumer/selectors/user";
import { MainAppState } from "../../main/types";
import { sendEmailVerificationRequest } from "../../sso/actions";

import * as emailVerificationNotification from "../components/EmailVerificationNotification";

const mapStateToProps = (
  state: MainAppState
): emailVerificationNotification.StateProps => {
  return {
    email: getUserEmail(state),
  };
};

const mapDispatchToProps: emailVerificationNotification.DispatchProps = {
  sendEmailVerificationRequest,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(emailVerificationNotification.EmailVerificationNotification)
);
