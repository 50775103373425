import React, { ReactElement } from "react";
import { Link, useLocation } from "react-router-dom";
import styled, { SimpleInterpolation } from "styled-components";

import { navigator } from "../../../navigator";

import * as Text from "../../shared/components/Text";

import * as r from "../routes";

type MobileNavLinkProps = {
  activeIcon: ReactElement;
  defaultIcon: ReactElement;
  to: string;
  startsWith: string;
  supportsBluetooth?: boolean;
};

export const MobileNavLink: React.FC<MobileNavLinkProps> = ({
  activeIcon,
  defaultIcon,
  to,
  startsWith,
  supportsBluetooth = !!navigator.bluetooth,
  ...props
}) => {
  const location = useLocation();
  const isActive =
    location.pathname.startsWith(startsWith) ||
    (to === r.device() && supportsBluetooth && location.pathname === "/") ||
    (to === r.explore() && !supportsBluetooth && location.pathname === "/");

  return (
    <sc.Link to={to} {...props}>
      {isActive ? activeIcon : defaultIcon}
    </sc.Link>
  );
};

type MobileNavLinkPAXLogoProps = MobileNavLinkProps & {
  $isDeviceLocked?: boolean;
};

export const MobileNavLinkPAXLogo = styled(
  MobileNavLink
)<MobileNavLinkPAXLogoProps>`
  background-color: ${({ $isDeviceLocked }): SimpleInterpolation =>
    $isDeviceLocked ? "var(--pale-red)" : "var(--white)"};
  & > svg {
    overflow: visible;
    & > g {
      fill: ${({ $isDeviceLocked }): SimpleInterpolation => {
        return $isDeviceLocked ? "var(--white)" : "var(--black)";
      }};
    }
  }
`;

const sc = {
  Link: styled(Link)`
    ${Text.Plaax}
    align-items: center;
    border-radius: 18px;
    color: var(--black);
    display: inline-flex;
    font-size: 20px;
    height: 36px;
    justify-content: center;
    line-height: 1;
    width: 36px;

    & > svg {
      overflow: visible;
    }
  `,
};
