import { connect } from "react-redux";

import * as c from "../../device/constants";
import { MainAppState } from "../../main/types";

import PodTestResultsCard from "../components/PodTestResults/PodTestResultsCard";
import { getDevicePodStrainId } from "../selectors";

const mapStateToProps = (state: MainAppState) => ({
  isDeviceLocked: state.device.attributes[c.ATTRIBUTE_LOCKED] === 1,
  podId: state.device.attributes[c.ATTRIBUTE_POD_DATA]?.podId,
  strainId: getDevicePodStrainId(state),
  testReport: state.device.pod.autoTestReport,
});

export default connect(mapStateToProps)(PodTestResultsCard);
