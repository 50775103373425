import { MainAppState } from "../../../main/types";

import { StrainJson } from "../types";
import { ConnectedPodJson } from "../types/connectedPods";

type GetStrainProps = {
  strainId?: string | null;
};

type GetStrain = (
  state: MainAppState,
  props: GetStrainProps
) => StrainJson | null;

export const getStrain: GetStrain = (state, { strainId }) =>
  strainId ? state.consumerApi.strains[strainId] : null;

type GetPodProps = GetStrainProps;

type GetPod = (
  state: MainAppState,
  props: GetPodProps
) => ConnectedPodJson | null;

export const getPod: GetPod = (state, { strainId }) =>
  strainId ? state.consumerApi.pods[strainId] : null;
