import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { StrainJson } from "../../api/consumer/types";
import * as deviceConstants from "../../device/constants";

type PodOverlayMobileProps = {
  strain: StrainJson | null;
  temperatureC: number;
};

export const PodOverlayMobile: React.FC<PodOverlayMobileProps> = ({
  strain = null,
  temperatureC = 0,
}) => {
  const [label, setLabel] = useState<string | null>(null);
  const timeout = useRef<number>();

  const isWithinPresetTemperatureBuffer = (
    temperatureC: number,
    presetTemperature: number
  ) => {
    return (
      presetTemperature &&
      Math.abs(presetTemperature - temperatureC) <=
        deviceConstants.PRESET_TEMP_BUFFER
    );
  };

  useEffect(() => {
    if (!strain) {
      setLabel(null);
      return;
    }

    const {
      bestFlavorTemperature,
      maxVaporTemperature,
      recommendedTemperature,
    } = strain;

    if (isWithinPresetTemperatureBuffer(temperatureC, recommendedTemperature)) {
      setLabel("Expert Temp");
      return;
    }

    if (isWithinPresetTemperatureBuffer(temperatureC, bestFlavorTemperature)) {
      setLabel("Ideal Flavor");
      return;
    }

    if (isWithinPresetTemperatureBuffer(temperatureC, maxVaporTemperature)) {
      setLabel("Max Vapor");
      return;
    }
  }, [strain, temperatureC]);

  useEffect(() => {
    if (!label) return;

    clearTimeout(timeout.current);

    const newTimeout = setTimeout(() => setLabel(null), 2000) as unknown;

    timeout.current = newTimeout as number;
  }, [label]);

  if (!label) return null;

  return (
    <sc.Container data-testid="preset-temp-overlay">
      <sc.TextPill>{label}</sc.TextPill>
    </sc.Container>
  );
};

const sc = {
  Container: styled.div`
    align-items: center;
    backdrop-filter: blur(10px);
    border-radius: 50%;
    display: flex;
    height: 138px;
    justify-content: center;
    position: absolute;
    width: 138px;
    z-index: 1;
  `,

  TextPill: styled.div`
    background-color: var(--black);
    border-radius: 19px;
    color: var(--white);
    font-size: 12px;
    padding: 10px;
    text-transform: uppercase;
  `,
};
