import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import * as consumerApi from "../../api/consumer";
import * as deviceSelectors from "../../device/selectors";

import * as Icons from "../components/Icons";
import { PodSwitcher } from "./components/PodSwitcher";
import { PodSwitcherConfirmationModal } from "./components/PodSwitcherConfirmationModal";
import { PodSwitcherWithText } from "./components/PodSwitcherWithText";
import { PodSwitcherRequestType } from "./types";

export const useDeviceControlsPodSwitcher = (
  onClick: () => void,
  strain?: consumerApi.types.StrainJson | null
): React.ReactNode => {
  const doesDeviceSupportPods = useSelector(
    deviceSelectors.doesDeviceSupportPods
  );

  if (!doesDeviceSupportPods) return null;

  const icon = strain ? (
    <Icons.Switch data-testid="switch-icon" />
  ) : (
    <Icons.Add data-testid="add-icon" />
  );

  return <PodSwitcher Icon={icon} onClick={onClick} strain={strain} />;
};

export type PodDetailsPagePodSwitcher = (
  devicePodId: string | null,
  strain: consumerApi.types.StrainJson,
  onPodAdded?: () => void
) => {
  modal: React.ReactElement;
  opener: React.ReactElement | false;
};

export const usePodDetailsPagePodSwitcher: PodDetailsPagePodSwitcher = (
  devicePodId,
  strain,
  onPodAdded
) => {
  const [mainIcon, setMainIcon] = useState<React.ReactElement>(<></>);
  const [hoverIcon, setHoverIcon] = useState<React.ReactElement>(<></>);
  const [requestType, setRequestType] = useState<PodSwitcherRequestType>();
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] =
    useState<boolean>(false);
  const [isButtonActive, setIsButtonActive] = useState<boolean>(false);

  const isPax3Connected = useSelector(deviceSelectors.getIsPax3Connected);

  useEffect(() => {
    // Show the add icon when the device pod id is blank.
    if (!devicePodId) {
      setMainIcon(<Icons.PodSwitcherAdd data-testid="add-icon" />);
      setHoverIcon(<Icons.PodSwitcherAdd data-testid="add-icon" />);
      setIsButtonActive(false);
      return;
    }

    // Show the clear icon when the current strain matches device pod id.
    if (devicePodId === strain.id) {
      setMainIcon(<Icons.PodSwitcherCheckmark data-testid="clear-icon" />);
      setHoverIcon(<Icons.Clear data-testid="check-icon" />);
      setIsButtonActive(true);
      return;
    }

    // Show the switch icon by default.
    setMainIcon(<Icons.PodSwitcherAdd data-testid="switch-icon" />);
    setHoverIcon(<Icons.Switch data-testid="switch-icon" />);
    setIsButtonActive(false);
  }, [devicePodId, strain.id]);

  const handlePodSwitcherClick = (): void => {
    setIsConfirmationModalOpen(true);
    if (!devicePodId) {
      setRequestType({ add: true });
    } else if (devicePodId !== strain.id) {
      setRequestType({ switch: true });
    } else if (devicePodId === strain.id) {
      setRequestType({ clear: true });
    }
  };

  const confirmationModal = (
    <PodSwitcherConfirmationModal
      closerProps={{ size: 0 }}
      isOpen={isConfirmationModalOpen}
      onConfirm={() => {
        if (onPodAdded) onPodAdded();
      }}
      onRequestClose={() => setIsConfirmationModalOpen(false)}
      podSwitcherRequestType={requestType}
      strain={strain}
    />
  );

  const opener = !isPax3Connected && (
    <PodSwitcherWithText
      data-testid="pod-switcher-confirmation-modal-opener"
      Icon={mainIcon}
      isActive={isButtonActive}
      HoverIcon={hoverIcon}
      onClick={handlePodSwitcherClick}
      strain={strain}
    />
  );

  return {
    modal: confirmationModal,
    opener,
  };
};
