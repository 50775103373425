import Bugsnag from "@bugsnag/js";

import * as ff from "../../featureFlags";

import { DeviceLocation } from "../consumer/types/device";
import { GOOGLE_API } from "./constants";
import googleMapsAxios from "./googleMapsAxios";
import * as t from "./types";
import * as u from "./utils";

type getFormattedAddressProps = (
  location: DeviceLocation,
  getReverseGeocode?: typeof googleMapsAxios.get,
  isGoogleMapsApiEnabled?: boolean
) => Promise<{ formatSuccessful: boolean; formattedAddress: string }>;

export const getFormattedAddress: getFormattedAddressProps = async (
  location,
  getReverseGeocode = googleMapsAxios.get,
  isGoogleMapsApiEnabled = ff.isGoogleMapsApiEnabled
) => {
  const { latitude, longitude } = location;

  const formattedAddress = (address: string, success: boolean) => ({
    formatSuccessful: success,
    formattedAddress: address,
  });

  if (!isGoogleMapsApiEnabled) {
    return formattedAddress(`${latitude}, ${longitude}`, false);
  }

  try {
    const geocodeResponse: t.GeocodeResponse = await getReverseGeocode(
      u.buildPath(GOOGLE_API.GEOCODING, {
        latlng: `${latitude},${longitude}`,
        result_type: "street_address",
      })
    );

    if (
      geocodeResponse?.results &&
      geocodeResponse?.results.length !== 0 &&
      (geocodeResponse?.status === google.maps.GeocoderStatus.OK ||
        geocodeResponse?.status === "200")
    ) {
      return formattedAddress(
        geocodeResponse.results[0].formatted_address,
        true
      );
    } else if (
      geocodeResponse?.status !== google.maps.GeocoderStatus.ZERO_RESULTS &&
      Object.keys(google.maps.GeocoderStatus).includes(geocodeResponse?.status)
    ) {
      Bugsnag.notify(
        new Error(
          "unexpected geocode response status: " + geocodeResponse?.status
        )
      );
    }
  } catch (err) {
    Bugsnag.notify(err as Error);
  }

  // The Geocoding API can't always find an address for given coordinates.
  // In those cases, return the coordinates.
  return formattedAddress(`${latitude}, ${longitude}`, false);
};
