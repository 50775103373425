import React, { useState } from "react";
import styled, { SimpleInterpolation } from "styled-components";

import * as PaxBle from "../../../pax-ble";

import { SessionProgressBar } from "../../device/components/SessionProgressBar";
import * as Card from "../../shared/components/Card";
import { Row } from "../../shared/components/Flex";
import { QuestionMarkWhite } from "../../shared/components/Icons";
import * as ps from "../../shared/components/PillSelect";
import * as Text from "../../shared/components/Text";

import { ERA_PRO_DOSE_OPTIONS, ERA_PRO_LOCKOUT_TIME } from "../constants";
import { useDoseStatus } from "../hooks";
import DoseControlExplainerModal from "./DoseControlExplainerModal";

export type EraProDoseControlCardProps = {
  isDeviceLocked: boolean;
  session?: PaxBle.Types.EraPro.Session;
  onStartEraProSession: (dosage: number) => void;
  onStopEraProSession: () => void;
};

const EraProDoseControlCard: React.FC<EraProDoseControlCardProps> = ({
  isDeviceLocked,
  session,
  onStartEraProSession,
  onStopEraProSession,
}) => {
  const [isExplainerModalOpen, setIsExplainerModalOpen] =
    useState<boolean>(false);

  const { isSessionInProgress, shouldShowTimeout } = useDoseStatus(session);

  const handleDoseChange = (selectedOption: ps.PillSelectOption): void => {
    const doseId = selectedOption.value;
    onStartEraProSession(doseId);
  };

  const handleSessionCancelClick = (e: React.MouseEvent): void => {
    e.preventDefault();
    onStopEraProSession();
  };

  const handleQuestionMarkClick = (): void => {
    setIsExplainerModalOpen(true);
  };

  const handleExplainerModalRequestClose = (): void => {
    setIsExplainerModalOpen(false);
  };

  if (isDeviceLocked) {
    return (
      <Card.Small data-testid="dose-control-card">
        <sc.TitleRow>
          <sc.Title isDeviceLocked>Dose Control</sc.Title>
        </sc.TitleRow>
      </Card.Small>
    );
  }

  const explainerModal = (
    <DoseControlExplainerModal
      isOpen={isExplainerModalOpen}
      onRequestClose={handleExplainerModalRequestClose}
    />
  );

  const explainerModalOpener = (
    <sc.QuestionMarkWhite
      data-testid="explainer-modal-opener"
      onClick={handleQuestionMarkClick}
    />
  );

  if (
    !session ||
    session.action === PaxBle.Types.EraPro.EraProSessionAction.INACTIVE
  ) {
    return (
      <>
        <Card.Small data-testid="dose-control-card">
          <sc.TitleRow>
            <sc.Title>Dose Control</sc.Title>
            {explainerModalOpener}
          </sc.TitleRow>
          <sc.Text>Choose desired number of doses</sc.Text>
          <ps.PillSelect
            Container={sc.PillSelectContainer}
            data-testid="dose-control-pill-select"
            onChange={handleDoseChange}
            options={[...ERA_PRO_DOSE_OPTIONS].reverse()}
          />
        </Card.Small>
        {explainerModal}
      </>
    );
  }

  const { doseId, usageTowardCompletion, initialTotalUsage } = session;
  const nDoses = `${doseId} dose${doseId > 1 ? "s" : ""}`;
  const percentageCompleted = Math.round(
    (100 * usageTowardCompletion) / initialTotalUsage
  );

  return (
    <>
      <Card.Small data-testid="dose-control-card">
        <SessionProgressBar
          initialLockoutSeconds={ERA_PRO_LOCKOUT_TIME}
          explainerModalOpener={explainerModalOpener}
          label={nDoses}
          onCancelClick={handleSessionCancelClick}
          progressPercentage={isSessionInProgress ? percentageCompleted : 0}
          showTimeout={shouldShowTimeout}
        />
      </Card.Small>
      {explainerModal}
    </>
  );
};

type TitleProps = {
  isDeviceLocked?: boolean;
};

type TitleRowProps = {
  activeSession?: boolean;
};

const sc = {
  PillSelectContainer: styled.div`
    display: flex;
    flex-direction: row-reverse;
    height: 36px;
    width: 100%;
  `,

  ProgressFooter: styled(Row)`
    ${Text.Plaax}
    font-size: 14px;
    justify-content: space-between;
    margin-top: 4px;
    padding: 0 6px;
    text-transform: uppercase;
    width: 100%;
  `,

  QuestionMarkWhite: styled(QuestionMarkWhite)`
    background-color: var(--very-light-pink-2);
    border-radius: 100%;
    cursor: pointer;
    height: 16px;
    padding: 3px;
    position: absolute;
    right: 0;
    top: 2px;
    width: 16px;
  `,

  Text: styled.div`
    ${Text.PlaaxLight}
    font-size: 14px;
    margin-bottom: 20px;
  `,

  Title: styled.div<TitleProps>`
    ${Text.Plaax}
    color: ${(props): SimpleInterpolation =>
      props.isDeviceLocked ? "var(--very-light-pink)" : "var(--black)"};
    font-size: 20px;
    margin-bottom: 8px;
    text-transform: uppercase;
  `,

  TitleActiveSession: styled.div`
    ${Text.PlaaxLight}
    font-size: 20px;
    margin-bottom: 8px;
    text-transform: uppercase;
  `,

  TitleRow: styled.div<TitleRowProps>`
    position: relative;
    text-align: center;
    width: 100%;

    ${({ activeSession }): SimpleInterpolation =>
      activeSession ? "margin-bottom: 24px;" : ""}
  `,
};

export default EraProDoseControlCard;
