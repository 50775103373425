import { CancelToken } from "axios";

import * as announcements from "./announcements";
import * as connectedPods from "./connectedPods";
import * as device from "./device";
import * as experiments from "./experiments";
import * as favorites from "./favorites";
import * as partner from "./partner";
import * as reviews from "./reviews";
import * as strain from "./strain";
import * as user from "./user";

export { StrainIngredient } from "./strain";
export type {
  CannabisCompoundJson,
  ParsedTestResultJson,
  Range,
  SafetyTestResultJson,
  StrainJson,
  TestResultJson,
} from "./strain";

export class ApiError extends Error {
  errorCode: ErrorCode;

  constructor(errorCode: ErrorCode) {
    super();
    this.errorCode = errorCode;
  }
}

export interface CancellablePromise<T> extends Promise<T> {
  cancel?: () => void;
}

export type CollectionResponse<T> = {
  count: number;
  meta?: strain.StrainMetaDataJson;
  next: string | null;
  previous: string | null;
  results: T[];
};

export enum ErrorCode {
  BAD_PROVINCE = "BAD_PROVINCE",
  DUPLICATE_EMAIL = "DUPLICATE_EMAIL",
  GEOCODING_ERROR = "GEOCODING_ERROR",
  GEO_STATE_DOES_NOT_EXIST = "GEO_STATE_DOES_NOT_EXIST",
  INVALID_LOGIN = "INVALID_LOGIN",
  INVALID_PASSWORD = "INVALID_PASSWORD",
  INVALID_TOKEN = "INVALID_TOKEN",
  INVALID_USERNAME = "INVALID_USERNAME",
  NO_GEOCODING_RESULT = "NO_GEOCODING_RESULT",
  REQUIRES_PASSWORD_RESET = "REQUIRES_PASSWORD_RESET",
  REUSED_PASSWORD = "REUSED_PASSWORD",
  UNKNOWN_ERROR = "UNKNOWN_ERROR",
}

export type FetchAction<T> = {
  type: string;
  error?: Error;
  payload?: T;
};

export type FetchMethod = "DELETE" | "GET" | "PATCH" | "POST" | "PUT";

export type FetchOptions = {
  cancelToken?: CancelToken;
  data?: object;
  fullUrl?: boolean;
  method?: FetchMethod;
  retryCount?: number;
  requiresAuthentication?: boolean;
};

export type FetchState<T> = {
  response?: T;
  error?: Error | null;
  isLoading: boolean;
};

export type LinkJson = {
  type: string;
  url: string;
};

export type LogoJson = {
  fileId: string;
  downloadUrl: string;
};

export type PaginatedFetchFunctions = {
  fetchNextPage: () => Promise<void>;
  setPath: (path: string) => void;
};

export type PaginatedFetchState<T> = {
  count: number;
  data: T[];
  isFetching: boolean;
  hasFetchedFirstPage: boolean;
  nextFetchUrl?: string | null;
  shouldFetchNextPage: boolean;
};

export type PhotoJson = {
  downloadUrl: string;
  fileId: string;
};

export interface UseFetch<T> {
  (initialPath: string, options?: FetchOptions):
    | [FetchState<T>, (path: string) => void]
    | [FetchState<T>];
}

export interface UsePaginatedFetch<T> {
  (initialPath: string, options: FetchOptions): [
    PaginatedFetchState<T>,
    PaginatedFetchFunctions
  ];
}

export type VideoJson = {
  downloadUrl: string;
  fileId: string;
  name: string;
};

export {
  announcements,
  connectedPods,
  device,
  experiments,
  favorites,
  partner,
  reviews,
  strain,
  user,
};
