import { connect } from "react-redux";

import { MainAppState } from "../../main/types";

import { verifyEmail } from "../actions";
import { SsoVerifyEmailPage } from "../components/SsoVerifyEmailPage";

const mapStateToProps = (state: MainAppState) => {
  return {
    ...state.sso.verifyEmail,
  };
};

const mapDispatchToProps = {
  verifyEmail,
};

export default connect(mapStateToProps, mapDispatchToProps)(SsoVerifyEmailPage);
