import { debounce } from "lodash";
import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";

import { Row } from "../../../shared/components/Flex";
import * as Text from "../../../shared/components/Text";
import * as t from "../../../shared/text";
import { desktopBreakpoint } from "../../../shared/utils";

import { MyPodsType } from "../../types";

export type MyPodsTabBarProps = {
  selectedMyPodsType: MyPodsType;
  setSelectedPodsType: React.Dispatch<React.SetStateAction<MyPodsType>>;
};

export const MyPodsTabBar: React.FC<MyPodsTabBarProps> = ({
  selectedMyPodsType,
  setSelectedPodsType,
  ...props
}) => {
  const [isHistoryActive, setIsHistoryActive] = useState<boolean>(
    selectedMyPodsType === MyPodsType.HISTORY
  );

  // Add listener to move between tabs with arrow keys
  useEffect(() => {
    const handleArrowKeyDown = (e: KeyboardEvent): void => {
      console.log(e.key);
      switch (e.key) {
        case "ArrowLeft":
          setSelectedPodsType(MyPodsType.HISTORY);
          document?.getElementById("history_tab")?.focus();
          return;
        case "ArrowRight":
          setSelectedPodsType(MyPodsType.FAVORITES);
          document?.getElementById("favorites_tab")?.focus();
          return;
        default:
          return;
      }
    };

    document.addEventListener("keydown", handleArrowKeyDown);

    return () => {
      document.removeEventListener("keydown", handleArrowKeyDown);
    };
  }, [setSelectedPodsType]);

  const handleTabClick = (tabType: MyPodsType) => {
    setIsHistoryActive(tabType === MyPodsType.HISTORY);

    // debounce needed for aria-selected to work correctly
    debounce(() => {
      setSelectedPodsType(tabType);
    }, 200)();
  };

  const tabBarTestId = isHistoryActive
    ? "my-pods-tab-bar-history-selected"
    : "my-pods-tab-bar-favorites-selected";

  return (
    <sc.Row
      aria-label="My Pods"
      data-testid={tabBarTestId}
      id="tab_container"
      role="tablist"
      {...props}
    >
      <sc.Tab
        aria-controls="tab_container"
        aria-selected={isHistoryActive}
        id="history_tab"
        isActive={isHistoryActive}
        onClick={(): void => handleTabClick(MyPodsType.HISTORY)}
        role="tab"
        tabIndex={0}
      >
        {t.HISTORY}
      </sc.Tab>
      <sc.Tab
        aria-controls="tab_container"
        aria-selected={!isHistoryActive}
        id="favorites_tab"
        isActive={!isHistoryActive}
        onClick={(): void => handleTabClick(MyPodsType.FAVORITES)}
        role="tab"
        tabIndex={0}
      >
        {t.FAVORITES}
      </sc.Tab>
    </sc.Row>
  );
};

type TabProps = {
  isActive?: boolean;
};

const activeTabStyles = css`
  ${Text.PlaaxBold}
  border-bottom: solid 2px;
`;

const sc = {
  Row: styled(Row)`
    justify-content: center;
    margin-top: 14px;
    width: 100%;

    & > :last-child {
      margin-right: 0;
    }

    ${desktopBreakpoint(
      css`
        justify-content: flex-start;
      `
    )}
  `,

  Tab: styled.div<TabProps>`
    ${Text.Plaax}
    color: var(--black);
    cursor: pointer;
    font-size: 14px;
    height: 24px;
    margin-right: 24px;
    text-align: center;
    text-transform: capitalize;

    ${desktopBreakpoint(
      css`
        font-size: 18px;
        height: 28px;
      `
    )}

    ${({ isActive }) => (isActive ? activeTabStyles : "")}
  `,
};
