export const MOBILE_MAX_WIDTH = 768;

export const PRIVACY_POLICY_URL = "https://www.pax.com/pages/privacy-policy";

export const BETA_URL = "https://web-app-beta.pax.com/";

export const SMALL_SCREEN_MAX_WIDTH = 359;

// Note: Values should match the width values above.
// Typescript does not yet support enums with computed strings, so values are hardcoded.
// https://github.com/microsoft/TypeScript/issues/40793
export enum Breakpoint {
  DESKTOP = "(min-width: 769px)",
  MOBILE = "(max-width: 768px)",
  SMALL_SCREEN = "(max-width: 359px)",
}

export const DEFAULT_TOAST_TIMEOUT = 10_000;
