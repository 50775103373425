import React, { InputHTMLAttributes, useState } from "react";
import styled, { StyledComponent } from "styled-components";

import { Eye as EyeIcon } from "./Icons";

const DefaultInput = styled.input``;

export type PasswordInputProps = InputHTMLAttributes<HTMLInputElement> & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Input?: StyledComponent<"input", any, any>;
  hasError?: boolean;
};

export const PasswordInput: React.FC<PasswordInputProps> = ({
  Input = DefaultInput,
  ...props
}) => {
  const [isRevealed, setIsRevealed] = useState<boolean>(false);

  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    setIsRevealed(!isRevealed);
  };

  return (
    <sc.Container>
      <Input
        data-testid="password-input"
        type={isRevealed ? "text" : "password"}
        boxShadow={props.hasError && "0 0 0 2px var(--pale-red)"}
        {...props}
      />
      <sc.EyeIcon
        $isRevealed={isRevealed}
        data-testid="reveal-icon"
        onClick={handleClick}
      />
    </sc.Container>
  );
};

const sc = {
  Container: styled.div`
    align-items: center;
    display: flex;
    position: relative;
  `,

  EyeIcon: styled(EyeIcon)`
    cursor: pointer;
    height: 24px;
    position: absolute;
    right: 12px;
    top: calc(50% - 12px);
    width: 24px;
  `,
};
