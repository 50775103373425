import { connect } from "react-redux";

import * as t from "../../../main/types";

import * as penThermostatCard from "../../components/Thermostat/PenThermostatCard";
import * as s from "../../selectors";

const mapStateToProps = (
  state: t.MainAppState
): penThermostatCard.PenThermostatCardProps => ({
  deviceTemperature: s.getDeviceTemperature(state),
  isDeviceLocked: s.getIsDeviceLocked(state),
  podStrainId: s.getDevicePodStrainId(state),
  temperatureRange: s.getTemperatureRange(state),
  temperatureUnit: s.getPreferredTemperatureUnit(state),
});

export default connect(mapStateToProps)(penThermostatCard.PenThermostatCard);
