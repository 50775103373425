type PaginationParams = {
  page: number;
  pageSize: number;
};

type PublicPartnersParams = PaginationParams;

export const publicPartners = ({
  page,
  pageSize,
}: PublicPartnersParams): string => {
  return `/public/partners?page=${page}&page_size=${pageSize}`;
};

export const publicPartner = (partnerCode: string): string => {
  return `/public/partners/${partnerCode}`;
};
