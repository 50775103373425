import React, { useEffect } from "react";
import { isIOS } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import styled, { css } from "styled-components";

import { trackEventAction } from "../../analytics/actions";
import { DEVICE_MODEL_MAP } from "../../api/consumer/constants";
import { getShareLocationDataFlagForDevice } from "../../api/consumer/selectors/devices";
import { DeviceJson } from "../../api/consumer/types/device";
import * as googleMapsApi from "../../api/google-maps/";
import { isGoogleMapsApiEnabled } from "../../featureFlags";
import { MainAppState } from "../../main/types";
import { getIsMyDeviceConnected } from "../../my-pax/utils";
import Button from "../../shared/components/Button";
import * as Text from "../../shared/components/Text";
import { desktopBreakpoint } from "../../shared/utils";

import * as c from "../constants";
import { getIsRequestingLocation } from "../selectors";
import * as u from "../utils";

const MAP_SIZE = "400x272";

type FindMyPaxMapProps = {
  address?: string;
  device: DeviceJson;
};

export const FindMyPaxMap: React.FC<FindMyPaxMapProps> = ({
  address,
  device,
}) => {
  const { location, model, serialNumber } = device;

  const dispatch = useDispatch();
  const isRequestingLocation = useSelector(getIsRequestingLocation);
  const isFindMyPAXEnabled = useSelector((state: MainAppState) =>
    getShareLocationDataFlagForDevice(state, { serial: serialNumber })
  );

  const isConnected = getIsMyDeviceConnected(device.serialNumber);

  const mapMarkerUrl = u.getFindMyPaxMapIconUrl(
    device.model,
    device.shellColor,
    !isConnected || isRequestingLocation
  );

  const mapImageUrl = googleMapsApi.utils.buildPath(
    googleMapsApi.constants.GOOGLE_API.STATIC_MAP,
    {
      center: location
        ? `${location.latitude},${location.longitude}`
        : c.DEFAULT_MAP_LOCATION,
      markers: location
        ? `anchor:center|icon:${mapMarkerUrl}|${location.latitude},${location.longitude}`
        : "none",
      size: MAP_SIZE,
      style:
        "feature:poi|element:labels|visibility:off&style=feature:landscape|element:geometry.fill|color:0xf5f5f5",
      zoom: location
        ? googleMapsApi.constants.MAP_ZOOM_LEVEL.STREET
        : googleMapsApi.constants.MAP_ZOOM_LEVEL.CITY,
    }
  );

  useEffect(() => {
    if (!isFindMyPAXEnabled) return;

    dispatch(
      trackEventAction("find_my_pax_gps_state_change", {
        event: "view_maps",
        newState: isFindMyPAXEnabled,
        peripheralModel: DEVICE_MODEL_MAP[model],
        serial: serialNumber,
      })
    );
  }, [dispatch, isFindMyPAXEnabled, model, serialNumber]);

  const handleGetDirectionsClick = (): void => {
    dispatch(
      trackEventAction("find_my_pax_gps_state_change", {
        event: "open_maps",
        newState: true,
        peripheralModel: DEVICE_MODEL_MAP[device.model],
        serial: device.serialNumber,
      })
    );
  };

  const mapsProtocol = isIOS ? "maps" : "https";
  const mapsUrl = `${mapsProtocol}://maps.google.com/maps?daddr=${address}`;

  return (
    <sc.Container>
      {isGoogleMapsApiEnabled && (
        <sc.Map alt="PAXFinder™ map" src={mapImageUrl} />
      )}
      {!device.dataSharingPreferences?.shareLocationData && (
        <sc.DisabledTag>Not Enabled</sc.DisabledTag>
      )}
      {address && (
        <a
          href={mapsUrl}
          onClick={handleGetDirectionsClick}
          rel="noreferrer"
          target="_blank"
        >
          <sc.DirectionsButton>Get Directions</sc.DirectionsButton>
        </a>
      )}
    </sc.Container>
  );
};

const sc = {
  Container: styled.div`
    position: relative;
    width: 100%;

    ${desktopBreakpoint(
      css`
        width: 400px;
      `
    )}
  `,

  DirectionsButton: styled(Button)`
    ${Text.PlaaxLight}
    bottom: 16px;
    font-size: 13px;
    left: calc(50% - 78px);
    position: absolute;
    width: 156px;
  `,

  DisabledTag: styled.div`
    ${Text.PlaaxLight}
    align-items: center;
    background-color: var(--brown-grey);
    border-radius: 16.5px;
    color: var(--white);
    display: flex;
    font-size: 13px;
    height: 24px;
    justify-content: center;
    margin: auto;
    position: absolute;
    right: 8px;
    text-transform: uppercase;
    top: 8px;
    width: 104px;
  `,

  Map: styled.img`
    border: solid 1px var(--very-light-pink-6);
    border-radius: 8px;
    width: 100%;

    ${desktopBreakpoint(
      css`
        width: 400px;
      `
    )}
  `,
};
