import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled, { css } from "styled-components";

import { updateUserDataSharingPreferences } from "../../api/consumer/actions/users";
import * as userSelectors from "../../api/consumer/selectors/user";
import { isExternalSsoEnabled } from "../../featureFlags";
import * as routes from "../../main/routes";
import { useNotifications } from "../../notifications/hooks";
import { getHasNotifications } from "../../notifications/selectors";
import { Spinner } from "../../notifications/styledComponents";
import Button from "../../shared/components/Button";
import { Row } from "../../shared/components/Flex";
import { Pencil } from "../../shared/components/Icons";
import * as Text from "../../shared/components/Text";
import { Toggle } from "../../shared/components/Toggle";
import { PRIVACY_POLICY_URL } from "../../shared/constants";
import * as text from "../../shared/text";
import { desktopBreakpoint } from "../../shared/utils";
import { ssoSignOut } from "../../sso/actions";
import { SsoLink } from "../../sso/components/SsoLink";
import { ssoApiReturnTo } from "../../sso/utils";

import MyPaxHeader from "../containers/MyPaxHeader/MyPaxHeader";
import PreferencesModal from "../containers/PreferencesModal";
import ProfileThemePicker from "../containers/ProfileThemePicker";
import * as SC from "../styledComponents";
import { AccountInfoModal } from "./AccountInfoModal";
import { SignInHeader } from "./SignInHeader";

export const AccountSettingsPage: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const notifications = useNotifications();
  const returnTo = ssoApiReturnTo(routes.accountSettings());

  const hasFetchedUser = useSelector(userSelectors.getHasFetchedUser);
  const isSignedIn = useSelector(userSelectors.getIsUserSignedIn);
  const preferredTemperatureUnit = useSelector(
    userSelectors.getPreferredTemperatureLabel
  );
  const user = useSelector(userSelectors.getUser);
  const hasNotifications = useSelector(getHasNotifications);

  const [isAccountInfoModalOpen, setIsAccountInfoModalOpen] =
    useState<boolean>(false);
  const [isDataSharingToggleLoading, setIsDataSharingToggleLoading] =
    useState<boolean>(false);
  const [isPreferencesModalOpen, setIsPreferencesModalOpen] =
    useState<boolean>(false);

  const handleDataSharingToggle = async (): Promise<void> => {
    setIsDataSharingToggleLoading(true);

    try {
      await dispatch(
        updateUserDataSharingPreferences({
          shareUsageData: !user.dataSharingPreferences?.shareUsageData,
        })
      );
    } finally {
      // updateDataSharingPreferences shows a flash banner on error.
      setIsDataSharingToggleLoading(false);
    }
  };

  const handleSignOutClick = (): void => {
    dispatch(ssoSignOut(history, routes.account()));
  };

  const dataShareLink = (
    <sc.PrivacyLink
      href={PRIVACY_POLICY_URL}
      target="_blank"
      rel="noopener noreferrer"
    >
      {text.DEVICE_USAGE_DATA}
    </sc.PrivacyLink>
  );

  const signOutButton = <Button data-testid="sign-out">{text.SIGN_OUT}</Button>;

  const signOutLink = isExternalSsoEnabled ? (
    <SsoLink
      button={signOutButton}
      returnTo={returnTo}
      ssoRouteTo={routes.ssoSignOut}
    />
  ) : (
    <Button data-testid="sign-out" onClick={handleSignOutClick}>
      {text.SIGN_OUT}
    </Button>
  );

  const isDisabled = hasFetchedUser && !isSignedIn;

  return (
    <sc.Page>
      <MyPaxHeader>{text.ACCOUNT_SETTINGS}</MyPaxHeader>
      {isDisabled && <SignInHeader returnTo={returnTo} />}
      <sc.Container disabled={isDisabled} isSignedIn={isSignedIn}>
        <sc.Title>{text.PROFILE_THEME}</sc.Title>
        <sc.Box>
          <sc.ProfileThemePicker />
        </sc.Box>
        <sc.Title data-testid="account-info-header">
          {text.ACCOUNT_INFO}{" "}
          <sc.PencilContainer
            data-testid="edit-account-info"
            onClick={(): void => setIsAccountInfoModalOpen(true)}
          >
            <Pencil />
          </sc.PencilContainer>
        </sc.Title>
        <sc.Box>
          <sc.SettingContainer>
            <sc.SettingLabel>{text.EMAIL}:</sc.SettingLabel>{" "}
            <sc.SettingValue data-testid="user-email">
              {isDisabled ? "example420@pax.com" : user.email}
            </sc.SettingValue>
          </sc.SettingContainer>
          <sc.SettingContainer>
            <sc.SettingLabel>{text.PASSWORD}:</sc.SettingLabel>{" "}
            <sc.SettingValue>{text.PASSWORD_PLACEHOLDER}</sc.SettingValue>
          </sc.SettingContainer>
        </sc.Box>
        <sc.Title data-testid="preferences-header">
          {text.PREFERENCES}{" "}
          <sc.PencilContainer
            data-testid="edit-preferences"
            onClick={(): void => setIsPreferencesModalOpen(true)}
          >
            <Pencil />
          </sc.PencilContainer>
        </sc.Title>
        <sc.Box>
          <sc.SettingContainer>
            <sc.SettingLabel>{text.TEMPERATURE_UNIT}:</sc.SettingLabel>{" "}
            <sc.SettingValue data-testid="selected-temperature-unit">
              {isDisabled ? "Fahrenheit/Celsius" : preferredTemperatureUnit}
            </sc.SettingValue>
          </sc.SettingContainer>
        </sc.Box>
        <sc.Title data-testid="settings-header">{text.SETTINGS}</sc.Title>
        <sc.Box>
          <sc.DataShareToggleContainer>
            <sc.DataShareToggleLoadingOverlay
              active={isDataSharingToggleLoading}
            >
              <sc.DataShareToggleLoadingSpinner color="var(--white)" />
            </sc.DataShareToggleLoadingOverlay>
            <sc.DataShareToggleText>
              {text.SHARE_MY_DATA_WITH_PAX(dataShareLink)}
            </sc.DataShareToggleText>
            <sc.DataShareToggle
              data-testid="data-sharing-toggle"
              active={!!user.dataSharingPreferences?.shareUsageData}
              onClick={handleDataSharingToggle}
            />
          </sc.DataShareToggleContainer>
        </sc.Box>
        {hasNotifications && (
          <>
            <sc.Title data-testid="notifications-header">
              {text.NOTIFICATIONS}
            </sc.Title>
            <sc.Box>{notifications}</sc.Box>
          </>
        )}
      </sc.Container>
      {hasFetchedUser && isSignedIn && (
        <sc.ButtonRow>{signOutLink}</sc.ButtonRow>
      )}
      <AccountInfoModal
        isOpen={isAccountInfoModalOpen}
        onRequestClose={(): void => setIsAccountInfoModalOpen(false)}
      />
      <PreferencesModal
        isOpen={isPreferencesModalOpen}
        onRequestClose={(): void => setIsPreferencesModalOpen(false)}
      />
    </sc.Page>
  );
};

type ContainerProps = {
  disabled?: boolean | null;
  isSignedIn?: boolean;
};

type DataShareToggleLoadingOverlayProps = {
  active: boolean;
};

const sc = {
  Box: styled.div`
    border: solid 1px var(--very-light-pink-2);
    border-radius: 8px;
    padding: 16px;
  `,

  ButtonRow: styled(Row)`
    align-content: center;
    justify-content: center;
    margin-top: 48px;
    width: 100%;

    ${desktopBreakpoint(
      css`
        margin-top: 68px;
        width: ${(props) => props.theme.pageWidth};
      `
    )}
  `,

  Container: styled.div<ContainerProps>`
    display: flex;
    flex-direction: column;
    max-width: ${(props) => props.theme.pageWidth};
    opacity: ${({ disabled }) => (disabled ? "0.36" : "1")};
    pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};

    ${desktopBreakpoint(
      css<ContainerProps>`
        margin: ${({ isSignedIn }) => (isSignedIn ? "40px" : "16px")} -4px 0;
        width: var(--my-pax-page-content-width);
      `
    )}
  `,

  DataShareToggle: styled(Toggle)`
    position: absolute;
    right: 16px;
  `,

  DataShareToggleContainer: styled.div`
    display: flex;
    position: relative;
  `,

  DataShareToggleLoadingOverlay: styled.div<DataShareToggleLoadingOverlayProps>`
    background-color: var(--black-20);
    border-radius: 8px;
    display: ${({ active }) => (active ? "block" : "none")};
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
  `,

  DataShareToggleLoadingSpinner: styled(Spinner)`
    border-width: 3.5px;
    height: 28px;
    position: absolute;
    right: calc(50% - 14px);
    top: calc(50% - 14px);
    width: 28px;
  `,

  DataShareToggleText: styled.div`
    ${Text.PlaaxLight}
    font-size: 14px;
    width: calc(100% - 80px);
  `,

  Page: styled(SC.Page)`
    align-items: center;
    display: flex;
    max-width: ${(props) => props.theme.myPax.pageContentWidth};
  `,

  PencilContainer: styled.div`
    cursor: pointer;
    height: 32px;
    margin-left: 8px;
    width: 32px;

    & svg {
      margin: 8px;
    }

    &:hover {
      background: black;
      border-radius: 16px;
    }

    &:hover svg path {
      fill: var(--white);
    }
  `,

  PrivacyLink: styled.a`
    color: var(--black);
  `,

  ProfileThemePicker: styled(ProfileThemePicker)`
    height: 50px;
    min-height: auto;
    width: 100%;

    & img {
      height: auto;
      margin: 0 2%;
      width: 16%;
    }

    & img:hover {
      border-radius: 128px;
      box-shadow: 0 0 0 4px var(--black);
    }

    ${desktopBreakpoint(
      css`
        height: 80px;

        & img {
          height: 80px;
          margin: 0 12px;
          width: 80px;
        }
      `
    )}
  `,

  SettingContainer: styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    &:last-child {
      margin: 0;
    }
  `,

  SettingLabel: styled.div`
    font-size: 16px;
    line-height: 1.13;
  `,

  SettingValue: styled.div`
    ${Text.PlaaxLight}
    font-size: 16px;
    line-height: 1.13;
    text-align: right;
  `,

  Title: styled.div`
    align-items: center;
    display: flex;
    font-size: 20px;
    justify-content: space-between;
    margin: 30px 0 8px;
    text-transform: uppercase;

    ${desktopBreakpoint(css`
      justify-content: flex-start;
    `)}
  `,
};
