import React, { useMemo } from "react";
import styled, { css } from "styled-components";

import * as t from "../../../../api/consumer/types";
import { Row } from "../../../../shared/components/Flex";
import * as Text from "../../../../shared/components/Text";
import * as text from "../../../../shared/text";
import { StrainColor } from "../../../../shared/types";
import * as utils from "../../../../shared/utils";

import { PodSectionTitleStyles } from "../../../styledComponents";

import { PodStrengthBar } from "./PodStrengthBar";

const getCannabinoidName = (name: string): string => {
  return name === "D9THC" ? text.THC : name;
};

const getSortedCannabinoids = (
  cannabinoids: t.CannabisCompoundJson[]
): {
  primaryCannabinoid?: t.CannabisCompoundJson;
  secondaryCannabinoids: t.CannabisCompoundJson[];
} => {
  cannabinoids.sort((a, b) => b.fieldValue - a.fieldValue);
  const primaryCannabinoid = cannabinoids.shift();

  return { primaryCannabinoid, secondaryCannabinoids: cannabinoids };
};

type CannabinoidStrengthProps = {
  potency: string;
  strainColor: StrainColor;
  testReport: t.ParsedTestResultJson;
};

export const CannabinoidStrength: React.FC<CannabinoidStrengthProps> = ({
  potency,
  strainColor,
  testReport,
}) => {
  const { primaryCannabinoid, secondaryCannabinoids } = useMemo(() => {
    return getSortedCannabinoids(testReport.parsedCannabinoids);
  }, [testReport.parsedCannabinoids]);

  const testDate = new Date(testReport.testDate).toLocaleDateString("en-US", {
    day: "numeric",
    month: "short",
    year: "numeric",
  });

  return (
    <>
      <sc.Heading>
        <sc.Title>{text.STRENGTH}</sc.Title>
        <sc.PotencyContainer>
          <sc.PotencyText>{potency}</sc.PotencyText>
        </sc.PotencyContainer>
      </sc.Heading>
      {primaryCannabinoid && (
        <>
          <sc.BarHeading isPrimary>
            <div>{getCannabinoidName(primaryCannabinoid.fieldName)}</div>
            <div>{`${+primaryCannabinoid.fieldValue.toFixed(2)}%`}</div>
          </sc.BarHeading>
          <PodStrengthBar
            height={20}
            strainColor={strainColor}
            strengthRange={[primaryCannabinoid.fieldValue]}
          />
        </>
      )}
      {secondaryCannabinoids.map((cannabinoid) => {
        const name = getCannabinoidName(cannabinoid.fieldName);
        return (
          <div key={name}>
            <sc.BarHeading>
              <div>{name}</div>
              <div>{`${+cannabinoid.fieldValue.toFixed(2)}%`}</div>
            </sc.BarHeading>
            <PodStrengthBar
              height={10}
              strainColor={strainColor}
              strengthRange={[cannabinoid.fieldValue]}
            />
          </div>
        );
      })}
      <sc.TestDate>{text.TEST_RESULTS_FROM.concat(" ", testDate)}</sc.TestDate>
    </>
  );
};

type BarHeadingProps = {
  isPrimary?: boolean;
};

const sc = {
  BarHeading: styled(Row)<BarHeadingProps>`
    ${Text.MediumLL}
    justify-content: space-between;
    margin: ${({ isPrimary }) => (isPrimary ? "0 0 8px" : "12px 0 8px")};
  `,

  Heading: styled(Row)`
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;

    ${utils.desktopBreakpoint(
      css`
        margin-top: 0;
      `
    )}
  `,

  PotencyContainer: styled(Row)`
    align-items: center;
    border: 1px solid var(--black);
    border-radius: 4px;
    padding: 8px;
  `,

  PotencyText: styled.div`
    ${Text.MediumLLBold}
    font-size: 12px;
    margin-bottom: 2px;
  `,

  TestDate: styled.div`
    ${Text.MediumLLLight}
    color: var(--brownish-grey);
    font-size: 14px;
    margin-top: 8px;
    text-align: right;
  `,

  Title: styled.div`
    ${PodSectionTitleStyles}
  `,
};
