import React from "react";
import styled from "styled-components";

type KnobProps = {
  onMouseDown: () => void;
  positionX: number;
  positionY: number;
};
export const Knob: React.FC<KnobProps> = ({
  onMouseDown,
  positionX,
  positionY,
}) => {
  return (
    <sc.KnobContainer
      data-testid="mobile-thermostat-knob"
      onMouseDown={onMouseDown}
      onTouchStart={onMouseDown}
      positionX={positionX}
      positionY={positionY}
    >
      <svg width="46px" height="46px" viewBox="-4 -4 46 46">
        <defs>
          <radialGradient
            id="radialGradient"
            cx="50%"
            cy="50%"
            r="50%"
            fx="50%"
            fy="50%"
          >
            <sc.StopInner offset="0%" />
            <sc.StopOuter offset="100%" />
          </radialGradient>
          <filter id="shadow" width="120%" height="120%">
            <feGaussianBlur in="SourceAlpha" stdDeviation="3" />
            <feOffset result="offsetblur" />
          </filter>
        </defs>
        <circle
          fill="var(--very-light-pink-6)"
          filter="url(#shadow)"
          stroke="none"
          cx={20}
          cy={20}
          r={18}
        />
        <circle fill="var(--white)" stroke="none" cx={20} cy={20} r={20} />
        <circle fill="var(--white)" stroke="none" cx={20} cy={20} r={16} />
        <ellipse cx={20} cy={20} rx={16} ry={16} fill="url(#radialGradient)" />
      </svg>
    </sc.KnobContainer>
  );
};

type KnobContainerProps = {
  positionX?: number;
  positionY?: number;
};

const sc = {
  KnobContainer: styled.div.attrs<KnobContainerProps>(
    ({ positionX, positionY }) => ({
      style: {
        transform: `translate(${positionX}px, ${positionY}px)`,
      },
    })
  )<KnobContainerProps>`
    position: absolute;
    left: -14px;
    top: -14px;
    cursor: grab;
  `,

  OuterKnob: styled.circle`
    box-shadow: 0 1px 4px 0 rgba(164, 165, 177, 0.5);
  `,

  StopInner: styled.stop`
    stop-color: rgb(255, 255, 255);
    stop-opacity: 0;
  `,

  StopOuter: styled.stop`
    stop-color: var(--very-light-pink-6);
    stop-opacity: 0.69;
  `,
};
