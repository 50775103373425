import styled from "styled-components";

type ButtonWrapperProps = {
  backgroundColor?: string;
};

export const ButtonWrapper = styled.button<ButtonWrapperProps>`
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : "var(--white)"};
  border: none;
`;
