import React, { useEffect, useState } from "react";
import styled from "styled-components";

import * as r from "../../main/routes";

import { SSO_CONFIRMATION_TIMEOUT } from "../constants";
import * as text from "../text";
import { SsoProps } from "../types";
import * as u from "../utils";
import { SsoChrome } from "./SsoChrome";
import * as SsoForm from "./SsoForm";

export type SsoResetPasswordConfirmationPageProps = SsoProps & {
  redirectTo: string;
};

export const SsoResetPasswordConfirmationPage: React.FC<SsoResetPasswordConfirmationPageProps> =
  ({ redirectTo, ssoRedirect = u.ssoRedirect }) => {
    const [shouldRedirect, setShouldRedirect] = useState(false);

    useEffect(() => {
      setTimeout(() => {
        setShouldRedirect(true);
      }, SSO_CONFIRMATION_TIMEOUT);
    }, [redirectTo]);

    if (shouldRedirect) {
      ssoRedirect(r.ssoInternalLogin({ close: "false", returnTo: redirectTo }));
      return null;
    }

    return (
      <SsoChrome>
        <SsoForm.Form data-testid="reset-password-confirmation">
          <sc.Field>
            <SsoForm.Title>{text.PASSWORD_CONFIRMATION_TITLE}</SsoForm.Title>
            <SsoForm.P>{text.PASSWORD_CONFIRMATION}</SsoForm.P>
          </sc.Field>
        </SsoForm.Form>
      </SsoChrome>
    );
  };

const sc = {
  Field: styled(SsoForm.Field)`
    margin-bottom: 60px;
  `,
};
