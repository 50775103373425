import { Action } from "../../shared/types";

import * as userSelectors from "./selectors/user";
import * as a from "./actions";
import * as t from "./types";

export type ConsumerApiState = {
  anonymousToken: string;
  connectedPods: t.connectedPods.ConnectedPods;
  experiments: t.experiments.ExperimentsJson;
  hasFetchedUser: boolean;
  isFetchingUser: boolean;
  partners: t.partner.PartnerJson[];
  pods: { [key: string]: t.connectedPods.ConnectedPodJson };
  strains: { [key: string]: t.StrainJson };
  strainReviews: { [key: string]: t.reviews.StrainReviewJson };
  user: t.user.User;
  themes?: t.user.Theme[];
};

const initialState: ConsumerApiState = {
  anonymousToken: "",
  connectedPods: {
    needsReview: {
      data: [],
      isFetched: false,
    },
  },
  experiments: { assignedVariations: [] },
  hasFetchedUser: false,
  isFetchingUser: false,
  partners: [],
  pods: {},
  strainReviews: {},
  strains: {},
  user: {
    devices: [],
    peripheralId: userSelectors.getUserPeripheralId(),
    recentSearches: [],
    recentlyViewedPods: [],
    type: t.user.UserType.ANONYMOUS,
    uuid: userSelectors.getUserUuid(),
  },
};

export const getInitialState = (
  overrides?: Partial<ConsumerApiState>
): ConsumerApiState => {
  return { ...initialState, ...overrides };
};

export const reducer = (
  state: ConsumerApiState = initialState,
  action: Action
): ConsumerApiState => {
  const { payload } = action;

  switch (action.type) {
    case a.users.RECEIVED_ANONYMOUS_USER:
      const { anonymousToken, anonymousUser } = payload;
      return {
        ...state,
        anonymousToken,
        hasFetchedUser: true,
        isFetchingUser: false,
        user: {
          ...state.user,
          ...anonymousUser,
        },
      };

    case a.users.RECEIVED_REGISTERED_USER:
      const { registeredUser } = payload;
      return {
        ...state,
        hasFetchedUser: true,
        isFetchingUser: false,
        user: {
          ...state.user,
          ...registeredUser,
        },
      };

    case a.users.RECEIVED_USER_LOCATION:
      const { location } = payload;
      return {
        ...state,
        user: {
          ...state.user,
          location,
        },
      };

    case a.experiments.RECEIVED_EXPERIMENTS:
      const { experiments } = payload;

      return { ...state, experiments };

    case a.partners.FETCH_PARTNERS_SUCCESS:
      const partners = payload.response.results as t.partner.PartnerJson[];

      return { ...state, partners };

    case a.connectedPods.RECEIVED_CONNECTED_POD:
      const { connectedPod } = payload;
      const newState = {
        ...state,
        // TODO clean this up; data is repeated under state.strains.
        pods: {
          ...state.pods,
          [connectedPod.strain.id]: connectedPod,
        },
        strains: {
          ...state.strains,
          [connectedPod.strain.id]: connectedPod.strain,
        },
      };

      if (connectedPod.latestReview) {
        newState.strainReviews = {
          ...newState.strainReviews,
          [connectedPod.latestReview.strainId]: connectedPod.latestReview,
        };
      } else {
        const podNeedingReviewIndex =
          newState.connectedPods.needsReview.data.findIndex(
            (pod) => pod.strain.id === connectedPod.strain.id
          );
        const newNeedsReviewData = [...newState.connectedPods.needsReview.data];

        if (podNeedingReviewIndex < 0) {
          newNeedsReviewData.push(connectedPod);
        } else {
          newNeedsReviewData[podNeedingReviewIndex] = connectedPod;
        }

        newState.connectedPods = {
          ...newState.connectedPods,
          needsReview: {
            ...newState.connectedPods.needsReview,
            data: newNeedsReviewData,
          },
        };
      }

      return newState;

    case a.devices.RECEIVED_DEVICE:
      const { device } = payload;
      const updatedDevices = [...state.user.devices];

      const deviceIndexToUpdate = updatedDevices.findIndex(
        (d) => d.serialNumber === device.serialNumber
      );

      if (deviceIndexToUpdate > -1) {
        updatedDevices[deviceIndexToUpdate] = device;
      } else {
        updatedDevices.push(device);
      }

      return {
        ...state,
        user: {
          ...state.user,
          devices: updatedDevices,
        },
      };

    case a.users.RECEIVED_NEW_RECENT_SEARCH:
      const { newQuery } = payload;
      const updatedRecentSearches = [newQuery, ...state.user.recentSearches];

      return {
        ...state,
        user: {
          ...state.user,
          recentSearches: updatedRecentSearches,
        },
      };

    case a.users.RECEIVED_NEW_VIEWED_POD:
      const { strain } = payload;
      const updatedRecentlyViewed = state.user.recentlyViewedPods
        ? [strain, ...state.user.recentlyViewedPods]
        : [strain];

      return {
        ...state,
        user: {
          ...state.user,
          recentlyViewedPods: updatedRecentlyViewed,
        },
      };

    case a.reviews.RECEIVED_REVIEW:
      const { review } = payload;

      const pod = state.pods[review.strainId] || {};

      return {
        ...state,
        pods: {
          ...state.pods,
          [review.strainId]: {
            ...pod,
            latestReview: review,
          },
        },
        strainReviews: {
          ...state.strainReviews,
          [review.strainId]: review,
        },
      };

    case a.users.RECEIVED_THEMES:
      const { themes } = payload;
      return { ...state, themes };

    case a.devices.REMOVE_DEVICE:
      const newDevices = state.user.devices.filter(
        (d) => d.serialNumber !== payload.device.serialNumber
      );

      return {
        ...state,
        user: {
          ...state.user,
          devices: newDevices,
        },
      };

    case a.strains.RECEIVED_STRAIN:
      const newStrain: t.StrainJson = payload.strain;
      return {
        ...state,
        strains: {
          ...state.strains,
          [newStrain.id]: newStrain,
        },
      };

    case a.RESET_CONSUMER_API_STATE:
      return getInitialState({ user: { ...initialState.user, uuid: "" } });

    case a.users.SET_IS_FETCHING_USER:
      return { ...state, hasFetchedUser: false, isFetchingUser: true };

    default:
      return state;
  }
};
