import React from "react";
import { useSelector } from "react-redux";
import styled, { css } from "styled-components";

import { Column } from "../../shared/components/Flex";
import * as t from "../../shared/text";
import { desktopBreakpoint } from "../../shared/utils";

import { getIsUserLocationUndetermined } from "../selectors";
import { PodsLocationModalContent } from "./PodsLocationModalContent";

export const PodsLocationInput: React.FC = () => {
  const isLocationUndetermined = useSelector(getIsUserLocationUndetermined);

  if (!isLocationUndetermined) return null;

  return (
    <sc.Container>
      <sc.Title>{t.WHERE_ARE_YOU}</sc.Title>
      <PodsLocationModalContent hideDivider />
    </sc.Container>
  );
};

const sc = {
  Container: styled(Column)`
    background-color: var(--white-three);
    margin: 0 -16px 16px;
    padding: 16px 16px 0;

    ${desktopBreakpoint(
      css`
        border-radius: 8px;
        margin: 0 0 16px;
        padding: 16px 0 16px;
      `
    )}
  `,

  Title: styled.div`
    font-size: 18px;
    margin: 0 auto 4px;
    max-width: 500px;
    width: 100%;

    ${desktopBreakpoint(
      css`
        margin: 12px auto 4px;
      `
    )}
  `,
};
