import * as Era from "./Era";
import * as EraPro from "./EraPro";
import * as VaporEngine from "./VaporEngine";

export { Era, EraPro, VaporEngine };
export * as Pax3 from "./Pax3";

export enum DeviceType {
  ERA = "ERA",
  ERA_PRO = "ERA_PRO",
  PAX_3 = "PAX_3",
  PAX_35 = "PAX_35",
}

export enum Endian {
  LITTLE,
  BIG,
}

export enum BrightnessCommand {
  DEFAULT = 0,
  TEMPORARY = 1,
  RESET = 2,
}

export type ColorMode = {
  color1: {
    red: number;
    blue: number;
    green: number;
  };
  color2: {
    red: number;
    blue: number;
    green: number;
  };
  animation: number;
  frequency: number;
};

export enum HEATING_STATE_COLOR_MODE_ANIMATIONS {
  DISPLAY_HEATING_UP_FADE = 0,
  DISPLAY_HEATING_DOWN_FADE = 1,
  DISPLAY_HEATING_PLASMA = 2,
  DISPLAY_HEATING_KNIGHT_RIDER = 3,
  DISPLAY_HEATING_CW_ROTATE = 4,
  DISPLAY_HEATING_CCW_ROTATE = 5,
  DEFAULT = 255,
}

export enum COLOR_MODE_FREQUENCIES {
  DISPLAY_FREQ_0P2HZ = 5,
  DISPLAY_FREQ_0P25HZ = 6,
  DISPLAY_FREQ_0P33HZ = 7,
  DISPLAY_FREQ_0P5HZ = 9,
  DISPLAY_FREQ_0P75HZ = 11,
  DISPLAY_FREQ_1HZ = 13,
  DISPLAY_FREQ_1P25HZ = 15,
  DISPLAY_FREQ_1P37HZ = 16,
  DISPLAY_FREQ_1P5HZ = 17,
  DISPLAY_FREQ_4HZ = 27,
}

export enum STARTUP_STATE_COLOR_MODE_ANIMATIONS {
  DISPLAY_STARTUP_SWIRL = 0,
  DISPLAY_STARTUP_FLICKER_SWIRL = 1,
  DISPLAY_STARTUP_STORM = 2,
  DEFAULT = 255,
}

export type DeviceRequestOptions =
  | {
      filters: BluetoothRequestDeviceFilter[];
      optionalServices?: BluetoothServiceUUID[] | undefined;
    }
  | {
      acceptAllDevices: boolean;
      optionalServices?: BluetoothServiceUUID[] | undefined;
    }
  | undefined;

export type DeviceOptions = {
  eraProLegacyFirmwareSupportedAttributes?: number[];
  serial?: string;
};

export type LogEvent = Era.LogEvent | EraPro.LogEvent;
export type LogEventType = Era.LogType | EraPro.LogType;

export type LogStoreAnalyticsData = {
  action: string;
  hasReachedEnd?: boolean;
  logCount?: number;
  maxIndex?: number;
  puffLogsCount?: number;
  reachedWebLogThreshold?: boolean;
  resetLogEvent?: boolean;
  timeSyncDeltaS?: number;
  timeSyncLogEvent?: boolean;
};
