import { PayloadAction } from "@reduxjs/toolkit";

import { trackPodId } from "../../../analytics/pods/podId";
import { AppThunk } from "../../../shared/types";

import { fetch } from "../index";
import * as p from "../paths";
import * as t from "../types";

export const RECEIVED_CONNECTED_POD = "RECEIVED_CONNECTED_POD";

type RecordConnectedPod = (
  strainId: string,
  options?: {
    podSerialNumber?: string;
    wasScanned?: boolean;
    onPodRecorded?: () => void;
  }
) => AppThunk;

export const recordConnectedPod: RecordConnectedPod =
  (strainId, options) =>
  async (dispatch, getState): Promise<void> => {
    trackPodId(strainId, !!options?.podSerialNumber, !!options?.wasScanned)(
      dispatch,
      getState,
      null
    );

    try {
      const connectedPod = await fetch<t.connectedPods.ConnectedPodJson>(
        p.connectedPods.recordConnectedPod(strainId),
        {
          data: {
            clientTimestamp: new Date().toISOString(),
            podSerialNumber: options?.podSerialNumber,
            wasScanned: !!options?.wasScanned,
          },
          method: "POST",
        }
      );

      dispatch(receivedConnectedPod(connectedPod));
      if (options?.onPodRecorded) options.onPodRecorded();
    } catch (e) {
      // Ignore the error; UI doesn't need to respond.
    }
  };

export const receivedConnectedPod = (
  connectedPod: t.connectedPods.ConnectedPodJson
): PayloadAction<t.connectedPods.ConnectedPodPayload> => ({
  payload: { connectedPod },
  type: RECEIVED_CONNECTED_POD,
});
