// Spec:
// https://github.com/PaxLabs/pax-logging/blob/develop/schemas/events/app/navigation/pod_detail_0.json

import { StrainsFilter } from "../../pods/types";
import { AppThunk } from "../../shared/types";

import { trackEventAction } from "../actions";

const EVENT_NAME = "pod_detail";

type PodDetailEventProperties = {
  brandLogoTap?: string;
  desiredEffectsFilter?: string[];
  partnerIdFilter?: string;
  podView: string;
  query?: string;
  regionFilter?: string;
  stateFilter?: string;
  strainClassificationsFilter?: string[];
};

export const strainsFilterToPodDetailEventData = (
  strainsFilter: StrainsFilter,
  strainId: string
): PodDetailEventProperties => {
  const data: PodDetailEventProperties = { podView: strainId };

  if (strainsFilter.desiredEffects?.length)
    data.desiredEffectsFilter = strainsFilter.desiredEffects.map((effect) =>
      effect.toLowerCase()
    );

  if (strainsFilter.partnerId) data.partnerIdFilter = strainsFilter.partnerId;

  if (strainsFilter.query) data.query = strainsFilter.query;

  if (strainsFilter.region)
    data.regionFilter = strainsFilter.region.toLowerCase();

  if (strainsFilter.state) data.stateFilter = strainsFilter.state.toLowerCase();

  if (strainsFilter.strainClassifications?.length)
    data.strainClassificationsFilter = strainsFilter.strainClassifications.map(
      (classification) => classification.toLowerCase()
    );

  return data;
};

export const trackPodDetails =
  (strainsFilter: StrainsFilter, strainId: string): AppThunk =>
  (dispatch, getState): void => {
    const eventData: PodDetailEventProperties =
      strainsFilterToPodDetailEventData(strainsFilter, strainId);

    trackEventAction<PodDetailEventProperties>(EVENT_NAME, eventData)(
      dispatch,
      getState,
      null
    );
  };

export const trackPartnerClick =
  (partnerId: string, strainId: string): AppThunk =>
  (dispatch, getState): void => {
    trackEventAction<PodDetailEventProperties>(EVENT_NAME, {
      brandLogoTap: partnerId,
      podView: strainId,
    })(dispatch, getState, null);
  };
