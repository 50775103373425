import classNames from "classnames";
import React from "react";
import { isMobile } from "react-device-detect";

type BannerSiblingProps = {
  mobileOnly?: boolean;
};

type StyledProps = {
  className?: string;
};

export const BannerSibling: React.FC<BannerSiblingProps> = ({
  children,
  mobileOnly,
  ...props
}) => {
  const className = classNames((props as StyledProps).className, {
    "sticky-banner-sibling": (mobileOnly && isMobile) || !mobileOnly,
  });

  return (
    <div {...props} className={className}>
      {children}
    </div>
  );
};
