import styled, { css } from "styled-components";

import { ButtonWrapper } from "./ButtonWrapper";
import { Row } from "./Flex";

const boxStyle = css`
  align-items: center;
  background: var(--white);
  border: 3px solid var(--black-10);
  border-radius: 4px;
  padding: 22px 17px;
`;

export const Box = styled(Row)`
  ${boxStyle}
`;

export const ClickableBox = styled(ButtonWrapper)`
  ${boxStyle}
  display: flex;
  flex-direction: row;
`;
