import { createLogger } from "redux-logger";

import { RECEIVED_ATTRIBUTE } from "../device/constants";

const ATTRIBUTES_TO_FILTER = process.env.REACT_APP_ATTRIBUTES_TO_FILTER;

export const logger = createLogger({
  predicate: (_, action) => {
    // if (action.type === RECEIVED_ATTRIBUTE && ATTRIBUTES_TO_FILTER) {
    //   const { attributeId } = action.payload;
    //
    //   return !ATTRIBUTES_TO_FILTER.includes(attributeId);
    // }

    return true;
  },
});
