import React from "react";
import styled, { css } from "styled-components";

import { mobileBreakpoint } from "../utils";

const MINIMUM_TOUCH_TARGET_PX = 44;

type TouchTargetProps = {
  height?: number;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  width?: number;
};

export const TouchTarget: React.FC<TouchTargetProps> = ({
  height = MINIMUM_TOUCH_TARGET_PX,
  width = MINIMUM_TOUCH_TARGET_PX,
  ...props
}) => {
  return (
    <sc.TouchContainer height={height} width={width} {...props}>
      <div>{props.children}</div>
    </sc.TouchContainer>
  );
};

type TouchContainerProps = {
  height?: number;
  width?: number;
};

const sc = {
  TouchContainer: styled.div<TouchContainerProps>`
    cursor: pointer;

    ${mobileBreakpoint(css<TouchContainerProps>`
      align-items: center;
      display: flex;
      height: ${({ height }) => height}px;
      justify-content: center;
      min-height: ${MINIMUM_TOUCH_TARGET_PX}px;
      min-width: ${MINIMUM_TOUCH_TARGET_PX}px;
      width: ${({ width }) => width}px;
    `)}
  `,
};
