import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styled, { css, SimpleInterpolation } from "styled-components";

import { trackPodLowEventFromDeviceControlsPage } from "../../analytics/fuel-gauge/podLow";
import { getPod } from "../../api/consumer/selectors/strains";
import * as t from "../../api/consumer/types";
import { getDevicePod } from "../../device/selectors";
import * as r from "../../main/routes";
import { MainAppState } from "../../main/types";
import { PodFeedbackPrompt } from "../../pod-feedback/components/PodFeedbackPrompt";
import * as hooks from "../../pods/hooks";
import * as Strain from "../../shared/colors/Strain";
import * as Icons from "../../shared/components/Icons";
import { LowPodBadge } from "../../shared/components/LowPodBadge";
import { StrainComposition } from "../../shared/components/StrainComposition";
import * as Text from "../../shared/components/Text";
import * as text from "../../shared/text";
import * as utils from "../../shared/utils";

import { DeviceWithoutPodText } from "./DeviceWithoutPodText";

export type DevicePodDetailsMobileProps = {
  isDeviceLocked?: boolean;
  podId?: string;
  useBatchListFetch?: hooks.UseBatchListFetch;
  useStrainById?: hooks.UseStrainById;
};

export const DevicePodDetailsMobile: React.FC<DevicePodDetailsMobileProps> = ({
  isDeviceLocked,
  podId,
  useBatchListFetch = hooks.useBatchListFetch,
  useStrainById = hooks.useStrainById,
  ...props
}) => {
  const dispatch = useDispatch();
  const pod = useSelector(getDevicePod);
  const { strainId, isAutoId } = pod;
  const connectedPodJson = useSelector((state: MainAppState) =>
    getPod(state, { strainId: strainId })
  );

  const { strain } = useStrainById(strainId, { shouldShowErrorBanner: true });
  const batchList = useBatchListFetch(strainId || undefined);

  useEffect(() => {
    if (!connectedPodJson) return;

    dispatch(trackPodLowEventFromDeviceControlsPage(pod, connectedPodJson));
    // Track only when strain id changes.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [strainId]);

  const isPodMissingStrainInfo = isAutoId && (!strainId || !strain);

  if (!strain || !strainId) {
    return (
      <sc.Container isDeviceLocked={isDeviceLocked} {...props}>
        <DeviceWithoutPodText
          isPodMissingStrainInfo={isPodMissingStrainInfo}
          shouldUppercase
        />
      </sc.Container>
    );
  }

  const latestBatchWithReport = batchList.find(
    (batch) => batch.parsedBatchResult
  );

  let testResultsPath;
  if (latestBatchWithReport && podId) {
    testResultsPath = r.podAutoTestResults(podId);
  } else if (latestBatchWithReport?.batchId && strainId) {
    testResultsPath = r.podManualTestResults(
      latestBatchWithReport.batchId,
      strainId
    );
  }

  return (
    <>
      <sc.Container isDeviceLocked={isDeviceLocked} {...props}>
        <sc.Title
          to={{
            pathname: r.podDetails(strainId ? strainId : ""),
            state: { goBackTo: r.device() },
          }}
        >
          {strain.name}
        </sc.Title>
        {isAutoId && connectedPodJson?.isLow ? (
          <LowPodBadge data-testid="low-pod-badge" />
        ) : (
          <sc.Subtitle>
            <Icons.Hexagon
              color={Strain.getPrimaryStrainColor(strain)}
              size={14}
            />
            <sc.Classification>
              {utils.getStrainClassificationFormattedText(
                strain.classification
              )}
            </sc.Classification>
          </sc.Subtitle>
        )}
        <sc.ResultsContainer>
          <LinkButton
            isDisabled={isDeviceLocked}
            label={text.STRAIN_DETAILS}
            pathName={r.podDetails(strain.id)}
          >
            <Icons.WhiteOil />
          </LinkButton>
          <sc.CompositionContainer>
            <StrainComposition
              ingredient={t.StrainIngredient.THC}
              isMobileDevicePage
              strain={strain}
            />
            <StrainComposition
              ingredient={t.StrainIngredient.CBD}
              isMobileDevicePage
              strain={strain}
            />
          </sc.CompositionContainer>
          <LinkButton
            isDisabled={isDeviceLocked || !testResultsPath}
            label={text.TEST_RESULTS}
            pathName={testResultsPath}
          >
            <Icons.WhiteTestResults />
          </LinkButton>
        </sc.ResultsContainer>
      </sc.Container>
      <PodFeedbackPrompt strainId={strain.id} />
    </>
  );
};

type LinkButtonProps = {
  isDisabled?: boolean;
  label: string;
  pathName?: string;
};

const LinkButton: React.FC<LinkButtonProps> = ({
  isDisabled,
  label,
  pathName,
  children,
}) => {
  return (
    <sc.BlackLink
      $isDisabled={isDisabled}
      to={{
        pathname: pathName,
        state: { goBackTo: r.device() },
      }}
    >
      {children}
      <sc.LinkText>{label}</sc.LinkText>
    </sc.BlackLink>
  );
};

type BlackLinkProps = {
  $isDisabled?: boolean;
};

type ContainerProps = {
  isDeviceLocked?: boolean;
};

const sc = {
  BlackLink: styled(Link)<BlackLinkProps>`
    align-items: center;
    background-color: ${({ $isDisabled }): SimpleInterpolation =>
      $isDisabled ? "var(--very-light-pink)" : "var(--black)"};
    border-radius: 8px;
    color: var(--white);
    display: flex;
    flex-direction: column;
    height: 65px;
    justify-content: center;
    pointer-events: ${({ $isDisabled }): SimpleInterpolation =>
      $isDisabled ? "none" : "auto"};
    text-decoration: none;
    width: 66px;
  `,

  Classification: styled.span`
    ${Text.PlaaxLight}
    font-size: 16px;
    margin-left: 8px;
    text-transform: uppercase;
  `,

  CompositionContainer: styled.div`
    border: solid 1px var(--gunmetal-20);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    margin: 0 12px;
    padding: 10px;
    width: 166px;

    & > :first-child {
      margin-bottom: 7px;
    }

    ${utils.smallScreenBreakpoint(css`
      width: 146px;
    `)}
  `,

  Container: styled.div<ContainerProps>`
    align-items: center;
    display: flex;
    filter: ${({ isDeviceLocked }): SimpleInterpolation =>
      isDeviceLocked ? "grayscale(100%)" : "none"};
    flex-direction: column;
    opacity: ${({ isDeviceLocked }): SimpleInterpolation =>
      isDeviceLocked ? "0.3" : "1"};
    padding: 16px 20px;
    position: relative;
  `,

  LinkText: styled.div`
    text-align: center;
  `,

  ResultsContainer: styled.div`
    align-items: center;
    display: flex;
    height: 65px;
    justify-content: center;
    margin-top: 20px;
  `,

  Subtitle: styled.div`
    align-items: flex-end;
    display: flex;
    font-size: 14px;
    justify-content: center;
    line-height: 1.14;
    text-align: center;
    width: 236px;
  `,

  Title: styled(Link)`
    color: var(--black);
    font-size: 24px;
    margin-bottom: 4px;
    overflow: hidden;
    text-align: center;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 90%;
  `,
};
