import styled, { css } from "styled-components";

import { desktopBreakpoint } from "../utils";
import { ButtonWrapper } from "./ButtonWrapper";

export const MyPaxDeviceCard = styled(ButtonWrapper)`
  align-items: center;
  border-radius: 8px;
  box-shadow: 0 1px 6px 0 var(--bluey-grey-30);
  display: flex;
  flex-direction: row;
  height: 112px;
  justify-content: flex-start;
  position: relative;
  width: 100%;

  ${desktopBreakpoint(
    css`
      flex-direction: column;
      height: 190px;
      justify-content: center;
      width: 196px;
    `
  )}
`;
