import * as PaxBleTypes from "../../../../pax-ble/types";

import { trackEventAction } from "../../../analytics/actions";
import { getConnectedDevice } from "../../../bluetooth/connectedDevice";
import { DeviceColor } from "../../../device/types";
import * as flashBanner from "../../../shared/components/FlashBanner";
import { OOPS_AN_ERROR_OCCURRED_TRY_AGAIN } from "../../../shared/text";
import { Action, AppThunk } from "../../../shared/types";

import * as c from "../constants";
import { fetch } from "../index";
import * as p from "../paths";
import { getAccountDevice } from "../selectors/devices";
import * as t from "../types/device";

export const RECEIVED_DEVICE = "RECEIVED_DEVICE";
export const REMOVE_DEVICE = "REMOVE_DEVICE";

const PAX_3_5_COLORS = [
  t.DeviceShellColor.BURGUNDY,
  t.DeviceShellColor.ONYX,
  t.DeviceShellColor.SAGE,
  t.DeviceShellColor.SAND,
];

type AssociateDevice = (
  deviceType: PaxBleTypes.DeviceType,
  serial: string,
  deviceName?: string,
  shellColorCode?: DeviceColor
) => AppThunk;

type CreateDevice = (device: t.PostDeviceJson) => AppThunk;

const createDevice: CreateDevice =
  (device) =>
  async (dispatch): Promise<void> => {
    const data: t.DeviceRequest = {
      appType: "WEB",
      clientTimestamp: new Date().toISOString(),
      device,
    };

    const newDevice = await fetch<t.DeviceJson>(p.devices.pairedDevices(), {
      data,
      method: "POST",
    });

    dispatch(receivedDevice(newDevice));
  };

export const associateDevice: AssociateDevice =
  (deviceType, serial, deviceName, shellColorCode) =>
  (dispatch, getState): void => {
    const connectedDevice = getConnectedDevice();
    const state = getState();

    let model = c.DEVICE_TYPE_MAP[deviceType];
    let shellColor: t.DeviceShellColor;

    if (shellColorCode === undefined) {
      if (model === t.DeviceModel.ERA) {
        // An Era device doesn't have shell color attribute so we need to hardcode it.
        shellColor = t.DeviceShellColor.MATTE_BLACK;
      } else {
        // If it's not an Era, wait for the shell color code to be read from the device.
        return;
      }
    } else {
      shellColor = c.DEVICE_COLOR_NUMBER_MAP[shellColorCode];
    }

    if (model === t.DeviceModel.PAX_3 && PAX_3_5_COLORS.includes(shellColor)) {
      model = t.DeviceModel.PAX_3_5;
    }

    if (!deviceName) {
      if (model === t.DeviceModel.PAX_3 || model === t.DeviceModel.PAX_3_5) {
        // We cannot read the device name for PAX 3 or PAX 3.5 so we need to hardcode it.
        deviceName = "PAX 3";
      } else if (
        model === t.DeviceModel.ERA &&
        !connectedDevice?.supportsRenameDevice
      ) {
        deviceName = "PAX Era";
      } else {
        // If it's not a PAX 3/3.5, wait for the device name to be read from the device.
        return;
      }
    }

    const accountDevice = getAccountDevice(state, { serial });

    if (accountDevice) {
      // The device is already associated with this account, so update it.
      const updatedDevice: Partial<t.PostDeviceJson> = {
        ...accountDevice,
        model,
        shellColor,
      };

      if (model !== t.DeviceModel.PAX_3 && model !== t.DeviceModel.PAX_3_5) {
        updatedDevice.deviceName = deviceName;
      }

      updateDevice(serial, updatedDevice)(dispatch, getState, null);
      return;
    }

    // Device is not associated with this account, so create a new device.
    const deviceJson: t.PostDeviceJson = {
      deviceName,
      logSyncOffset: 0,
      model,
      serialNumber: serial,
      shellColor,
    };

    createDevice(deviceJson)(dispatch, getState, null);
  };

export const setShareLocationDataFlag =
  (shareLocationData: boolean, device: t.DeviceJson): AppThunk =>
  (dispatch, getState): void => {
    const updates: Partial<t.PostDeviceJson> = {
      dataSharingPreferences: {
        setAt: new Date().toISOString(),
        shareLocationData,
      },
      location: undefined,
    };

    updateDevice(device.serialNumber, updates)(dispatch, getState, null);
    trackEventAction("find_my_pax_gps_state_change", {
      event: "on_off",
      newState: shareLocationData,
      peripheralModel: c.DEVICE_MODEL_MAP[device.model],
      serial: device.serialNumber,
    })(dispatch, getState, null);
  };

const receivedDevice = (device: t.DeviceJson): Action => ({
  payload: { device },
  type: RECEIVED_DEVICE,
});

export type RemoveDevice = (device: t.DeviceJson) => AppThunk;

export const removeDevice: RemoveDevice =
  (device) =>
  async (dispatch): Promise<void> => {
    await fetch(p.devices.pairedDevices(device.serialNumber), {
      method: "DELETE",
    });

    dispatch({ payload: { device }, type: REMOVE_DEVICE });
  };

export type UpdateDevice = (
  serial: string,
  updates: Partial<t.PostDeviceJson>
) => AppThunk;

export const updateDevice: UpdateDevice =
  (serial, updates) =>
  async (dispatch): Promise<void> => {
    const data: t.DeviceRequest = {
      appType: "WEB",
      clientTimestamp: new Date().toISOString(),
      device: updates as t.PostDeviceJson,
    };

    try {
      const updatedDevice = await fetch<t.DeviceJson>(
        p.devices.pairedDevices(serial),
        {
          data,
          method: "PATCH",
        }
      );

      dispatch(receivedDevice(updatedDevice));
    } catch (error) {
      dispatch(
        flashBanner.showFlashBanner(
          OOPS_AN_ERROR_OCCURRED_TRY_AGAIN,
          flashBanner.BannerType.ERROR
        )
      );
    }
  };

export const updateLogOffsetForDevice =
  (logSyncOffset: number, deviceSerial: string): AppThunk =>
  async (dispatch, getState): Promise<Action> => {
    const updates: Partial<t.PostDeviceJson> = {
      logSyncOffset,
    };

    return updateDevice(deviceSerial, updates)(dispatch, getState, null);
  };

export const updateLogOffsetForEra =
  (
    logSyncOffset: number,
    lastLogReadIndex: number,
    deviceSerial: string
  ): AppThunk =>
  async (dispatch, getState): Promise<Action> => {
    const updates: Partial<t.PostDeviceJson> = {
      lastLogReadIndex,
      logSyncOffset,
    };

    return updateDevice(deviceSerial, updates)(dispatch, getState, null);
  };
