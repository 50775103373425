import { debounce } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { InputActionMeta } from "react-select";
import styled, { css } from "styled-components";

import { receivedNewRecentSearch } from "../../api/consumer/actions/users";
import * as r from "../../main/routes";
import { Button } from "../../shared/components/Button";
import { ChevronLeft, Clear } from "../../shared/components/Icons";
import { QueryFieldDropdown } from "../../shared/components/QueryFieldDropdown";
import { StickyHeader } from "../../shared/components/StickyHeader";
import * as sharedHooks from "../../shared/hooks";
import { DropdownOption } from "../../shared/types";
import { desktopBreakpoint } from "../../shared/utils";

import * as h from "../hooks";
import { OnPodSearchQuerySet } from "../types";
import { PodsLocationHeader } from "./PodsLocationHeader";
import { PodsSearchResultsHeader } from "./PodsSearchResultsHeader";

export type PodsSearchQueryFieldProps = {
  initialQuery?: string;
  isClearable?: boolean;
  podsActiveFilters?: React.ReactNode;
  setQuery?: OnPodSearchQuerySet;
  shouldDisableStickyHeader?: boolean;
  shouldShowSearchDropdown?: boolean;
  useDesktopMediaQuery?: sharedHooks.UseDesktopMediaQuery;
};

const QUERY_DEBOUNCE = 750;
const QUERY_FIELD_HEIGHT = 70;
const SEARCH_RESULTS_HEADER_HEIGHT = 114;

export const PodsSearchQueryField: React.FC<PodsSearchQueryFieldProps> = ({
  initialQuery = "",
  isClearable = false,
  podsActiveFilters = null,
  setQuery = (): void => {},
  shouldDisableStickyHeader,
  shouldShowSearchDropdown,
  useDesktopMediaQuery = sharedHooks.useDesktopMediaQuery,
  ...props
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);
  const [newPendingQuery, setNewPendingQuery] = useState<string>(initialQuery);
  const [shouldClearQueryField, setShouldClearQueryField] =
    useState<boolean>(false);
  const [shouldShowQueryField, setShouldShowQueryField] =
    useState<boolean>(true);
  const [shouldAutoFocus, setShouldAutoFocus] = useState<boolean>(true);

  const activeStrainFiltersCount = h.useGetActiveStrainFilterCount();
  const debouncedSetQuery = useRef(debounce(setQuery, QUERY_DEBOUNCE));
  const isDesktop = useDesktopMediaQuery();

  useEffect(() => {
    debouncedSetQuery.current = debounce((query) => {
      if (query) dispatch(receivedNewRecentSearch(query));
      setQuery(query);
    }, QUERY_DEBOUNCE);
  }, [dispatch, setQuery]);

  const toggleAutoFocus = (bool: boolean) => {
    setShouldAutoFocus(bool);
  };

  const showSearchField = (): void => {
    setMenuIsOpen(true);
    setShouldShowQueryField(true);
  };

  const hideSearchField = (): void => {
    if (!shouldClearQueryField) {
      setMenuIsOpen(false);
      setShouldShowQueryField(false);
    }

    setShouldClearQueryField(false);
  };

  const handleKeyDown = (e: React.KeyboardEvent): void => {
    if (e.key !== "Enter" || !newPendingQuery) return;

    setMenuIsOpen(false);
    setShouldShowQueryField(false);
    e.preventDefault();

    if (newPendingQuery === initialQuery) return;

    setQuery(newPendingQuery);
    dispatch(receivedNewRecentSearch(newPendingQuery));
  };

  const handleChange = (option: DropdownOption | null): void => {
    if (!option) return;

    setMenuIsOpen(false);
    setNewPendingQuery(option.value);
    setQuery(option.value);
    dispatch(receivedNewRecentSearch(option.value));
  };

  const handleInputChange = (
    value: string,
    { action }: InputActionMeta
  ): void => {
    if (action === "input-change" || value) {
      setMenuIsOpen(true);
      setNewPendingQuery(value);
      debouncedSetQuery.current(value);
    }
  };

  const handleBackClick = () => {
    if (newPendingQuery || initialQuery || activeStrainFiltersCount) {
      hideSearchField();
      return;
    }

    history.push(r.podsHome());
  };

  const handleClearSearchClick = () => {
    setShouldClearQueryField(true);
    setNewPendingQuery("");
    setQuery("");
  };

  const handleResetClick = () => {
    history.push(r.podsHome());
  };

  const shouldShowSearchHeader = h.useShouldShowPodsSearchHeader({
    hasQuery: !!newPendingQuery || !!initialQuery,
    hideSearchField,
    isDesktop,
    resetAll: handleResetClick,
  });

  const queryField = (): React.ReactElement => (
    <sc.QueryField {...props}>
      {!isDesktop && (
        <sc.BackButton
          aria-label="back"
          onMouseDown={handleBackClick}
          transparent
        >
          <sc.ChevronLeft size={20} />
        </sc.BackButton>
      )}
      <QueryFieldDropdown
        autoFocus={!isDesktop && shouldAutoFocus}
        data-testid="search-page-query-field-dropdown"
        inputValue={newPendingQuery}
        menuIsOpen={menuIsOpen}
        onBlur={hideSearchField}
        onChange={handleChange}
        onFocus={showSearchField}
        onInputChange={handleInputChange}
        onKeyDown={handleKeyDown}
        shouldShowDropdown={shouldShowSearchDropdown}
        value={
          newPendingQuery
            ? {
                label: newPendingQuery,
                value: newPendingQuery,
              }
            : null
        }
      />
      {isClearable && newPendingQuery && (
        <sc.ClearButton
          aria-label="clear-search"
          onMouseDown={handleClearSearchClick}
          transparent
        >
          <Clear size={20} />
        </sc.ClearButton>
      )}
    </sc.QueryField>
  );

  const searchHeaderHeight = shouldShowSearchHeader
    ? SEARCH_RESULTS_HEADER_HEIGHT
    : 0;

  const activeFilterHeight = activeStrainFiltersCount ? 66 : 0;

  const containerHeight =
    QUERY_FIELD_HEIGHT + searchHeaderHeight + activeFilterHeight;

  return isDesktop ? (
    queryField()
  ) : (
    <StickyHeader
      disabled={shouldDisableStickyHeader}
      containerHeight={containerHeight}
      toggleAutoFocus={toggleAutoFocus}
    >
      <sc.DropShadow>
        {shouldShowQueryField ? (
          queryField()
        ) : (
          <PodsLocationHeader handleSearchClick={showSearchField} />
        )}
      </sc.DropShadow>
      {shouldShowSearchHeader && (
        <PodsSearchResultsHeader
          handleResetClick={handleResetClick}
          initialQuery={initialQuery}
        />
      )}
      <sc.ActiveFilters>{podsActiveFilters}</sc.ActiveFilters>
    </StickyHeader>
  );
};

const sc = {
  ActiveFilters: styled.div`
    padding-left: 20px;

    ${desktopBreakpoint(
      css`
        padding-left: 0;
      `
    )}
  `,

  BackButton: styled(Button)`
    padding: 20px 0px;
  `,

  ChevronLeft: styled(ChevronLeft)`
    align-self: center;
    justify-self: end;

    ${desktopBreakpoint(css`
      display: none;
    `)}
  `,

  ClearButton: styled(Button)`
    height: 50px;
    padding: 15px;
    position: absolute;
    right: 0;
    top: 6px;
    width: 50px;

    ${desktopBreakpoint(css`
      top: 37px;
    `)}
  `,

  DropShadow: styled.div`
    box-shadow: var(--mobile-header-box-shadow);

    ${desktopBreakpoint(css`
      box-shadow: none;
    `)}
  `,

  QueryField: styled.div`
    display: grid;
    grid-template-columns: 42px 1fr 42px;
    width: 100%;

    ${desktopBreakpoint(css`
      box-shadow: none;
      display: block;
      width: ${(props) => props.theme.queryField.width};
    `)}
  `,
};
