import * as t from "../../api/consumer/types";

import { StrainColor } from "../types";

const classificationColorsMap: {
  [key: string]: StrainColor;
} = {
  CBD: {
    primary: "var(--pax-forest-green)",
    secondary: "var(--pax-yellow)",
  },
  HYBRID: {
    primary: "var(--pax-lavender)",
    secondary: "var(--pax-forest-green)",
  },
  INDICA: {
    primary: "var(--pax-navy-blue)",
    secondary: "var(--pax-lavender)",
  },
  SATIVA: {
    primary: "var(--pax-yellow)",
    secondary: "var(--pax-navy-blue)",
  },
};

const classificationGradientColorsMap: { [key: string]: string } = {
  CBD: "var(--pax-yellow-60)",
  HYBRID: "var(--pax-forest-green-60)",
  INDICA: "var(--pax-lavender-60)",
  SATIVA: "var(--pax-navy-blue-60)",
};

export const getPrimaryStrainColor = (strain: t.StrainJson): string => {
  return classificationColorsMap[strain.classification]?.primary || "";
};

export const getSecondaryStrainColor = (strain: t.StrainJson): string => {
  return classificationColorsMap[strain.classification]?.secondary || "";
};

export const getStrainColors = (strain: t.StrainJson): StrainColor => {
  return (
    classificationColorsMap[strain.classification] || {
      primary: "",
      secondary: "",
    }
  );
};

export const getStrainColorGradient = (strain: t.StrainJson): string => {
  return classificationGradientColorsMap[strain.classification];
};

const legacyClassificationGradientColorsMap: { [key: string]: string } = {
  CBD: "var(--pale-red-60)",
  HYBRID: "var(--seafoam-blue-60)",
  INDICA: "var(--powder-blue-60)",
  SATIVA: "var(--maize-60)",
};

export const getLegacyStrainColorGradient = (strain: t.StrainJson): string => {
  return legacyClassificationGradientColorsMap[strain.classification];
};
