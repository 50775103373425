import styled, {
  css,
  CSSProperties,
  FlattenInterpolation,
  ThemedStyledProps,
} from "styled-components";

import * as t from "../../api/consumer/types/device";
import { getImageAssetForApiDevice } from "../../api/consumer/utils";
import { desktopBreakpoint } from "../../shared/utils";

type DeviceLogoProps = {
  device: t.DeviceJson;
};

const ERA_PRO_STYLES = css`
  background-position: 23px 8px;
  background-size: 24px;

  ${desktopBreakpoint(
    css`
      background-position: 28px 8px;
      background-size: 32px;
    `
  )}
`;

const ERA_STYLES = css`
  background-position: 13px 8px;
  background-size: 44px;

  ${desktopBreakpoint(
    css`
      background-position: 18px 4px;
      background-size: 52px;
    `
  )}
`;

const PAX_3_STYLES = css`
  background-position: 20px 12px;
  background-size: 30px;

  ${desktopBreakpoint(
    css`
      background-position: 24px 12px;
      background-size: 40px;
    `
  )}
`;

export const DeviceLogo = styled.div<DeviceLogoProps>`
  background-image: url(${({ device }) => getImageAssetForApiDevice(device)});
  background-repeat: no-repeat;
  border: solid 0.6px var(--very-light-pink-2);
  border-radius: 80px;
  height: 72px;
  margin: 20px 0 0 54px;
  width: 72px;

  ${({
    device,
  }): FlattenInterpolation<ThemedStyledProps<CSSProperties, unknown>> => {
    if (device.model === t.DeviceModel.ERA_PRO) {
      return ERA_PRO_STYLES;
    }

    if (device.model === t.DeviceModel.ERA) {
      return ERA_STYLES;
    }

    return PAX_3_STYLES;
  }}

  ${desktopBreakpoint(
    css`
      height: 88px;
      width: 88px;
    `
  )}
`;
