import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import styled, { css, SimpleInterpolation } from "styled-components";

import * as PaxBle from "../../../pax-ble";

import { getDeviceAsset } from "../../device/constants";
import * as dt from "../../device/types";
import { home } from "../../main/routes";
import Button from "../../shared/components/Button";
import * as Text from "../../shared/components/Text";
import * as text from "../../shared/text";
import { desktopBreakpoint } from "../../shared/utils";

import { InstallFirmware, SetFirmwareUpgradeProgress } from "../actions";
import * as hooks from "../hooks";
import * as t from "../types";

const WAIT_TIME = 2500;

export type StateProps = {
  device?: PaxBle.BaseDevice;
  deviceColor: dt.DeviceColor;
  firmwareInfo: t.FirmwareInfo;
  isDeviceRestarting: boolean;
  isLowBattery: boolean;
  isUpgradeComplete: boolean;
  message?: string;
  progress?: number;
  title: string;
};

export type DispatchProps = {
  installFirmware: InstallFirmware;
  setFirmwareUpgradeProgress: SetFirmwareUpgradeProgress;
};

export type OwnProps = t.FirmwareModalContentProps & {
  useFetchFirmwareImage?: hooks.UseFetchFirmwareImage;
};

export type FirmwareUpdateProgressModalContentProps = OwnProps &
  StateProps &
  DispatchProps;

export const FirmwareUpdateProgressModalContent: React.FC<FirmwareUpdateProgressModalContentProps> =
  ({
    device,
    deviceColor,
    firmwareInfo,
    installFirmware,
    isDeviceRestarting,
    isLowBattery,
    isUpgradeComplete,
    message,
    onStepCompleted,
    progress,
    setFirmwareUpgradeProgress,
    title,
    useFetchFirmwareImage = hooks.useFetchFirmwareImage,
  }) => {
    const { error, imageData } = useFetchFirmwareImage(
      firmwareInfo.downloadUrl
    );
    const deviceAsset = getDeviceAsset(
      PaxBle.Types.DeviceType.ERA_PRO,
      deviceColor
    );
    const shouldShowProgressBar =
      isDeviceRestarting || !!(progress && progress > 0);

    useEffect(() => {
      if (!device || !imageData || isLowBattery) return;

      installFirmware(device, firmwareInfo, imageData);
    }, [device, imageData, isLowBattery, firmwareInfo, installFirmware]);

    useEffect(() => {
      if (!isDeviceRestarting || !device) return;

      // 1ms delay to set progess to 100 for CSS transition to appear properly.
      window.setTimeout(() => setFirmwareUpgradeProgress(100), 1);
    }, [device, isDeviceRestarting, setFirmwareUpgradeProgress]);

    useEffect(() => {
      let stepTimeout: number;

      if (isUpgradeComplete || isLowBattery || error) {
        stepTimeout = window.setTimeout(() => {
          onStepCompleted(t.FirmwareStep.UPDATE_PROGRESS, true);
        }, WAIT_TIME);
      }

      return (): void => clearTimeout(stepTimeout);
    }, [error, isLowBattery, isUpgradeComplete, onStepCompleted]);

    if (!device && !(isDeviceRestarting || isUpgradeComplete)) {
      return <Redirect to={home()} />;
    }

    if (isLowBattery) {
      title = text.BATTERY_TOO_LOW;
      message = text.PLEASE_CHARGE_DEVICE;
    }

    if (error) {
      title = text.FIRMWARE_NOT_AVAILABLE;
      message = text.PROBLEM_UPDATING_FIRMWARE;
    }

    return (
      <sc.Container>
        <sc.Title data-testid="firmware-progress-title">{title}</sc.Title>
        <sc.Device src={deviceAsset || ""} alt="PAX device" />
        {shouldShowProgressBar && (
          <sc.ProgressBar value={progress} slow={isDeviceRestarting}>
            <div />
          </sc.ProgressBar>
        )}
        <sc.Message>{message}</sc.Message>
        {(isUpgradeComplete || isLowBattery) && (
          <sc.ContinueButton
            data-testid="continue-button"
            onClick={(): void =>
              onStepCompleted(t.FirmwareStep.UPDATE_PROGRESS, false)
            }
          >
            Continue
          </sc.ContinueButton>
        )}
      </sc.Container>
    );
  };

type ProgressBarProps = {
  slow: boolean;
  value?: number;
};

const sc = {
  Container: styled.div`
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: column;
    margin: auto;
    max-width: 424px;

    ${desktopBreakpoint(
      css`
        height: 588px;
        max-width: none;
        width: 424px;
      `
    )}
  `,

  ContinueButton: styled(Button)`
    font-size: 16px;
    margin-bottom: 60px;
    margin-top: 40px;
  `,

  Device: styled.img`
    height: 300px;
    width: 65px;

    ${desktopBreakpoint(
      css`
        height: 337px;
        margin-top: 40px;
        width: 80px;
      `
    )}
  `,

  Message: styled.div`
    ${Text.PlaaxLight}
    color: var(--black);
    font-size: 14px;
    line-height: 1.43;
    margin-top: 16px;
    text-align: center;
    width: 300px;
  `,

  ProgressBar: styled.div<ProgressBarProps>`
    background-color: var(--very-light-pink);
    border-radius: 4px;
    height: 8px;
    margin-top: 41px;
    width: 300px;

    & > div {
      background-color: var(--black);
      border-radius: ${({ value }): SimpleInterpolation =>
        !value || value < 100 ? "4px 0 0 4px" : "4px"};
      height: 8px;
      transition: width
        ${({ slow }): SimpleInterpolation => (slow ? "15s " : "0.5s")};
      width: ${({ value }): SimpleInterpolation => value || "0"}%;
    }
  `,

  Title: styled.div`
    color: var(--black);
    font-size: 24px;
    height: 34px;
    margin-top: 40px;
    text-align: center;
    text-transform: uppercase;
  `,
};
