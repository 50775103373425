import React, { useCallback, useEffect, useState } from "react";
import styled, { css } from "styled-components";

import * as PaxBle from "../../../pax-ble";

import { ConnectToDevice, SetDeviceError } from "../../device/actions";
import * as deviceConstants from "../../device/constants";
import { NullableDeviceType } from "../../device/types";
import Button from "../../shared/components/Button";
import * as Text from "../../shared/components/Text";
import { useDesktopMediaQuery } from "../../shared/hooks";
import * as text from "../../shared/text";
import { desktopBreakpoint } from "../../shared/utils";

import { InnerContainer } from "../styles";
import * as t from "../types";
import * as SC from "./StyledComponents";

type PairingInstructionsProps = t.DeviceConnectionModalContentProps & {
  connectToDevice: ConnectToDevice;
  deviceError?: string;
  deviceType: NullableDeviceType;
  isDeviceConnected: boolean;
  isDeviceConnecting: boolean;
  serial: string;
  setDeviceError: SetDeviceError;
};

export const PairingInstructions: React.FC<PairingInstructionsProps> = ({
  connectToDevice,
  deviceError,
  deviceType,
  isDeviceConnected,
  isDeviceConnecting,
  onStepCompleted,
  serial,
  setDeviceError,
}) => {
  const isDesktop = useDesktopMediaQuery();
  const [isConnectButtonDisabled, setIsConnectButtonDisabled] =
    useState<boolean>(false);

  const connect = useCallback(async (): Promise<void> => {
    const deviceOptions = deviceConstants.getConnectToDeviceOptions(
      deviceType,
      serial
    );

    setIsConnectButtonDisabled(true);

    try {
      await connectToDevice(deviceType, deviceOptions);
    } catch (e) {
      const message =
        (e as Error).message === text.DEVICE_ALREADY_CONNECTED
          ? (e as Error).message
          : text.ERROR_CONNECTING_DEVICE_TRY_AGAIN;

      setDeviceError(message);
      setIsConnectButtonDisabled(false);
    }
  }, [connectToDevice, deviceType, serial, setDeviceError]);

  useEffect(() => {
    if (isDeviceConnected) return;

    connect();
  }, [connect, isDeviceConnected]);

  useEffect(() => {
    if (isDeviceConnected) {
      onStepCompleted(t.DeviceConnectionStep.PAIRING_INSTRUCTIONS);
    }
  }, [isDeviceConnected, onStepCompleted]);

  useEffect(() => {
    if (!deviceType || deviceError) {
      onStepCompleted(t.DeviceConnectionStep.PAIRING_INSTRUCTIONS, true);
    }
  }, [deviceError, deviceType, onStepCompleted]);

  const handleBackClick = () => {
    window.location.reload();
  };

  const devicePairingAsset = deviceConstants.getDevicePairingAsset(deviceType);

  if (!isDesktop) {
    return (
      <sc.InnerContainer>
        <SC.Title>{text.PAIR_YOUR_DEVICE}</SC.Title>
        {!isDeviceConnecting && (
          <>
            <div>{text.NEED_TO_TRY_AGAIN}</div>
            <sc.ConnectButton
              disabled={isConnectButtonDisabled}
              onClick={connect}
            >
              {text.CONNECT}
            </sc.ConnectButton>
          </>
        )}
        <sc.Subtitle>
          {isDeviceConnecting ? (
            text.CONNECTING
          ) : (
            <Text.Link underline onClick={handleBackClick}>
              {text.GO_BACK}
            </Text.Link>
          )}
        </sc.Subtitle>
      </sc.InnerContainer>
    );
  }

  return (
    <sc.InnerContainer>
      <SC.Title>{text.PAIR_YOUR_DEVICE}</SC.Title>
      {devicePairingAsset && (
        <sc.DevicePairing
          src={devicePairingAsset}
          alt={text.PAX_DEVICE_PAIRING}
        />
      )}
      <sc.Subtitle>
        {isDeviceConnecting ? text.CONNECTING : text.SELECT_DEVICE_FROM_MENU}
      </sc.Subtitle>
    </sc.InnerContainer>
  );
};

type ConnectButtonProps = {
  disabled?: boolean;
};

type ShakeContainerProps = {
  deviceType: NullableDeviceType;
};

const sc = {
  ConnectButton: styled(Button)<ConnectButtonProps>`
    color: ${({ disabled }) =>
      disabled ? "var(--very-light-pink-4)" : "var(--white)"};
    margin-top: 32px;
    opacity: ${({ disabled }) => (disabled ? ".6" : "1")};
  `,

  DevicePairing: styled.img`
    max-width: 342px;
    width: 100%;

    ${desktopBreakpoint(
      css`
        width: 342px;
      `
    )}
  `,

  InnerContainer: styled(InnerContainer)`
    min-height: ${(props) => props.theme.connectionModal.minHeight};
    padding: ${(props) => props.theme.connectionModal.padding};
    width: ${(props) => props.theme.connectionModal.width};
  `,

  ShakeContainer: styled.div<ShakeContainerProps>`
    height: 246px;
    margin-top: 24px;
    width: ${({ deviceType }): string =>
      deviceType === PaxBle.Types.DeviceType.ERA_PRO ? "54px" : "90px"};
  `,

  Subtitle: styled.div`
    color: var(--black);
    font-size: 14px;
    line-height: 1.43;
    margin-top: 32px;
    max-width: 260px;
    text-align: center;
  `,
};
