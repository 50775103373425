import styled, { css, SimpleInterpolation } from "styled-components";

import { desktopBreakpoint } from "../../shared/utils";

import * as text from "../text";

type LowPodBadgeProps = {
  toRight?: boolean;
};

export const LowPodBadge = styled.div<LowPodBadgeProps>`
  background: var(--black);
  border-radius: 10px;
  height: 20px;
  width: 68px;

  ${desktopBreakpoint(
    css<LowPodBadgeProps>`
      0;
      border-radius: ${({ toRight }): SimpleInterpolation =>
        toRight ? "0 8px" : "8px 0"};
      position: absolute;
      ${({ toRight }): SimpleInterpolation =>
        toRight ? "right" : "left"}: top: 0;
    `
  )}

  &::after {
    color: var(--white);
    content: "${text.POD_LOW}";
    font-size: 11px;
    letter-spacing: 0.34px;
    line-height: 0.91;
    margin-left: 8px;
    text-align: right;
    text-transform: uppercase;
  }
`;
