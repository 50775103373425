import React, { useState } from "react";
import styled, { css } from "styled-components";

import * as consumerApi from "../../api/consumer";
import * as routes from "../../main/routes";
import { ChevronCard } from "../../shared/components/ChevronCard";
import { Star } from "../../shared/components/Icons";
import * as Text from "../../shared/components/Text";
import { useSignInUpsellModal } from "../../shared/hooks";
import * as text from "../../shared/text";
import { desktopBreakpoint } from "../../shared/utils";

import { DEFAULT_FORM_VALUES } from "../constants";
import { PodFeedbackModal } from "./PodFeedbackModal";
import { StarRating } from "./StarRating";

export type PodFeedbackAddMyReviewProps = {
  onReviewComplete: () => void;
  onReviewStarted: () => void;
  strain?: consumerApi.types.StrainJson | null;
};

export const PodFeedbackAddMyReview: React.FC<PodFeedbackAddMyReviewProps> = ({
  onReviewComplete,
  onReviewStarted,
  strain,
}) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [starRating, setStarRating] =
    useState<consumerApi.types.reviews.StrainRating>();

  const { handleShowSignInUpsell, signInUpsellModal } = useSignInUpsellModal(
    text.signInToRateThisPod(strain ? strain.name : text.THIS_POD),
    <Star $isFilled />,
    strain ? routes.podDetails(strain.id) : routes.podsHome(),
    text.SIGN_IN_TO_RATE
  );

  if (!strain) return null;

  const handleModalClose = () => {
    setIsModalOpen(false);
    onReviewComplete();
  };

  const handleModalOpen = () => {
    onReviewStarted();
    handleShowSignInUpsell();
    setIsModalOpen(true);
  };

  const handleStarChange = (
    rating: consumerApi.types.reviews.StrainRating
  ): void => {
    setStarRating(rating);
  };

  return (
    <>
      <sc.Title>{text.REVIEW}</sc.Title>
      <sc.Subtitle>{text.ADD_A_REVIEW}</sc.Subtitle>
      <sc.ChevronCard
        data-testid="review-chevron-card"
        hideChevron
        onClick={handleModalOpen}
      >
        <sc.CardTitle>{text.howDidYouLikePodName(strain.name)}</sc.CardTitle>
        <sc.StarRating
          data-testid="star-rating"
          onChange={handleStarChange}
          outlinedStars
          showZeroStars
        />
      </sc.ChevronCard>
      <PodFeedbackModal
        initialValues={{ rating: starRating, ...DEFAULT_FORM_VALUES }}
        isOpen={isModalOpen}
        onRequestClose={handleModalClose}
        strainId={strain.id}
      />
      {signInUpsellModal}
    </>
  );
};

const sc = {
  CardTitle: styled.div`
    ${Text.PlaaxBold}
    font-size: 14px;
    letter-spacing: -0.105px;
    line-height: 20px;
  `,

  ChevronCard: styled(ChevronCard)`
    align-items: normal;
    flex-direction: column;

    ${desktopBreakpoint(css`
      max-width: 400px;
    `)}
  `,

  StarRating: styled(StarRating)`
    margin-top: 16px;

    & svg {
      height: 20px;
      margin-right: 20px;
      width: 20px;
    }

    & svg path {
      stroke-width: 2px;
    }
  `,

  Subtitle: styled.div`
    ${Text.PlaaxLight}
    font-size: 14px;
    letter-spacing: -0.105px;
    line-height: 24px;
    margin-bottom: 12px;
  `,

  Title: styled.div`
    font-size: 20px;
    letter-spacing: -0.21px;
    line-height: 28px;
    margin-bottom: 8px;
  `,
};
