import Konva from "konva";
import React, { useCallback, useEffect, useState } from "react";
import { Circle, Layer, Line, Rect, Stage } from "react-konva";
import { useSelector } from "react-redux";
import styled, { SimpleInterpolation } from "styled-components";

import { PreferredTemperatureUnit } from "../../../api/consumer/types/user";
import { MainAppState } from "../../../main/types";

type Point = {
  x: number;
  y: number;
};

const WIDTH = 772;
const HEIGHT = 134;

// The knob.
const KNOB_X_MIN = 24;
const KNOB_X_DISABLED = KNOB_X_MIN + 55;
const KNOB_X_MAX = WIDTH - 24;
const KNOB_Y = HEIGHT - 28;

// The temperature bar.
const BAR_X = 22;
const BAR_Y = KNOB_Y - 4;
const BAR_WIDTH = 726;
const BAR_HEIGHT = 8;
// Colors are hard-coded; Konva can't consume CSS variables.
const BAR_GRADIENT_START_COLOR = "#fcde4a"; // var(--maize)
const BAR_GRADIENT_END_COLOR = "#fc8773"; // var(--salmon)
const BAR_GRADIENT_START_COLOR_DISABLED = "#4b4f54"; // var(--gunmetal)
const BAR_GRADIENT_END_COLOR_DISABLED = "#d1d1d1"; // var(--very-light-pink-6)

// The ticker lines.
const LINE_LENGTH = { LONG: 40, SHORT: 20 };
const LINE_SPACING = 5;
const LINE_PROPS = {
  stroke: "#a0a4a9", // var(--cool-grey)
  strokeWidth: 1,
};
const LINE_X_START = BAR_X + 0.5;
const LINE_Y_START = BAR_Y - 2;
const LINE_OFFSET_THRESHOLD = 50;

// The background gradient.
const BACKGROUND_X = BAR_X - 5;
const BACKGROUND_WIDTH = BAR_WIDTH + 10;

export type ThermostatProps = {
  deviceTemperature?: number;
  tempRange: number[];
  isDisabled?: boolean;
  idealFlavorTemperature?: number | null;
  expertTemperature?: number | null;
  idealVaporTemperature?: number | null;
  onChange?: (temperature: number, unit: PreferredTemperatureUnit) => void;
  onDrag?: (temperature: number) => void;
  temperatureUnit: PreferredTemperatureUnit;
};

export const Thermostat: React.FC<ThermostatProps> = ({
  deviceTemperature,
  tempRange,
  isDisabled,
  idealFlavorTemperature,
  expertTemperature,
  idealVaporTemperature,
  onChange,
  onDrag,
  temperatureUnit,
}) => {
  const isModalOpen = useSelector((state: MainAppState) => state.modal.isOpen);
  const [tempMin, tempMax] = tempRange;

  const getTemperature = (x: number): number => {
    const knobPercentage = (x - KNOB_X_MIN) / (KNOB_X_MAX - KNOB_X_MIN);
    const temperature = tempMin + knobPercentage * (tempMax - tempMin);

    return temperature;
  };

  // Memoize getKnobX to avoid triggering the effect below on each render.
  const getKnobX = useCallback(
    (temperature: number): number => {
      let knobX =
        KNOB_X_MIN +
        ((temperature - tempMin) / (tempMax - tempMin)) *
          (KNOB_X_MAX - KNOB_X_MIN);

      // Account for temperatures outside expected range, possibly set by mobile or due to rounding.
      knobX = Math.min(KNOB_X_MAX, knobX);
      knobX = Math.max(KNOB_X_MIN, knobX);

      return knobX;
    },
    [tempMin, tempMax]
  );

  const [desiredTemperature, setDesiredTemperature] = useState<number>(
    deviceTemperature || tempMin
  );
  const [knobX, setKnobX] = useState(desiredTemperature);
  const [isKeyDown, setIsKeyDown] = useState<boolean>(false);

  useEffect(() => {
    if (isDisabled) {
      setKnobX(KNOB_X_DISABLED);
    } else if (deviceTemperature) {
      setKnobX(getKnobX(deviceTemperature));
      setDesiredTemperature(deviceTemperature);
    }
  }, [deviceTemperature, getKnobX, isDisabled, setDesiredTemperature]);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (
        isModalOpen ||
        !(event.key === "ArrowLeft" || event.key === "ArrowRight") ||
        !deviceTemperature ||
        isKeyDown
      ) {
        return;
      }

      setIsKeyDown(true);

      const currentTemperature = Math.round(deviceTemperature);
      let newDesiredTemperature = Math.round(desiredTemperature);

      if (event.key === "ArrowLeft") {
        if (desiredTemperature <= tempMin) return;

        newDesiredTemperature -= 1;
      } else {
        if (desiredTemperature >= tempMax) return;

        newDesiredTemperature += 1;
      }

      setDesiredTemperature(newDesiredTemperature);
      setKnobX(getKnobX(newDesiredTemperature));
      if (onDrag) onDrag(newDesiredTemperature);

      // Due to rounding cutoff from UI being in °F and device storing
      // temperature in °C, only send new temperature to device if
      // delta is more than 2°F.
      if (
        onChange &&
        Math.abs(newDesiredTemperature - currentTemperature) >= 2
      ) {
        onChange(newDesiredTemperature, temperatureUnit);
      }
    },
    [
      desiredTemperature,
      deviceTemperature,
      tempMax,
      tempMin,
      getKnobX,
      isKeyDown,
      isModalOpen,
      onChange,
      onDrag,
      temperatureUnit,
    ]
  );

  const handleKeyUp = (): void => {
    setIsKeyDown(false);
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);

    return (): void => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, [handleKeyDown]);

  const handleKnobChange = (point: Point): Point => {
    let newKnobX = point.x;
    newKnobX = Math.max(KNOB_X_MIN, newKnobX);
    newKnobX = Math.min(KNOB_X_MAX, newKnobX);

    setKnobX(newKnobX);

    if (onDrag) {
      const temperature = getTemperature(newKnobX);

      onDrag(temperature);
    }

    return { x: newKnobX, y: KNOB_Y };
  };

  const handleKnobDragEnd = (): void => {
    if (onChange) {
      const temperature = getTemperature(knobX);

      onChange(temperature, temperatureUnit);
    }
  };

  const handleTemperatureBarClick = (
    e: Konva.KonvaEventObject<MouseEvent>
  ): void => {
    if (isDisabled) return;

    const newKnobX = e.evt.offsetX;

    handleKnobChange({ x: newKnobX, y: KNOB_Y });
    if (onChange) {
      const temperature = getTemperature(newKnobX);

      onChange(temperature, temperatureUnit);
    }
  };

  const handleTemperatureBarTap = (
    e: Konva.KonvaEventObject<TouchEvent>
  ): void => {
    if (isDisabled || !onChange) return;

    const target = e.evt.target as HTMLElement;
    const touchX = e.evt.targetTouches[0]?.pageX;

    if (!target || !touchX) return;

    const offsetX = touchX - target.getBoundingClientRect().left;

    const temperature = getTemperature(offsetX);

    onChange(temperature, temperatureUnit);
  };

  function handleKonvaNodeMouseEnter(this: Konva.Circle | Konva.Rect): void {
    if (isDisabled) return;
    setKonvaNodeCursor(this, "pointer");
  }

  function handleKonvaNodeMouseLeave(this: Konva.Circle | Konva.Rect): void {
    if (isDisabled) return;
    setKonvaNodeCursor(this, "");
  }

  function setKonvaNodeCursor(
    knob: Konva.Circle | Konva.Rect,
    cursor: string
  ): void {
    const stage = knob.getStage();
    if (!stage) return;
    stage.container().style.cursor = cursor;
  }

  // Draw 29 groups of 5 thermostat lines, with each first line drawn taller.
  const lines = [];
  let lineX = LINE_X_START;
  let lineY1 = LINE_Y_START;

  // Lines in this range will be offset along a parabolic curve.
  const lineOffsetRange = {
    end: knobX + LINE_OFFSET_THRESHOLD,
    start: knobX - LINE_OFFSET_THRESHOLD,
  };

  for (let i = 1; i <= 29; i++) {
    for (let j = 1; j <= 5; j++) {
      lineY1 = LINE_Y_START;

      // Offset the line's y points when close to the knob.
      if (lineX >= lineOffsetRange.start && lineX <= lineOffsetRange.end) {
        // Draw a parabola around the knob to calculate the offset.
        const parabolaX = knobX - lineX;
        const parabolaY =
          1 / (0.0085 * Math.log(0.0003 * Math.pow(parabolaX, 4) + 25)) - 15;

        lineY1 -= parabolaY;
      }

      const lineLength = j === 1 ? LINE_LENGTH.LONG : LINE_LENGTH.SHORT;
      const lineY2 = lineY1 - lineLength;
      const linePoints = [lineX, lineY1, lineX, lineY2];

      lines.push(
        <Line key={`line-${i}-${j}`} points={linePoints} {...LINE_PROPS} />
      );

      lineX += LINE_SPACING;
    }
  }

  const lastLinePoints = [lineX, lineY1, lineX, lineY1 - LINE_LENGTH.LONG];
  lines.push(<Line key={"lastLine"} points={lastLinePoints} {...LINE_PROPS} />);

  // Invisible rectangle over lines to allow for a
  // click event across the entire width of lines
  const lineClickArea = (
    <Rect
      onClick={handleTemperatureBarClick}
      onMouseEnter={handleKonvaNodeMouseEnter}
      onMouseLeave={handleKonvaNodeMouseLeave}
      onTouchStart={handleTemperatureBarTap}
      x={BAR_X}
      y={BAR_Y - LINE_LENGTH.LONG}
      width={BAR_WIDTH}
      height={LINE_LENGTH.LONG}
    />
  );

  // Draw temperature bar.
  const temperatureBar = (
    <Rect
      cornerRadius={100}
      fillLinearGradientStartPoint={{ x: 0, y: BAR_HEIGHT }}
      fillLinearGradientEndPoint={{ x: BAR_WIDTH, y: BAR_HEIGHT }}
      fillLinearGradientColorStops={[
        0,
        isDisabled
          ? BAR_GRADIENT_START_COLOR_DISABLED
          : BAR_GRADIENT_START_COLOR,
        1,
        isDisabled ? BAR_GRADIENT_END_COLOR_DISABLED : BAR_GRADIENT_END_COLOR,
      ]}
      onClick={handleTemperatureBarClick}
      onMouseEnter={handleKonvaNodeMouseEnter}
      onMouseLeave={handleKonvaNodeMouseLeave}
      onTouchStart={handleTemperatureBarTap}
      x={BAR_X}
      y={BAR_Y}
      width={BAR_WIDTH}
      height={BAR_HEIGHT}
    />
  );

  // Draw the knob.
  const knobOuter = (
    <Circle
      dragBoundFunc={handleKnobChange}
      draggable={!isDisabled}
      fill="#fff"
      onDragEnd={handleKnobDragEnd}
      onMouseEnter={handleKonvaNodeMouseEnter}
      onMouseLeave={handleKonvaNodeMouseLeave}
      radius={22}
      shadowBlur={4}
      shadowColor="#b0b1be"
      shadowOffsetY={1}
      shadowOpacity={0.5}
      x={knobX}
      y={KNOB_Y}
    />
  );

  const knobInner = (
    <Circle
      dragBoundFunc={handleKnobChange}
      draggable={!isDisabled}
      fillRadialGradientColorStops={[
        0,
        "#fff",
        0.68,
        "rgba(240, 241, 248, 0.6)",
      ]}
      fillRadialGradientEndRadius={18}
      fillRadialGradientStartRadius={0}
      onDragEnd={handleKnobDragEnd}
      onMouseEnter={handleKonvaNodeMouseEnter}
      onMouseLeave={handleKonvaNodeMouseLeave}
      radius={18}
      x={knobX}
      y={KNOB_Y}
    />
  );

  const idealFlavor = idealFlavorTemperature ? (
    <Circle
      draggable={false}
      fill="#000"
      onClick={handleTemperatureBarClick}
      onMouseEnter={handleKonvaNodeMouseEnter}
      onMouseLeave={handleKonvaNodeMouseLeave}
      onTouchStart={handleTemperatureBarTap}
      radius={3}
      x={getKnobX(idealFlavorTemperature)}
      y={KNOB_Y}
    />
  ) : null;

  const expertTemp = expertTemperature ? (
    <Circle
      draggable={false}
      fill="#000"
      onClick={handleTemperatureBarClick}
      onMouseEnter={handleKonvaNodeMouseEnter}
      onMouseLeave={handleKonvaNodeMouseLeave}
      onTouchStart={handleTemperatureBarTap}
      radius={3}
      x={getKnobX(expertTemperature)}
      y={KNOB_Y}
    />
  ) : null;

  const idealVapor = idealVaporTemperature ? (
    <Circle
      draggable={false}
      fill="#000"
      onClick={handleTemperatureBarClick}
      onMouseEnter={handleKonvaNodeMouseEnter}
      onMouseLeave={handleKonvaNodeMouseLeave}
      onTouchStart={handleTemperatureBarTap}
      radius={3}
      x={getKnobX(idealVaporTemperature)}
      y={KNOB_Y}
    />
  ) : null;

  return (
    <sc.Container data-testid="thermostat">
      {!isDisabled && <sc.Background left={BACKGROUND_X} />}
      <sc.Stage width={WIDTH} height={HEIGHT} isDisabled={isDisabled}>
        <Layer>
          {lines}
          {lineClickArea}
          {temperatureBar}
          {idealFlavor}
          {expertTemp}
          {idealVapor}
          {knobOuter}
          {knobInner}
        </Layer>
      </sc.Stage>
    </sc.Container>
  );
};

interface BackgroundProps {
  readonly left: number;
}

interface StageProps {
  readonly isDisabled: boolean;
}

const sc = {
  Background: styled.div<BackgroundProps>`
    background-image: linear-gradient(
      to right,
      ${BAR_GRADIENT_START_COLOR} 12%,
      ${BAR_GRADIENT_END_COLOR} 89%
    );
    border-radius: 41px;
    filter: blur(30px);
    height: 68px;
    left: ${(props): SimpleInterpolation => props.left}px;
    opacity: 0.2;
    position: absolute;
    top: 40px;
    width: ${BACKGROUND_WIDTH}px;
  `,

  Container: styled.div`
    position: relative;
  `,

  Stage: styled(Stage)<StageProps>`
    ${({ isDisabled }): SimpleInterpolation =>
      isDisabled ? "opacity: 0.5;" : ""}
  `,
};
