import { get as lsGet } from "local-storage";

import { Action } from "../shared/types";
import * as c from "./constants";
import * as t from "./types";

const attributes: { [key: string]: unknown } = {};

const initialState: t.DeviceState = {
  attributes,
  connected: false,
  connecting: false,
  error: undefined,
  firmwareRevision: "",
  hasEncryptionErrors: false,
  isSyncing: false,
  k3Serial: lsGet<string>(c.LS_KEY_K3_SERIAL) || "",
  k4Serial: lsGet<string>(c.LS_KEY_K4_SERIAL) || "",
  pax3Serial: lsGet<string>(c.LS_KEY_P3_SERIAL) || "",
  pod: {
    autoBatchId: null,
    autoTestReport: null,
    hasTestReport: false,
    isAutoId: false,
    strainId: null,
  },
  requested: false,
  selectedDeviceType: null,
  supportedAttributes: {},
};

export const getInitialState = (
  overrides?: Partial<t.DeviceState>
): t.DeviceState => {
  return { ...initialState, ...overrides };
};

export const reducer = (
  state: t.DeviceState = initialState,
  action: Action
): t.DeviceState => {
  const { payload } = action;

  switch (action.type) {
    case c.SELECT_DEVICE_TYPE:
      return {
        ...state,
        error: initialState.error,
        selectedDeviceType: action.payload.deviceType,
      };

    case c.DEVICE_REQUESTED:
      return { ...state, requested: true };

    case c.DEVICE_CONNECTING:
      return { ...state, connecting: true, requested: false };

    case c.DEVICE_CONNECTED:
      return {
        ...state,
        connected: true,
        connecting: false,
        error: initialState.error,
        firmwareRevision: payload.firmwareRevision,
        // Update or maintain existing serial numbers.
        k3Serial: payload.deviceSerial?.k3Serial || state.k3Serial,
        k4Serial: payload.deviceSerial?.k4Serial || state.k4Serial,
        pax3Serial: payload.deviceSerial?.pax3Serial || state.pax3Serial,
        pod: {
          ...initialState.pod,
          strainId: payload.podStrainId,
        },
      };

    case c.DEVICE_DISCONNECTED:
      const { error } = action.payload;

      const newState = {
        ...initialState,
        connected: false,
        error,
        isSyncing: false,
        // Maintain serial numbers to avoid stale value in initialState.
        k3Serial: state.k3Serial,
        k4Serial: state.k4Serial,
        pax3Serial: state.pax3Serial,
        selectedDeviceType: error ? state.selectedDeviceType : null,
      };

      return newState;

    case c.DEVICE_RESTARTING:
      return { ...state, connected: false };

    case c.DEVICE_ERROR:
      return { ...state, error: action.payload.error };

    case c.RECEIVED_ATTRIBUTE:
      const { attributeId, value } = payload;
      const attributeName = c.getAttributeName(attributeId);

      // Handle SUPPORTED_ATTRIBUTES attribute.
      if (attributeName === c.ATTRIBUTE_SUPPORTED_ATTRIBUTES) {
        const binaryDigits: string = value;
        const supportedAttributes: { [key: number]: string } = {};

        for (let i = 0; i < binaryDigits.length; i++) {
          if (binaryDigits[i] === "0") continue;

          const supportedAttributeName = c.getAttributeName(i + 1);
          supportedAttributes[i + 1] = supportedAttributeName || `${i + 1}`;
        }

        return {
          ...state,
          supportedAttributes,
        };
      }

      return {
        ...state,
        attributes: {
          ...state.attributes,
          [attributeName]: value,
        },
      };

    case c.RECEIVED_FIRMWARE_REVISION:
      const { firmwareRevision } = payload;

      return { ...state, firmwareRevision };

    case c.SET_MANUAL_ID_POD:
      return {
        ...state,
        pod: {
          ...initialState.pod,
          strainId: payload.strainId,
        },
      };

    case c.RECEIVED_NFC_POD_DATA:
      return {
        ...state,
        pod: {
          ...initialState.pod,
          isAutoId: true,
          strainId: payload.productId,
        },
      };

    case c.CLEAR_CURRENT_POD:
      return {
        ...state,
        pod: { ...initialState.pod },
      };

    case c.SET_K3_SERIAL:
      const { k3Serial } = payload;

      return { ...state, k3Serial };

    case c.SET_K4_SERIAL:
      const { k4Serial } = payload;

      return { ...state, k4Serial };

    case c.SET_PAX_3_SERIAL:
      const { pax3Serial } = payload;

      return { ...state, pax3Serial };

    case c.RECEIVED_BATCH_RESULT:
      const { batchId, parsedBatchResult } = payload.response;
      const hasTestReport = !!parsedBatchResult;

      return {
        ...state,
        pod: {
          ...state.pod,
          autoBatchId: batchId,
          autoTestReport: parsedBatchResult,
          hasTestReport,
        },
      };

    case c.SET_SYNCING_STATUS:
      const { isSyncing } = payload;

      return { ...state, isSyncing };

    case c.SET_HAS_ENCRYPTION_ERRORS:
      const { hasEncryptionErrors } = payload;

      return { ...state, hasEncryptionErrors };

    default:
      return state;
  }
};
