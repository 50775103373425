export const BASE_URL = "https://maps.googleapis.com/maps/api";

export enum GOOGLE_API {
  GEOCODING = "geocode/json",
  STATIC_MAP = "staticmap",
}

export enum MAP_ZOOM_LEVEL {
  // Google Maps Static API Zoom Levels: https://developers.google.com/maps/documentation/maps-static/start?hl=en_US#Zoomlevels
  WORLD = "1",
  CONTINENT = "5",
  CITY = "10",
  STREET = "15",
  BUILDING = "20",
}
