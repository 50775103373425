import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled, { css, SimpleInterpolation } from "styled-components";

import * as consumerApi from "../../api/consumer";
import { DeviceLogo } from "../../my-pax/components/DeviceLogo";
import { Row } from "../../shared/components/Flex";
import * as Icons from "../../shared/components/Icons";
import * as Text from "../../shared/components/Text";
import { Toggle } from "../../shared/components/Toggle";
import * as text from "../../shared/text";
import * as utils from "../../shared/utils";
import { desktopBreakpoint, smallScreenBreakpoint } from "../../shared/utils";

import * as s from "../selectors";
import { DisableFindMyPaxModal } from "./DisableFindMyPaxModal";

type FindMyPaxInfoProps = {
  address?: string;
  device: consumerApi.types.device.DeviceJson;
};

export const FindMyPaxInfo: React.FC<FindMyPaxInfoProps> = ({
  address,
  device,
}) => {
  const dispatch = useDispatch();
  const isRequestingLocation = useSelector(s.getIsRequestingLocation);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [locationSharingFlag, setLocationSharingFlag] = useState<boolean>(
    !!device.dataSharingPreferences?.shareLocationData
  );
  const lastSaved = device.location
    ? [text.LAST_SAVED, utils.getLastUsed(device.location.setAt)].join(" ")
    : text.LOCATION_IS_SAVED_ONLY_WHEN;

  useEffect(() => {
    setLocationSharingFlag(!!device.dataSharingPreferences?.shareLocationData);
  }, [device]);

  const handleLocationSharingToggle = (flag: boolean): void => {
    if (!flag) {
      setIsModalOpen(true);
      return;
    }

    toggleLocationSharing(flag);
  };

  const toggleLocationSharing = (flag: boolean): void => {
    setLocationSharingFlag(flag);

    dispatch(
      consumerApi.actions.devices.setShareLocationDataFlag(flag, device)
    );
  };

  return (
    <>
      <sc.Container>
        <Info
          Icon={
            <sc.DeviceLogo data-testid="small-device-logo" device={device} />
          }
          subtitle={locationSharingFlag ? text.ENABLED : text.NOT_ENABLED}
          title={device.deviceName}
        />
        <Info
          Icon={<Icons.Location $isBold={locationSharingFlag} />}
          subtitle={
            !locationSharingFlag
              ? text.NOT_AVAILABLE
              : isRequestingLocation
              ? text.LOOKING_FOR_LOCATION
              : lastSaved
          }
          title={address ? text.nearAddress(address) : text.LOCATION}
        />
        <Info
          Icon={<Icons.SavedFrom $isBold={locationSharingFlag} />}
          subtitle={
            !locationSharingFlag
              ? text.NOT_AVAILABLE
              : isRequestingLocation
              ? text.WEB
              : utils.capitalize(device.location?.appType || text.NOT_AVAILABLE)
          }
          title={isRequestingLocation ? text.SAVING_FROM : text.SAVED_FROM}
        />
        <sc.Divider />
        <sc.ToggleContainer>
          <sc.ToggleText muted={locationSharingFlag}>
            {text.PAXFINDER_SAVES_LOCATION_EVERY}
          </sc.ToggleText>
          <sc.Toggle
            active={locationSharingFlag}
            onClick={(): void =>
              handleLocationSharingToggle(!locationSharingFlag)
            }
          />
        </sc.ToggleContainer>
      </sc.Container>
      <DisableFindMyPaxModal
        disableFindMyPax={(): void => toggleLocationSharing(false)}
        isOpen={isModalOpen}
        onRequestClose={(): void => setIsModalOpen(false)}
      />
    </>
  );
};

type InfoProps = {
  Icon: React.ReactNode;
  subtitle: string;
  title: string;
};

const Info: React.FC<InfoProps> = ({ Icon, subtitle, title }) => {
  return (
    <sc.InfoContainer>
      <sc.IconContainer>{Icon}</sc.IconContainer>
      <sc.Info>
        <sc.InfoTitle title={title}>{title}</sc.InfoTitle>
        <sc.InfoSubtitle>{subtitle}</sc.InfoSubtitle>
      </sc.Info>
    </sc.InfoContainer>
  );
};

type ToggleTextProps = {
  muted: boolean;
};

const ERA_PRO_STYLES = css`
  background-position: 12px 3px;
  background-size: 11px;
`;

const ERA_STYLES = css`
  background-position: 9.25px 4px;
  background-size: 16px;
`;

const PAX_3_STYLES = css`
  background-position: 11px 5px;
  background-size: 12px;
`;

const sc = {
  Container: styled.div`
    border: solid 1px var(--very-light-pink-6);
    border-radius: 8px;
    padding: 20px 20px 4px;
    position: relative;
    width: 100%;

    ${desktopBreakpoint(
      css`
        width: 400px;
      `
    )}
  `,

  DeviceLogo: styled(DeviceLogo)`
    height: 36px;
    margin: 0;
    width: 36px;

    ${({ device }): SimpleInterpolation => {
      if (device.model === consumerApi.types.device.DeviceModel.ERA_PRO) {
        return ERA_PRO_STYLES;
      }

      if (device.model === consumerApi.types.device.DeviceModel.ERA) {
        return ERA_STYLES;
      }

      return PAX_3_STYLES;
    }}
  `,

  Divider: styled.hr`
    border: solid 1px var(--very-light-pink-6);
    margin: 0 -20px;
    width: calc(100% + 39px);
  `,

  IconContainer: styled.div`
    flex: 0 0 36px;
  `,

  Info: styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 12px;
  `,

  InfoContainer: styled.div`
    align-items: center;
    display: flex;
    margin-bottom: 28px;
  `,

  InfoSubtitle: styled.div`
    ${Text.PlaaxLight}
    color: var(--brown-grey);
    font-size: 16px;
  `,

  InfoTitle: styled.div`
    ${Text.PlaaxLight}
    font-size: 16px;
    overflow: hidden;
    padding-bottom: 2px;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 260px;

    ${smallScreenBreakpoint(css`
      width: 200px;
    `)}

    ${desktopBreakpoint(
      css`
        width: 296px;
      `
    )}
  `,

  Toggle: styled(Toggle)`
    flex: 0 0 40px;
    margin-left: 24px;
  `,

  ToggleContainer: styled(Row)`
    align-items: center;
    margin: 8px 0;
  `,

  ToggleText: styled.div<ToggleTextProps>`
    ${Text.PlaaxLight}
    color: ${({ muted }): SimpleInterpolation =>
      muted ? "var(--brown-grey)" : "var(--black)"};
    font-size: 14px;
    line-height: 1.25;
    width: 304px;
  `,
};
