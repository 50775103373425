import React from "react";

import { Row } from "../../shared/components/Flex";
import { StickyHeader } from "../../shared/components/StickyHeader";
import { useDesktopMediaQuery } from "../../shared/hooks";

import { DESKTOP_SEARCH_HEADER_HEIGHT } from "../constants";
import PodsActiveFilters from "../containers/PodsActiveFilters";
import * as t from "../types";
import { PodsExploreHeader, QueryFieldContainer } from "./PodsExploreHeader";
import { PodsSearchQueryField } from "./PodsSearchQueryField";

type PodsSearchPageHeaderProps = {
  isClearable?: boolean;
  onPodSearchQuerySet: t.OnPodSearchQuerySet;
  onStrainsFilterSet: t.OnStrainsFilterSet;
  shouldShowActiveFilters?: boolean;
  shouldShowSearchDropdown?: boolean;
  strainsFilter: t.StrainsFilter;
};

export const PodsSearchPageHeader: React.FC<PodsSearchPageHeaderProps> = ({
  isClearable = false,
  onPodSearchQuerySet,
  onStrainsFilterSet,
  shouldShowActiveFilters = false,
  shouldShowSearchDropdown = false,
  strainsFilter,
}) => {
  const isDesktop = useDesktopMediaQuery();

  const renderDesktopHeaderContent = (): React.ReactElement => {
    return (
      <Row alignItems="center">
        <QueryFieldContainer>
          <PodsSearchQueryField
            initialQuery={strainsFilter.query}
            isClearable={isClearable}
            setQuery={onPodSearchQuerySet}
            shouldShowSearchDropdown={shouldShowSearchDropdown}
          />
        </QueryFieldContainer>
      </Row>
    );
  };

  const renderMobileHeaderContent = (): React.ReactElement => (
    <PodsSearchQueryField
      initialQuery={strainsFilter.query}
      isClearable={isClearable}
      podsActiveFilters={activeFilters}
      setQuery={onPodSearchQuerySet}
      shouldDisableStickyHeader={!shouldShowSearchDropdown}
    />
  );

  const activeFilters = shouldShowActiveFilters && (
    <PodsActiveFilters
      setFilter={onStrainsFilterSet}
      strainsFilter={strainsFilter}
    />
  );

  return isDesktop ? (
    <StickyHeader containerHeight={DESKTOP_SEARCH_HEADER_HEIGHT}>
      <PodsExploreHeader
        renderHeaderContent={renderDesktopHeaderContent}
        podsActiveFilters={activeFilters}
        shouldShowTitle={isDesktop}
      />
    </StickyHeader>
  ) : (
    <PodsExploreHeader
      renderHeaderContent={renderMobileHeaderContent}
      shouldShowTitle={isDesktop}
    />
  );
};
