import React from "react";

import { ParsedTestResultJson } from "../../../api/consumer/types/strain";

import PodTestResultsCardNoReport from "./PodTestResultsCardNoReport";
import PodTestResultsCardWithReport from "./PodTestResultsCardWithReport";

export type PodAutoTestResultsCardProps = {
  podId?: string;
  testReport?: ParsedTestResultJson | null;
};

const PodAutoTestResultsCard: React.FC<PodAutoTestResultsCardProps> = ({
  podId,
  testReport,
}) => {
  if (testReport) {
    return (
      <PodTestResultsCardWithReport
        parsedTestResults={testReport}
        podId={podId}
      />
    );
  }

  return <PodTestResultsCardNoReport />;
};

export default PodAutoTestResultsCard;
