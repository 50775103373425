import React from "react";
import styled from "styled-components";

import * as text from "../text";
import { Banner } from "./Banner";

export const BetaAppBanner: React.FC = () => {
  return (
    <sc.Banner data-testid="beta-app-banner">
      {text.YOURE_USING_A_BETA_FIRMWARE}
    </sc.Banner>
  );
};

const sc = {
  Banner: styled(Banner)`
    & a {
      color: var(--white);
      font-weight: 600;
    }
  `,
};
