import React from "react";
import styled, { keyframes } from "styled-components";

import * as PaxBle from "../../../pax-ble";

import { NullableDeviceType } from "../../device/types";
import { isPax3 } from "../../device/utils";
import Button from "../../shared/components/Button";
import * as DeviceImages from "../../shared/components/DeviceImages";
import * as text from "../../shared/text";

import { InnerContainer } from "../styles";
import * as t from "../types";
import * as SC from "./StyledComponents";

type DevicePairingAssets = {
  device: string;
  lights1: string;
  lights2: string;
};

type PairingAssets = {
  [PaxBle.Types.DeviceType.ERA]: DevicePairingAssets;
  [PaxBle.Types.DeviceType.ERA_PRO]: DevicePairingAssets;
  [PaxBle.Types.DeviceType.PAX_3]: DevicePairingAssets;
  [PaxBle.Types.DeviceType.PAX_35]: DevicePairingAssets;
};

const pairingAssets: PairingAssets = {
  ERA: {
    device: DeviceImages.Era,
    lights1: DeviceImages.EraBT1,
    lights2: DeviceImages.EraBT2,
  },
  ERA_PRO: {
    device: DeviceImages.EraProSilver,
    lights1: DeviceImages.EraProSilverBT1,
    lights2: DeviceImages.EraProSilverBT2,
  },
  PAX_3: {
    device: DeviceImages.Pax3Pairing,
    lights1: DeviceImages.Pax3BT1,
    lights2: DeviceImages.Pax3BT2,
  },
  PAX_35: {
    device: DeviceImages.Pax3Pairing,
    lights1: DeviceImages.Pax3BT1,
    lights2: DeviceImages.Pax3BT2,
  },
};

type ShakeInstructionsProps = t.DeviceConnectionModalContentProps & {
  deviceType: NullableDeviceType;
  serial: string;
};

export const ShakeInstructions: React.FC<ShakeInstructionsProps> = ({
  deviceType,
  onStepCompleted,
}) => {
  const handleConnectClick = async (): Promise<void> => {
    onStepCompleted(t.DeviceConnectionStep.SHAKE_INSTRUCTIONS);
  };

  if (!deviceType) {
    onStepCompleted(t.DeviceConnectionStep.SHAKE_INSTRUCTIONS, true);
    return null;
  }

  const deviceAssets: DevicePairingAssets = pairingAssets[deviceType];

  return (
    <sc.InnerContainer>
      <SC.Title>{text.SHAKE_TO_PAIR}</SC.Title>
      <sc.ShakeContainer
        data-testid="shake-to-pair-asset"
        deviceType={deviceType}
      >
        <sc.DevicePair src={deviceAssets.device} alt="PAX device pairing" />
        <sc.DevicePairingLights1
          src={deviceAssets.lights1}
          alt="PAX device pairing lights 1"
        />
        <sc.DevicePairingLights2
          src={deviceAssets.lights2}
          alt="PAX device pairing lights 2"
        />
      </sc.ShakeContainer>
      {isPax3(deviceType) && <sc.Subtitle>{text.TURN_ON_PAX3}</sc.Subtitle>}
      <sc.Subtitle>{text.KEEP_SHAKING_DEVICE}</sc.Subtitle>
      <sc.ConnectButton
        data-testid="connect-button"
        onClick={handleConnectClick}
      >
        {text.CONNECT}
      </sc.ConnectButton>
    </sc.InnerContainer>
  );
};

const shakeToPair = {
  device: keyframes`
    0% {
      transform: rotate(-30deg);
    }
    50% {
      transform: rotate(30deg);
    }
    100% {
      transform: rotate(-30deg);
    }
  `,
  lights1: keyframes`
    0% {
      transform: rotate(-30deg);
      opacity: 1;
    }
    50% {
      transform: rotate(30deg);
      opacity: 0;
    }
    100% {
      transform: rotate(-30deg);
      opacity: 1;
    }
  `,
  lights2: keyframes`
    0% {
      transform: rotate(-30deg);
      opacity: 0;
    }
    50% {
      transform: rotate(30deg);
      opacity: 1;
    }
    100% {
      transform: rotate(-30deg);
      opacity: 0;
    }
  `,
};

type ShakeContainerProps = {
  deviceType: NullableDeviceType;
};

const sc = {
  ConnectButton: styled(Button)`
    margin-top: 32px;
  `,

  DevicePair: styled.img`
    animation: ${shakeToPair.device} 1s infinite;
    height: ${(props) =>
      props.theme.connectionModal.deviceShakingAnimationHeight};
    position: absolute;
    transform-origin: bottom center;
    z-index: 0;
  `,

  DevicePairingLights1: styled.img`
    animation: ${shakeToPair.lights1} 1s infinite;
    height: ${(props) =>
      props.theme.connectionModal.deviceShakingAnimationHeight};
    position: absolute;
    transform-origin: bottom center;
    z-index: 1;
  `,

  DevicePairingLights2: styled.img`
    animation: ${shakeToPair.lights2} 1s infinite;
    height: ${(props) =>
      props.theme.connectionModal.deviceShakingAnimationHeight};
    position: absolute;
    transform-origin: bottom center;
    z-index: 1;
  `,

  InnerContainer: styled(InnerContainer)`
    min-height: ${(props) => props.theme.connectionModal.minHeight};
    padding: ${(props) => props.theme.connectionModal.padding};
    width: ${(props) => props.theme.connectionModal.width};
  `,

  ShakeContainer: styled.div<ShakeContainerProps>`
    display: flex;
    justify-content: center;
    min-height: ${(props) =>
      props.theme.connectionModal.deviceShakingAnimationHeight};
    position: relative;
    width: ${({ deviceType }): string =>
      deviceType === PaxBle.Types.DeviceType.ERA_PRO ? "54px" : "90px"};
  `,

  Subtitle: styled.div`
    color: var(--black);
    font-size: 14px;
    line-height: 1.43;
    margin-top: 32px;
    max-width: 260px;
    text-align: center;
  `,
};
