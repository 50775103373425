import { connect } from "react-redux";

import { navigator } from "../../../navigator";

import * as deviceSelectors from "../../api/consumer/selectors/devices";
import { disconnectDevice } from "../../device/actions";
import * as firmwareSelectors from "../../firmware/selectors";
import { MainAppState } from "../../main/types";
import { openFirmwareModal } from "../../modal/actions";
import * as modalSelectors from "../../modal/selectors";
import { showFlashBanner } from "../../shared/components/FlashBanner";

import * as myDevices from "../components/MyDevices";

const mapStateToProps = (state: MainAppState): myDevices.StateProps => ({
  devices: deviceSelectors.getOrderedAccountDevices(state),
  isFirmwareModalOpen: modalSelectors.getIsFirmwareModalOpen(state),
  isNewerFirmwareAvailable:
    firmwareSelectors.getIsNewerFirmwareAvailable(state),
  supportsBluetooth: !!navigator.bluetooth,
});

const mapDispatchToProps: myDevices.DispatchProps = {
  disconnectDevice,
  openFirmwareModal,
  showFlashBanner,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(myDevices.MyDevices);
