import React, { useEffect, useState } from "react";

import * as consumerApi from "../../api/consumer";
import { ErrorCode, UseFetch } from "../../api/consumer/types";
import * as r from "../../main/routes";
import * as sharedHooks from "../../shared/hooks";
import { SearchParam } from "../../shared/types";

import { VerifyEmail } from "../actions";
import { SSO_CONFIRMATION_TIMEOUT } from "../constants";
import { pendingEmailVerificationRequests } from "../paths";
import * as text from "../text";
import * as t from "../types";
import * as utils from "../utils";
import { SsoChrome } from "./SsoChrome";
import * as SsoForm from "./SsoForm";

export type SsoVerifyEmailPageProps = t.SsoProps & {
  failureCode?: ErrorCode;
  ssoRedirect?: t.SsoRedirect;
  success?: boolean;
  useFetch: UseFetch<t.CreatedEmailVerificationRequest>;
  useSearchParam: sharedHooks.UseSearchParam;
  verifyEmail: VerifyEmail;
};

export const SsoVerifyEmailPage: React.FC<SsoVerifyEmailPageProps> = ({
  failureCode,
  ssoRedirect = utils.ssoRedirect,
  success,
  useFetch = consumerApi.hooks.useFetch,
  useSearchParam = sharedHooks.useSearchParam,
  verifyEmail,
}) => {
  const token = useSearchParam(SearchParam.TOKEN) || "";
  const redirectTo = useSearchParam(SearchParam.REDIRECT) || r.home();
  const shouldCloseWindow = useSearchParam(SearchParam.CLOSE) || "false";
  const [email, setEmail] = useState<string>("");
  const [emailVerificationRequestsFetch] = useFetch(
    pendingEmailVerificationRequests(token)
  );

  useEffect(() => {
    if (!emailVerificationRequestsFetch.response) return;
    setEmail(emailVerificationRequestsFetch.response.email);
  }, [emailVerificationRequestsFetch, setEmail]);

  useEffect(() => {
    if (!success && !failureCode) {
      const onLoadDoVerifyEmail = async (): Promise<void> => {
        await verifyEmail(token);
      };
      onLoadDoVerifyEmail();
    }
  }, [success, failureCode, verifyEmail, token]);

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        ssoRedirect(redirectTo);
      }, SSO_CONFIRMATION_TIMEOUT);
    }
  }, [success, redirectTo, ssoRedirect]);

  if (failureCode) {
    ssoRedirect(
      r.ssoEmailVerificationTokenExpired({
        close: shouldCloseWindow,
        returnTo: redirectTo,
      })
    );
    return null;
  }

  return (
    <SsoChrome>
      <SsoForm.Form data-testid="verify-email-form">
        <SsoForm.Field>
          <SsoForm.Title>{text.VERIFICATION_CONFIRMATION_TITLE}</SsoForm.Title>
          <SsoForm.P>{text.VERIFICATION_CONFIRMATION}</SsoForm.P>
        </SsoForm.Field>
        <SsoForm.Field>
          <SsoForm.Input
            data-testid="email-input"
            disabled={true}
            type="email"
            value={email}
          />
        </SsoForm.Field>
      </SsoForm.Form>
    </SsoChrome>
  );
};
