import React, { Dispatch, SetStateAction } from "react";
import styled from "styled-components";

import * as ps from "../../../shared/components/PillSelect";
import * as Text from "../../../shared/components/Text";
import * as t from "../../../shared/text";

import { StrainsFilter } from "../../types";
import { getPotencyOptions, handlePillOptionChange } from "../../utils";

import * as SC from "./styledComponents";

type PodsPotencyFilterProps = {
  filterUpdate: StrainsFilter;
  setFilterUpdate: Dispatch<SetStateAction<StrainsFilter>>;
};

export const PodsPotencyFilter: React.FC<PodsPotencyFilterProps> = ({
  filterUpdate,
  setFilterUpdate,
}) => {
  return (
    <SC.Filter>
      <SC.Label>{t.POTENCY}</SC.Label>
      <ps.PillSelect
        Container={sc.Container}
        onChange={(options) =>
          handlePillOptionChange(
            options,
            filterUpdate.potency,
            "potency",
            filterUpdate,
            setFilterUpdate
          )
        }
        options={getPotencyOptions(filterUpdate.potency)}
        PillOption={sc.PillOption}
      />
    </SC.Filter>
  );
};

const sc = {
  Container: styled.div`
    column-gap: 19px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 12px;
  `,

  PillOption: styled(SC.BasePill)<SC.BasePillProps>`
    ${Text.MediumLLBold}
    background: ${({ active }) => (active ? "var(--black)" : "var(--white)")};
    border: 3px solid var(--black);
    border-radius: 6px;
    color: ${({ active }) => (active ? "var(--white)" : "var(--black)")};
    flex-direction: column;
    font-size: 12px;
    height: 52px;
    min-width: 100px;
    padding: 11px;
    text-transform: uppercase;
  `,
};
