import { EnhancedStore } from "@reduxjs/toolkit";
import React from "react";
import { render } from "react-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ModalProvider } from "styled-react-modal";

import "./polyfills";
import "./bugsnag";
import "./app/declare-global";

import { trackEvent } from "./app/analytics";
import * as consumerApi from "./app/api/consumer";
import * as c from "./app/appConstants";
import ErrorBoundary from "./app/shared/components/ErrorBoundary";
import { ResponsiveModalBackground } from "./app/shared/components/ResponsiveModalBackground";
import { isIOSUpgradeNeeded, navigator } from "./navigator";

import "./app/styles/index.css";

type RenderAppParams = {
  App: React.FC;
  store: EnhancedStore;
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      queryFn: consumerApi.reactQueryUtils.defaultQueryFn,
      queryKeyHashFn: consumerApi.reactQueryUtils.defaultQueryKeyHashFn,
      refetchOnWindowFocus: c.IS_PRODUCTION,
      retry: false,
    },
  },
});

const renderApp = ({ App, store }: RenderAppParams): void => {
  render(
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <BrowserRouter>
            <ModalProvider backgroundComponent={ResponsiveModalBackground}>
              <App />
            </ModalProvider>
          </BrowserRouter>
        </Provider>
        {c.IS_LOCAL && c.USE_REACT_QUERY_DEVTOOLS && (
          <ReactQueryDevtools initialIsOpen={false} position={"bottom-left"} />
        )}
      </QueryClientProvider>
    </ErrorBoundary>,
    document.getElementById("root")
  );
};

const renderExploreApp = () => {
  const { ExploreApp: App } = require("./app/explore/components/ExploreApp");
  const { store } = require("./app/explore/store");

  renderApp({ App, store });
};

const renderBluetoothApp = () => {
  const {
    BluetoothApp: App,
  } = require("./app/bluetooth/components/BluetoothApp");
  const { store } = require("./app/bluetooth/store");

  renderApp({ App, store });
};

const isSso = window.location.pathname.indexOf("/sso/") !== -1;

if (!navigator.bluetooth) {
  trackEvent("bt_unavailable");
}

switch (true) {
  case isSso:
    require("./app/sso");
    break;

  // Catch Connect Browser running in iOS 13, which is missing BigInt.
  // Only in production since Chrome Dev Tools' iPhone models come in as iOS 13.
  case c.IS_PRODUCTION && isIOSUpgradeNeeded():
    renderExploreApp();
    break;

  case !!navigator.bluetooth:
    renderBluetoothApp();
    break;

  default:
    renderExploreApp();
    break;
}
