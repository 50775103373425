import React from "react";

import * as PaxBle from "../../../pax-ble";

import { SelectDeviceType } from "../../device/actions";
import { NullableDeviceType } from "../../device/types";

import * as t from "../types";
import { DeviceTypeList } from "./DeviceTypeList";

type AddDeviceProps = t.DeviceConnectionModalContentProps & {
  deviceError?: string;
  deviceTypes: PaxBle.Types.DeviceType[];
  selectDeviceType: SelectDeviceType;
  selectedDeviceType: NullableDeviceType;
};

export const AddDevice: React.FC<AddDeviceProps> = ({
  deviceError,
  deviceTypes,
  onStepCompleted,
  selectedDeviceType,
  selectDeviceType,
}) => {
  const handleOptionClick = (newDeviceType: NullableDeviceType): void => {
    selectDeviceType(newDeviceType);
    onStepCompleted(t.DeviceConnectionStep.ADD_DEVICE);
  };

  return (
    <DeviceTypeList
      deviceError={deviceError}
      deviceTypes={deviceTypes}
      horizontal
      onOptionClick={handleOptionClick}
      selectedDeviceType={selectedDeviceType}
      title="Add a Device"
    />
  );
};
