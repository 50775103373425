import { connect } from "react-redux";

import * as t from "../../../main/types";

import { setHeaterSetPoint } from "../../actions";
import {
  Thermostat,
  ThermostatProps,
} from "../../components/Thermostat/Thermostat";
import { getDeviceTemperature } from "../../selectors";

const mapStateToProps = (
  state: t.MainAppState,
  ownProps: ThermostatProps
): ThermostatProps => ({
  deviceTemperature: getDeviceTemperature(state),
  ...ownProps,
});

const mapDispatchToProps = {
  onChange: setHeaterSetPoint,
};

export default connect(mapStateToProps, mapDispatchToProps)(Thermostat);
