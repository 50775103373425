import * as PaxBleConstants from "../../pax-ble/constants";
import * as PaxBleTypes from "../../pax-ble/types";

import * as DeviceImages from "../shared/components/DeviceImages";
import * as DevicePairingImages from "../shared/components/DevicePairingImages";
import * as DeviceSerialLocationImages from "../shared/components/DeviceSerialLocationImages";
import * as text from "../shared/text";
import * as t from "./types";

// Action types.
export const CLEAR_CURRENT_POD = "CLEAR_CURRENT_POD";
export const DEVICE_CONNECTED = "DEVICE_CONNECTED";
export const DEVICE_CONNECTING = "DEVICE_CONNECTING";
export const DEVICE_DISCONNECTED = "DEVICE_DISCONNECTED";
export const DEVICE_DISCONNECTING = "DEVICE_DISCONNECTING";
export const DEVICE_ERROR = "DEVICE_ERROR";
export const DEVICE_REQUESTED = "DEVICE_REQUESTED";
export const DEVICE_RESTARTING = "DEVICE_RESTARTING";
export const DEVICE_TIME_SYNC = "DEVICE_TIME_SYNC";
export const NO_OP = "NO_OP";
export const RECEIVED_ATTRIBUTE = "RECEIVED_ATTRIBUTE";
export const RECEIVED_BATCH_RESULT = "RECEIVED_BATCH_RESULT";
export const RECEIVED_FIRMWARE_REVISION = "RECEIVED_FIRMWARE_REVISION";
export const RECEIVED_LOG_EVENTS = "RECEIVED_LOG_EVENTS";
export const RECEIVED_NFC_POD_DATA = "RECEIVED_NFC_POD_DATA";
export const SELECT_DEVICE_TYPE = "SELECT_DEVICE_TYPE";
export const SET_HAS_ENCRYPTION_ERRORS = "SET_HAS_ENCRYPTION_ERRORS";
export const SET_K3_SERIAL = "SET_K3_SERIAL";
export const SET_K4_SERIAL = "SET_K4_SERIAL";
export const SET_MANUAL_ID_POD = "SET_MANUAL_ID_POD";
export const SET_PAX_3_SERIAL = "SET_PAX_3_SERIAL";
export const SET_SYNCING_STATUS = "SET_SYNCING_STATUS";

// Attributes.
export const ATTRIBUTE_ACTUAL_TEMP = "ACTUAL_TEMP";
export const ATTRIBUTE_BRIGHTNESS = "BRIGHTNESS";
export const ATTRIBUTE_CHARGE_STATUS = "CHARGE_STATUS";
export const ATTRIBUTE_COLOR_THEME = "COLOR_THEME";
export const ATTRIBUTE_DEVICE_NAME = "DEVICE_NAME";
export const ATTRIBUTE_DYNAMIC_MODE = "DYNAMIC_MODE";
export const ATTRIBUTE_HAPTICS = "HAPTICS";
export const ATTRIBUTE_HAPTIC_MODE = "HAPTIC_MODE";
export const ATTRIBUTE_HEATER_SET_POINT = "HEATER_SET_POINT";
export const ATTRIBUTE_HEATING_STATE = "HEATING_STATE";
export const ATTRIBUTE_LOCKED = "LOCKED";
export const ATTRIBUTE_POD_DATA = "POD_DATA";
export const ATTRIBUTE_POD_INSERTED = "POD_INSERTED";
export const ATTRIBUTE_REPLAY = "REPLAY";
export const ATTRIBUTE_SESSION_CONTROL = "SESSION_CONTROL";
export const ATTRIBUTE_SUPPORTED_ATTRIBUTES = "SUPPORTED_ATTRIBUTES";
export const ATTRIBUTE_VAPOR_ENGINE = "VAPOR_ENGINE";

const ATTRIBUTE_BATTERY = "BATTERY";
const ATTRIBUTE_BLE_DIS_DATA = "ATTRIBUTE_BLE_DIS_DATA";
const ATTRIBUTE_CURRENT_TARGET_TEMP = "CURRENT_TARGET_TEMP";
const ATTRIBUTE_ENCRYPTION_EXCHANGE = "ATTRIBUTE_ENCRYPTION_EXCHANGE";
const ATTRIBUTE_ENCRYPTION_PACKET = "ATTRIBUTE_ENCRYPTION_PACKET";
const ATTRIBUTE_GAME_MODE = "GAME_MODE";
const ATTRIBUTE_HEATER_RANGES = "HEATER_RANGES";
const ATTRIBUTE_LOW_SOC_MODE = "LOW_SOC_STATUS";
const ATTRIBUTE_SHELL_COLOR = "SHELL_COLOR";
const ATTRIBUTE_STATUS_UPDATE = "STATUS_UPDATE";
const ATTRIBUTE_TIME = "TIME";
const ATTRIBUTE_UI_MODE = "UI_MODE";
const ATTRIBUTE_USAGE = "USAGE";

const Pax3InitialAttributes = [
  PaxBleConstants.ATTRIBUTE_BATTERY,
  PaxBleConstants.ATTRIBUTE_BRIGHTNESS,
  PaxBleConstants.ATTRIBUTE_CHARGE_STATUS,
  PaxBleConstants.ATTRIBUTE_COLOR_THEME,
  PaxBleConstants.ATTRIBUTE_CURRENT_TARGET_TEMP,
  PaxBleConstants.ATTRIBUTE_DEVICE_NAME,
  PaxBleConstants.ATTRIBUTE_DYNAMIC_MODE,
  PaxBleConstants.ATTRIBUTE_HAPTIC_MODE,
  PaxBleConstants.ATTRIBUTE_HEATER_SET_POINT,
  PaxBleConstants.ATTRIBUTE_LOCKED,
  PaxBleConstants.ATTRIBUTE_LOW_SOC_MODE,
  PaxBleConstants.ATTRIBUTE_POD_INSERTED,
  PaxBleConstants.ATTRIBUTE_SHELL_COLOR,
  PaxBleConstants.ATTRIBUTE_SUPPORTED_ATTRIBUTES,
  PaxBleConstants.ATTRIBUTE_TIME,
];

export const initialAttributeIdsForDeviceType: {
  [key in PaxBleTypes.DeviceType]: number[];
} = {
  [PaxBleTypes.DeviceType.PAX_3]: Pax3InitialAttributes,
  [PaxBleTypes.DeviceType.PAX_35]: Pax3InitialAttributes,
  [PaxBleTypes.DeviceType.ERA]: [
    PaxBleConstants.ATTRIBUTE_BATTERY,
    PaxBleConstants.ATTRIBUTE_BRIGHTNESS,
    PaxBleConstants.ATTRIBUTE_CHARGE_STATUS,
    PaxBleConstants.ATTRIBUTE_COLOR_THEME,
    PaxBleConstants.ATTRIBUTE_DEVICE_NAME,
    PaxBleConstants.ATTRIBUTE_HEATER_SET_POINT,
    PaxBleConstants.ATTRIBUTE_LOCKED,
    PaxBleConstants.ATTRIBUTE_POD_INSERTED,
    PaxBleConstants.ATTRIBUTE_REPLAY,
    PaxBleConstants.ATTRIBUTE_SUPPORTED_ATTRIBUTES,
    PaxBleConstants.ATTRIBUTE_TIME,
  ],
  [PaxBleTypes.DeviceType.ERA_PRO]: [
    PaxBleConstants.ATTRIBUTE_BATTERY,
    PaxBleConstants.ATTRIBUTE_BRIGHTNESS,
    PaxBleConstants.ATTRIBUTE_CHARGE_STATUS,
    PaxBleConstants.ATTRIBUTE_DEVICE_NAME,
    PaxBleConstants.ATTRIBUTE_ENCRYPTION_PACKET,
    PaxBleConstants.ATTRIBUTE_HAPTICS,
    PaxBleConstants.ATTRIBUTE_HEATER_SET_POINT,
    PaxBleConstants.ATTRIBUTE_LOCKED,
    PaxBleConstants.ATTRIBUTE_POD_DATA,
    PaxBleConstants.ATTRIBUTE_POD_INSERTED,
    PaxBleConstants.ATTRIBUTE_SESSION_CONTROL,
    PaxBleConstants.ATTRIBUTE_SHELL_COLOR,
    PaxBleConstants.ATTRIBUTE_SUPPORTED_ATTRIBUTES,
    PaxBleConstants.ATTRIBUTE_TIME,
  ],
};

// Applies to FW versions below 4.1.18
// Removes ATTRIBUTE_POD_DATA and ATTRIBUTE_ENCRYPTION_PACKET
export const eraProLegacyFirmwareSupportedAttributes = [
  PaxBleConstants.ATTRIBUTE_BATTERY,
  PaxBleConstants.ATTRIBUTE_BRIGHTNESS,
  PaxBleConstants.ATTRIBUTE_CHARGE_STATUS,
  PaxBleConstants.ATTRIBUTE_DEVICE_NAME,
  PaxBleConstants.ATTRIBUTE_HAPTICS,
  PaxBleConstants.ATTRIBUTE_HEATER_SET_POINT,
  PaxBleConstants.ATTRIBUTE_LOCKED,
  PaxBleConstants.ATTRIBUTE_POD_INSERTED,
  PaxBleConstants.ATTRIBUTE_SESSION_CONTROL,
  PaxBleConstants.ATTRIBUTE_SHELL_COLOR,
  PaxBleConstants.ATTRIBUTE_SUPPORTED_ATTRIBUTES,
  PaxBleConstants.ATTRIBUTE_TIME,
];

export const PEN_TEMP_MIN_C = 220;
export const PEN_TEMP_MAX_C = 420;
export const PEN_TEMP_MIN_F = 430;
export const PEN_TEMP_MAX_F = 790;

export const FLOWER_DEVICE_TEMP_MIN_C = 175;
export const FLOWER_DEVICE_TEMP_MAX_C = 215;
export const FLOWER_DEVICE_TEMP_MIN_F = 340;
export const FLOWER_DEVICE_TEMP_MAX_F = 420;

export const PRESET_TEMP_BUFFER = 2;
export const PRESET_TEMP_BUFFER_C10 = PRESET_TEMP_BUFFER * 10;

export const DEVICE_TIME_DIFFERENCE_THRESHOLD = 2; // seconds.

export const DEVICE_SYNCING_TIMEOUT = 20_000; // 20 seconds
export const LOG_FETCH_INTERVAL = 5_000;

export const DEVICE_NAME_MAX_LENGTH = 13;

export const OPT_IN_LOG_TYPES: PaxBleTypes.LogEventType[] = [
  PaxBleTypes.EraPro.LogType.PUFF_V1,
  PaxBleTypes.EraPro.LogType.PUFF_V2,
  PaxBleTypes.EraPro.LogType.PUFF_V3,
  PaxBleTypes.EraPro.LogType.PUFF_V4,
  PaxBleTypes.EraPro.LogType.HEATER_SET_POINT_CHANGED,
  PaxBleTypes.EraPro.LogType.BLUETOOTH_APP_COMMAND,
  PaxBleTypes.EraPro.LogType.BLUETOOTH_NAME_CHANGED,
  PaxBleTypes.Era.LogType.PUFF_USAGE,
  PaxBleTypes.Era.LogType.PUFF_TIME,
  PaxBleTypes.Era.LogType.HEATER_SET_POINT_CHANGED,
  PaxBleTypes.Era.LogType.BLUETOOTH_APP_COMMAND,
  PaxBleTypes.Era.LogType.PUFF_ENERGY,
  PaxBleTypes.Era.LogType.DRAW_ATTEMPTED_WITH_UNSTABLE_BASELINE,
  PaxBleTypes.Era.LogType.DRAW_ATTEMPTED_WHILE_PERIPHERAL_LOCKED,
  PaxBleTypes.Era.LogType.USAGE_SESSION_ENDED,
  PaxBleTypes.Era.LogType.CONTROLLED_SESSION_STARTED,
  PaxBleTypes.Era.LogType.CONTROLLED_SESSION_COMPLETED,
  PaxBleTypes.Era.LogType.SESSION_CONTROL_STOP,
  PaxBleTypes.Era.LogType.SESSION_CONTROL_PROGRESS_AT_STOP,
];

export const LS_KEY_K3_SERIAL = "__paxWeb.k3Serial";
export const LS_KEY_K4_SERIAL = "__paxWeb.k4Serial";
export const LS_KEY_P3_SERIAL = "__paxWeb.pax3Serial";
export const LS_KEY_IS_DEVICE_CONNECTED = "__paxWeb.isDeviceConnected";
export const LS_KEY_LOCKOUT_TIMEOUT_START = "__paxWeb.lockoutTimeoutStart";
export const LS_KEY_CURRENT_POD_STRAIN_ID = "__paxWeb.currentPodStrainId";

const attributeIdToNameMap: { [key: number]: string } = {
  [PaxBleConstants.ATTRIBUTE_ACTUAL_TEMP]: ATTRIBUTE_ACTUAL_TEMP,
  [PaxBleConstants.ATTRIBUTE_BATTERY]: ATTRIBUTE_BATTERY,
  [PaxBleConstants.ATTRIBUTE_BLE_DIS_DATA]: ATTRIBUTE_BLE_DIS_DATA,
  [PaxBleConstants.ATTRIBUTE_BRIGHTNESS]: ATTRIBUTE_BRIGHTNESS,
  [PaxBleConstants.ATTRIBUTE_CHARGE_STATUS]: ATTRIBUTE_CHARGE_STATUS,
  [PaxBleConstants.ATTRIBUTE_COLOR_THEME]: ATTRIBUTE_COLOR_THEME,
  [PaxBleConstants.ATTRIBUTE_CURRENT_TARGET_TEMP]:
    ATTRIBUTE_CURRENT_TARGET_TEMP,
  [PaxBleConstants.ATTRIBUTE_DEVICE_NAME]: ATTRIBUTE_DEVICE_NAME,
  [PaxBleConstants.ATTRIBUTE_DYNAMIC_MODE]: ATTRIBUTE_DYNAMIC_MODE,
  [PaxBleConstants.ATTRIBUTE_ENCRYPTION_EXCHANGE]:
    ATTRIBUTE_ENCRYPTION_EXCHANGE,
  [PaxBleConstants.ATTRIBUTE_ENCRYPTION_PACKET]: ATTRIBUTE_ENCRYPTION_PACKET,
  [PaxBleConstants.ATTRIBUTE_GAME_MODE]: ATTRIBUTE_GAME_MODE,
  [PaxBleConstants.ATTRIBUTE_HAPTICS]: ATTRIBUTE_HAPTICS,
  [PaxBleConstants.ATTRIBUTE_HAPTIC_MODE]: ATTRIBUTE_HAPTIC_MODE,
  [PaxBleConstants.ATTRIBUTE_HEATER_RANGES]: ATTRIBUTE_HEATER_RANGES,
  [PaxBleConstants.ATTRIBUTE_HEATER_SET_POINT]: ATTRIBUTE_HEATER_SET_POINT,
  [PaxBleConstants.ATTRIBUTE_HEATING_STATE]: ATTRIBUTE_HEATING_STATE,
  [PaxBleConstants.ATTRIBUTE_LOCKED]: ATTRIBUTE_LOCKED,
  [PaxBleConstants.ATTRIBUTE_LOW_SOC_MODE]: ATTRIBUTE_LOW_SOC_MODE,
  [PaxBleConstants.ATTRIBUTE_POD_DATA]: ATTRIBUTE_POD_DATA,
  [PaxBleConstants.ATTRIBUTE_POD_INSERTED]: ATTRIBUTE_POD_INSERTED,
  [PaxBleConstants.ATTRIBUTE_REPLAY]: ATTRIBUTE_REPLAY,
  [PaxBleConstants.ATTRIBUTE_SESSION_CONTROL]: ATTRIBUTE_SESSION_CONTROL,
  [PaxBleConstants.ATTRIBUTE_SHELL_COLOR]: ATTRIBUTE_SHELL_COLOR,
  [PaxBleConstants.ATTRIBUTE_STATUS_UPDATE]: ATTRIBUTE_STATUS_UPDATE,
  [PaxBleConstants.ATTRIBUTE_SUPPORTED_ATTRIBUTES]:
    ATTRIBUTE_SUPPORTED_ATTRIBUTES,
  [PaxBleConstants.ATTRIBUTE_TIME]: ATTRIBUTE_TIME,
  [PaxBleConstants.ATTRIBUTE_UI_MODE]: ATTRIBUTE_UI_MODE,
  [PaxBleConstants.ATTRIBUTE_USAGE]: ATTRIBUTE_USAGE,
  [PaxBleConstants.ATTRIBUTE_VAPOR_ENGINE]: ATTRIBUTE_VAPOR_ENGINE,
};

const EraProAssets: t.DeviceAssets = {
  [t.DeviceColor.MATTE_JADE]: DeviceImages.EraProTeal,
  [t.DeviceColor.MATTE_RED]: DeviceImages.EraProMaroon,
  [t.DeviceColor.MATTE_BLACK]: DeviceImages.EraProBlack,
  [t.DeviceColor.MATTE_SILVER]: DeviceImages.EraProSilver,
  [t.DeviceColor.MIDNIGHT_RAIN]: DeviceImages.EraProSapphire,
  [t.DeviceColor.UNASSIGNED]: DeviceImages.EraProBlack,
};

// These strings correspond to what is shown to the user
// in the UI, NOT what the actual heating state is.
const heatingStateLabelsMap = {
  [PaxBleTypes.Pax3.HeatingState.HEATING]: text.HEATING,
  [PaxBleTypes.Pax3.HeatingState.READY]: text.READY,
  [PaxBleTypes.Pax3.HeatingState.LIP_BOOSTING]: text.READY,
  [PaxBleTypes.Pax3.HeatingState.LIP_COOLING]: text.READY,
  [PaxBleTypes.Pax3.HeatingState.MOTION_STANDBY]: text.COOLING,
  [PaxBleTypes.Pax3.HeatingState.OVEN_OFF]: text.OVEN_OFF,
  [PaxBleTypes.Pax3.HeatingState.TEMP_SET_MODE]: text.SETTING_TEMPERATURE,
};

const EraAsset: string = DeviceImages.Era;

const PAX3Assets: t.DeviceAssets = {
  [t.DeviceColor.AMBER]: DeviceImages.Pax35Amber,
  [t.DeviceColor.GLOSSY_BLACK]: DeviceImages.Pax3GlossyBlack,
  [t.DeviceColor.GLOSSY_SILVER]: DeviceImages.Pax3GlossySilver,
  [t.DeviceColor.GLOSSY_GOLD]: DeviceImages.Pax3GlossyGold,
  [t.DeviceColor.GLOSSY_ROSE_GOLD]: DeviceImages.Pax3GlossyRoseGold,
  [t.DeviceColor.GLOSSY_RED]: DeviceImages.Pax3GlossyRed,
  [t.DeviceColor.BLUE]: DeviceImages.Pax3Blue,
  [t.DeviceColor.MATTE_BLACK]: DeviceImages.Pax3MatteBlack,
  [t.DeviceColor.MATTE_SILVER]: DeviceImages.Pax3MatteSilver,
  [t.DeviceColor.MATTE_ROSE_GOLD]: DeviceImages.Pax3MatteRoseGold,
  [t.DeviceColor.MATTE_TEAL]: DeviceImages.Pax3MatteTeal,
  [t.DeviceColor.MATTE_FUCHSIA]: DeviceImages.Pax3MatteFuchsia,
  [t.DeviceColor.BURGUNDY]: DeviceImages.Pax35Burgundy,
  [t.DeviceColor.ONYX]: DeviceImages.Pax35Onyx,
  [t.DeviceColor.SAGE]: DeviceImages.Pax35Sage,
  [t.DeviceColor.SAND]: DeviceImages.Pax35Sand,
  [t.DeviceColor.MIDNIGHT_RAIN]: DeviceImages.Pax35MidnightRain,
  [t.DeviceColor.SHADOW_RAIN]: DeviceImages.Pax35ShadowRain,
  [t.DeviceColor.OCEAN]: DeviceImages.Pax35Ocean,
  [t.DeviceColor.UNASSIGNED]: DeviceImages.Pax3MatteBlack,
};

const PAX35Assets: t.DeviceAssets = {
  [t.DeviceColor.AMBER]: DeviceImages.Pax35Amber,
  [t.DeviceColor.BURGUNDY]: DeviceImages.Pax35Burgundy,
  [t.DeviceColor.ONYX]: DeviceImages.Pax35Onyx,
  [t.DeviceColor.SAGE]: DeviceImages.Pax35Sage,
  [t.DeviceColor.SAND]: DeviceImages.Pax35Sand,
  [t.DeviceColor.MIDNIGHT_RAIN]: DeviceImages.Pax35MidnightRain,
  [t.DeviceColor.SHADOW_RAIN]: DeviceImages.Pax35ShadowRain,
  [t.DeviceColor.OCEAN]: DeviceImages.Pax35Ocean,
};

export const getConnectToDeviceOptions = (
  deviceType: t.NullableDeviceType,
  serial: string
): PaxBleTypes.DeviceOptions => {
  if (deviceType === PaxBleTypes.DeviceType.ERA_PRO) {
    return { eraProLegacyFirmwareSupportedAttributes, serial };
  }

  return { serial };
};

export const getDeviceAsset = (
  type: t.NullableDeviceType,
  color: t.DeviceColor
): string | null => {
  if (!type) return null;

  switch (type) {
    case PaxBleTypes.DeviceType.ERA:
      return EraAsset;
    case PaxBleTypes.DeviceType.ERA_PRO:
      return EraProAssets[color] || DeviceImages.EraProBlack;
    case PaxBleTypes.DeviceType.PAX_3:
      return PAX3Assets[color] || DeviceImages.Pax3MatteBlack;
    case PaxBleTypes.DeviceType.PAX_35:
      return PAX35Assets[color] || DeviceImages.Pax35Burgundy;
  }
};

export const getAttributeName = (attributeId: number): string => {
  return attributeIdToNameMap[attributeId];
};

export const getHeatingStateLabel = (
  heatingState: PaxBleTypes.Pax3.HeatingState
): string => {
  return heatingStateLabelsMap[heatingState];
};

export const deviceTypeMap = {
  [PaxBleTypes.DeviceType.ERA]: text.PAX_ERA,
  [PaxBleTypes.DeviceType.ERA_PRO]: text.PAX_ERA_PRO,
  [PaxBleTypes.DeviceType.PAX_3]: text.PAX_3,
  [PaxBleTypes.DeviceType.PAX_35]: text.PAX_3,
};

export const getDevicePairingAsset = (
  type: t.NullableDeviceType
): string | null => {
  if (!type) return null;

  switch (type) {
    case PaxBleTypes.DeviceType.ERA:
      return DevicePairingImages.EraScanning;
    case PaxBleTypes.DeviceType.ERA_PRO:
      return DevicePairingImages.EraProScanning;
    case PaxBleTypes.DeviceType.PAX_3:
    case PaxBleTypes.DeviceType.PAX_35:
      return DevicePairingImages.Pax3Scanning;
  }
};

export const getDeviceSerialLocationAsset = (
  type: t.NullableDeviceType
): string | null => {
  if (!type) return null;

  switch (type) {
    case PaxBleTypes.DeviceType.ERA:
      return DeviceSerialLocationImages.EraSerialLocation;
    case PaxBleTypes.DeviceType.ERA_PRO:
      return DeviceSerialLocationImages.EraProSerialLocation;
    case PaxBleTypes.DeviceType.PAX_3:
    case PaxBleTypes.DeviceType.PAX_35:
      return DeviceSerialLocationImages.Pax3SerialLocation;
  }
};

export const connectionStatusTextMap: t.ConnectionStatusTextMap = {
  [t.ConnectionStatus.CONNECTED]: text.CONNECTED,
  [t.ConnectionStatus.CONNECTING]: text.CONNECTING,
  [t.ConnectionStatus.NOT_CONNECTED]: text.DISCONNECTED,
};
