import React, { useEffect, useState } from "react";
import styled, { SimpleInterpolation } from "styled-components";

import * as apiTypes from "../../../api/consumer/types";
import { TEMPERATURE_UNITS } from "../../../data/temperatureUnits";
import { useStrainById } from "../../../pods/hooks";
import * as Card from "../../../shared/components/Card";
import { Row } from "../../../shared/components/Flex";
import * as Text from "../../../shared/components/Text";

import Thermostat from "../../containers/Thermostat/Thermostat";
import { celsiusToFahrenheit } from "../../utils";

import TemperatureInfo from "./TemperatureInfo";

export type PenThermostatCardProps = {
  deviceTemperature?: number;
  isDeviceLocked: boolean;
  podStrainId?: string | null;
  temperatureRange: number[];
  temperatureUnit: apiTypes.user.PreferredTemperatureUnit;
};

export const PenThermostatCard: React.FC<PenThermostatCardProps> = ({
  deviceTemperature,
  isDeviceLocked,
  podStrainId,
  temperatureRange,
  temperatureUnit,
}) => {
  const { strain } = useStrainById(podStrainId, {
    shouldShowErrorBanner: true,
  });

  const [idealFlavor, setIdealFlavor] = useState<number | null>();
  const [expertTemp, setExpertTemp] = useState<number | null>();
  const [idealVapor, setIdealVapor] = useState<number | null>();
  const [displayedTemperature, setDisplayedTemperature] = useState(
    deviceTemperature ? Math.round(deviceTemperature) : ""
  );
  const [tempMin, tempMax] = temperatureRange;

  useEffect(() => {
    if (!strain) {
      setPresetTemperatures(null, null, null);
      return;
    }

    const roundPresetTemperature = (temperature: number): number => {
      if (temperatureUnit === apiTypes.user.PreferredTemperatureUnit.FAHRENHEIT)
        return Math.round(celsiusToFahrenheit(temperature));

      return Math.round(temperature);
    };

    const flavor =
      strain.bestFlavorTemperature === 0
        ? null
        : roundPresetTemperature(strain.bestFlavorTemperature);

    const expert =
      strain.recommendedTemperature === 0
        ? null
        : roundPresetTemperature(strain.recommendedTemperature);

    const vapor =
      strain.maxVaporTemperature === 0
        ? null
        : roundPresetTemperature(strain.maxVaporTemperature);

    setPresetTemperatures(flavor, expert, vapor);
  }, [strain, temperatureUnit]);

  const setPresetTemperatures = (
    idealFlavor: number | null,
    expertTemp: number | null,
    idealVapor: number | null
  ): void => {
    setIdealFlavor(idealFlavor);
    setExpertTemp(expertTemp);
    setIdealVapor(idealVapor);
  };

  useEffect(() => {
    if (!deviceTemperature) return;
    setDisplayedTemperature(Math.round(deviceTemperature));
  }, [deviceTemperature]);

  const handleThermostatDrag = (deviceTemperature: number): void => {
    setDisplayedTemperature(Math.round(deviceTemperature));
  };

  if (isDeviceLocked) {
    return (
      <sc.BigCard data-testid="locked-thermostat-card" isDeviceLocked>
        <sc.Temperature isDeviceLocked>---</sc.Temperature>
        <Row alignItems="flex-end">
          <sc.TemperatureLabel>{tempMin}°</sc.TemperatureLabel>
          <sc.ThermostatContainer>
            <Thermostat
              isDisabled={true}
              tempRange={[tempMin, tempMax]}
              temperatureUnit={temperatureUnit}
            />
          </sc.ThermostatContainer>
          <sc.TemperatureLabel>{tempMax}°</sc.TemperatureLabel>
        </Row>
      </sc.BigCard>
    );
  }

  return (
    <sc.BigCard data-testid="thermostat-card">
      <sc.Temperature data-testid="temperature">
        {displayedTemperature}
        {displayedTemperature && (
          <sc.UnitContainer>
            <sc.DegreeSymbol>°</sc.DegreeSymbol>
            <sc.DegreeType>
              {TEMPERATURE_UNITS[temperatureUnit].shorthand}
            </sc.DegreeType>
          </sc.UnitContainer>
        )}
      </sc.Temperature>
      <Row alignItems="flex-end">
        <sc.TemperatureLabel>{tempMin}°</sc.TemperatureLabel>
        <sc.ThermostatContainer>
          <Thermostat
            onDrag={handleThermostatDrag}
            tempRange={[tempMin, tempMax]}
            idealFlavorTemperature={idealFlavor}
            expertTemperature={expertTemp}
            idealVaporTemperature={idealVapor}
            temperatureUnit={temperatureUnit}
          />
        </sc.ThermostatContainer>
        <sc.TemperatureLabel>{tempMax}°</sc.TemperatureLabel>
      </Row>
      <TemperatureInfo
        deviceTemperature={deviceTemperature}
        idealFlavorTemperature={idealFlavor}
        expertTemperature={expertTemp}
        idealVaporTemperature={idealVapor}
        temperatureUnit={temperatureUnit}
      />
    </sc.BigCard>
  );
};

type DeviceLockedProps = {
  isDeviceLocked?: boolean;
};

const sc = {
  BigCard: styled(Card.Big)<DeviceLockedProps>`
    justify-content: ${(props): SimpleInterpolation =>
      props.isDeviceLocked ? "flex-start" : "center"};
    margin-top: 8px;
  `,

  DegreeSymbol: styled.span`
    letter-spacing: 0.81px;
  `,

  DegreeType: styled.span`
    letter-spacing: 0.75px;
  `,

  Temperature: styled.div<DeviceLockedProps>`
    ${Text.PlaaxLight}
    font-size: 80px;
    height: 80px;
    position: relative;

    ${({ isDeviceLocked }): SimpleInterpolation =>
      isDeviceLocked ? "color: var(--very-light-pink);" : ""}
  `,

  TemperatureLabel: styled.div`
    color: var(--light-grey-blue);
    font-size: 20px;
    margin-bottom: 18px;
  `,

  ThermostatContainer: styled.div`
    margin: 0 4px;
  `,

  UnitContainer: styled.div`
    font-size: 24px;
    position: absolute;
    right: -25px;
    top: 16px;
  `,
};
