/*
 * This file is for app level constants only. Any shared constants should be
 * placed in /app/shared/constants'
 */

export const IS_BETA = process.env.REACT_APP_ENV === "beta";
export const IS_LOCAL = process.env.REACT_APP_ENV === "local";
export const IS_PRODUCTION = process.env.REACT_APP_ENV === "production";
export const IS_STAGING = process.env.REACT_APP_ENV === "staging";
export const IS_TEST = process.env.REACT_APP_ENV === "test";
export const USE_REACT_QUERY_DEVTOOLS =
  process.env.REACT_APP_USE_REACT_QUERY_DEVTOOLS === "true";

export const MINIMUM_IOS_VERSION = "14";

export const PLATFORM_WINDOWS = "Win32";

export const MINIMUM_CONNECT_BROWSER_VERSION = "1.2.1";
