import { Page } from "./types";

export const isPageActive = (
  location: Location,
  startsWith: Page,
  supportsBluetooth?: boolean
): boolean => {
  const { pathname } = location;

  if (startsWith === Page.EXPLORE)
    return pathname.startsWith(Page.EXPLORE) ||
      (!supportsBluetooth && pathname === Page.HOME)
      ? true
      : false;

  if (startsWith === Page.DEVICE)
    return (pathname.startsWith(Page.DEVICE) && !pathname.includes(Page.DEV)) ||
      (supportsBluetooth && location.pathname === Page.HOME)
      ? true
      : false;

  return pathname.startsWith(startsWith) ? true : false;
};
