import * as c from "../constants";
import BleManager from "./BleManager";
import CharacteristicChangedPacketHandler from "./CharacteristicChangedPacketHandler";
import Request from "./Request";
import WriteRequest from "./WriteRequest";

const DEFAULT_MTU = 160;
export const MAX_PACKET_LENGTH = DEFAULT_MTU - 3;

export default class McuMgrBleTransport {
  ble: BleManager;
  maxPacketLength: number;
  requestQueue: Request[];
  isRequestInProgress: boolean;

  constructor(device: BluetoothDevice) {
    this.ble = new BleManager(device);

    this.maxPacketLength = MAX_PACKET_LENGTH;
    this.requestQueue = [];
    this.isRequestInProgress = false;
  }

  // TODO add and call onDeviceReady
  async initialize(): Promise<void> {
    await this.ble.connect();
  }

  queuePacket(packet: Uint8Array): Promise<Object> {
    // TODO - refactor to avoid the 'promise constructor anti-pattern'
    // https://stackoverflow.com/questions/43036229/is-it-an-anti-pattern-to-use-async-await-inside-of-a-new-promise-constructor/43050114
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<string>(async (resolve, reject) => {
      if (packet.length > this.maxPacketLength) {
        reject(new Error("Payload length exceeds max packet length"));
        return;
      }

      const packetHandler = new CharacteristicChangedPacketHandler(
        resolve,
        reject
      );

      await this.ble.subscribeToCharacteristicValue(
        c.SMP_CHARACTERISTIC_UUID,
        c.SMP_SERVICE_UUID,
        packetHandler
      );

      const request = new WriteRequest(
        resolve,
        reject,
        c.SMP_CHARACTERISTIC_UUID,
        c.SMP_SERVICE_UUID,
        packet
      );

      this.queueRequest(request);
    });
  }

  queueRequest(request: Request): void {
    this.requestQueue.push(request);
    this.nextRequest();
  }

  async nextRequest(force?: boolean): Promise<void> {
    if (force) this.isRequestInProgress = false;
    if (this.isRequestInProgress) return;

    const request = this.requestQueue.shift();
    if (!request) return;

    this.isRequestInProgress = true;

    switch (request.type) {
      case Request.Type.WRITE: {
        const { characteristicUUID, payload, serviceUUID } =
          request as unknown as WriteRequest;

        await this.ble.writeCharacteristic(
          characteristicUUID,
          serviceUUID,
          payload
        );
        break;
      }
    }

    this.isRequestInProgress = false;
    this.nextRequest();
  }
}
