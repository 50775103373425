import { connect } from "react-redux";

import * as cookies from "../../cookies";
import { MainAppState } from "../../main/types";

import { resetPassword } from "../actions";
import { SsoResetPasswordPage } from "../components/SsoResetPasswordPage";

const mapStateToProps = (state: MainAppState) => {
  return {
    anonymousUuid: cookies.getAnonymousUserUuid(),
    ...state.sso.resetPassword,
  };
};

const mapDispatchToProps = {
  resetPassword,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SsoResetPasswordPage);
