import { get as lsGet } from "local-storage";
import { createSelector } from "reselect";

import * as PaxBle from "../../pax-ble";
import { UiFunction } from "../../pax-ble/types/VaporEngine";

import { getOrderedAccountDevices } from "../api/consumer/selectors/devices";
import { getPod } from "../api/consumer/selectors/strains";
import * as apiTypes from "../api/consumer/types";
import { getConnectedDevice } from "../bluetooth/connectedDevice";
import { MainAppState } from "../main/types";
import { PodData } from "../pods/types";
import * as c from "./constants";
import * as t from "./types";
import * as u from "./utils";

export const getActiveUiFunction = (
  state: MainAppState
): PaxBle.Types.VaporEngine.UiFunction | null => {
  const vaporEngine = state.device.attributes[c.ATTRIBUTE_VAPOR_ENGINE];
  return vaporEngine
    ? (vaporEngine.activeUiFunction as PaxBle.Types.VaporEngine.UiFunction)
    : null;
};

export const getIsCustomTemperatureModeActive = (
  state: MainAppState
): boolean => {
  const vaporEngine = state.device.attributes[c.ATTRIBUTE_VAPOR_ENGINE];
  if (!vaporEngine) return false;
  return vaporEngine.activeUiFunction === UiFunction.CUSTOM;
};

export const getIsAutoIdPodDataAvailable = (state: MainAppState): boolean =>
  state.device.pod.isAutoId && !!state.device.pod.strainId;

export const getIsDeviceLocked = (state: MainAppState): boolean =>
  state.device.attributes[c.ATTRIBUTE_LOCKED] === 1;

export const getBrightness = (state: MainAppState): number =>
  state.device.attributes[c.ATTRIBUTE_BRIGHTNESS];

export const getHeaterSetPoint = (state: MainAppState): number =>
  state.device.attributes[c.ATTRIBUTE_HEATER_SET_POINT];

export const getSelectedDeviceType = (
  state: MainAppState
): t.NullableDeviceType => state.device.selectedDeviceType;

const getHaptics = (state: MainAppState): number =>
  state.device.attributes[c.ATTRIBUTE_HAPTICS];

const getHapticMode = (state: MainAppState): number =>
  state.device.attributes[c.ATTRIBUTE_HAPTIC_MODE];

export const getHapticsForDevice = createSelector(
  [getSelectedDeviceType, getHaptics, getHapticMode],
  (deviceType, haptics, hapticMode) => {
    if (u.isPax3(deviceType)) {
      return hapticMode;
    }

    return haptics;
  }
);

export const getDevicePod = (state: MainAppState): PodData => state.device.pod;

export const getDevicePodStrainId = (state: MainAppState): string | null =>
  state.device?.pod.strainId;

export const getCurrentPodStrainId = (state: MainAppState): string | null =>
  state.device?.pod.strainId || lsGet(c.LS_KEY_CURRENT_POD_STRAIN_ID);

export const getExpertTempC10 = (state: MainAppState): number | null => {
  const devicePodStrainId = getDevicePodStrainId(state);
  if (!devicePodStrainId) return null;

  const pod = getPod(state, { strainId: devicePodStrainId });
  const expertTemp = pod?.strain.recommendedTemperature;

  return expertTemp ? expertTemp * 10 : null;
};

export const getFirmwareRevision = (state: MainAppState): string =>
  state.device.firmwareRevision;

export const getBatteryLevel = (state: MainAppState): number =>
  state.device.attributes.BATTERY;

export const getChargeStatus = (state: MainAppState): t.DeviceChargeStatus =>
  state.device.attributes[c.ATTRIBUTE_CHARGE_STATUS];

export const getIsDosePulseActive = (state: MainAppState): boolean =>
  !!state.device.attributes[c.ATTRIBUTE_VAPOR_ENGINE].dosePulse;

export const getIsPodInserted = (state: MainAppState): boolean =>
  state.device.attributes[c.ATTRIBUTE_POD_INSERTED] === 1;

export const getSession = (state: MainAppState): PaxBle.Types.EraPro.Session =>
  state.device.attributes[c.ATTRIBUTE_SESSION_CONTROL];

export const getPreferredTemperatureUnit = (
  state: MainAppState
): apiTypes.user.PreferredTemperatureUnit =>
  state.consumerApi.user.profile?.preferredTemperatureUnit ||
  apiTypes.user.PreferredTemperatureUnit.FAHRENHEIT;

export const getDeviceTemperature = createSelector(
  [getHeaterSetPoint, getSelectedDeviceType, getPreferredTemperatureUnit],
  (heaterSetPoint, deviceType, temperatureUnit) => {
    if (!heaterSetPoint || !deviceType) return;

    if (temperatureUnit === apiTypes.user.PreferredTemperatureUnit.FAHRENHEIT) {
      return u.setPointToFahrenheit(heaterSetPoint, deviceType);
    }

    return u.setPointToCelsius(heaterSetPoint, deviceType);
  }
);

const getCurrentHeaterSetPoint = (state: MainAppState): number =>
  state.device.attributes[c.ATTRIBUTE_ACTUAL_TEMP];

export const getCurrentTemperature = createSelector(
  [
    getPreferredTemperatureUnit,
    getCurrentHeaterSetPoint,
    getSelectedDeviceType,
  ],
  (temperatureUnit, currentSetPoint, deviceType) => {
    return temperatureUnit === apiTypes.user.PreferredTemperatureUnit.FAHRENHEIT
      ? u.setPointToFahrenheit(currentSetPoint, deviceType)
      : u.setPointToCelsius(currentSetPoint, deviceType);
  }
);

export const getDeviceSerialNumber = (
  state: MainAppState,
  deviceType: t.NullableDeviceType
): string | undefined => {
  switch (deviceType) {
    case PaxBle.Types.DeviceType.ERA:
      return state.device.k3Serial;
    case PaxBle.Types.DeviceType.PAX_3:
    case PaxBle.Types.DeviceType.PAX_35:
      return state.device.pax3Serial;
    case PaxBle.Types.DeviceType.ERA_PRO:
      return state.device.k4Serial;
    default:
      return;
  }
};

export const getIsDeviceConnected = (state: MainAppState): boolean =>
  state.device.connected;

export const getIsDeviceConnecting = (state: MainAppState): boolean =>
  state.device.connecting;

export const getDeviceConnectionStatus = createSelector(
  [getIsDeviceConnected, getIsDeviceConnecting],
  (isConnected, isConnecting): t.ConnectionStatus => {
    if (isConnecting) {
      return t.ConnectionStatus.CONNECTING;
    } else if (isConnected) {
      return t.ConnectionStatus.CONNECTED;
    } else {
      return t.ConnectionStatus.NOT_CONNECTED;
    }
  }
);

export const getDeviceNameFromAttribute = (state: MainAppState): string =>
  state.device.attributes.DEVICE_NAME;

export const getHasEncryptionErrors = (state: MainAppState): boolean =>
  state.device.hasEncryptionErrors;

export const getDeviceError = (state: MainAppState): string | undefined =>
  state.device.error;

export const getIsDeviceSyncing = (state: MainAppState): boolean =>
  state.device.isSyncing;

export const getDeviceName = createSelector(
  [
    getSelectedDeviceType,
    getDeviceNameFromAttribute,
    getConnectedDevice,
    getOrderedAccountDevices,
  ],
  (type, name, connectedDevice, accountDevices) => {
    const accountDevice = accountDevices.find(
      (accountDevice) => accountDevice.serialNumber === connectedDevice?.serial
    );

    if (u.isPax3(type)) return accountDevice?.deviceName || "PAX 3";

    if (
      type === PaxBle.Types.DeviceType.ERA &&
      connectedDevice &&
      !connectedDevice.supportsRenameDevice
    )
      return accountDevice?.deviceName || "ERA";

    if (type === PaxBle.Types.DeviceType.ERA_PRO && !name)
      return accountDevice?.deviceName || "ERA PRO";

    return accountDevice?.deviceName || name;
  }
);

export const getEraReplay = (
  state: MainAppState
): PaxBle.Types.Era.EraReplayJson =>
  state.device.attributes[c.ATTRIBUTE_REPLAY];

export const getShellColorCode = (state: MainAppState): t.DeviceColor =>
  state.device.attributes.SHELL_COLOR;

export const getTemperatureRange = createSelector(
  [getSelectedDeviceType, getPreferredTemperatureUnit],
  (type, temperatureUnit): t.TemperatureRange => {
    if (u.isPax3(type)) {
      if (temperatureUnit === apiTypes.user.PreferredTemperatureUnit.CELSIUS) {
        return [c.FLOWER_DEVICE_TEMP_MIN_C, c.FLOWER_DEVICE_TEMP_MAX_C];
      }
      return [c.FLOWER_DEVICE_TEMP_MIN_F, c.FLOWER_DEVICE_TEMP_MAX_F];
    }

    if (temperatureUnit === apiTypes.user.PreferredTemperatureUnit.CELSIUS) {
      return [c.PEN_TEMP_MIN_C, c.PEN_TEMP_MAX_C];
    }

    return [c.PEN_TEMP_MIN_F, c.PEN_TEMP_MAX_F];
  }
);

export const doesDeviceSupportPods = createSelector(
  [getIsDeviceConnected, getSelectedDeviceType],
  (isConnected, deviceType) => {
    if (!isConnected || !deviceType) return false;

    return (
      [PaxBle.Types.DeviceType.ERA, PaxBle.Types.DeviceType.ERA_PRO].indexOf(
        deviceType
      ) >= 0
    );
  }
);

export const getIsPax3Connected = createSelector(
  [getIsDeviceConnected, getSelectedDeviceType],
  (isConnected, deviceType) => {
    if (!isConnected || !deviceType) return false;

    return u.isPax3(deviceType);
  }
);
