import React from "react";
import { Redirect, Route, RouteComponentProps, Switch } from "react-router-dom";

import { PartnerDetailsPage } from "./components/PartnerDetails/PartnerDetailsPage";
import { PodsBatchListPage } from "./components/PodsBatchListPage";
import { PodsHomePage } from "./components/PodsHomePage";
import { PodsTestResultsPage } from "./components/PodsTestResultsPage";
import PodDetailsPage from "./containers/PodDetailsPage";
import PodsSearchPage from "./containers/PodsSearchPage";

export const ExploreRoutes: React.FC<RouteComponentProps> = () => (
  <Switch>
    <Route
      path="/explore"
      exact
      render={() => <Redirect to="/explore/pods" />}
    />
    <Route
      exact
      path="/explore/partners/:partnerId"
      component={PartnerDetailsPage}
    />
    <Route exact path="/explore/pods" component={PodsHomePage} />
    <Route exact path="/explore/pods/search" component={PodsSearchPage} />
    <Route exact path="/explore/pods/:strainId" component={PodDetailsPage} />
    <Route
      exact
      path="/explore/pods/:podId/pods-test-results"
      component={PodsTestResultsPage}
    />
    <Route
      exact
      path="/explore/pods/:strainId/:batchId/pods-test-results"
      component={PodsTestResultsPage}
    />
    <Route
      exact
      path="/explore/pods/:strainId/batch-list"
      component={PodsBatchListPage}
    />
    <Redirect from="*" to="/explore" />
  </Switch>
);
