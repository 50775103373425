import React from "react";
import * as Router from "react-router-dom";
import styled from "styled-components";

import { ParsedTestResultJson } from "../../../api/consumer/types/strain";
import * as r from "../../../main/routes";
import * as Card from "../../../shared/components/Card";
import { Row } from "../../../shared/components/Flex";
import * as Text from "../../../shared/components/Text";
import { formatTimestampWithDots } from "../../../shared/utils";

type PodTestResultsCardWithReportProps = {
  batchId?: string;
  parsedTestResults: ParsedTestResultJson;
  podId?: string;
  strainId?: string;
};

const PodTestResultsCardWithReport: React.FC<PodTestResultsCardWithReportProps> =
  ({ batchId, parsedTestResults, podId, strainId }) => {
    let routeToTestResultPage;
    if (podId) {
      routeToTestResultPage = r.podAutoTestResults(podId);
    } else if (batchId && strainId) {
      routeToTestResultPage = r.podManualTestResults(batchId, strainId);
    }

    return (
      <Card.Small data-testid="pod-test-results-card-with-report">
        <sc.TitleRow>
          <sc.Title>Pod Test Results</sc.Title>
        </sc.TitleRow>
        <sc.ThcCbdRow>
          <Row>
            <sc.ThcCbdValue data-testid="total-thc">
              {parsedTestResults.totalThcPercent.toFixed(0)}
              {"% "}
            </sc.ThcCbdValue>
            <sc.ThcCbdLabel>THC</sc.ThcCbdLabel>
          </Row>
          <sc.Separator />
          <Row>
            <sc.ThcCbdValue data-testid="total-cbd">
              {parsedTestResults.totalCbdPercent.toFixed(0)}
              {"% "}
            </sc.ThcCbdValue>
            <sc.ThcCbdLabel>CBD</sc.ThcCbdLabel>
          </Row>
        </sc.ThcCbdRow>
        <sc.TextRow>
          <sc.Text>Tested Date:</sc.Text>
          <sc.Text data-testid="tested-date">
            {formatTimestampWithDots(false, parsedTestResults.testDate)}
          </sc.Text>
        </sc.TextRow>
        <sc.TextRow>
          <sc.Text>Safety Tests:</sc.Text>
          <sc.TextTestResults data-testid="safety-tests" isPass={true}>
            {getTestResultsStatus(
              parsedTestResults.parsedTestResults.map(
                (status) => status.hasPassed
              )
            )}
          </sc.TextTestResults>
        </sc.TextRow>
        {routeToTestResultPage && (
          <sc.Link data-testid="link-to-page" to={routeToTestResultPage}>
            View full report
          </sc.Link>
        )}
      </Card.Small>
    );
  };

const isEveryItemTruthy = (array: boolean[]): boolean => array.every(Boolean);
function getTestResultsStatus(results: boolean[]): string {
  return isEveryItemTruthy(results) ? "Passed" : "Failed";
}

type TextTestResultsProps = {
  isPass: boolean;
};

const sc = {
  Link: styled(Router.Link)`
    color: var(--black);
    font-size: 14px;
    margin-top: 12px;
    text-transform: uppercase;
  `,

  Separator: styled.div`
    border-left: solid 1px var(--very-light-pink);
    height: 18px;
    width: 1px;
  `,

  Text: styled.div`
    ${Text.PlaaxLight}
    color: var(--black);
    font-size: 14px;
    text-transform: uppercase;
  `,

  TextRow: styled(Row)`
    justify-content: space-between;
    margin: 0 24px 8px;
    width: 100%;
  `,

  TextTestResults: styled.div<TextTestResultsProps>`
    color: ${(props) =>
      props.isPass ? "var(--teal-green)" : "var(--pale-red)"};
    font-size: 14px;
    height: 16px;
    text-transform: uppercase;
  `,

  ThcCbdLabel: styled.div`
    color: var(--black);
    font-size: 16px;
    text-transform: uppercase;
  `,

  ThcCbdRow: styled(Row)`
    justify-content: space-between;
    margin: 4px 0 12px;
    width: 164px;
  `,

  ThcCbdValue: styled.div`
    color: var(--black);
    font-size: 16px;
    white-space: pre;
  `,

  Title: styled.h3`
    ${Text.Plaax}
    color: var(--black);
    font-size: 20px;
    margin: 0 0 8px;
    text-transform: uppercase;
  `,

  TitleRow: styled(Row)`
    justify-content: center;
    position: relative;
    width: 100%;
  `,
};

export default PodTestResultsCardWithReport;
