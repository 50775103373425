import React from "react";
import styled, { css } from "styled-components";

import { ReactComponent as MapCircle } from "../../assets/images/find-my-pax/map-circle.svg";
import { MyPaxDeviceCard } from "../../shared/components/MyPaxDeviceCard";
import * as Text from "../../shared/components/Text";
import { useDesktopMediaQuery } from "../../shared/hooks";
import * as text from "../../shared/text";
import { desktopBreakpoint } from "../../shared/utils";

export type FindMyPaxCardProps = {
  hasDevices: boolean;
};

export const FindMyPaxCard: React.FC<FindMyPaxCardProps> = ({
  hasDevices,
  ...props
}) => {
  const isDesktop = useDesktopMediaQuery();

  return (
    <MyPaxDeviceCard {...props} data-testid="find-my-pax-card">
      <sc.MapCircle />
      <sc.TextContainer>
        <sc.Text>
          {hasDevices
            ? text.ENABLE_PAXFINDER_UNDER_EACH_DEVICE
            : text.ADD_A_DEVICE_TO_TRY_OUT_PAXFINDER}{" "}
          {isDesktop ? "→" : "↓"}
        </sc.Text>
      </sc.TextContainer>
    </MyPaxDeviceCard>
  );
};

const sc = {
  MapCircle: styled(MapCircle)`
    height: 72px;
    margin: 0 12px 0 16px;
    width: 72px;

    ${desktopBreakpoint(
      css`
        height: 80px;
        margin: 24px 0 0 0;
        width: 80px;
      `
    )}
  `,

  Text: styled.div`
    ${Text.PlaaxLight}
    font-size: 14px;
    line-height: 1.14;
    width: 124px;

    ${desktopBreakpoint(
      css`
        margin: 12px auto 0;
        text-align: center;
      `
    )}
  `,

  TextContainer: styled.div`
    flex: 1;
  `,
};
