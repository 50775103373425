export enum DeviceConnectionStep {
  ADD_DEVICE = "ADD_DEVICE",
  CLOSE_MODAL = "CLOSE_MODAL",
  DEVICE_TYPE_SELECTOR = "DEVICE_TYPE_SELECTOR",
  PAIRING_INSTRUCTIONS = "PAIRING_INSTRUCTIONS",
  RECENT_DEVICES = "RECENT_DEIVCES",
  SERIAL_INPUT = "SERIAL_INPUT",
  SHAKE_INSTRUCTIONS = "SHAKE_INSTRUCTIONS",
}

export type DeviceConnectionModalContentProps = {
  onStepCompleted: OnDeviceConnectionStepCompleted;
};

export type OnDeviceConnectionStepCompleted = (
  currentStep: DeviceConnectionStep,
  goBack?: boolean
) => void;

export type DeviceConnectionFlow = {
  [key: string]: {
    back: React.FC<DeviceConnectionModalContentProps> | string;
    next: React.FC<DeviceConnectionModalContentProps> | string;
  };
};

export type ModalStep = React.FC<DeviceConnectionModalContentProps>;
