import React from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import styled, { css } from "styled-components";

import { trackPartnerClick } from "../../../analytics/navigation/podDetails";
import * as consumerTypes from "../../../api/consumer/types";
import * as r from "../../../main/routes";
import { Modal } from "../../../modal/components/Modal";
import { BottomSheet } from "../../../shared/components/BottomSheet";
import { CircularBar } from "../../../shared/components/CircularBar";
import { Column, Row } from "../../../shared/components/Flex";
import { PartnerLogoWithCheckmark } from "../../../shared/components/PartnerLogoWithCheckmark";
import * as Text from "../../../shared/components/Text";
import { useDesktopMediaQuery } from "../../../shared/hooks";
import * as t from "../../../shared/text";
import { desktopBreakpoint } from "../../../shared/utils";

import { EFFECTS_TEXT_MAP } from "../../constants";

const BOTTOM_SHEET_HEIGHT = 200;
const EXTENDED_BOTTOM_SHEET_HEIGHT = 270;

export type EffectsDetailsProps = {
  effectData?: consumerTypes.strain.EffectJson;
  isOpen: boolean;
  onRequestClose: () => void;
  strain: consumerTypes.StrainJson;
};

export const EffectsDetails: React.FC<EffectsDetailsProps> = ({
  isOpen,
  effectData,
  onRequestClose,
  strain,
}) => {
  const isDesktop = useDesktopMediaQuery();

  if (!isOpen || !effectData) return null;

  if (isDesktop) {
    return (
      <sc.Modal isOpen={isOpen} onRequestClose={onRequestClose}>
        <sc.ModalHeader>{EFFECTS_TEXT_MAP[effectData.effect]}</sc.ModalHeader>
        <EffectsDetailsContent effect={effectData} strain={strain} />
      </sc.Modal>
    );
  }

  return (
    <BottomSheet
      data-testid="effect-details-bottom-sheet"
      isOpen={isOpen}
      height={
        effectData.wasBpSelected
          ? EXTENDED_BOTTOM_SHEET_HEIGHT
          : BOTTOM_SHEET_HEIGHT
      }
      onRequestClose={onRequestClose}
      title={EFFECTS_TEXT_MAP[effectData.effect]}
    >
      <EffectsDetailsContent effect={effectData} strain={strain} />
    </BottomSheet>
  );
};

type EffectsDetailsContentProps = {
  effect: consumerTypes.strain.EffectJson;
  strain: consumerTypes.StrainJson;
};

const EffectsDetailsContent: React.FC<EffectsDetailsContentProps> = ({
  effect,
  strain,
}) => {
  const dispatch = useDispatch();

  const history = useHistory();

  const handlePartnerLogoClick = (): void => {
    dispatch(trackPartnerClick(strain.partner.id, strain.id));

    history.push(r.partnerDetails(strain.partner.id));
  };

  return (
    <sc.Container>
      {effect.wasBpSelected && (
        <sc.Section>
          <sc.Column>
            <sc.Title>{t.PARTNER}</sc.Title>
            <sc.Text>
              <sc.PartnerName
                data-testid="partner-name"
                onClick={() =>
                  dispatch(trackPartnerClick(strain.partner.id, strain.id))
                }
                to={r.partnerDetails(strain.partner.id)}
              >
                {strain.partner.partnerName}
              </sc.PartnerName>
              {` ${t.SELECTED_THIS_EFFECT}`}
            </sc.Text>
          </sc.Column>
          <PartnerLogoWithCheckmark
            onClick={handlePartnerLogoClick}
            partner={strain?.partner}
          />
        </sc.Section>
      )}
      {effect.percentEffect > 0 && (
        <sc.Section>
          <sc.Column>
            <sc.Title>{t.COMMUNITY}</sc.Title>
            <sc.Text>
              {t.percentUsersFelt(
                effect.percentEffect,
                strain?.name || t.THIS_STRAIN
              )}
            </sc.Text>
          </sc.Column>
          <CircularBar percent={effect.percentEffect} />
        </sc.Section>
      )}
    </sc.Container>
  );
};

const sc = {
  Column: styled(Column)`
    margin-right: 16px;
  `,

  Container: styled(Column)`
    padding: 0 20px;
    width: 100%;

    ${desktopBreakpoint(
      css`
        min-width: 491px;
        padding: 0 24px 16px;
      `
    )}
  `,

  Modal: styled(Modal)`
    max-width: 491px;
  `,

  ModalHeader: styled.div`
    ${Text.MediumLLBold}
    border-bottom: 1px solid var(--khaki-20);
    margin-bottom: 8px;
    padding: 30px 0 16px;
    text-align: center;
  `,

  PartnerName: styled(Link)`
    ${Text.MediumLLMedium}
    color: var(--black);
    font-size: 14px;
    letter-spacing: -0.11px;
  `,

  Section: styled(Row)`
    align-items: center;
    border-bottom: 1px solid var(--khaki-20);
    justify-content: space-between;
    padding: 16px 0 16px;

    &:last-child {
      border-bottom: none;
    }
  `,

  Text: styled.div`
    ${Text.MediumLLMedium}
    font-size: 14px;
    letter-spacing: -0.105px;
    line-height: 24px;
  `,

  Title: styled.div`
    font-size: 14px;
    font-weight: bold;
    letter-spacing: -0.105px;
    line-height: 20px;
  `,
};
