import React from "react";
import Select, { Theme } from "react-select";
import { CSSObject, CSSProperties } from "styled-components";

export type DropdownProps = { [key in string]: unknown };

export const Dropdown: React.FC<DropdownProps> = (props) => (
  <div data-testid={props["data-testid"]}>
    <Select
      isSearchable={false}
      theme={DropdownTheme}
      styles={DropdownStyles}
      {...props}
    />
  </div>
);

const DropdownTheme = (theme: Theme): Theme => {
  const dropdownTheme: Theme = {
    ...theme,
    colors: {
      ...theme.colors,
      primary: "var(--black)",
      primary25: "var(--white)",
      primary50: "var(--white)",
    },
    spacing: {
      ...theme.spacing,
      controlHeight: 40,
    },
  };

  return dropdownTheme;
};

const DropdownStyles: DropdownProps = {
  control: (provided: CSSProperties): CSSProperties => ({
    ...provided,
    backgroundColor: "var(--white)",
    borderColor: "var(--black)",
    borderRadius: 6,
    borderWidth: "3px",
    boxShadow: "none",
    cursor: "pointer",
    fontSize: "14px",
    fontWeight: 600,
    padding: "0 16px 0 8px",
    textTransform: "uppercase",
  }),
  dropdownIndicator: (provided: CSSProperties): CSSProperties => ({
    ...provided,
    color: "var(--black) !important",
    padding: 0,
  }),
  indicatorSeparator: (provided: CSSProperties): CSSProperties => ({
    ...provided,
    display: "none",
  }),
  menu: (provided: CSSProperties): CSSProperties => ({
    ...provided,
    backgroundColor: "var(--white)",
    border: "2px solid var(--black)",
    borderRadius: 6,
    borderWidth: "3px",
    boxShadow: "none",
    overflow: "hidden",
    top: -8,
  }),
  menuList: (provided: CSSProperties): CSSProperties => ({
    ...provided,
    padding: 0,
  }),
  option: (provided: CSSProperties): CSSObject => ({
    ...provided,
    "&:hover": {
      backgroundColor: "var(--bluey-grey-80)",
      color: "var(--white)",
      cursor: "pointer",
    },
    "&:last-of-type": {
      borderBottom: "none",
    },
    borderBottom: "1px solid var(--very-light-pink-2)",
    fontSize: "14px",
    fontWeight: 600,
    height: "40px",
    paddingTop: "11px",
    textTransform: "uppercase",
  }),
  placeholder: (provided: CSSProperties): CSSProperties => ({
    ...provided,
    color: "var(--black)",
  }),
};
