import { Dispatch, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { trackStrainFavorite } from "../analytics/pods/strainFavorite";
import * as consumerApi from "../api/consumer";
import { useConnectedDevice } from "../device/hooks";
import * as deviceSelectors from "../device/selectors";
import { useFetchFirmwareInfo } from "../firmware/hooks";
import { MainAppState } from "../main/types";
import { RESPONSIVE_POD_ROW_WIDTH } from "../pods/constants";
import { useResponsiveScreenSize } from "../pods/hooks";
import { ResponsiveScreenSize } from "../pods/types";
import { useSmallScreenMediaQuery } from "../shared/hooks";
import * as c from "./constants";
import * as t from "./types";
import { getStrainWithFavoriteStatusMap } from "./utils";

/*
  This hook handles all things related to connecting a BLE device
  and pairing it with a user accounts. Including:
  * Start fetching logs when a BLE device is connected.
  * Sync the connected device's log offset with user account.
  * Associate the connected BLE device with the active user.
  * Update device name in user account when changed on device.
*/
export const usePAXDevice = (): void => {
  useAssociateDevice();
  useFetchFirmwareInfo();
  useFetchLogs();
};

/*
  This hook will associate the connected BLE device with
  active user. It will wait until both device name and
  shell color are read from the device which are both required
  to associate a device to an account. If device name is updated
  on the device, it will automatically update the user account.
*/
const useAssociateDevice = (): void => {
  const dispatch = useDispatch();
  const deviceName = useSelector(deviceSelectors.getDeviceNameFromAttribute);
  const deviceType = useSelector(deviceSelectors.getSelectedDeviceType);
  const isDeviceConnected = useSelector(deviceSelectors.getIsDeviceConnected);
  const shellColorCode = useSelector(deviceSelectors.getShellColorCode);
  const serialNumber = useSelector((state: MainAppState) =>
    deviceSelectors.getDeviceSerialNumber(state, deviceType)
  );

  useEffect(() => {
    if (isDeviceConnected && deviceType && serialNumber) {
      dispatch(
        consumerApi.actions.devices.associateDevice(
          deviceType,
          serialNumber,
          deviceName,
          shellColorCode
        )
      );
    }
  }, [
    deviceName,
    deviceType,
    dispatch,
    isDeviceConnected,
    serialNumber,
    shellColorCode,
  ]);
};

// This hook will start fetching logs for the connected device.
const useFetchLogs = (): void => {
  const connectedDevice = useConnectedDevice();

  // Most logs can be collected after the user respond to the usage agreement.
  // Specific tracking calls should check dataSharingPreferences as needed.
  // See https://paxlabs.atlassian.net/l/c/E10dALow for what needs user opt-in.
  const isShareDataFlagForUserUnset = useSelector(
    consumerApi.selectors.user.getIsShareDataFlagForUserUnset
  );

  useEffect(() => {
    if (!connectedDevice || isShareDataFlagForUserUnset) return;

    connectedDevice.fetchLogs();
  }, [connectedDevice, isShareDataFlagForUserUnset]);

  useLogOffsetSync();
};

// Updates the log sync offset for the connected device when a user signs in.
const useLogOffsetSync = (): void => {
  const connectedDevice = useConnectedDevice();
  const isSignedIn = useSelector(consumerApi.selectors.user.getIsUserSignedIn);
  const accountDevice = useSelector((state: MainAppState) => {
    if (!connectedDevice?.serial) return;
    return consumerApi.selectors.devices.getAccountDevice(state, {
      serial: connectedDevice?.serial,
    });
  });

  useEffect(() => {
    if (!connectedDevice) return;

    if (
      isSignedIn &&
      accountDevice?.logSyncOffset &&
      connectedDevice.logStore
    ) {
      connectedDevice.logStore.updateOffset(accountDevice.logSyncOffset);
    }
  }, [connectedDevice, isSignedIn, accountDevice]);
};

export const useMyPodsMobileColumn = (): number => {
  const screenSize = useResponsiveScreenSize();
  const isSmallScreen = useSmallScreenMediaQuery();

  if (screenSize === ResponsiveScreenSize.SMALL) {
    return isSmallScreen
      ? c.MOBILE_SMALL_COLUMN_WIDTH
      : c.MOBILE_DEFAULT_COLUMN_WIDTH;
  }

  const gridWidth = RESPONSIVE_POD_ROW_WIDTH[screenSize];
  if (gridWidth > c.MOBILE_LARGE_COLUMN_WIDTH) {
    return c.MOBILE_LARGE_COLUMN_WIDTH;
  }

  return gridWidth;
};

type UseFavoritePod = (strain: consumerApi.types.strain.StrainJson) => {
  createFavorite: () => Promise<void>;
  deleteFavorite: () => Promise<void>;
  isFavorited: boolean;
};

export const useFavoritePod: UseFavoritePod = (strain) => {
  const dispatch = useDispatch();
  const [isFavorited, setIsFavorited] = useState<boolean>(!!strain.isFavorited);

  useEffect(() => {
    setIsFavorited(!!strain.isFavorited);
  }, [strain]);

  const createFavorite = async () => {
    if (isFavorited) return;

    try {
      setIsFavorited(true);
      await createFavoriteStrain(strain, dispatch);
    } catch (e) {
      setIsFavorited(false);
    }
  };

  const deleteFavorite = async () => {
    if (!isFavorited) return;

    try {
      setIsFavorited(false);
      await deleteFavoriteStrain(strain, dispatch);
    } catch (e) {
      setIsFavorited(true);
    }
  };

  return { createFavorite, deleteFavorite, isFavorited };
};

type CreateFavoriteStrain = (
  strain: consumerApi.types.strain.StrainJson,
  dispatch: Dispatch<unknown>
) => Promise<unknown>;

const createFavoriteStrain: CreateFavoriteStrain = async (strain, dispatch) => {
  await consumerApi.fetch<unknown>(
    consumerApi.paths.favorites.favoriteStrain(strain.id),
    {
      data: {
        clientTimestamp: new Date().toISOString(),
      },
      method: "POST",
    }
  );

  dispatch(
    consumerApi.actions.strains.receivedStrain({
      ...strain,
      isFavorited: true,
    })
  );

  dispatch(trackStrainFavorite(strain, true));
};

type DeleteFavoriteStrain = (
  strain: consumerApi.types.strain.StrainJson,
  dispatch: Dispatch<unknown>
) => Promise<unknown>;

const deleteFavoriteStrain: DeleteFavoriteStrain = async (strain, dispatch) => {
  await consumerApi.fetch<unknown>(
    consumerApi.paths.favorites.favoriteStrain(strain.id),
    { method: "DELETE" }
  );

  dispatch(
    consumerApi.actions.strains.receivedStrain({
      ...strain,
      isFavorited: false,
    })
  );

  dispatch(trackStrainFavorite(strain, false));
};

export type UseMyFavoritesOptions = {
  count: number;
  fetchNextPage: () => Promise<void>;
  hasFetchedFirstPage: boolean;
  isFetching: boolean;
  nextFetchUrl: string | null | undefined;
  onHeartClick: t.OnHeartClick;
  pods: t.StrainWithFavoriteStatus[];
};

export type UseMyFavorites = () => UseMyFavoritesOptions;

export const useMyFavorites: UseMyFavorites = () => {
  const appDispatch = useDispatch();

  const [
    { data, hasFetchedFirstPage, isFetching, nextFetchUrl },
    paginatedFetchFunctions,
  ] = consumerApi.paginatedFetch.usePaginatedFetch<consumerApi.types.favorites.FavoriteStrainJson>(
    consumerApi.paths.favorites.favorites(),
    { requiresAuthentication: true }
  );

  const [strainWithFavoriteStatusMap, setStrainWithFavoriteStatusMap] =
    useState<t.StrainWithFavoriteStatusMap>(
      getStrainWithFavoriteStatusMap(data)
    );

  // Add new favorites to map (used when fetching new pages)
  useEffect(() => {
    // Don't overwrite map to avoid losing changes to favorite status.
    const favoritesMap = getStrainWithFavoriteStatusMap(data);
    setStrainWithFavoriteStatusMap({
      ...favoritesMap,
      ...strainWithFavoriteStatusMap,
    });

    // Only track when favorites changes.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleHeartClick = async (
    strain: consumerApi.types.strain.StrainJson,
    dispatch = appDispatch
  ) => {
    const isCurrentlyFavorited =
      strainWithFavoriteStatusMap[strain.id].isFavorited;

    try {
      // Update the strainWithFavoriteStatusMap for the UI
      setStrainWithFavoriteStatusMap({
        ...strainWithFavoriteStatusMap,
        [strain.id]: {
          ...strainWithFavoriteStatusMap[strain.id],
          isFavorited: !isCurrentlyFavorited,
        },
      });

      // Update the favorite status on the backend
      isCurrentlyFavorited
        ? await deleteFavoriteStrain(strain, dispatch)
        : await createFavoriteStrain(strain, dispatch);
    } catch (e) {
      setStrainWithFavoriteStatusMap({
        ...strainWithFavoriteStatusMap,
        [strain.id]: {
          ...strainWithFavoriteStatusMap[strain.id],
          isFavorited: isCurrentlyFavorited,
        },
      });
    }
  };

  return {
    count: Object.values(strainWithFavoriteStatusMap).length,
    fetchNextPage: paginatedFetchFunctions.fetchNextPage,
    hasFetchedFirstPage,
    isFetching,
    nextFetchUrl,
    onHeartClick: handleHeartClick,
    pods: Object.values(strainWithFavoriteStatusMap),
  };
};
