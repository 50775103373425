import * as PaxBle from "../../pax-ble";

import { getConnectedDevice } from "../bluetooth/connectedDevice";

const getPax3Device = () => getConnectedDevice() as PaxBle.Pax3Device;

export const setColorMode =
  (colorMode: PaxBle.Types.Pax3.ColorMode, pax3Device = getPax3Device()) =>
  (): void => {
    pax3Device.writeColorMode(colorMode);

    pax3Device.readColorTheme();
  };

export const setDefaultColorMode =
  (pax3Device = getPax3Device()) =>
  (): void => {
    pax3Device.writeDefaultColorMode();

    pax3Device.readColorTheme();
  };

export type SetDynamicMode = (
  dynamicMode: PaxBle.Types.Pax3.DynamicMode,
  pax3Device?: PaxBle.Pax3Device
) => void;

export const setDynamicMode: SetDynamicMode = (
  dynamicMode,
  pax3Device = getPax3Device()
) => {
  pax3Device.writeDynamicMode(dynamicMode);
  pax3Device.readAttributes([PaxBle.Constants.ATTRIBUTE_DYNAMIC_MODE]);
};
