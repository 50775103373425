import styled, { css } from "styled-components";

import { desktopBreakpoint } from "../../shared/utils";

const PodsTestResultsCard = styled.div`
  align-items: left;
  border: 2px var(--very-light-pink);
  border-width: 0 0 2px 0;
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  padding: 20px 0;

  ${desktopBreakpoint(css`
    border-radius: 8px;
    border-width: 2px;
    flex: 0 0 398px;
    margin-right: 12px;
    padding: 20px 20px 24px;

    &:last-child {
      margin-right: 0;
    }
  `)}
`;

type PodsTestResultsCardProps = {
  borderStyle: string;
};

export const BigCard = styled(PodsTestResultsCard)<PodsTestResultsCardProps>`
  border-style: ${(props) => props.borderStyle};

  ${desktopBreakpoint(css`
    height: 276px;
    min-width: 398px;
  `)}
`;

export const SmallCard = styled(PodsTestResultsCard)<PodsTestResultsCardProps>`
  border-style: ${(props) => props.borderStyle};

  ${desktopBreakpoint(css`
    height: 148px;
    min-width: 398px;
  `)}
`;
