import React from "react";
import styled, { css } from "styled-components";

import * as Text from "../../shared/components/Text";
import { desktopBreakpoint } from "../../shared/utils";

import { BigCard } from "./PodsTestResultsCard";

const PodsPaxCommitmentCard: React.FC = () => {
  return (
    <sc.Container>
      <BigCard data-testid="pods-pax-commitment-card" borderStyle="none">
        <sc.Title>Pax&apos;s Commitment To Safety</sc.Title>
        <sc.Description>
          As our customer, we think it&apos;s your right to know what is in your
          cannabis, so you can make informed, confident choices. That&apos;s
          what responsible cannabis use looks like, and it is core to who we are
          at PAX.
        </sc.Description>
      </BigCard>
    </sc.Container>
  );
};

const sc = {
  Container: styled.div`
    ${desktopBreakpoint(css`
      margin-left: auto;
      margin-right: 0;
    `)}
  `,

  Description: styled.p`
    ${Text.PlaaxLight}
    color: var(--brownish-grey);
    font-size: 14px;
    line-height: 1;
    margin: 0 0 8px;
    max-width: var(--pod-test-result-content-width);
    text-align: left;
  `,

  Title: styled.div`
    color: var(--brownish-grey);
    font-size: 20px;
    margin-bottom: 10px;
    text-transform: uppercase;
  `,
};

export default PodsPaxCommitmentCard;
