import React, { useState } from "react";
import * as redux from "react-redux";

import * as consumerApi from "../../api/consumer";
import { ModalProps } from "../../modal/components/Modal";
import { useStrainById } from "../../pods/hooks";
import * as text from "../../shared/text";
import { UseDispatch } from "../../shared/types";

import * as c from "../constants";
import { usePodFeedbackModalController } from "../hooks";
import { ModalContentStyles } from "../styledComponents";
import { StrainDetailsCard } from "./PodFeedbackForm/StrainDetailsCard";
import { PodFeedbackForm } from "./PodFeedbackForm";
import { ThankYouPage } from "./ThankYouPage";

export type PodFeedbackModalEditContentProps = ModalProps & {
  initialValues?: Partial<consumerApi.types.reviews.StrainReviewJson> | null;
  reviewId?: string;
  saveReview?: consumerApi.actions.reviews.SaveReview;
  strainId: string;
  submitLabel?: string;
  useDispatch?: UseDispatch;
};

export const PodFeedbackModalEditContent: React.FC<PodFeedbackModalEditContentProps> =
  ({
    initialValues = c.DEFAULT_FORM_VALUES,
    onRequestClose,
    reviewId,
    saveReview = consumerApi.actions.reviews.saveReview,
    strainId,
    submitLabel = text.SUBMIT_REVIEW,
    useDispatch = redux.useDispatch,
    ...props
  }) => {
    const dispatch = useDispatch();
    const controller = usePodFeedbackModalController(props.isOpen);
    const { strain } = useStrainById(strainId) || {};
    const [hasChanged, setHasChanged] = useState<boolean>(false);

    const handleCloseClick = (): void => {
      if (onRequestClose) onRequestClose();
    };

    const saveForm = (
      values: Partial<consumerApi.types.reviews.StrainReviewJson>
    ) => {
      dispatch(
        saveReview(
          strainId,
          {
            rating: values.rating,
            selectedEffects: values.selectedEffects,
            text: values.text,
          },
          reviewId
        )
      );
    };

    const handleFormFieldChange = (
      fieldName: string,
      values: Partial<consumerApi.types.reviews.StrainReviewJson>
    ): void => {
      if (fieldName === "rating") {
        saveForm(values);
      }

      setHasChanged(true);
    };

    const handleSubmitClick = (
      values: Partial<consumerApi.types.reviews.StrainReviewJson>
    ) => {
      saveForm(values);

      controller.showThankYouPage();
    };

    const handleDoneClick = () => {
      if (onRequestClose) onRequestClose();
    };

    if (!strain) return null;

    return (
      <ModalContentStyles.Container
        data-testid="pod-feedback-edit-modal"
        $desktopWidth={controller.desktopWidth}
        {...props}
      >
        <ModalContentStyles.Content
          className="modal-content"
          $opacity={controller.contentOpacity}
        >
          <ModalContentStyles.Header>
            <ModalContentStyles.Close
              data-testid="close-edit-modal"
              onClick={handleCloseClick}
            />
            <ModalContentStyles.HeaderTitle>
              {text.REVIEW}
            </ModalContentStyles.HeaderTitle>
          </ModalContentStyles.Header>
          <ModalContentStyles.Body>
            {!controller.shouldShowThankYouPage ? (
              <>
                <StrainDetailsCard strain={strain} />
                <PodFeedbackForm
                  initialValues={initialValues}
                  isSubmitDisabled={!!(reviewId && !hasChanged)}
                  onFieldChange={handleFormFieldChange}
                  onSubmit={handleSubmitClick}
                  submitLabel={submitLabel}
                />
              </>
            ) : (
              <ThankYouPage
                onDoneClick={handleDoneClick}
                onPodRecommendationClick={onRequestClose}
                strain={strain}
              />
            )}
          </ModalContentStyles.Body>
        </ModalContentStyles.Content>
      </ModalContentStyles.Container>
    );
  };
