import React, { useEffect, useState } from "react";
import styled, { SimpleInterpolation } from "styled-components";

import * as apiTypes from "../../../api/consumer/types";
import { PreferredTemperatureUnit } from "../../../api/consumer/types/user";
import { TEMPERATURE_UNITS } from "../../../data/temperatureUnits";

type OwnProps = {
  desiredTemperature: number;
};

export type StateProps = {
  deviceTemperature?: number;
  isDeviceLocked: boolean;
  onChange?: (temperature: number, unit: PreferredTemperatureUnit) => void;
  temperatureRange: number[];
  temperatureUnit: apiTypes.user.PreferredTemperatureUnit;
};

type FineControlsProps = StateProps & OwnProps;

export const FineControls: React.FC<FineControlsProps> = ({
  desiredTemperature,
  deviceTemperature,
  isDeviceLocked,
  onChange,
  temperatureRange,
  temperatureUnit,
  ...props
}) => {
  const [tempMin, tempMax] = temperatureRange;
  const [displayedTemperature, setDisplayedTemperature] = useState<number>(
    deviceTemperature ? Math.round(deviceTemperature) : 0
  );

  useEffect(() => {
    setDisplayedTemperature(Math.round(desiredTemperature));
  }, [desiredTemperature]);

  useEffect(() => {
    if (!deviceTemperature) return;
    setDisplayedTemperature(Math.round(deviceTemperature));
  }, [deviceTemperature]);

  const handleTemperatureChange = (change: number): void => {
    if (!onChange || !deviceTemperature) return;

    const desiredTemp = displayedTemperature + change;

    if (desiredTemp < tempMin || desiredTemp > tempMax) return;

    setDisplayedTemperature(desiredTemp);

    if (Math.abs(desiredTemp - deviceTemperature) >= 2) {
      onChange(desiredTemp, temperatureUnit);
    }
  };

  return (
    <sc.FineControlsContainer {...props}>
      <sc.FineControlsButton
        isDeviceLocked={isDeviceLocked}
        onClick={(): void => handleTemperatureChange(-1)}
      >
        -
      </sc.FineControlsButton>
      <sc.Temperature isDeviceLocked={isDeviceLocked}>
        {displayedTemperature}
        {displayedTemperature && (
          <sc.DegreeContainer>
            <sc.DegreeSymbol>°</sc.DegreeSymbol>
            <sc.DegreeType>
              {TEMPERATURE_UNITS[temperatureUnit].shorthand}
            </sc.DegreeType>
          </sc.DegreeContainer>
        )}
      </sc.Temperature>
      <sc.FineControlsButton
        isDeviceLocked={isDeviceLocked}
        onClick={(): void => handleTemperatureChange(1)}
      >
        +
      </sc.FineControlsButton>
    </sc.FineControlsContainer>
  );
};

type DeviceLockedProps = {
  isDeviceLocked?: boolean;
};

const sc = {
  DegreeContainer: styled.div`
    height: 30px;
    position: absolute;
    right: -23px;
    top: -1px;
  `,
  DegreeSymbol: styled.span`
    font-size: 20px;
    letter-spacing: 0.81px;
    line-height: 1.92;
    vertical-align: top;
  `,

  DegreeType: styled.span`
    font-size: 20px;
    letter-spacing: 0.75px;
    line-height: 2.08;
    vertical-align: top;
  `,

  FineControlsButton: styled.button<DeviceLockedProps>`
    align-items: center;
    background-color: var(--white);
    border: none;
    border-radius: 15px;
    box-shadow: 0 1px 3px 0 rgba(40, 40, 40, 0.2);
    color: ${({ isDeviceLocked }): SimpleInterpolation =>
      isDeviceLocked ? "var(--very-light-pink)" : "inherit"};
    display: flex;
    font-size: 22px;
    height: 24px;
    justify-content: center;
    padding: 0;
    pointer-events: ${({ isDeviceLocked }): SimpleInterpolation =>
      isDeviceLocked ? "none" : "auto"};
    width: 24px;
  `,

  FineControlsContainer: styled.div`
    align-items: baseline;
    display: flex;
    justify-content: center;
    text-align: center;
  `,

  Temperature: styled.div<DeviceLockedProps>`
    color: ${({ isDeviceLocked }): SimpleInterpolation =>
      isDeviceLocked ? "var(--very-light-pink)" : "inherit"};
    font-size: 56px;
    height: 56px;
    margin: 0 18px;
    position: relative;
  `,
};
