import React from "react";
import styled, { css, SimpleInterpolation } from "styled-components";

import { DeviceJson } from "../../api/consumer/types/device";
import { Column } from "../../shared/components/Flex";
import { Add as AddIcon } from "../../shared/components/Icons";
import * as Icons from "../../shared/components/Icons";
import { MyPaxDeviceCard } from "../../shared/components/MyPaxDeviceCard";
import * as Text from "../../shared/components/Text";
import { useDesktopMediaQuery } from "../../shared/hooks";
import * as text from "../../shared/text";
import { desktopBreakpoint, getLastUsed } from "../../shared/utils";

import { DeviceLogo } from "./DeviceLogo";

export type DeviceCardProps = {
  connected: boolean;
  device: DeviceJson;
  onClick?: () => void;
  shouldShowConnectionStatus?: boolean;
};

export const DeviceCard: React.FC<DeviceCardProps> = ({
  connected,
  device,
  shouldShowConnectionStatus = true,
  ...props
}) => {
  const isDesktop = useDesktopMediaQuery();

  return (
    <MyPaxDeviceCard data-testid="device-card" {...props}>
      <sc.DeviceLogo data-testid="device-logo" device={device}></sc.DeviceLogo>
      <sc.TextContainer>
        <sc.DeviceName shouldCenterVertically={!shouldShowConnectionStatus}>
          {device.deviceName}
        </sc.DeviceName>
        {shouldShowConnectionStatus && (
          <sc.ConnectionStatus connected={connected}>
            {connected ? text.CONNECTED : text.DISCONNECTED}
          </sc.ConnectionStatus>
        )}
      </sc.TextContainer>
      <sc.TimeSinceLastConnected>
        {connected ? text.NOW : getLastUsed(device.latestClientTimestamp)}
      </sc.TimeSinceLastConnected>
      {!isDesktop && connected && (
        <sc.CheckmarkContainer>
          <Icons.CheckWhite />
        </sc.CheckmarkContainer>
      )}
    </MyPaxDeviceCard>
  );
};

export type AddDeviceCardProps = {
  isDisabled?: boolean;
  onClick?: () => void;
};

export const AddDeviceCard: React.FC<AddDeviceCardProps> = ({
  isDisabled,
  ...props
}) => {
  return (
    <sc.MyPaxDeviceCard data-testid="add-device-card" {...props}>
      <sc.AddIconContainer
        data-testid="add-device-icon"
        isDisabled={isDisabled}
      >
        <AddIcon $isDisabled={isDisabled} />
      </sc.AddIconContainer>
    </sc.MyPaxDeviceCard>
  );
};

type AddIconContainerProps = {
  isDisabled?: boolean;
};

type ConnectionStatusProps = {
  connected: boolean;
};

type DeviceNameProps = {
  shouldCenterVertically?: boolean;
};

const sc = {
  AddIconContainer: styled.div<AddIconContainerProps>`
    border: solid 1px;
    border-color: ${({ isDisabled }): SimpleInterpolation =>
      isDisabled ? "var(--black-20)" : "var(--black)"};
    border-radius: 32px;
    display: flex;
    height: 48px;
    width: 48px;

    & svg {
      height: 28px;
      margin: 9px;
      width: 28px;
    }
  `,

  CheckmarkContainer: styled.div`
    align-items: center;
    background-color: var(--black);
    border-radius: 10px;
    display: flex;
    height: 20px;
    justify-content: center;
    position: absolute;
    right: 16px;
    top: 16px;
    width: 20px;

    & svg {
      height: 10px;
      width: 10px;
    }
  `,

  ConnectionStatus: styled.div<ConnectionStatusProps>`
    ${Text.PlaaxLight}
    color: ${({ connected }): SimpleInterpolation =>
      connected ? "var(--teal-green)" : "var(--brown-grey)"};
    font-size: 15px;
    text-align: center;
  `,

  DeviceLogo: styled(DeviceLogo)`
    margin: 0 12px 0 16px;

    ${desktopBreakpoint(
      css`
        margin: -6px 0 0px 0;
      `
    )}
  `,

  DeviceName: styled.div<DeviceNameProps>`
    ${Text.PlaaxLight}
    font-size: 18px;
    line-height: 1.32;
    margin: ${({ shouldCenterVertically }): SimpleInterpolation =>
      shouldCenterVertically ? "16px 0" : "0 0 4px 0"};
    overflow: hidden;
    padding: 2px 3px 0;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
  `,

  MyPaxDeviceCard: styled(MyPaxDeviceCard)`
    justify-content: center;
  `,

  TextContainer: styled(Column)`
    align-items: flex-start;
    flex-grow: 1;

    ${desktopBreakpoint(
      css`
        align-items: center;
        flex-grow: 0;
      `
    )}
  `,

  TimeSinceLastConnected: styled.div`
    ${Text.PlaaxLight}
    align-self: flex-end;
    font-size: 13px;
    margin: 16px;
    opacity: 0.32;

    ${desktopBreakpoint(
      css`
        margin: 4px auto 0;
      `
    )}
  `,
};
