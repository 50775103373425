import { connect } from "react-redux";

import { navigator } from "../../../navigator";

import * as deviceActions from "../../device/actions";
import { getDeviceConnectionStatus } from "../../device/selectors";
import { MainAppState } from "../../main/types";

import * as myDevice from "../components/MyDevice";

const mapStateToProps = (state: MainAppState): myDevice.StateProps => ({
  connectionStatus: getDeviceConnectionStatus(state),
  supportsBluetooth: !!navigator.bluetooth,
});

const mapDispatchToProps: myDevice.DispatchProps = {
  connectToDevice: deviceActions.connectToDevice,
  deviceConnecting: deviceActions.deviceConnecting,
  disconnectDevice: deviceActions.disconnectDevice,
  removeDeviceInUserAccount: deviceActions.removeDeviceInUserAccount,
  selectDeviceType: deviceActions.selectDeviceType,
};

export default connect(mapStateToProps, mapDispatchToProps)(myDevice.MyDevice);
