import React from "react";
import { Route, RouteComponentProps, Switch } from "react-router-dom";

import ssoCreateAccount from "./containers/SsoCreateAccountPage";
import ssoEditAccount from "./containers/SsoEditAccountPage";
import ssoEmailVerificationRequestSent from "./containers/SsoEmailVerificationRequestSentPage";
import ssoEmailVerificationTokenExpired from "./containers/SsoEmailVerificationTokenExpiredPage";
import ssoForgotPassword from "./containers/SsoForgotPasswordPage";
import ssoLogin from "./containers/SsoLoginPage";
import ssoResetPasswordEmailSent from "./containers/SsoResetPasswordEmailSentPage";
import ssoResetPassword from "./containers/SsoResetPasswordPage";
import ssoResetPasswordTokenExpired from "./containers/SsoResetPasswordTokenExpiredPage";
import ssoVerifyEmail from "./containers/SsoVerifyEmailPage";

export const SsoRoutes: React.FC<RouteComponentProps> = () => (
  <Switch>
    <Route exact path="/sso/login" component={ssoLogin} />
    <Route exact path="/sso/create-account" component={ssoCreateAccount} />
    <Route exact path="/sso/edit-account" component={ssoEditAccount} />
    <Route exact path="/sso/forgot-password" component={ssoForgotPassword} />
    <Route exact path="/sso/reset-password" component={ssoResetPassword} />
    <Route
      exact
      path="/sso/reset-password-email-sent"
      component={ssoResetPasswordEmailSent}
    />
    <Route
      exact
      path="/sso/reset-password-token-expired"
      component={ssoResetPasswordTokenExpired}
    />
    <Route
      exact
      path="/sso/email-verification-request-sent"
      component={ssoEmailVerificationRequestSent}
    />
    <Route
      exact
      path="/sso/email-verification-token-expired"
      component={ssoEmailVerificationTokenExpired}
    />
    <Route exact path="/sso/verify-email" component={ssoVerifyEmail} />
  </Switch>
);
