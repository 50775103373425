import React from "react";

import * as hooks from "../../../pods/hooks";

import PodTestResultsCardNoReport from "./PodTestResultsCardNoReport";
import PodTestResultsCardWithReport from "./PodTestResultsCardWithReport";

export type PodManualTestResultsCardProps = {
  strainId?: string;
  useBatchListFetch?: hooks.UseBatchListFetch;
};

const PodManualTestResultsCard: React.FC<PodManualTestResultsCardProps> = ({
  strainId,
  useBatchListFetch = hooks.useBatchListFetch,
}) => {
  const batchList = useBatchListFetch(strainId);

  const latestBatchWithReport = batchList.find(
    (batch) => batch.parsedBatchResult
  );

  if (latestBatchWithReport && latestBatchWithReport.parsedBatchResult) {
    return (
      <PodTestResultsCardWithReport
        batchId={latestBatchWithReport.batchId}
        parsedTestResults={latestBatchWithReport.parsedBatchResult}
        strainId={latestBatchWithReport.strainId}
      />
    );
  }

  return <PodTestResultsCardNoReport />;
};

export default PodManualTestResultsCard;
