import Bugsnag from "@bugsnag/js";

import * as consumerApi from "../api/consumer";
import { BannerType, showFlashBanner } from "../shared/components/FlashBanner";
import { Action, AppThunk } from "../shared/types";
import { MailingList } from "./types";

export const CREATE_MAILING_LIST_SUBSCRIPTION_START =
  "CREATE_MAILING_LIST_SUBSCRIPTION_START";
export const CREATE_MAILING_LIST_SUBSCRIPTION_END =
  "CREATE_MAILING_LIST_SUBSCRIPTION_END";

export type CreateMailingListSubscription = (
  mailingList: MailingList,
  email: string,
  fetch?: consumerApi.Fetch
) => AppThunk;

export const createMailingListSubscription: CreateMailingListSubscription =
  (mailingList, email, fetch = consumerApi.fetch) =>
  async (dispatch): Promise<Action> => {
    const path =
      consumerApi.paths.mailingList.mailingingListSubscription(mailingList);

    dispatch({
      payload: { path },
      type: CREATE_MAILING_LIST_SUBSCRIPTION_START,
    });

    try {
      await fetch(path, { data: { email }, method: "POST" });

      dispatch(
        showFlashBanner(`Success! You're subscribed to our mailing list.`)
      );
      return dispatch({ type: CREATE_MAILING_LIST_SUBSCRIPTION_END });
    } catch (e) {
      dispatch(
        showFlashBanner(
          "Uh oh! There was a problem. Please try again.",
          BannerType.ERROR
        )
      );
      dispatch({ type: CREATE_MAILING_LIST_SUBSCRIPTION_END });
      Bugsnag.notify(e as Error);
      return Promise.reject(e);
    }
  };
