import { AxiosError } from "axios";
import { Dispatch, useEffect, useReducer, useState } from "react";
import { useQuery, UseQueryOptions, UseQueryResult } from "react-query";
import { useDispatch, useSelector } from "react-redux";

import { MainAppState } from "../../main/types";
import * as podsActions from "../../pods/actions";
import * as flashBanner from "../../shared/components/FlashBanner";
import * as text from "../../shared/text";
import { Action } from "../../shared/types";

import * as userSelectors from "./selectors/user";
import * as a from "./actions";
import { fetch } from "./index";
import * as p from "./paths";
import * as s from "./selectors";
import * as t from "./types";

export const useUser = (
  getAuthenticationToken = userSelectors.getAuthenticationToken
): void => {
  const dispatch = useDispatch();
  const token = useSelector(getAuthenticationToken);

  // Create new anonymous user if no user exists
  useEffect(() => {
    if (token) return;
    let didCancel = false;

    async function createNewAnonymousUser() {
      try {
        const anonymousUserResponse = await fetch<t.user.UserResponse>(
          p.users.anonymousUsers(),
          { method: "POST" }
        );

        if (didCancel || !anonymousUserResponse) return;

        const { user, credentials } = anonymousUserResponse;
        dispatch(a.users.receivedAnonymousUser(user, credentials.token));
      } catch (e) {
        dispatch(
          flashBanner.showFlashBanner(
            text.OOPS_LOOKS_LIKE_OUR_SERVERS_ARE_BUSY,
            flashBanner.BannerType.ERROR,
            true
          )
        );
      }
    }

    createNewAnonymousUser();

    return (): void => {
      didCancel = true;
    };
  }, [dispatch, token]);

  // Get the current user and update redux state
  useEffect(() => {
    if (!token) return;
    let didCancel = false;

    async function getCurrentUser(): Promise<void> {
      try {
        const user = await fetch<t.user.User>(p.users.activeUser());

        if (didCancel || !user) return;

        dispatch(
          user.type !== t.user.UserType.REGISTERED
            ? a.users.receivedRegisteredUser(user)
            : a.users.receivedAnonymousUser(user)
        );

        // Update the pods state with the new user location status
        user.location
          ? dispatch({ type: podsActions.SET_USER_LOCATION_DETERMINED })
          : dispatch({ type: podsActions.SET_USER_LOCATION_UNDETERMINED });
      } catch (e) {
        // Bugsnag has been notified of fetch errors; do nothing.
      }
    }

    getCurrentUser();

    return (): void => {
      didCancel = true;
    };
  }, [dispatch, token]);
};

type UseExperiments = (
  getAuthenticationToken?: userSelectors.GetAuthenticationToken
) => void;

export const useExperiments: UseExperiments = (
  getAuthenticationToken = userSelectors.getAuthenticationToken
) => {
  const dispatch = useDispatch();
  const token = useSelector(getAuthenticationToken);

  useEffect(() => {
    if (!token) return;

    const fetchExperiments = async () => {
      try {
        const experiments = await fetch<t.experiments.ExperimentsJson>(
          p.experiments.assignedVariations()
        );
        dispatch(a.experiments.receivedExperiments(experiments));
      } catch (err) {
        // Bugsnag has been notified of fetch errors; do nothing.
      }
    };

    fetchExperiments();
  }, [dispatch, token]);
};

// These are strictly to make the useExperimentVariation typing more readable.
type SucceedVariation = () => void;
type ViewVariation = () => void;

type VariationProps = {
  succeedVariation: SucceedVariation;
  variation: t.experiments.Variation | null | undefined;
  viewVariation: ViewVariation;
};

type UseExperimentVariation = (
  experimentCode: t.experiments.ExperimentCode
) => VariationProps;

// Do not delete - this will likely be used intermittently
export const useExperimentVariation: UseExperimentVariation = (
  experimentCode
) => {
  const dispatch = useDispatch();

  const variation = useSelector((state: MainAppState) =>
    s.experiments.getVariation(state, {
      experimentCode,
    })
  );

  const viewVariation = () =>
    dispatch(a.experiments.viewedExperiment(experimentCode));

  const succeedVariation = () =>
    dispatch(a.experiments.succeededExperiment(experimentCode));

  return { succeedVariation, variation, viewVariation };
};

const USE_FETCH_ERROR = "USE_FETCH_ERROR";
const USE_FETCH_SUCCESS = "USE_FETCH_SUCCESS";

const useFetchReducer = <T>(
  state: t.FetchState<T>,
  action: t.FetchAction<T>
): t.FetchState<T> => {
  switch (action.type) {
    case USE_FETCH_SUCCESS:
      const newState = { ...state, error: null, isLoading: false };

      if (action.payload) {
        newState.response = action.payload;
      }
      return newState;

    case USE_FETCH_ERROR:
      if (!action.error) return state;

      return {
        ...state,
        error: action.error,
        isLoading: false,
      };

    default:
      throw new Error(`unexpected consumer api action: ${action.type}`);
  }
};

const handleFetchErrors = (
  error: AxiosError,
  appDispatch: Dispatch<Action>
) => {
  if (
    error.response &&
    error.response?.status !== 404 &&
    error.response?.status >= 400 &&
    error.response?.status < 600
  ) {
    appDispatch(
      flashBanner.showFlashBanner(error.message, flashBanner.BannerType.ERROR)
    );
  }
};

export const useFetch = <T>(
  initialPath: string,
  options: t.FetchOptions = {}
): [t.FetchState<T>, (path: string) => void] => {
  const [path, setPath] = useState(initialPath);

  const appDispatch = useDispatch();
  const [fetchState, fetchDispatch] = useReducer(useFetchReducer, {
    isLoading: !!path,
  });

  const token = useSelector(userSelectors.getAuthenticationToken);

  useEffect(() => {
    let didCancel = false;

    const fetchData = async (): Promise<void> => {
      try {
        if (options.requiresAuthentication && !token) {
          return;
        }

        const data = await fetch<T>(initialPath, options);

        if (didCancel) return;

        fetchDispatch({ payload: data, type: USE_FETCH_SUCCESS });
      } catch (error) {
        if (didCancel) return;

        fetchDispatch({
          error,
          type: USE_FETCH_ERROR,
        } as t.FetchAction<AxiosError>);

        handleFetchErrors(error as AxiosError, appDispatch);
      }
    };

    if (path) {
      fetchData();
    }

    return (): void => {
      didCancel = true;
    };
    // `options` is intentionally left out of the dependencies array.
    // This is to avoid re-fetches when the options are the same but a new options object is passed in.
    // eslint-disable-next-line
  }, [initialPath, options.method, path, token]);

  return [fetchState as t.FetchState<T>, setPath];
};

const useQueryDefaultOptions = { refetchOnWindowFocus: false };

type UseReactQueryParams<T> = {
  path: string;
  refetchConditions?: unknown[];
  shouldThrowError?: boolean;
  useQueryOptions?: UseQueryOptions<T | undefined, Error>;
};

export type UseReactQuery<T> = (
  params: UseReactQueryParams<T>
) => UseQueryResult<T | undefined, Error>;

export const useReactQuery = <T>({
  path,
  refetchConditions = [],
  shouldThrowError = false,
  useQueryOptions = {},
}: UseReactQueryParams<T>): UseQueryResult<T | undefined, Error> => {
  const dispatch = useDispatch();
  const token = useSelector(userSelectors.getAuthenticationToken);

  const fetchData = async (): Promise<T | undefined> => {
    if (!token) return;

    try {
      return await fetch<T>(path);
    } catch (error) {
      if (shouldThrowError) throw error;
      handleFetchErrors(error as AxiosError, dispatch);
    }
  };

  const queryState = useQuery<T | undefined, Error>(
    [path, token, ...refetchConditions],
    fetchData,
    { ...useQueryDefaultOptions, ...useQueryOptions }
  );

  return queryState;
};

type UsePendingReactQueryParams<T> = {
  path: string;
  pendingCondition?: unknown;
  refetchConditions?: unknown[];
  shouldThrowError?: boolean;
  useQueryOptions?: UseQueryOptions<T | undefined, Error>;
};

export type UsePendingReactQuery<T> = (
  params: UsePendingReactQueryParams<T>
) => UseQueryResult<T | undefined, Error>;

/*
  This version of useReactQuery can be used for conditional API requests. It is 
  intended for cases when there is a pending condition that is expected to be 
  resolved. API requests are only made after the pending value has been resolved.
  The pending condition should be a Falsy value. 
  See: https://developer.mozilla.org/en-US/docs/Glossary/Falsy
*/
export const usePendingReactQuery = <T>({
  path,
  pendingCondition,
  refetchConditions = [],
  shouldThrowError = false,
  useQueryOptions = {},
}: UsePendingReactQueryParams<T>): UseQueryResult<T | undefined, Error> => {
  const dispatch = useDispatch();
  const token = useSelector(userSelectors.getAuthenticationToken);

  const fetchData = async (): Promise<T | undefined> => {
    if (!token || !pendingCondition) return;

    try {
      return await fetch<T>(path);
    } catch (error) {
      if (shouldThrowError) throw error;
      handleFetchErrors(error as AxiosError, dispatch);
    }
  };

  const queryState = useQuery<T | undefined, Error>(
    [path, token, ...refetchConditions],
    fetchData,
    { ...useQueryDefaultOptions, ...useQueryOptions }
  );

  return queryState;
};
