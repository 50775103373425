import * as u from "../../../shared/utils";

import * as t from "../types";
import { Potency } from "../types/strain";

type StrainValues = {
  name: string;
  classification: string;
} & Partial<t.StrainJson>;

export const getStrainJson = (values: StrainValues): t.StrainJson => {
  return {
    averageRating: 0,
    bestFlavorTemperature: 0,
    cbd: 0,
    cbdRange: { max: 0, min: 0 },
    description: "",
    desiredEffects: [],
    effectData: [],
    extraction: "",
    geoState: "",
    highTerpene: false,
    id: "",
    links: [],
    logos: [],
    maxTemperature: 0,
    maxVaporTemperature: 0,
    msrp: 0,
    numEffectReviews: 0,
    numRatings: 0,
    partner: {
      description: "",
      id: "",
      links: [],
      logo: { downloadUrl: "", fileId: "" },
      partnerName: "",
      photos: [],
      region: "",
      states: [],
      videos: [],
      ...values.partner,
    },
    photos: [],
    potency: "" as Potency,
    potencyLevel: "",
    recommendedTemperature: 0,
    spectrumScore: 0,
    terpene: 0,
    terpeneRange: { max: 0, min: 0 },
    thc: 0,
    thcRange: { max: 0, min: 0 },
    videos: [],
    viscosity: 0,
    ...values,
  };
};

export const getLoadingStrainJson = (): t.StrainJson => {
  const partner: t.partner.PartnerJson = {
    description: "",
    id: "",
    links: [],
    logo: {
      downloadUrl: "",
      fileId: "",
    },
    partnerName: "",
    photos: [],
    region: "",
    states: [],
    videos: [],
  };

  return {
    averageRating: 0,
    bestFlavorTemperature: 0,
    cbd: 0,
    cbdRange: { max: 0, min: 0 },
    classification: "",
    description: "",
    desiredEffects: [],
    effectData: [],
    extraction: "",
    geoState: "",
    highTerpene: false,
    id: "",
    links: [],
    logos: [],
    maxTemperature: 0,
    maxVaporTemperature: 0,
    msrp: 0,
    name: "Loading...",
    numEffectReviews: 0,
    numRatings: 0,
    partner,
    photos: [],
    potency: "" as Potency,
    potencyLevel: "",
    recommendedTemperature: 0,
    spectrumScore: 0,
    terpene: 0,
    terpeneRange: { max: 0, min: 0 },
    thc: 0,
    thcRange: { max: 0, min: 0 },
    videos: [],
    viscosity: 0,
  };
};

const getIngredientRange = (
  strain: t.StrainJson,
  ingredient: t.StrainIngredient
): t.Range => {
  switch (ingredient) {
    case t.StrainIngredient.CBD:
      return strain.cbdRange;

    case t.StrainIngredient.TERPENE:
      return strain.terpeneRange;

    case t.StrainIngredient.THC:
      return strain.thcRange;
  }
};

export const getIngredientPercentageOrRangeString = (
  strain: t.StrainJson,
  ingredient: t.StrainIngredient
): string => {
  if (!isStrainCanadian(strain)) {
    return `${strain[ingredient]}%`;
  }

  const range = getIngredientRange(strain, ingredient);

  if (range.min === range.max) {
    return `${range.min}%`;
  }

  return `${range.min}% - ${range.max}%`;
};

export const isStrainAmerican = (strain: t.StrainJson): boolean => {
  return u.isGeoStateAmerican(strain.geoState);
};

export const isStrainCanadian = (strain: t.StrainJson): boolean => {
  return u.isGeoStateCanadian(strain.geoState);
};

export const getThcPercentageRange = (strain: t.StrainJson): number[] => {
  if (!isStrainCanadian(strain)) {
    return [strain.thc];
  }

  const range = strain.thcRange;

  if (range.min === range.max) {
    return [range.min];
  }

  return [range.min, range.max];
};
