import { AxiosError } from "axios";
import { useEffect, useReducer, useState } from "react";
import { useSelector } from "react-redux";

import { getAuthenticationToken } from "../../api/consumer/selectors/user";

import { fetch } from "./index";
import * as t from "./types";

const FETCH_ERROR = "FETCH_ERROR";
const FETCH_NEXT_PAGE = "FETCH_NEXT_PAGE";
const FETCH_START = "FETCH_START";
const FETCH_SUCCESS = "FETCH_SUCCESS";
const RESET_STATE = "RESET_STATE";

export const getInitialState = <T>(
  overrides?: Partial<t.PaginatedFetchState<T>>
): t.PaginatedFetchState<T> => ({
  count: 0,
  data: [],
  hasFetchedFirstPage: false,
  isFetching: false,
  shouldFetchNextPage: false,
  ...overrides,
});

const paginatedFetchReducer = <T>(
  state: t.PaginatedFetchState<T>,
  action: t.FetchAction<t.CollectionResponse<T>>
): t.PaginatedFetchState<T> => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_ERROR:
      return { ...state, isFetching: false, shouldFetchNextPage: false };

    case FETCH_NEXT_PAGE:
      return { ...state, shouldFetchNextPage: true };

    case FETCH_START:
      return {
        ...state,
        isFetching: true,
      };

    case FETCH_SUCCESS:
      if (!payload) return { ...state, shouldFetchNextPage: false };

      // If a response is for a subsequent page, concat the data.
      const data = payload.previous
        ? state.data.concat(payload.results)
        : payload.results;

      return {
        ...state,
        count: payload.count,
        data,
        hasFetchedFirstPage: true,
        isFetching: false,
        nextFetchUrl: payload.next,
        shouldFetchNextPage: false,
      };
    case RESET_STATE:
      return { ...getInitialState() };

    default:
      throw new Error(`unexpected consumer api action: ${action.type}`);
  }
};

export const usePaginatedFetch = <T>(
  initialPath: string,
  options: t.FetchOptions = {}
): [t.PaginatedFetchState<T>, t.PaginatedFetchFunctions] => {
  const [path, setPath] = useState<string>(initialPath);
  const token = useSelector(getAuthenticationToken);
  const [paginatedFetchState, paginatedFetchDispatch] = useReducer(
    paginatedFetchReducer,
    getInitialState()
  );

  // This useEffect is to fetch the first page of data.
  useEffect(() => {
    if (!path) return;

    const fetchData = async () => {
      paginatedFetchDispatch({ type: FETCH_START });

      try {
        if (options.requiresAuthentication && !token) {
          return;
        }

        const data = await fetch<t.CollectionResponse<T>>(initialPath, options);

        paginatedFetchDispatch({ payload: data, type: FETCH_SUCCESS });
      } catch (error) {
        paginatedFetchDispatch({ error, type: FETCH_ERROR } as t.FetchAction<
          t.CollectionResponse<AxiosError>
        >);
      }
    };

    fetchData();

    // Only redo initial fetch if path changes
    // eslint-disable-next-line
  }, [path]);

  // This useEffect is to fetch the subsequent pages of data.
  useEffect(() => {
    const { isFetching, nextFetchUrl, shouldFetchNextPage } =
      paginatedFetchState;

    if (!shouldFetchNextPage || !nextFetchUrl || isFetching) return;

    const fetchDataFromUrl = async () => {
      paginatedFetchDispatch({ type: FETCH_START });
      try {
        if (options.requiresAuthentication && !token) {
          return;
        }
        const response = await fetch<t.CollectionResponse<T>>(nextFetchUrl, {
          ...options,
          fullUrl: true,
        });

        paginatedFetchDispatch({ payload: response, type: FETCH_SUCCESS });
      } catch (error) {
        paginatedFetchDispatch({ error, type: FETCH_ERROR } as t.FetchAction<
          t.CollectionResponse<AxiosError>
        >);
      }
    };

    fetchDataFromUrl();

    // eslint-disable-next-line
  }, [paginatedFetchState]);

  const fetchNextPage = async () => {
    if (
      paginatedFetchState.isFetching ||
      !paginatedFetchState.nextFetchUrl ||
      paginatedFetchState.shouldFetchNextPage
    )
      return;

    paginatedFetchDispatch({ type: FETCH_NEXT_PAGE });
  };

  const setNewPath = (path: string) => {
    paginatedFetchDispatch({ type: RESET_STATE });
    setPath(path);
  };

  return [
    paginatedFetchState as t.PaginatedFetchState<T>,
    {
      fetchNextPage,
      setPath: setNewPath,
    },
  ];
};
