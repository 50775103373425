import EventEmitter from "events";

import * as c from "../constants";
import * as t from "../types";
import { BaseLogStore } from "./BaseLogStore";
import { Request } from "./Request";
import { RequestQueue } from "./RequestQueue";

export abstract class BaseDevice {
  device: BluetoothDevice;
  events = new EventEmitter();
  gatt: BluetoothRemoteGATTServer;
  hasReceivedNotification = false;
  hasReceivedUnknownAttribute = false;
  requestQueue = new RequestQueue();
  supportsLogs = false;
  supportsRenameDevice = false;
  supportsSessionControl = false;

  encryptedWrites?: boolean;
  firmwareRevision?: string;
  logStore?: BaseLogStore;
  notify?: BluetoothRemoteGATTCharacteristic;
  read?: BluetoothRemoteGATTCharacteristic;
  serial?: string;
  systemId?: string;
  write?: BluetoothRemoteGATTCharacteristic;

  abstract type: t.DeviceType;

  constructor(device: BluetoothDevice, gatt: BluetoothRemoteGATTServer) {
    this.device = device;
    this.gatt = gatt;

    device.addEventListener("gattserverdisconnected", () => {
      this.events.emit("disconnect");
      this.events.removeAllListeners();
    });
  }

  abstract initialize(
    attributeIdsToRead: number[],
    options?: t.DeviceOptions
  ): Promise<void>;
  abstract disconnect(): void;
  abstract getWriteOperationBuffer(
    attributeId: number,
    value: unknown
  ): Uint8Array;
  abstract readBrightness(): void;
  abstract readColorTheme(): void;
  abstract readDeviceName(): void;
  abstract readHaptics(): void;
  abstract readHeaterSetPoint(): void;
  abstract readLockState(): void;
  abstract writeBrightness(percentage: number): void;
  abstract writeColorMode(colorMode: t.ColorMode | t.Pax3.ColorMode): void;
  abstract writeDeviceName(deviceName: string): void;
  abstract writeHaptics(value: number): void;
  abstract writeHeaterSetPoint(heaterSetPoint: number): void;
  abstract writeLockState(value: number): void;

  get connected(): boolean {
    return this.gatt.connected;
  }

  readAttribute(attributeId: number): void {
    this.readAttributes([attributeId]);
  }

  readAttributes(attributeIds: number[]): void {
    if (attributeIds.length <= 0) return;

    const bitmap = attributeIds.reduce((acc, attributeId) => {
      // Math.pow is used instead of the bitwise operator <<, which
      // converts operands to 32-bit integers. This would result in overflow when attribute > 32.
      return acc + Math.pow(2, attributeId);
    }, 0);

    this.writeAttribute(c.ATTRIBUTE_STATUS_UPDATE, bitmap);
  }

  fetchLogs(): void {
    if (!this.connected || !this.supportsLogs) return;
    if (!this.logStore) throw new Error("log store not initialized");

    this.logStore.fetchLogs();
  }

  setShouldFetchLogs(flag: boolean): void {
    if (!this.logStore) return;

    this.logStore.shouldFetchLogs = flag;
  }

  writeAttribute(attributeId: number, value: unknown): void {
    const buffer = this.getWriteOperationBuffer(attributeId, value);

    this.queueWriteRequest(buffer);
  }

  queueWriteRequest(buffer: Uint8Array): void {
    if (!this.write) throw new Error("not initialized");

    this.queueRequest(new Request(this.write, buffer, this.encryptedWrites));
  }

  queueRequest(request: Request): void {
    this.requestQueue.push(request);
  }

  protected getService(serviceId: string): Promise<BluetoothRemoteGATTService> {
    return this.gatt.getPrimaryService(serviceId);
  }

  // Since it is not routed through the request queue, readCharacteristicValue
  // should only be called as part of #initialize().
  protected async readCharacteristicValue(
    serviceId: string,
    characteristicId: string | number
  ): Promise<DataView> {
    const service = await this.getService(serviceId);
    const characteristic = await service.getCharacteristic(characteristicId);

    return characteristic.readValue();
  }
}
