import styled from "styled-components";

import { Column } from "../shared/components/Flex";
import * as Text from "../shared/components/Text";

type ButtonProps = {
  active: boolean;
};

export const Button = styled.button<ButtonProps>`
  ${Text.PlaaxBold}
  align-items: center;
  backdrop-filter: blur(10px);
  background-color: ${({ active }): string =>
    active ? "var(--black)" : "var(--white-70)"};
  border: none;
  border-radius: 16px;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
  color: ${({ active }): string => (active ? "var(--white)" : "var(--black)")};
  display: flex;
  height: 28px;
  justify-content: center;
  padding: 0 16px;
`;

export const Card = styled(Column)`
  backdrop-filter: blur(10px);
  background-color: var(--white-70);
  border-radius: 16px;
  padding: 12px;
`;
