import compareVersions from "compare-versions";
import { isIOS, osVersion } from "react-device-detect";

import * as c from "./app/appConstants";

export const navigator = global.navigator;

export const isConnectBrowser = /ConnectBrowser/i.test(navigator.userAgent);

// If the user is using Connect Browser open SSO in the same tab
export const doesBrowserSupportTabs = (): boolean => {
  return !isConnectBrowser;
};

/* This function is currently not in use due to there being a bug where there is an 
issue with tabs closing and Connect Browse. Keeping it here for eventual use when we resolve the tab issues*/
// Only checks for Connect Browser versions above 1.2.1, will fail for other browsers.
export const doesConnectBrowserSupportTabs = (): boolean => {
  // Parsing for Connect Browser versioning (ie. 1.2.1)
  const matchConnectBrowserVersion = navigator.userAgent.match(
    /ConnectBrowser\/(\d+\.\d+\.\d+)/
  );

  const connectBrowserVersion =
    (matchConnectBrowserVersion && matchConnectBrowserVersion[1]) || "0.0.0";

  const versionCheck =
    compareVersions(connectBrowserVersion, c.MINIMUM_CONNECT_BROWSER_VERSION) >=
    0;

  return versionCheck;
};

export const isIOSUpgradeNeeded = (): boolean => {
  return isIOS && compareVersions(osVersion, c.MINIMUM_IOS_VERSION) === -1;
};
