import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { trackEventAction } from "../../analytics/actions";
import { SortedBy } from "../../analytics/search/search";
import { receivedNewRecentSearch } from "../../api/consumer/actions/users";
import { getUserLocationCode } from "../../api/consumer/selectors/user";
import { Column, Row } from "../../shared/components/Flex";
import * as Text from "../../shared/components/Text";

import * as hooks from "../hooks";
import { OnPodSearchQuerySet } from "../types";

export type PodsRecentSearchesProps = {
  onPodSearchQuerySet: OnPodSearchQuerySet;
  useRecommendedSearches?: hooks.UseRecommendedSearches;
};

export const PodsRecentSearches: React.FC<PodsRecentSearchesProps> = ({
  onPodSearchQuerySet,
  useRecommendedSearches = hooks.useRecommendedSearches,
}) => {
  const dispatch = useDispatch();
  const locationCode = useSelector(getUserLocationCode);

  const { isSuggestedQueries, recommendedSearches, title } =
    useRecommendedSearches();

  useEffect(() => {
    // [SEARCH_EVENT] - recent/suggested queries on load
    dispatch(
      trackEventAction("search", {
        action: "load",
        isRecentQuery: !isSuggestedQueries,
        isSuggestedQuery: isSuggestedQueries,
        isoLocation: locationCode,
        sortedBy: SortedBy.LANDING,
      })
    );
  }, [dispatch, isSuggestedQueries, locationCode]);

  const handleSearchItemClick = (query: string): void => {
    onPodSearchQuerySet(query);
    dispatch(receivedNewRecentSearch(query));

    // [SEARCH_EVENT] - recent/suggested queries on click
    dispatch(
      trackEventAction("search", {
        action: "click",
        isRecentQuery: !isSuggestedQueries,
        isSuggestedQuery: isSuggestedQueries,
        isoLocation: locationCode,
        query,
        sortedBy: SortedBy.LANDING,
      })
    );
  };

  return (
    <sc.Container>
      <sc.Title>{title}</sc.Title>
      {recommendedSearches.map((query) => (
        <sc.QueryRow
          key={query}
          onClick={(): void => handleSearchItemClick(query)}
        >
          <sc.QueryOption>{query}</sc.QueryOption>
        </sc.QueryRow>
      ))}
    </sc.Container>
  );
};

const sc = {
  Container: styled(Column)`
    margin: 20px;
    max-width: 420px;
    z-index: var(--z-index-explore-recent-searches);
  `,

  QueryOption: styled.a`
    ${Text.MediumLL}

    font-size: 14px;
    text-decoration: underline;
    text-transform: capitalize;
  `,

  QueryRow: styled(Row)`
    cursor: pointer;
    line-height: 1.29;
    margin-top: 16px;
  `,

  Title: styled.div`
    ${Text.MediumLLBold}
    font-size: 16px;
    line-height: 22px;
    text-transform: uppercase;
  `,
};
