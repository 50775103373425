import * as PaxBle from "../../pax-ble";

import { getConnectedDevice } from "../bluetooth/connectedDevice";
import { removeDeviceLockoutStarted } from "../device/utils";

const getEraDevice = (): PaxBle.EraDevice | undefined =>
  getConnectedDevice() as PaxBle.EraDevice;

export type StartEraSession = (
  presetDoseId: number,
  heaterSetPoint: number
) => void;

export const startEraSession: StartEraSession =
  (presetDoseId, heaterSetPoint, eraDevice = getEraDevice()) =>
  async (): Promise<void> => {
    if (!eraDevice) return;

    await eraDevice.startReplay(presetDoseId, heaterSetPoint);

    eraDevice.readReplay();
  };

export type StopEraSession = (doseId: number) => void;

export const stopEraSession: StopEraSession =
  (doseId, eraDevice = getEraDevice()) =>
  async (): Promise<void> => {
    if (!eraDevice) return;
    removeDeviceLockoutStarted();
    await eraDevice.stopReplay(doseId);

    eraDevice.readReplay();
  };

export type SetColorMode = (colorMode: PaxBle.Types.ColorMode) => void;

export const setColorMode: SetColorMode =
  (colorMode, eraDevice = getEraDevice()) =>
  async (): Promise<void> => {
    if (!eraDevice) return;

    eraDevice.writeColorMode(colorMode);

    eraDevice.readColorTheme();
  };
