import { connect } from "react-redux";

import { ATTRIBUTE_DYNAMIC_MODE } from "../../../device/constants";
import { MainAppState } from "../../../main/types";

import { setDynamicMode } from "../../actions";
import FlowerDynamicModes from "../../components/Thermostat/FlowerDynamicModes";
import { getDynamicModeFromType } from "../../utils";

const mapStateToProps = (state: MainAppState) => {
  let activeDynamicMode;
  const activeDynamicModeType = state.device.attributes[ATTRIBUTE_DYNAMIC_MODE];

  if (activeDynamicModeType !== undefined)
    activeDynamicMode = getDynamicModeFromType(activeDynamicModeType);

  return {
    activeDynamicMode,
    setDynamicMode,
  };
};

export default connect(mapStateToProps)(FlowerDynamicModes);
