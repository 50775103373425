import React from "react";
import styled, { css } from "styled-components";

import { CannabisCompoundJson } from "../../api/consumer/types/strain";
import { Row } from "../../shared/components/Flex";
import * as Text from "../../shared/components/Text";
import { desktopBreakpoint } from "../../shared/utils";

import { BigCard } from "./PodsTestResultsCard";

export type PodsCannabinoidsCardProps = {
  cannabinoids: CannabisCompoundJson[];
  cbd: number;
  thc: number;
};

export const PodsCannabinoidsCard: React.FC<PodsCannabinoidsCardProps> = ({
  cannabinoids,
  cbd,
  thc,
}) => {
  cannabinoids.sort(function (a, b) {
    return b.fieldValue - a.fieldValue;
  });

  return (
    <BigCard data-testid="pods-cannabinoids-card" borderStyle="dotted">
      <Row justifyContent="space-between">
        <sc.Title>Cannabinoids</sc.Title>
      </Row>
      <sc.Description>
        Cannabinoids are value-added molecules found in a number of products,
        plants, & biological systems on the planet.
      </sc.Description>
      <sc.ThcCbdContainer>
        <sc.ThcOrCbd data-testid="thc">
          {cannabinoidPercentage("THC", thc)}
        </sc.ThcOrCbd>
        <sc.Separator />
        <sc.ThcOrCbd data-testid="cbd">
          {cannabinoidPercentage("CBD", cbd)}
        </sc.ThcOrCbd>
      </sc.ThcCbdContainer>
      {cannabinoids.slice(0, 4).map(({ fieldName, fieldValue }, index) => (
        <Row justifyContent="space-between" key={fieldName}>
          <sc.Label data-testid={`cannabinoid-${index}`}>{fieldName}</sc.Label>
          <sc.Value data-testid={`cannabinoid-value-${index}`}>
            {fieldValue.toFixed(2)}%
          </sc.Value>
        </Row>
      ))}
    </BigCard>
  );
};

const cannabinoidPercentage = (
  cannabinoid: string,
  percentage: number
): string => `${cannabinoid} ${percentage.toFixed(2)}%`;

const sc = {
  Description: styled.p`
    ${Text.MediumLLLight}
    color: var(--black);
    font-size: 14px;
    line-height: 1;
    margin: 0 0 12px;
    max-width: var(--pod-test-result-content-width);
    text-align: left;
  `,

  Label: styled.div`
    ${Text.MediumLLLight}
    color: var(--teal-green);
    font-size: 16px;
    line-height: 1;
    margin-bottom: 8px;
    margin-left: 53px;
    text-transform: uppercase;
  `,

  Separator: styled.div`
    border-left: solid 1px var(--very-light-pink);
    height: 16px;
    opacity: 0.5;
    width: 1px;
  `,

  ThcCbdContainer: styled.div`
    align-items: center;
    border: solid 2px var(--gunmetal-10);
    border-radius: 4px;
    display: flex;
    height: 32px;
    justify-content: space-between;
    margin-bottom: 12px;

    & > :first-child {
      margin-left: 53px;
    }

    & > :last-child {
      margin-right: 53px;
    }

    ${desktopBreakpoint(css`
      padding: 8px 52px;
      width: var(--pod-test-result-content-width);

      & > :first-child {
        margin-left: 0;
      }

      & > :last-child {
        margin-right: 0;
      }
    `)}
  `,

  ThcOrCbd: styled.div`
    color: var(--teal-green);
    font-size: 16px;
    font-weight: 500;
    line-height: 1;
    text-transform: uppercase;
  `,

  Title: styled.div`
    color: var(--black);
    font-size: 20px;
    margin-bottom: 10px;
    text-transform: uppercase;
  `,

  Value: styled.div`
    ${Text.MediumLLLight}
    color: var(--teal-green);
    font-size: 16px;
    line-height: 1;
    margin-bottom: 8px;
    margin-right: 53px;
  `,
};
