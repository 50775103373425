import React, { useCallback, useEffect, useState } from "react";
import styled, { css } from "styled-components";

import { PhotoJson, VideoJson } from "../../api/consumer/types";
import { Modal, ModalProps } from "../../modal/components/Modal";
import { Row } from "../../shared/components/Flex";
import { ChevronLeft, ChevronRight } from "../../shared/components/Icons";

import { desktopBreakpoint } from "../utils";

type MediaModalProps = ModalProps & {
  initialIndex: number | null;
  onIndexChange?: (mediaIndex: number) => void;
  photos?: PhotoJson[];
  videos?: VideoJson[];
};

export const MediaModal: React.FC<MediaModalProps> = ({
  initialIndex,
  isOpen,
  onRequestClose,
  onIndexChange,
  photos,
  videos,
}) => {
  const modalProps = {
    isOpen,
    onRequestClose,
  };

  const [index, setIndex] = useState<number | null>(initialIndex);

  useEffect(() => {
    setIndex(initialIndex);
  }, [initialIndex]);

  photos = photos || [];
  videos = videos || [];
  const mediaCount = photos.length + videos.length;
  const isPreviousMediaAvailable = index !== null && index > 0;
  const isNextMediaAvailable = index !== null && index + 1 < mediaCount;

  const handleArrowLeftClick = useCallback(() => {
    if (!isPreviousMediaAvailable || index === null) return;
    const newIndex = index - 1;
    setIndex(newIndex);
    onIndexChange && onIndexChange(newIndex);
  }, [index, isPreviousMediaAvailable, onIndexChange]);

  const handleArrowRightClick = useCallback(() => {
    if (!isNextMediaAvailable || index === null) return;
    const newIndex = index + 1;
    setIndex(newIndex);
    onIndexChange && onIndexChange(newIndex);
  }, [index, isNextMediaAvailable, onIndexChange]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent): void => {
      if (event.key === "ArrowLeft") handleArrowLeftClick();
      if (event.key === "ArrowRight") handleArrowRightClick();
    };

    window.addEventListener("keydown", handleKeyDown);

    return (): void => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleArrowLeftClick, handleArrowRightClick]);

  if (index === null) {
    return null;
  }

  const media =
    index < videos.length ? (
      <Video video={videos[index]} />
    ) : (
      <Photo photo={photos[index - videos.length]} />
    );

  return (
    <Modal
      closerProps={modalIconProps}
      data-testid="media-modal"
      {...modalProps}
    >
      <sc.Container>
        <sc.ChevronLeft
          $isDisabled={!isPreviousMediaAvailable}
          onClick={handleArrowLeftClick}
          {...modalIconProps}
        />
        {media}
        <sc.ChevronRight
          $isDisabled={!isNextMediaAvailable}
          onClick={handleArrowRightClick}
          {...modalIconProps}
        />
      </sc.Container>
    </Modal>
  );
};

type MediaProps = {
  photo?: PhotoJson;
  video?: VideoJson;
};

const Photo: React.FC<MediaProps> = ({ photo }) => {
  if (!photo?.downloadUrl) return null;

  return <sc.Image src={photo.downloadUrl} />;
};

const Video: React.FC<MediaProps> = ({ video }) => {
  if (!video?.downloadUrl) return null;

  return (
    <sc.Video controls>
      <source src={video.downloadUrl} />
    </sc.Video>
  );
};

const modalIconProps = {
  color: "var(--white)",
  width: "24px",
};

const mediaDimensions = css`
  max-height: 100%;
  max-width: 100%;
`;

const chevronStyles = css`
  color: var(--white);
  position: absolute;
  top: calc(50% - 12px);
  width: 24px;
`;

const sc = {
  ChevronLeft: styled(ChevronLeft)`
    ${chevronStyles}
    left: 28px;
  `,

  ChevronRight: styled(ChevronRight)`
    ${chevronStyles}
    right: 28px;
  `,

  Container: styled(Row)`
    align-items: center;
    background-color: var(--black);
    border-radius: 8px;
    height: 100vh;
    justify-content: center;
    padding: 40px 80px;
    width: 100vw;

    ${desktopBreakpoint(
      css`
        height: 80vh;
        width: 80vw;
      `
    )}
  `,

  Image: styled.img`
    ${mediaDimensions}
  `,

  Video: styled.video`
    ${mediaDimensions}
    background-color: var(--black);
    outline: none;
  `,
};
