export enum EraReplayAction {
  USER_START = 1,
  USER_STOP = 2,
  CURRENT_STATE = 3,
  FINISHED = 4,
  TIMED_OUT = 5,
  ERROR = 6,
  CONFIGURE = 7,
  LOCKOUT_EXPIRED = 8,
}

export enum EraDose {
  NONE = 0,
  MICRO = 1,
  SMALL = 2,
  MEDIUM = 3,
  LARGE = 4,
}

export enum ErrorCode {
  NONE = 0,
  INVALID_NUM_SEGMENTS = 1,
  ZERO_SEGMENTS = 2,
  TEMP_OUT_OF_RANGE = 3,
  ZERO_USAGE = 4,
  INVALID_ID = 5,
}

export type EraReplayJson = {
  action: EraReplayAction;
  cutOffTime: number;
  doseId: number;
  idleTime: number;
  isActive: boolean;
  lockoutTime: number;
  progressPercentage: number;
  samples: EraReplaySampleData[];

  errorCode?: ErrorCode;
};

export type EraReplaySampleData = {
  heaterSetPoint: number;
  usage: number;
};

export type LogEvent = {
  eventIndex?: number;
  eventTime: number;
  eventTimeOriginal: number;
  eventTypeCode: LogType;
  eventValue: number;
};

export enum LogType {
  RESET = 0,
  ERROR = 1,
  PUFF_USAGE = 2,
  PUFF_TIME = 3,
  HEATER_SET_POINT_CHANGED = 4,
  UNLOCK = 5,
  CHARGER_INSERTED = 6,
  CHARGER_REMOVED = 7,
  CHARGE_COMPLETE = 8,
  STATE_OF_CHARGE_CHANGED = 9,
  POD_INSERTED = 10,
  POD_REMOVED = 11,
  MOTION_SENSOR_PULSE = 12,
  MOTION_SENSOR_SHAKE = 13,
  MOTION_SENSOR_ROLL = 14,
  BLUETOOTH_CONNECT = 15,
  BLUETOOTH_DISCONNECT = 16,
  BLUETOOTH_APP_COMMAND = 17,
  DFU_START = 18,
  FIRMWARE_UPDATE = 19,
  TIME_SYNC_HIGH = 20,
  TIME_SYNC_LOW = 21,
  USAGE_LIMITED = 22,
  PUFF_ENERGY = 23,
  PUFF_PRESSURE_SENSOR_READING = 24,
  POD_BASELINE_STABLE = 25,
  POD_BASELINE_INFO = 26,
  POD_RESISTANCE_MEASUREMENT_SCALE_CHANGED = 27,
  DRAW_ATTEMPTED_WITH_UNSTABLE_BASELINE = 28,
  DRAW_ATTEMPTED_WHILE_PERIPHERAL_LOCKED = 29,
  USAGE_SESSION_ENDED = 30,
  CONTROLLED_SESSION_STARTED = 31,
  CONTROLLED_SESSION_COMPLETED = 32,
  SESSION_CONTROL_STOP = 33,
  SESSION_CONTROL_PROGRESS_AT_STOP = 34,
}

export type Usage = {
  isEndOfPuff: boolean;
  puffCj: number;
  totalSessionCj: number;
};
