import { FirmwareUpgradeState as McuFirmwareUpgradeState } from "../../mcumgr-js/src/dfu/FirmwareUpgradeManager";

export enum FirmwareStep {
  UPDATE_AVAILABLE = "UPDATE_AVAILABLE",
  UPDATE_CHECK = "UPDATE_CHECK",
  UPDATE_CONFIRMATION = "UPDATE_CONFIRMATION",
  UPDATE_PROGRESS = "UPDATE_PROGRESS",
  USAGE_AGREEMENT = "USAGE_AGREEMENT",
}

export type FirmwareFlow = {
  [key: string]: {
    next: React.FC<FirmwareModalContentProps> | null;
    skip?: React.FC<FirmwareModalContentProps> | null;
  };
};

export type FirmwareInfo = {
  version: string;
  downloadUrl: string;
};

export type FirmwareInfoResponse = {
  blob: {
    downloadUrl: string;
    fileId: string;
  };
  datePublished: string;
  version: string;
};

export type FirmwareModalContentProps = {
  onStepCompleted: OnFirmwareStepCompleted;
};

export type FirmwareState = {
  firmwareInfo: FirmwareInfo;
  firmwareUpgradeState: FirmwareUpgradeState;
};

export type FirmwareUpgradeState = {
  mcuState: McuFirmwareUpgradeState;
  message?: string;
  progress?: number;
  title: string;
};

export type OnFirmwareStepCompleted = (
  currentStep: FirmwareStep,
  wasSkipped: boolean
) => void;
