import { connect } from "react-redux";
import { withRouter } from "react-router";

import { getUser } from "../../api/consumer/selectors/user";
import { MainAppState } from "../../main/types";

import { sendEmailVerificationRequest } from "../actions";
import { SsoEmailVerificationBanner } from "../components/SsoEmailVerificationBanner";

const mapStateToProps = (state: MainAppState) => {
  const user = getUser(state);
  return {
    email: user.email || "",
  };
};

const mapDispatchToProps = {
  sendEmailVerificationRequest,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SsoEmailVerificationBanner)
);
