import { get as lsGet } from "local-storage";
import React, { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";

import { Row } from "../../shared/components/Flex";
import Progress from "../../shared/components/Progress";
import * as Text from "../../shared/components/Text";
import { useDesktopMediaQuery } from "../../shared/hooks";
import { desktopBreakpoint } from "../../shared/utils";

import { LS_KEY_LOCKOUT_TIMEOUT_START } from "../constants";

type SessionProgressBarProps = {
  explainerModalOpener?: React.ReactElement;
  label: string;
  initialLockoutSeconds: number;
  onCancelClick: (e: React.MouseEvent) => void;
  progressPercentage: number;
  showTimeout?: boolean;
};

export const SessionProgressBar: React.FC<SessionProgressBarProps> = ({
  explainerModalOpener,
  label,
  initialLockoutSeconds,
  onCancelClick,
  progressPercentage,
  showTimeout = false,
}) => {
  const isDesktop = useDesktopMediaQuery();
  const [remainingLockoutSeconds, setRemainingLockoutSeconds] = useState(
    initialLockoutSeconds
  );

  const lockoutStartTime = useRef<number>();

  useEffect(() => {
    if (!showTimeout) return;

    lockoutStartTime.current = lsGet<number>(LS_KEY_LOCKOUT_TIMEOUT_START);

    if (!lockoutStartTime.current) {
      setRemainingLockoutSeconds(initialLockoutSeconds);
      return;
    }

    const elapsedTime = Math.round(
      (Date.now() - lockoutStartTime.current) / 1000
    );
    const newRemainingTime = initialLockoutSeconds - elapsedTime;

    setRemainingLockoutSeconds(
      newRemainingTime > 0 ? newRemainingTime : initialLockoutSeconds
    );
  }, [initialLockoutSeconds, lockoutStartTime, showTimeout]);

  useEffect(() => {
    if (remainingLockoutSeconds <= 0 || !showTimeout) return;

    const interval = setInterval(() => {
      setRemainingLockoutSeconds((prev) => {
        return prev - 1;
      });
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [remainingLockoutSeconds, showTimeout]);

  const lockoutText = `Lockout remaining: ${remainingLockoutSeconds}`;

  return (
    <>
      <sc.TitleRow>
        {showTimeout ? (
          <sc.TimeoutText>{lockoutText}</sc.TimeoutText>
        ) : (
          <sc.TitleActiveSession>{label}</sc.TitleActiveSession>
        )}
        {isDesktop && explainerModalOpener}
      </sc.TitleRow>
      <Progress
        complete={progressPercentage >= 100 || showTimeout}
        data-testid={"session-progress-bar"}
        max="100"
        value={showTimeout ? 100 : progressPercentage}
      ></Progress>
      <sc.ProgressFooter>
        {!isDesktop && showTimeout ? (
          <div>{lockoutText}</div>
        ) : (
          <div>{showTimeout ? 100 : progressPercentage}%</div>
        )}
        {!showTimeout && (
          <Text.Link
            data-testid={"cancel-session"}
            href="#"
            onClick={onCancelClick}
          >
            Cancel
          </Text.Link>
        )}
      </sc.ProgressFooter>
    </>
  );
};

const sc = {
  ProgressFooter: styled(Row)`
    ${Text.PlaaxLight}
    font-size: 14px;
    justify-content: space-between;
    margin-top: 4px;
    padding: 0 6px;
    text-transform: uppercase;
    width: 100%;
  `,

  Text: styled.div`
    ${Text.PlaaxLight}
    font-size: 14px;
    margin-bottom: 20px;
  `,

  TimeoutText: styled.div`
    ${Text.PlaaxLight}
    font-size: 18px;
    margin-bottom: 8px;
  `,

  TitleActiveSession: styled.div`
    font-size: 20px;
    margin-bottom: 8px;
    text-transform: uppercase;
  `,

  TitleRow: styled.div`
    display: none;
    position: relative;
    text-align: center;
    width: 100%;

    ${desktopBreakpoint(css`
      display: block;
    `)}
  `,
};
