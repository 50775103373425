import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { getIngredientPercentageOrRangeString } from "../../api/consumer/models/Strain";
import { StrainIngredient, StrainJson } from "../../api/consumer/types";
import * as selectors from "../../device/selectors";
import { useDoseStatus } from "../../era-pro/hooks";
import { useStrainById } from "../../pods/hooks";
import { Column, Row } from "../../shared/components/Flex";
import { PartnerLogo } from "../../shared/components/PartnerLogo";
import * as Text from "../../shared/components/Text";
import * as t from "../../shared/text";
import { calculatePercentage } from "../../shared/utils";

import { Card } from "../StyledComponents";
import { getSessionProgressText } from "../utils";

export const SessionInformationCard: React.FC = () => {
  const pod = useSelector(selectors.getDevicePod);
  const { strainId } = pod;
  const session = useSelector(selectors.getSession);
  const { doseId, initialTotalUsage, usageTowardCompletion } = session;
  const { strain } = useStrainById(strainId, { shouldShowErrorBanner: true });
  const { isSessionInProgress, shouldShowTimeout } = useDoseStatus(session);

  const isDosePulseActive = useSelector(selectors.getIsDosePulseActive);

  const percentageCompleted = calculatePercentage(
    usageTowardCompletion,
    initialTotalUsage
  );

  const doseText = doseId !== 0 ? t.nDose(doseId) : t.OFF;

  return (
    <Card>
      <sc.Row>
        <sc.PartnerLogo partner={strain?.partner} />
        {strain ? (
          <StrainDetails strain={strain} />
        ) : (
          <div>{t.UNABLE_TO_FIND_STRAIN}</div>
        )}
      </sc.Row>
      <sc.Row>
        <sc.DoseLabel>{t.DOSE_PULSE}</sc.DoseLabel>
        <sc.DoseText>{isDosePulseActive ? t.ON : t.OFF}</sc.DoseText>
      </sc.Row>
      <sc.Row>
        <sc.DoseLabel>{t.DOSE_CONTROL}</sc.DoseLabel>
        <sc.DoseText>
          {isSessionInProgress
            ? getSessionProgressText(percentageCompleted, shouldShowTimeout)
            : doseText}
        </sc.DoseText>
      </sc.Row>
    </Card>
  );
};

type StrainDetailsProps = {
  strain: StrainJson;
};

const StrainDetails: React.FC<StrainDetailsProps> = ({ strain }) => {
  return (
    <>
      <sc.ExpandedContentColumn>
        <sc.GreyText>{strain.partner.partnerName}</sc.GreyText>
        <sc.Text>{strain.name}</sc.Text>
      </sc.ExpandedContentColumn>
      <sc.ContentColumn>
        <sc.GreyText>{t.THC}</sc.GreyText>
        <sc.Text>
          {getIngredientPercentageOrRangeString(strain, StrainIngredient.THC)}
        </sc.Text>
      </sc.ContentColumn>
      <sc.ContentColumn>
        <sc.GreyText>{t.CBD}</sc.GreyText>
        <sc.Text>
          {getIngredientPercentageOrRangeString(strain, StrainIngredient.CBD)}
        </sc.Text>
      </sc.ContentColumn>
    </>
  );
};

const sc = {
  ContentColumn: styled(Column)`
    margin-left: 12px;
  `,

  DoseLabel: styled.div`
    ${Text.PlaaxBold}
    font-size: 12px;
    line-height: 18px;
    margin-right: 12px;
  `,

  DoseText: styled.div`
    ${Text.PlaaxBold}
    color: var(--black-50);
    font-size: 12px;
    line-height: 18px;
    margin-right: 12px;
    text-transform: uppercase;
  `,

  ExpandedContentColumn: styled(Column)`
    flex: 1;
  `,

  GreyText: styled.div`
    ${Text.PlaaxBold}
    color: var(--black-50);
    font-size: 10px;
    line-height: 12px;
    margin-bottom: 4px;
  `,

  PartnerLogo: styled(PartnerLogo)`
    height: 34px;
    margin-right: 8px;
    width: 34px;
  `,

  Row: styled(Row)`
    &:first-child {
      margin-bottom: 12px;
    }
  `,

  Text: styled.div`
    ${Text.PlaaxBold}
    font-size: 14px;
    line-height: 14px;
  `,
};
