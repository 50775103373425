import React from "react";
import * as redux from "react-redux";

import { trackFilteredSearchResultClicked } from "../../analytics/search/search";
import { StrainJson } from "../../api/consumer/types/strain";
import { PodCard } from "../../pods/components/PodCard";
import * as SC from "../../pods/styledComponents";
import InfiniteLoadingList from "../../shared/components/InfiniteLoadingList";
import { useDesktopMediaQuery } from "../../shared/hooks";
import { UseDispatch } from "../../shared/types";

import { incrementClickedPodCount } from "../actions";
import * as c from "../constants";
import { useResponsiveScreenSize } from "../hooks";
import { getClickedPodCount, getStrainsFilter } from "../selectors";

export type PodsSearchGridProps = {
  fetchNextPage: () => Promise<void>;
  gridWidth: number;
  hasMoreItems: boolean;
  strainRows: StrainJson[][];
  useDispatch?: UseDispatch;
};

export const PodsSearchGrid: React.FC<PodsSearchGridProps> = ({
  fetchNextPage,
  gridWidth,
  hasMoreItems,
  strainRows,
  useDispatch = redux.useDispatch,
}) => {
  const clickedPodCount = redux.useSelector(getClickedPodCount);
  const strainsFilter = redux.useSelector(getStrainsFilter);
  const dispatch = useDispatch();
  const screenSize = useResponsiveScreenSize();
  const isDesktop = useDesktopMediaQuery();

  const rowHeight = isDesktop
    ? c.POD_GRID_ROW_HEIGHT_DESKTOP
    : c.POD_GRID_ROW_HEIGHT_MOBILE;

  const handlePodClick = (strain: StrainJson, index: number) => {
    // [SEARCH_EVENT] - clicking on search results pod card
    dispatch(
      trackFilteredSearchResultClicked(strainsFilter, strain, {
        clickedOffset: index + 1,
        clickedSelectionCount: clickedPodCount + 1,
        isRecentlyViewed: false,
      })
    );

    dispatch(incrementClickedPodCount());
  };

  const rows = strainRows.map((strainRow, rowIndex) => (
    <SC.PodGridRow height={rowHeight} key={`row-${strainRow[0].id}`}>
      {strainRow.map((strain, columnIndex) => {
        const cardIndex =
          rowIndex * c.RESPONSIVE_POD_COLUMN_COUNT[screenSize] + columnIndex;
        return (
          <SC.PodCardContainer key={strain.id}>
            <PodCard
              Icon={strain.isFavorited ? <SC.FavoriteIcon /> : null}
              onClick={() => handlePodClick(strain, cardIndex)}
              strain={strain}
            />
          </SC.PodCardContainer>
        );
      })}
    </SC.PodGridRow>
  ));

  return (
    <SC.PodGridContainer>
      <InfiniteLoadingList
        hasMoreItems={hasMoreItems}
        loadMoreRows={fetchNextPage}
        rowHeight={rowHeight}
        rows={rows}
        width={gridWidth}
      />
    </SC.PodGridContainer>
  );
};
