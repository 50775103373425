import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

import { ConnectedPodJson } from "../../../api/consumer/types/connectedPods";
import { getDevicePodStrainId } from "../../../device/selectors";
import * as r from "../../../main/routes";
import { StarRating } from "../../../pod-feedback/components/StarRating";
import { PodAverageRating } from "../../../pods/components/PodAverageRating";
import * as strainColors from "../../../shared/colors/Strain";
import { Column } from "../../../shared/components/Flex";
import { Heart, PodSwitcherCheckmark } from "../../../shared/components/Icons";
import * as Text from "../../../shared/components/Text";
import { useDesktopMediaQuery } from "../../../shared/hooks";
import {
  desktopBreakpoint,
  getStrainClassificationInitial,
} from "../../../shared/utils";

import * as SC from "../../styledComponents";
import * as t from "../../types";
import { getMyPodsLastUsedText } from "../../utils";

export type MyPodCardProps = {
  height: number;
  onHeartClick?: t.OnHeartClick;
  pod: ConnectedPodJson | t.StrainWithFavoriteStatus;
  podType: t.MyPodsType;
  width: number;
};

export const MyPodCard: React.FC<MyPodCardProps> = ({
  height,
  onHeartClick,
  pod,
  podType,
  width,
}) => {
  const { strain } = pod;
  const isDesktop = useDesktopMediaQuery();
  const isCurrentPod = useSelector(getDevicePodStrainId) === strain.id;

  const colors = strainColors.getStrainColors(strain);
  const strainClassificationInitial = getStrainClassificationInitial(
    strain.classification
  );

  const lastUsed = podType === t.MyPodsType.HISTORY && (
    <sc.LastUsed data-testid="last-used">
      {getMyPodsLastUsedText(
        (pod as ConnectedPodJson).latestClientTimestamp,
        (pod as ConnectedPodJson).isLow
      )}
    </sc.LastUsed>
  );

  const rating =
    podType === t.MyPodsType.HISTORY ? (
      <sc.MyRating
        data-testid="my-rating"
        value={(pod as ConnectedPodJson).latestReview?.rating}
      />
    ) : (
      <sc.AverageRatingContainer data-testid="average-rating">
        <PodAverageRating
          ratingsAverage={strain.averageRating}
          ratingsCount={strain.numRatings}
        />
      </sc.AverageRatingContainer>
    );

  const shouldShowHeart =
    strain.isFavorited || podType === t.MyPodsType.FAVORITES;

  const favoriteHeart =
    podType === t.MyPodsType.HISTORY ? (
      <sc.Heart data-testid="heart-true" />
    ) : (
      <sc.HeartButtonContainer
        onClick={(): void => {
          if (onHeartClick) onHeartClick(strain);
        }}
      >
        <sc.HeartButton
          $isFavorited={(pod as t.StrainWithFavoriteStatus).isFavorited}
          data-testid={
            "heart-" + (pod as t.StrainWithFavoriteStatus).isFavorited
          }
        />
      </sc.HeartButtonContainer>
    );

  const renderDesktopContent = (): React.ReactElement => {
    return (
      <>
        <sc.Classification
          backgroundColor={colors.primary}
          textColor={colors.secondary}
        >
          {strainClassificationInitial}
        </sc.Classification>
        {isCurrentPod && <sc.CurrentPodIcon />}
        {shouldShowHeart && favoriteHeart}
        <Link to={r.podDetails(strain.id)}>
          <SC.PodCardPartnerLogo
            borderColor={colors.primary}
            isFavorite={podType === t.MyPodsType.FAVORITES}
            partner={strain.partner}
          />
          <sc.Name>{strain.name}</sc.Name>
          {lastUsed}
          {rating}
        </Link>
      </>
    );
  };

  const renderMobileContent = (): React.ReactElement => {
    return (
      <>
        {shouldShowHeart && favoriteHeart}
        <Link to={r.podDetails(strain.id)}>
          <SC.PodCardPartnerLogo
            borderColor={colors.primary}
            partner={strain.partner}
          />
          <sc.Content>
            <sc.Name>{strain.name}</sc.Name>
            {lastUsed}
            {rating}
          </sc.Content>
          {isCurrentPod && <sc.CurrentPodIcon />}
          <sc.Classification
            backgroundColor={colors.primary}
            textColor={colors.secondary}
          >
            {strainClassificationInitial}
          </sc.Classification>
        </Link>
      </>
    );
  };

  return (
    <SC.PodCardContainer
      data-testid={`my-pod-card-${strain.id}`}
      height={height}
      width={width}
    >
      {isDesktop ? renderDesktopContent() : renderMobileContent()}
    </SC.PodCardContainer>
  );
};

type ClassificationProps = {
  backgroundColor: string;
  textColor: string;
};

const sc = {
  AverageRatingContainer: styled.div`
    margin-top: 10px;
  `,

  Classification: styled.div<ClassificationProps>`
    ${Text.PlaaxBold}
    background: ${({ backgroundColor }) => backgroundColor};
    border-radius: 100px;
    bottom: 26px;
    color: ${({ textColor }) => textColor};
    font-size: 14px;
    height: 20px;
    line-height: 18px;
    position: absolute;
    right: 16px;
    text-align: center;
    width: 20px;

    ${desktopBreakpoint(
      css`
        left: 16px;
        top: 16px;
      `
    )}
  `,

  Content: styled(Column)`
    margin: auto 0;
    min-width: 140px;
  `,

  CurrentPodIcon: styled(PodSwitcherCheckmark)`
    background: var(--black);
    border-radius: 100px;
    bottom: 26px;
    height: 20px;
    padding: 3px;
    position: absolute;
    right: 44px;
    width: 20px;

    > path {
      fill: var(--white);
    }

    ${desktopBreakpoint(
      css`
        left: 44px;
        top: 16px;
      `
    )}
  `,

  Heart: styled(Heart)`
    position: absolute;
    right: 17px;
    top: 16px;

    > path {
      fill: var(--very-light-pink);
      stroke: var(--very-light-pink);
    }
  `,

  HeartButton: styled(Heart)`
    cursor: pointer;
    position: absolute;
    right: 17px;
    top: 16px;
    z-index: var(--z-index-favorite-heart);
  `,

  // Provides a larger click area for the Heart on mobile
  HeartButtonContainer: styled.div`
    height: 50px;
    position: absolute;
    right: 0;
    top: 0;
    width: 50px;
  `,

  LastUsed: styled.div`
    ${Text.Plaax}
    font-size: 14px;
    line-height: 20px;
  `,

  MyRating: styled(StarRating)`
    margin-top: 6px;
  `,

  Name: styled.div`
    ${Text.PlaaxBold}
    font-size: 14px;
    height: 20px;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    ${desktopBreakpoint(
      css`
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        display: -webkit-box;
        height: 36px;
        line-height: 1.13;
        margin: 6px 16px 0;
        text-align: center;
        white-space: normal;
      `
    )}
  `,
};
