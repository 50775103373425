import { connect } from "react-redux";

import * as cookies from "../../cookies";
import { MainAppState } from "../../main/types";

import { login } from "../actions";
import { SsoLoginPage } from "../components/SsoLoginPage";

const mapStateToProps = (state: MainAppState) => {
  const loginCookie = cookies.getLoginCookie();
  const initialEmail = state.sso.login.initialEmail;

  return {
    anonymousUuid: cookies.getAnonymousUserUuid(),
    initialEmail,
    loginCookie,
    ...state.sso.login,
  };
};

const mapDispatchToProps = {
  login,
};

export default connect(mapStateToProps, mapDispatchToProps)(SsoLoginPage);
