import React from "react";
import * as reactRedux from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";

import * as actions from "../../device/actions";
import { Banner } from "../../shared/components/Banner";
import * as Text from "../../shared/components/Text";
import * as text from "../../shared/text";
import { UseDispatch } from "../../shared/types";

export type EncryptionErrorBannerProps = {
  disconnectDevice?: actions.DisconnectDevice;
  useDispatch?: UseDispatch;
};

export const EncryptionErrorBanner: React.FC<EncryptionErrorBannerProps> = ({
  disconnectDevice = actions.disconnectDevice,
  useDispatch = reactRedux.useDispatch,
}) => {
  const dispatch = useDispatch();

  const handleReconnectDeviceClick = (): void => {
    dispatch(
      disconnectDevice({
        error: text.DEVICE_SYNCING_DISCONNECTED,
      })
    );
  };

  return (
    <Banner data-testid="encryption-error-banner">
      <span>
        {text.ISSUES_COMMUNICATING_WITH_DEVICE}
        <sc.ReconnectLink
          data-testid="reconnect-link"
          to="#"
          onClick={handleReconnectDeviceClick}
        >
          <Text.Bold>{text.RECONNECT}</Text.Bold>
        </sc.ReconnectLink>
      </span>
    </Banner>
  );
};

const sc = {
  ReconnectLink: styled(Link)`
    color: var(--white);
    margin-left: 4px;
    text-transform: uppercase;
  `,
};
