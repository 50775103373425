import { EffectJson, StrainJson } from "../../api/consumer/types/strain";
import { AppThunk } from "../../shared/types";

import { trackEventAction } from "../actions";

const EVENT_NAME = "effects_click";

type EffectsClickEventProperties = {
  isBrandPartnerSelected: boolean;
  partnerId: string;
  position: number;
  selectedEffect: string;
  strainId: string;
  userFeltPercentage: number;
};

type TrackEffectsClick = (
  effect: EffectJson,
  strain: StrainJson,
  position: number
) => AppThunk;

export const trackEffectsClick: TrackEffectsClick =
  (effect, strain, position = 1): AppThunk =>
  (dispatch, getState): void => {
    trackEventAction<EffectsClickEventProperties>(EVENT_NAME, {
      isBrandPartnerSelected: effect.wasBpSelected,
      partnerId: strain.partner.id,
      position,
      selectedEffect: effect.effect,
      strainId: strain.id,
      userFeltPercentage: effect.percentEffect,
    })(dispatch, getState, null);
  };
