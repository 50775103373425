import React from "react";

import { DEFAULT_BAR_HEIGHT } from "../../constants";

type PodPlaceholderBarProps = {
  height?: number;
  width: number;
};

export const PodPlaceholderBar: React.FC<PodPlaceholderBarProps> = ({
  height = DEFAULT_BAR_HEIGHT,
  width,
}) => {
  const strokeOffset = height / 2;
  const viewWidth = width + strokeOffset;

  return (
    <svg viewBox={`0 0 ${viewWidth} ${height}`}>
      <rect
        fill="var(--khaki-20)"
        height={height}
        width={width + strokeOffset}
        rx="4"
      />
    </svg>
  );
};
