import { useFormik } from "formik";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import * as consumerApiActions from "../../../api/consumer/actions/devices";
import { getAccountDevice } from "../../../api/consumer/selectors/devices";
import { getConnectedDevice } from "../../../bluetooth/connectedDevice";
import * as deviceActions from "../../../device/actions";
import { DEVICE_NAME_MAX_LENGTH } from "../../../device/constants";
import * as deviceSelectors from "../../../device/selectors";
import { MainAppState } from "../../../main/types";
import { ModalProps } from "../../../modal/components/Modal";
import { DeviceLogo } from "../../../my-pax/components/DeviceLogo";
import { PlaaxButton } from "../../../shared/components/Button";
import * as flashBanner from "../../../shared/components/FlashBanner";
import { Row } from "../../../shared/components/Flex";
import * as Icons from "../../../shared/components/Icons";
import { Slider } from "../../../shared/components/Slider";
import * as Text from "../../../shared/components/Text";
import * as text from "../../../shared/text";
import { removeEmojis } from "../../../shared/utils";

export const DeviceSettingsModalContent: React.FC<ModalProps> = () => {
  const dispatch = useDispatch();
  const brightness = useSelector(deviceSelectors.getBrightness);
  const haptics = useSelector(deviceSelectors.getHapticsForDevice);
  const isDeviceLocked = useSelector(deviceSelectors.getIsDeviceLocked);

  const handleBrightnessChange = (value: number): void => {
    const percentage = value / 100;
    dispatch(deviceActions.setBrightness(percentage));
  };

  const handleHapticsChange = (value: number): void => {
    dispatch(deviceActions.setHaptics(value));
  };

  const handleDisconnectClick = (): void => {
    dispatch(deviceActions.disconnectDevice());
  };

  return (
    <>
      <sc.Title>{text.SETTINGS}</sc.Title>
      <sc.Subtitle>{text.DEVICE_NAME}</sc.Subtitle>
      <DeviceNameRow />
      <sc.Subtitle>{text.DEVICE_SETTINGS}</sc.Subtitle>
      <sc.Settings>
        <sc.Label>{text.BRIGHTNESS}</sc.Label>
        <sc.Slider
          isDisabled={isDeviceLocked}
          onAfterChange={handleBrightnessChange}
          value={brightness * 100}
        />
        <sc.Label>{text.HAPTICS}</sc.Label>
        <sc.Slider
          isDisabled={isDeviceLocked}
          onAfterChange={handleHapticsChange}
          value={haptics}
        />
      </sc.Settings>
      <sc.DisconnectButton onClick={handleDisconnectClick}>
        {text.DISCONNECT}
      </sc.DisconnectButton>
    </>
  );
};

const DeviceNameRow: React.FC = () => {
  const dispatch = useDispatch();
  const deviceName = useSelector(deviceSelectors.getDeviceName);
  const isDeviceLocked = useSelector(deviceSelectors.getIsDeviceLocked);
  const connectedDevice = getConnectedDevice();
  const apiDevice = useSelector((state: MainAppState) =>
    getAccountDevice(state, { serial: connectedDevice?.serial })
  );
  const [editMode, setEditMode] = useState(false);

  const handleDeviceNameClick = (): void => {
    if (isDeviceLocked) return;
    setEditMode(true);
  };

  const formik = useFormik({
    initialValues: { deviceName },
    onSubmit: (values) => {
      const updatedDeviceName = removeEmojis(values.deviceName);

      dispatch(deviceActions.setDeviceName(updatedDeviceName));
      setEditMode(false);

      if (!connectedDevice || !connectedDevice.serial) return;

      try {
        dispatch(
          consumerApiActions.updateDevice(connectedDevice.serial, {
            deviceName: updatedDeviceName,
          })
        );
      } catch (err) {
        dispatch(
          flashBanner.showFlashBanner(
            text.OOPS_AN_ERROR_OCCURRED_TRY_AGAIN,
            flashBanner.BannerType.ERROR
          )
        );
      }
    },
  });

  if (editMode) {
    return (
      <sc.DeviceNameRow>
        {apiDevice && <sc.DeviceLogo device={apiDevice} />}
        <form data-testid="device-name-form" onSubmit={formik.handleSubmit}>
          <input
            data-testid="device-name-input"
            id="deviceName"
            maxLength={DEVICE_NAME_MAX_LENGTH}
            name="deviceName"
            onBlur={(): void => formik.handleSubmit()}
            onChange={formik.handleChange}
            required
            type="text"
            value={removeEmojis(formik.values.deviceName)}
          />
        </form>
      </sc.DeviceNameRow>
    );
  }

  return (
    <sc.DeviceNameRow>
      {apiDevice && <sc.DeviceLogo device={apiDevice} />}
      <sc.DeviceName
        data-testid="device-name"
        onClick={isDeviceLocked ? undefined : handleDeviceNameClick}
      >
        {deviceName}
      </sc.DeviceName>
      {!isDeviceLocked && (
        <sc.Pencil
          data-testid="device-name-edit"
          onClick={handleDeviceNameClick}
        />
      )}
    </sc.DeviceNameRow>
  );
};

const sc = {
  DeviceLogo: styled(DeviceLogo)`
    background-position: 9px 8px;
    background-size: 20px;
    height: 40px;
    margin: 0 12px 0 0;
    width: 40px;
  `,

  DeviceName: styled.div`
    ${Text.Plaax}
    border-bottom: 1px solid transparent;
    cursor: ${(props) => (props.onClick ? "pointer" : "auto")};
    font-size: 14px;

    &:hover {
      border-bottom: ${(props) =>
        props.onClick ? "1px solid var(--black)" : ""};
    }
  `,

  DeviceNameRow: styled(Row)`
    align-items: center;
    background-color: var(--white-50);
    border-radius: 8px;
    height: 56px;
    margin-top: 12px;
    padding: 8px;
    position: relative;
  `,

  DisconnectButton: styled(PlaaxButton)`
    margin-top: 64px;
    width: 100%;
  `,

  Label: styled.div`
    ${Text.Plaax}
    font-size: 14px;
    line-height: 20px;
  `,

  Pencil: styled(Icons.Pencil)`
    cursor: pointer;
    height: 17px;
    position: absolute;
    right: 20px;
    top: 20px;
    width: 16px;
  `,

  Settings: styled.div`
    background-color: var(--white-50);
    border-radius: 8px;
    height: 156px;
    margin-top: 12px;
    padding: 16px;
    width: 296px;
  `,

  Slider: styled(Slider)`
    margin: 16px 0 20px 0;
  `,

  Subtitle: styled.div`
    ${Text.PlaaxBold}
    font-size: 14px;
    line-height: 20px;
    margin-top: 24px;
  `,

  Title: styled.div`
    ${Text.PlaaxBold}
    font-size: 20px;
    line-height: 24px;
  `,
};
