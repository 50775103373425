import AddDevice from "./containers/AddDevice";
import DeviceTypeSelector from "./containers/DeviceTypeSelector";
import PairingInstructions from "./containers/PairingInstructions";
import SerialInput from "./containers/SerialInput";
import ShakeInstructions from "./containers/ShakeInstructions";
import * as t from "./types";

export const DeviceConnectionFlows = {
  addDevice: {
    [t.DeviceConnectionStep.ADD_DEVICE]: {
      back: t.DeviceConnectionStep.CLOSE_MODAL,
      next: SerialInput,
    },
    [t.DeviceConnectionStep.SERIAL_INPUT]: {
      back: AddDevice,
      next: ShakeInstructions,
    },
    [t.DeviceConnectionStep.SHAKE_INSTRUCTIONS]: {
      back: SerialInput,
      next: PairingInstructions,
    },
    [t.DeviceConnectionStep.PAIRING_INSTRUCTIONS]: {
      back: AddDevice,
      next: t.DeviceConnectionStep.CLOSE_MODAL,
    },
  },
  connectDevice: {
    [t.DeviceConnectionStep.RECENT_DEVICES]: {
      back: DeviceTypeSelector,
      next: ShakeInstructions,
    },
    [t.DeviceConnectionStep.DEVICE_TYPE_SELECTOR]: {
      back: t.DeviceConnectionStep.CLOSE_MODAL,
      next: SerialInput,
    },
    [t.DeviceConnectionStep.SERIAL_INPUT]: {
      back: DeviceTypeSelector,
      next: ShakeInstructions,
    },
    [t.DeviceConnectionStep.SHAKE_INSTRUCTIONS]: {
      back: SerialInput,
      next: PairingInstructions,
    },
    [t.DeviceConnectionStep.PAIRING_INSTRUCTIONS]: {
      back: DeviceTypeSelector,
      next: t.DeviceConnectionStep.CLOSE_MODAL,
    },
  },
};
