import { capitalize } from "lodash";
import React from "react";
import * as ReactRouterDom from "react-router-dom";
import styled from "styled-components";

import * as r from "../../../main/routes";
import { ChevronCard } from "../../../shared/components/ChevronCard";
import { Column, Row } from "../../../shared/components/Flex";
import { PaxLabsLogo, TestBadge } from "../../../shared/components/Icons";
import * as Text from "../../../shared/components/Text";
import * as text from "../../../shared/text";

import { PodSectionTitleStyles } from "../../styledComponents";

type TestResultSectionProps = {
  latestBatchId: string;
  strainId: string;
};

export const TestResultSection: React.FC<TestResultSectionProps> = ({
  latestBatchId,
  strainId,
}) => {
  return (
    <sc.Container>
      <sc.Title>{text.TESTING}</sc.Title>
      <sc.Subtitle>{text.PASSED_ALL_TESTS_FOR_SAFETY_AND_PURITY}</sc.Subtitle>
      {latestBatchId ? (
        <sc.TestReportLink to={r.podManualTestResults(latestBatchId, strainId)}>
          <ChevronCard>
            <Row alignItems="center">
              <TestBadge />
              <sc.ContentRight>
                <sc.PaxLabsLogo />
                {capitalize(text.LAB_REPORT)}
              </sc.ContentRight>
            </Row>
          </ChevronCard>
        </sc.TestReportLink>
      ) : (
        <sc.CustomChevronCard hideChevron>
          <Row alignItems="center">
            <TestBadge />
            <sc.ContentRight>
              <sc.PaxLabsLogo />
              {capitalize(text.LAB_TESTED)}
            </sc.ContentRight>
          </Row>
        </sc.CustomChevronCard>
      )}
    </sc.Container>
  );
};

const sc = {
  Container: styled.div`
    position: relative;
  `,

  ContentRight: styled(Column)`
    margin-left: 14px;
  `,

  CustomChevronCard: styled(ChevronCard)`
    border: solid 1px var(--khaki-20);
    box-shadow: none;
  `,

  PaxLabsLogo: styled(PaxLabsLogo)`
    margin-bottom: 3px;
    margin-left: 1px;
  `,

  Subtitle: styled.div`
    ${Text.MediumLLLight}
    font-size: 14px;
    margin: 8px 0 16px;
  `,

  TestReportLink: styled(ReactRouterDom.Link)`
    color: var(--black);
    display: block;
    text-align: center;
    text-decoration: none;
  `,

  Title: styled.div`
    ${PodSectionTitleStyles}
  `,
};
