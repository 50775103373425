import React from "react";

import { useDesktopMediaQuery } from "../../shared/hooks";

import * as c from "../constants";
import { useResponsiveScreenSize } from "../hooks";
import * as SC from "../styledComponents";
import { PodLoadingCard } from "./PodCard";

export const PodsLoadingGrid: React.FC = () => {
  const isDesktop = useDesktopMediaQuery();
  const screenSize = useResponsiveScreenSize();
  const rowHeight = isDesktop
    ? c.POD_GRID_ROW_HEIGHT_DESKTOP
    : c.POD_GRID_ROW_HEIGHT_MOBILE;

  const loadingRow = (
    <SC.PodGridRow data-testid="loading-row" height={rowHeight}>
      {Array.from({ length: c.RESPONSIVE_POD_COLUMN_COUNT[screenSize] }).map(
        (_, i) => (
          <SC.PodCardContainer key={i}>
            <PodLoadingCard data-testid="loading-card" />
          </SC.PodCardContainer>
        )
      )}
    </SC.PodGridRow>
  );

  return (
    <SC.PodGridContainer>
      {loadingRow}
      {loadingRow}
      {loadingRow}
      {loadingRow}
    </SC.PodGridContainer>
  );
};
