import { animated, useSpring } from "@react-spring/web";
import React, { useRef } from "react";
import styled from "styled-components";

import { Row } from "../../../../shared/components/Flex";
import { useGetComponentWidth } from "../../../../shared/hooks";
import { StrainColor } from "../../../../shared/types";

import { DEFAULT_BAR_HEIGHT } from "../../../constants";

import { PodOuterBar } from "../PodOuterBar";
import { PodPlaceholderBar } from "../PodPlaceholderBar";

const BAR_WIDTH = 318;
const MODULE_BAR_WIDTH = 296;

type PodStrengthBarProps = {
  height?: number;
  isModule?: boolean;
  strainColor: StrainColor;
  strengthRange: number[];
};

export const PodStrengthBar: React.FC<PodStrengthBarProps> = ({
  height = DEFAULT_BAR_HEIGHT,
  isModule = false,
  strainColor,
  strengthRange,
}) => {
  const { x } = useSpring({
    config: { friction: 80, mass: 1, tension: 280 },
    delay: 700,
    from: { x: 0 },
    x: 1,
  });

  const rangeIncludesMax = strengthRange.length > 1;
  const widthBuffer = height / 2;
  const ref = useRef<HTMLDivElement>(null);
  const width = useGetComponentWidth(
    ref,
    undefined,
    isModule ? MODULE_BAR_WIDTH : BAR_WIDTH
  );
  const fillWidthMax = width - widthBuffer;

  const fillWidth =
    Math.max(fillWidthMax * (strengthRange[0] / 100), 0) + widthBuffer;
  const gradientFillWidth = rangeIncludesMax
    ? Math.max(width * (strengthRange[1] / 100), widthBuffer)
    : 0;

  return (
    <sc.BarContainer
      data-testid="pod-strength-bar"
      height={height}
      ref={ref}
      width={isModule ? width : undefined}
    >
      {!strainColor.primary && (
        <PodPlaceholderBar height={height} width={width} />
      )}
      <sc.Svg viewBox={`0 0 ${width} ${height}`}>
        <sc.PodOuterBar
          color={strainColor.primary}
          height={height}
          width={width}
        />
      </sc.Svg>
      {rangeIncludesMax && (
        <sc.AnimatedSvg
          viewBox={x.to((x) => {
            return `0 0 ${x * gradientFillWidth} ${height}`;
          })}
          width={x.to((x) => {
            return x * gradientFillWidth;
          })}
        >
          <defs>
            <linearGradient id="gradient1" x1="0%" y1="0%" x2="100%" y2="0%">
              <stop
                offset={`${strengthRange[0]}%`}
                stopColor={strainColor.primary}
                stopOpacity={1}
              />
              <stop
                offset="100%"
                stopColor={strainColor.primary}
                stopOpacity={0.2}
              />
            </linearGradient>
          </defs>
          <sc.Rect
            data-testid="pod-strength-gradient-bar"
            fill="url(#gradient1)"
            height={height}
            rx="4"
            width={gradientFillWidth}
          />
        </sc.AnimatedSvg>
      )}
      <sc.AnimatedSvg
        viewBox={x.to((x) => {
          return `0 0 ${x * fillWidth} ${height}`;
        })}
        width={x.to((x) => {
          return x * fillWidth;
        })}
      >
        <sc.Rect
          data-testid="pod-strength-solid-bar"
          fill={strainColor.primary}
          height={height}
          rx="4"
          width={fillWidth}
        />
      </sc.AnimatedSvg>
    </sc.BarContainer>
  );
};

type BarContainerProps = {
  height: number;
  width?: number;
};

const sc = {
  AnimatedSvg: styled(animated.svg)`
    left: 0;
    position: absolute;
  `,

  BarContainer: styled(Row)<BarContainerProps>`
    height: ${({ height }) => height}px;
    position: relative;
    width: ${({ width }) => (width ? width + "px" : "100%")};
  `,

  PodOuterBar: styled(PodOuterBar)`
    height: 20px;
    opacity: 0.3;
  `,

  Rect: styled.rect`
    left: 0;
    position: absolute;
  `,

  Svg: styled.svg`
    left: 2px;
    position: absolute;
  `,
};
