import { connect } from "react-redux";

import { getConnectedDevice } from "../../bluetooth/connectedDevice";
import * as deviceSelectors from "../../device/selectors";
import {
  installFirmware,
  setFirmwareUpgradeProgress,
} from "../../firmware/actions";
import * as t from "../../main/types";

import * as firmwareUpdateProgressModalContent from "../components/FirmwareUpdateProgressModalContent";
import * as firmwareSelectors from "../selectors";

const mapStateToProps = (
  state: t.MainAppState,
  ownProps: firmwareUpdateProgressModalContent.OwnProps
): firmwareUpdateProgressModalContent.StateProps => {
  const { progress, message, title } =
    firmwareSelectors.getFirmwareUpgradeState(state);

  return {
    ...ownProps,
    device: getConnectedDevice(),
    deviceColor: deviceSelectors.getShellColorCode(state),
    firmwareInfo: firmwareSelectors.getFirmwareInfo(state),
    isDeviceRestarting: firmwareSelectors.getIsDeviceRestarting(state),
    isLowBattery: deviceSelectors.getBatteryLevel(state) < 25,
    isUpgradeComplete: firmwareSelectors.getIsFirmwareUpgradeComplete(state),
    message,
    progress,
    title,
  };
};

const mapDispatchToProps: firmwareUpdateProgressModalContent.DispatchProps = {
  installFirmware,
  setFirmwareUpgradeProgress,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(firmwareUpdateProgressModalContent.FirmwareUpdateProgressModalContent);
