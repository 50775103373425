import { connect } from "react-redux";

import { updateUser } from "../../api/consumer/actions/users";
import { getThemes } from "../../api/consumer/selectors/user";
import { MainAppState } from "../../main/types";

import * as profileThemePicker from "../components/ProfileThemePicker";

const mapStateToProps = (
  state: MainAppState
): profileThemePicker.StateProps => ({
  activeThemeId: state.consumerApi.user.profile?.theme?.id,
  themes: getThemes(state),
});

const mapDispatchToProps: profileThemePicker.DispatchProps = {
  updateUser,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(profileThemePicker.ProfileThemePicker);
