import { get as lsGet } from "local-storage";
import { v4 as uuidv4 } from "uuid";

import { EraLogRequestOptions } from "./era/EraLogStore";
import { EraProLogRequestOptions } from "./era-pro/EraProLogStore";
import { LogEventAnalyticsType } from "./shared/BaseLogStore";
import { TextDecoder, TextEncoder } from "./vendor/EncoderAndDecoderNodeJS.src";
import * as c from "./constants";
import * as t from "./types";

const decoder = new TextDecoder();
const encoder = new TextEncoder();

export const decodeTextValue = (
  data: ArrayBuffer | ArrayBufferView
): string => {
  return decoder.decode(data);
};

export const encodeTextValue = (text: string): Uint8Array | Buffer => {
  return encoder.encode(text);
};

export const byteArrayToUnsignedInt = (
  data: Uint8Array,
  offset: number,
  endian: t.Endian,
  length: number
): number => {
  if (length < 0 || length > 4) {
    throw new Error(
      `Length must be between 0 and 4 inclusive (length=${length}).`
    );
  }

  let result = 0;

  for (let i = 0; i < length; i++) {
    const unsignedByte = byteToUnsignedInt(data[i + offset]);

    if (endian === null || endian === t.Endian.BIG) {
      result += unsignedByte * Math.pow(2, (length - 1 - i) * 8); // big endian
    } else {
      // Math.pow is used instead of the bitwise operator <<.
      // Shift operators in JS convert their operands to 32-bit integers which overflow when length >= 4.
      result += unsignedByte * Math.pow(2, i * 8);
    }
  }

  return result;
};

const byteToUnsignedInt = (b: number): number => {
  return b & 0xff;
};

export const mergeTypedArrays = (a: Uint8Array, b: Uint8Array): Uint8Array => {
  // Check for truthy values or empty arrays to avoid unnecessary construction of a new array.
  if (!b || b.length === 0) return a;
  if (!a || a.length === 0) return b;

  const c = new Uint8Array(a.length + b.length);
  c.set(a);
  c.set(b, a.length);

  return c;
};

export const getUuid = (length?: number): string => {
  let uuid = uuidv4().replace(/-/g, "");

  if (length !== undefined) {
    uuid = uuid.slice(0, length);
  }

  return uuid;
};

export const generateCrc16 = (bytes: Uint8Array): Uint8Array => {
  let crc = 0xbeef; // initial value
  const polynomial = 0x1021; // 0001 0000 0010 0001  (0, 5, 12)
  bytes.forEach((byte) => {
    for (let i = 0; i < 8; i++) {
      const bit = ((byte >> (7 - i)) & 1) === 1;
      const c15 = ((crc >> 15) & 1) === 1;
      crc = crc << 1;
      if (c15 !== bit) crc = crc ^ polynomial;
    }
  });

  const buffer = new ArrayBuffer(2);
  const view = new DataView(buffer);
  const crcValue = crc & 0xffff;
  view.setUint16(0, crcValue, true);

  return new Uint8Array(buffer);
};

export const areEraColorModesEqual = (
  cm1: t.ColorMode,
  cm2: t.ColorMode
): boolean => {
  if (cm1 === cm2) return true;
  if (!cm1 || !cm2) return false;

  return (
    cm1.color1.red === cm2.color1.red &&
    cm1.color1.green === cm2.color1.green &&
    cm1.color1.blue === cm2.color1.blue &&
    cm1.color2.red === cm2.color2.red &&
    cm1.color2.green === cm2.color2.green &&
    cm1.color2.blue === cm2.color2.blue &&
    cm1.animation === cm2.animation &&
    cm1.frequency === cm2.frequency
  );
};

export const arePax3ColorModesEqual = (
  cm1: t.Pax3.ColorMode,
  cm2: t.Pax3.ColorMode
): boolean => {
  if (cm1 === cm2) return true;
  if (!cm1 || !cm2) return false;

  return (
    cm1.startup.color1.red === cm2.startup.color1.red &&
    cm1.startup.color1.green === cm2.startup.color1.green &&
    cm1.startup.color1.blue === cm2.startup.color1.blue &&
    cm1.startup.color2.red === cm2.startup.color2.red &&
    cm1.startup.color2.green === cm2.startup.color2.green &&
    cm1.startup.color2.blue === cm2.startup.color2.blue &&
    cm1.startup.animation === cm2.startup.animation &&
    cm1.startup.frequency === cm2.startup.frequency &&
    cm1.heating.color1.red === cm2.heating.color1.red &&
    cm1.heating.color1.green === cm2.heating.color1.green &&
    cm1.heating.color1.blue === cm2.heating.color1.blue &&
    cm1.heating.color2.red === cm2.heating.color2.red &&
    cm1.heating.color2.green === cm2.heating.color2.green &&
    cm1.heating.color2.blue === cm2.heating.color2.blue &&
    cm1.heating.animation === cm2.heating.animation &&
    cm1.heating.frequency === cm2.heating.frequency &&
    cm1.regulating.color1.red === cm2.regulating.color1.red &&
    cm1.regulating.color1.green === cm2.regulating.color1.green &&
    cm1.regulating.color1.blue === cm2.regulating.color1.blue &&
    cm1.regulating.color2.red === cm2.regulating.color2.red &&
    cm1.regulating.color2.green === cm2.regulating.color2.green &&
    cm1.regulating.color2.blue === cm2.regulating.color2.blue &&
    cm1.regulating.animation === cm2.regulating.animation &&
    cm1.regulating.frequency === cm2.regulating.frequency &&
    cm1.standby.color1.red === cm2.standby.color1.red &&
    cm1.standby.color1.green === cm2.standby.color1.green &&
    cm1.standby.color1.blue === cm2.standby.color1.blue &&
    cm1.standby.color2.red === cm2.standby.color2.red &&
    cm1.standby.color2.green === cm2.standby.color2.green &&
    cm1.standby.color2.blue === cm2.standby.color2.blue &&
    cm1.standby.animation === cm2.standby.animation &&
    cm1.standby.frequency === cm2.standby.frequency
  );
};

export const getEraProLogRequestFromLocalStorage = (
  deviceSystemId: string | undefined
): EraProLogRequestOptions | undefined => {
  if (!deviceSystemId) return;

  return JSON.parse(
    lsGet<string>(`__paxWeb.deviceLogs.${deviceSystemId}.nextLogToRequest`)
  );
};

export const getEraLogRequestFromLocalStorage = (
  deviceSystemId: string | undefined
): EraLogRequestOptions | undefined => {
  if (!deviceSystemId) return;

  return JSON.parse(
    lsGet<string>(`__paxWeb.deviceLogs.${deviceSystemId}.nextLogToRequest`)
  );
};

export const getLogStoreAnalyticsData = (
  logCount: number,
  logEventType: LogEventAnalyticsType,
  timeDelta: bigint | number,
  puffLogsCount?: number
): t.LogStoreAnalyticsData => {
  const logStoreData: t.LogStoreAnalyticsData = {
    action: "preemptive_upload",
    hasReachedEnd: false,
    logCount: logCount,
  };

  if (puffLogsCount) {
    logStoreData.puffLogsCount = puffLogsCount;
  }

  switch (logEventType) {
    case LogEventAnalyticsType.RESET_EVENT:
      logStoreData.resetLogEvent = true;
      break;
    case LogEventAnalyticsType.UNSYNCED_LOGS_THRESHOLD:
      logStoreData.reachedWebLogThreshold = true;
      break;
    case LogEventAnalyticsType.TIME_SYNC_EVENT:
      logStoreData.timeSyncDeltaS = Number(timeDelta);
      logStoreData.timeSyncLogEvent = true;
  }

  return logStoreData;
};

export const getDecryptionError = (
  data: Uint8Array,
  decryptedValue: Uint8Array
): Error => {
  const attributeId = decryptedValue[0];
  const expectedDataLength = data[2]; // includes CRC16 but not 20 byte header
  const actualDataLength = decryptedValue.length;

  const validAttributes = [
    c.ATTRIBUTE_BATTERY,
    c.ATTRIBUTE_BRIGHTNESS,
    c.ATTRIBUTE_CHARGE_STATUS,
    c.ATTRIBUTE_DEVICE_NAME,
    c.ATTRIBUTE_ENCRYPTION_PACKET,
    c.ATTRIBUTE_HAPTICS,
    c.ATTRIBUTE_HEATER_SET_POINT,
    c.ATTRIBUTE_LOCKED,
    c.ATTRIBUTE_POD_DATA,
    c.ATTRIBUTE_POD_INSERTED,
    c.ATTRIBUTE_SESSION_CONTROL,
    c.ATTRIBUTE_SHELL_COLOR,
    c.ATTRIBUTE_SUPPORTED_ATTRIBUTES,
    c.ATTRIBUTE_TIME,
  ];

  const validLogs = [
    c.LOG_RESET,
    c.LOG_ERROR,
    c.LOG_RESET_V2,
    c.LOG_PUFF,
    c.LOG_PUFF_V2,
    c.LOG_PUFF_V3,
    c.LOG_HEATER_SET_POINT,
    c.LOG_LOCK,
    c.LOG_UNLOCK,
    c.LOG_BRIGHTNESS,
    c.LOG_CHARGER_IN,
    c.LOG_CHARGER_OUT,
    c.LOG_CHARGE_COMPLETE,
    c.LOG_CHARGE_CHANGE,
    c.LOG_POD_INSERTED,
    c.LOG_POD_REMOVED,
    c.LOG_POD_READ,
    c.LOG_POD_READ_V2,
    c.LOG_STATE_CHANGE,
    c.LOG_MOTION_SENSOR,
    c.LOG_DISCONNECT_REASON,
    c.LOG_CONNECT,
    c.LOG_DISCONNECT,
    c.LOG_APP_COMMAND,
    c.LOG_NAME_CHANGE,
    c.LOG_DFU_START,
    c.LOG_TIME_SYNC,
    c.LOG_BLUETOOTH_ERROR,
    c.LOG_ASSERT,
    c.LOG_LOW_BATTERY,
    c.LOG_INACTIVITY,
  ];

  let firstLogCode;
  let firstLogExpectedLength;
  let nextLogCode;
  let nextLogExpectedLength;
  let error;

  if (
    !validAttributes.includes(attributeId) &&
    !validLogs.includes(attributeId)
  ) {
    error = new Error("Invalid decrypted packet");
  } else if (
    // See: https://paxlabs.atlassian.net/l/c/qomjPd1f
    validLogs.includes(attributeId) &&
    expectedDataLength > actualDataLength &&
    actualDataLength === 81
  ) {
    firstLogCode = attributeId;
    firstLogExpectedLength = decryptedValue[14];

    const firstPacketEnd = firstLogExpectedLength + 16; // 16 = header + crc
    const nextLogPacket = decryptedValue.slice(firstPacketEnd);

    if (nextLogPacket.length > 14) {
      nextLogCode = nextLogPacket[0];
      nextLogExpectedLength = nextLogPacket[14];
    }

    error = new Error("Truncated log packet is missing CRC16 values");
  } else {
    error = new Error("Decrypted packet CRC16 values do not match");
  }

  console.error({
    actualDataLength,
    attributeId,
    decryptedValue,
    expectedDataLength,
    firstLogCode,
    firstLogExpectedLength,
    nextLogCode,
    nextLogExpectedLength,
  });

  return error;
};
