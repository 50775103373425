import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import * as analytics from "../../analytics/pods/recommendedPod";
import * as consumerTypes from "../../api/consumer/types";

import { PodCard } from "./PodCard";

export type RecommendedPodCardProps = {
  onPodRecommendationClick?: () => unknown;
  recommendedStrain: consumerTypes.StrainJson;
  referenceStrainReview: consumerTypes.reviews.StrainReviewJson;
};

export const RecommendedPodCard: React.FC<RecommendedPodCardProps> = ({
  onPodRecommendationClick,
  recommendedStrain,
  referenceStrainReview,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      analytics.trackRecommendedPodDisplayed(
        referenceStrainReview,
        recommendedStrain.id
      )
    );

    // Only fire when it's first displayed.
    //eslint-disable-next-line
  }, []);

  const handlePodRecommendationClick = (): void => {
    if (referenceStrainReview)
      dispatch(
        analytics.trackRecommendedPodClicked(
          referenceStrainReview,
          recommendedStrain.id
        )
      );

    if (onPodRecommendationClick) onPodRecommendationClick();
  };

  return (
    <PodCard
      onClick={handlePodRecommendationClick}
      strain={recommendedStrain}
    />
  );
};
