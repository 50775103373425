import { connect } from "react-redux";

import * as deviceSelectors from "../../device/selectors";
import * as t from "../../main/types";

import { EraDevicePage } from "../components/EraDevicePage";

const mapStateToProps = (state: t.MainAppState) => {
  return {
    deviceName: deviceSelectors.getDeviceName(state),
    isDeviceConnected: deviceSelectors.getIsDeviceConnected(state),
    isDeviceLocked: deviceSelectors.getIsDeviceLocked(state),
  };
};

export default connect(mapStateToProps)(EraDevicePage);
