import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled, { css } from "styled-components";

import { updateUserDataSharingPreferences } from "../../api/consumer/actions/users";
import { closeUsageAgreementModal } from "../../modal/actions";
import { Modal } from "../../modal/components/Modal";
import { getIsUsageAgreementModalOpen } from "../../modal/selectors";
import Button, { SecondaryButton } from "../../shared/components/Button";
import { Column } from "../../shared/components/Flex";
import * as Text from "../../shared/components/Text";
import { PRIVACY_POLICY_URL } from "../../shared/constants";
import { desktopBreakpoint } from "../../shared/utils";

export const UsageAgreementModal: React.FC = () => {
  const dispatch = useDispatch();

  const isUsageAgreementModalOpen = useSelector(getIsUsageAgreementModalOpen);

  const handleButtonClick = (shareUsageData: boolean): void => {
    dispatch(updateUserDataSharingPreferences({ shareUsageData }));
    dispatch(closeUsageAgreementModal());
  };

  const modalProps = {
    "data-testid": "usage-agreement-modal",
    hideCloser: true,
    isOpen: isUsageAgreementModalOpen,
  };

  return (
    <Modal {...modalProps}>
      <sc.Container>
        <sc.Title data-testid="usage-agreement-title">
          Product Usage Data
        </sc.Title>
        <sc.TextContainer>
          <sc.Message>
            In addition to the other types of data we collect as described in
            our Privacy Policy you may optionally share data regarding the usage
            of your PAX Product, such as temperature and session length. We call
            this Product Usage Data in our{" "}
            <sc.PrivacyLink
              data-testid="privacy-policy-link1"
              href={PRIVACY_POLICY_URL}
              rel="noopener noreferrer"
              target="_blank"
            >
              Privacy Policy
            </sc.PrivacyLink>
            . We use this data to help improve our products and services.
          </sc.Message>
          <sc.Message>
            We only collect Product Usage with your consent, and we take
            appropriate measures to protect it. You can opt out at any time. For
            more details, please review our{" "}
            <sc.PrivacyLink
              data-testid="privacy-policy-link2"
              href={PRIVACY_POLICY_URL}
              rel="noopener noreferrer"
              target="_blank"
            >
              Privacy Policy
            </sc.PrivacyLink>
            . Do you agree to share your Product Usage Data?
          </sc.Message>
        </sc.TextContainer>
        <sc.ButtonContainer>
          <SecondaryButton
            data-testid="no-button"
            onClick={(): void => handleButtonClick(false)}
          >
            No
          </SecondaryButton>

          <sc.YesButton
            data-testid="yes-button"
            onClick={(): void => handleButtonClick(true)}
          >
            Yes
          </sc.YesButton>
        </sc.ButtonContainer>
      </sc.Container>
    </Modal>
  );
};

const sc = {
  ButtonContainer: styled.div`
    margin-top: 40px;
  `,

  Container: styled(Column)`
    align-items: center;
    height: 588px;
    justify-content: center;
    margin: auto;
    max-width: 424px;
    padding: 40px;

    ${desktopBreakpoint(
      css`
        width: 424px;
      `
    )}
  `,

  Message: styled.div`
    ${Text.PlaaxLight}
    color: var(--black);
    font-size: 16px;
    line-height: 1.23;
    margin-top: 20px;
    text-align: left;
  `,

  PrivacyLink: styled.a`
    color: var(--black);
  `,

  TextContainer: styled.div`
    margin-top: 12px;
  `,

  Title: styled.div`
    ${Text.Plaax}
    font-size: 24px;
    text-transform: uppercase;
  `,

  YesButton: styled(Button)`
    margin-left: 60px;
    width: 80px;
  `,
};
