import { isConnectBrowser } from "../../../navigator";

import CharacteristicChangedPacketHandler from "./CharacteristicChangedPacketHandler";

export default class BleManager {
  characteristicChangedPacketHandlers: {
    [key: string]: CharacteristicChangedPacketHandler[];
  };
  characteristics: { [key: string]: BluetoothRemoteGATTCharacteristic };
  device: BluetoothDevice;
  gatt: BluetoothRemoteGATTServer;
  hasValueChangedListener?: boolean;
  server?: BluetoothRemoteGATTServer;
  services: { [key: string]: BluetoothRemoteGATTService };

  constructor(device: BluetoothDevice) {
    if (!device) throw new Error("device is undefined");
    if (!device.gatt) throw new Error("device.gatt is undefined");

    this.characteristicChangedPacketHandlers = {};
    this.characteristics = {};
    this.device = device;
    this.gatt = device.gatt;
    this.hasValueChangedListener = false;
    this.services = {};
  }

  async connect(): Promise<BluetoothRemoteGATTServer> {
    this.server = await this.gatt.connect();

    return this.server;
  }

  disconnect(): void {
    if (this.gatt.connected) {
      this.gatt.disconnect();
    }
  }

  async getCharacteristic(
    characteristicUUID: BluetoothCharacteristicUUID,
    serviceUUID: BluetoothServiceUUID
  ): Promise<BluetoothRemoteGATTCharacteristic> {
    if (this.characteristics[characteristicUUID]) {
      return this.characteristics[characteristicUUID];
    }

    const service = await this.getPrimaryService(serviceUUID);
    const characteristic = await service.getCharacteristic(characteristicUUID);
    this.characteristics[characteristicUUID] = characteristic;

    return characteristic;
  }

  async writeCharacteristic(
    characteristicUUID: BluetoothCharacteristicUUID,
    serviceUUID: BluetoothServiceUUID,
    value: Uint8Array
  ): Promise<void> {
    const characteristic = await this.getCharacteristic(
      characteristicUUID,
      serviceUUID
    );

    return characteristic.writeValue(value);
  }

  async subscribeToCharacteristicValue(
    characteristicUUID: BluetoothCharacteristicUUID,
    serviceUUID: BluetoothServiceUUID,
    packetHandler: CharacteristicChangedPacketHandler
  ): Promise<void> {
    const characteristic = await this.getCharacteristic(
      characteristicUUID,
      serviceUUID
    );

    this.characteristicChangedPacketHandlers[characteristic.uuid] =
      this.characteristicChangedPacketHandlers[characteristic.uuid] || [];
    const packetHandlers =
      this.characteristicChangedPacketHandlers[characteristic.uuid];

    packetHandlers.push(packetHandler);

    // Add an event listener the first time a characteristic is subscribed to.
    if (packetHandlers.length === 1 && !this.hasValueChangedListener) {
      characteristic.addEventListener(
        "characteristicvaluechanged",
        this.onCharacteristicValueChanged
      );
      this.hasValueChangedListener = true;
    }

    characteristic.startNotifications();
  }

  onCharacteristicValueChanged = async (event: Event): Promise<void> => {
    if (!event || !event.target) return;

    const characteristic = event.target as BluetoothRemoteGATTCharacteristic;
    const packetHandlers =
      this.characteristicChangedPacketHandlers["" + characteristic.uuid];

    if (!packetHandlers || packetHandlers.length === 0) return;

    // readValue is not implemented in Connect Browser.
    let value: DataView | undefined;
    if (!isConnectBrowser) {
      try {
        value = await characteristic.readValue();
      } catch (error) {
        // Do nothing. Another attempt to set value is made below.
      }
    }

    if (!value && characteristic.value) value = characteristic.value;
    if (!value) return;

    const packet = new Uint8Array(value.buffer);

    packetHandlers.forEach((packetHandler) => {
      packetHandler.onResponsePacket(packet);
    });

    // Clear completed packet handlers.
    this.clearCompletedPacketHandlers(characteristic.uuid);
  };

  async getPrimaryService(
    serviceUUID: BluetoothServiceUUID
  ): Promise<BluetoothRemoteGATTService> {
    if (!this.server) {
      throw new Error("Not connected to server");
    }

    if (!this.services[serviceUUID]) {
      this.services[serviceUUID] = await this.server.getPrimaryService(
        serviceUUID
      );
    }

    return this.services[serviceUUID];
  }

  clearCompletedPacketHandlers(
    characteristicUUID: BluetoothCharacteristicUUID
  ): void {
    const handlers =
      this.characteristicChangedPacketHandlers[characteristicUUID];
    const incompleteHandlers = handlers.filter(
      (handler) => !handler.hasAllPackets()
    );

    this.characteristicChangedPacketHandlers[characteristicUUID] =
      incompleteHandlers;
  }
}
