import * as rt from "@reduxjs/toolkit";

import { logger } from "./logger";

const middleware = rt.getDefaultMiddleware();

if (process.env.REACT_APP_USE_REDUX_LOGGER === "true") {
  middleware.push(logger);
}

export { middleware };
