import Bugsnag from "@bugsnag/js";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { get as lsGet, remove as lsRemove } from "local-storage";

import { FirmwareUpgradeState } from "../../../mcumgr-js/src/dfu/FirmwareUpgradeManager";
import * as PaxBle from "../../../pax-ble";

import { trackLogAudit } from "../../analytics/device/logAudit";
import * as consumerActions from "../../api/consumer/actions";
import * as connectedDevice from "../../bluetooth/connectedDevice";
import { MainAppState } from "../../main/types";
import * as text from "../../shared/text";
import { Action, AppThunk, GetState } from "../../shared/types";

import * as a from "../actions";
import * as c from "../constants";
import * as t from "../types";
import { isPax3 } from "../utils";

export type ConnectToDevice = (
  deviceType: t.NullableDeviceType,
  options?: PaxBle.Types.DeviceOptions,
  requestDevice?: PaxBle.RequestDevice
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
) => AppThunk<any>;

const onBeforeUnload = (): void => {
  // Any async requests we attempt here will be canceled by the browser.
  // So just disconnect the device.
  connectedDevice.disconnectConnectedDevice();
};

export const connectToDevice: ConnectToDevice =
  (deviceType, options?, requestDevice = PaxBle.requestDevice) =>
  async (dispatch, getState): Promise<void> => {
    if (lsGet(c.LS_KEY_IS_DEVICE_CONNECTED)) {
      // Remove the flag to avoid locking the user out from connecting a device.
      lsRemove(c.LS_KEY_IS_DEVICE_CONNECTED);

      throw new Error(text.DEVICE_ALREADY_CONNECTED);
    }

    // Request a device.
    dispatch(a.deviceRequested());
    const device = await requestDevice(deviceType);
    a.deviceConnecting()(dispatch, getState, null);

    window.addEventListener("beforeunload", onBeforeUnload);

    // Listen for device attributes.
    device.events.on(
      "receivedAttribute",
      getReceivedAttributeHandler(dispatch, getState, device)
    );

    // Initialize the device.
    try {
      await initializeDevice(dispatch, device, deviceType, options);
    } catch (e) {
      if ((e as Error).message === PaxBle.Constants.ERR_CONNECTION_TIMEOUT) {
        a.disconnectDevice({ error: text.DEVICE_CONNECTION_TAKING_TOO_LONG })(
          dispatch,
          getState,
          null
        );
      } else if (
        (deviceType === PaxBle.Types.DeviceType.ERA || isPax3(deviceType)) &&
        (e as Error).message === PaxBle.Constants.ERR_BAD_IDENTIFIER
      ) {
        a.disconnectDevice({ error: text.ERROR_CONNECTING_CHECK_SERIAL })(
          dispatch,
          getState,
          null
        );
      } else if (
        deviceType === PaxBle.Types.DeviceType.ERA_PRO &&
        (e as Error).message ===
          PaxBle.Constants.ERR_ENCRYPTION_HANDSHAKE_FAILED
      ) {
        Bugsnag.notify(e as Error);
        a.disconnectDevice({ error: text.ERROR_CONNECTING_DEVICE_TRY_AGAIN })(
          dispatch,
          getState,
          null
        );
      } else {
        Bugsnag.notify(e as Error);
        device.disconnect();
        throw e;
      }

      device.disconnect();
      return;
    }

    // Set the connected device.
    connectedDevice.setConnectedDevice(device);

    // Set up log events listener.
    device.events.on(
      "receivedLogEvents",
      (logEvents: a.LogEventNotification) => {
        a.receivedLogEvents(logEvents)(dispatch, getState, null);
      }
    );

    let deviceSyncingTimeout: number;
    let logFetchInterval: number;

    // Set up device syncing listener
    device.events.on("onLogReceived", () => {
      dispatch(a.setLogSyncingStatus(true));

      // Once enough time passes without any new logs, set syncing status to false.
      clearTimeout(deviceSyncingTimeout);
      deviceSyncingTimeout = window.setTimeout(() => {
        // Track when user has reached the end of their log queue.
        trackLogAudit(
          {
            action: "end",
            hasReachedEnd: true,
          },
          device
        )(dispatch, getState, null);
        dispatch(a.setLogSyncingStatus(false));
      }, c.DEVICE_SYNCING_TIMEOUT);

      // Start checking for newly created logs.
      clearInterval(logFetchInterval);
      logFetchInterval = window.setInterval(
        () => device.fetchLogs(),
        c.LOG_FETCH_INTERVAL
      );
    });

    // Set up listener for consecutive decryption errors.
    device.events.on("onConsecutiveDecryptionErrors", () => {
      dispatch(a.setHasEncryptionErrors(true));
      dispatch(a.setLogSyncingStatus(false));
    });

    device.events.on(
      "eventLogUpload",
      (analyticsData: PaxBle.Types.LogStoreAnalyticsData) => {
        trackLogAudit(analyticsData, device)(dispatch, getState, null);
      }
    );

    const handleDeviceDisconnect = getDeviceDisconnectHandler(
      dispatch,
      getState
    );

    // Set up disconnect listener.
    device.events.on("disconnect", () => {
      clearInterval(logFetchInterval);
      handleDeviceDisconnect();
    });

    // Dispatch device connection, passing a previously connected manual pod id.
    const serial = device.serial || options?.serial;
    const manualPodStrainId: string = lsGet(c.LS_KEY_CURRENT_POD_STRAIN_ID);
    a.deviceConnected(device.firmwareRevision, deviceType, {
      manualPodStrainId,
      serial,
    })(dispatch, getState, null);
  };

const initializeDevice = async (
  dispatch: ThunkDispatch<MainAppState, unknown, Action>,
  device: PaxBle.BaseDevice,
  deviceType: t.NullableDeviceType,
  options?: PaxBle.Types.DeviceOptions
): Promise<void> => {
  // Initialize the device and fetch initial attributes.
  try {
    const attributeIds =
      c.initialAttributeIdsForDeviceType[deviceType as PaxBle.Types.DeviceType];
    await device.initialize(attributeIds, options);
  } catch (e) {
    // Handle a bad identifier and encryption handshake errors.
    if (
      (deviceType === PaxBle.Types.DeviceType.ERA ||
        deviceType === PaxBle.Types.DeviceType.PAX_3) &&
      (e as Error).message === PaxBle.Constants.ERR_BAD_IDENTIFIER
    ) {
      // Dispatch a bad serial message and disconnect.
      dispatch(
        a.disconnectDevice({
          error: text.ERROR_CONNECTING_CHECK_SERIAL,
        })
      );
    } else if (
      deviceType === PaxBle.Types.DeviceType.ERA_PRO &&
      (e as Error).message === PaxBle.Constants.ERR_ENCRYPTION_HANDSHAKE_FAILED
    ) {
      // Dispatch encryption failed message, disconnect device, and notify bugsnag.
      Bugsnag.notify(e as Error);
      dispatch(
        a.disconnectDevice({ error: text.ERROR_CONNECTING_DEVICE_TRY_AGAIN })
      );
    } else {
      Bugsnag.notify(e as Error);
    }

    device.disconnect();
    throw e;
  }
};

export const getReceivedAttributeHandler =
  (
    dispatch: ThunkDispatch<MainAppState, unknown, Action>,
    getState: GetState,
    device: PaxBle.BaseDevice
  ) =>
  (attributeId: number, value: unknown): void => {
    switch (attributeId) {
      case PaxBle.Constants.ATTRIBUTE_TIME:
        const currentUTCTime: Date = new Date();
        const epochSecondsUTC: number = Math.round(
          currentUTCTime.getTime() / 1000
        );
        const epochSecondsDevice: number = value as number;

        if (
          Math.abs(epochSecondsUTC - epochSecondsDevice) >
          c.DEVICE_TIME_DIFFERENCE_THRESHOLD
        ) {
          dispatch(a.syncDeviceTime(device));
        }

        return;

      case PaxBle.Constants.ATTRIBUTE_POD_DATA:
        const typedValue = value as {
          podId: string;
          podStatus: number;
          productId: string;
        };
        const { productId, podId, podStatus } = typedValue;

        // NFC pod inserted.
        if (podStatus === 0) {
          dispatch({ payload: typedValue, type: c.RECEIVED_NFC_POD_DATA });
          if (productId) {
            consumerActions.connectedPods.recordConnectedPod(productId, {
              podSerialNumber: podId,
              wasScanned: false,
            })(dispatch, getState, null);
          }

          a.fetchBatchResult(typedValue.podId)(dispatch, getState, null);
        }

        // Pod Removed
        if (podStatus === 5) {
          const isAutoId = getState().device.pod.isAutoId;

          if (isAutoId) {
            dispatch(a.clearPodId());
          }
        }

        dispatch(a.receivedAttribute(attributeId, value));

        break;

      case PaxBle.Constants.ATTRIBUTE_SHELL_COLOR:
        const { PAX_3, PAX_35 } = PaxBle.Types.DeviceType;

        if (
          device.type === PAX_3 &&
          t.PAX_35_COLORS.includes(value as t.DeviceColor)
        ) {
          device.type = PAX_35;
        }

        dispatch(a.receivedAttribute(attributeId, value));
        break;

      case PaxBle.Constants.ATTRIBUTE_FIND_MY_PAX:
        const { mode } = value as PaxBle.Types.EraPro.FindMyPaxStatus;

        if (mode !== 0) {
          (device as PaxBle.EraProDevice).stopFindMyPax();
        }

        break;

      case PaxBle.Constants.ATTRIBUTE_HEATER_SET_POINT:
        if (isPax3(device.type)) {
          dispatch(a.receivedAttribute(attributeId, value));
          break;
        }

        const heaterSetPoint = value as number;
        const minHeaterSetPoint = c.PEN_TEMP_MIN_C * 10;
        const maxHeaterSetPoint = c.PEN_TEMP_MAX_C * 10;

        // Fix the device temperature if it's out of range.
        if (heaterSetPoint < minHeaterSetPoint) {
          device.writeHeaterSetPoint(minHeaterSetPoint);
          device.readHeaterSetPoint();
          break;
        }

        if (heaterSetPoint > maxHeaterSetPoint) {
          device.writeHeaterSetPoint(maxHeaterSetPoint);
          device.readHeaterSetPoint();
          break;
        }

        dispatch(a.receivedAttribute(attributeId, value));

        break;

      default:
        dispatch(a.receivedAttribute(attributeId, value));
    }
  };

const getDeviceDisconnectHandler =
  (
    dispatch: ThunkDispatch<MainAppState, unknown, Action>,
    getState: GetState
  ) =>
  (): void => {
    const { firmware, device } = getState();
    const { mcuState } = firmware.firmwareUpgradeState;
    const LOW_SOC_STATUS = device.attributes.LOW_SOC_STATUS;

    if (mcuState === FirmwareUpgradeState.RESET) {
      dispatch(a.restartDevice());
    } else if (LOW_SOC_STATUS === 1) {
      dispatch(
        a.disconnectDevice({ error: text.PAX_3_LOW_BATTERY_DISCONNECT })
      );
    } else {
      dispatch(
        a.disconnectDevice({ error: text.DEVICE_DISCONNECTED_UNEXPECTEDLY })
      );
    }

    dispatch(a.setHasEncryptionErrors(false));

    window.removeEventListener("beforeunload", onBeforeUnload);
  };
