// TODO: remove use of any
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import styled from "styled-components";

type EraColorModeProps = {
  borderColor?: string;
  color: string;
  isSelected?: boolean;
  onClick: (...args: any) => any;
  title: string;
};

type EraColorModeCircleProps = {
  borderColor?: string;
  color: string;
};

type EraColorModeNameProps = {
  bold?: boolean;
};

const EraColorMode: React.FC<EraColorModeProps> = ({
  borderColor,
  color,
  isSelected,
  onClick,
  title,
}) => {
  if (isSelected) {
    borderColor = "black";
  }

  return (
    <sc.ColorMode onClick={onClick} data-testid="era-color-mode">
      <sc.ColorModeCircle color={color} borderColor={borderColor} />
      <sc.ColorModeName bold={isSelected}>{title}</sc.ColorModeName>
    </sc.ColorMode>
  );
};

const sc = {
  ColorMode: styled.div`
    cursor: pointer;
  `,

  ColorModeCircle: styled.div<EraColorModeCircleProps>`
    background-color: ${(props) => `${props.color}`};
    border: ${(props) =>
      props.borderColor ? `solid 1px var(--${props.borderColor})` : "none"};
    border-radius: 100%;
    height: 36px;
    margin: 0 auto;
    width: 36px;
  `,

  ColorModeName: styled.div<EraColorModeNameProps>`
    font-size: 14px;
    font-weight: ${(props) => (props.bold ? "normal" : "lighter")};
    margin-top: 8px;
    text-transform: uppercase;
  `,
};

export default EraColorMode;
