import { connect } from "react-redux";

import { getDeviceName } from "../../device/selectors";
import { getLatestFirmwareVersion } from "../../firmware/selectors";
import { MainAppState } from "../../main/types";
import { openFirmwareModal } from "../../modal/actions";

import * as firmwareNotification from "../components/FirmwareNotification";

const mapStateToProps = (
  state: MainAppState
): firmwareNotification.StateProps => {
  return {
    deviceName: getDeviceName(state),
    latestFirmwareVersion: getLatestFirmwareVersion(state),
  };
};

const mapDispatchToProps: firmwareNotification.DispatchProps = {
  openFirmwareModal,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(firmwareNotification.FirmwareNotification);
