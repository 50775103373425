import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";

import * as analytics from "../../analytics";
import { ErrorCode } from "../../api/consumer/types";
import { disconnectConnectedDevice } from "../../bluetooth/connectedDevice";
import * as cookies from "../../cookies";
import * as r from "../../main/routes";
import { BannerType } from "../../shared/components/FlashBanner";
import { PasswordInput } from "../../shared/components/PasswordInput";
import * as sharedHooks from "../../shared/hooks";
import { SearchParam } from "../../shared/types";

import { Login, updateSsoPageState } from "../actions";
import { SsoCountry } from "../constants";
import { useSsoPageReset } from "../hooks";
import * as text from "../text";
import { SsoPage, SsoProps } from "../types";
import * as utils from "../utils";
import { SsoChrome } from "./SsoChrome";
import { SsoErrorMessage } from "./SsoErrorMessage";
import * as SsoForm from "./SsoForm";

export type SsoLoginPageProps = SsoProps & {
  anonymousUuid: string;
  failureCode?: ErrorCode;
  initialEmail?: string;
  login: Login;
  loginCookie?: string;
  success?: boolean;
  trackLogin: analytics.sso.TrackLogin;
  useSearchParam: sharedHooks.UseSearchParam;
};

export const SsoLoginPage: React.FC<SsoLoginPageProps> = ({
  anonymousUuid,
  endSsoFlow = utils.endSsoFlow,
  failureCode,
  initialEmail = "",
  login,
  loginCookie,
  success,
  trackLogin = analytics.sso.trackLogin,
  useSearchParam = sharedHooks.useSearchParam,
}) => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState<string>(initialEmail);
  const [password, setPassword] = useState<string>("");

  const redirectTo = useSearchParam(SearchParam.REDIRECT) || r.home();
  const shouldCloseWindow = useSearchParam(SearchParam.CLOSE) || "false";
  const country =
    utils.getSsoCountry(useSearchParam(SearchParam.COUNTRY)) || SsoCountry.US;

  const createAccountLink = r.ssoInternalCreateAccount({
    close: shouldCloseWindow,
    country,
    returnTo: redirectTo,
  });
  const forgotPasswordLink = r.ssoForgotPassword({
    close: shouldCloseWindow,
    returnTo: redirectTo,
  });

  useSsoPageReset(SsoPage.login);

  useEffect(() => {
    disconnectConnectedDevice();
  }, []);

  useEffect(() => {
    trackLogin({
      isStart: true,
      source: "web-sso-login-form",
    });
    // Only track isStart on initial mount.
    // eslint-disable-next-line
  }, [trackLogin]);

  if (success) {
    cookies.setBannerCookie("Sign in successful!", BannerType.SUCCESS);
  }

  if (success || loginCookie) {
    endSsoFlow(redirectTo, shouldCloseWindow);
    return null;
  }

  return (
    <SsoChrome>
      <SsoForm.Form
        data-testid="login-form"
        onSubmit={(e): void => {
          e.preventDefault();
          login({
            anonymousUuid,
            email,
            password,
          });
        }}
      >
        <sc.UsernameField>
          <SsoForm.Label htmlFor="email-input">
            {text.EMAIL_LABEL}
          </SsoForm.Label>
          <SsoForm.Input
            data-testid="email-input"
            hasError={utils.isUsernameInError(failureCode)}
            id="email-input"
            onChange={(e): void => setEmail(e.target.value)}
            required
            type="email"
            value={email}
          />
        </sc.UsernameField>
        <sc.PasswordField>
          <SsoForm.Label htmlFor="password-input">
            {text.PASSWORD_LABEL}
          </SsoForm.Label>
          <PasswordInput
            data-testid="password-input"
            hasError={utils.isPasswordInError(failureCode)}
            id="password-input"
            Input={SsoForm.PasswordInput}
            onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
              setPassword(e.target.value)
            }
            required
          />
        </sc.PasswordField>
        {failureCode && (
          <SsoErrorMessage failureCode={failureCode} redirectTo={redirectTo} />
        )}
        <SsoForm.ButtonsContainer>
          {failureCode === ErrorCode.REQUIRES_PASSWORD_RESET ? (
            <sc.Link
              to={r.ssoForgotPassword({
                close: shouldCloseWindow,
                returnTo: redirectTo,
              })}
            >
              <SsoForm.Button data-testid="reset-password-button" type="button">
                {text.RESET_PASSWORD_BUTTON}
              </SsoForm.Button>
            </sc.Link>
          ) : (
            <SsoForm.Button data-testid="submit-button" type="submit">
              {text.SIGN_IN_BUTTON}
            </SsoForm.Button>
          )}
        </SsoForm.ButtonsContainer>
        <SsoForm.Footer>
          <SsoForm.Link to={createAccountLink} data-testid="sign-up-link">
            {text.SIGN_UP_LINK}
          </SsoForm.Link>
          <sc.ForgotPasswordLink
            to={forgotPasswordLink}
            onClick={(): void => {
              dispatch(
                updateSsoPageState(SsoPage.forgotPassword, {
                  initialEmail: email,
                })
              );
            }}
            data-testid="forgot-password-link"
          >
            {text.FORGOT_PASSWORD_LINK}
          </sc.ForgotPasswordLink>
        </SsoForm.Footer>
      </SsoForm.Form>
    </SsoChrome>
  );
};

const sc = {
  ForgotPasswordLink: styled(SsoForm.Link)`
    & span {
      margin: 16px 0 0 0;
    }
  `,

  Link: styled(Link)`
    text-decoration: none;
  `,

  PasswordField: styled(SsoForm.Field)`
    margin-bottom: 4px;
  `,

  UsernameField: styled(SsoForm.Field)`
    margin-top: 60px;
  `,
};
