import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";

import * as apiTypes from "../../../api/consumer/types";
import * as podsHooks from "../../../pods/hooks";
import { PodOverlayMobile } from "../../../shared/components/PodTempOverlayMobile";
import { useEventListener } from "../../../shared/hooks";
import { getOffsetRelativeToDocument } from "../../../shared/utils";

import { SetHeaterSetPoint } from "../../actions";
import FineControls from "../../containers/MobileThermostat/FineControls";
import PresetTemperatures from "../../containers/MobileThermostat/PresetTemperatures";
import * as t from "../../types";
import { celsiusToFahrenheit, fahrenheitToCelcius } from "../../utils";

import { Knob } from "./Knob";
import { PartnerLogo } from "./PartnerLogo";
import { RoundTemperatureSlider } from "./RoundTemperatureSlider";

const CENTER_OFFSET = 120;
const DEGREE_OFFSET = 47;
const RADIUS = 110;
const DEGREES_ROUNDING_ERROR = 1;
const SPREAD_DEGREES = 274;
const STROKE_DASH_ARRAY = 500;

type Preset = {
  position: t.Point | null;
  temperature: number | null;
};

export type Presets = {
  [t.PresetType.EXPERT]: Preset | null;
  [t.PresetType.IDEAL_FLAVOR]: Preset | null;
  [t.PresetType.IDEAL_VAPOR]: Preset | null;
};

export type StateProps = {
  deviceTemperature?: number;
  isDeviceLocked: boolean;
  podStrainId: string | null;
  temperatureRange: t.TemperatureRange;
  temperatureUnit: apiTypes.user.PreferredTemperatureUnit;
};

export type MobileThermostatProps = StateProps & {
  initialDesiredTemp?: number;
  onChange?: SetHeaterSetPoint;
  useStrainById?: podsHooks.UseStrainById;
};

export const MobileThermostat: React.FC<MobileThermostatProps> = ({
  deviceTemperature,
  isDeviceLocked,
  initialDesiredTemp = 0,
  onChange,
  podStrainId,
  temperatureRange,
  temperatureUnit,
  useStrainById = podsHooks.useStrainById,
}) => {
  const [tempMin, tempMax] = temperatureRange;
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [position, setPosition] = useState<t.Point>(() => {
    if (!deviceTemperature) return { x: 0, y: 0 };

    const radians = getRadiansFromTemp(deviceTemperature, temperatureRange);
    return {
      x: RADIUS * Math.cos(radians) + RADIUS,
      y: RADIUS * Math.sin(radians) + RADIUS,
    };
  });
  const [strokeDashOffset, setStrokeDashOffset] = useState<number>(() => {
    if (!deviceTemperature) return 0;

    const radians = getRadiansFromTemp(deviceTemperature, temperatureRange);
    const degrees = getDegreeFromRadians(radians, DEGREE_OFFSET);

    return (
      ((SPREAD_DEGREES - degrees) / SPREAD_DEGREES) * STROKE_DASH_ARRAY +
      STROKE_DASH_ARRAY
    );
  });
  const [desiredTemp, setDesiredTemp] = useState<number>(initialDesiredTemp);
  const { strain } = useStrainById(podStrainId, {
    shouldShowErrorBanner: true,
  });

  const [presets, setPresets] = useState<Presets>({
    [t.PresetType.EXPERT]: null,
    [t.PresetType.IDEAL_FLAVOR]: null,
    [t.PresetType.IDEAL_VAPOR]: null,
  });

  // Get preset temperatures and their positions on dial
  const setPresetTemperaturePosition = useCallback(
    (presetType: t.PresetType, temperature: number | null): void => {
      const position =
        temperature &&
        getPositionFromTemperature(temperature, temperatureRange);

      setPresets((presets: Presets) => ({
        ...presets,
        [presetType]: {
          position,
          temperature,
        },
      }));
    },
    [temperatureRange]
  );

  useEffect(() => {
    if (!strain) {
      setPresetTemperaturePosition(t.PresetType.IDEAL_FLAVOR, null);
      setPresetTemperaturePosition(t.PresetType.EXPERT, null);
      setPresetTemperaturePosition(t.PresetType.IDEAL_VAPOR, null);
      return;
    }

    const flavor = roundPresetTemperature(
      strain.bestFlavorTemperature,
      temperatureUnit
    );
    setPresetTemperaturePosition(t.PresetType.IDEAL_FLAVOR, flavor);

    const expert = roundPresetTemperature(
      strain.recommendedTemperature,
      temperatureUnit
    );
    setPresetTemperaturePosition(t.PresetType.EXPERT, expert);

    const vapor = roundPresetTemperature(
      strain.maxVaporTemperature,
      temperatureUnit
    );
    setPresetTemperaturePosition(t.PresetType.IDEAL_VAPOR, vapor);
  }, [setPresetTemperaturePosition, strain, temperatureUnit]);

  // Calculate circular slider positioning
  const circularSlider = useRef<HTMLDivElement>(null);

  const updateDesiredTemperature = useCallback(
    (degrees: number): void => {
      const dialProgress = degrees / SPREAD_DEGREES;
      let newDesiredTemp = tempMin + (tempMax - tempMin) * dialProgress;

      if (newDesiredTemp > tempMax) newDesiredTemp = tempMax;
      if (newDesiredTemp < tempMin) newDesiredTemp = tempMin;

      setDesiredTemp(newDesiredTemp);
    },
    [tempMax, tempMin]
  );

  const setKnobPosition = useCallback(
    (radians?: number): void => {
      if (!radians) return;

      const degrees = getDegreeFromRadians(radians, DEGREE_OFFSET);

      if (degrees > SPREAD_DEGREES + DEGREES_ROUNDING_ERROR) return;

      setStrokeDashOffset(
        ((SPREAD_DEGREES - degrees) / SPREAD_DEGREES) * STROKE_DASH_ARRAY +
          STROKE_DASH_ARRAY
      );
      setPosition({
        x: RADIUS * Math.cos(radians) + RADIUS,
        y: RADIUS * Math.sin(radians) + RADIUS,
      });
      updateDesiredTemperature(degrees);
    },
    [updateDesiredTemperature]
  );

  const setTemperature = useCallback(
    (radians?: number): void => {
      if (!onChange || !radians) return;

      const degrees = getDegreeFromRadians(radians, DEGREE_OFFSET);

      if (degrees > SPREAD_DEGREES + DEGREES_ROUNDING_ERROR) return;

      const dialProgress = degrees / SPREAD_DEGREES;
      const newDesiredTemp = tempMin + (tempMax - tempMin) * dialProgress;
      onChange(newDesiredTemp, temperatureUnit);
    },
    [onChange, tempMax, tempMin, temperatureUnit]
  );

  useEffect(() => {
    if (!deviceTemperature) return;

    const radians = getRadiansFromTemp(deviceTemperature, temperatureRange);

    setKnobPosition(radians);
  }, [deviceTemperature, setKnobPosition, temperatureRange]);

  const handleMouseDown = (): void => {
    setIsDragging(true);
  };

  const handleMouseUp = (): void => {
    if (!onChange || !isDragging) return;

    setIsDragging(false);
    onChange(desiredTemp, temperatureUnit);
  };

  const offset = getOffsetRelativeToDocument(circularSlider);

  const getRadiansFromXY = (x: number, y: number): number | undefined => {
    if (!offset) return;

    const xFromCenter = x - (offset.left + CENTER_OFFSET);
    const yFromCenter = y - (offset.top + CENTER_OFFSET);

    return Math.atan2(yFromCenter, xFromCenter);
  };

  const handleMouseMove = (
    event: React.TouchEvent | React.MouseEvent
  ): void => {
    if (!isDragging || !offset) return;

    event.preventDefault();
    let radians;

    if (event instanceof TouchEvent) {
      const touch = event.changedTouches[0];
      radians = getRadiansFromXY(touch.pageX, touch.pageY);
    } else if (event instanceof MouseEvent) {
      radians = getRadiansFromXY(event.pageX, event.pageY);
    }

    setKnobPosition(radians);
  };

  const handleDialClick = (event: React.MouseEvent<SVGPathElement>): void => {
    const radians = getRadiansFromXY(event.pageX, event.pageY);
    setTemperature(radians);
  };

  const touchSupported = "ontouchstart" in window;
  const SLIDER_EVENT = {
    DOWN: touchSupported ? "touchstart" : "mousedown",
    MOVE: touchSupported ? "touchmove" : "mousemove",
    UP: touchSupported ? "touchend" : "mouseup",
  };

  useEventListener(SLIDER_EVENT.MOVE, handleMouseMove);
  useEventListener(SLIDER_EVENT.UP, handleMouseUp);

  if (isDeviceLocked || (position.x === 0 && position.y === 0)) {
    return (
      <sc.Container data-testid="mobile-thermostat">
        <sc.RoundTemperatureSliderContainer ref={circularSlider}>
          <RoundTemperatureSlider
            isDisabled
            onClick={handleDialClick}
            strokeDashArray={STROKE_DASH_ARRAY}
            strokeDashOffset={strokeDashOffset}
          />
        </sc.RoundTemperatureSliderContainer>
        <sc.PartnerLogo isDeviceLocked />
        <sc.FineControls desiredTemperature={desiredTemp} />
      </sc.Container>
    );
  }

  return (
    <sc.Container data-testid="mobile-thermostat">
      <sc.RoundTemperatureSliderContainer ref={circularSlider}>
        <RoundTemperatureSlider
          onClick={handleDialClick}
          strokeDashArray={STROKE_DASH_ARRAY}
          strokeDashOffset={strokeDashOffset}
        />
      </sc.RoundTemperatureSliderContainer>
      <PresetTemperatures
        presets={presets}
        temperatureRange={temperatureRange}
      />
      <Knob
        positionX={position.x}
        positionY={position.y}
        onMouseDown={handleMouseDown}
      />
      <sc.PartnerLogo>
        <PodOverlayMobile
          strain={strain}
          temperatureC={
            temperatureUnit === apiTypes.user.PreferredTemperatureUnit.CELSIUS
              ? desiredTemp
              : fahrenheitToCelcius(desiredTemp)
          }
        />
      </sc.PartnerLogo>
      <sc.FineControls desiredTemperature={desiredTemp} />
    </sc.Container>
  );
};

const getRadiansFromTemp = (
  temperature: number,
  range: t.TemperatureRange
): number => {
  const [tempMin, tempMax] = range;

  const dialProgress =
    ((temperature - tempMin) / (tempMax - tempMin)) * SPREAD_DEGREES;
  const radians =
    (dialProgress * Math.PI) / 180 - Math.PI - (DEGREE_OFFSET * Math.PI) / 180;

  return radians;
};

const getDegreeFromRadians = (radians: number, degreeOffset = 0): number => {
  const offsetRadians = radians + (degreeOffset * Math.PI) / 180;
  let degrees = ((offsetRadians + Math.PI) * 180) / Math.PI;

  if (degrees > 360) degrees -= 360;

  return degrees;
};

const getPositionFromTemperature = (
  temperature: number,
  range: t.TemperatureRange
): t.Point => {
  const [tempMin, tempMax] = range;
  const dialProgress =
    ((temperature - tempMin) / (tempMax - tempMin)) * SPREAD_DEGREES;
  const radians =
    (dialProgress * Math.PI) / 180 - Math.PI - (DEGREE_OFFSET * Math.PI) / 180;
  const x = (RADIUS - 4) * Math.cos(radians) + RADIUS;
  const y = (RADIUS - 4) * Math.sin(radians) + RADIUS - 3;

  return { x, y };
};

const roundPresetTemperature = (
  temperature: number,
  temperatureUnit: apiTypes.user.PreferredTemperatureUnit | undefined
): number | null => {
  if (temperature === 0) return null;
  if (temperatureUnit === apiTypes.user.PreferredTemperatureUnit.FAHRENHEIT)
    return Math.round(celsiusToFahrenheit(temperature));

  return Math.round(temperature);
};

const sc = {
  Container: styled.div`
    margin: 30px auto 0;
    position: relative;
  `,

  FineControls: styled(FineControls)`
    left: 0;
    position: absolute;
    right: 0;
    top: 210px;
  `,

  PartnerLogo: styled(PartnerLogo)`
    left: 50px;
    position: absolute;
    top: 50px;
  `,

  RoundTemperatureSliderContainer: styled.div`
    height: 240px;
  `,
};
